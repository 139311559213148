import { ref, reactive } from 'vue';
import type { App } from 'vue';
const useRoamDirective = (app: App) => {
  const targetEl = ref();
  const startXY = reactive({ x: 0, y: 0 });
  const origionScroll = reactive({ left: 0, top: 0 });
  const mouseMove = (e: MouseEvent) => {
    e.preventDefault();
    targetEl.value.style.cursor = 'grabbing';
    const _x = e.x - startXY.x;
    const _y = e.y - startXY.y;
    targetEl.value.scrollLeft = origionScroll.left - _x;
    targetEl.value.scrollTop = origionScroll.top - _y;
  };
  app.directive('roam', {
    mounted(el, binding) {
      targetEl.value = el;
      const roamEls = binding.value?.map((el: any) => {
        return document.querySelector(el);
      });
      el.addEventListener('mousedown', (e: MouseEvent) => {
        e.stopPropagation();
        if (roamEls.includes(e.target)) {
          targetEl.value.style.cursor = 'grab';
          startXY.x = e.clientX;
          startXY.y = e.clientY;
          origionScroll.left = targetEl.value.scrollLeft;
          origionScroll.top = targetEl.value.scrollTop;
          el.addEventListener('mousemove', mouseMove);
          el.addEventListener('mouseleave', () => {
            const event = document.createEvent('HTMLEvents');
            event.initEvent('mouseup');
            el.dispatchEvent(event);
          });
        }
      });
      el.addEventListener('mouseup', () => {
        targetEl.value.style.cursor = 'default';
        el.removeEventListener('mousemove', mouseMove);
      });
    }
  });
};

export default useRoamDirective;
