// 定义内容
export default {
  label: {
    one1: '用户名登录',
    two2: '手机号登录',
    three3: '短信登录',
    four4: '账号登录'
  },
  link: {
    one3: '第三方登录',
    two4: '友情链接'
  },
  account: {
    accountPlaceholder1: '请输入用户名',
    accountPlaceholder2: '请输入密码',
    accountPlaceholder3: '请输入验证码',
    accountPlaceholder4: '验证码',
    accountBtnText: '登 录',
    forgetPassword: '忘记密码'
  },
  mobile: {
    placeholder1: '请输入手机号码',
    placeholder2: '请输入验证码',
    placeholder3: '手机号码格式不正确',
    codeText: '获取验证码',
    btnText: '登 录',
    msgText:
      '* 温馨提示：建议使用谷歌、Microsoft Edge，版本 79.0.1072.62 及以上浏览器，360浏览器请使用极速模式'
  },
  scan: {
    text: '打开手机扫一扫，快速登录/注册'
  },
  signInText: '欢迎回来！'
};
