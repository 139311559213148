import type { App } from 'vue';

import SSummarize from './SSummarize.vue';
import SExposureTrend from './SExposureTrend.vue';
import SChannelVolume from './SChannelVolume.vue';
import SAccountList from './SAccountList.vue';
import SHotTopic from './SHotTopic.vue';
import SInteractionTrend from './SInteractionTrend.vue';
import SEvolution from './SEvolution.vue';
import SEmotionalTendency from './SEmotionalTendency.vue';
const searchAnalysis = [
  SSummarize,
  SExposureTrend,
  SChannelVolume,
  SAccountList,
  SHotTopic,
  SInteractionTrend,
  SEvolution,
  SEmotionalTendency
].map(c => {
  return (c.install = (app: App): void => {
    app.component(c.name, c);
  });
});

export default searchAnalysis;
