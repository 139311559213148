<template>
  <w-form-item
    :label="label"
    class="w-form-item-select"
    style="position: relative"
  >
    <w-select
      popup-container=".w-form-item-select"
      v-model="value"
      :multiple="multiple"
      :clearable="clearable"
      :disabled="disabled"
      :placeholder="placeholder"
      @change="emitChange"
    >
      <w-option
        v-for="item in option"
        :key="item.value"
        :value="item.value"
        :label="item.label"
      ></w-option>
    </w-select>
  </w-form-item>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';
import { i18n } from '/@/i18n/index';
const t = i18n.global.t;
export default defineComponent({
  name: 'ConfigSelect',
  props: {
    label: {
      type: String
    },
    modalValue: {
      type: [Number, String, Array],
      required: false
    },
    placeholder: {
      type: String,
      default: t('message.analysisDetails.pleaseSelect')
    },
    option: {
      type: Object as PropType<{ label: string; value: string }[]>,
      default: []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const value = ref(props.modalValue);
    watch(value, val => {
      emit('update:modalValue', val);
    });
    const setValue = (val: [Number, String, string[], number[]]) => {
      value.value = val;
    };
    const emitChange = () => {
      emit('change', value.value);
    };
    return { value, emitChange, setValue };
  }
});
</script>
