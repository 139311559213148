<template>
  <w-form-item :label="label" field="name">
    <div class="link-num-container">
      <w-input-number
        v-model="numberArray[0]"
        :max="max[0]"
        :min="min[0]"
        :default-value="min[0]"
        :step="step"
        @change="emitChange"
        :hide-button="hideButton"
      />
      <span class="separator"
        ><svg-icon :size="16" name="link" :raw="true"></svg-icon
      ></span>
      <w-input-number
        v-model="numberArray[1]"
        :max="max[1]"
        :min="min[1]"
        :default-value="min[1]"
        :step="step"
        @change="emitChange"
        :hide-button="hideButton"
      />
    </div>
  </w-form-item>
</template>
<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';
export default defineComponent({
  name: 'ConfigLinkedNumber',
  props: {
    label: {
      type: String
    },
    modalValue: {
      type: Array as PropType<number[]>,
      required: true,
      default: () => {
        return [0, 0];
      }
    },
    step: {
      type: Number,
      default: () => {
        return 1;
      }
    },
    max: {
      type: Array as PropType<number[]>,
      required: true,
      default: () => {
        return [0, 0];
      }
    },
    min: {
      type: Array as PropType<number[]>,
      required: true,
      default: () => {
        return [0, 0];
      }
    },
    hideButton: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const numberArray = ref(props.modalValue);
    const emitChange = () => {
      emit('update:modalValue', numberArray.value);
      emit('change', numberArray.value);
    };
    return { numberArray, emitChange };
  }
});
</script>
<style scoped lang="scss">
.link-num-container {
  display: flex;
  align-items: center;

  .w-input-wrapper {
    color: #3b70ff;
    width: 84px;
  }

  .separator {
    padding: 0 10px;
    color: #697282;
  }
}
</style>
