import request, { post, get } from '/@/utils/request';
import { ResponseData } from '/@/api/report/interface';

/**
 * 用户登录
 * @param params 要传的参数值
 * @returns 返回接口数据
 * 登录api接口集合
 * @method signIn 用户登录
 * @method signOut 用户退出登录
 * @method getIpProperty 用户IP属地
 * @method getImgCode 获取验证码
 * @method getInfo 获取用户权限
 */
export function useLoginApi() {
  return {
    signIn: (params: object) => {
      return request({
        url: '/auth/login',
        method: 'post',
        data: params
      });
    },
    signOut: () => {
      return request({
        url: '/auth/logout',
        method: 'delete'
      });
    },
    getIpProperty: () => {
      return request({
        url: '/system/user/getIpProperty',
        method: 'get'
      });
    },
    getImgCode: () => {
      return request({
        url: '/code',
        method: 'get'
      });
    },
    getInfo: () => {
      return request({
        url: '/system/user/getInfo',
        method: 'get'
      });
    },
    // 获取短信验证码
    getCaptcha: (pm: { phoneNumber: string; uuid: string; code: string }) => {
      return post<ResponseData>('/auth/getCaptcha', pm);
    },
    // 手机验证码登录
    captchaLogin: (pm: object) => {
      return post<ResponseData>('/auth/captchaLogin', pm);
    },
    // 校验手机号是否在系统中存在
    checkMobilePhoneExist: (phoneNumber: string) => {
      return get<ResponseData>(
        '/system/captcha/checkMobilePhoneExist?mobilePhone=' + phoneNumber
      );
    },
    // 手机验证码校验
    checkCaptcha: (pm: { phoneNumber: string; captcha: string }) => {
      return get<ResponseData>(
        `/system/captcha/checkCaptcha?phoneNumber=${pm.phoneNumber}&captcha=${pm.captcha}`
      );
    },
    // 修改密码
    modifyUserPassword: (pm: { phoneNumber: string; password: string }) => {
      return post<ResponseData>('/system/user/modifyUserPassword', pm);
    },
    // 绑定手机号
    userPhoneNumberBinding: (pm: { phoneNumber: string; captcha: string }) => {
      return post<ResponseData>('/system/user/userPhoneNumberBinding', pm);
    }
  };
}
