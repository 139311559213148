import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import InfoOverview from './src/index.vue';

InfoOverview.install = (app: App): void => {
  app.component('AInfoOverview', InfoOverview);
  app.component(
    'AInfoOverviewProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default InfoOverview;
