import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import MentionMap from './src/index.vue';

MentionMap.install = (app: App): void => {
  app.component('AMentionMap', MentionMap);
  app.component(
    'AMentionMapProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default MentionMap;
