<template>
  <i v-if="isShowIconURL">
    <iconpark-icon
      v-bind="attr"
      :fill="fill"
      :stroke="stroke"
      :color="color"
      :height="getHeight"
      :width="getWidth"
      :name="getIconName"
    ></iconpark-icon>
  </i>
  <i v-else :class="getIconName" :style="setIconSvgStyle">
    <component :is="getIconName" />
  </i>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'cIcon',
  props: {
    // svg 图标组件名字
    name: {
      type: String
    },
    height: {
      type: String
    },
    width: {
      type: String
    },
    // svg 大小
    size: {
      type: String,
      default: () => '14px'
    },
    // svg 颜色
    color: {
      type: String
    },
    // fill 填充颜色
    fill: {
      type: String,
      default: () => ''
    },
    // stroke 描边颜色
    stroke: {
      type: String,
      default: () => ''
    },
    //
    spin: {
      type: Boolean,
      default: () => false
    }
  },
  setup(props) {
    // 获取 icon 图标名称
    const getIconName = computed(() => {
      return props?.name;
    });
    // 用于判断是否加载线上 icon 资源
    const isShowIconURL = computed(() => {
      return true;
    });
    const getWidth = computed(() => {
      return props?.width || props?.size + 'px';
    });
    const getHeight = computed(() => {
      return props?.height || props?.size + 'px';
    });

    // 设置icon的动态属性
    const attr = computed(() => {
      const obj: any = {};
      if (props?.spin) {
        obj['spin'] = true;
      }
      return obj;
    });

    // 设置图标样式
    const setIconSvgStyle = computed(() => {
      return `width: ${props.size}px;height: ${props.size}px;fill: ${props.color};`;
    });

    return {
      attr,
      getWidth,
      getHeight,
      getIconName,
      isShowIconURL,
      setIconSvgStyle
    };
  }
});
</script>
<style lang="scss" scoped>
i {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
