import type { App } from 'vue';
import { Local } from '/@/utils/storage';
import { I18NNAME } from '/@/constants/cons';

const nodataClass = 'v-nodata';
const getNodaEl = (text?: string) => {
  return `<div class="${nodataClass}" style="position:absolute;top:0;right:0;bottom:0;left:0;display:flex;justify-content:center;align-items:center;"><img src="${
    new URL(`/src/assets/icons/v-nodata.svg`, import.meta.url).href
  }" style="height:100%;max-height:300px;position:relative;"><span style="color:var(--el-text-color-secondary);position:absolute;left:50%;transform:translateX(-50%);bottom:30%;">${
    text || I18NNAME?.[Local.get('globalI18n')]
  }</span></div>`;
};
let origionPositon = '';
const add = (el: HTMLElement, tip?: string) => {
  if (!['relative', 'fixed'].includes(el.style.position)) {
    origionPositon = el.style.position;
    el.style.position = 'relative';
  } else {
    origionPositon = '';
  }
  el.insertAdjacentHTML('afterbegin', getNodaEl(tip));
};
const remove = (el: HTMLElement) => {
  origionPositon && (el.style.position = origionPositon);
  el.getElementsByClassName(nodataClass)[0]?.remove();
};

export function nodataDirective(app: App) {
  app.directive('nodata', {
    mounted(el, binding) {
      const { value } = binding;
      if (!value) return;
      if (typeof value == 'boolean') {
        add(el);
      } else {
        add(el, value.tip);
      }
    },
    updated(el, binding) {
      const { value, oldValue } = binding;
      if (!value) {
        remove(el);
        return;
      }
      if (String(value) == String(oldValue)) return;
      if (value) {
        if (typeof value == 'boolean') {
          add(el);
        } else {
          add(el, value.tip);
        }
      }
    }
  });
}
