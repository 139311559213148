<template>
  <div
    v-loading="loading"
    class="publish-map"
    v-nodata="!data?.length && !loading"
  >
    <MapChart
      class="map-chart"
      v-if="!loading && data && data?.length"
      :data="data"
      :type="pm.chartType"
      :province="params.province_front"
      tooltip
      :theme="theme"
      :key="updateKey"
      @chart:click="chartClick"
    />
    <!-- <ul class="map-view" v-if="!loading">
			<li class="view-item" v-for="item in listData">
				<span :title="item.name">{{ item.name }}</span>
				<span :title="item.value.toString()">{{ item.value }}</span>
			</li>
		</ul> -->
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  toRef,
  computed,
  inject,
  watch
} from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { cloneDeep } from 'lodash-es';
import { useDataCenter } from '../../_hooks/use-data-center';
enum mapType {
  'world' = 0,
  'china' = 1,
  'province' = 2
}

export default defineComponent({
  name: 'AMentionMap',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const updateKey = ref();
    watch(toRef(props, 'theme'), () => {
      updateKey.value = new Date().getTime();
    });
    const { mentionDistribution } = useAnalysisApi();
    const data = ref<{ name: string; value: number }[]>([]);
    const loading = ref(false);
    const pm = toRef(props, 'params');
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const apiData = ref();
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        startPubTime: apiData.value.startPubTime,
        endPubTime: apiData.value.endPubTime,
        mapType: mapType[pm.value.chartType],
        aeResultData: apiData.value.territoryCountList || []
      };
    };
    const { setCurrentDrillingScheme } = useDataCenter(props.params);
    const getData = async () => {
      if (!props.params.schemeId) return;
      loading.value = true;
      const res = (await mentionDistribution({
        ...pm.value,
        mapType: mapType[pm.value.chartType]
      }).catch(() => {
        loading.value = false;
      })) as any;
      apiData.value = res?.data;
      if (chartsClick) {
        setDrillPreviewData();
        searchResultList();
      }
      data.value = res?.data?.territoryCountList
        ?.filter(itm => {
          return itm.territory != '未知';
        })
        .map((item: { territory: string; totalCount: number }) => {
          return {
            name: item.territory,
            value: item.totalCount
          };
        });
      loading.value = false;
    };
    const listData = computed(() => {
      return data.value
        .map(item => {
          return item;
        })
        .sort((a, b) => {
          return b.value - a.value;
        })
        .splice(0, 5);
    });
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(props.params.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      data,
      listData,
      loading,
      pm,
      update,
      updateKey,
      chartClick
    };
  }
});
</script>
<style scoped lang="scss">
.publish-map {
  overflow: hidden;
  position: relative;

  .map-view {
    width: 150px;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    bottom: 10px;
    box-shadow: 0px 4px 12px 0px rgba(108, 113, 126, 0.14);

    .view-item {
      line-height: 30px;
      display: flex;

      span {
        width: 50%;
        padding: 0 10px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
          background-color: #f6f7f8;
        }

        &:last-child {
          text-align: center;
        }
      }
    }
  }
}
</style>
