/* eslint-disable no-unused-vars */
import {
  EsortWay,
  sortFieldOption,
  hotSizeOption,
  interactiveTypeOption,
  mediaTagsOption,
  deepClone,
  isMobile
} from '/@/utils/other';
import { IPrivateSettingParams } from '/@/stores/analysis';
import { i18n } from '/@/i18n/index';
const t = i18n.global.t;
interface IConfig {
  id: number;
  name: string;
  type: string;
  icon: string;
  w: number;
  mw: number;
  h: number;
  mh: number;
  maxH?: number;
  style?: object;
  data: IPrivateSettingParams;
  disabled?: boolean;
  usedPage: ('analysis' | 'bigscreen')[];
  bigscreenConfig?: {
    w: number; //百分比值0-1
    h: number; //像素值
    isGlobal?: boolean; //全局组件
  };
  tools?: object;
}
interface Ilibrary {
  groupName: string;
  components: IConfig[];
}

export enum EComponent {
  GAI_SHU = 1,
  YING_XIANG_LI_ZHI_SHU,
  QU_DAO_SHOU_FA,
  MEI_TI_BAO_GUANG_QU_SHI,
  QU_DAO_SHENG_LIANG_FEN_XI,
  JIE_DUAN_YAN_HUA,
  HUO_YUE_ZHAN_DIAN,
  RE_DIAN_WEN_ZHANG,
  RE_DIAN_CI_YU,
  RE_DIAN_ZHU_TI,
  ZHUAN_JIA_GUAN_DIAN,
  WEI_BO_WANG_MIN_GUAN_DIAN,
  XIN_XI_GAI_LAN,
  HU_DONG_GAI_LAN,
  HU_DONG_QU_SHI,
  // v0.7新增↓
  RE_DIAN_DUAN_YU,
  DI_YU_FEN_XI,
  CHUAN_BO_LU_JING,
  QING_GAN_QING_XIANG,
  RE_MEN_REN_WU,
  RE_MEN_ZU_ZHI,
  RE_MEN_HUA_TI,
  FU_MIAN_XIN_XI_LIE_BIAO,
  ZHANG_HAO_REN_ZHENG_FEN_BU,
  ZHANG_HAO_XING_BIE_FEN_BU,
  FA_WEN_XI_GUAN,
  WEI_BO_ZHUAN_FA_GUAN_XI,
  GUAN_ZHU_DIAN_FEN_XI,
  CHUAN_BO_GUO_JIA_LIANG,
  CHUAN_BO_YU_ZHONG_LIANG,
  MEI_TI_DENG_JI_FEN_BU,
  YING_XIANG_LI_QU_SHI,
  HUAN_BI_ZENG_ZHANG_LV,
  FA_BU_DI_YU_FEN_BU,
  TI_JI_DI_YU_FEN_BU,
  MEI_TI_BANG_DAN,
  RE_DIAN_BANG_DAN,
  // 作为组件id使用，从1自增，新增组件依次往下写，上面顺序不要改动
  DA_PING_BIAO_TI,
  MEI_TI_BAO_GUANG_QU_SHI_DUI_BI,
  XIN_XI_LIE_BIAO,
  YING_XIANG_LI_ZHI_SHU_BANG_DAN,
  SHENG_LIANG_ZHAN_BI,
  ZONG_SHENG_LIANG_QU_DAO_DUI_BI,
  DUO_ZHU_TI_QING_GAN_DUI_BI,
  HU_DONG_QU_SHI_DUI_BI,
  DA_PING_YE_MIAN,
  SHI_JIE_SHI_ZHONG,
  DAO_JI_SHI,
  HUO_YUE_ZHANG_HAO,
  XIN_XI_FEN_LEI
}

// 数据堆叠选项 无/情感类型/媒体渠道/数据类型
const stackTypeOption = [
  { label: t('message.analysisDetails.stackTypeOption[0]'), value: 0 },
  { label: t('message.analysisDetails.stackTypeOption[1]'), value: 3 },
  { label: t('message.analysisDetails.stackTypeOption[2]'), value: 7 },
  { label: t('message.analysisDetails.stackTypeOption[3]'), value: 9 }
];
// 数据查询维度选项  按小时/按天/按月
const dateIntervalOption = [
  { label: t('message.analysisDetails.dateIntervalOption[0]'), value: 'HOUR' },
  { label: t('message.analysisDetails.dateIntervalOption[0]'), value: 'DAY' },
  { label: t('message.analysisDetails.dateIntervalOption[0]'), value: 'MONTH' }
];
// 地图类型  热点地图（全球）/热点地图（境内）/热点地图（省级）
const mapTypeOption = [
  { label: t('message.analysisDetails.mapTypeOption[0]'), value: 'world' },
  { label: t('message.analysisDetails.mapTypeOption[1]'), value: 'china' },
  { label: t('message.analysisDetails.mapTypeOption[2]'), value: 'province' }
];

export const ComponentOptionMap = {
  [EComponent.GAI_SHU]: {
    id: EComponent.GAI_SHU,
    type: 'ASummarize',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.TextChartTypeOption[0]'),
        value: 'text'
      }
    ], // 文本数据
    params: {
      name: t('message.analysisComponents.informationOverviewComponents[0]'), // 概述
      chartType: 'text'
    }
  },
  [EComponent.YING_XIANG_LI_ZHI_SHU]: {
    id: EComponent.YING_XIANG_LI_ZHI_SHU,
    type: 'AInfluenceIndex',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.gaugeChartTypeOption[0]'),
        value: 'stage'
      }, // 多色仪表盘
      {
        label: t('message.analysisDetails.gaugeChartTypeOption[1]'),
        value: 'gradient-stage'
      }, // 多色渐变仪表盘
      {
        label: t('message.analysisDetails.gaugeChartTypeOption[2]'),
        value: 'default'
      }, // 单色仪表盘
      {
        label: t('message.analysisDetails.gaugeChartTypeOption[3]'),
        value: 'gradient'
      } // 单色渐变仪表盘
    ],
    params: {
      name: t('message.analysisComponents.informationOverviewComponents[1]'), // 影响力指数
      chartType: 'stage'
    }
  },
  [EComponent.YING_XIANG_LI_ZHI_SHU_BANG_DAN]: {
    id: EComponent.YING_XIANG_LI_ZHI_SHU_BANG_DAN,
    type: 'AInfluenceIndexList',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.TextChartTypeOption[1]'),
        value: 'default'
      }
    ], // 热点榜单
    params: {
      name: t('message.analysisComponents.informationOverviewComponents[2]'), // 影响力指数榜单
      chartType: 'default',
      schemeIdList: []
    }
  },
  [EComponent.QU_DAO_SHOU_FA]: {
    id: EComponent.QU_DAO_SHOU_FA,
    type: 'AChannelStart',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.TextChartTypeOption[2]'),
        value: 'timeline'
      }, // 传播时间轴
      {
        label: t('message.analysisDetails.TextChartTypeOption[3]'),
        value: 'list'
      } // 信息列表
    ],
    params: {
      name: t('message.analysisComponents.informationOverviewComponents[3]'), // 渠道首发
      chartType: 'timeline',
      sortInfo: {
        sortField: 1,
        sortWay: EsortWay.DESC
      },
      articleType: [1],
      titleRows: 2
    }
  },
  [EComponent.MEI_TI_BAO_GUANG_QU_SHI]: {
    id: EComponent.MEI_TI_BAO_GUANG_QU_SHI,
    type: 'AExposureTrend',
    chartTypeOption: [
      { label: t('message.analysisDetails.chartTypeOption[0]'), value: 'line' }, // 折线图
      { label: t('message.analysisDetails.chartTypeOption[1]'), value: 'bar' } // 柱状图
    ],
    stackTypeOption: stackTypeOption,
    dateIntervalOption: dateIntervalOption,
    mediaTagsOption: mediaTagsOption,
    params: {
      name: t('message.analysisComponents.disseminationTrendComponents[0]'), // 媒体曝光趋势
      chartType: 'line',
      stackType: 7,
      dateInterval: 'DAY',
      mediaTags: []
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.QU_DAO_SHENG_LIANG_FEN_XI]: {
    id: EComponent.QU_DAO_SHENG_LIANG_FEN_XI,
    type: 'AChannelVolume',
    chartTypeOption: [
      { label: t('message.analysisDetails.chartTypeOption[2]'), value: 'pie' }, // 饼图
      { label: t('message.analysisDetails.chartTypeOption[1]'), value: 'bar' } // 柱状图
    ],
    stackTypeOption: stackTypeOption.filter(item => {
      return item.value !== 7;
    }),
    params: {
      name: t('message.analysisComponents.disseminationTrendComponents[2]'), // 渠道声量分析
      chartType: 'pie',
      stackType: 0
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.JIE_DUAN_YAN_HUA]: {
    id: EComponent.JIE_DUAN_YAN_HUA,
    chartTypeOption: [
      { label: t('message.analysisDetails.chartTypeOption[3]'), value: 'area' }
    ], // '演变面积图'
    type: 'AEvolution',
    params: {
      name: t('message.analysisComponents.disseminationTrendComponents[5]'), // 阶段演化
      chartType: 'area',
      dateInterval: 'DAY'
    },
    drilling: isMobile() ? false : true
  },

  [EComponent.HUO_YUE_ZHAN_DIAN]: {
    id: EComponent.HUO_YUE_ZHAN_DIAN,
    type: 'AAccountList',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.chartTypeOption[4]'),
        value: 'bar_horizontal'
      }
    ], // 横向条形图
    stackTypeOption: stackTypeOption,
    hotSizeOption: hotSizeOption,
    params: {
      name: t('message.analysisComponents.hotspotInformationComponents[0]'), // 活跃榜单
      chartType: 'bar_horizontal',
      size: 10
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.XIN_XI_FEN_LEI]: {
    id: EComponent.XIN_XI_FEN_LEI,
    type: 'AInformationList',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.chartTypeOption[4]'),
        value: 'bar_horizontal'
      }
    ], // 横向条形图
    stackTypeOption: stackTypeOption,
    hotSizeOption: hotSizeOption,
    params: {
      name: t('message.analysisComponents.hotspotInformationComponents[2]'), // 信息内容分类
      chartType: 'bar_horizontal',
      size: 10
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.HUO_YUE_ZHANG_HAO]: {
    id: EComponent.HUO_YUE_ZHANG_HAO,
    type: 'AActiveAccount',
    chartTypeOption: [
      {
        label: t('message.analysisComponents.hotspotInformationComponents[3]'),
        value: 'bar_horizontal'
      }
    ],
    stackTypeOption: stackTypeOption,
    hotSizeOption: hotSizeOption,
    params: {
      name: t('message.analysisComponents.hotspotInformationComponents[3]'),
      chartType: 'bar_horizontal',
      size: 50
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.RE_DIAN_WEN_ZHANG]: {
    id: EComponent.RE_DIAN_WEN_ZHANG,
    type: 'AHotArticle',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.TextChartTypeOption[3]'),
        value: 'list'
      }
    ], // 信息列表
    hotSizeOption: hotSizeOption,
    sortFieldOption: sortFieldOption.filter(item => {
      return [2, 3, 4, 5, 6].includes(item.value);
    }),
    params: {
      name: t('message.analysisComponents.hotspotInformationComponents[1]'), // 热点文章
      chartType: 'list',
      size: 10,
      sortInfo: {
        sortField: 5,
        sortWay: EsortWay.DESC
      },
      titleRows: 2,
      autoScroll: true,
      autoScrollStep: 1
    },
    drilling: isMobile() ? false : true,
    drillingHideSelf: true
  },
  [EComponent.RE_DIAN_CI_YU]: {
    id: EComponent.RE_DIAN_CI_YU,
    type: 'AHotWord',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.wordCloudTypeOption[0]'),
        value: 'default'
      }, // 圆形词云图
      {
        label: t('message.analysisDetails.wordCloudTypeOption[1]'),
        value: 'rect'
      }, // 矩形词云图
      {
        label: t('message.analysisDetails.wordCloudTypeOption[2]'),
        value: 'tree'
      }, // 树形词云图
      {
        label: t('message.analysisDetails.wordCloudTypeOption[3]'),
        value: 'map'
      }
    ], // 词云地图
    params: {
      name: t('message.analysisComponents.informationExtractionComponents[0]'), // 热点词语
      chartType: 'default'
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true,
    privateDataSetting: true
  },
  [EComponent.RE_DIAN_ZHU_TI]: {
    id: EComponent.RE_DIAN_ZHU_TI,
    type: 'AHotTopic',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.TextChartTypeOption[1]'),
        value: 'hotlist'
      }
    ], // 热点榜单
    sortFieldOption: sortFieldOption.filter(item => {
      return item.value === 7;
    }),
    hotSizeOption: hotSizeOption,
    params: {
      name: t('message.analysisComponents.informationExtractionComponents[2]'), // 热点主题
      chartType: 'hotlist',
      size: 10,
      sortInfo: {
        sortField: 7,
        sortWay: EsortWay.DESC
      }
    }
    // drilling: isMobile() ? false : true,
  },
  [EComponent.ZHUAN_JIA_GUAN_DIAN]: {
    id: EComponent.ZHUAN_JIA_GUAN_DIAN,
    type: 'AExpertOpinion',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.chartTypeOption[5]'),
        value: 'bar_horizontal'
      }, // 圆角条形图
      {
        label: t('message.analysisDetails.TextChartTypeOption[1]'),
        value: 'hotlist'
      } // 热点榜单
    ],
    sortFieldOption: sortFieldOption.filter(item => {
      return item.value === 8;
    }),
    params: {
      name: t('message.analysisComponents.informationExtractionComponents[7]'), // 专家观点
      chartType: 'bar_horizontal',
      size: 10,
      sortInfo: {
        sortField: 8,
        sortWay: EsortWay.DESC
      },
      titleName: t('message.analysisDetails.expertOpinionHeaders[1]'), // 专家观点
      valueName: t('message.analysisDetails.expertOpinionHeaders[2]') // 信息量
    },
    drilling: isMobile() ? false : true
  },
  [EComponent.WEI_BO_WANG_MIN_GUAN_DIAN]: {
    id: EComponent.WEI_BO_WANG_MIN_GUAN_DIAN,
    type: 'ANetizenOpinion',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.chartTypeOption[5]'),
        value: 'bar_horizontal'
      }, // 圆角条形图
      {
        label: t('message.analysisDetails.TextChartTypeOption[1]'),
        value: 'hotlist'
      } // 热点榜单
    ],
    sortFieldOption: sortFieldOption.filter(item => {
      return item.value === 8;
    }),
    params: {
      name: t('message.analysisComponents.informationExtractionComponents[8]'), // 微博网民观点
      chartType: 'bar_horizontal',
      dataSource: [
        {
          label: '微博',
          type: 0,
          value: '00301',
          children: null
        }
      ],
      dataSourceUpdated: true,
      size: 10,
      sortInfo: {
        sortField: 8,
        sortWay: EsortWay.DESC
      },
      titleName: t('message.analysisDetails.netizenOpinionHeaders[1]'), // 网民观点
      valueName: t('message.analysisDetails.netizenOpinionHeaders[2]'), // 信息量
      articleType: [2, 3]
    },
    drilling: isMobile() ? false : true
  },
  [EComponent.XIN_XI_GAI_LAN]: {
    id: EComponent.XIN_XI_GAI_LAN,
    type: 'AInfoOverview',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.TextChartTypeOption[4]'),
        value: 'overviewCart'
      }
    ], //指标卡
    params: {
      name: t('message.analysisComponents.informationOverviewComponents[4]'), // 信息概览
      chartType: 'overviewCart'
    }
  },
  [EComponent.HU_DONG_GAI_LAN]: {
    id: EComponent.HU_DONG_GAI_LAN,
    type: 'AInteractionOverview',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.TextChartTypeOption[4]'),
        value: 'overviewCart'
      }
    ], //指标卡
    params: {
      name: t('message.analysisComponents.informationOverviewComponents[5]'), // 互动概览
      chartType: 'overviewCart'
    }
  },
  [EComponent.HU_DONG_QU_SHI]: {
    id: EComponent.HU_DONG_QU_SHI,
    type: 'AInteractionTrend',
    chartTypeOption: [
      { label: t('message.analysisDetails.chartTypeOption[0]'), value: 'line' }
    ], // 折线图
    interactiveTypeOption: interactiveTypeOption,
    params: {
      name: t('message.analysisComponents.disseminationTrendComponents[3]'), // 互动趋势
      chartType: 'line',
      interactiveType: 36
    },
    // drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.HU_DONG_QU_SHI_DUI_BI]: {
    id: EComponent.HU_DONG_QU_SHI_DUI_BI,
    type: 'AInteractionTrendCompare',
    chartTypeOption: [
      { label: t('message.analysisDetails.chartTypeOption[0]'), value: 'line' }
    ], // 折线图
    interactiveTypeOption: interactiveTypeOption,
    params: {
      name: t('message.analysisComponents.disseminationTrendComponents[4]'), // 互动趋势对比
      chartType: 'line',
      interactiveType: 10,
      schemeIdList: []
    },
    // drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  // v0.7新增↓
  [EComponent.RE_DIAN_DUAN_YU]: {
    id: EComponent.RE_DIAN_DUAN_YU,
    type: 'AHotPhrase',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.wordCloudTypeOption[1]'),
        value: 'rect'
      }, // 矩形词云图
      {
        label: t('message.analysisDetails.wordCloudTypeOption[4]'),
        value: 'topic'
      } // 话题词云图
    ],
    params: {
      name: t('message.analysisComponents.informationExtractionComponents[1]'), // 热点短语
      chartType: 'rect'
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.DI_YU_FEN_XI]: {
    id: EComponent.DI_YU_FEN_XI,
    type: '',
    params: {
      name: '地域分析'
    }
  },
  [EComponent.CHUAN_BO_LU_JING]: {
    id: EComponent.CHUAN_BO_LU_JING,
    type: 'APropagationPath',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.TextChartTypeOption[3]'),
        value: 'list'
      }
    ], // 信息列表
    params: {
      name: t('message.analysisComponents.disseminationPathComponents[0]'), // 传播路径
      chartType: 'list',
      isTrace: 1
    }
  },
  [EComponent.QING_GAN_QING_XIANG]: {
    id: EComponent.QING_GAN_QING_XIANG,
    type: 'AEmotionalTendency',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.chartTypeOption[6]'),
        value: 'scale'
      }, // 比例折线图
      { label: t('message.analysisDetails.chartTypeOption[0]'), value: 'line' }, // 折线图
      { label: t('message.analysisDetails.chartTypeOption[2]'), value: 'pie' } // 饼图
    ],
    params: {
      name: t('message.analysisComponents.disseminationTrendComponents[6]'), // 情感倾向
      chartType: 'scale'
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.RE_MEN_REN_WU]: {
    id: EComponent.RE_MEN_REN_WU,
    type: 'APopularPeople',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.chartTypeOption[7]'),
        value: 'bubble'
      }, // 气泡图
      { label: t('message.analysisDetails.chartTypeOption[2]'), value: 'pie' }, // 饼图
      { label: t('message.analysisDetails.chartTypeOption[1]'), value: 'bar' } // 柱状图
    ],
    params: {
      name: t('message.analysisComponents.informationExtractionComponents[4]'), // 热门人物
      chartType: 'bubble'
    },
    drilling: isMobile() ? false : true
  },
  [EComponent.RE_MEN_ZU_ZHI]: {
    id: EComponent.RE_MEN_ZU_ZHI,
    chartTypeOption: [
      { label: t('message.analysisDetails.chartTypeOption[8]'), value: 'area' }, // 面积图
      { label: t('message.analysisDetails.chartTypeOption[2]'), value: 'pie' }, // 饼图
      { label: t('message.analysisDetails.chartTypeOption[1]'), value: 'bar' } // 柱状图
    ],
    type: 'APopularOrganization',
    params: {
      name: t('message.analysisComponents.informationExtractionComponents[5]'), // 热门组织
      chartType: 'area'
    },
    drilling: isMobile() ? false : true
  },
  [EComponent.RE_MEN_HUA_TI]: {
    id: EComponent.RE_MEN_HUA_TI,
    type: 'APopularTopic',
    params: {
      name: t('message.analysisComponents.informationExtractionComponents[6]'), // 热门话题
      chartType: 'default'
    }
    // drilling: isMobile() ? false : true,
  },
  [EComponent.FU_MIAN_XIN_XI_LIE_BIAO]: {
    id: EComponent.FU_MIAN_XIN_XI_LIE_BIAO,
    type: 'ANegativeInfoList',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.TextChartTypeOption[3]'),
        value: 'list'
      }
    ], // 信息列表
    hotSizeOption: hotSizeOption,
    sortFieldOption: sortFieldOption.filter(item => {
      return [2, 3, 4, 5, 6].includes(item.value);
    }),
    params: {
      name: t('message.analysisComponents.informationOverviewComponents[6]'), // 负面信息列表
      chartType: 'list',
      size: 10,
      sortInfo: {
        sortField: 2,
        sortWay: EsortWay.DESC
      }
    }
  },
  [EComponent.ZHANG_HAO_REN_ZHENG_FEN_BU]: {
    id: EComponent.ZHANG_HAO_REN_ZHENG_FEN_BU,
    type: 'AAuthentication',
    chartTypeOption: [
      { label: t('message.analysisDetails.chartTypeOption[2]'), value: 'pie' }
    ], // 饼图
    params: {
      name: t('message.analysisComponents.mediaAnalysisComponents[0]'), // 账号认证分布
      chartType: 'pie'
    },
    drilling: isMobile() ? false : true
  },
  [EComponent.ZHANG_HAO_XING_BIE_FEN_BU]: {
    id: EComponent.ZHANG_HAO_XING_BIE_FEN_BU,
    type: 'ASexDistribution',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.chartTypeOption[4]'),
        value: 'butterfly_bar'
      }
    ], // 横向条形图
    params: {
      name: t('message.analysisComponents.mediaAnalysisComponents[1]'), // 账号性别分布
      chartType: 'butterfly_bar'
    },
    drilling: isMobile() ? false : true
  },
  [EComponent.FA_WEN_XI_GUAN]: {
    id: EComponent.FA_WEN_XI_GUAN,
    chartTypeOption: [
      { label: t('message.analysisDetails.chartTypeOption[1]'), value: 'bar' }
    ], // 柱状图
    type: 'AWritingHabit',
    params: {
      name: t('message.analysisComponents.mediaAnalysisComponents[3]'), // 发文习惯
      chartType: 'bar'
    },
    layoutCopy: true
  },
  [EComponent.WEI_BO_ZHUAN_FA_GUAN_XI]: {
    id: EComponent.WEI_BO_ZHUAN_FA_GUAN_XI,
    chartTypeOption: [
      {
        label: t('message.analysisDetails.chartTypeOption[9]'),
        value: 'relationalGraph'
      }
    ], // 关系图谱
    type: 'AWeiboForwarding',
    params: {
      name: t('message.analysisComponents.disseminationPathComponents[1]'), // 微博转发关系
      chartType: 'relationalGraph',
      dataSource: [
        {
          label: '微博',
          type: 0,
          value: '00301',
          children: null
        }
      ],
      dataSourceUpdated: true
    }
  },
  [EComponent.GUAN_ZHU_DIAN_FEN_XI]: {
    id: EComponent.GUAN_ZHU_DIAN_FEN_XI,
    type: '',
    params: {
      name: '关注点分析'
    }
  },
  [EComponent.CHUAN_BO_GUO_JIA_LIANG]: {
    id: EComponent.CHUAN_BO_GUO_JIA_LIANG,
    type: 'APropagationCountry',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.TextChartTypeOption[5]'),
        value: 'propagationCapacity'
      }
    ], // 传播量
    params: {
      name: t('message.analysisComponents.informationOverviewComponents[7]'), // 传播国家量
      chartType: 'propagationCapacity',
      size: 10
    }
  },
  [EComponent.CHUAN_BO_YU_ZHONG_LIANG]: {
    id: EComponent.CHUAN_BO_YU_ZHONG_LIANG,
    type: 'APropagationLanguage',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.TextChartTypeOption[5]'),
        value: 'propagationCapacity'
      }
    ], // 传播量
    params: {
      name: t('message.analysisComponents.informationOverviewComponents[8]'), // 传播语种量
      chartType: 'propagationCapacity',
      size: 10
    }
  },
  [EComponent.MEI_TI_DENG_JI_FEN_BU]: {
    id: EComponent.MEI_TI_DENG_JI_FEN_BU,
    type: 'ARankDistribution',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.chartTypeOption[10]'),
        value: 'radar'
      }
    ], // 雷达图
    params: {
      name: t('message.analysisComponents.mediaAnalysisComponents[2]'), // 媒体等级分布
      chartType: 'radar'
    }
  },
  [EComponent.YING_XIANG_LI_QU_SHI]: {
    id: EComponent.YING_XIANG_LI_QU_SHI,
    type: '',
    params: {
      name: '影响力趋势'
    }
  },
  [EComponent.HUAN_BI_ZENG_ZHANG_LV]: {
    id: EComponent.HUAN_BI_ZENG_ZHANG_LV,
    type: '',
    params: {
      name: '环比增长率'
    }
  },
  [EComponent.FA_BU_DI_YU_FEN_BU]: {
    id: EComponent.FA_BU_DI_YU_FEN_BU,
    type: 'APublishMap',
    chartTypeOption: mapTypeOption.filter(item => {
      return item.value != 'province';
    }),
    params: {
      name: t('message.analysisComponents.disseminationRegionsComponents[0]'), // 发布地域分布
      chartType: 'china',
      detectionType: 0,
      dataType: 0
      // hideTitle: true,
    },
    // drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.TI_JI_DI_YU_FEN_BU]: {
    id: EComponent.TI_JI_DI_YU_FEN_BU,
    type: 'AMentionMap',
    chartTypeOption: mapTypeOption.filter(item => {
      return item.value != 'world';
    }),
    params: {
      name: t('message.analysisComponents.disseminationRegionsComponents[1]'), // 提及地域分布
      chartType: 'china',
      province: '',
      detectionType: 1,
      dataType: 0
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.RE_DIAN_BANG_DAN]: {
    id: EComponent.RE_DIAN_BANG_DAN,
    type: 'AHotList',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.chartTypeOption[6]'),
        value: 'hotlist'
      }
    ], // 排行榜
    params: {
      name: t('message.analysisComponents.informationExtractionComponents[3]'), // 热点榜单
      chartType: 'hotlist',
      type: 1
    }
  },
  [EComponent.MEI_TI_BANG_DAN]: {
    id: EComponent.MEI_TI_BANG_DAN,
    type: 'AMediaList',
    chartTypeOption: [
      { label: t('message.analysisDetails.chartTypeOption[6]'), value: 'list' }
    ], // 排行榜
    params: {
      name: t('message.analysisComponents.mediaAnalysisComponents[4]'), // 媒体榜单
      chartType: 'list',
      realTime: 1, //最多查询近七天，给定默认realTime，就不会同步搜索的配置了
      mediaAccountList: []
    }
  },
  [EComponent.DA_PING_BIAO_TI]: {
    id: EComponent.DA_PING_BIAO_TI,
    type: 'ABigScreenTitle',
    params: {
      name: t('message.analysisComponents.staticComponents[0]'), // 大屏标题
      hideTitle: true
    }
  },
  [EComponent.DA_PING_YE_MIAN]: {
    id: EComponent.DA_PING_YE_MIAN,
    type: 'ABigScreenPage',
    params: {
      name: t('message.analysisComponents.overallComponent[0]'), // 大屏页面
      hideTitle: true
    }
  },
  [EComponent.MEI_TI_BAO_GUANG_QU_SHI_DUI_BI]: {
    id: EComponent.MEI_TI_BAO_GUANG_QU_SHI_DUI_BI,
    type: 'AExposureTrendComparison',
    chartTypeOption: [
      { label: t('message.analysisDetails.chartTypeOption[0]'), value: 'line' }
    ], // 折线图
    dateIntervalOption: dateIntervalOption,
    params: {
      name: t('message.analysisComponents.disseminationTrendComponents[1]'), // 媒体曝光趋势对比
      chartType: 'line',
      dateInterval: 'DAY',
      schemeIdList: []
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.XIN_XI_LIE_BIAO]: {
    id: EComponent.XIN_XI_LIE_BIAO,
    type: 'AInfoList',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.TextChartTypeOption[3]'),
        value: 'list'
      }
    ], // 信息列表
    hotSizeOption: hotSizeOption,
    sortFieldOption: sortFieldOption.filter(item => {
      return [12, 1].includes(item.value);
    }),
    params: {
      name: t('message.analysisComponents.informationOverviewComponents[9]'), // 信息列表
      chartType: 'list',
      size: 10,
      sortInfo: {
        sortField: 12,
        sortWay: EsortWay.DESC
      },
      autoScroll: true,
      autoScrollStep: 1,
      titleRows: 2
    },
    drilling: isMobile() ? false : true,
    drillingHideSelf: true
  },
  [EComponent.SHENG_LIANG_ZHAN_BI]: {
    id: EComponent.SHENG_LIANG_ZHAN_BI,
    type: 'AVolumeRatio',
    chartTypeOption: [
      { label: t('message.analysisDetails.chartTypeOption[2]'), value: 'pie' }
    ], //饼图
    params: {
      name: t('message.analysisComponents.disseminationTrendComponents[7]'), // 声量占比
      chartType: 'pie',
      schemeIdList: []
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.ZONG_SHENG_LIANG_QU_DAO_DUI_BI]: {
    id: EComponent.ZONG_SHENG_LIANG_QU_DAO_DUI_BI,
    type: 'AChannelVolumeCompare',
    chartTypeOption: [
      {
        label: t('message.analysisDetails.chartTypeOption[4]'),
        value: 'bar_horizontal'
      }
    ], // 横向条形图
    params: {
      name: t('message.analysisComponents.disseminationTrendComponents[8]'), // 总声量渠道对比
      chartType: 'bar_horizontal',
      schemeIdList: []
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.DUO_ZHU_TI_QING_GAN_DUI_BI]: {
    id: EComponent.DUO_ZHU_TI_QING_GAN_DUI_BI,
    type: 'AEmotionCompare',
    chartTypeOption: [
      { label: t('message.analysisDetails.chartTypeOption[1]'), value: 'bar' }
    ], //柱状图
    params: {
      name: t('message.analysisComponents.disseminationTrendComponents[9]'), // 多主题情感对比
      chartType: 'bar',
      schemeIdList: []
    },
    drilling: isMobile() ? false : true,
    layoutCopy: true
  },
  [EComponent.SHI_JIE_SHI_ZHONG]: {
    id: EComponent.SHI_JIE_SHI_ZHONG,
    type: 'AWorldClock',
    chartTypeOption: [{ label: '时间信息', value: 'default' }],
    params: {
      name: t('message.analysisComponents.timeComponents[0]'),
      chartType: 'default',
      clockList: [156]
    }
  },
  [EComponent.DAO_JI_SHI]: {
    id: EComponent.DAO_JI_SHI,
    type: 'ACountdown',
    chartTypeOption: [
      {
        label: t('message.analysisComponents.timeComponents[0]'),
        value: 'default'
      }
    ],
    params: {
      name: t('message.analysisComponents.timeComponents[0]'),
      chartType: 'default',
      countdownLabel: t('message.analysisComponents.timeComponents[0]'),
      countdownEndtime: '',
      countdownUnit: 'second'
    }
  }
};
export const getBaseConfig = (compType: EComponent) => {
  // 组件基本属性
  const { id, type, params } = deepClone(ComponentOptionMap[compType] || {});
  return {
    id,
    type,
    data: params
  } as { id: number; type: string; data: IPrivateSettingParams };
};

// 静态组件
export const staticComponents: IConfig[] = [
  {
    name: t('message.analysisComponents.staticComponents[0]'), // 大屏标题
    icon: 'icon-35',
    w: 12,
    h: 3,
    mw: 6,
    mh: 3,
    ...getBaseConfig(EComponent.DA_PING_BIAO_TI),
    usedPage: ['bigscreen'],
    bigscreenConfig: {
      w: 1,
      h: 65
    }
  }
];
// 全局组件
export const globalComponents: IConfig[] = [
  {
    name: t('message.analysisComponents.overallComponent[0]'), // 大屏页面
    icon: 'icon-36',
    w: 3,
    h: 3,
    mw: 3,
    mh: 3,
    ...getBaseConfig(EComponent.DA_PING_YE_MIAN),
    usedPage: ['bigscreen'],
    bigscreenConfig: {
      w: 0.1,
      h: 40,
      isGlobal: true
    }
  }
];

export const library: Ilibrary[] = [
  {
    groupName: t('message.analysisComponents.groupName[1]'), // 信息概览
    components: [
      {
        name: t('message.analysisComponents.informationOverviewComponents[0]'), // 概述
        icon: 'icon-6',
        w: 12,
        h: 3,
        mw: 6,
        mh: 3,
        ...getBaseConfig(EComponent.GAI_SHU),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.informationOverviewComponents[1]'), // 影响力指数
        icon: 'icon-10',
        w: 4,
        h: 6,
        mw: 4,
        mh: 6,
        ...getBaseConfig(EComponent.YING_XIANG_LI_ZHI_SHU),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.informationOverviewComponents[2]'), // 影响力指数榜单
        icon: 'icon-13',
        w: 6,
        h: 5,
        mw: 6,
        mh: 5,
        ...getBaseConfig(EComponent.YING_XIANG_LI_ZHI_SHU_BANG_DAN),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.informationOverviewComponents[3]'), // 渠道首发
        icon: 'icon-3',
        w: 4,
        h: 9,
        mw: 4,
        mh: 9,
        ...getBaseConfig(EComponent.QU_DAO_SHOU_FA),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.informationOverviewComponents[4]'), // 信息概览
        icon: 'icon-18',
        w: 6,
        h: 6,
        mw: 6,
        mh: 6,
        ...getBaseConfig(EComponent.XIN_XI_GAI_LAN),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.informationOverviewComponents[5]'), // 互动概览
        icon: 'icon-19',
        w: 6,
        h: 6,
        mw: 6,
        mh: 6,
        ...getBaseConfig(EComponent.HU_DONG_GAI_LAN),
        usedPage: ['analysis', 'bigscreen']
      },
      // {
      // 	name: t('message.analysisComponents.informationOverviewComponents[6]'), // 负面信息列表
      // 	icon: 'icon-8',
      // 	w: 4,
      // 	h: 9,
      // 	mw: 4,
      // 	mh: 9,
      // 	...getBaseConfig(EComponent.FU_MIAN_XIN_XI_LIE_BIAO)
      // },
      {
        name: t('message.analysisComponents.informationOverviewComponents[7]'), // 传播国家量
        icon: 'icon-31',
        w: 6,
        h: 3,
        mw: 6,
        mh: 3,
        ...getBaseConfig(EComponent.CHUAN_BO_GUO_JIA_LIANG),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.informationOverviewComponents[8]'), // 传播语种量
        icon: 'icon-31',
        w: 6,
        h: 3,
        mw: 6,
        mh: 3,
        ...getBaseConfig(EComponent.CHUAN_BO_YU_ZHONG_LIANG),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.informationOverviewComponents[9]'), // 信息列表
        icon: 'icon-8',
        w: 4,
        h: 9,
        mw: 4,
        mh: 9,
        ...getBaseConfig(EComponent.XIN_XI_LIE_BIAO),
        usedPage: ['analysis', 'bigscreen']
      }
    ]
  },
  {
    groupName: t('message.analysisComponents.groupName[2]'), // 热点信息
    components: [
      {
        name: t('message.analysisComponents.hotspotInformationComponents[0]'), // 活跃榜单（1.0.10更名为活跃站点）
        icon: 'icon-8',
        w: 4,
        h: 9,
        mw: 4,
        mh: 9,
        ...getBaseConfig(EComponent.HUO_YUE_ZHAN_DIAN),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.hotspotInformationComponents[3]'),
        icon: 'icon-8',
        w: 4,
        h: 9,
        mw: 4,
        mh: 9,
        ...getBaseConfig(EComponent.HUO_YUE_ZHANG_HAO),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.hotspotInformationComponents[1]'), // 热点文章
        icon: 'icon-8',
        w: 4,
        h: 9,
        mw: 4,
        mh: 9,
        ...getBaseConfig(EComponent.RE_DIAN_WEN_ZHANG),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.hotspotInformationComponents[2]'), // 信息内容分类
        icon: 'icon-8',
        w: 4,
        h: 9,
        mw: 4,
        mh: 9,
        ...getBaseConfig(EComponent.XIN_XI_FEN_LEI),
        usedPage: ['analysis', 'bigscreen']
      }
    ]
  },
  {
    groupName: t('message.analysisComponents.groupName[3]'), // 传播趋势
    components: [
      {
        name: t('message.analysisComponents.disseminationTrendComponents[0]'), // 媒体曝光趋势
        icon: 'icon-4',
        w: 4,
        h: 6,
        mw: 4,
        mh: 6,
        ...getBaseConfig(EComponent.MEI_TI_BAO_GUANG_QU_SHI),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.disseminationTrendComponents[1]'), // 媒体曝光趋势对比
        icon: 'icon-4',
        w: 6,
        h: 6,
        mw: 6,
        mh: 6,
        ...getBaseConfig(EComponent.MEI_TI_BAO_GUANG_QU_SHI_DUI_BI),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.disseminationTrendComponents[2]'), // 渠道声量分析
        icon: 'icon-1',
        w: 4,
        h: 6,
        mw: 4,
        mh: 6,
        ...getBaseConfig(EComponent.QU_DAO_SHENG_LIANG_FEN_XI),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.disseminationTrendComponents[3]'), // 互动趋势
        icon: 'icon-20',
        w: 6,
        h: 6,
        mw: 6,
        mh: 6,
        ...getBaseConfig(EComponent.HU_DONG_QU_SHI),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.disseminationTrendComponents[4]'), // 互动趋势对比
        icon: 'icon-20',
        w: 6,
        h: 6,
        mw: 6,
        mh: 6,
        ...getBaseConfig(EComponent.HU_DONG_QU_SHI_DUI_BI),
        usedPage: ['analysis']
      },
      {
        name: t('message.analysisComponents.disseminationTrendComponents[5]'), // 阶段演化
        icon: 'icon-7',
        w: 12,
        h: 4,
        mw: 6,
        mh: 4,
        ...getBaseConfig(EComponent.JIE_DUAN_YAN_HUA),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.disseminationTrendComponents[6]'), // 情感倾向
        icon: 'icon-25',
        w: 6,
        h: 6,
        mw: 4,
        mh: 6,
        ...getBaseConfig(EComponent.QING_GAN_QING_XIANG),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.disseminationTrendComponents[7]'), // 声量占比
        icon: 'icon-1',
        w: 4,
        h: 6,
        mw: 4,
        mh: 6,
        ...getBaseConfig(EComponent.SHENG_LIANG_ZHAN_BI),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.disseminationTrendComponents[8]'), // 总声量渠道对比
        icon: 'icon-33',
        w: 6,
        h: 6,
        mw: 6,
        mh: 6,
        ...getBaseConfig(EComponent.ZONG_SHENG_LIANG_QU_DAO_DUI_BI),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.disseminationTrendComponents[9]'), // 多主题情感对比
        icon: 'icon-11',
        w: 6,
        h: 6,
        mw: 6,
        mh: 6,
        ...getBaseConfig(EComponent.DUO_ZHU_TI_QING_GAN_DUI_BI),
        usedPage: ['analysis', 'bigscreen']
      }
    ]
  },
  {
    groupName: t('message.analysisComponents.groupName[4]'), // 信息提取
    components: [
      {
        name: t(
          'message.analysisComponents.informationExtractionComponents[0]'
        ), // 热点词语
        icon: 'icon-17',
        w: 6,
        h: 8,
        mw: 6,
        mh: 8,
        // tools: { zoom: 1 },
        ...getBaseConfig(EComponent.RE_DIAN_CI_YU),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t(
          'message.analysisComponents.informationExtractionComponents[1]'
        ), // 热点短语
        icon: 'icon-21',
        w: 6,
        h: 8,
        mw: 6,
        mh: 8,
        ...getBaseConfig(EComponent.RE_DIAN_DUAN_YU),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t(
          'message.analysisComponents.informationExtractionComponents[2]'
        ), // 热点主题
        icon: 'icon-12',
        w: 4,
        h: 6,
        mw: 4,
        mh: 6,
        ...getBaseConfig(EComponent.RE_DIAN_ZHU_TI),
        usedPage: ['analysis', 'bigscreen']
      },
      // {
      // 	name: t('message.analysisComponents.informationExtractionComponents[3]'), // 热点榜单
      // 	icon: 'icon-13',
      // 	w: 12,
      // 	h: 10,
      // 	mw: 12,
      // 	mh: 10,
      // 	...getBaseConfig(EComponent.RE_DIAN_BANG_DAN),
      // 	usedPage: ['analysis', 'bigscreen'],
      // },
      {
        name: t(
          'message.analysisComponents.informationExtractionComponents[4]'
        ), // 热门人物
        icon: 'icon-26',
        w: 6,
        h: 8,
        mw: 6,
        mh: 8,
        ...getBaseConfig(EComponent.RE_MEN_REN_WU),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t(
          'message.analysisComponents.informationExtractionComponents[5]'
        ), // 热门组织
        icon: 'icon-27',
        w: 6,
        h: 8,
        mw: 6,
        mh: 8,
        ...getBaseConfig(EComponent.RE_MEN_ZU_ZHI),
        usedPage: ['analysis', 'bigscreen']
      },
      // {
      // 	name: t('message.analysisComponents.informationExtractionComponents[6]'), // 热门话题
      // 	icon: 'icon-28',
      // 	w: 6,
      // 	h: 8,
      // 	mw: 6,
      // 	mh: 8,
      // 	...getBaseConfig(EComponent.RE_MEN_HUA_TI),
      // 	// disabled: true,
      // 	usedPage: ['analysis'],
      // },
      {
        name: t(
          'message.analysisComponents.informationExtractionComponents[7]'
        ), // 专家观点
        icon: 'icon-33',
        w: 4,
        h: 9,
        mw: 4,
        mh: 9,
        ...getBaseConfig(EComponent.ZHUAN_JIA_GUAN_DIAN),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t(
          'message.analysisComponents.informationExtractionComponents[8]'
        ), // 微博网民观点
        icon: 'icon-11',
        w: 4,
        h: 9,
        mw: 4,
        mh: 9,
        ...getBaseConfig(EComponent.WEI_BO_WANG_MIN_GUAN_DIAN),
        // disabled: true,
        usedPage: ['analysis', 'bigscreen']
      }
    ]
  },
  {
    groupName: t('message.analysisComponents.groupName[5]'), // 传播路径
    components: [
      // {
      // 	name: t('message.analysisComponents.disseminationPathComponents[0]'), // 传播路径
      // 	icon: 'icon-24',
      // 	w: 6,
      // 	h: 9,
      // 	mw: 6,
      // 	mh: 9,
      // 	...getBaseConfig(EComponent.CHUAN_BO_LU_JING),
      // 	usedPage: ['analysis', 'bigscreen'],
      // },
      // {
      // 	name: t('message.analysisComponents.disseminationPathComponents[1]'), // 微博转发关系
      // 	icon: 'icon-30',
      // 	w: 4,
      // 	h: 6,
      // 	mw: 4,
      // 	mh: 6,
      // 	...getBaseConfig(EComponent.WEI_BO_ZHUAN_FA_GUAN_XI),
      // 	usedPage: ['analysis'],
      // }
    ]
  },
  {
    groupName: t('message.analysisComponents.groupName[6]'), // 传播地域
    components: [
      {
        name: t('message.analysisComponents.disseminationRegionsComponents[0]'), // 发布地域分布
        icon: 'icon-22',
        w: 12,
        h: 6,
        mw: 6,
        mh: 3,
        ...getBaseConfig(EComponent.FA_BU_DI_YU_FEN_BU),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.disseminationRegionsComponents[1]'), // 提及地域分布
        icon: 'icon-22',
        w: 12,
        h: 6,
        mw: 6,
        mh: 3,
        ...getBaseConfig(EComponent.TI_JI_DI_YU_FEN_BU),
        usedPage: ['analysis', 'bigscreen']
      }
    ]
  },
  {
    groupName: t('message.analysisComponents.groupName[7]'), // 媒体分析
    components: [
      {
        name: t('message.analysisComponents.mediaAnalysisComponents[0]'), // 账号认证分布
        icon: 'icon-1',
        w: 6,
        h: 6,
        mw: 4,
        mh: 6,
        ...getBaseConfig(EComponent.ZHANG_HAO_REN_ZHENG_FEN_BU),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.mediaAnalysisComponents[1]'), // 账号性别分布
        icon: 'icon-34',
        w: 6,
        h: 6,
        mw: 4,
        mh: 4,
        ...getBaseConfig(EComponent.ZHANG_HAO_XING_BIE_FEN_BU),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.mediaAnalysisComponents[2]'), // 媒体等级分布
        icon: 'icon-32',
        w: 6,
        h: 6,
        mw: 6,
        mh: 6,
        ...getBaseConfig(EComponent.MEI_TI_DENG_JI_FEN_BU),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.mediaAnalysisComponents[3]'), // 发文习惯
        icon: 'icon-29',
        w: 6,
        h: 6,
        mw: 4,
        mh: 6,
        ...getBaseConfig(EComponent.FA_WEN_XI_GUAN),
        usedPage: ['analysis', 'bigscreen']
      },
      {
        name: t('message.analysisComponents.mediaAnalysisComponents[4]'), // 媒体榜单
        icon: 'icon-8',
        w: 12,
        h: 10,
        mw: 6,
        mh: 10,
        ...getBaseConfig(EComponent.MEI_TI_BANG_DAN),
        usedPage: ['analysis']
      }
    ]
  },
  {
    groupName: t('message.analysisComponents.groupName[8]'), // 时间组件
    components: [
      {
        name: t('message.analysisComponents.timeComponents[0]'),
        icon: 'icon-37',
        w: 6,
        h: 6,
        mw: 4,
        mh: 6,
        ...getBaseConfig(EComponent.SHI_JIE_SHI_ZHONG),
        usedPage: ['bigscreen']
      },
      {
        name: t('message.analysisComponents.timeComponents[1]'),
        icon: 'icon-38',
        w: 6,
        h: 6,
        mw: 4,
        mh: 6,
        ...getBaseConfig(EComponent.DAO_JI_SHI),
        usedPage: ['bigscreen']
      }
    ]
  },
  {
    groupName: t('message.analysisComponents.groupName[0]'), // 静态组件
    components: [...staticComponents]
  },
  {
    groupName: t('message.analysisComponents.groupName[9]'), // 全局组件
    components: [...globalComponents]
  }
];
