import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import HotWord from './src/index.vue';

HotWord.install = (app: App): void => {
  app.component('AHotWord', HotWord);
  app.component(
    'AHotWordProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
  app.component(
    'AHotWordDataConfigProp',
    loadAsyncComponent(() => import('./src/dataConfig.vue'))
  );
};

export default HotWord;
