<script lang="ts" setup>
import { onBeforeUnmount, onMounted, shallowRef, watchEffect } from 'vue';
import { debounce } from 'lodash-es';
import { TTheme } from '../utils';

interface CardDatum {
  name: string;
  value: number | string;
  formatter?: (val: number | string) => string;
  icon?: string;
  color?: string;
}

interface CardChartProps {
  data: CardDatum[];
  theme?: TTheme;
}

interface Emits {
  (e: 'chart:click', params: any): void;
}

const props = defineProps<CardChartProps>();

const emit = defineEmits<Emits>();
const size = shallowRef(Symbol());
const resize = debounce(() => {
  size.value = Symbol();
}, 100);

const iconNames = [
  'comment',
  'emoji',
  'like',
  'play',
  'reading',
  'share'
] as const;
const colors = ['#007cff', '#fe7770', '#ff9030', '#6093ff', '#00cb9b'];
const color = (index: number) => {
  return colors[index % colors.length];
};
const getIcon = (icon: typeof iconNames[number]) => {
  let url: string = icon;
  if (iconNames.indexOf(icon) > -1) {
    url = icon + '-count.png';
    return new URL('./images/' + url, import.meta.url).href;
  }
  return url;
};
watchEffect(() => {
  // 收集size依赖
  size.value;
  const data = props.data;
});

onMounted(() => {
  window.addEventListener('resize', resize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resize);
});
</script>

<template>
  <ul class="wh-chart--card" :class="props.theme" prevent-resize>
    <li class="card-item" v-for="(item, index) of data" :key="item.name">
      <img class="icon" :src="getIcon(item.icon)" />
      <div class="titleText">
        <p class="sum" :style="{ color: item.color }">
          {{ item.value.toLocaleString() }}
        </p>
        <p class="count">{{ item.name }}</p>
      </div>
    </li>
  </ul>
</template>
<style lang="scss" scoped>
.wh-chart--card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;

  .card-item {
    border-radius: 4px;
    border: 1px solid #faf6f6;
    width: 48%;
    height: 43%;
    background-size: 100% 100%;
    list-style: none;
    display: flex;
    align-items: center;
    .icon {
      width: 40px;
      height: 40px;
      margin: 0 12px 0 12%;
    }

    .titleText {
      .sum {
        font-size: 18px;
        margin-bottom: 5px;
        font-weight: bold;
      }

      .count {
        margin-top: 5px;
        font-size: 12px;
        text-align: center;
        color: #0b0b0b;
        font-weight: 500;
      }
    }
  }
  &.bigscreen-theme-1 {
    .card-item {
      background: rgba(44, 126, 251, 0.1);
      border: none;
      .titleText {
        .count {
          color: #8895a4;
        }
      }
    }
  }
  &.bigscreen-theme-2 {
    .card-item {
      background: linear-gradient(90deg, #023e70 0%, rgba(2, 62, 112, 0) 100%);
      border: none;
      .icon {
        width: 60px;
        height: 60px;
      }
      .titleText {
        .sum {
          font-size: 22px;
          font-family: YouSheBiaoTiHei;
          color: #d8f0ff !important;
          line-height: 16px;
          text-shadow: 0px 0px 4px #00a7ff;
          margin-bottom: 8px;
        }
        .count {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: rgba(189, 219, 255, 0.6);
        }
      }
    }
  }
}
</style>
