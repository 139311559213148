<template>
  <div v-loading="loading" v-nodata="!loading && !Boolean(data.length)">
    <RingChart
      ref="refChart"
      v-if="!loading && data.length"
      :data="data"
      :label="theme == 'bigscreen-theme-2'"
      legend
      tooltip
      :labelStr="labelStr"
      :labelRich="labelRich"
      v-memo="[]"
      :theme="theme"
      @chart:click="chartClick"
    />
  </div>
</template>

<script lang="ts">
import RingChart from '@wenhai/visual-components/RingChart';
import { defineComponent, ref, onMounted, computed, inject } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { useDataCenter } from '../../_hooks/use-data-center';
const colors = [
  '#FFAC30',
  '#00CB9B',
  '#007CFF',
  '#3AC4D4',
  '#FE7770',
  '#B45BF3',
  '#00AAF8',
  '#FF70A9',
  '#6093FF',
  '#FF9030'
];
const defaultParams = { dataSource: [] };
export default defineComponent({
  name: 'AVolumeRatio',
  components: { RingChart },
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const data = ref<any[]>([]);
    const loading = ref(false);
    const paramsObj = computed(() => {
      return props.params;
    });
    const { setCurrentDrillingScheme } = useDataCenter(paramsObj.value);
    const chartsClick = inject('chartsClick', undefined);
    const initDrillingParams = inject('initDrillingParams');
    const { soundProportion } = useAnalysisApi();
    const labelRich = ref({});
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const apiData = ref();
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        realTime: 0,
        startPubTime: apiData.value.startPubTime,
        endPubTime: apiData.value.endPubTime
      };
    };
    const getData = async () => {
      if (!paramsObj.value.schemeIdList?.length) return;
      loading.value = true;
      data.value.splice(0);
      try {
        const res = await soundProportion({
          ...paramsObj.value,
          ...defaultParams
        });
        apiData.value = res.data;
        const resData = res.data?.soundPercentVOList as any[];
        data.value.push(
          ...resData.map((item, i) => {
            const sColor = colors[i % resData.length];
            labelRich.value['schemeId' + item.schemeId] = { color: sColor };
            return {
              name: item.schemeName,
              value: item.count,
              percent: item.percent,
              schemeId: item.schemeId,
              color: sColor
            };
          })
        );
        const maxTotalSchemeId = resData.sort((a, b) => {
          return b.count - a.count;
        })[0].schemeId;
        if (chartsClick) {
          setDrillPreviewData();
          initDrillingParams({ schemeId: maxTotalSchemeId, ...defaultParams });
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
      loading.value = false;
    };
    const labelStr = param => {
      const { data } = param as any;
      return `{schemeId${data.schemeId}|${data.name}\n${data.percent} (${data.value})}`;
    };

    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(obj.data.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      loading,
      data,
      getData,
      update,
      labelStr,
      colors,
      labelRich,
      chartClick
    };
  }
});
</script>
