// 定义内容
export default {
  router: {
    home: '首页',
    hotspot: '热榜',
    search: '搜索',
    analysis: '分析',
    dashboard: '大屏',
    monitor: '监测',
    warning: '预警',
    report: '报告',
    bulletin: '輿情簡報',
    proofreading: '内容校对',
    contentReview: '内容审查',
    searchResult: '搜索结果',
    collect: '收藏',
    analysisSettings: '分析设置',
    dashboardSettings: '大屏设置',
    other: '其他',
    otherIcon: '图标',
    otherI18n: '国际化',
    otherStyle: '全局样式',
    contacts: '个人中心',
    task: '任务中心',
    formation: '预览',
    technologyRadar: {
      technologyRadar: '科技雷达',
      dataInfo: '数据筛选',
      report: '报告管理',
      preview: '报告预览'
    },
    login: '登录',
    analysisShare: '分析分享',
    bigscreenRun: '大屏运行',
    links: {
      videoRiskControl: '视频风控',
      yaYiLLM: 'YAYI雅意大模型',
      dataMap: '数据地图'
    },
    bigscreenShare: '大屏分享'
  },
  staticRoutes: {
    signIn: '登入',
    notFound: '找不到此頁面',
    noPower: '沒有許可權'
  },
  user: {
    title0: '組件大小',
    title1: '語言切換',
    title2: '蒐索',
    title3: '佈局配寘',
    title4: '消息',
    title5: '開全屏',
    title6: '關全屏',
    fontSizeSwitching: '字體大小切換',
    StandardFont: '標準字體',
    MediumFont: '中號字體',
    LargeFont: '大號字體',
    dropdownLarge: '大型',
    dropdownDefault: '默認',
    dropdownSmall: '小型',
    dropdown1: '首頁',
    dropdown2: '個人中心',
    dropdown3: '404',
    dropdown4: '401',
    logOut: '登出',
    dropdown6: '程式碼倉庫',
    searchPlaceholder: '選單蒐索：支援中文、路由路徑',
    newTitle: '通知',
    newBtn: '全部已讀',
    newGo: '前往通知中心',
    newDesc: '暫無通知',
    logOutTitle: '提示',
    logOutMessage: '此操作將登出，是否繼續？',
    logOutConfirm: '確定',
    logOutCancel: '取消',
    logOutExit: '退出中'
  },
  tagsView: {
    refresh: '重繪',
    close: '關閉',
    closeOther: '關閉其它',
    closeAll: '全部關閉',
    fullscreen: '當前頁全屏',
    closeFullscreen: '關閉全屏'
  },
  notFound: {
    foundTitle: '地址輸入錯誤，請重新輸入地址~',
    foundMsg: '您可以先檢查網址，然後重新輸入或給我們迴響問題。',
    foundBtn: '返回首頁'
  },
  noAccess: {
    accessTitle: '您未被授權，沒有操作許可權~',
    accessMsg: '聯繫方式：加QQ群探討665452019',
    accessBtn: '重新授權'
  },
  layout: {
    configTitle: '佈局配寘',
    oneTitle: '全域主題',
    twoTopTitle: '頂欄設定',
    twoMenuTitle: '選單設定',
    twoColumnsTitle: '分欄設定',
    twoTopBar: '頂欄背景',
    twoTopBarColor: '頂欄默認字體顏色',
    twoIsTopBarColorGradual: '頂欄背景漸變',
    twoMenuBar: '選單背景',
    twoMenuBarColor: '選單默認字體顏色',
    twoIsMenuBarColorGradual: '選單背景漸變',
    twoColumnsMenuBar: '分欄選單背景',
    twoColumnsMenuBarColor: '分欄選單默認字體顏色',
    twoIsColumnsMenuBarColorGradual: '分欄選單背景漸變',
    threeTitle: '介面設定',
    threeIsCollapse: '選單水准折疊',
    threeIsUniqueOpened: '選單手風琴',
    threeIsFixedHeader: '固定 Header',
    threeIsClassicSplitMenu: '經典佈局分割選單',
    threeIsLockScreen: '開啟鎖屏',
    threeLockScreenTime: '自動鎖屏(s/秒)',
    fourTitle: '介面顯示',
    fourIsShowLogo: '側邊欄 Logo',
    fourIsBreadcrumb: '開啟 Breadcrumb',
    fourIsBreadcrumbIcon: '開啟 Breadcrumb 圖標',
    fourIsTagsview: '開啟 Tagsview',
    fourIsTagsviewIcon: '開啟 Tagsview 圖標',
    fourIsCacheTagsView: '開啟 TagsView 緩存',
    fourIsSortableTagsView: '開啟 TagsView 拖拽',
    fourIsShareTagsView: '開啟 TagsView 共用',
    fourIsFooter: '開啟 Footer',
    fourIsGrayscale: '灰色模式',
    fourIsInvert: '色弱模式',
    fourIsDark: '深色模式',
    fourIsWartermark: '開啟浮水印',
    fourWartermarkText: '浮水印文案',
    fiveTitle: '其它設定',
    fiveTagsStyle: 'Tagsview 風格',
    fiveAnimation: '主頁面切換動畫',
    fiveColumnsAsideStyle: '分欄高亮風格',
    fiveColumnsAsideLayout: '分欄佈局風格',
    sixTitle: '佈局切換',
    sixDefaults: '默認',
    sixClassic: '經典',
    sixTransverse: '橫向',
    sixColumns: '分欄',
    tipText: '點擊下方按鈕，複製佈局配寘去`src/stores/themeConfig.ts`中修改。',
    copyText: '一鍵複製配寘',
    resetText: '一鍵恢復默認',
    copyTextSuccess: '複製成功！',
    copyTextError: '複製失敗！'
  }
};
