import type { App } from 'vue';
/**
 * 注册一个全局自定义指令 `highlight`
 * 仅支持元素内text处理，不支持多层元素处理
 * 使用方法
 *  <div v-highlight="['天津', '北京']">
 *    {{text}}
 * </div>
 */

export default function (app: App) {
  app.directive('highlight', {
    mounted(el, binding) {
      if (!binding?.value?.length) return;
      // 除去含有包含关系的关键词
      const highlightList = _handleIncludeWord(binding?.value);
      let elText = el.innerText;
      // 获取文档中对应关键词的[位置, 关键词],并且进行坐标倒序排列，一次性获取下标替换，解决输入包含<span style="color: #cb2634"></span> (例如s p span color st style)文案错乱问题
      const indexWordArr = _getWordIindex(highlightList, elText);
      // 处理当前内容高亮
      elText = _handleHighlight(elText, indexWordArr);
      el.innerHTML = elText;
    }
  });
}
// 除去含有包含关系的关键词
const _handleIncludeWord = (list: string[]) => {
  if (!list?.length) return [];
  // 长度倒叙排序
  list.sort((a, b) => b.length - a.length);
  const arr: string[] = [];
  list.forEach(listItem => {
    let isInclude = false;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].includes(listItem)) {
        isInclude = true;
        return;
      }
    }
    !isInclude && arr.push(listItem);
  });
  return arr;
};
// 获取文档中对应关键词的[位置, 关键词],并且进行坐标倒序排列
const _getWordIindex = (words: string[], elText: string) => {
  const indexWordArr: [number, string][] = [];
  words.forEach(word => {
    const wordLen = word.length;
    let lastElText = elText;
    // 每个关键词对应的[位置, 关键词]数据
    const currentindexWordArr: [number, string][] = [];
    while (lastElText.includes(word)) {
      const index = lastElText.indexOf(word);
      if (currentindexWordArr.length) {
        // 当前关键词的下标，在本次剩余文档中的位置+当前关键词长度+上次关键词出现的下标
        const currentIndex =
          index +
          wordLen +
          currentindexWordArr[currentindexWordArr.length - 1][0];
        currentindexWordArr.push([currentIndex, word]);
      } else {
        // 当前关键词第一次出现
        currentindexWordArr.push([index, word]);
      }
      lastElText = lastElText.substring(index + wordLen);
    }
    indexWordArr.push(...currentindexWordArr);
  });
  // 按照坐标倒序排列，用于逆向处理高亮词
  indexWordArr.sort((a, b) => b[0] - a[0]);
  return indexWordArr;
};

// 处理当前内容高亮
const _handleHighlight = (
  elText: string,
  indexWordArr: [number, string][] = []
) => {
  let highlightText = '';
  indexWordArr.forEach(item => {
    highlightText =
      `<span style="color: #cb2634">${item[1]}</span>${elText.substring(
        item[0] + item[1].length
      )}` + highlightText;
    elText = elText.substring(0, item[0] as number);
  });
  return elText + highlightText;
};
