<script lang="ts" setup>
// TODO: 有需要再继续做
import type { SeriesItem } from './WordCloudBase.vue';
import { hexToRgb } from '../utils';
import { computed, inject } from 'vue';
//import { SeriesData } from 'echarts/types/dist/shared';
// import { interpolateNumber } from 'd3-interpolate';
// import { max, min } from 'lodash-es';
interface Emits {
  (e: 'item-click', params: any): void;
}
const emit = defineEmits<Emits>();
interface DataItem {
  name: string;
  value: number;
}
interface WordCloudMapProps {
  series?: SeriesItem;
  data?: DataItem[];
  colors?: string[];
  size?: number[];
  drilling?: boolean;
}
const props = withDefaults(defineProps<WordCloudMapProps>(), {
  colors: () => [
    '#007CFF',
    '#7ABBFF',
    '#FF9030',
    '#FFC797',
    '#6093FF',
    '#A7C3FF',
    '#3AC4D4',
    '#7EDAE5',
    '#FE7770',
    '#FFA29E',
    '#00CB9B',
    '#73DFC6',
    '#B45BF3',
    '#D3A9F1',
    '#00AAF8',
    '#7FD4FB',
    '#FFAC30',
    '#FFCF88',
    '#FF70A9',
    '#FFAECE',
    '#6E70FF',
    '#B0B1FF',
    '#FF8F66',
    '#FFB9A1',
    '#2CD36A',
    '#75E29E',
    '#3B6FFF',
    '#91AEFF',
    '#007CFF',
    '#7ABBFF',
    '#FF9030',
    '#FFC797',
    '#6093FF',
    '#A7C3FF',
    '#3AC4D4',
    '#7EDAE5',
    '#FE7770',
    '#FFA29E',
    '#00CB9B',
    '#73DFC6',
    '#B45BF3',
    '#D3A9F1',
    '#00AAF8',
    '#7FD4FB',
    '#FFAC30',
    '#FFCF88',
    '#FF70A9',
    '#FFAECE',
    '#6E70FF',
    '#B0B1FF',
    '#FF8F66',
    '#FFB9A1',
    '#2CD36A',
    '#75E29E',
    '#3B6FFF',
    '#91AEFF',
    '#007CFF',
    '#7ABBFF',
    '#FF9030',
    '#FFC797',
    '#6093FF',
    '#A7C3FF',
    '#3AC4D4',
    '#7EDAE5',
    '#FE7770',
    '#FFA29E',
    '#00CB9B',
    '#73DFC6',
    '#B45BF3',
    '#D3A9F1',
    '#00AAF8',
    '#7FD4FB',
    '#FFAC30',
    '#FFCF88',
    '#FF70A9',
    '#FFAECE',
    '#6E70FF',
    '#B0B1FF',
    '#FF8F66',
    '#FFB9A1',
    '#2CD36A',
    '#75E29E',
    '#3B6FFF',
    '#91AEFF'
  ],
  size: () => [20, 40]
});
// const gap = 20;
// const rendering = ref(false);

const data = computed(() => (props.series?.data || props.data) as DataItem[]);
// const maxValue = max(data.value.map(d => d.value)) || 0;
// const minValue = min(data.value.map(d => d.value)) || 0;
// const chartsClick = inject('chartsClick', undefined);
// const anaSearch = inject('anaSearch', undefined);
const componentDrillType = inject('componentDrillType', undefined);
// const ratio = (max: number, min: number, val: number) => {
//   return (val - min) / (max - min || 1);
// };

// const getFontSize = flow(
//   partial(ratio, maxValue, minValue),
//   interpolateNumber(props.size[0], props.size[1])
// );
const getItemLeft = (d: DataItem) => {
  const randomX = Math.random() * 100;
  const itemW = d.name.length * 14 + 28;
  return `calc(${randomX}% - ${randomX > 70 ? itemW : 0}px)`;
};

const options = computed(() => {
  const len = data.value.length;
  return data.value.map((d, i) => {
    return {
      ...d,
      // fontSize: getFontSize(d.value),
      fontSize: props.size[0] + 'px',
      backgroundImage: `linear-gradient(270deg, ${hex2rgba(
        props.colors[i % len],
        0
      )} 0%,${hex2rgba(props.colors[i % len], 0.3)} 48%, ${hex2rgba(
        props.colors[i % len],
        0
      )} 100%)`,
      // left: Math.random()*(d.name.length> 10 ? 50 :80) + '%',
      // top: (i % len)*10 + '%',
      left: getItemLeft(d), //会有遮挡情况，原型上也有遮挡，暂时解决关键词展示不全的问题，后续改动看需求
      top: Math.min(Math.random() * 92, 90) + '%',
      opacity: 1,
      color: props.colors[i % len],
      componentDrillType
    };
  });
});

const hex2rgba = (hex: string, alpha = 1) => {
  return `rgba(${hexToRgb(hex).join(',')}, ${alpha})`;
};
const itemClick = item => {
  emit('item-click', item);
};
</script>
<template>
  <div class="wh-chart--word-cloud">
    <div
      class="word-cloud--item"
      :class="{ cur: drilling }"
      v-for="item in options"
      :key="item.name"
      :style="{ ...item }"
      @click="itemClick(item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.wh-chart--word-cloud {
  position: relative;
  background: url(./images/bg.png) no-repeat center/ 100% 100%;
  height: 100%;
  width: 100%;

  .word-cloud--item {
    position: absolute;
    padding: 0.5em 2em;
    transition: left 0.5s, top 0.5s, opacity 0.5s;
    white-space: nowrap;
  }

  .word-cloud--item.cur {
    cursor: pointer;
  }
}

@media (max-resolution: 2dppx) {
  .wh-chart--word-cloud {
    background: url(./images/bg@2x.png) no-repeat center/100% 100%;
  }
}
</style>
