import { createApp } from 'vue';
import pinia from '/@/stores/index';
import App from './App.vue';
import * as Sentry from '@sentry/vue';
import VueMatomo from 'vue-matomo';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from './router';
import { directive } from '/@/utils/directive';
import { i18n } from '/@/i18n/index';
import other from '/@/utils/other';

import AnalysisCharts from '/@/views/analysis/components';

import cIcon from '/@/components/coolicon/index.vue';
import YqBUi from 'yq-bui';
import 'yq-bui/css';
import WinboxUI from 'winbox-ui-next';
import 'winbox-ui-next/dist/winbox.css';

import '/@/theme/index.scss';

import mitt from 'mitt';
import 'virtual:svg-icons-register';
import directives from './directives';

import VueGridLayout from 'vue-grid-layout';
import Package from '../package.json';
import { Local } from '/@/utils/storage';
import '/@/utils/polyfill';
import { Session } from '/@/utils/storage';

const app = createApp(App);
!import.meta.env.VITE_LOCALIZATION &&
  import.meta.env.PROD &&
  Sentry.init({
    app,
    dsn: 'https://7e845903a6944653a185c1fe2fe41dfb@sentry.wengegroup.com/4',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.MODE,
    release: Package.version,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ]
  });
const userName = Session.get('userInfo')?.userName;
setTimeout(() => {
  !import.meta.env.VITE_LOCALIZATION &&
    import.meta.env.PROD &&
    Sentry.configureScope(scope => {
      scope.setUser({
        username: userName
      });
    });
}, 5000);
directive(app);
other.elSvg(app);
app.use(directives);
!import.meta.env.VITE_LOCALIZATION &&
  import.meta.env.MODE === 'production' &&
  app.use(VueMatomo, {
    host: import.meta.env.VITE_MATOMO_HOST_URL,
    siteId: import.meta.env.VITE_MATOMO_SITEID,
    router: router,
    requireConsent: false,
    enableLinkTracking: true,
    trackInitialView: true,
    trackerFileName: 'matomo',
    debug: false
  });
// 全局切换配置字体大小
// let dataFontSize = Local.get('data-font-size')
// if (!dataFontSize) {
//   dataFontSize = 'large';
//   Local.set('data-font-size', dataFontSize)
// }
let dataZoom = Local.get('data-zoom');
if (!dataZoom) {
  dataZoom = '1';
  Local.set('data-zoom', dataZoom);
}
// window.document.documentElement.setAttribute('data-font-size', dataZoom);
document.body.style.zoom = dataZoom;
app
  .use(AnalysisCharts)
  .component('c-icon', cIcon)
  .use(pinia)
  .use(router)
  .use(ElementPlus)
  .use(WinboxUI)
  .use(i18n)
  .use(VueGridLayout)
  .use(YqBUi)
  .mount('#app');
export const mittBus = mitt();
app.config.globalProperties.mittBus = mittBus;

window.addEventListener('unhandledrejection', event => {
  console.error(
    'Unhandled Rejection at:',
    event.promise,
    'Reason:',
    event.reason
  );
});
