<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(data.data.length)"
    class="chart-box-in"
  >
    <StageEvolution v-if="!loading && data.data.length" :series="data" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, toRef, reactive } from 'vue';
import { useSearchApi } from '/@/api/search';
import { storeToRefs } from 'pinia';
import { useSearchAnalyse } from '/@/stores/searchAnalyse';
import { useDictStore } from '/@/stores/dict';
import { cloneDeep } from 'lodash-es';
import { getfilterList } from '../components/utils';
export default defineComponent({
  // 阶段演化
  name: 'SEvolution',
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { analysisExposureTrend } = useSearchApi();
    const data = ref<{ data: { name: string; value: [string, number] }[] }>({
      data: []
    });
    const loading = ref(false);
    const paramsReset = toRef(props, 'params');
    const searchAnalyseStore = useSearchAnalyse();
    const dictStore = useDictStore();
    const {
      searchAnalyseParams,
      sAnalysechannelIds,
      dataAuthType,
      designatedMediaLabelArr,
      searchTime
    } = storeToRefs(searchAnalyseStore);
    const { mediaTypeList, mediaTypeLocalList, mediaTypeForeignList } =
      storeToRefs(dictStore);
    let resParams = reactive({});
    const getData = async () => {
      loading.value = true;
      try {
        resParams = cloneDeep(searchAnalyseParams.value);
        delete resParams.dataSource;
        let defalutMediaTypeList = [];
        if (dataAuthType.value === 0) {
          defalutMediaTypeList = cloneDeep(mediaTypeList.value);
        }
        if (dataAuthType.value === 1) {
          defalutMediaTypeList = cloneDeep(mediaTypeLocalList.value);
        }
        if (dataAuthType.value === 2) {
          defalutMediaTypeList = cloneDeep(mediaTypeForeignList.value);
        }
        const arr = getfilterList(
          defalutMediaTypeList,
          sAnalysechannelIds.value,
          'value'
        );
        resParams = Object.assign({}, resParams, {
          dataSourceInfos: arr,
          schemeId: paramsReset.value.schemeId,
          dateInterval: paramsReset.value.dateInterval
        });
        if (resParams?.designatedMediaLabelId === 0) {
          resParams.searchDesignatedLabelVOS = [];
        } else {
          resParams.searchDesignatedLabelVOS =
            designatedMediaLabelArr.value.filter(item => {
              return item.id === resParams.designatedMediaLabelId;
            });
        }
        if (searchTime.value.startPubTime && searchTime.value.endPubTime) {
          (resParams.realTime = 0),
            (resParams.startPubTime = searchTime.value.startPubTime),
            (resParams.endPubTime = searchTime.value.endPubTime);
        }
        const res = (await analysisExposureTrend(resParams)) as any;
        data.value.data = res.data?.noStackResult?.itemList?.map(
          (item: { intervalTime: string; sum: number }) => {
            return {
              name: item.intervalTime.split(' ')[0],
              value: [item.intervalTime.split(' ')[0], item.sum]
            };
          }
        );
      } catch (error) {
        data.value.data = [];
        loading.value = false;
      }
      loading.value = false;
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      data,
      loading,
      paramsReset,
      update
    };
  }
});
</script>
<style lang="scss" scoped>
.chart-box-in {
  width: 100%;
  height: 330px !important;
  :deep(.el-loading-mask) {
    z-index: 9 !important;
  }
}
</style>
