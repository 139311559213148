<script lang="ts" setup>
import type { SeriesItem, ChartDataItem } from '../utils';
import { loadAsyncComponent } from '../utils/async-component';
import useChartClick from '../hooks/useChartClick';

export interface GaugeProps {
  series?: SeriesItem<ChartDataItem>;
  data?: ChartDataItem;
  trackWidth?: number;
  min?: number;
  max?: number;
  type?: 'base' | 'gradient' | 'stage' | 'gradient-stage';
  theme?: String;
}

interface Emits {
  (e: 'chart:click', params: any): void;
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<GaugeProps>(), {
  min: 0,
  max: 100,
  type: 'base'
});

let gaugeImport: any;
if (props.theme == 'bigscreen-theme-2') {
  gaugeImport = () => import('./GaugeChartStageTheme2.vue');
} else {
  if (props.type === 'stage') {
    gaugeImport = () => import('./GaugeChartStage.vue');
  } else if (props.type === 'gradient') {
    gaugeImport = () => import('./GaugeChartGradient.vue');
  } else if (props.type === 'gradient-stage') {
    gaugeImport = () => import('./GaugeChartGradientStage.vue');
  } else {
    gaugeImport = () => import('./GaugeChartBase.vue');
  }
}

const gauge = loadAsyncComponent(gaugeImport);
const chartRef = useChartClick(emit);
</script>
<template>
  <gauge v-bind="props" ref="chartRef"></gauge>
</template>
