import type { App } from 'vue';
import visualComponents from './visual-components';
import configFormItems from '../configFormItems';
import searchAnalysis from '../searchAnalysis';

import ASummarize from './summarize';
import AInfluenceIndex from './influenceIndex';
import AChannelStart from './channelstart';
import AExposureTrend from './exposuretrend';
import AChannelVolume from './channelVolume';
import AEvolution from './evolution';
import AAccountList from './accountList';
import AHotArticle from './hotarticle';
import AHotWord from './hotword';
import AHotTopic from './hottopic';
import AHotList from './hotList';
import AExpertOpinion from './expertOpinion';
import ANetizenOpinion from './netizenOpinion';
import InfoOverview from './infoOverview';
import InteractionOverview from './interactionOverview';
import InteractionTrend from './interactionTrend';
import AHotPhrase from './hotphrase';
import ANegativeInfoList from './negativeInfoList';
import APropagationCountry from './propagationCountry';
import APropagationLanguage from './propagationLanguage';
import APopularTopic from './popularTopic';
import AWritingHabit from './writingHabit';
import AAuthentication from './authentication';
import AEmotionalTendency from './emotionalTendency';
import AMediaList from './MediaList';

import PublishMap from './publishMap';
import MentionMap from './mentionMap';
import PopularPeople from './popularPeople';
import PopularOrganization from './popularOrganization';
import WeiboForwarding from './weiboForwarding';
import ARankDistribution from './rankDistribution';
import APropagationPath from './propagationPath';
import ASexDistribution from './sexDistribution';
import ABigScreenTitle from './bigScreenTitle';
import AExposureTrendComparison from './exposuretrendComparison';
import AInfoList from './infolist';
import AInfluenceIndexList from './influenceIndexList';
import AVolumeRatio from './volumeRatio';
import AChannelVolumeCompare from './channelVolumeCompare';
import AEmotionCompare from './emotionCompare';
import AInteractionTrendCompare from './interactionTrendCompare';
import ABigScreenPage from './bigscreenPage';
import AWorldClock from './worldClock';
import ACountdown from './countdown';
import AActiveAccount from './activeAccount';
import AInformationList from './informationList';

const components = [
  ASummarize,
  AInfluenceIndex,
  AChannelStart,
  AExposureTrend,
  AChannelVolume,
  AEvolution,
  AAccountList,
  AHotArticle,
  AHotWord,
  AHotTopic,
  AExpertOpinion,
  ANetizenOpinion,
  InfoOverview,
  InteractionOverview,
  InteractionTrend,
  AHotPhrase,
  ANegativeInfoList,
  APropagationCountry,
  APropagationLanguage,
  APopularTopic,
  AWritingHabit,
  AAuthentication,
  AEmotionalTendency,
  InteractionTrend,
  PublishMap,
  MentionMap,
  PopularPeople,
  PopularOrganization,
  WeiboForwarding,
  ARankDistribution,
  APropagationPath,
  ASexDistribution,
  AMediaList,
  AHotList,
  ABigScreenTitle,
  AExposureTrendComparison,
  AInfoList,
  AInfluenceIndexList,
  AVolumeRatio,
  AChannelVolumeCompare,
  AEmotionCompare,
  AInteractionTrendCompare,
  ABigScreenPage,
  AWorldClock,
  ACountdown,
  AActiveAccount,
  AInformationList
];

const install = (app: App): void => {
  components.forEach(component => {
    app.use(component);
  });
  visualComponents.forEach(c => {
    app.use(c);
  });
  configFormItems.forEach(c => {
    app.use(c);
  });
  searchAnalysis.forEach(c => {
    app.use(c);
  });
};

export default {
  install
};
