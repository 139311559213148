<template>
  <div v-loading="loading" v-nodata="!sortData.length && !loading">
    <div style="width: inherit; height: inherit" v-if="sortData.length">
      <TreeMap
        v-if="!loading && params.chartType == 'area'"
        :data="sortData"
        :theme="theme"
        @chart:click="chartClick"
      />
      <RingChart
        v-if="!loading && params.chartType == 'pie'"
        :data="sortData"
        label
        legend
        tooltip
        @chart:click="chartClick"
      />
      <BarChart
        v-if="!loading && params.chartType == 'bar'"
        horizontal
        :series="{
          data: sortData.map(item => {
            return { value: item.value };
          })
        }"
        :x-axis="{
          show: false,
          data: sortData.map(item => {
            return item.name;
          })
        }"
        :label="{ show: true, position: [0, '-50%'], color: '#9D9D9D', formatter: (params: any) => { return params.name } }"
        :grid="{ containLabel: false, bottom: 25 }"
        tooltip
        @chart:click="chartClick"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, toRef, computed, inject } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { useDataCenter } from '../../_hooks/use-data-center';
export default defineComponent({
  name: 'APopularOrganization',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const { popularOrganization } = useAnalysisApi();
    const data = ref<{ name: string; value: number }[]>([]);
    const sortData = computed(() => {
      return data.value.sort((a, b) => {
        return a.value - b.value;
      });
    });
    const loading = ref(false);
    const params = toRef(props, 'params');
    const { setCurrentDrillingScheme } = useDataCenter(params.value);
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const apiData = ref();
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        startPubTime: apiData.value?.startPubTime,
        endPubTime: apiData.value?.endPubTime
      };
    };
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      try {
        const res = (await popularOrganization(params.value)) as any;
        apiData.value = res.data;
        if (chartsClick) {
          setDrillPreviewData();
          searchResultList();
        }
        data.value = res?.data?.itemList.map(
          (item: { orgName: string; count: number }) => {
            return {
              name: item.orgName,
              value: item.count
            };
          }
        );
      } catch (error) {
        loading.value = false;
      }
      loading.value = false;
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(params.value.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      data,
      loading,
      params,
      update,
      sortData,
      chartClick
    };
  }
});
</script>
