import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import HotArticle from './src/index.vue';

HotArticle.install = (app: App): void => {
  app.component('AHotArticle', HotArticle);
  app.component(
    'AHotArticleProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default HotArticle;
