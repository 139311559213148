import { post, get } from '/@/utils/request';
import { ResponseData } from '/@/api/report/interface';
import { IScheme } from '/@/stores/searchScheme';

type ListResponse = ResponseData<IScheme[]>;
type SchemeResponse = ResponseData<{ schemeId: number }>;

export default function useSchemeMenuApi() {
  return {
    // 查询树状列表
    getList: () => {
      return post<ListResponse>('/search/searchScheme/tree', {});
    },
    // 保存（增加、修改）
    save: (pm: object) => {
      return post<SchemeResponse>('/search/searchScheme/create', pm);
    },
    // 删除
    deleteById: (schemeId: number) => {
      return get<ResponseData>(
        `/search/searchScheme/selectSchemeByRootId/${schemeId}`
      );
    },
    // 重命名
    rename: (pm: { id: number; name: string }) => {
      return post<ResponseData>('/search/searchScheme/updateName', pm);
    },
    // 分页获取搜索主题树
    searchSchemeTree: (pm: {
      currentPage: number;
      pageSize: number;
      briefFlag?: 1;
    }) => {
      return post<ListResponse>('/search/searchScheme/searchSchemeTree', pm);
    },
    // 查询搜索主题
    getSearchSchemeList: (pm: { schemeName: string }) => {
      return post<ListResponse>('/search/searchScheme/getSearchSchemeList', pm);
    },
    // 根据id查询主题
    getSearchSchemeConfig: (id: number) => {
      return get<ListResponse>(`/search/searchScheme/single/${id}`);
    },
    // 移动主题位置
    removeScheme: (pm: object) => {
      return post<ResponseData>('/search/searchScheme/moveSchemeSortOrder', pm);
    }
  };
}
