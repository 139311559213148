export default {
  taskList: {
    taskCenter: '任务中心',
    taskSearchLabel: ['任务状态', '请输入任务名称'],
    taskColumns: [
      '序号',
      '任务名称',
      '数据量',
      '发起人',
      '创建时间',
      '状态',
      '操作'
    ],
    activeBtn: ['取消', '下载', '删除'],
    exportStatusType: ['排队中', '进行中', '成功', '失败', '取消']
  }
};
