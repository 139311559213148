import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import PopularOrganization from './src/index.vue';

PopularOrganization.install = (app: App): void => {
  app.component('APopularOrganization', PopularOrganization);
  app.component(
    'APopularOrganizationProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default PopularOrganization;
