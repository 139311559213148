<template>
  <div v-loading="loading" v-nodata="!Boolean(series.length) && !loading">
    <img v-if="isImage" :src="imageUrl" style="width: 100%; height: 100%" />
    <LineChart
      v-if="!isImage && !loading && series.length"
      ref="refChart"
      :smooth="true"
      :series="series"
      :x-axis="{ data: xAxis }"
      y-axis
      tooltip
      legend
      :theme="theme"
      :key="updateKey"
      dataZoom
      @chart:click="chartClick"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  nextTick,
  inject,
  watch,
  toRef
} from 'vue';
import useAnalysisApi from '/@/api/analysis/index';
import { isImage, getChartImage, utilDebounce } from '../../utils';
import { useEditorStore, getCurrentSchemeConfig } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { cloneDeep } from 'lodash-es';
import { getDateByInterval } from '../../utils';
import { useDataCenter } from '../../_hooks/use-data-center';
export default defineComponent({
  // 媒体曝光趋势对比
  name: 'AExposureTrendComparison',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const updateKey = ref();
    watch(toRef(props, 'theme'), () => {
      updateKey.value = new Date().getTime();
    });
    const refChart = ref();
    const imageUrl = ref('');
    const { exposureTrendComparison } = useAnalysisApi();
    const paramsObj = computed(() => {
      return props.params;
    });
    const { setCurrentDrillingScheme } = useDataCenter(paramsObj.value);
    const xAxis = ref<any[]>([]);
    const series = ref<any[]>([]);
    const loading = ref(false);
    const setTime = (intervalTime: string) => {
      let time = intervalTime.substr(5, 11);
      if (props.params.dateInterval === 'DAY') {
        time = time.split(' ')[0];
      }
      if (props.params.dateInterval === 'MONTH') {
        time = time.substring(0, 2) + '月';
      }
      return time;
    };
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const apiData = ref();
    const setDrillPreviewData = () => {
      const dateInterval = apiData.value.stackedResult[0].dateInterval;
      drillPreviewData.value = {
        startPubTime: apiData.value?.startPubTime,
        endPubTime: apiData.value?.endPubTime,
        dateInterval,
        aeResultData: apiData.value?.stackedResult || []
      };
    };
    const getData = async () => {
      if (!paramsObj.value.schemeIdList?.length) return;
      loading.value = true;
      xAxis.value.splice(0);
      series.value.splice(0);
      try {
        const res = await exposureTrendComparison(paramsObj.value);
        if (res.code === 200) {
          apiData.value = res.data;
          const resData = res.data as any;
          if (chartsClick) {
            setDrillPreviewData();
            searchResultList();
          }
          resData.stackedResult.forEach((item: any) => {
            const data = item.itemList.map((itm: any) => {
              const intervalTime: string = getDateByInterval(
                itm.intervalTime,
                item.dateInterval
              );
              !xAxis.value.includes(intervalTime) &&
                xAxis.value.push(intervalTime);
              return {
                schemeId: item.schemeId,
                value: Number(itm.sum),
                startTime: itm.intervalTime
              };
            });
            series.value.push({
              name: item.schemeName,
              data
            });
          });
          nextTick(() => {
            saveChartImage();
          });
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const saveChartImage = utilDebounce(() => {
      refChart.value && (imageUrl.value = getChartImage(refChart));
    }, 300);
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(obj.data.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      refChart,
      isImage,
      imageUrl,
      xAxis,
      series,
      loading,
      paramsObj,
      update,
      saveChartImage,
      updateKey,
      chartClick
    };
  }
});
</script>
