import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import ExposureTrend from './src/index.vue';

ExposureTrend.install = (app: App): void => {
  app.component('AExposureTrend', ExposureTrend);
  app.component(
    'AExposureTrendProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default ExposureTrend;
