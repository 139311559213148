import { Ref } from 'vue';
import { ECharts } from 'echarts';
type Refs = Record<'chartRef', { chart: ECharts; $refs: Refs }>;
export default function useChartInstance(instance: Ref<any>) {
  let $refs = instance.value?.$refs as Refs;
  if (instance.value?.chart) return instance.value.chart;
  while ($refs?.chartRef) {
    if ($refs.chartRef?.chart) {
      return $refs.chartRef.chart;
    } else if ($refs.chartRef?.$refs) {
      $refs = $refs.chartRef.$refs;
    } else {
      return undefined;
    }
  }
  return undefined;
}
