<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(data.length) && !loading"
    :class="isMobile ? 'isMobile' : ''"
  >
    <CardStatisticsChart v-if="!loading" :data="data" :theme="theme" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch, toRef } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { useI18n } from 'vue-i18n';
import other from '/@/utils/other';
export default defineComponent({
  // 互动概览
  name: 'AInteractionOverview',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const { t } = useI18n();
    const { interactionOverview } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const data = ref<
      { name: string; value: number | string; icon: string; color: string }[]
    >([]);
    const loading = ref(false);
    const iconOption = computed(() => {
      return props.theme == 'bigscreen-theme-2'
        ? [
            new URL('./icon/theme-2/yuedu.png', import.meta.url).href,
            new URL('./icon/theme-2/dianzan.png', import.meta.url).href,
            new URL('./icon/theme-2/pinglun.png', import.meta.url).href,
            new URL('./icon/theme-2/fenxiang.png', import.meta.url).href
          ]
        : [
            new URL('./icon/yuedu.png', import.meta.url).href,
            new URL('./icon/dianzan.png', import.meta.url).href,
            new URL('./icon/pinglun.png', import.meta.url).href,
            new URL('./icon/fenxiang.png', import.meta.url).href
          ];
    });
    watch(toRef(props, 'theme'), () => {
      data.value.forEach((item, i) => {
        item.icon = iconOption.value[i];
      });
    });
    const getData = async () => {
      if (!params.value.schemeId) return;
      data.value.splice(0);
      loading.value = true;
      try {
        const res = await interactionOverview(params.value);
        if (res?.code === 200) {
          const resData = res.data as any;
          data.value.push(
            ...[
              {
                name: t('message.analysisDetails.interactionOverviewItem[0]'), // 阅读总量
                value: resData.readCount,
                icon: iconOption.value[0],
                color: '#A06FFF'
              },
              {
                name: t('message.analysisDetails.interactionOverviewItem[1]'), // 点赞总量
                value: resData.likeCount,
                icon: iconOption.value[1],
                color: '#FF6021'
              },
              {
                name: t('message.analysisDetails.interactionOverviewItem[2]'), // 评论总量
                value: resData.commentCount,
                icon: iconOption.value[2],
                color: '#FFBA2B'
              },
              {
                name: t('message.analysisDetails.interactionOverviewItem[3]'), // 分享总量
                value: resData.rttCount,
                icon: iconOption.value[3],
                color: '#28B4FA'
              }
            ]
          );
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    const isMobile = ref(false);
    onMounted(() => {
      isMobile.value = other.isMobile();
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      data,
      loading,
      params,
      update,
      isMobile
    };
  }
});
</script>
<style lang="scss" scoped>
.isMobile {
  :deep(.wh-chart--card) {
    .card-item {
      .icon {
        margin: 0 8px 0 8px;
      }
      .titleText {
        .sum {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
