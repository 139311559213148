import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import ChannelStart from './src/index.vue';

ChannelStart.install = (app: App): void => {
  app.component('AChannelStart', ChannelStart);
  app.component(
    'AChannelStartProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default ChannelStart;
