export default {
  hotSpot: {
    hotSearchPlaceholder: '热点检索',
    listColumn: ['排名', '热点', '排名变化', '热度'],
    detailBtn: '查看热点详情>>',
    zbListColumn: [
      '排名',
      '热点榜单',
      '热点',
      '热度值',
      '热度趋势',
      '排名变化',
      '操作'
    ],
    detail: '详情',
    hotDetail: '热点详情',
    hotDetailBtn: ['搜一搜', '原文'],
    source: '来源',
    detailTop: ['当前排名', '最新热度', '在榜时长'],
    hotTrends: '热点趋势',
    hotNode: '热点节点',
    hotNodeColumn: ['编号', '节点', '排名', '热度值', '时间'],
    hotEnumNodeTexts: ['首次上榜', '掉出榜单', '再次上榜'],
    goback: '返回热榜'
  }
};
