import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import PropagationCountry from './src/index.vue';

PropagationCountry.install = (app: App): void => {
  app.component('APropagationCountry', PropagationCountry);
  app.component(
    'APropagationCountryProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default PropagationCountry;
