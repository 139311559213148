import { Emits } from '../types';

import { onBeforeUnmount, ref, watchEffect } from 'vue';
import useChartInstance from './useChartInstance';

export default function useChartClick(emit: Emits) {
  const chartRef = ref();
  const emitParams = (params: any) => emit('chart:click', params);
  watchEffect(() => {
    const chart = useChartInstance(chartRef);
    chart?.off('click', emitParams)?.on('click', emitParams);
  });
  onBeforeUnmount(() => {
    const chart = useChartInstance(chartRef);
    chart?.off('click');
  });
  return chartRef;
}
