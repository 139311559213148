import { defineStore } from 'pinia';
import Cookies from 'js-cookie';
import { UserInfosStates } from './interface';
import { Session } from '/@/utils/storage';
import { useLoginApi } from '/@/api/login/index';
import { ElMessageBox } from 'element-plus';
import { Local } from '/@/utils/storage';

/**
 * 用户信息
 * @methods setUserInfos 设置用户信息
 */
export const useUserInfo = defineStore('userInfo', {
  state: (): UserInfosStates => ({
    userInfos: {
      userName: '',
      photo: '',
      time: 0,
      roles: [],
      authBtnList: [],
      areaId: '',
      areaName: ''
    }
  }),
  actions: {
    async setUserInfos() {
      const userInfos: any = await this.getApiUserInfo();
      this.userInfos = userInfos;
    },
    // 模拟接口数据
    // https://gitee.com/lyt-top/vue-next-admin/issues/I5F1HP
    async getApiUserInfo() {
      // 获取用户权限
      let userRoles: any = [];
      try {
        userRoles = await useLoginApi().getInfo();
        if (userRoles.code === 200) {
          // 模拟数据，请求接口时，记得删除多余代码及对应依赖的引入
          const userName = Cookies.get('userName');
          // 模拟数据
          let defaultRoles: Array<string> = [];
          let defaultAuthBtnList: Array<string> = [];
          // admin 页面权限标识，对应路由 meta.roles，用于控制路由的显示/隐藏
          let adminRoles: Array<string> = ['admin'];
          // admin 按钮权限标识
          let adminAuthBtnList: Array<string> = [
            'btn.add',
            'btn.del',
            'btn.edit',
            'btn.link'
          ];
          // test 页面权限标识，对应路由 meta.roles，用于控制路由的显示/隐藏
          let testRoles: Array<string> = ['common'];
          // test 按钮权限标识
          let testAuthBtnList: Array<string> = ['btn.add', 'btn.link'];
          // 不同用户模拟不同的用户权限
          // if (['qt-test', 'wg-show', 'xinghe-dev'].includes(userName)) {
          // 	defaultRoles = adminRoles;
          // 	defaultAuthBtnList = adminAuthBtnList;
          // } else {
          defaultRoles = userRoles.roles;
          defaultAuthBtnList = testAuthBtnList;
          // }

          // 用户信息模拟数据
          const userIp: any =
            import.meta.env.VITE_NAME === 'video' ? this.getIpProperty() : {};
          const userInfos = {
            userName: userName,
            photo:
              userName === 'admin'
                ? 'https://img2.baidu.com/it/u=1978192862,2048448374&fm=253&fmt=auto&app=138&f=JPEG?w=504&h=500'
                : 'https://img2.baidu.com/it/u=2370931438,70387529&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
            time: new Date().getTime(),
            roles: defaultRoles,
            authBtnList: defaultAuthBtnList,
            areaId: userIp.areaId || '',
            areaName: userIp.areaName || ''
          };
          // 存储用户信息到浏览器缓存
          Session.set('userInfo', userInfos);
          Session.set('userObj', userRoles.user);
          Local.set(
            'userInfoPermission',
            JSON.stringify(userRoles.permissions)
          );
          return userInfos;
        }
      } catch (error: any) {
        if (error.code === 401) {
          ElMessageBox.alert(error.msg, '提示', {
            confirmButtonText: 'OK',
            callback: () => {
              Session.clear();
              window.location.href = window.location.pathname;
            }
          })
            .then(() => {})
            .catch(() => {});
        }
      }
    },
    async getIpProperty() {
      // 获取用户IP属地
      return new Promise(resolve => {
        useLoginApi()
          .getIpProperty()
          .then((res: any) => {
            // 拦截器会直接返回undefined这里做进一步处理
            if (!res) {
              resolve({});
              return {};
            }

            if (res.code === 200) {
              resolve({
                areaId: res.data.areaId,
                areaName: res.data.areaName
              });
            } else {
              resolve({});
            }
          })
          .catch(() => {
            resolve({});
          });
      });
    }
  }
});
