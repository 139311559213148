<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(series.length && data.length) && !loading"
  >
    <LineChart
      ref="refChart"
      v-if="!loading && series.length"
      :smooth="theme == 'bigscreen-theme-2' ? false : true"
      :series="series"
      :x-axis="{ data: xAxis }"
      y-axis
      tooltip
      :theme="theme"
      :key="updateKey"
      dataZoom
      v-memo="[]"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  inject,
  watch,
  toRef
} from 'vue';
import useAnalysisApi from '/@/api/analysis/index.ts';
import { isImage, getChartImage, utilDebounce } from '../../utils';
import { storeToRefs } from 'pinia';
import { useEditorStore } from '/@/stores/analysis';
import { cloneDeep } from 'lodash-es';
import { getDateByInterval } from '../../utils';
export default defineComponent({
  // 互动趋势
  name: 'AInteractionTrend',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const updateKey = ref();
    watch(toRef(props, 'theme'), () => {
      updateKey.value = new Date().getTime();
    });
    const refChart = ref();
    const imageUrl = ref('');
    const params = computed(() => {
      return props.params;
    });
    const { interactiveTrend } = useAnalysisApi();
    const xAxis = ref<any[]>([]);
    const series = ref<any[]>([]);
    const loading = ref(false);
    const data = ref<any[]>([]);
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      xAxis.value.splice(0);
      series.value.splice(0);
      try {
        const res = await interactiveTrend(params.value);
        if (res.code === 200) {
          const dateInterval = res?.data?.dateInterval;
          if (chartsClick) {
            drillPreviewData.value = Object.assign(
              {
                startPubTime: res?.data?.startPubTime,
                endPubTime: res?.data?.endPubTime,
                dateInterval
              },
              { aeResultData: cloneDeep(res?.data?.itemList || []) }
            );
            searchResultList();
          }
          const resData = res?.data?.itemList as any[];
          data.value = resData;
          const yAxis = [] as any[];
          resData.forEach((item: any) => {
            xAxis.value.push(getDateByInterval(item.date, dateInterval));
            yAxis.push(Number(item.count));
          });
          series.value.push({ data: yAxis });
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const saveChartImage = utilDebounce(() => {
      refChart.value && (imageUrl.value = getChartImage(refChart));
    }, 300);
    return {
      refChart,
      isImage,
      imageUrl,
      xAxis,
      series,
      loading,
      params,
      update,
      saveChartImage,
      data,
      updateKey
    };
  }
});
</script>
