import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import InteractionTrend from './src/index.vue';

InteractionTrend.install = (app: App): void => {
  app.component('AInteractionTrend', InteractionTrend);
  app.component(
    'AInteractionTrendProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default InteractionTrend;
