<template>
  <div v-loading="loading" v-nodata="!Boolean(dataList.length) && !loading">
    <OvalRingChart
      v-if="params.chartType === 'pie' && !loading && dataList.length"
      v-memo="[]"
      ref="refChart"
      :data="dataObject.seriesLine"
      tooltip
      :color="dataObject.color"
      layout
      legend
      label
      @chart:click="chartClick"
    />
    <LineChart
      v-memo="[]"
      ref="refChart"
      v-if="params.chartType === 'line' && !loading && dataList.length"
      :smooth="true"
      :series="dataObject.seriesLine"
      :x-axis="{ data: dataObject.xAxis }"
      y-axis
      tooltip
      legend
      :color="dataObject.color"
      dataZoom
      @chart:click="chartClick"
    />
    <ScaleLineChart
      v-if="params.chartType === 'scale' && !loading && dataList.length"
      ref="refChart"
      :smooth="true"
      :dataObject="dataObject"
      y-axis
      tooltip
      legend
      :theme="theme"
      dataZoom
      @chart:click="chartClick"
    ></ScaleLineChart>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  inject
} from 'vue';
import ScaleLineChart from '../_components/ScaleLineChart.vue';
import useAnalysisApi from '/@/api/analysis/index.ts';
import dayjs from 'dayjs';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { cloneDeep } from 'lodash-es';
import { getDateByInterval } from '../../utils';
import { useI18n } from 'vue-i18n';
import { useDataCenter } from '../../_hooks/use-data-center';
export default defineComponent({
  // 情感倾向
  name: 'AEmotionalTendency',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  components: {
    ScaleLineChart
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { emotionalTendency } = useAnalysisApi();
    const loading = ref(false);
    const tooltips = ref({ trigger: 'axis' });
    const dataObject = reactive({
      xAxis: [] as string[],
      color: ['#2055f4', '#f0a431', '#ed6363'],
      seriesLine: [
        { name: t('message.analysisDetails.polarityOption[0]'), type: 'line' }, // 正面
        { name: t('message.analysisDetails.polarityOption[2]'), type: 'line' }, // 中立
        { name: t('message.analysisDetails.polarityOption[1]'), type: 'line' } // 负面
      ],
      negative: [] as number[],
      neutrality: [] as number[],
      front: [] as number[],
      negativeSum: '',
      neutralitySum: '',
      frontSum: ''
    });
    const params = computed(() => {
      return props.params;
    });
    const { setCurrentDrillingScheme } = useDataCenter(params.value);
    const dataList = ref<Record<string, any>>([]);
    onMounted(() => {
      getData();
    });
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const apiData = ref();
    const setDrillPreviewData = () => {
      const dateInterval = apiData.value[0].dateInterval;
      drillPreviewData.value = Object.assign(
        {
          startPubTime: apiData.value[0]?.startPubTime,
          endPubTime: apiData.value[0]?.endPubTime,
          dateInterval
        },
        { aeResultData: cloneDeep(apiData.value || []) }
      );
    };
    function getData() {
      if (!params.value.schemeId) return;
      loading.value = true;
      dataObject.xAxis.splice(0);
      dataObject.negative.splice(0);
      dataObject.neutrality.splice(0);
      dataObject.front.splice(0);
      emotionalTendency(params.value)
        .then((res: any) => {
          apiData.value = res.data;
          const dateInterval = apiData.value[0].dateInterval;
          if (chartsClick) {
            setDrillPreviewData();
            searchResultList();
          }
          dataList.value = res.data;
          // 每项情感分类的返回的时间项不同，这里合并每项情感的时间
          dataObject.xAxis.push(
            ...[
              ...new Set(
                res.data
                  ?.map(
                    (item: {
                      articleTypeExposureTrend: { intervalTime: string }[];
                    }) => {
                      return item.articleTypeExposureTrend.map(
                        (c: { intervalTime: string }) => {
                          return c.intervalTime;
                        }
                      );
                    }
                  )
                  .flat()
              )
            ]
              .sort((a, b) => {
                return (new Date(a as string).getTime() -
                  new Date(b as string).getTime()) as number;
              })
              .map(date => {
                return getDateByInterval(date as Date, dateInterval);
              })
          );
          res.data.forEach((item: Record<string, any>) => {
            if (item.type === '0') {
              // 中立
              dataObject.neutralitySum = item.percent;
              dataObject.neutrality.push(
                ...item.articleTypeExposureTrend.map((v: any) => {
                  return v.sum;
                })
              );
              // 中立图表参数
              dataObject.seriesLine.map((v: Record<string, any>) => {
                if (v.name === t('message.analysisDetails.polarityOption[2]')) {
                  v.data = dataObject.neutrality;
                  v.value = item.sum;
                }
              });
            } else if (item.type === '1') {
              // 正面
              dataObject.frontSum = item.percent;
              dataObject.front.push(
                ...item.articleTypeExposureTrend.map((v: any) => {
                  return v.sum;
                })
              );
              // 正面图表参数
              dataObject.seriesLine.map((v: Record<string, any>) => {
                if (v.name === t('message.analysisDetails.polarityOption[0]')) {
                  v.data = dataObject.front;
                  v.value = item.sum;
                }
              });
            } else if (item.type === '2') {
              // 负面
              dataObject.negativeSum = item.percent;
              dataObject.negative.push(
                ...item.articleTypeExposureTrend.map(
                  (v: Record<string, any>) => {
                    return v.sum;
                  }
                )
              );
              // 负面图表参数
              dataObject.seriesLine.map((v: Record<string, any>) => {
                if (v.name === t('message.analysisDetails.polarityOption[1]')) {
                  v.data = dataObject.negative;
                  v.value = item.sum;
                }
              });
            }
          });
        })
        .finally(() => {
          loading.value = false;
        });
    }

    const update = () => {
      getData();
    };
    const chartClick = obj => {
      if (
        !props.drilling ||
        EditorStore.drillingTrigger !== 'chartItemClick' ||
        !obj.data
      )
        return;
      setCurrentDrillingScheme(params.value.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      loading,
      update,
      dataObject,
      tooltips,
      dataList,
      chartClick
    };
  }
});
</script>
<style scoped></style>
