export default {
  monitorReceive: {
    helpContent1:
      '1. Up to 3 sets of keywords can be configured. In each input box, you can enter more than one keyword by pressing the Enter key. And different keywords are subject to the OR relationship.',
    helpContent2:
      '2. Different input boxes are subject to the AND relationship.',
    helpContent3:
      '3. You can enter Chinese characters, English words, figures, and special characters. The character limit is 100.',
    postComments:
      'Post comments refer to all the comment information that matches the post in the plan; Only available when the scheme enables source monitoring.',
    schemeName: 'Program name cannot be empty',
    wordSpan: 'Keyword spacing range cannot be empty',
    messageSourceConfig: 'Account number cannot be empty',
    rules: {
      schemeName: [
        { required: true, message: '方案名称不可为空' }
        // { match: /^[a-zA-Z0-9_\( \)\（ \）\-\u4e00-\u9fa5]+$/, message: '只允许输入汉字，英文，数字，括号，中划线-，下划线_' },
      ],
      wordSpan: [{ required: true, message: '关键词词距范围不可为空' }],
      messageSourceConfig: [
        { required: true, message: '账号不可为空' },
        {
          validator: (val: any[], callback: (error: string) => void) => {
            !val.length && callback('error');
          },
          message: '账号不可为空'
        }
      ]
    },
    keywordTip:
      '1. You can enter more than one keyword by pressing the Enter key. Articles that contain any of the keywords will be detected. , 2. Region Keyword, Entity Keyword, and Event Keyword are subject to the AND relationship. Only the articles that contain the three kinds of keywords will be detected. For example, if you enter "NYC" in the Region Keyword field, "Tom" in the Entity Keyword field, and "take bribes" in the Event Keyword field, only the articles that contain the 3 keywords will be detected.',
    exculedKeywordTip:
      '1. You can enter more than one keyword by pressing the Enter key. Multiple keywords are subject to the OR relationship. ,2. The articles that contain any of the specified keywords will be excluded by the system.',
    wordSpanTip: [
      '1.地域词、主体词、事件词在信息中出现的间距值。词距范围0~500。',
      '2.满足词间距的信息，将出现在精准信息列表中。'
    ],
    wordSpanTipNew:
      '1. Keyword Distance indicates the distance between the keywords randomly selected from the Region Keyword, Entity Keyword, and Event Keyword categories, with its value ranging from 0 to 500., 2. When Keyword Distance Matching is enabled, only the articles whose keyword distance is within the specified value will appear in the monitoring information list. ,3. If any of the Region Keyword, Entity Keyword, and Event Keyword fields is/are left empty, the field(s) will be ignored. When Keyword Distance Matching is disabled, the keyword monitoring rule prevails.',
    accountTip:
      '1.You can specify any media or accounts for monitoring., 2.If no keywords are configured, all the data of the specified media/accounts will be displayed. When any keywords are configured, only the data of the specified media/accounts in relation to the keywords will be displayed.',
    accountTipGetUser:
      '1.You can specify any media or accounts for monitoring., 2.If no keywords are configured, all the data of the specified media/accounts will be displayed. When any keywords are configured, only the data of the specified media/accounts in relation to the keywords will be displayed., 3.After enabling source monitoring, alerts can be issued for comments posted by the target source.',
    EditorialSolutions: 'Editorial Program',
    CreatedSolutions: 'Create Monitor Scheme',
    ProgramName: 'Scheme Name',
    ProgramNameholder: 'Enter the scheme name',
    ContentMonitoring: 'Content Monitoring',
    Geographicalwords: 'Region Keyword',
    Mainwords: 'Entity Keyword',
    Eventwords: 'Event Keyword',
    errTip:
      'Region Keyword, Entity Keyword and Event Keyword cannot be empty at the same time',
    ExclusionWords: 'Exclude Keyword',
    Precisesettings: 'Keyword Distance Matching',
    Keywordspacingrange: 'Keyword Distance Range',
    TargetedMonitoring: 'Targeted Monitoring',
    MonitoringCancellation: 'Cancel',
    Monitoringpreservation: 'Save',
    Preview: 'Preview',
    PatternOfExpression: 'Expression Mode',
    StandardMode: 'Standard Mode',
    MonitorKeyWords: 'Monitor Keyword',
    MonitorKeyWordsTip: [
      `1. An expression can contain logic operators "&", "{'|'}", and "!" that stand for AND, OR, and NOT relationships respectively. `,
      `2. Each set of keywords must be enclosed in the brackets "()". `,
      `Example: (Beijing&Olympic Winter Games)&(Skiing{'|'}Skating{'|'}Ice Hockey{'|'}Luge)!(Bobsleigh{'|'}Curling)(China{'|'}Beijing)&(High-tech Winter Olympics{'|'}Snow Tech)`
    ],
    confirm: 'Confirm',
    reference: 'Reference',
    schemeList: 'Scheme List',
    copy: 'Copy',
    popTip:
      'Switching to Standard mode will clear the current Settings. Are you sure to switch to standard mode?',
    informationSourceMonitoring: 'Information Source Monitoring',
    informationSourceMonitoringBtn: [
      'Specified Info Source',
      'Exclude Info Source'
    ],
    specifiedInfoSourceTip: [
      '1. More than one information source can be selected. Different information sources are subject to the OR relationship.',
      '2. When no keywords are configured, all the data of the specified information sources will be displayed. When any keywords are configured, only the data of the specified information sources in relation to the keywords will be displayed.'
    ],
    excludeInfoSourceTip: [
      '1. More than one information source can be selected. Different information sources are subject to the OR relationship. ',
      '2.The specified information sources are exclude by the monitor scheme.'
    ],
    dataInfo: 'Data Filtering',
    yayi: 'YaYi'
  },
  TargetedMonitoring: {
    all: 'All',
    placeholder:
      'Enter the information source name, account name, or domain name',
    search: 'Search',
    specialPre:
      'Rule for Special Character Search Matching: Fuzzy search is supported for ',
    specialBeh: '',
    titles: 'Info Source List, Selected Info Source',
    nodata: 'No data available',
    noCheckdata: 'No data selected',
    loder: 'Loading...',
    intheEnd: 'The end',
    contentPre: 'Confirm to clear all',
    contentBeh: '？',
    filter: 'Filter selected accounts',
    confirm: 'Determine'
  },
  MonitorIndex: {
    goback: 'Back',
    name: 'Expert advice',
    list: 'Information List',
    photo: 'Chart Analysis',
    waring: 'Alert Settings',
    nodata:
      'No monitoring program has been added yet, so go ahead and create one!',
    add: 'Add',
    MonitoringProgram: 'Monitor Scheme',
    CreatProgram: 'Create Scheme',
    CreateCategory: 'Create Folder',
    edit: 'Edit',
    modalPlaceholder: [
      'The folder name cannot be empty',
      'The folder name cannot exceed 20 characters',
      'Enter a folder name'
    ],
    modalTitle: [
      'Create Folder',
      'Edit Folder',
      'Delete Folder',
      'Delete Scheme'
    ],
    deleteTip: [
      'Deleting a folder deletes all schemes under the folder. Are you sure you want to delete the folder?',
      'Are you sure you want to delete the selected scheme?'
    ],
    filterTip: 'Enter the scheme name',
    treeNoDataTip: 'Note: No '
  },
  filter: {
    ReadAll: 'All',
    ReadingStatus: 'Read Status',
    Read: 'Read',
    Unread: 'Unread',
    pubtime: 'Publication Time',
    warehousetime: 'warehouse entry time',
    Emotional: 'Emotional Tendencies',
    mediaType: 'Media Types',
    time1: 'Today',
    time2: 'Past 24 hours',
    time3: 'Past 7 days',
    time4: 'Custom',
    time5: 'Past 3 days',
    time14: 'Past 14 days',
    Emotional1: 'Positive',
    Emotionalf1: 'Positive',
    Emotional2: 'Neutral',
    Emotionalf2: 'Neutral',
    Emotional3: 'Negative',
    Emotionalf3: 'Negative',
    Emotionalf4: 'Irrelevant',
    EmotionalfTip: 'Provided by YaYi LLM',
    codeType: 'Data Type',
    screening: 'Precise Screening',
    sameindex: 'Similar information',
    codeType1: 'Original Text',
    codeType2: 'Retweet',
    codeType3: 'Comment',
    codeType4: 'Post comments',
    screening1: 'All',
    screening2: 'Precise',
    sameindex1: 'de-duplication',
    sameindex2: 'No removal of duplicates',
    Morefilters: 'More Filters',
    search: 'Search',
    Filtercriteria: 'Filter Criteria',
    resite: 'Reset',
    moreSearch: 'Search',
    all: 'All',
    content: 'Text Match',
    titlePick: 'Title Match',
    keyCodePlaceholder: 'Enter a keyword and press the Enter key to confirm it',
    Geographical: 'Geographical Identification',
    IP: 'IP Properties',
    MediaProperties: 'Media Properties',
    Contentmentions: 'Content mentions',
    saveFilters: 'Save Filters',
    saveFiltersTip: [
      '1. The saved filters are effective for the current Monitor scheme only.',
      '2. The saved filters are loaded by default when the scheme is enabled the next time. ',
      '3. The custom filters on the publication time/monitoring time cannot be saved.'
    ],
    filtersTab: ['Account Property', 'Region/Language', 'Advanced Filter'],
    monitoringTime: 'Monitoring Time'
  },
  alertSettings: {
    system: 'System pop-up warning',
    title: 'Pubilc Opinion Alert',
    open: 'On',
    close: 'Off',
    titleTip:
      'Alert notifications will be automatically sent to the relevant contacts according to the user-defined rules.',
    Earlywarning: 'Alert Reception Settings',
    warningWay: 'Reception Method:',
    weixin: 'WeChat public reception',
    newContact: '+ Add Contacts',
    oldContact: '+ Add from Contacts',
    name: 'Name',
    namePlaceholder: 'Enter the name',
    pubtime: 'Creation time',
    warehousetime: 'warehouse entry time',
    operation: 'Operation',
    editor: 'Edit',
    noContact: "You haven't added a WeChat contact yet",
    receivingTime: 'Reception Time:',
    everyday: 'Everyday',
    workday: 'Workday',
    EWF: 'Alert Frequency:',
    EWC: 'Alert Content Settings',
    EWkeycode: 'Alert Term',
    EWplaceholder:
      'Enter the alert term(s). Press the Enter key to confirm each term before you enter the next one.',
    add: 'Add',
    delete: 'Delete',
    ExclusionWords: 'Exclude Term',
    ExWplaceholder:
      'Enter the exclude term(s). Press the Enter key to confirm each term before you enter the next one.',
    tend: 'Sentiment:',
    mediatype: 'Media Type:',
    Precisescreening: 'Precise screening:',
    target: 'Text Position:',
    inexType: 'Data Type:',
    cancel: 'Cancel',
    save: 'Save',
    addUser: 'Add new user',
    ScanCode: 'Scan to add：',
    refresh: 'Refresh',
    ScanCodeTip:
      'Scan the QR code to follow our Wechat official account for alert notifications.',
    SacnCodeSuccess: 'Scan the code successfully',
    formName: 'Name:',
    formNameRequire: 'Please enter your name first',
    formNamePre: '(Note: You can go to ',
    formNameMid: '"User Center" > "My Contacts"',
    formNameEnd:
      ' to view or modify any contacts that are already added to your contacts list.)',
    myContact: 'My contacts',
    wxContactPre: 'WeChat Contacts',
    wxContactMid: 'Total ',
    wxContactEnd: 'member',
    inputContact: 'Enter contact',
    Realtime: 'Real-time',
    minutes5: '5 minutes',
    minutes10: '10 minutes',
    minutes30: '30 minutes',
    minutes60: '1 hour',
    minutes120: '2 hours',
    minutes240: '4 hours',
    minutes480: '8 hours',
    minutes720: '12 hours',
    minutes1440: '1 day',
    pleaseInputName: 'Enter the name',
    informationClassification: 'information classification:',
    analogInformation: 'analog information:',
    weChatID: 'WeChat ID'
  },
  messageTip: {
    isRead: 'Read',
    creatError: 'Failed to create',
    creatSuccess: 'Created successfully',
    editorSuccess: 'Modified successfully',
    editorError: 'Modification failure',
    keycodeLimit: 'The total number of keywords should not exceed',
    keycodeLimitEnd: '',
    Nonrepeatable: 'Keywords cannot be repeated',
    codeLengthLimit: 'The maximum length of a single keyword character is',
    alreadyexists: 'This contact already exists',
    noSame: 'No duplicate contacts can be added',
    setContact: 'Please set the WeChat contact and save it',
    choose: 'Please select the program and then operate',
    addWXcontact: 'Please add WeChat contact',
    Alread: 'Already exists warning setting information, can not be added',
    KeycodeAndExclusionWords:
      'Early warning words and exclusion words must not be repeated',
    keycodeLength: 'Keyword character length over 100',
    startTime: 'The start time must be less than the end time',
    saveSuccess: 'Save successfully',
    Noname: 'Name cannot be empty',
    ScanTheCode: 'Please scan the code first to follow',
    InputName: 'Enter the name',
    keyCodeRepeat: 'Keywords cannot be repeated',
    MaxLength: 'The maximum length of a single keyword character is'
  },
  yayiModel: {
    poweredAnalysis: 'YaYi-powered Analysis',
    analysisContents: 'Analysis Contents',
    example: 'Example',
    analysisContentsItem: [
      'Article Summary',
      'Sentiment Analysis',
      'Entity Recognition',
      'Core Opinion',
      'Handling Suggestion',
      'Content Recognition'
    ],
    analysisContentsTab: [
      'Entity',
      'Background',
      'Sentiment',
      'Content Recognition'
    ],
    analysisContentsAdd: 'Add',
    analysisContentsColumn: [
      'No.',
      'Entity Name',
      'Background',
      'Negative Sentiment',
      'Content Recognition Tag',
      'Action'
    ],
    deRowTip: [
      [
        'Note: Not required, such as filling in the large model will be based on the perspective of the configuration of the main position analysis, up to three',
        'Example: Government management, Zhongguancun street'
      ],
      [
        'Note: Not required, if part of the content can not be related to the analysis, you can supplement the relevant knowledge of your field of interest, up to three',
        'Example: Zhongguancun, Yanyuan and Qinghuayuan streets all belong to Haidian District'
      ],
      [
        'Note: Not required, please list the negative types that need to be focused on by the large model, up to ten negative types',
        'Examples: natural disaster, environmental pollution, safety accident'
      ],
      [
        'Note: Required when enabled. You can add 1 to 5 content recognition tags for the YaYi LLM to focus on.',
        "Example: Tourist complaint, People's livelihood complaint, Consumer right defense"
      ]
    ]
  },
  yayiModelIndex: [
    'China Broadcasting Network Shandong Company and Shandong Cultural Tourism Group signed a strategic cooperation',
    'The article explicitly mentions that China Broadcasting Network Shandong Company and Shandong Cultural Tourism Group have signed a strategic cooperation agreement.',
    'Related',
    'China Broadcasting Network Shandong Company, Shandong Cultural Tourism Group',
    'The two parties have a solid foundation for cooperation and broad prospects for development. They will leverage their respective advantages in resources, capital, channels, and technology to jointly promote industrial cooperation. This collaboration aims to make new and greater contributions to the construction of a strong digital province and to promote the diverse integration of culture and tourism.',
    `China Broadcasting Network Shandong Company is eager to collaborate with the Cultural Tourism Group in developing cultural tourism industry projects and serving the rural revitalization strategy, aiming to empower the high-quality development of Shandong's cultural tourism industry through "digital intelligence."`,
    'The two parties will establish an in-depth cooperation mechanism in areas such as cloud resource construction and project operations, jointly expanding government information technology services, and deepening cooperation in smart hotel, smart scenic area, and smart park initiatives.',
    'China Broadcasting Network Branches, Subsidiaries',
    'Further strengthen cooperation with Shandong Cultural Tourism Group, jointly promote the development of the cultural tourism industry, increase the construction of cloud resources and the expansion of government informatization business, and enhance the level and quality of services.',
    'Entity involved ',
    'Entity mentioned ',
    'None',
    'Neutral',
    'Positive',
    'Negative',
    'Irrelevant'
  ]
};
