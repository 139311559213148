<template>
  <div v-loading="loading" v-nodata="!Boolean(data.length) && !loading">
    <img
      v-if="isImage && imageUrl"
      :src="imageUrl"
      style="width: 100%; height: 100%"
    />
    <RadarChart
      ref="refChart"
      v-if="!isImage && !loading && data.length"
      :tooltip="tooltip"
      :series="series"
      :indicator="indicator"
      :symbolicon="theme == 'bigscreen-theme-2' ? undefined : href"
      :theme="theme"
    />
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref } from 'vue';
import { isImage, getChartImage, utilDebounce } from '../../utils';
import { TooltipComponentOption } from 'echarts';
import useAnalysisApi from '/@/api/analysis';
const mediaTagList = [
  '中央媒体',
  '省级媒体',
  '地市媒体',
  '商业门户',
  '境外重点'
];
export default defineComponent({
  // 媒体等级分布
  name: 'ARankDistribution',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const { mediaLevelDistributed } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const refChart = ref();
    const loading = ref<boolean>(false);
    const imageUrl = ref<string>('');
    const series = ref<any[]>([]);
    const indicator = ref<any[]>([]);
    const data = ref<any[]>([]);
    const href = new URL('./images/radarYuan.png', import.meta.url).href;
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      data.value.splice(0);
      indicator.value.splice(0);
      series.value.splice(0);
      imageUrl.value = '';
      try {
        const res: any = await mediaLevelDistributed(params.value);
        if (res.code === 200 && res?.data?.itemList) {
          mediaTagList.forEach((v: string) => {
            data.value.push({
              mediaTag: v,
              count:
                res?.data?.itemList.find((it: any) => {
                  return it.mediaTag === v;
                })?.count || 0
            });
          });
          const max = Math.max(...data.value.map(v => v.count));
          data.value.forEach((v: any) => {
            indicator.value.push({
              name: v.mediaTag,
              max: max
            });
          });
          series.value.push({
            value: data.value.map(v => v.count)
          });
          nextTick(() => {
            saveChartImage();
          });
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
        imageUrl.value = '';
      }
    };
    const saveChartImage = utilDebounce(() => {
      refChart.value && (imageUrl.value = getChartImage(refChart));
    }, 300);
    const tooltip: TooltipComponentOption = {
      show: true,
      enterable: true,
      confine: true,
      formatter: (param: any) => {
        return (
          '<div style="padding: 4px 8px;">' +
          data.value
            .map(function (j, i) {
              return '<span>' + j.mediaTag + '：' + param.value[i] + '</span>';
            })
            .join('<br>') +
          '</div>'
        );
      }
    };
    const update = () => {
      getData();
    };
    onMounted(() => {
      getData();
    });
    return {
      // eslint-disable-next-line vue/no-dupe-keys
      params,
      refChart,
      loading,
      isImage,
      imageUrl,
      series,
      indicator,
      tooltip,
      data,
      href,
      update,
      saveChartImage
    };
  }
});
</script>
