<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(data.length && series.length)"
    style="overflow-y: auto"
    class="chart-box-in"
  >
    <StripChart
      ref="refChart"
      v-if="!isImage && !loading && series.length"
      :tooltip="tooltip"
      :series="series"
      :label="labelDefault"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  nextTick,
  reactive
} from 'vue';
import { useSearchApi } from '/@/api/search';
import { ElMessage as msg } from 'element-plus';
import { isImage, getChartImage, utilDebounce } from '../components/utils';
import { storeToRefs } from 'pinia';
import { useSearchAnalyse } from '/@/stores/searchAnalyse';
import { useDictStore } from '/@/stores/dict';
import { cloneDeep } from 'lodash-es';
import { getfilterList } from '../components/utils';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  // 活跃榜单
  name: 'SAccountList',
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const refChart = ref();
    const imageUrl = ref('');
    const { analysisActiveWebsite } = useSearchApi();
    const paramsReset = computed(() => {
      return props.params;
    });
    const series = ref<any[]>([]);
    const data = ref<any[]>([]);

    const loading = ref(false);
    const labelDefault = {
      color: '#272A31'
    };
    const tooltip = {
      formatter: param => {
        const { data, marker } = param as any;
        return `
      <div style="max-width: 320px;white-space:normal;">
        <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${t(
          'message.analysisComponents.hotspotInformationComponents[0]'
        )}</div>
        <div style="margin: 10px 0 0;line-height:1;">
          <div style="margin: 0px 0 0;line-height:1;">
            ${marker}
            <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${
              data.originName || data.name
            }</span>
            <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${
              data.origin
            }</span>
            <div style="clear:both"></div>
          </div>
          <div style="clear:both"></div>
        </div>
      </div>
      `;
      }
    };
    const searchAnalyseStore = useSearchAnalyse();
    const dictStore = useDictStore();
    const {
      searchAnalyseParams,
      sAnalysechannelIds,
      dataAuthType,
      designatedMediaLabelArr,
      searchTime
    } = storeToRefs(searchAnalyseStore);
    const { mediaTypeList, mediaTypeLocalList, mediaTypeForeignList } =
      storeToRefs(dictStore);
    let resParams = reactive({});
    const getData = async () => {
      try {
        resParams = cloneDeep(searchAnalyseParams.value);
        series.value.splice(0);
        loading.value = true;
        delete resParams.dataSource;
        let defalutMediaTypeList = [];
        if (dataAuthType.value === 0) {
          defalutMediaTypeList = cloneDeep(mediaTypeList.value);
        }
        if (dataAuthType.value === 1) {
          defalutMediaTypeList = cloneDeep(mediaTypeLocalList.value);
        }
        if (dataAuthType.value === 2) {
          defalutMediaTypeList = cloneDeep(mediaTypeForeignList.value);
        }
        const arr = getfilterList(
          defalutMediaTypeList,
          sAnalysechannelIds.value,
          'value'
        );
        resParams = Object.assign({}, resParams, {
          dataSourceInfos: arr,
          schemeId: paramsReset.value.schemeId,
          size: paramsReset.value.size
        });
        if (resParams?.designatedMediaLabelId === 0) {
          resParams.searchDesignatedLabelVOS = [];
        } else {
          resParams.searchDesignatedLabelVOS =
            designatedMediaLabelArr.value.filter(item => {
              return item.id === resParams.designatedMediaLabelId;
            });
        }
        if (searchTime.value.startPubTime && searchTime.value.endPubTime) {
          (resParams.realTime = 0),
            (resParams.startPubTime = searchTime.value.startPubTime),
            (resParams.endPubTime = searchTime.value.endPubTime);
        }
        const res = await analysisActiveWebsite(resParams);
        if (res.code === 200) {
          const resData = res?.data?.itemList as any[];
          data.value = resData;
          // if (params.value.stackType == 0) {
          // 非堆叠数据
          series.value.push({
            name: 'SAccountList',
            data: resData.map((item: any) => {
              return {
                name: item.websiteName,
                value: Number(item.count),
                label: { color: '#272A31' },
                color: '#0C7CFF'
              };
            })
          });
        } else {
          msg.error(res.msg);
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      refChart,
      isImage,
      imageUrl,
      series,
      loading,
      update,
      data,
      tooltip,
      labelDefault
    };
  }
});
</script>
<style lang="scss" scoped>
.chart-box-in {
  width: 100%;
  height: 430px !important;
  :deep(.el-loading-mask) {
    z-index: 9 !important;
  }
}
</style>
