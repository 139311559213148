import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import SexDistribution from './src/index.vue';

SexDistribution.install = (app: App): void => {
  app.component('ASexDistribution', SexDistribution);
  app.component(
    'ASexDistributionProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default SexDistribution;
