<template>
  <div :class="['bigscreen-title', theme]">
    <span class="name">{{ obj.name }}</span>
    <div class="date-info" v-if="theme == 'bigscreen-theme-2'">
      <span>{{ dateInfo.date }}</span>
      <span class="info-week">{{ dateInfo.week }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  onMounted,
  reactive,
  onUnmounted,
  watch,
  toRef
} from 'vue';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'ABigscreenTitle',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const { t } = useI18n();
    const obj = computed(() => {
      return props.params;
    });
    const timer = ref();
    const dateInfo = reactive({
      date: '',
      week: ''
    });
    const clearTimer = () => {
      timer.value && clearInterval(timer.value);
    };
    const showDateInfo = () => {
      dayjs.locale('zh-cn');
      if (props.theme != 'bigscreen-theme-2') {
        clearTimer();
      }
      timer.value = setInterval(() => {
        dateInfo.date = dayjs().format('YYYY-MM-DD');
        dateInfo.week = t(`message.Symbol.week.${dayjs().format('dddd')}`);
      }, 1000);
    };
    watch(toRef(props, 'theme'), () => {
      showDateInfo();
    });
    onMounted(() => {
      showDateInfo();
    });
    onUnmounted(() => {
      clearTimer();
    });
    return {
      obj,
      dateInfo
    };
  }
});
</script>
<style scoped lang="scss">
.bigscreen-title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.bigscreen-theme-1 {
    color: #ffffff;
    font-size: 26px;
    font-weight: 600;
    background: url('/@/assets/bigscreen/bigscreen-title.png') no-repeat
      center/cover;
  }

  &.bigscreen-theme-2 {
    background: url('/@/assets/bigscreen/theme-2/bigscreen-title.png') no-repeat
      center/cover;

    > span.name {
      color: #ffffff;
      font-size: 54px;
      font-family: YouSheBiaoTiHei;
      background-image: -webkit-linear-gradient(top, #fff, #e3feff, #9afcff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
      letter-spacing: 7px;
    }

    .date-info {
      position: absolute;
      right: 13px;
      bottom: 0;
      color: #fff;
      font-size: 21px;
      font-family: HelveticaNeue;
      line-height: 30px;

      > span {
        padding: 0 12px;

        &.info-week {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            width: 2px;
            height: 20px;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            background: url('/@/assets/bigscreen/theme-2/decoration-shu.png')
              no-repeat center/cover;
          }
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
        background: url('/@/assets/bigscreen/theme-2/decoration-rili.png')
          no-repeat center/cover;
      }
    }
  }
}
</style>
