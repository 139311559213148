<script lang="ts" setup>
import {
  shallowRef,
  ShallowRef,
  ref,
  onMounted,
  Ref,
  watchEffect,
  inject
} from 'vue';
import BaseChart from '../BaseChart/BaseChart.vue';
import {
  buildAxis,
  calcRange,
  lag,
  RangeTypes,
  ECOption,
  buildGridWidthLegend,
  buildLegend,
  buildAxisLabelFormatter
} from '../utils';
import { LineSeriesOption } from 'echarts/charts';
import { XAXisComponentOption, YAXisComponentOption } from 'echarts';
import { flow } from 'lodash-es';
import nzh from 'nzh';
import dayjs from 'dayjs';
import useChartClick from '../hooks/useChartClick';
import { useI18n } from 'vue-i18n';

interface StageDataItem {
  name: string;
  value: (string | number)[]; //[string, number],
  info?: {
    firstPublish: Record<string, number>;
    keyInfo: Record<string, string>[]; //  [{name: '', title: ''}]
  };
}
interface StageEvolutionProps {
  series: {
    name: string;
    data: StageDataItem[];
  };
  withUnit?: boolean;
  theme?: string;
}

interface Emits {
  (e: 'chart:click', params: any): void;
}

enum LegendName {
  KeyInfo = '关键信息',
  FirstPublish = '首发媒体'
}

const props = withDefaults(defineProps<StageEvolutionProps>(), {
  withUnit: true
});
const emit = defineEmits<Emits>();

let option: ShallowRef<ECOption> = shallowRef({});
let chartRef = useChartClick(emit);
let selectedLegend = ref({
  [LegendName.KeyInfo]: false,
  [LegendName.FirstPublish]: false
});
type UnWrapRef<T> = T extends Ref<infer U> ? U : never;
type SelectedType = UnWrapRef<typeof selectedLegend>;
const chartsClick = inject('chartsClick', undefined);
onMounted(() => {
  chartRef.value?.chart?.on('legendselectchanged', (params: any) => {
    selectedLegend.value = (params as { selected: SelectedType }).selected;
  });
});

watchEffect(() => {
  const encode = nzh.cn;
  const xAxis = {
    type: 'time',
    splitLine: { show: false },
    axisLine: {
      show: true
    },
    axisLabel: {
      showMaxLabel: true,
      formatter(param: number) {
        let str = '';
        data.forEach(item => {
          param ==
            new Date(String(item.value[0]).replace('-', ' ')).getTime() &&
            (str = dayjs(param).format('MM-DD'));
        });
        return str;
      },
      color:
        props.theme == 'bigscreen-theme-2' ? 'rgba(216,240,255,0.6)' : undefined
    },
    triggerEvent: chartsClick
  } as XAXisComponentOption;
  const axisLabel = buildAxisLabelFormatter(props);
  const yAxis = buildAxis('y', {
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color:
          props.theme == 'bigscreen-theme-2' ? 'rgba(176,215,255,0.25)' : '#fff'
      }
    },
    ...{
      ...axisLabel,
      axisLabel: {
        ...axisLabel.axisLabel,
        color:
          props.theme == 'bigscreen-theme-2'
            ? 'rgba(216,240,255,0.6)'
            : undefined
      }
    }
  }) as YAXisComponentOption;
  const propSeries = props.series;
  const data = propSeries.data || [];
  const valueData = data.map(d => d.value[1] as number);
  const timeData = data.map(d => d.value[0] as string);
  const ranges = flow(lag, calcRange);
  const trendRange = ranges(valueData);
  type Range = typeof trendRange;

  const getTime = (start: boolean, time: string, prevent?: boolean) => {
    let sign = 1;
    if (start) {
      sign = -1;
    }

    let $day = dayjs(time);
    // 最开始的节点不需要向前推12个小时
    if (!prevent) {
      $day = $day.add(12 * sign, 'hours');
    }
    return $day.format('YYYY-MM-DD HH:mm:ss');
  };
  const startTime = (time: string, prevent?: boolean) =>
    getTime(true, time, prevent);
  const endTime = (time: string, prevent?: boolean) =>
    getTime(false, time, prevent);

  const getAreaData = (
    style: Record<string, string | number>,
    time: string[],
    top = 10
  ) => {
    let name = style.name;
    const order = style.order as number;
    if (order > 0) {
      name = encode.encodeS(order + 1) + '次' + '\n' + name;
    }
    (style.order as number)++;
    return [
      {
        name: name,
        itemStyle: {
          color: style.area
        },
        label: {
          color: style.label,
          position: 'insideTop',
          offset: [0, props.theme == 'bigscreen-theme-2' ? 0 : top],
          width: 1
        },
        xAxis: time[0]
      },
      {
        xAxis: time[1]
      }
    ];
  };
  const { t } = useI18n();
  // 计算标识区域的范围
  const calcMarkArea = (trend: Range, time: string[]) => {
    const base: Record<
      'decrement' | 'increment' | 'peak',
      Record<string, any>
    > = {
      decrement: {
        name: t('message.analysisDetails.phasedEvolutionItem[0]'), // 回落
        area:
          props.theme == 'bigscreen-theme-2'
            ? ' rgba(255,255,255,0.04)'
            : 'rgba(0, 204, 95, 0.07)',
        label:
          props.theme == 'bigscreen-theme-2'
            ? 'rgba(216,240,255,0.8)'
            : '#00CC5F',
        order: 0
      },
      increment: {
        name: t('message.analysisDetails.phasedEvolutionItem[1]'), // 发展
        area:
          props.theme == 'bigscreen-theme-2'
            ? ' rgba(255,255,255,0.04)'
            : 'rgba(255, 160, 0, 0.07)',
        label:
          props.theme == 'bigscreen-theme-2'
            ? 'rgba(216,240,255,0.8)'
            : '#FFA000',
        order: 0
      },
      peak: {
        name: t('message.analysisDetails.phasedEvolutionItem[2]'), // 高峰
        area:
          props.theme == 'bigscreen-theme-2'
            ? 'rgba(255,255,255,0.08)'
            : 'rgba(254, 0, 56, 0.07)',
        label:
          props.theme == 'bigscreen-theme-2'
            ? 'rgba(216,240,255,0.8)'
            : '#FE0038',
        order: 0
      }
    };

    const result = [];
    const len = trend.length;
    if (len == 1) {
      const rangeStyle = base[trend[0].type];
      return [getAreaData(rangeStyle, [startTime(time[0]), endTime(time[0])])];
    }

    for (let i = 0; i < len; i++) {
      const cur = trend[i];
      const next = trend[i + 1];

      const curStyle = base[cur.type];

      // trend start 和 end 都表示原数据的第 start 或 end 个区间
      let start = i === 0 ? cur.start : cur.start + 1;
      if (
        cur.type === RangeTypes.increment &&
        next?.type === RangeTypes.decrement
      ) {
        start <= cur.end &&
          result.push(
            getAreaData(curStyle, [
              startTime(time[start], i === 0),
              endTime(time[cur.end])
            ])
          );
        result.push(
          getAreaData(
            base.peak,
            [startTime(time[cur.end + 1]), endTime(time[cur.end + 1])],
            5
          )
        );
      } else {
        result.push(
          getAreaData(curStyle, [
            startTime(time[start], i === 0),
            endTime(time[cur.end + 1], i === len - 1)
          ])
        );
      }
    }

    return result;
  };

  const getAreaByTime = (
    markArea: ReturnType<typeof calcMarkArea>,
    time: string
  ) => {
    const area = markArea.find(area => {
      const [start, end] = area;
      const cur = dayjs(time);
      return dayjs(start.xAxis).isBefore(cur) && dayjs(end.xAxis).isAfter(cur);
    });
    return area;
  };

  const getAreaColor = (time: string) => {
    const area = getAreaByTime(markAreaData, time);
    return area?.[0].label?.color || '#FFA000';
  };

  let formatter = (params: any) => {
    const data = params[0].data as StageDataItem;
    const selected = selectedLegend.value;
    const firstPublish = (num: number) =>
      selected[LegendName.FirstPublish]
        ? `
      <div style="margin-top: 0.2em;">${t(
        'message.analysisDetails.phasedEvolutionDetail[0]'
      )}：${num}</div> 
  `
        : '';
    const keyInfo = (info: Record<string, string>[]) => {
      return selected[LegendName.KeyInfo]
        ? `
      <div style="margin-top: 0.2em;">
          ${info
            .map(
              d =>
                `<div style="margin-top: 0.1em; overflow:hidden;text-overflow: ellipsis; ">
            <span style="display:inline-block;border: 1px solid ${getAreaColor(
              data.value[0] as string
            )}; width: 10px; height: 10px;border-radius: 50%;"></span>
            【${d.name}】
            ${d.title}
          </div>`
            )
            .join('')}
      </div>
      `
        : '';
    };

    return `
    <div style="padding: 0.2em 0.4em; max-width: 300px; overflow: hidden; white-space: nowrap;">
      <div>${data.name}</div>
      <div style="margin-top: 0.2em;">${t(
        'message.analysisDetails.phasedEvolutionDetail[1]'
      )}：${data.value[1]}</div>
      ${firstPublish(data.info?.firstPublish?.[data.name] || 0)}
      ${keyInfo(data.info?.keyInfo || [])}
    </div> 
  `;
  };

  let markAreaData = calcMarkArea(trendRange, timeData);
  let grid = buildGridWidthLegend({ right: 16, bottom: 30, top: 10 });
  let legend = buildLegend({
    icon: 'roundRect',
    itemWidth: 16,
    itemHeight: 6,
    itemGap: 30,
    textStyle: {
      lineHeight: 13
    },
    inactiveBorderWidth: 0,
    itemStyle: {
      borderWidth: 0
    },
    data: [
      {
        name: LegendName.KeyInfo
      },
      {
        name: LegendName.FirstPublish
      }
    ],
    selected: {
      [LegendName.KeyInfo]: false,
      [LegendName.FirstPublish]: false
    }
  });

  let series: LineSeriesOption[] = [
    {
      type: 'line',
      symbol: 'circle',
      symbolSize: 8,
      areaStyle: {
        color:
          props.theme == 'bigscreen-theme-2'
            ? {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: `rgba(67,164,245,0.5)`
                  },
                  {
                    offset: 1,
                    color: `rgba(67,164,245,0)`
                  }
                ]
              }
            : '#048FFF',
        opacity: props.theme == 'bigscreen-theme-2' ? 1 : 0.2
      },
      itemStyle: {
        borderWidth: 1,
        color:
          props.theme == 'bigscreen-theme-2'
            ? 'rgba(67,164,245,1)'
            : 'rgba(4, 143, 255, 0.07)'
      },
      smooth: false,
      data,
      markArea: {
        silent: true,
        data: markAreaData as unknown as any
      }
    },
    {
      type: 'line',
      name: LegendName.KeyInfo,
      data: []
    },
    {
      type: 'line',
      name: LegendName.FirstPublish,
      data: []
    }
  ];

  option.value = {
    // legend,
    grid,
    xAxis,
    yAxis,
    series,
    tooltip: {
      trigger: 'axis',
      formatter
    },
    dataZoom: {
      showDetail: false,
      startValue: data[0]?.value[0],
      endValue: data[data.length - 1]?.value[0],
      labelFormatter: (timeStr: Date, date: string) => {
        return dayjs(date).format('MM-DD');
      },
      zoomOnMouseWheel: 'ctrl',
      height: 20,
      bottom: 10,
      backgroundColor: '#F4F8FE',
      fillerColor: 'rgba(230,247,255,0.11)',
      borderWidth: 0,
      borderColor: 'transparent',
      dataBackground: {
        lineStyle: {
          opacity: 0
        },
        areaStyle: {
          opacity: 0
        }
      }
      // handleIcon: 'path://M30.9,53.2C16.8,53.2,5.3,41.7,5.3,27.6S16.8,2,30.9,2C45,2,56.4,13.5,56.4,27.6S45,53.2,30.9,53.2z M30.9,3.5C17.6,3.5,6.8,14.4,6.8,27.6c0,13.3,10.8,24.1,24.101,24.1C44.2,51.7,55,40.9,55,27.6C54.9,14.4,44.1,3.5,30.9,3.5z M36.9,35.8c0,0.601-0.4,1-0.9,1h-1.3c-0.5,0-0.9-0.399-0.9-1V19.5c0-0.6,0.4-1,0.9-1H36c0.5,0,0.9,0.4,0.9,1V35.8z M27.8,35.8 c0,0.601-0.4,1-0.9,1h-1.3c-0.5,0-0.9-0.399-0.9-1V19.5c0-0.6,0.4-1,0.9-1H27c0.5,0,0.9,0.4,0.9,1L27.8,35.8L27.8,35.8z'
    }
  };
  if (props.theme == 'bigscreen-theme-2') {
    option.value.dataZoom = {
      ...(option.value.dataZoom as any),
      showDetail: false,
      height: 20,
      backgroundColor: 'rgba(47, 84, 235, .2)',
      dataBackground: {
        lineStyle: {
          opacity: 0
        },
        areaStyle: {
          color: 'rgba(70, 171, 255, 0.17)',
          opacity: 1
        }
      },
      selectedDataBackground: {
        lineStyle: {
          opacity: 0
        },
        areaStyle: {
          color: 'rgba(70, 171, 255, 0.47)',
          opacity: 1
        }
      },
      handleIcon:
        'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAAAXNSR0IArs4c6QAAAEFJREFUOE9jlJjw/z8DhYBxmBuyIQQSQAFrIDQhPkgNRpg8z4dolpwIoQnxRw3BDCPahQmhKEWXx+oScrLRMMs7AOehWnGh2DeEAAAAAElFTkSuQmCC'
    };
  }
});
</script>
<template>
  <base-chart :option="option" class="wh-chart--stage" ref="chartRef" />
</template>
