export const RootPermission = {};

export enum ArticleListMode {
  MONITOR = 'monitor_mode',
  REPORT = 'report_mode',
  WARNING = 'warning_mode'
}

export const ArticleListPermission = {
  [ArticleListMode.MONITOR]: {
    search: 1, // 表头搜索框部分
    delete: 1,
    deleteAll: 0,
    collect: 0,
    copy: 1,
    detail: 1,
    addBrief: 1,
    warning: 1,
    newInfo: 1
  },
  [ArticleListMode.REPORT]: {
    search: 0,
    delete: 1,
    deleteAll: 1,
    collect: 0,
    copy: 0,
    detail: 0,
    addBrief: 0,
    warning: 0,
    newInfo: 0
  },
  [ArticleListMode.WARNING]: {
    search: 1,
    delete: 0,
    deleteAll: 0,
    collect: 0,
    copy: 1,
    detail: 0,
    addBrief: 1,
    warning: 0,
    newInfo: 0
  },
  search: 1,
  delete: 0,
  deleteAll: 1,
  collect: 1,
  copy: 1,
  detail: 1,
  addBrief: 0,
  warning: 0,
  newInfo: 0
};
