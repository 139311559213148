export default {
  analysisList: {
    createAnalysis: 'Create Analysis',
    createAnalysisModal: {
      modalTitle: [
        'Create Analysis',
        'Edit Analysis',
        'Rename',
        'Visual Dashboard'
      ],
      formNameLabel: ['Analysis Name', 'Dashboard Name'],
      dataSource: 'Data Source',
      placeholder: [
        'Enter the name',
        'Select the data source',
        'Select the template'
      ],
      templateSelection: 'Template Selection',
      templateType: ['Recommended Templates', 'My Templates'],
      modalBtn: ['Cancel', 'Confirm'],
      notemplate: 'No template available'
    },
    countTip: [
      'You have created',
      ' analysis theme',
      ' analysis themes',
      '/The upper limit is',
      '.'
    ],
    sortType: ['Last Edited', 'Last Created'],
    templateAll: 'All Templates',
    searchPlaceholder: ['Template type', 'Enter the keyword'],
    listDetail: [
      'Data Volume',
      'Media Quantity',
      'Peak Spread Volume',
      'Last Edited',
      'Creation Time'
    ],
    unit: ['piece', 'piece', 'hour'],
    listBtn: ['Edit', 'Copy', 'Delete', 'Run', 'Duplicate'],
    deletedTip: 'Analysis theme data source deleted',
    copyTheme: 'Copy Analysis Theme',
    deleteAnalysisTheme: 'Delete Analysis Theme',
    note: 'Note',
    deleteTip: [
      'The data source has been deleted. Do you want to delete the theme?',
      'Are you sure you would like to delete the analysis theme'
    ],
    createSuccTip: ['Created successfully', 'Check later', 'Check now']
  },
  bigscreenList: {
    create: 'Create',
    countTip: [
      'You have created',
      ' dashboard',
      ' dashboards',
      '/The upper limit is',
      ''
    ],
    deletedTip: 'The dashboard data source has been deleted',
    copybigscreen: 'Duplicate Dashboard',
    deletebigscreen: 'Delete Dashboard',
    deleteTip: 'Are you sure you would like to delete the dashboard'
  },
  analysisDetails: {
    actionItem: [
      'Edit',
      'Save',
      'Save as',
      'Save Template',
      'Cancel',
      'Share',
      'Generate Report',
      'Run',
      'Auto-refresh',
      'Rename',
      'Delete'
    ],
    timeRange: 'Time Range',
    timeRangeItem: [
      'Today',
      'Past 24 Hours',
      'Past 7 Days',
      'Past 30 Days',
      'Custom',
      'Past 3 Days',
      'Past 3 Months'
    ],
    modifyTime: 'Modify Time',
    modifyTimeTip:
      'Are you sure you would like to adjust all components accordingly?',
    changeStyleTip:
      'You need to manually adjust the component layout after switching page styles. Please confirm whether to adjust the page style?',
    saveRulesTip: [
      'The analysis name cannot be empty',
      'The name contains a maximum of 100 characters'
    ],
    savePlaceholder: ['Enter the ', 'template ', 'analysis ', 'name'],
    contentStructure: 'Content Structure',
    addSection: 'Add Section',
    modalTitleContentStructure: ['Add Section', 'Rename', 'Delete Section'],
    modalTitleRules: [
      'The section name cannot be empty',
      'The length of the section name cannot exceed 20 characters',
      'Enter the section name'
    ],
    deleteSection: [
      'Are you sure you would like to delete the section title?',
      ' After deletion, all the components contained in the section will be deleted.'
    ],
    actionBtn: ['Confirm', 'Reset', 'Edit'],
    componentsLibrary: 'Components',
    canvasSettings: 'Canvas Settings',
    configGroupLabel: [
      'Data Analytics',
      'Page Settings',
      'Component Style',
      'Data Filtering'
    ],
    single: 'Single',
    multiple: 'Multiple',
    configGroupItem: [
      'Data Source',
      'Page Mode',
      'Dashboard Size',
      'Page Style',
      'Background Color',
      'Component Background',
      'Transparent Background',
      'Component Title',
      'Component Name',
      'Chart Type',
      'Media Type',
      'Data Type',
      'Media Tag',
      'Data Stack',
      'Sentiment',
      'Number of Rankings',
      'Sorting Method',
      'Statistical Dimension',
      'Interaction Type',
      'Hotspot Data Scope',
      'Specified Article',
      'First Release Tracing',
      'Specified Media',
      'Time Range',
      'Information Scrolling'
    ],
    componentNameEmpty: 'Component name cannot be empty!',
    componentNamePl: 'Please enter the component name',
    componentLoadErrorr: 'Loading failed, please refresh the page',
    specifiedArticleTip:
      'The most frequently disseminated article is displayed by default. Click here to re-select.',
    mediaConfiguration: 'Media Configuration',
    timeRangLabel: ['Start Time', 'End Time'],
    timeRangLabelPlace: ['Select the start time', 'Select the end time'],
    bigscreenThemeOption: ['Blue', 'Black'],
    stackTypeOption: ['None', 'Sentiment Type', 'Media Type', 'Data Type'],
    dateIntervalOption: ['Hourly', 'Daily', 'Monthly'],
    mapTypeOption: [
      'Hotspot Map (Global)',
      'Hotspot Map (Mainland)',
      'Hotspot Map (Provincial)'
    ],
    TextChartTypeOption: [
      'Textual Data',
      'Hotspot Ranking',
      'Dissemination Timeline',
      'Information List',
      'Indicator Card',
      'Spread Volume',
      'Ranking'
    ],
    gaugeChartTypeOption: [
      'Multi-color dashboard',
      'Multi-color gradient dashboard',
      'Monochrome dashboard',
      'Monochrome gradient dashboard'
    ],
    chartTypeOption: [
      'Line Chart',
      'Histogram',
      'Pie Chart',
      'Evolution Area Chart',
      'Horizontal Bar Chart',
      'Rounded-tip Bar Chart',
      'Percentage Line Chart',
      'Bubble Chart',
      'Area Chart',
      'Relational Graph',
      'Radar Chart'
    ],
    wordCloudTypeOption: [
      'Circular Word Cloud',
      'Rectangular Word Cloud',
      'Tree-shaped Word Cloud',
      'Word Cloud Map',
      'Topic Word Cloud'
    ],
    sortFieldOption: [
      'Publication Time',
      'Likes',
      'Retweets',
      'Comments',
      'Views',
      'Playbacks',
      'Popularity',
      'Current Views',
      'Comprehensive Ranking',
      'Interaction volume'
    ],
    sortWayOption: ['ASC', 'DESC'],
    polarityOption: ['Positive', 'Negative', 'Neutral'],
    articleTypeOption: ['Original Text', 'Retweet', 'Comment', 'Dialogue'],
    all: ' All',
    unlimit: 'No Limit',
    unknown: 'Unknown',
    pleaseSelect: 'Please select',
    componentOperations: [
      'Data Duplication',
      'Data Pivot',
      'Settings',
      'Delete'
    ],
    duplicationSuccess: 'Duplicated successfully',
    deleteSuccess: 'Delete successfully',
    deleteFailed: 'Delete Failed',
    exportToWordTip:
      'Please wait for the data loading to complete the operation',
    hotTopicHeaders: ['No.', 'Hot Topic', 'Popularity'],
    expertOpinionHeaders: ['No.', 'Expert Opinion', 'Data Volume'],
    netizenOpinionHeaders: ['序号', 'Netizen Opinion', 'Data Volume'],
    infoOverviewItem: [
      'Data Volume',
      'Media Quantity',
      'Peak Spread Volume',
      'Average Daily Data Volume'
    ],
    interactionOverviewItem: ['Views', 'Likes', 'Comments', 'Shares'],
    phasedEvolutionItem: ['Drop', 'Rise', 'Peak'],
    phasedEvolutionDetail: ['首发量', 'Data Volume'],
    sexDistributionTitle: ['Male Proportion ', ' Female Proportion'],
    sexDistributionItem: ['Male', 'Female'],
    propagationCountryDetail: 'Total Coverage',
    propagationLanguageDetail: 'Total Coverage',
    mediaListNodataTip: 'Please configure media',
    mediaListColumn: [
      'Ranking',
      'Accounts',
      'Posts',
      'Likes',
      'Comments',
      'Negative Posts',
      'Retweets',
      'Current Views',
      'Views'
    ]
  },
  analysisComponents: {
    groupName: [
      'Static Component',
      'Information Overview',
      'Hotspot Information',
      'Dissemination Trend',
      'Information Extraction',
      'Dissemination Path',
      'Dissemination Regions',
      'Media Analysis',
      'Time element',
      'Overall Component'
    ],
    staticComponents: ['Dashboard Title'],
    informationOverviewComponents: [
      'Overview',
      'Influence Index',
      'Influence Index List',
      'First Release',
      'Information Overview',
      'Interaction Overview',
      'Negative Information List',
      'Countries Spread',
      'Languages Spread',
      'Information List'
    ],
    hotspotInformationComponents: [
      'Active Channel List',
      'Hot Articles',
      'Information classification',
      'Active accounts'
    ],
    disseminationTrendComponents: [
      'Media Exposure Trend',
      'Media Exposure Trend Comparison',
      'Channel Volume Analysis',
      'Interaction Trend',
      'Interaction Trend Comparison',
      'Phased Evolution',
      'Sentiment Orientation',
      'Volume Proportion',
      'Channels Volume Comparison',
      'Multi-theme Sentiment Comparison'
    ],
    informationExtractionComponents: [
      'Popular Terms',
      'Popular Phrases',
      'Popular Topics',
      'Trending Topic List',
      'Popular Figures',
      'Popular Organizations',
      'Hot Topic',
      'Expert Opinions',
      'Microblog Netizen Opinions'
    ],
    disseminationPathComponents: [
      'Dissemination Path',
      'Microblogging Forwarding Relationship'
    ],
    disseminationRegionsComponents: [
      'Publication Region Distribution',
      'Mentioned Region Distribution'
    ],
    mediaAnalysisComponents: [
      'Account Verification Distribution',
      'Account Gender Distribution',
      'All-level Media Distribution',
      'Posting Habits',
      'Media Rankings'
    ],
    timeComponents: ['World clock', 'Countdown'],
    overallComponent: ['Tab Page Switching']
  },
  analysisOther: {
    viewTitle: 'read full post'
  },
  mediaTypeList: {
    Twitter: 'Twitter',
    Facebook: 'Facebook',
    Instagram: 'Instagram',
    APP: 'APP',
    Custom: 'Custom',
    ENewspaper: 'Digital Newspaper',
    Forum: 'Forum',
    Mblog: 'Weibo',
    MblogMessage: 'MblogMessage',
    QA: 'QA',
    ShortVideo: 'Short Video',
    TV: 'TV',
    Telegram: 'Telegram',
    TouTiao: 'TouTiao',
    Translation: 'Translation',
    Video: 'Video Website ',
    Website: 'Website',
    Wechat: 'WeChat',
    Youtube: 'Youtube',
    ZhiHu: 'ZhiHu'
  },
  Symbol: {
    hour: ':00',
    week: {
      星期日: 'Sunday',
      星期一: 'Monday',
      星期二: 'Tuesday',
      星期三: 'Wednesday',
      星期四: 'Thursday',
      星期五: 'Friday',
      星期六: 'Saturday'
    },
    componentConfig: 'componentConfig'
  },
  analysisIndexPage: ['Default template', 'edited successfully'],
  analysis: {
    analysisEditorGlobalSetting: [
      'Page',
      'Add pages',
      'new page',
      'No more pages can be added',
      'Standard style',
      'Screen style'
    ]
  }
};
