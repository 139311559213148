export const NOT_ERROR_LIS = [
  '/search/search/getImage',
  '/search/search/getAbroadImage',
  '/monitor/getFocusWeChatUserInfo',
  '/search/search/articleTranslate',
  '/monitor/monitorPublic/articleTranslate',
  '/video-py/playurl',
  '/search/search/keyword'
];
export const NOT_WARNING_LIS = [
  '/monitor/hotReport/moveHotReportDetailInItem',
  '/monitor/hotReport/deleteHotReportDetailInItem',
  '/monitor/hotReport/addHotReportDetail'
];
export const MUST_WARNING_LIS = ['/monitor/hotReport/addHotReport'];
export const UPDATE_MESAGE_TIP_WHITE_NAME = [
  'bigscreenShare',
  'analysisShare',
  'bigscreenRun'
]; // 分析分享、大屏分享、大屏运行不进行版本更细提示弹窗
export const TOKEN_ERROR_CODE = [401, 4001];
export const SENTRY_ERROR_CODE = [420, 500, 4000];
export const LANGUAGESLIST = [
  '中文',
  '英语',
  '日语',
  '韩语',
  '俄语',
  '西班牙语',
  '法语',
  '阿拉伯语',
  '葡萄牙语',
  '德语',
  '马来语',
  '泰米尔语',
  '泰语',
  '高棉语',
  '印尼语',
  '越南语',
  '土耳其语',
  '爱沙尼亚语',
  '乌克兰语',
  '意大利语',
  '塞尔维亚语',
  '白俄罗斯语',
  '巴斯克语',
  '匈牙利语',
  '保加利亚语',
  '乌尔都语',
  '索马里语',
  '古吉拉特语',
  '波斯语',
  '波兰语',
  '荷兰语',
  '克罗地亚语',
  '尼泊尔语',
  '孟加拉语',
  '芬兰语',
  '立陶宛语',
  '现代希腊语',
  '罗马尼亚语',
  '瑞典语',
  '印地语',
  '老挝语',
  '缅甸语',
  '挪威语',
  '维吾尔语'
];
export const LANGUAGESLISTID = [
  'zh',
  'en',
  'ja',
  'ko',
  'ru',
  'es',
  'fr',
  'ar',
  'pt',
  'de',
  'ms',
  'ta',
  'th',
  'km',
  'id',
  'vi',
  'tr',
  'et',
  'uk',
  'it',
  'sr',
  'be',
  'eu',
  'hu',
  'bg',
  'ur',
  'so',
  'gu',
  'fa',
  'pl',
  'nl',
  'sh',
  'ne',
  'bn',
  'fi',
  'lt',
  'el',
  'ro',
  'sv',
  'hi',
  'lo',
  'my',
  'no',
  'ug'
];

// 请求头中添加浏览器名称、版本、唯一标识uuid
export const REQUEST_HEADER_AGENT = ['/auth/login', '/auth/captchaLogin'];
export const SHARE_LIST = [
  '/system/user/getInfo',
  '/system/userPermControl/getUserPermControl',
  '/monitor/monitorPublic/getPopWarnList'
];
export const ROUTER_LIST = [
  'from=qinghai',
  'from=sheqing',
  '#/analysis',
  '#/bigscreen'
];
export const ROUTER2_LIST = [
  '#/qinghai',
  'from=qinghai',
  '#/sheqing',
  'from=sheqing'
];
export const I18NNAME = {
  'zh-cn': '暂无数据',
  en: 'No data available',
  'zh-tw': ''
};
