<script lang="ts" setup>
import IconItem from '../InfoList/IconItem.vue';
import TimelineItem from './TimelineItem.vue';
import { TimelineDataItem } from './types';
import { getMediaColorfulIcon } from '/@/utils/other';

const props = withDefaults(
  defineProps<{
    data: TimelineDataItem[];
    link: boolean;
    theme: string;
    titleRows?: number;
  }>(),
  {
    link: true
  }
);
const textColor =
  props.theme == 'bigscreen-theme-2' ? 'rgba(189,219,255,0.6)' : '';
</script>
<template>
  <div class="wh-chart--timeline">
    <slot v-bind="{ data }">
      <timeline-item
        v-for="item in data"
        :key="item.uid"
        :data="item"
        :link="link"
        :theme="props.theme"
        :titleRows="titleRows"
      >
        <template #pic>
          <img :src="getMediaColorfulIcon(item.dataSourceType)" class="pic" />
        </template>
        <template #bottom-bar>
          <icon-item
            :name="`${item.dataSource} ${item.author}`"
            :sourceCode="item.dataSourceType"
            :textColor="textColor"
            :without-icon="true"
          ></icon-item>
        </template>
      </timeline-item>
    </slot>
  </div>
</template>
<style lang="scss" scoped>
.wh-chart--timeline {
  overflow-y: auto;
  width: inherit;
  height: inherit;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
