<script lang="ts" setup>
import { onMounted, ref, shallowRef, watchEffect } from 'vue';
import type { ShallowRef } from 'vue';
import 'echarts-wordcloud';
import BaseChart from '../BaseChart/BaseChart.vue';
import { ECOption } from '../utils';

// export interface SeriesItem {
//   [index: number]: {
//     name?: string;
//     data: any[];
//   };
// }

export interface SeriesItem {
  name?: string;
  data: any[];
}

interface WordCloudProps {
  series: SeriesItem;
  size?: number[];
  rotation?: number[];
  step?: number;
  image?: string;
  gridSize?: number;
  shape?:
    | 'circle'
    | 'cardioid'
    | 'diamond'
    | 'triangle-forward'
    | 'triangle'
    | 'pentagon'
    | 'star';
}

const props = withDefaults(defineProps<WordCloudProps>(), {
  size: () => [16, 70],
  rotation: () => [0, 0],
  step: 0,
  shape: 'circle',
  gridSize: 10
});

const chartRef = ref<InstanceType<typeof BaseChart>>();

const colors = [
  '#007CFF',
  '#7ABBFF',
  '#FF9030',
  '#FFC797',
  '#6093FF',
  '#A7C3FF',
  '#3AC4D4',
  '#7EDAE5',
  '#FE7770',
  '#FFA29E',
  '#00CB9B',
  '#73DFC6',
  '#B45BF3',
  '#D3A9F1',
  '#00AAF8',
  '#7FD4FB',
  '#FFAC30',
  '#FFCF88',
  '#FF70A9',
  '#FFAECE',
  '#6E70FF',
  '#B0B1FF',
  '#FF8F66',
  '#FFB9A1',
  '#2CD36A',
  '#75E29E',
  '#3B6FFF',
  '#91AEFF'
];

const maskImage = props.image ? new Image() : undefined;
if (maskImage) {
  maskImage.src = props.image as string;
}

const data = props.series?.[0]?.data || props.series.data;

onMounted(() => {
  if (maskImage) {
    maskImage.onload = () => {
      chartRef.value?.chart?.setOption(option.value);
    };
  }
});

let option: ShallowRef<ECOption> = shallowRef({});
watchEffect(() => {
  option.value = {
    tooltip: {
      show: true,
      formatter: (params: any) => {
        return (
          `<div 
        style="display:inline-block;
        margin-right:5px;
        border-radius:8px;
        width:8px;height:8px;
        background-color:${
          params.color === '#fff'
            ? params.data.textStyle.backgroundColor
            : params.color
        }">
          </div>` +
          params.data.name +
          ': ' +
          params.data.value
        );
      },
      extraCssText: 'color: white;',
      backgroundColor: 'rgba(0, 0, 0,0.65)',
      borderWidth: 0,
      padding: 4
    },
    series: [
      {
        type: 'wordCloud',
        shape: props.shape,
        width: '100%',
        height: '100%',
        left: 'center',
        top: 'center',
        sizeRange: props.size,
        gridSize: props.gridSize,
        rotationRange: props.rotation,
        rotationStep: props.step,
        maskImage,
        // shrinkToFit: true,
        emphasis: {
          textStyle: {
            textShadowBlur: 3,
            textShadowColor: '#9D5044'
          }
        },
        textStyle: {
          color: () => {
            const i = Math.floor(Math.random() * colors.length);
            return colors[i];
          }
        },
        data: data.map(item => {
          if (props.gridSize === 40) {
            return {
              name: item.name,
              value: item.value,
              textStyle: {
                color: '#fff',
                backgroundColor:
                  colors[Math.floor(Math.random() * colors.length)],
                padding: 8
              }
            };
          } else {
            return {
              name: item.name,
              value: item.value,
              textStyle: {
                color: colors[Math.floor(Math.random() * colors.length)]
              }
            };
          }
        })
      }
    ]
  };
});
</script>
<template>
  <base-chart :option="option" ref="chartRef"></base-chart>
</template>
<style lang="scss" scoped></style>
