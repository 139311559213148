// 定义内容
export default {
  collectMenu: {
    title: 'Collection',
    searchPlaceholder: 'Enter collection name',
    editBtn: ['Edit', 'Delete'],
    modalDelMenu: [
      'Delete a tap',
      'Deleting a label will also delete all data under the label. Are you sure you want to delete the label?'
    ],
    collectMessageToast: [
      'The number of custom labels created has reached the maximum limit',
      'The name cannot be repeated'
    ],
    customMenuName: 'Custom Label',
    useModalBtn: ['Cancel', 'Confirm']
  },
  addCollectForm: {
    addCollection: 'Add to Collection',
    createNewCollection: 'Create New Collection',
    add: 'Add',
    addTips: ['Collected successfully', 'Check later', 'Check now'],
    note: 'Note',
    removeCollectTip:
      'Are you sure you would like to remove this article from the collection?'
  }
};
