<script lang="ts" setup>
import type { InfoListDataItem } from './InfoList.vue';
import KvText from './KVText.vue';
import { ref } from 'vue';

const props = defineProps<{
  data: InfoListDataItem;
}>();
const name = ref('阅读量');
const count = ref(props.data.readCount);
switch (props.data.sortField) {
  case 2:
    name.value = '阅读量';
    count.value = props.data.readCount;
    break;
  case 3:
    name.value = '转发量';
    count.value = props.data.rttCount;
    break;
  case 4:
    name.value = '评论量';
    count.value = props.data.commentCount;
    break;
  case 5:
    name.value = '点赞量';
    count.value = props.data.likeCount;
    break;
  case 6:
    name.value = '播放量';
    count.value = props.data.playCount;
}
// 判断dataSource 选择对应的 interaction name
</script>
<template>
  <kv-text :name="name" :value="count || 0"></kv-text>
</template>
