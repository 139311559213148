<template>
  <div v-loading="loading" v-nodata="!Boolean(data.length) && !loading">
    <Timeline
      v-if="!loading && params.chartType == 'timeline'"
      :data="data"
      link
      :theme="theme"
      :titleRows="params.titleRows"
    />
    <InfoList
      :theme="theme"
      v-if="!loading && params.chartType == 'list'"
      :data="data"
      :titleRows="params.titleRows"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { getSourceName } from '/@/utils/other';
export default defineComponent({
  // 渠道首发
  name: 'AChannelStart',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const { channelDebut } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const data = ref<any[]>([]);
    const loading = ref(false);
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      try {
        const res = await channelDebut(params.value);
        if (res.code === 200) {
          data.value = (res?.data?.itemList as any[]).map(item => {
            return {
              id: item.uuid,
              uid: item.uuid,
              site: item.websiteName || '',
              polarity: item.polarity,
              pubtime: item.publishTime,
              title: item.title,
              url: item.url,
              author: item.author || item.websiteName || '',
              dataSource: getSourceName(item.dataSourceType),
              dataSourceType: item.dataSourceType,
              articleType: item.articleType
            };
          });
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      data,
      loading,
      params,
      update
    };
  }
});
</script>
<style scoped>
.wh-chart--info-list {
  height: 100%;
}
</style>
