import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import WeiboForwarding from './src/index.vue';

WeiboForwarding.install = (app: App): void => {
  app.component('AWeiboForwarding', WeiboForwarding);
  app.component(
    'AWeiboForwardingProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default WeiboForwarding;
