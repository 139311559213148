<script lang="ts" setup>
import { ShallowRef, shallowRef, watchEffect, PropType } from 'vue';
import BaseChart from '../BaseChart/BaseChart.vue';
import useChartClick from '../hooks/useChartClick';
import { getRandomColor } from '../utils';
import { getRandomStr } from '/@/utils/other';

const colors = [
  '#0088FF',
  '#FF7F30',
  '#8860FF',
  '#6093FF',
  '#3AC4D4',
  '#FE7770',
  '#FF9030',
  '#007CFF',
  '#7ABBFF',
  '#FFC797',
  '#6093FF',
  '#A7C3FF',
  '#3AC4D4',
  '#7EDAE5',
  '#FFA29E'
];

interface PropDataItem {
  name: string;
  value: number;
  level: number;
  children?: PropDataItem[];
  branchIndex: number;
}

interface chartDataItem {
  id: string;
  name: string;
  value: number;
  level: number;
  itemStyle?: Object;
  symbolSize?: number;
  draggable?: boolean;
  label: Object;
  emphasis: Object;
}

interface chartLinkItem {
  source: string;
  target: string;
  lineStyle: Object;
}

const props = defineProps({
  data: {
    type: Array as PropType<PropDataItem[]>,
    required: true
  },
  tooltip: Boolean as PropType<Boolean>,
  draggable: Boolean,
  colors: Array as PropType<string[]>,
  nodeColorBy: {
    type: String as PropType<'level' | 'branch'>,
    default: 'branch'
  }
});

let option: ShallowRef = shallowRef({});

const transPropData = (
  list: PropDataItem[],
  pId: string = 'root',
  pColor: string,
  chartData: chartDataItem[],
  chartLink: chartLinkItem[],
  level: number = -1
) => {
  const colorOption = props.colors || colors;
  if (list?.length) {
    ++level;
    const randomColor = getRandomColor();
    list.forEach((item, i) => {
      if (level == 0) {
        item.branchIndex = 0;
      }
      if (level == 1) {
        item.branchIndex = i + 1;
      }
      const itemColorIndex =
        props.nodeColorBy == 'branch' ? item.branchIndex : level;
      let itemColor = pColor || colorOption[itemColorIndex] || randomColor;
      if (props.nodeColorBy == 'branch') {
        if (level == 0) {
          itemColor = pColor || colorOption[itemColorIndex] || randomColor;
        }
        if (level == 1) {
          itemColor = colorOption[itemColorIndex] || randomColor;
        }
      }
      if (props.nodeColorBy == 'level') {
        itemColor = colorOption[level] || randomColor;
      }
      const randomId = getRandomStr(6);
      item['id'] = randomId;
      chartData.push({
        id: randomId,
        name: item.name,
        value: item.value,
        level: item.level,
        itemStyle: {
          color: itemColor
        },
        label: {
          show: item.level <= 2,
          position: 'inside',
          color: '#272A31'
        },
        emphasis: {
          label: {
            show: true
          }
        }
      });
      chartLink.push({
        source: randomId,
        target: pId,
        lineStyle: {
          color: itemColor,
          curveness: 0.5
        }
      });
      if (item.children?.length) {
        transPropData(
          item.children,
          item['id'],
          itemColor,
          chartData,
          chartLink,
          level
        );
      }
    });
  }
  return { chartData, chartLink };
};

watchEffect(() => {
  const { chartData, chartLink } = transPropData(
    props.data,
    'root',
    '',
    [],
    []
  );
  const valueArr = chartData.map(item => {
    return item.value;
  });
  const maxValue = Math.max(...valueArr);
  const maxSymbolSize = 100;
  const minSymbolSize = 20;
  chartData.forEach(item => {
    item.symbolSize = Math.max(
      minSymbolSize,
      (item.value / maxValue) * maxSymbolSize
    );
    item.draggable = true;
  });
  option.value = {
    series: {
      type: 'graph',
      layout: 'force',
      focusNodeAdjacency: true,
      force: {
        repulsion: 600,
        gravity: 0.1
      },
      roam: 'scale',
      scaleLimit: {
        min: 0.5,
        max: 3
      },
      data: chartData,
      links: chartLink
    } as unknown as any
  };
  props.tooltip && (option.value.tooltip = {});
});
interface Emits {
  (e: 'chart:click', params: any): void;
}
const emit = defineEmits<Emits>();
const chartRef = useChartClick(emit);
</script>
<template>
  <base-chart ref="chartRef" :option="option" class="wh-chart--line" />
</template>

<style lang="scss" scoped></style>
