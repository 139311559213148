<template>
  <div v-loading="loading" v-nodata="!Boolean(series.length) && !loading">
    <BarChart
      ref="refChart"
      tooltip
      :series="{ data: series }"
      :x-axis="{ data: xAxis }"
      :theme="theme"
      :key="updateKey"
      v-if="!loading && xAxis.length"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch, toRef } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  // 发文习惯
  name: 'AWritingHabit',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const { t } = useI18n();
    const updateKey = ref();
    watch(toRef(props, 'theme'), () => {
      updateKey.value = new Date().getTime();
    });
    const { writingHabit } = useAnalysisApi();
    const loading = ref<boolean>(false);
    const params = computed(() => {
      return props.params;
    });
    onMounted(() => {
      getData();
    });
    const xAxis = ref<any[]>([]);
    const series = ref<any[]>([]);
    function getData() {
      if (!props.params.schemeId) return;
      xAxis.value.splice(0);
      series.value.splice(0);
      loading.value = true;
      writingHabit(params.value)
        .then((res: any) => {
          res.data.noStackResult.forEach(item => {
            const timeStr =
              (Number(item.intervalTime.substring(0, 2)) + 1)
                .toString()
                .padStart(2, '0') + t('message.Symbol.hour');
            xAxis.value.push(timeStr);
            series.value.push({
              name: timeStr,
              value: item.sum
            });
          });
        })
        .finally(() => {
          loading.value = false;
        });
    }

    const update = () => {
      getData();
    };
    return {
      loading,
      update,
      series,
      xAxis,
      updateKey
    };
  }
});
</script>
<style scoped></style>
