<template>
  <div v-loading="loading" v-nodata="!Boolean(series.length) && !loading">
    <LineChart
      ref="refChart"
      v-if="!loading && series.length"
      :smooth="theme == 'bigscreen-theme-2' ? false : true"
      :series="series"
      :x-axis="{ data: xAxis }"
      y-axis
      :tooltip="{ trigger: 'axis' }"
      :theme="theme"
      :key="updateKey"
      v-memo="[]"
      dataZoom
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  inject,
  watch,
  toRef
} from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { storeToRefs } from 'pinia';
import { useEditorStore } from '/@/stores/analysis';
import { cloneDeep } from 'lodash-es';
import { getDateByInterval } from '../../utils';
export default defineComponent({
  // 互动趋势对比
  name: 'AInteractionTrendCompare',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const updateKey = ref();
    watch(toRef(props, 'theme'), () => {
      updateKey.value = new Date().getTime();
    });
    const refChart = ref();
    const paramsObj = computed(() => {
      return props.params;
    });
    const { interactiveTrendComparison } = useAnalysisApi();
    const xAxis = ref<any[]>([]);
    const series = ref<any[]>([]);
    const loading = ref(false);
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const chartsClick = inject('chartsClick', undefined);
    const initDrillingParams = inject('initDrillingParams', undefined);
    const getData = async () => {
      if (!paramsObj.value.schemeIdList.length) return;
      loading.value = true;
      xAxis.value.splice(0);
      series.value.splice(0);
      try {
        const res = await interactiveTrendComparison(paramsObj.value);
        if (res.code === 200) {
          const resData = res?.data as any;
          (resData.dataList as any[])?.forEach((item: any) => {
            const dateInterval = item.dateInterval;
            const data = item.itemList.map((itm: any) => {
              const intervalTime: string = getDateByInterval(
                itm.date,
                dateInterval
              );
              !xAxis.value.includes(intervalTime) &&
                xAxis.value.push(intervalTime);
              return {
                schemeId: item.schemeId,
                value: Number(itm.count),
                startTime: itm.date,
                interval: dateInterval
              };
            });
            series.value.push({
              name: item.schemeName,
              data
            });
          });
          if (chartsClick) {
            initDrillingParams({ schemeId: paramsObj.value.schemeIdList[0] });
          }
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      refChart,
      xAxis,
      series,
      loading,
      paramsObj,
      update,
      updateKey
    };
  }
});
</script>
