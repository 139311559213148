import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import AMediaList from './src/index.vue';

AMediaList.install = (app: App): void => {
  app.component('AMediaList', AMediaList);
  app.component(
    'AMediaListProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default AMediaList;
