// 定义内容
export default {
  warningList: {
    fullWarning: '全部預警',
    manualWarning: '手動預警',
    monitoringScheme: '監測方案',
    monitorWarning: '監測預警',
    selectWarning: '搜索預警',
    monitor: '监测',
    search: '搜索'
  },
  filterPanel: {
    WarningTime: '預警時間:',
    polarity: '情感傾向:',
    polarityYayi: '情感分析:',
    mediaType: '媒體類型:',
    time1: '今天',
    time2: '近24小時',
    time3: '近7天',
    time4: '近30天',
    time5: '自定義',
    time6: '近3天',
    time14: '近14天',
    all: '全部',
    Search: '查詢'
  },
  articlelist: {
    newInfo: '有新信息，點擊獲取',
    upperInfo: '今日數據量已達上限,暫停更新。請聯繫客服人員增加數據量~',
    chooseAll: '選擇當前頁',
    collect: '收藏',
    cancelCollect: '取消收藏',
    changeLabels: '更換標籤',
    addedWarning: '已加入預警',
    warning: '預警',
    addBrief: '加入簡報',
    addedBrief: '已加入簡報',
    delete: '刪除',
    removeAll: '清空',
    positiveSequence: '正序',
    reverse: '倒序',
    placehoder1: '請輸入關鍵詞',
    placehoder2: '請輸入預警推送內容',
    text1: '是否確認取消收藏？',
    text2: '確定刪除選中素材?',
    text3: '確定刪除選中的信息嗎?',
    text4: '確定刪除這條信息嗎?',
    text5: '確定要修改情感信息嗎?',
    cancel: '取消',
    confirm: '確定',
    checkText: '查看原文',
    copy: '複製',
    warningContent: '預警推送內容',
    tip1: '內容不可為空',
    reset: '重置',
    sendWarning: '發送預警',
    all: '共',
    tip2: '個字符,不能超過100個字符',
    tip3: '條信息',
    keyword: '關鍵詞:',
    negative: '負面',
    positive: '正面',
    neutrality: '中立',
    originalLink: '原文鏈接',
    prompt: '提示',
    issueTime: '發佈時間',
    fullText: '全文',
    title: '標題',
    publishPlatform: '發佈平台',
    author: '作者',
    copyLink: '複製鏈接',
    original: '原創',
    retransmission: '轉發',
    remark: '評論',
    msg1: '取消收藏成功',
    msg2: '所選信息存在已加入簡報數據',
    msg3: '加入簡報成功',
    msg4: '預警成功',
    msg5: '預警失敗',
    msg6: '複製成功',
    link: '鏈接',
    summary: '摘要',
    accountNumber: '賬號',
    site: '站點',
    exportAll: '导出全部',
    dataExport: '数据导出',
    translate: '翻译',
    similarInfoDeduplication: '相似信息去重',
    displayOptions: '展示选项',
    displayOptionsItems: ['正文', '图片'],
    optsSortItems: [
      '综合排序',
      '相关度',
      '热度',
      '发布时间',
      '入库时间',
      '点赞量',
      '转发量',
      '评论量',
      '阅读量',
      '播放量',
      '在看量',
      '入库时间',
      '监测时间',
      '预警时间',
      '互動量'
    ],
    sortWayItems: ['正序', '倒序'],
    matchMethodItems: ['全文', '标题', '站点', '账号'],
    matchMethodTips:
      '站点指网站、电子报刊、APP和论坛贴吧的名称。举例:如果想搜索人民网，在二次筛选中选择“站点“，在输入框中输入“人民网“。',
    analysisPreview: '分析预览',
    trace: '溯源',
    emptyTip: '输入关键词，点击搜索即可查看相关内容',
    nodata: '暂无数据',
    detailsSwitchTip: [
      '键盘中的向上/向下键，可快捷切换上一篇/下一篇',
      '已经是第一条信息',
      '已经是最后一条信息'
    ]
  },
  charts: {
    totalInformation: '信息總量',
    positiveInformation: '正面信息',
    negativeInformation: '負面信息',
    neutralInformation: '中立信息',
    mediaType: '媒體類型',
    count: '信息量',
    ratio: '佔比',
    ChannelRatio: '信息來源佔比',
    DownloadPictures: '下載圖片',
    ActiveMedia: '活躍媒體',
    area: '地區',
    quantity: '數量',
    MentionRegion: '提及地區',
    HotTopicWords: '熱點主題詞',
    DataDevelopmentTrend: '數據發展趨勢',
    HotArticles: '熱門文章',
    ranking: '序號',
    title: '標題',
    heat: '熱度',
    PolarityRatio: '情感分佈',
    PolarityTrend: '情感分佈'
  }
};
