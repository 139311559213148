/**字典表 */
import request from '/@/utils/request';
export function useDictApi() {
  return {
    /**查询国家三字英文代码对照表*/
    getCountryPekList: () => {
      return request({
        url: '/system/dict/data/countryPekList',
        method: 'post'
      });
    },
    getMentionAreaTree: () => {
      return request({
        url: '/system/dict/data/getMentionAreaTree',
        method: 'post'
      });
    },
    getAreaInfo: () => {
      return request({
        url: '/strategy/public/v1/getAreaInfo',
        method: 'get'
      });
    },
    getLanguagesList: () => {
      return request({
        url: '/system/dict/data/languagesList',
        method: 'post'
      });
    },
    getMediaTagList: () => {
      return request({
        url: '/system/dict/data/selectMediaTagList',
        method: 'post'
      });
    },
    getMediaTypeList: () => {
      return request({
        url: '/system/userPermControl/dataAuth',
        method: 'get'
      });
    },
    getInformationCategory: () => {
      return request({
        url: '/search/searchMediaGradeTag/list',
        method: 'post'
      });
    },
    getSearchDataSourceCount: (params: object) => {
      return request({
        url: '/search/search/getSearchDataSourceCount',
        method: 'post',
        data: params
      });
    },
    // 查询所有渠道选项
    getDataSourceOption: () => {
      return request({
        url: '/system/userPermControl/dataMap',
        method: 'get'
      });
    },
    // 国内省级地区编码列表
    getProvinceOption: () => {
      return request.post('/analysis/analyzeArea/provincialRegionList');
    },
    // 获取标签选项（机构类型标签、媒体等级标签、媒体地域标签、个体账号标签）
    getMediaTagTree: () => {
      return request.post('/search/searchMediaGradeTag/mediaTagTree');
    },
    // 查询当前用户的信息分类
    getListByUser: () => {
      return request.post('/search/searchMediaGradeTag/listByUser');
    },
    // 查询搜索推荐标签更多常用标签
    getMediaTagByHighFrequency: () => {
      return request.get('/system/mediaTag/querySysMediaTagByHighFrequency');
    },
    // 获取媒体标签
    getMediaTagByName: (params: any) => {
      return request({
        url: '/system/mediaTag/querySysMediaTagByName',
        method: 'get',
        params: params
      });
    },
    // 获取当前用户的媒体标签设置
    getMediaTagsByUser: () => {
      return request.get('/system/mediaTag/querySysMediaTagByUser');
    },
    // 获取所有时区
    getAllTimezoneList: () => {
      return request.get('/system/dict/data/getAllTimezoneList');
    }
  };
}
