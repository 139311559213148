import { ref, watch, computed } from 'vue';
import { Local } from '/@/utils/storage';
import { deepClone } from '/@/utils/other';
import { useUserPermission } from '/@/stores/useUserPermission';
export interface keywordsItem {
  advanced: any[];
  must: string[];
  any: string[];
  not: string[];
}
const searchKeywordType = ref<number>(
  Local.get('searchKeywordType') == undefined
    ? 1
    : Local.get('searchKeywordType')
); //搜索模式0表达式搜索，1关键词搜索
const keywordLengthLimit = ref<number>(50); // 单个关键词长度限制
const keywordsLimitNum = computed(() => {
  return useUserPermission().searchKeywordNum;
}); // 关键词总个数限制
const remaininglimitNum = ref<number>(keywordsLimitNum.value); // 关键词剩余个数
const keywordsCurrentNum = ref<number>(0); //关键词现有个数
const keywordsStrLengthLimit = ref<number>(1000); //表达式模式输入的字符串长度限制
const keywordsStrTotalLength = ref<number>(1000); // 关键词字符的总长度限制
const popupvisible = ref(false);
const charList = ['&', '|', '(', ')', '!'];
const searchTransLateLanguages = ref(['english']);
// 用于展示的输入框中关键词
const keywords = ref<keywordsItem>({
  advanced: [],
  must: [],
  any: [],
  not: []
});
// 用户输入的关键词
const inputKeywords = ref<any>({
  advanced: [
    {
      keyword: 'A',
      edited: false,
      children: [
        { keyword: 'EA', language: 'en', edited: false },
        { keyword: 'JA', language: 'ja', edited: false }
      ]
    },
    {
      keyword: 'B',
      edited: false,
      children: [
        { keyword: 'EB', language: 'en', edited: false },
        { keyword: 'JB', language: 'ja', edited: false }
      ]
    }
  ],
  must: [],
  any: [],
  not: []
});

// 点击翻译的表达式关键词
const translateKeyword = ref<any>({
  advanced: {}
});

const keywordsSearchMode = [
  {
    label: '关键词搜索',
    id: '1',
    showPop: true,
    popTip: '当前为表达式搜索，切换到关键词搜索将清空已输入的关键词，是否切换？'
  },
  {
    label: '表达式搜索',
    id: '0'
  }
];
watch(searchKeywordType, val => {
  Local.set('searchKeywordType', val);
});
export default function useKeywords() {
  //切换模式处理关键词
  const changeSearchTypeMode = (isTranslateLanguage?: boolean): boolean => {
    // 返回true|false，表示是否通过校验，用于交互判断
    if (searchKeywordType.value == 1) {
      // 转表达式模式，直接返回true
      keywordsMerge(isTranslateLanguage);
      searchKeywordType.value = 0;
      return true;
    } else {
      if (!keywords.value.advanced.length) {
        // 空表达式直接返回true
        searchKeywordType.value = 1;
        return true;
      } else if (keywords.value.advanced.length > 1) {
        // 多组表达式不支持转为关键词，返回true
        return false;
      } else {
        // 拆分表达式
        return keywordsSplit(isTranslateLanguage);
      }
    }
  };
  // 关键词拼接
  const keywordsMerge = (isTranslateLanguage?: boolean) => {
    let strKws: string = '';
    if (keywords.value.must.length)
      strKws += '(' + keywords.value.must.join('&') + ')';
    if (keywords.value.must.length && keywords.value.any.length)
      strKws += '&(' + keywords.value.any.join('|') + ')';
    else if (keywords.value.any.length)
      strKws += '(' + keywords.value.any.join('|') + ')';
    if (keywords.value.not.length)
      strKws += '!(' + keywords.value.not.join('|') + ')';
    if (strKws.length) {
      if (!isTranslateLanguage) keywords.value.advanced[0] = strKws;
      else {
        const obj = {
          keyword: strKws,
          showLanguage: false,
          language: ''
          // children: []
        };
        keywords.value.advanced.push(obj);
      }
    } else keywords.value.advanced.splice(0);
    keywords.value.must = [];
    keywords.value.any = [];
    keywords.value.not = [];
  };
  const splitAndOr = (str: string) => {
    let arr = [] as string[];
    if (str.indexOf('|') > 0) {
      arr = str.split('|');
    } else if (str.indexOf('&') > 0) {
      arr = str.split('&');
    } else if (str != '') {
      arr.push(str);
    }
    arr.forEach((item, i) => {
      arr[i] = arr[i].replace('(', '').replace(')', '');
    });
    return arr;
  };
  const complexKwsTocommonKws = (str: string) => {
    (str.indexOf('！(') >= 0 || str.indexOf('！（') >= 0) &&
      (str = str.replace(/！/g, '!'));
    str = str.replace(/（/g, '(').replace(/）/g, ')');
    let kws = str.split('!');
    let not = '';
    let contain = [];
    let must = '';
    let any = '';
    // 不包含
    if (kws.length > 1) {
      not = kws[1].replace('(', '').replace(')', '');
      str = kws[0];
    }
    // 包含
    if (kws[0].indexOf(')&(') > 0) {
      contain = kws[0].split(')&(');
      if (contain[0].indexOf('|') >= 0) {
        must = contain[1].replace('(', '').replace(')', '');
        any = contain[0].replace('(', '').replace(')', '');
      } else {
        must = contain[0].replace('(', '').replace(')', '');
        any = contain[1].replace('(', '').replace(')', '');
      }
    } else {
      if (str.indexOf('|') > 0) {
        any = str.replace('(', '').replace(')', '');
      } else {
        must = str.replace('(', '').replace(')', '');
      }
    }
    let tempArr = [];
    if (must.indexOf('|') > 0) {
      must.split('|').forEach(function (item) {
        tempArr.push({
          must: splitAndOr(item),
          any: splitAndOr(any),
          not: splitAndOr(not),
          mustMarker: true,
          notMarker: not.indexOf('|') > 0 ? true : false
        });
      });
    } else {
      tempArr.push({
        must: splitAndOr(must),
        any: splitAndOr(any),
        not: splitAndOr(not),
        notMarker: not.indexOf('|') > 0 ? true : false
      });
    }
    return tempArr;
  };
  const advancedKeywordsFill = (data: any) => {
    if (!data) {
      return '';
    } else {
      let mustVal = data.mustMarker ? data.must.join('|') : data.must.join('&'),
        anyVal = data.any.join('|'),
        notVal = data.notMarker ? data.not.join('|') : data.not.join('&'),
        keywordsHtml = '';

      mustVal = mustVal == '' ? mustVal : '(' + mustVal + ')';
      anyVal = anyVal == '' ? anyVal : '(' + anyVal + ')';
      notVal = notVal == '' ? notVal : '!(' + notVal + ')';
      if (mustVal != '' && anyVal != '') {
        anyVal = '&' + anyVal;
      }
      keywordsHtml = mustVal + anyVal + notVal;
      return keywordsHtml;
    }
  };
  const equalKeywordsStr = (str1: string, str2: string) => {
    // 判断解析后的表达式和原始表达式等效
    const ma1 = str1.split('!')[0].split('&').sort().join('');
    const ma2 = str2.split('!')[0].split('&').sort().join('');
    const evidence1 = str1 === str2; //表达式完全相等
    const evidence2 = ma1 === ma2; //必要关键词和任一关键词只是书写顺序调换
    return evidence1 || evidence2;
  };
  // 关键词分解(单条表达式搜索转关键词搜索)
  const keywordsSplit = (isTranslateLanguage?: boolean) => {
    let checkPass = true;
    const cpCeywords = {
      must: [] as string[],
      any: [] as string[],
      not: [] as string[],
      advanced: deepClone(
        isTranslateLanguage
          ? [keywords.value.advanced[0].keyword]
          : keywords.value.advanced
      )
    };
    let tempKeywordArr = [] as any;
    const formulaStr = cpCeywords.advanced[0]
      .replace(/！/g, '!')
      .replace(/（/g, '(')
      .replace(/）/g, ')')
      .trim();
    if (!/(^\(|^\!\()([\s\S])*\)$/.test(formulaStr)) {
      // 不是公式的
      tempKeywordArr.push(formulaStr);
    }
    let advancedTag1Text: string = cpCeywords.advanced[0]
      ? cpCeywords.advanced[0]
          .trim()
          .replace(/！/g, '!')
          .replace(/（/g, '(')
          .replace(/）/g, ')')
      : '';
    let must: string[] = [],
      any: string[] = [],
      not: string[] = [];
    if (
      tempKeywordArr.length > 0 &&
      cpCeywords.advanced.length == tempKeywordArr.length
    ) {
      // 多组关键词回填
      tempKeywordArr.forEach(function (item: any) {
        any.push(item);
      });
    } else if (
      equalKeywordsStr(
        advancedKeywordsFill(complexKwsTocommonKws(advancedTag1Text)[0]),
        advancedTag1Text
      )
    ) {
      tempKeywordArr = complexKwsTocommonKws(advancedTag1Text);
    }
    if (tempKeywordArr.length > 0 && typeof tempKeywordArr[0] == 'object') {
      tempKeywordArr[0].must.forEach(function (item: any) {
        must.push(item);
      });
      tempKeywordArr[0].any.forEach(function (item: any) {
        any.push(item);
      });
      tempKeywordArr[0].not.forEach(function (item: any) {
        not.push(item);
      });
      cpCeywords.must = must;
      cpCeywords.any = any;
      cpCeywords.not = not;
    } else if (
      tempKeywordArr[0] &&
      tempKeywordArr[0].indexOf('(') < 0 &&
      tempKeywordArr[0].indexOf(')') < 0
    ) {
      // 没括号的情况
      const keywordsStr = tempKeywordArr[0];
      if (keywordsStr.indexOf('&') < 0 && keywordsStr.indexOf('|') < 0) {
        // 只有关键词，按照必须必须包含回显
        cpCeywords.must = [keywordsStr];
      }
      if (keywordsStr.indexOf('&') >= 0 && keywordsStr.indexOf('|') < 0) {
        // 只有&连接
        cpCeywords.must = keywordsStr.split('&');
      }
      if (keywordsStr.indexOf('&') < 0 && keywordsStr.indexOf('|') >= 0) {
        // 只有|连接
        cpCeywords.any = keywordsStr.split('|');
      }
    } else {
      checkPass = false;
    }
    if (checkPass) {
      keywordClear();
      keywords.value.must = cpCeywords.must;
      keywords.value.any = cpCeywords.any;
      keywords.value.not = cpCeywords.not;
    }
    return checkPass;
  };
  const keywordClear = () => {
    searchKeywordType.value = 1;
    keywords.value.must = [];
    keywords.value.any = [];
    keywords.value.not = [];
    keywords.value.advanced = [];
  };
  //校验关键词个数
  const checkKeywordsNum = (
    data: any,
    searchKeywordType: number,
    simpleToTraditional?: number | boolean
  ) => {
    const { advanced, must, any, not } = data;
    if (searchKeywordType === 1) {
      remaininglimitNum.value = simpleToTraditional
        ? keywordsLimitNum.value - (must.length + any.length + not.length) * 2
        : keywordsLimitNum.value - (must.length + any.length + not.length);
      keywordsCurrentNum.value = simpleToTraditional
        ? (must.length + any.length + not.length) * 2
        : must.length + any.length + not.length;
    } else {
      let newNumArr = [] as any;
      advanced.forEach(function (keyword: any) {
        let keywordItem = keyword;
        // if (keyword.indexOf('!') == 0) {
        // 	keywordItem = keywordItem.slice(1);
        // }
        keywordItem = keywordItem
          .replace(/(\)&\()/g, '&')
          .replace(/(\)\|\()/g, '&')
          .replace(/(\)\!\()/g, '&')
          .replace(/(\|)/g, '&')
          .replace(/(\(|\))/g, '')
          .split('&');
        // keywordItem = keywordItem.split(/[!&|()（）]/g).filter((k: string) => {
        // 	return k;
        // });
        newNumArr.push(...keywordItem);
        // }
      });
      newNumArr = newNumArr.filter((item: any) => {
        return item !== '';
      });
      keywordsCurrentNum.value = simpleToTraditional
        ? newNumArr.length * 2
        : newNumArr.length;
      remaininglimitNum.value = simpleToTraditional
        ? keywordsLimitNum.value - newNumArr.length * 2
        : keywordsLimitNum.value - newNumArr.length;
    }
  };
  return {
    searchKeywordType,
    keywords,
    keywordLengthLimit,
    keywordsLimitNum,
    remaininglimitNum,
    keywordsCurrentNum,
    keywordsStrLengthLimit,
    charList,
    keywordsSearchMode,
    popupvisible,

    changeSearchTypeMode,
    keywordsMerge,
    keywordsSplit,
    checkKeywordsNum,
    searchTransLateLanguages,
    inputKeywords,
    translateKeyword,
    keywordsStrTotalLength,
    keywordClear
  };
}
