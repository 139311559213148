<template>
  <base-chart
    ref="chartRef"
    :option="option"
    class="wh-chart--line"
    :key="updateKey"
  />
</template>
<script lang="ts" setup>
import BaseChart from '../../../../../visual-components/BaseChart/BaseChart.vue';
import { ref, watch, toRef, onMounted, nextTick } from 'vue';
import { deepClone, isMobile } from '/@/utils/other';
import useChartClick from '@wenhai/visual-components/hooks/useChartClick';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
interface Emits {
  (e: 'chart:click', params: any): void;
}
const emit = defineEmits<Emits>();
const props = defineProps({
  dataObject: {
    type: Object
  },
  theme: String,
  dataZoom: Boolean
});
const option = ref({});
const chartRef = useChartClick(emit);
const updateKey = ref();
watch(toRef(props, 'theme'), val => {
  nextTick(() => {
    changeOption();
    updateKey.value = new Date().getTime();
  });
});
const changeOption = () => {
  option.value = {};
  if (props.theme == 'bigscreen-theme-2') {
    // 第二套大屏样式
    let colors = ['#5EB4EB', '#2A5AF8', '#6220FE'];
    const colorsRGB = ['94,180,235', '42,90,248', '98,32,254'];
    const lineData = deepClone(props.dataObject?.seriesLine);
    lineData.forEach((item, i) => {
      item.smooth = false;
      item.symbol = 'none';
      item.emphasis = {};
      item.areaStyle = {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: `rgba(${colorsRGB[i]},0.3)`
            },
            {
              offset: 1,
              color: `rgba(${colorsRGB[i]},0)`
            }
          ]
        }
      };
    });
    option.value = {
      dataZoom: props.dataZoom
        ? {
            type: 'slider',
            minValueSpan: 1,
            height: 20,
            bottom: 10,
            showDetail: false
          }
        : null,
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: [
          t('message.analysisDetails.polarityOption[0]'),
          t('message.analysisDetails.polarityOption[2]'),
          t('message.analysisDetails.polarityOption[1]')
        ], //['正面', '中立', '负面']
        left: 'center',
        // width: 70,
        // height: 20,
        orient: 'horizontal',
        backgroundColor: 'rgba(54,97,158,0.22)',
        itemGap: 16,
        padding: [4, 12],
        itemStyle: {},
        textStyle: {
          color: 'rgba(216,240,255,0.8)'
        }
      },
      color: colors,
      grid: {
        // left: '1%',
        left: 27,
        right: 110,
        bottom: props.dataZoom ? 35 : 5,
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: props.dataObject?.xAxis,
        axisLabel: {
          color: 'rgba(216,240,255,0.6)'
        }
      },
      yAxis: {
        type: 'value',
        splitLine: {
          lineStyle: {
            color: 'rgba(176,215,255,0.25)',
            type: [5, 10],
            dashOffset: 5
          }
        },
        axisLabel: {
          color: 'rgba(216,240,255,0.6)'
        }
      },
      graphic: [
        {
          type: 'group',
          right: 0,
          top: 20,
          width: 100,
          height: 50,
          children: [
            {
              type: 'image',
              z: 100,
              left: 0,
              top: 0,
              shape: {
                width: 100,
                height: 50
              },
              style: {
                width: 100,
                height: 50,
                image:
                  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAAAyCAYAAADCxvyGAAAAAXNSR0IArs4c6QAAA5tJREFUeF7tW8ty2kAQ7NVKYGM7B1d8zM03PiL5leRL8j/5Hi6p/IFzieMyBnanU7MrgSArEI5PqaGK0gMJqK7untHMrMOCRO/1aYbPAOgY6SIoXve9uCpSoicQ6QjSe9Fr9Foh6PSeyotD3B7rZ0znAl0AnQdjAKtJLTGE9D3pPurv1OIY0nHab887bhgBVr6RCmDQe7hhBTLKRFwDVmtwwzUrgr6epOs2BCuuWDdTWaf9/G6mkOVyiSZeyssF6J/AyXuI/w0ur0D/C6wFXM0gum30HcHHAN7cQn5GcBrAywBer8CHOeQO4BzgV/3PRUBjpKswElBPUfALgDrvJYO9A1SowIFRz7WAVr6WmIDK154EdL1h1YwDNP1WM5UO0DBF2vcEXyRvJ5cZ0PoKfP4FPssjp7N3IwBdcD6fy+IkoAWGSnsus/cMhiq7fQY0gaZsq2rpAFUwEQKGGBq5yff4RoJu9Tv6gCrzEiPXmZ3b48zQ0AMzg7pkmF7KFtA+QxVg+QeGugJDP17EL0m6+sdj3EoeiIqiwMcETtWyTy1gX/JqAZmxe5Jnndh3KHkFtJN3HCv5AkNds2ZYnZZ8EdALCPCE9exaSpJ/jGDTSv4igg/nSH4HaAYlM9OLJO889NAM2pDkBz20Y6iv6ULgTvIjPbQFVBmZvPKIhx5Kvg9opV7Zk3znoYeS7wP6Og8tSj4Ho07ymaHHg5KkwFXw0BScsod2DM0eGtoAdCIoDUi+FJQOJb/mkvWQ5AeC0hCgH+4h3wGeDkpv6aG9KB81Y1C76Hlo9scuKB1naNFDe1G+5KHnBKVl66HloPTAm9u7XpT/wevV/TbKf1OLK3noLm3a99Ak/5aRmVnnM3QL6KQWlfo2SL0RoIcM9Q0EqxX6UV7TJpV5Kcp3gB6mTcOSX/BhPt+lTccBtTzU8tBCYn+O5C0P1Scly0P3Hz0tD917lrc8dNyzvOWhe8URy0N71aZzgpLloSfKd5aHji2OWD3U6qF/VeytHjqiYm/10LYFYvXQ3AKxeqjXilUtuVln9dDkq2f3lKwe2rWRrR7ale+sHmp9eevLj58csb689eWP9ZSsL38wimN9+dKwmPXl29km68vvZpusL18qjlhf3uZDiwO3Nh9q86E2H9obCbf50CNdT8tDLQ8dXgVi86G9GXvLQy0PtXVKtk5pzEo6q4daPdTqobZOyfry8urZJuvLW1/e1inZevn/d738Hx0WpuSdi/1dAAAAAElFTkSuQmCC'
              }
            },
            {
              type: 'text',
              z: 100,
              left: 'center',
              top: 8,
              silent: true,
              style: {
                fill: '#D4EDFD',
                text: t('message.analysisDetails.polarityOption[0]'), // '正面'
                font: '16px Microsoft YaHei'
              }
            },
            {
              type: 'text',
              z: 100,
              left: 'center',
              top: 30,
              silent: true,
              style: {
                fill: '#D8F0FF',
                text: props.dataObject?.frontSum,
                font: '16px YouSheBiaoTiHei'
                // textAlign: 'center',
              }
            }
          ]
        },
        {
          type: 'group',
          right: 0,
          top: 90,
          width: 100,
          height: 50,
          children: [
            {
              type: 'image',
              z: 100,
              left: 0,
              top: 0,
              shape: {
                width: 100,
                height: 50
              },
              style: {
                width: 100,
                height: 50,
                image:
                  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAAAyCAYAAADCxvyGAAAAAXNSR0IArs4c6QAAA4JJREFUeF7tW8tu20AMJHcl23EPQR/ooeeemq9Kv6B/0HxlTum9lx6CBE0k73IKrrS2JEvG1mkuBQMYkiJbhkdDcjTk8ttvP0CDP+fcNYEQGSCJYHiQgxAciCNYSI9B8KLbdKzvBYGdk6h76bOUXuydkNP9ABIP5gCilUSnxwCxnoN+T3c9fS954dh/j57T/8FL2qZjYKfnaxGONci14AiQ1+tUwoHQeGATgGZdC4f+3A7gzVro6YncRRRqN+D2EW77Qbh+gGvfgJ/vwSvBI7bCawH/FriLCH4MqD++E3cfUV0GVL8CVj8b3F5dCX0h0C2Bbgh8BCi568gRxK4D1HkQygE93IwemAxuAtCDPaQDhRB6QJm8kAIyB2i+aQNAdwnA+gBoaPW6I0AV3DYDSbUksCsFfC2swNYAtZK2DhcjQB9WAlcA6N3PBjQE9PscoIsM7Zh3kqHo2NixOzMUQnpTEkMBVkaSk9izjbwXCoH+hqFjQPVGUGIoryqhAChDE6CVRs6EoRnUDOiIoQJ+lnKGvm9wS0OG8jFDmekr6w+ehnxKCxAWTQFhMeRHDM0hn9OEHk9D3nvZ3yQFpSjkW+yOGFqDfLMP+T1DpyE/C+hG9Nd5ZeVSyG8Dajoj5BVQISc5D7LmxJRDCxg6zaHKHGVtAnSYQzNDPYgDzg/5SlLuXAh5DfFGwzuHvO5PAX1ByK+OGLqQQ7VQHBelckA9A1FB1OIym0M99kUpMzTnUP1ctVyUdgKs3JChCqgWv4UcOmWopoT1WvY59AVF6a75LPSJUFCUcqUeVvkCQGdz6FyVH+RQphcwdFzlpzl0lqGbU0Wpy6GlRemYoTM51KUq/48AnWNon0PZhdev8qiFqKFRyBcAqrKputzK/dNp2XRU5W/mADUdWl7lTYeaDu2fjMZPSqZDTYeaDi159DQdqsLedGjvH5wyR3q3yXToa7tNpkNLzRHzQw/miPmh2bE/91ne/FDzQ+daIOaHdt6n+aHmhw66nuaHLnU9zQ81P9T68taXt778dHLE+vIzbeTU9bS+fD+KY335frbJ+vJlkyOmQ02HjqfvDsNipkNNh5oONR1qOhQgmw+dzNifHAk3HWo69LAK5Nyeks2H2nyozYfaOiVbp1S0CsTWKdk6pW5povmh5ofuF36l2SbzQ80PnVuNXNJTsnVK3eJZ80PND/0//dA/N7XbHgG7ZrsAAAAASUVORK5CYII='
              }
            },
            {
              type: 'text',
              z: 100,
              left: 'center',
              top: 8,
              silent: true,
              style: {
                fill: '#D4EDFD',
                text: t('message.analysisDetails.polarityOption[2]'), // '中立'
                font: '16px Microsoft YaHei'
              }
            },
            {
              type: 'text',
              z: 100,
              left: 'center',
              top: 30,
              silent: true,
              style: {
                fill: '#D8F0FF',
                text: props.dataObject?.neutralitySum,
                font: '16px YouSheBiaoTiHei'
                //textAlign: 'center'
              }
            }
          ]
        },
        {
          type: 'group',
          right: 0,
          top: 160,
          width: 100,
          height: 50,
          children: [
            {
              type: 'image',
              z: 100,
              left: 0,
              top: 0,
              shape: {
                width: 100,
                height: 50
              },
              style: {
                width: 100,
                height: 50,
                image:
                  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAAAyCAYAAADCxvyGAAAAAXNSR0IArs4c6QAAA6JJREFUeF7tm79y00AQxnfvJNk4k4ahoqbCLS8ETwIPSaoMFFDQMBASksjW7cfsnRRJ1l9POmZTRKOxJce/fLv73e6JP737Cer8fP/65z0TQQLApL88HEGYHIQCHBOEAEde4rE+12vATlxIr8d7EMGzEz0yVWDyEK4AKsSFSu+LQBSPYC/xnAkZedFjej19BsiLHtM5EI76PpHAORwdEFj/Jr1PFt9XlYDfAlLmkjFwJMAzsOGNPNADiQuS0RYZ30HcK8n5FqW7QM43eGRBuNtJwYINC/66gC1XqPKXcuECfmUVLrMKP4oS+6u9vCXCFRE+6vccBRoCWoAe6AAFK5wZoE//jAYCItgGKDGkgSRVAt/AbIA28CaBHhVgHoF6zlGNAVWAB8BHyLl0gR4ZODD0eihscS/6QG8Fl24n90tAr0vsaS+fibCfBaoAgoLoKrRW5olCG8BPCu0ATQqFCPkINCo3qi0pOSqKvBBV1FXoOUCTMikqNOdMqlrRelSgQn2FRoC8kRZoUmjJt8idIGeJCr28WwF0oFAeKvTbl98fHDs5BapZQZXq2ENIw3Ui5AdAm5Cv08BJyCu8JrynFNqkgTbkDwjHpNA25DX0ywRVQ74GWlAuFSelqjLHgW5Fv5/4nTQhfw7QWYVGoOJEhaQKS/lLc+i4QudyqF5Dozm0UahHII37lD9XA30K+UySQtsc2gOqIMt+yA+BppBPCq1z6NqQrxX6rJBfzKFalDzAmihjIWlzqIa+poBAHlqUNAX4EYWeFqWeQo+AK7oKbUO+V5QmQl5VqoVpPOTborRGoeX1G3lNhGWgIRWesRy6CHQy5BWgB8eQb3OoVvklhQ6qfKcodav8IIeOKFRhjhWlXg49U6FtlR/JodE2zQBdtE0zVb4FWojjCmur/CCHTgA9VWhxyIWopDVVflCUsp3c3yzYpkGVnwJqPtR86MDYmw9tV0rmQ+ulZlx6mg/tr+XNh97g8cwqv2ybZpaei7bJfOhIt8l86LrmyJq1vPnQujliPtT6oatXSnNLT+uHWj80jUCsHzo9ArF+aK99Z/1Q64c2Qzrrh64Y0g1GINYPtbm8zeVtLm9zeZvLn7HRwXyo+dC0gSzuHLG5fLv7znyo+dD57Yw2l7f9oeMbbs2Hmg81H2o+NM2UbH+o7Q9d/9DC6VMg5kPNh5oPteeUnrkl3ObyNpe3ufzSo4nWD7V+qPVDR59GNh9qPtR8qPnQ/8yH/gNPckM02CqjWQAAAABJRU5ErkJggg=='
              }
            },
            {
              type: 'text',
              z: 100,
              left: 'center',
              top: 8,
              silent: true,
              style: {
                fill: '#D4EDFD',
                text: t('message.analysisDetails.polarityOption[1]'), // '负面'
                font: '16px Microsoft YaHei'
              }
            },
            {
              type: 'text',
              z: 100,
              left: 'center',
              top: 30,
              silent: true,
              style: {
                fill: '#D8F0FF',
                text: props.dataObject?.negativeSum,
                font: '16px YouSheBiaoTiHei'
                //textAlign: 'center'
              }
            }
          ]
        }
      ],
      series: lineData
    };
  } else {
    let colors = ['#2055f4', '#f0a431', '#ed6363'];
    option.value = {
      dataZoom: props.dataZoom
        ? {
            type: 'slider',
            minValueSpan: 1,
            height: 20,
            bottom: 10,
            showDetail: false
          }
        : null,
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: [
          t('message.analysisDetails.polarityOption[0]'),
          t('message.analysisDetails.polarityOption[2]'),
          t('message.analysisDetails.polarityOption[1]')
        ] //['正面', '中立', '负面']
      },
      color: props.dataObject?.color,
      grid: {
        // left: '1%',
        left: 16,
        right: isMobile() ? 16 : 140,
        bottom: props.dataZoom ? 35 : 5,
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: props.dataObject?.xAxis
      },
      yAxis: {
        type: 'value'
      },
      graphic: isMobile()
        ? []
        : [
            {
              type: 'group',
              right: 10,
              top: 20,
              width: 100,
              height: 65,
              children: [
                {
                  type: 'rect',
                  z: 100,
                  left: 0,
                  top: 0,
                  shape: {
                    width: 100,
                    height: 65,
                    r: 5
                  },
                  style: {
                    // fill: 'rgb(112, 229, 193)',
                    fill: colors[0]
                  }
                },
                {
                  type: 'text',
                  z: 100,
                  left: 23,
                  top: 16,
                  silent: true,
                  style: {
                    fill: '#fff',
                    text: t('message.analysisDetails.polarityOption[0]'), // 正面
                    font: '16px Microsoft YaHei'
                  }
                },
                {
                  type: 'text',
                  z: 100,
                  left: 19,
                  top: 42,
                  silent: true,
                  style: {
                    fill: '#fff',
                    text: props.dataObject?.frontSum,
                    font: '14px Microsoft YaHei'
                    // textAlign: 'center',
                  }
                }
              ]
            },
            {
              type: 'group',
              right: 10,
              top: 100,
              width: 100,
              height: 65,
              children: [
                {
                  type: 'rect',
                  z: 100,
                  left: 0,
                  top: 0,
                  shape: {
                    width: 100,
                    height: 65,
                    r: 5
                  },
                  style: {
                    // fill: 'rgb(247, 182, 79)',
                    fill: colors[1]
                  }
                },
                {
                  type: 'text',
                  z: 100,
                  left: 23,
                  top: 16,
                  silent: true,
                  style: {
                    fill: '#fff',
                    text: t('message.analysisDetails.polarityOption[2]'), // 中立
                    font: '16px Microsoft YaHei'
                  }
                },
                {
                  type: 'text',
                  z: 100,
                  left: 19,
                  top: 42,
                  silent: true,
                  style: {
                    fill: '#fff',
                    text: props.dataObject?.neutralitySum,
                    font: '14px Microsoft YaHei'
                    //textAlign: 'center'
                  }
                }
              ]
            },
            {
              type: 'group',
              right: 10,
              top: 180,
              width: 100,
              height: 65,
              children: [
                {
                  type: 'rect',
                  z: 100,
                  left: 0,
                  top: 0,
                  shape: {
                    width: 100,
                    height: 65,
                    r: 5
                  },
                  style: {
                    // fill: 'rgb(245, 136, 147)',
                    fill: colors[2]
                  }
                },
                {
                  type: 'text',
                  z: 100,
                  left: 23,
                  top: 16,
                  silent: true,
                  style: {
                    fill: '#fff',
                    text: t('message.analysisDetails.polarityOption[1]'), // 负面
                    font: '16px Microsoft YaHei'
                  }
                },
                {
                  type: 'text',
                  z: 100,
                  left: 19,
                  top: 42,
                  silent: true,
                  style: {
                    fill: '#fff',
                    text: props.dataObject?.negativeSum,
                    font: '14px Microsoft YaHei'
                    //textAlign: 'center'
                  }
                }
              ]
            }
          ],
      series: props.dataObject?.seriesLine
    };
  }
};
onMounted(() => {
  changeOption();
});
</script>
