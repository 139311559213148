<script lang="ts" setup>
import { ShallowRef, shallowRef, PropType, watchEffect } from 'vue';
import BaseChart from '../BaseChart/BaseChart';
import type { ECOption, SeriesItem, ChartDataItem, Arrayable } from '../utils';
import {
  buildGrid,
  buildGridWidthLegend,
  buildLegend,
  buildAxis,
  buildAxisLabelFormatter,
  calcRightMargin,
  getFontWidth,
  normalizeAxis,
  normalizeSeries,
  buildTooltip
} from '../utils';
import { XAXisComponentOption, YAXisComponentOption } from 'echarts';
import useChartClick from '../hooks/useChartClick';

export interface LineChartProps {
  series: SeriesItem<number | ChartDataItem>;
  tooltip?: boolean;
  legend?: boolean;
  smooth?: boolean;
  xAxis?: XAXisComponentOption | boolean;
  yAxis?: YAXisComponentOption | boolean;
  area?: boolean;
  areaStyle: object;
}

let option: ShallowRef<ECOption> = shallowRef({});

// 用 ts 泛型的方式在传参校验阶段会不识别类型
const props = defineProps({
  series: {
    type: Object as PropType<SeriesItem<number | ChartDataItem>>,
    required: true
  },
  tooltip: Boolean as PropType<boolean>,
  legend: Boolean as PropType<boolean>,
  smooth: {
    type: Boolean as PropType<boolean>,
    default: true
  },
  area: Boolean as PropType<boolean>,
  xAxis: {
    type: [Object, Boolean] as PropType<XAXisComponentOption | boolean>,
    default: true
  },
  yAxis: {
    type: [Object, Boolean] as PropType<YAXisComponentOption | boolean>,
    default: true
  },
  withUnit: {
    // 坐标轴是否需要简化量级
    type: Boolean as PropType<boolean>,
    default: true
  },
  color: {
    type: Array
  },
  areaStyle: {
    default: () => {
      return {};
    }
  },
  dataZoom: {
    type: Boolean,
    default: true
  },
  animation: {
    type: Boolean,
    default: true
  },
  theme: String
});

watchEffect(() => {
  const propSeries = props.series;
  let series = normalizeSeries(propSeries, 'line');
  series = series.map(s => ({ smooth: props.smooth, ...s }));
  props.area &&
    (series = series.map(s => ({
      smooth: props.smooth,
      areaStyle: props.areaStyle ? props.areaStyle : { opacity: 0.2 },
      ...s
    })));

  option.value = {
    animation: props.animation,
    series: series as unknown as any,
    dataZoom: props.dataZoom
      ? {
          type: 'slider',
          minValueSpan: 1,
          height: 20,
          bottom: 10,
          showDetail: false
        }
      : null
  };
  if (props.color) option.value.color = props.color;

  const axisLabel = buildAxisLabelFormatter(props);

  const xAxis = (option.value.xAxis = buildAxis(
    'x',
    normalizeAxis(props.xAxis) as XAXisComponentOption
  ) as Arrayable<XAXisComponentOption>);
  option.value.yAxis = buildAxis('y', {
    ...normalizeAxis(props.yAxis),
    ...axisLabel
  } as YAXisComponentOption) as Arrayable<YAXisComponentOption>;
  const label = (xAxis as any)?.data?.[0] || '';
  const rightMargin = calcRightMargin(label);

  if (props.legend) {
    option.value.legend =
      props.theme == 'bigscreen-theme-2'
        ? buildLegend({
            type: 'scroll',
            left: 'center',
            backgroundColor: 'rgba(54,97,158,0.22)',
            itemGap: 16,
            padding: [4, 12],
            textStyle: {
              color: 'rgba(216,240,255,0.8)'
            },
            top: 10
          })
        : buildLegend({ type: 'scroll', left: '1%', top: 10 });
    // option.value.grid = buildGridWidthLegend({ right: rightMargin, left: 15 })
    option.value.grid = buildGridWidthLegend({ right: rightMargin, left: 27 });
  } else {
    option.value.grid = buildGrid({ right: rightMargin, left: 15 });
  }
  if (props.dataZoom) {
    option.value.grid.bottom += option.value.dataZoom.height + 10;
  }
  option.value.tooltip = buildTooltip(props.tooltip);
  if (props.theme == 'bigscreen-theme-2') {
    Object.assign(option.value.tooltip, { trigger: 'axis' });
    Object.assign(option.value.yAxis, {
      splitLine: {
        lineStyle: {
          color: 'rgba(176,215,255,0.25)',
          type: [5, 10],
          dashOffset: 5
        }
      },
      axisLabel: {
        color: 'rgba(216,240,255,0.6)'
      }
    });
    Object.assign(option.value.xAxis, {
      axisLabel: { color: 'rgba(216,240,255,0.6)' }
    });
    series.forEach(s => {
      s.symbol = 'none';
      s.areaStyle = {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: `rgba(94,180,235,0.3)`
            },
            {
              offset: 1,
              color: `rgba(94,180,235,0)`
            }
          ]
        }
      };
    });
  }
});
interface Emits {
  (e: 'chart:click', params: any): void;
}
const emit = defineEmits<Emits>();
const chartRef = useChartClick(emit);
</script>
<template>
  <base-chart
    ref="chartRef"
    :option="option"
    class="wh-chart--line"
    :bigscreenTheme="theme"
  />
</template>

<style lang="scss" scoped></style>
