import type { App } from 'vue';

// 可视化组件
import Overview from '@wenhai/visual-components/Overview/Overview.vue';
import StripChart from '@wenhai/visual-components/StripChart/StripChart.vue';
import Timeline from '@wenhai/visual-components/TimeLine/Timeline.vue';
import InfoList from '@wenhai/visual-components/InfoList/InfoList.vue';
import RingChart from '@wenhai/visual-components/RingChart/RingChart.vue';
import BarChart from '@wenhai/visual-components/BarChart/BarChart.vue';
import StageEvolution from '@wenhai/visual-components/StageEvolution/StageEvolution.vue';
import TopicList from '@wenhai/visual-components/TopicList/TopicList.vue';
import HotList from '@wenhai/visual-components/HotList/HotList.vue';
import LineChart from '@wenhai/visual-components/LineChart/LineChart.vue';
import WordCloud from '@wenhai/visual-components/WordCloud/WordCloud.vue';
import GaugeChart from '@wenhai/visual-components/GaugeChart/GaugeChart.vue';
import CardChart from '@wenhai/visual-components/CardChart/CardChart.vue';
import CardChartDom from '@wenhai/visual-components/CardChart/CardChartDom.vue';
import CardStatisticsChart from '@wenhai/visual-components/CardChart/CardStatisticsChart.vue';
import PropagationCapacity from '@wenhai/visual-components/PropagationCapacity/PropagationCapacity.vue';
import OvalRingChart from '@wenhai/visual-components/OvalRingChart/OvalRingChart.vue';

import MapChart from '@wenhai/visual-components/MapChart/MapChart.vue';
import BubbleChart from '@wenhai/visual-components/BubbleChart/BubbleChart.vue';
import TreeMap from '@wenhai/visual-components/TreeMap/TreeMap.vue';
import RelationalGraph from '@wenhai/visual-components/RelationalGraph/RelationalGraph.vue';
import RadarChart from '@wenhai/visual-components/RadarChart/RadarChart.vue';
import PropagationPathList from '@wenhai/visual-components/PropagationPathList/PropagationPathList.vue';
import ClockChart from '@wenhai/visual-components/ClockChart/ClockChart.vue';
const visualComponents = [
  Overview,
  StripChart,
  Timeline,
  InfoList,
  RingChart,
  BarChart,
  StageEvolution,
  TopicList,
  LineChart,
  WordCloud,
  GaugeChart,
  CardChart,
  CardChartDom,
  PropagationCapacity,
  OvalRingChart,
  MapChart,
  BubbleChart,
  TreeMap,
  RelationalGraph,
  RadarChart,
  PropagationPathList,
  CardStatisticsChart,
  HotList,
  ClockChart
].map(c => {
  return (c.install = (app: App): void => {
    app.component(c.__name!, c);
  });
});

export default visualComponents;
