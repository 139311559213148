// 定义内容
export default {
  searchForm: {
    placeholder1: '请输入关键词检索',
    placeholder2: '输入搜索内容',
    placeholder3: '请输入关键词,回车键确认',
    placeholder4: '请输入关键词',
    mode1: '表达式',
    mode2: '关键词',
    mode3: '云搜索',
    mode4: '全文',
    mode5: '标题',
    mode6: '正文',
    mode7: '标题正文',
    search: '搜索',
    search1: '查询',
    transferAnalysis: '转分析',
    turntofmonitor: '转监测',
    example: '示例：',
    tipTtitle1: '(北京&冬奥)&(滑雪|滑冰|冰球|雪橇)!(雪车|冰壶)',
    tipTtitle2: '(中国|北京)&(科技冬奥|冰雪科技)',
    modeSwitchTip:
      '当前为表达式搜索，切换到关键词搜索将清空已输入的关键词，是否切换？',
    rememberChoice: '记住我的选择,下次不再提醒',
    note: '帮助说明：',
    noteTip: ``,
    notPrompt: '以后不再提示',
    close: '关闭',
    moreActions: '更多操作',
    saveThemeTip: '请先保存主题再继续操作！',
    regionSwitchTip:
      '修改数据权限后部分筛选条件的可选项也会改变，并重置全部已选内容，确定修改吗 ？',
    domestic: '境内',
    abroad: '境外',
    chooseRegionTip: '最少选择一个数据权限！',
    filterCriteria: '筛选条件',
    pleaseChoose: '请选择',
    reset: '重置',
    positive: '正面',
    negative: '负面',
    neutral: '中立',
    irrelevant: '无关',
    toAnalyzed: '待分析',
    today: '今天',
    unlimited: '不限',
    nearly24hours: '近24小时',
    nearly3days: '近3天',
    nearly7days: '近7天',
    nearly14days: '近14天',
    nearly30days: '近30天',
    nearly3months: '近3个月',
    custom: '自定义',
    original: '原文',
    repost: '转发',
    comment: '评论',
    all: '全部',
    agencyCertification: '机构认证',
    personalAuthentication: '个人认证',
    unauthorized: '未认证',
    corporate: '公司企业',
    other: '其他',
    minfancount: '最少粉丝数',
    maxfancount: '最多粉丝数',
    more: '更多',
    packup: '收起',
    showMore: '更多',
    pubtime: '发布时间',
    warehousetime: '入库时间',
    matchMethods: '命中位置',
    mediaType: '媒体渠道',
    polarity: '情感倾向',
    languages: '语言类型',
    mediaTags: '推荐标签',
    areaIds: '地域识别',
    authTypes: '账号认证',
    fansCount: '粉丝数量',
    articleType: '数据类型',
    keywordsTip: '关键词不可重复',
    keywordsTip2:
      '选中简繁匹配关键词个数将超过关键词限制个数，请先删除部分关键词！',
    keywordsTip3: '关键词个数不超过{count}个',
    keywordsTip4: '请按正确的公式填写',
    keywordsTip5: '关键词搜索模式输入不能包含&、{`|`}、!、(、)符号',
    standardFuzzy: ['精准', '模糊'],
    standardFuzzyLabel: '精准/模糊',
    confirm: '确定',
    cancel: '取消',
    notes: [
      `1. 公式可包括"与"，"或”，"非"的关系，用"&"代表"与"，"{'|'}"代表"或"，"!"代表"非";`,
      '2. 每个公式中的每组关键词必须以"()"包含，不支持2级括号及1组以上配置；',
      `3. 关键词搜索模式输入不能包含&、{'|'}、!、(、)符号；`,
      `4. 表达式搜索模式下，每个公式中的每组关键词只能有&或{'|'}一种关系，大于2组关键词时，每组关键词之间不能出现{'|'}和!同时存在，也不能出现{'|'}和&同时存在；多组公式或关键词之间是"或关系"；回车输入下一组关键词或公式。`
    ],
    examples: [
      `(北京&冬奥)&(滑雪{'|'}滑冰{'|'}冰球{'|'}雪橇)!(雪车{'|'}冰壶)`,
      `(中国{'|'}北京)&(科技冬奥{'|'}冰雪科技)`
    ],
    topBtns: ['添加分析', '添加监测', '大模型报告', '可视化大屏'],
    timeControl: ['请选择开始时间', '请选择结束时间', '至'],
    infoMatch: '信息匹配',
    dataType: '数据类型',
    textPosition: '命中位置',
    Sentiment: '情感倾向',
    InfoClassification: '信息分类',
    STCTMatch: '简繁匹配',
    dialogue: '对话',
    mediaScope: '媒体范围',
    mediaTag: '媒体标签',
    specifiedMedia: '指定媒体',
    mediaLevel: '媒体等级',
    mediaLevelTips: [
      '在全球影响力大、覆盖面广、传播力强、受众数量多的传媒机构，主要包括电视台、广播电台、报纸、杂志和新闻资讯网站等',
      '在领域有很高的知名度、权威性、影响力，报道内容深度和广度都很高',
      '指在领域内具有一定专业性和用户群体的媒体',
      '具有国家官方背景，由政府直接或间接控制，或者为其提供经费补贴资源的媒体'
    ],
    mediaRegion: '媒体地域',
    organizationType: '机构类型',
    accountProperty: '账号属性',
    regionOrLanguage: '地域/语言',
    advancedFilter: '高级筛选',
    fansList: ['0-1万', '1-10万', '10-100万', '100万以上'],
    areaList: ['IP属地', '媒体属地', '内容提及'],
    noKeywordOrMeidasTip: '请输入关键词或选择媒体范围',
    zhandianfanwei: '站点范围',
    siteWideList: ['全部站点', '付费站点', '非付费站点'],
    customizeTags: '输入自定义标签',
    commonTags: '常用标签',
    noTags: '暂无相关标签',
    eliminate: '剔除',
    ExcludedInformation: '已剔除信息',
    eliminateMsg1:
      '1.批量选择当前页需要剔除的文章，点击【剔除】按钮，即可从相应的主题中将所选中文章剔除；',
    eliminateMsg2: '2. 剔除数据可以在【已剔除信息】中查看和取消剔除。',
    eliminateMsg3: '相似信息跨渠道去重，媒体渠道未进行渠道内去重统计',
    YayiAnalysis: 'YaYi分析',
    AppendChat: '追加聊天',
    AppendBriefs: '追加简报'
  },
  searchScheme: {
    savedScheme: '已保存的主题',
    placeholderEnterTopicName: '输入主题名称',
    placeholderEnterTopicName1: '请输入主题名称',
    topicName: '主题名称',
    lastModifiedDate: '最后修改日期',
    saveBtn: '保存',
    saveNewTheme: '记录另存',
    searchTopics: '搜索主题',
    enterName: '输入名称',
    noFilterData: '无筛选结果',
    deleteSchemeTip: '确认删除主题',
    deleteSchemeChildTip: '将同时删除所有子主题',
    cancel: '取消',
    confirm: '确认',
    tip: '提示',
    transferAnalysisSuccTip: '添加分析成功!',
    viewLater: '稍后查看',
    gocheck: '去查看',
    createAnalysis: '创建分析',
    createSubthemes: '创建子主题',
    rename: '重命名',
    delete: '删除',
    emptyThemeName: '主题名称不可为空',
    themeNameLimit: '主题名称长度不可超过20个字符',
    createTheme: '创建主题',
    createFolder: '创建文件夹',
    deleteTheme: '删除主题',
    createThemeSucessTip: '主题创建成功',
    createThemeFailed: '主题创建失败',
    saveThemeSucc: '主题保存成功',
    saveThemeFailed: '主题保存失败',
    nameModifiedSucc: '名称修改成功',
    nameModifiedFailed: '名称修改失败',
    deleteSucceeded: '删除成功',
    deleteFailed: '删除失败',
    keywordEmpty: '关键词不可为空',
    operationSucceeded: '操作成功',
    operationFailed: '操作失败',
    createFailed: '创建失败',
    createTopic: '创建主题',
    duplicate: '复制',
    placeholderEnterTopicName2: '请输入文件夹名称',
    addedSuccess: '添加成功!'
  },
  searchResult: {
    mode1: '表达式模式',
    mode2: '关键词模式',
    mode3: '搜索',
    modeSW1: '转换为关键词搜索模式',
    modeSW2: '转换为表达式搜索模式',
    inputRich1: '必要关键字',
    inputRich2: '任意包含',
    inputRich3: '需要排除',
    allCheck: '全选',
    multipleSort: '综合排序',
    timeSort: '时间排序',
    hotSort: '热度',
    commonSort: '评论量',
    downLoad: '下载',
    checked: '已选',
    downConfirm: '确定下载',
    confirm: '确认',
    custom: '自定义',
    cancel: '取消',
    moreFilters: '更多筛选',
    full: '全文',
    all: '全部',
    duplicate: '去重',
    notDuplicate: '不去重',
    drowTitle: '标题/Title(更多筛选)',
    Release: '发布渠道',
    clearAll: '全部清空'
  },
  designatedMediaLabelId: {
    saveBtn: '保存',
    cancel: '取消',
    addMediaTip: '添加媒体',
    addMediaList: '添加媒体组'
  },
  searchSpecifiedMedia: {
    centralMedia: '中央媒体',
    businessPortal: '商业门户',
    selectedMedia: '已选媒体',
    selectMedia: '选择媒体',
    clear: '清空',
    noMediaTip: ['暂无媒体,可点击', '选择媒体', '进行添加'],
    forfiltering: '在结果中',
    include: '筛选',
    exclude: '排除',
    mediaGroup: '媒体组',
    mediaGroupPlaceholder: '输入媒体组名称',
    saveAndApply: '保存并应用',
    mediaSearchPlaceholder: '搜索添加',
    addAll: '添加全部',
    searchWithinaWebsite: '站点内搜索',
    nodata: '暂无数据',
    search: '检索',
    advancedSearchPlaceholder: '请输入站点名称和域名，如sina.cn',
    advancedSearch: '高级搜索',
    loadMoreText: ['加载中', '上划加载更多', '没有更多了'],
    searchTip: '可通过右上角“搜索”功能，搜索添加',
    addNewGroup: '保存到新的媒体组',
    mediaGroupBtn: [
      '创建分组',
      '编辑分组',
      '请输入分组名称',
      '删除分组',
      '分组被删除后，无法找回，是否确认要删除此分组'
    ],
    channelType: ['站点', '频道/群组', '账号'],
    mediaGrouPlaceholder: '输入媒体组名称',
    masterStation: '主站',
    subStation: '子站'
  },
  AnalysisPreview: {
    commentCount: '评论量',
    likeCount: '点赞量',
    watchCount: '在看量',
    readCount: '阅读量',
    rttCount: '转发量'
  },
  resultIntelligentWordFilling: ['智能填词']
};
