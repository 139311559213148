<template>
  <div
    ref="hotwordRef"
    v-loading="loading"
    v-nodata="!Boolean(data.length) && !loading"
    style="overflow: hidden"
    class="without-padding"
  >
    <!-- <img v-if="isImage && data.length" :src="imageUrl" style="width: 100%; height: 100%" /> -->
    <WordCloud
      ref="refChart"
      v-if="!loading && data.length"
      :series="{ data: data.filter(item => item.status != 0) }"
      :type="chartType"
      :theme="theme"
      :key="updateKey"
      :drilling="drilling"
      @chart:click="chartClick"
      :size="zoomSize"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  nextTick,
  toRefs,
  inject,
  watch,
  toRef
} from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { isImage, getChartImage, utilDebounce } from '../../utils';
import { useEditorStore, EsideBarType } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { useDataCenter } from '../../_hooks/use-data-center';
export default defineComponent({
  name: 'AHotWord',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean,
    zoom: {
      type: Number,
      default: 1
    },
    comIndex: String
  },
  setup(props, { emit }) {
    const hotwordRef = ref();
    const updateKey = ref();
    watch(toRef(props, 'theme'), () => {
      updateKey.value = new Date().getTime();
    });
    const refChart = ref();
    const imageUrl = ref('');
    const { hotWords } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const chartType = toRefs(props.params.chartType);

    const data = ref<any[]>([]);
    const loading = ref(false);
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const EditorStore = useEditorStore();
    const { drillPreviewData, sideBarType, privateSetting } =
      storeToRefs(EditorStore);
    const apiData = ref();
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        startPubTime: apiData.value?.startPubTime,
        endPubTime: apiData.value?.endPubTime
      };
    };
    const { setCurrentDrillingScheme } = useDataCenter(params.value);
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      data.value.splice(0);
      chartType.value = params.value.chartType;
      try {
        const res = await hotWords(params.value);
        if (res.code === 200) {
          apiData.value = res.data;
          if (chartsClick) {
            setDrillPreviewData();
            searchResultList();
          }
          const resData = res?.data?.itemList as any[];
          data.value.push(
            ...resData.map((item: any) => {
              return {
                name: item.name,
                value: item.docCount,
                status: item.status
              };
            })
          );
          if (
            sideBarType.value === EsideBarType.privateSetting &&
            props.comIndex === privateSetting.value.i
          ) {
            EditorStore.setPrivateSetting(null, { data: data.value });
          }
          nextTick(() => {
            saveChartImage();
          });
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const saveChartImage = utilDebounce(() => {
      refChart.value && (imageUrl.value = getChartImage(refChart));
    }, 300);
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(params.value.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    const baseSize = [18, 90];
    const size = ref([baseSize[0] * props.zoom, baseSize[1] * props.zoom]);
    const zoomSize = computed(() => {
      return [size.value[0] * props.zoom, size.value[1] * props.zoom];
    });
    watch(toRef(props, 'zoom'), val => {
      size.value[0] = baseSize[0] * val;
      size.value[1] = baseSize[1] * val;
      updateKey.value = new Date().getTime();
    });
    return {
      refChart,
      isImage,
      imageUrl,
      data,
      loading,
      params,
      update,
      saveChartImage,
      chartType,
      updateKey,
      chartClick,
      zoomSize,
      hotwordRef
    };
  }
});
</script>
