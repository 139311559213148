// 定义内容
export default {
  collectMenu: {
    title: '收藏夹',
    searchPlaceholder: '输入收藏夹名称',
    editBtn: ['编辑', '删除'],
    modalDelMenu: [
      '删除标签',
      '删除标签将同时删除标签下所有数据，确认要删除该标签吗？'
    ],
    collectMessageToast: ['自定义标签创建数量已达上限', '名称不可重复'],
    customMenuName: '自定义标签',
    useModalBtn: ['取消', '确认']
  },
  addCollectForm: {
    addCollection: '添加收藏',
    createNewCollection: '新建收藏夹',
    add: '添加',
    addTips: ['收藏成功', '稍后查看', '立即查看'],
    note: '提示',
    removeCollectTip: '是否确认取消收藏？'
  }
};
