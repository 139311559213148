<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(data?.value) && !loading"
    style="overflow-y: auto"
  >
    <GaugeChart
      ref="refChart"
      v-if="!loading && data?.value"
      :type="paramsObj.chartType"
      :data="data"
      :theme="theme"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed, defineProps, defineExpose } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { useI18n } from 'vue-i18n';
const props = defineProps({
  params: {
    type: Object,
    required: true
  },
  theme: String
});
const refChart = ref();
const data = ref<any>();
const loading = ref(false);
const paramsObj = computed(() => {
  return props.params;
});
const { influenceIndexation } = useAnalysisApi();
const { t } = useI18n();
const getData = async () => {
  if (!paramsObj.value.schemeId) return;
  loading.value = true;
  try {
    const res = await influenceIndexation(paramsObj.value);
    if (res.code == 200) {
      data.value = {
        name: t('message.analysisComponents.informationOverviewComponents[1]'),
        value: res.data?.influenceNum
      }; //'影响力指数'
    }
    loading.value = false;
  } catch (error) {
    loading.value = false;
  }
};
onMounted(() => {
  getData();
});
const update = () => {
  getData();
};

defineExpose({
  update
});
</script>
