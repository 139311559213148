import { use, ComposeOption } from 'echarts/core';
import {
  BarChart,
  BarSeriesOption,
  LineChart,
  LineSeriesOption,
  PieChart,
  PieSeriesOption,
  GaugeChart,
  GaugeSeriesOption,
  MapChart,
  MapSeriesOption,
  TreeChart,
  TreeSeriesOption,
  RadarSeriesOption
} from 'echarts/charts';
import themeConfig1 from '../theme/wenhai-theme-1.json';
import { TreemapSeriesOption, WordCloudSeriesOption } from 'echarts';
import 'echarts-gl';

import {
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
  GridComponent,
  GridComponentOption,
  PolarComponent,
  PolarComponentOption,
  MarkAreaComponent,
  MarkAreaComponentOption,
  VisualMapComponent,
  VisualMapComponentOption,
  GraphicComponent,
  GraphicComponentOption
} from 'echarts/components';
import { XAXisComponentOption, YAXisComponentOption } from 'echarts';
import { CanvasRenderer } from 'echarts/renderers';
import { registerTheme } from 'echarts';

export type Arrayable<T> = T | T[];

export const themeMap = {
  theme1: 'wenhai-theme-1'
} as const;

export type ThemeKey = keyof typeof themeMap;

export const buildChart = () => {
  if (buildChart.built) return;
  use([
    BarChart,
    PieChart,
    LineChart,
    GaugeChart,
    MapChart,
    TreeChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    PolarComponent,
    MarkAreaComponent,
    VisualMapComponent,
    GraphicComponent,
    CanvasRenderer
  ]);
  buildTheme();
  buildChart.built = 1;
};

buildChart.built = 0;

export type WordCloudShape =
  | 'circle'
  | 'cardioid'
  | 'diamond'
  | 'triangle-forward'
  | 'triangle'
  | 'star';

export interface WordCloudDataItem {
  name: string;
  value: string | number;
}

export type ECOption = ComposeOption<
  | BarSeriesOption
  | PieSeriesOption
  | WordCloudSeriesOption
  | GaugeSeriesOption
  | LineSeriesOption
  | MapSeriesOption
  | TreeSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | GridComponentOption
  | TitleComponentOption
  | PolarComponentOption
  | MarkAreaComponentOption
  | VisualMapComponentOption
  | GraphicComponentOption
  | TreemapSeriesOption
  | RadarSeriesOption
>;

type ThemeOption = Record<string, any>;
export const buildTheme = (theme?: ThemeKey, themeConfig?: ThemeOption) => {
  if (!theme) {
    theme = 'theme1';
  }
  if (!themeConfig) {
    themeConfig = themeConfig1;
  }
  registerTheme(theme, themeConfig as ThemeOption);
};

export type SeriesItem<T = number | ChartDataItem> = Arrayable<{
  name: string;
  data: T[];
}>;

export interface ChartDataItem {
  name: string;
  value: number;
}

export type ChartType = Exclude<
  Exclude<ECOption['series'], undefined>,
  any[]
>['type'];
export const normalizeSeries = (
  series: SeriesItem,
  type: Exclude<ChartType, undefined | 'wordCloud'>
) => {
  let newSeries = series;
  if (!Array.isArray(series)) newSeries = [series];
  return (newSeries as Extract<SeriesItem, any[]>).map(n => ({ type, ...n }));
};

export const normalizeAxis = (
  opt: XAXisComponentOption | YAXisComponentOption | boolean
): XAXisComponentOption | YAXisComponentOption => {
  if (typeof opt === 'boolean') return { show: opt };
  return opt;
};

export const buildGrid = (
  option: GridComponentOption = {
    left: 10,
    right: 10,
    top: 32,
    bottom: 5,
    containLabel: true
  }
) => {
  const newOpt: GridComponentOption = {
    left: option.left ?? 10,
    right: option.right ?? 10,
    top: option.top ?? 32,
    bottom: option.bottom ?? 5,
    containLabel: option.containLabel ?? true
  };
  return {
    ...option,
    ...newOpt
  };
};

export const buildGridWidthLegend = (
  option: GridComponentOption = { top: 66 }
) => {
  return buildGrid({ top: option.top ?? 66, ...option });
};

export const buildLegend = (opt: LegendComponentOption = { left: 0 }) => {
  const newOpt: LegendComponentOption = {
    left: opt.left ?? 0,
    top: opt.top ?? 20
  };
  return {
    ...opt,
    ...newOpt
  };
};

export const buildAxis = (
  dir: 'x' | 'y',
  opt: XAXisComponentOption | YAXisComponentOption = {}
) => {
  if (dir === 'x') {
    return {
      type: 'category',
      boundaryGap: false,
      ...opt
    };
  }
  return {
    type: 'value',
    ...opt
  };
};
