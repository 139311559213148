/* eslint-disable no-unused-vars */
// https://pinia.vuejs.org/
import { defineStore } from 'pinia';
import useAnalysisApi from '/@/api/analysis';
import { debounce, realTime, EsortWay, deepClone } from '/@/utils/other';
import {
  getBaseConfig,
  EComponent,
  staticComponents
} from '/@/views/analysis/analysis-editor/canvas-panel/widget/component-library/library';
import { transRealTime } from '/@/utils/other';
import useSearchForm from '/@/views/search/hooks/useSearchForm';
import { useSearchSchemeStore } from '/@/stores/searchScheme';
const { getChannelIds, getfilterList } = useSearchForm();

export interface Account {
  userName: string;
  userId: string;
  dataSourceType: string;
  websiteName: string;
  uuid: string;
}
export interface IPrivateSettingParams {
  name: string; // 组件名称
  chartType: string; // 图表类型
  schemeId: number; // 方案id
  schemeIdList?: number[]; //多选方案id
  dataSource: any[]; // 媒体渠道
  dataSourceUpdated?: boolean;
  realTime: realTime;
  startPubTime: string;
  endPubTime: string;
  sortInfo?: {
    sortField: number;
    sortWay: EsortWay;
  };
  stackType?: number;
  dateInterval?: string;
  size?: number;
  titleName?: string;
  valueName?: string;
  interactiveType?: number;
  polarity?: number[];
  tagName?: string[];
  mediaTag?: string[];
  province?: string;
  mediaTags?: string[];
  isTrace?: number;
  targetArticleInfo?: {
    uuid?: string;
    title: string;
    author: string;
    publishTime: string;
    site: string;
    coverImage: string;
    fingerprint: string;
  };
  uuid?: string;
  articlePublishTime?: string;
  fingerprint?: string;
  province_front?: string;
  dataField?: string;
  rootName?: string;
  type?: number;
  startTime: string;
  endTime: string;
  mediaListAccountId?: number;
  datasourceList?: string[];
  mediaAccountList?: Account[];
  articleType: (1 | 2 | 3)[];
  autoScroll?: boolean;
  autoScrollStep: number;
  pageLinkId?: number;
  titleRows?: number; //信息列表类组件标题展示行数
  clockList?: any[]; //世界时钟列表
  countdownLabel?: string; //倒计时名称
  countdownEndtime?: string; //倒计时截止时间
  countdownUnit?: string; //倒计时单位
}
export interface ILayoutItem {
  x: number;
  y: number;
  w: number;
  h: number;
  i: number | string;
  componentConfig: {
    //组件属性设置
    id: number;
    name: string;
    type: string;
    style?: object; //样式参数设置
    data: IPrivateSettingParams; //数据参数设置
  };
}

export enum EsideBarType {
  library = 'library',
  privateSetting = 'privateSetting',
  globalSetting = 'globalSetting'
}
export interface OutlineItem {
  id: number;
  name: string;
  layout: ILayoutItem[];
}

export interface IAnalysisConfig {
  name: string;
  id: number;
  sid: number;
  articleType: (1 | 2 | 3)[];
  templateId: number;
  realTime: realTime;
  startTime: string;
  endTime: string;
  datasourceInfo: any[];
  bigScreenMode: boolean; //开启大屏风格
  screenWidth: number; //大屏尺寸
  screenHeight: number;
  extendedConfig: {
    bigScreenTheme: string; //大屏主题
    screenRefresh: boolean; //大屏是否自动刷新
    saved: boolean; //判断大屏是否已保存，展示运行按钮
  };
  style: {
    canvasBackgroundColor: string;
    componentBakcgroundColor: string;
    componentBakcgroundTransparent: boolean;
    componentTitleColor: string;
    componentTitleFontSize: string;
    componentTitleAlign: string;
  };
}

// 默认大屏配置
export const defaultBigScreenConfig = {
  bigScreenMode: true,
  screenWidth: 1920,
  screenHeight: 1080,
  extendedConfig: {
    bigScreenTheme: 'bigscreen-theme-2', // 大屏主题
    screenRefresh: false,
    saved: false
  }
};

// 默认画布样式
export const defaultAnalysisStyle = {
  canvasBackgroundColor: '#ffffff',
  componentBakcgroundColor: '#ffffff',
  componentBakcgroundTransparent: false,
  componentTitleColor: '#000000',
  componentTitleFontSize: '14px',
  componentTitleAlign: 'left'
};

export interface AnlysisEditorState {
  editMode?: boolean;
  isImage?: boolean;
  privateSetting: ILayoutItem | null; //临时保存组件的参数
  privateComponent: any; //临时保存组件实例
  privateComponentKey: any;
  globalSettingStyle: any; //临时保存画布样式
  sideBarType: EsideBarType; //当前设置类型 analysis分析属性设置 component组件属性设置
  outline: OutlineItem[];
  globalLayout: ILayoutItem[];
  currentOutlineItemId?: number; //大屏当前选中的页面id，默认outline[0].id
  anlysisConfig: IAnalysisConfig | null;
  copyAnalysisData: {
    //备份分析主题的数据，取消编辑操作时还原使用
    anlysisConfig: IAnalysisConfig;
    outline: OutlineItem[];
  } | null;
  filterName: any;
  drillPreviewData: any;
  drillingTrigger: 'headIconClick' | 'chartItemClick'; //下钻触发方式 headIconClick点击头部下钻图图标  chartClick点击图表项
  drillingViewType: 'modal' | 'drawer'; //下钻页面展示方式 对话弹窗/抽屉
}
export const triggerResize = debounce(() => {
  const event = document.createEvent('HTMLEvents');
  event.initEvent('resize', false, true);
  window.dispatchEvent(event);
}, 300);

// 大屏模式默认栅格数量
export const BigScreenGrid = {
  column: 4, //列
  row: 3 //行
};
// 大屏默认布局分块
const gridLayoutTemplate = () => {
  return {
    default: [0, [1, 2, 5, 6], 3, 4, 7, 8, 9, 10, 11]
  };
};
// 大屏优先大视图展示的组件
export const largeViewComponents = [
  EComponent.FA_BU_DI_YU_FEN_BU,
  EComponent.TI_JI_DI_YU_FEN_BU
].map(c => {
  return getBaseConfig(c).type;
});
// 大屏标题组件默认配置
export const TitleComponentDefaultConfig = staticComponents.find(item => {
  return item.id === EComponent.DA_PING_BIAO_TI;
})!;

const getRridIndex = (i: number, template: (number | number[])[]) => {
  const index = template[i];
  return index === undefined
    ? i - template.length + BigScreenGrid.column * BigScreenGrid.row
    : index;
};

export const transSchemeTree = (objArr: any[], flatArr: any[] = []) => {
  let transObj = objArr;
  const transFlatArr = flatArr;
  transObj = objArr.map(item => {
    const rt = {
      label: item.schemeName,
      value: item.schemeId,
      config: item.config,
      disabled: String(item.schemeId).includes('mockId'),
      children: item.children
        ? [...transSchemeTree(item.children, transFlatArr).transObj]
        : null
    };
    transFlatArr.push(rt);
    return rt;
  });
  return { transObj, transFlatArr };
};

//扁平化的主题选项
export const flatSchemeTreeOption = () => {
  const { transFlatArr } = transSchemeTree(useSearchSchemeStore().schemeList);
  return [...transFlatArr];
};

// 获取当前搜索主题的dataSourceInfos
export const getCurrentSchemeConfig = (id: number) => {
  const scheme =
    flatSchemeTreeOption().find(item => {
      return item.value === id;
    }) || {};
  const {
    schemeName,
    dataSourceInfos,
    realTime,
    startPubTime,
    endPubTime,
    searchKeywordType,
    keywordExp,
    mustKeyWord,
    anyKeyWord,
    needExclude
  } = scheme.config || {};
  return {
    ...scheme.config,
    schemeName: schemeName || scheme.label,
    dataSourceInfos: dataSourceInfos || [],
    realTime,
    startPubTime: startPubTime || '',
    endPubTime: endPubTime || '',
    searchKeywordType,
    keywordExp,
    mustKeyWord,
    anyKeyWord,
    needExclude
  };
};

// 组件随机I
export const getRandomId = (data: { id: number; type: string }) => {
  return `${data.id}-${data.type}-${new Date().getTime()}`;
};

export const useEditorStore = defineStore('editor', {
  state: (): AnlysisEditorState => ({
    editMode: false,
    isImage: false,
    privateSetting: null,
    privateComponent: null,
    privateComponentKey: '',
    globalSettingStyle: null,
    sideBarType: EsideBarType.library,
    outline: [],
    globalLayout: [],
    currentOutlineItemId: 0,
    anlysisConfig: null,
    copyAnalysisData: null,
    filterName: '',
    drillPreviewData: {},
    drillingTrigger: 'chartItemClick',
    drillingViewType: 'drawer'
  }),
  actions: {
    setImageChart(flg: boolean) {
      if (this.isImage === flg) return;
      this.isImage = flg;
    },
    // 开关编辑模式
    setEditMode(flg: boolean) {
      this.changeSideBarType(EsideBarType.library);
      this.editMode = flg;
      this.resize();
      this.setFilterName('');
    },
    setFilterName(data: any) {
      this.filterName = data;
    },
    // 切换右边栏类型
    changeSideBarType(type: EsideBarType) {
      this.sideBarType = type || EsideBarType.library;
    },
    updatePrivateComponentKey() {
      this.privateComponentKey = new Date().getTime().toString();
    },
    // 点击组件设置，临时保存组件的参数
    setPrivateSetting(data: ILayoutItem | null, comRef: any) {
      this.privateComponent = deepClone({ data: comRef.data });
      data && (this.privateSetting = data);
      this.changeSideBarType(EsideBarType.privateSetting);
      this.updatePrivateComponentKey();
    },
    // 重置组件设置参数为默认
    resetPrivateSetting(data: ILayoutItem) {
      const schemeId = this.anlysisConfig?.sid!;
      const { realTime, startPubTime, endPubTime } =
        getCurrentSchemeConfig(schemeId); //搜索主题关联的配置参数
      const { data: defaultParams } = getBaseConfig(data.componentConfig.id); //分析组件默认配置参数
      const configParams = { schemeId, realTime, startPubTime, endPubTime };
      const allDefaultParams = this.initApiParams({
        ...configParams,
        ...defaultParams
      });
      this.privateSetting!.componentConfig.data = allDefaultParams;
    },
    // 初始化默认参数
    initApiParams(params: IPrivateSettingParams) {
      if (!params.schemeId) {
        // 通过模板渲染的，给定默认数据源
        params.schemeId = this.anlysisConfig?.sid!;
      }
      if (params.schemeIdList && !params.schemeIdList.length) {
        params.schemeIdList = [this.anlysisConfig?.sid!];
      }
      if (!params.dataSourceUpdated) {
        const datasourceInfo = deepClone(
          this.anlysisConfig?.datasourceInfo || []
        );
        const mediaType = getChannelIds(datasourceInfo, 'value');
        const dataSource = getfilterList(datasourceInfo, mediaType, 'value');
        params.dataSource = dataSource;
      }
      if (params.realTime == undefined) {
        params.realTime =
          getCurrentSchemeConfig(params.schemeId).realTime ||
          this.anlysisConfig?.realTime;
        params.startPubTime =
          getCurrentSchemeConfig(params.schemeId).startPubTime ||
          this.anlysisConfig?.startTime;
        params.endPubTime =
          getCurrentSchemeConfig(params.schemeId).endPubTime ||
          this.anlysisConfig?.endTime;
      }
      if (!params.articleType) {
        params.articleType = this.anlysisConfig?.articleType || [];
      }
      return params;
    },
    // 保存画布数据
    setAnalysisData(data: {
      anlysisConfig: IAnalysisConfig;
      outline: OutlineItem[];
      globalLayout?: ILayoutItem[];
    }) {
      this.anlysisConfig = data.anlysisConfig;
      !this.anlysisConfig.style &&
        (this.anlysisConfig.style = defaultAnalysisStyle); //设置默认样式
      const outline = data.outline;
      outline.forEach(item => {
        item.layout.forEach(c => {
          this.initApiParams(c.componentConfig.data);
        });
      });
      this.outline = outline;
      this.globalLayout = data.globalLayout || [];
      this.copyAnalysisData = deepClone(data);
      this.globalSettingStyle = deepClone(this.anlysisConfig?.style);
      if (data?.anlysisConfig?.bigScreenMode) {
        this.changeCurrentBigscreenPage(outline[0].id); //设置大屏默认展示页面索引
        //大屏模式默认编辑状态
        this.setEditMode(true);
      }
    },
    // 切换大屏页面
    changeCurrentBigscreenPage(id: number) {
      if (!id) return;
      this.currentOutlineItemId = id;
    },
    //保存后更新备份的分析主题数据
    updateCopyAnalysisData() {
      this.anlysisConfig?.bigScreenMode &&
        (this.anlysisConfig!.extendedConfig.saved = true); //
      this.copyAnalysisData = {
        anlysisConfig: deepClone(this.anlysisConfig),
        outline: deepClone(this.outline)
      };
      this.globalSettingStyle = deepClone(this.anlysisConfig?.style);
    },
    // 画布拖拽、调整大小等后同步更新outline
    updateLayout(data: { id: number; layout: ILayoutItem[] }) {
      const index = this.outline.findIndex(item => {
        return item.id === data.id;
      });
      this.outline[index].layout = data.layout;
    },
    // 组件设置点击确认后更新对应组件的参数
    updateLayoutPrivateParams(data: ILayoutItem, dataConfig: any) {
      if (data.componentConfig.bigscreenConfig?.isGlobal) {
        // 更新大屏全局组件
        const targetC = this.globalLayout.find(c => c.i === data.i);
        if (!targetC) return;
        Object.assign(
          targetC.componentConfig.data,
          data.componentConfig.data,
          dataConfig
        );
      } else {
        this.outline.forEach(item => {
          item.layout.forEach(c => {
            if (c.i === data.i) {
              Object.assign(
                c.componentConfig.data,
                data.componentConfig.data,
                dataConfig
              );
            }
          });
        });
      }
    },
    // 将分析outline转为大屏ouline
    setBigScreenOutline() {
      const baseConfig = getBaseConfig(EComponent.DA_PING_BIAO_TI);
      const { data } = baseConfig;
      data.name = this.anlysisConfig?.name.slice(0, 20)!;
      const titleConfig: ILayoutItem = {
        x: 0,
        y: 0,
        w:
          TitleComponentDefaultConfig?.bigscreenConfig?.w! *
          this.anlysisConfig?.screenWidth!,
        h: TitleComponentDefaultConfig?.bigscreenConfig?.h!,
        i: getRandomId(baseConfig),
        componentConfig: {
          name: TitleComponentDefaultConfig.name,
          ...baseConfig
        }
      };
      const defaultWidth =
        this.anlysisConfig?.screenWidth! / BigScreenGrid.column; //默认宽度
      const defaultHeight =
        (this.anlysisConfig?.screenHeight! - titleConfig.h) / BigScreenGrid.row; //默认高度
      const restItems = [] as ILayoutItem[];
      this.outline.forEach((item, i) => {
        i > 0 && restItems.push(...item.layout);
      });
      this.outline.splice(1);
      this.outline[0].layout.push(...[...restItems, titleConfig]);
      const template = gridLayoutTemplate().default;
      const largeComIndex = this.outline[0].layout.findIndex(item => {
        return largeViewComponents.includes(item.componentConfig.type);
      });
      if (largeComIndex > -1) {
        // 找出第一个需要大视图展示的组件,放到模板索引位置,目前只有一个大视图索引1
        const c = deepClone(this.outline[0].layout[largeComIndex]);
        this.outline[0].layout.splice(largeComIndex, 1);
        this.outline[0].layout.splice(1, 0, c);
      }
      this.outline[0].layout.forEach((item, i) => {
        if (item.componentConfig.id === EComponent.DA_PING_BIAO_TI) return;
        const index = getRridIndex(i, template);
        if (Array.isArray(index)) {
          item.x = (index[0] % BigScreenGrid.column) * defaultWidth;
          item.y =
            Math.floor(index[0] / BigScreenGrid.column) * defaultHeight +
            titleConfig.h;
          item.w =
            defaultWidth *
            (((index[index.length - 1] - index[0]) % BigScreenGrid.column) + 1);
          item.h =
            defaultHeight *
            (Math.floor(index[index.length - 1] / BigScreenGrid.column) -
              Math.floor(index[0] / BigScreenGrid.column) +
              1);
        } else {
          item.x = (index % BigScreenGrid.column) * defaultWidth;
          item.y =
            Math.floor(index / BigScreenGrid.column) * defaultHeight +
            titleConfig.h;
          item.w = defaultWidth;
          item.h = defaultHeight;
          if (i > template.length - 1) {
            // 超出屏幕的留出与上部的间隙
            item.y +=
              (Math.floor(
                (index - BigScreenGrid.column * BigScreenGrid.row) /
                  BigScreenGrid.column
              ) +
                1) *
              30;
          }
        }
      });
    },
    // 转为大屏
    convertToBigScreen() {
      const anlysisConfig = deepClone(this.anlysisConfig) as IAnalysisConfig;
      anlysisConfig.style.componentTitleColor = '#BDDBFF';
      anlysisConfig.style.componentTitleFontSize = '24px';
      const anlysisConfigData = { ...anlysisConfig, ...defaultBigScreenConfig };
      const outline = deepClone(this.outline);
      this.setAnalysisData({ anlysisConfig: anlysisConfigData, outline });
      this.setBigScreenOutline();
    },
    // 设置大屏尺寸
    setScreenSize(data: { width: number; height: number }) {
      this.anlysisConfig!.screenWidth = data.width;
      this.anlysisConfig!.screenHeight = data.height;
    },
    // 全局设置点击确认后更新每个组件的参数
    updateGlobalParams(data: {
      schemeId?: number;
      dataSource?: any[];
      realTime?: realTime;
      startPubTime?: string;
      endPubTime?: string;
      style?: any;
    }) {
      const dataObj = {} as any;
      if (data.schemeId) {
        // 数据源（数据源和媒体渠道联动，所以需要同步修改每个组件的dataSource）
        this.anlysisConfig!['sid'] = data.schemeId;
        // 更新每个组件的参数的schemeId和dataSource
        dataObj['schemeId'] = data.schemeId;
        dataObj['dataSource'] = data.dataSource;
      }
      if (data.realTime !== undefined) {
        const startTime = data.startPubTime || '',
          endTime = data.endPubTime || '';
        this.anlysisConfig!['realTime'] = data.realTime!;
        this.anlysisConfig!['startTime'] = startTime;
        this.anlysisConfig!['endTime'] = endTime;
        dataObj['realTime'] = data.realTime;
        dataObj['startPubTime'] = startTime;
        dataObj['endPubTime'] = endTime;
      }
      this.outline.forEach(item => {
        item.layout.forEach(c => {
          for (const key in dataObj) {
            dataObj[key] !== undefined &&
              (c.componentConfig.data[key] = dataObj[key]);
          }
        });
      });
      this.globalLayout?.forEach(c => {
        for (const key in dataObj) {
          dataObj[key] !== undefined &&
            (c.componentConfig.data[key] = dataObj[key]);
        }
      });
      if (data.style) {
        for (const key in data.style) {
          this.anlysisConfig!.style[key] =
            data.style[key] || defaultAnalysisStyle[key];
        }
      }
    },
    resize() {
      triggerResize();
    }
  }
});

export interface AnalysisListItem {
  id: number;
  name: string;
  templateName: string;
  totalInfo: number;
  totalMedia: number;
  peakValue: number;
  sid: number;
  templateId: number;
  startTime: string;
  endTime: string;
  insertTime: string;
  updateTime: string;
  realTime: realTime;
  bigScreenMode?: boolean;
  schemeStatus: 0 | 1;
}

export interface AnalysisListState {
  analysisList: AnalysisListItem[];
  count: number;
  loading: boolean;
}
export const useAnalysisListStore = defineStore('analysis', {
  state: (): AnalysisListState => ({
    analysisList: [],
    count: 0,
    loading: false
  }),
  actions: {
    async getAnalysisList(pm: object) {
      try {
        this.loading = true;
        const res = await useAnalysisApi().getAnalysisTopicList(pm);
        if (res.code === 200) {
          this.analysisList = res.data?.analysisTopicVOList || [];
          this.analysisList.forEach(item => {
            if (item.realTime !== 0) {
              item.startTime = transRealTime(item.realTime)[0];
              item.endTime = transRealTime(item.realTime)[1];
            }
          });
          this.count = res.data?.count || 0;
        }
      } catch (error) {}
      this.loading = false;
    }
  }
});

export const useAnalysisTemplateListStore = defineStore('analysisTemplat', {
  state: () => ({
    // 推荐模板列表
    recommendTemplateList: [],
    // 用户模板列表
    userTemplateList: []
  }),
  actions: {
    async getAnalysisTemplateList() {
      try {
        const res = await useAnalysisApi().getAnalysisTemplateList();
        if (res.code === 200) {
          this.recommendTemplateList = res.data?.recommendTemplateList || [];
          this.userTemplateList = res.data?.userTemplateList || [];
        }
      } catch (error) {}
    }
  }
});

// 大屏模板
export const useBigscreenTemplateListStore = defineStore('bigscreenTemplate', {
  state: () => ({
    // 推荐模板列表
    recommendTemplateList: [],
    // 用户模板列表
    userTemplateList: []
  }),
  actions: {
    async getBigScreenTemplateList() {
      try {
        const res = await useAnalysisApi().getBigScreenTemplateList();
        if (res.code === 200) {
          this.recommendTemplateList = res.data?.recommendTemplateList || [];
          this.userTemplateList = res.data?.userTemplateList || [];
        }
      } catch (error) {}
    }
  }
});
