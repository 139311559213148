<script lang="ts" setup>
import { ref } from 'vue';
import type { TimelineDataItem } from './types';
import { removeHTML } from '/@/utils/other';

const link = ref(true);
const getMonthDay = (date: string) => {
  // YYYY-MM-DD HH:mm:ss
  return date.slice(5, 10);
};
const getHourMinute = (date: string) => {
  return date.slice(11, 16);
};
defineProps<{ data: TimelineDataItem; theme: string; titleRows?: number }>();
</script>
<template>
  <div
    :class="[
      'timeline--item',
      { 'timeline--item--theme2': theme == 'bigscreen-theme-2' }
    ]"
  >
    <div class="timestamp">
      <div class="day">{{ getMonthDay(data.pubtime) }}</div>
      <div class="minute">{{ getHourMinute(data.pubtime) }}</div>
    </div>
    <div class="main--wrapper">
      <div class="timeline--container">
        <slot name="pic"></slot>
        <div class="content--wrapper">
          <div class="content" :class="[titleRows && `title-row-${titleRows}`]">
            <a :href="data.url" target="_blank" v-if="link" class="link">
              <c-icon name="lianjie" size="20"></c-icon>
              {{ $t('message.analysisOther.viewTitle') }}
            </a>
            <span
              class="title"
              :title="removeHTML(data.title)"
              v-html="data.title"
            ></span>
          </div>
          <div class="bottom-bar">
            <slot name="bottom-bar"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '../style/colors' as c;

.timeline--item {
  display: flex;
  font-size: 14px;

  &:last-child {
    .main--wrapper {
      padding-bottom: 0;
    }
  }

  .timestamp {
    margin-right: 17px;
    text-align: center;
    white-space: nowrap;

    .day {
      font-size: 14px;
      color: c.$text-black-0;
      line-height: 17px;
    }

    .minute {
      font-size: 12px;
      color: c.$text-gray-0;
      line-height: 15px;
    }
  }

  .main--wrapper {
    position: relative;
    padding-left: 17px;
    padding-bottom: 21px;
    border-left: 1px dashed c.$blue-2;
    width: calc(100% - 54px);

    &:before {
      content: '';
      position: absolute;
      left: -9px;
      top: 10px;
      width: 12px;
      height: 12px;
      border: 3px solid rgba(c.$blue-2, 0.2);
      background: c.$blue-2;
      border-radius: 50%;
      background-clip: padding-box;
    }

    .timeline--container {
      position: relative;
      display: flex;
      padding: 12px 14px;
      border-radius: 4px;
      gap: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      .pic {
        width: 40px;
        height: 40px;
      }

      .content--wrapper {
        width: 85%;

        .content {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          &.title-row-1 {
            -webkit-line-clamp: 1;
          }
          &.title-row-2 {
            -webkit-line-clamp: 2;
          }

          .link {
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            margin-right: 5px;
            text-decoration: none;
            color: c.$text-black-0;
            vertical-align: middle;

            &:hover {
              color: c.$blue-1;
            }
          }
        }

        .bottom-bar {
          margin-top: 7px;
          line-height: 1;
          color: c.$text-gray-0;
          white-space: normal;
        }
      }
    }
  }
  &.timeline--item--theme2 {
    .main--wrapper .timeline--container .content--wrapper .content .link {
      color: #21f2ff;
    }
    .main--wrapper .timeline--container .content--wrapper .content {
      color: #c4d9f3;
    }
    .timestamp {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .timestamp .day {
      color: #c4d9f3;
    }
    .timestamp .minute {
      color: rgba(189, 219, 255, 0.6);
    }
    .main--wrapper {
      border-left-color: rgba(255, 255, 255, 0.12);
      padding-bottom: 0;
    }
    .main--wrapper:before {
      top: 50%;
      transform: translateY(-50%);
      width: 22px;
      height: 22px;
      border: none;
      left: -11px;
      background: url(./images/timeline_deco.png) no-repeat center/cover;
    }
  }
}
</style>
