<template>
  <div class="no-data">
    <c-icon :name="icon" :size="String(iconSize)" :color="iconColor" />
    <div class="tip" :style="'color:' + tipColor">{{ tip }}</div>
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
interface INodata {
  icon: string;
  iconSize: string | number;
  iconColor: string;
  tip: string;
  tipColor?: string;
}
export default defineComponent({
  props: {
    icon: {
      type: String,
      default: 'nodata'
    },
    iconSize: {
      type: String || Number,
      default: '100'
    },
    iconColor: {
      type: String,
      default: 'var(--primary-6)'
    },
    tip: {
      type: String,
      default: '暂无数据'
    },
    tipColor: {
      type: String,
      default: '#99a3b7'
    }
  }
});
</script>
<style scoped lang="scss">
.no-data {
  width: 100%;
  text-align: center;
  // background: #fff url('/src/assets/monitor/bj.png') no-repeat right top;
  background-size: 110%;
  .tip {
    margin-bottom: 16px;
  }
}
</style>
