import { ArticleList, ResponseData } from '../report/interface';
import type { ChangeParamsType, CollectParamsType } from './interface';
import type {
  DetailParamsType,
  ListItemType,
  ListParamsType
} from '/@/components/articleList/interface';
import request, { del, get, post } from '/@/utils/request';
// 搜索结果列表
export const getSearchList = (
  params: Partial<ListParamsType>,
  cancelToken?: any
) => post<ResponseData>(`/search/search/getSearchList`, params, cancelToken);
//消息详情
export const getSearchDetail = (params: Partial<DetailParamsType>) =>
  post<ResponseData>(`/search/search/getArticleDetail`, params);
//收藏列表
export const getCollectList = () =>
  get<ResponseData>(`/search/userCollectionType/search`);
//新增收藏标签
export const insertCollect = (params: { name: string }) =>
  post<ResponseData>(`/search/userCollectionType/insert`, params);
//收藏
export const goCollect = (params: CollectParamsType) =>
  post<ResponseData>(`/search/userCollection/insert`, params);
//取消收藏
export const cancelCollect = (params: { uuids: string[] }) =>
  del<ResponseData>(`/search/userCollection/delete`, params);
//用户收藏列表
export const getCollectionList = (params: Partial<ListParamsType>) =>
  post<ResponseData>(`/search/userCollection/getCollectionList`, params);
export const listMethod = () => {
  return {
    // 搜索结果列表
    list: (params: Partial<ListParamsType>) =>
      post<ResponseData>(`/search/search/getSearchList`, params),
    analysisConfig: (params: Partial<ListParamsType>) =>
      post<ResponseData>(`/search/search/getSearchList`, params),
    //用户收藏列表
    collect: (params: Partial<ListParamsType>) =>
      post<ResponseData>(`/search/userCollection/getCollectionList`, params),
    //分析组件搜索列表
    analysis: (params: Partial<ListParamsType>) =>
      post<ResponseData>(`/search/search/getAnalysisSearchList`, params)
  };
};
//变更收藏标签
export const changeCollectTag = (params: ChangeParamsType) =>
  post<ResponseData>(`/search/userCollection/changeType`, params);

// 搜索结果列表
export const getArticleList = (params: Partial<ListParamsType>) =>
  post<ResponseData<ArticleList>>(`/search/search/getSearchList`, params);

// 获取复制模板
export const getCopyTemplate = (pm: object) =>
  post<ResponseData>(`/search/searchScheme/getCopyTemplate`, pm);

// 保存复制模板
export const saveCopyTemplate = (pm: object) =>
  post<ResponseData>(`/search/searchScheme/saveCopyTemplate`, pm);

// 获取可播放视频链接
export const getVideoPlayUrl = (pm: object) =>
  post<ResponseData>(`/video-py/playurl`, pm);

// 搜索获取总条数
export const getTotalNumber = (pm: Partial<ListParamsType>) =>
  post<ResponseData>(`/search/search/getSearchListTotalCount`, pm);
