import { defineStore } from 'pinia';

export const useSearchAnalyse = defineStore('searchAnalyse', {
  state: () => ({
    searchAnalyseParams: {},
    searchAnalyseShow: false,
    chartsClickParams: {},
    noStackResultData: [],
    sAnalysechannelIds: [],
    dataAuthType: 1,
    designatedMediaLabelArr: [],
    searchTime: {},
    isChartSearch: false,
    copyTemplate: {},
    searchDetail: {}
  }),
  actions: {}
});
