<template>
  <div class="config-switch">
    <w-form-item row-class="config-switch-inner" :label="label">
      <w-switch v-model="value" />
    </w-form-item>
    <slot name="content" />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  name: 'ConfigSwitch',
  props: {
    label: {
      type: String,
      default: 'Switch'
    },
    modalValue: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const value = ref(props.modalValue);
    watch(value, val => {
      emit('update:modalValue', val);
    });
    return { value };
  }
});
</script>
<style scoped lang="scss">
.config-switch {
  margin-bottom: 22px;
  .config-switch-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    :deep(.w-form-item-label-col) {
      margin-bottom: 0;
    }
    :deep(.w-form-item-wrapper-col) {
      width: 50px;
      .w-switch {
        width: 100%;
      }
    }
  }
}
</style>
