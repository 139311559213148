import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import ChannelVolume from './src/index.vue';

ChannelVolume.install = (app: App): void => {
  app.component('AChannelVolume', ChannelVolume);
  app.component(
    'AChannelVolumeProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default ChannelVolume;
