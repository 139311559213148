import { reactive, ref } from 'vue';
import { dayjs, ElMessage } from 'element-plus';
import useKeywords from './keywords';
import { useFilter } from './useFilter';
import { Local } from '/@/utils/storage';
import { formatDate } from '/@/utils/formatTime';
import { filterFormType, filterFormItemType, previewListItem } from '../types';
import { storeToRefs } from 'pinia';
import { useDictStore } from '/@/stores/dict';
import { useSearchAnalyse } from '/@/stores/searchAnalyse';
import { i18n } from '/@/i18n/index';
import { cloneDeep } from 'lodash-es';
import { getUserInfoPermissionItem } from '/@/utils/other';
const t = i18n.global.t;
const getUserPermItem = (key: any) => {
  const userPerms = JSON.parse(Local.get('userPermission')) || {
    sourcesNum: 100,
    schemeNum: 50,
    dataAuth: 1,
    keywordNum: 500,
    schemeDataNum: 100000
  };
  return userPerms[key];
};
const userDataAuth = ref<number>(1);
const searchMenuVisible = ref<boolean>(true);
const {
  timeList,
  matchList,
  emotion,
  accountAuthList,
  fansList,
  dataType,
  mediaType,
  queryTypeList,
  warehouseTime,
  sitePaidList
} = useFilter();
const {
  checkKeywordsNum,
  searchKeywordType,
  keywords,
  keywordsLimitNum,
  keywordsCurrentNum,
  searchTransLateLanguages,
  keywordsStrTotalLength
} = useKeywords();
const getDay = (day: number) => {
  return Date.now() + 1000 * 60 * 60 * 24 * day;
};
const filterFormItems = reactive<Array<filterFormItemType>>([
  {
    title: t('message.searchForm.pubtime'), //发布时间
    formKey: 'rangeValue',
    // type: 'datarange',
    type: 'datarangeTag',
    realTime: '1',
    default: '1',
    // showOut: true, //
    option: timeList,
    selectWidth: Local.get('globalI18n') == 'en' ? '155px' : '120px'
  },
  {
    title: t('message.searchForm.pubtime'), //发布时间-外部
    formKey: 'rangeValue',
    type: 'datarange',
    // type:'datarangeTag',
    realTime: '1',
    default: '1',
    showOut: true, //展示在外部
    option: timeList,
    selectWidth: Local.get('globalI18n') == 'en' ? '155px' : '120px'
  },
  {
    title: t('message.searchForm.warehousetime'), //入库时间
    formKey: 'esRangeValue',
    // type: 'datarange',
    type: 'warehousetime',
    esRealTime: '99',
    default: '99',
    option: warehouseTime,
    selectWidth: Local.get('globalI18n') == 'en' ? '155px' : '120px'
  },
  {
    title: t('message.searchForm.matchMethods'), // 命中位置
    formKey: 'matchMethods',
    type: 'radio',
    default: '',
    // showOut: true,
    option: matchList
  },
  {
    title: t('message.searchForm.matchMethods'), // 命中位置-外部
    formKey: 'matchMethods',
    type: 'select',
    default: '',
    showOut: true, //展示在外部
    option: matchList
  },
  {
    title: t('message.searchForm.polarity'), // 情感倾向
    formKey: 'polarity',
    type: 'checkbox',
    default: [],
    anchorId: 'emotion',
    option: emotion
  },
  {
    title: t('message.searchForm.languages'), // 信息语言
    formKey: 'languages',
    type: 'checkbox',
    default: [],
    anchorId: 'languages',
    option: []
  },
  {
    title: t('message.searchForm.mediaType'), //媒体渠道
    formKey: 'mediaType',
    // type: 'cascader', //下拉多选
    type: 'checkbox',
    // showOut: true,
    default: [],
    option: JSON.parse(JSON.stringify(mediaType.value))
  },
  {
    title: t('message.searchForm.mediaType'), //媒体渠道-外部
    formKey: 'mediaType',
    type: 'cascader', //下拉多选
    // type: 'checkbox',
    showOut: true, //展示在外部
    default: [],
    option: JSON.parse(JSON.stringify(mediaType.value))
  },
  {
    title: '媒体地域',
    formKey: 'mediaRegionTag',
    type: 'cascaderTag',
    anchorId: 'mediaRegionTag',
    default: [],
    option: [],
    optionProps: {
      label: 'label',
      value: 'value'
    }
  },
  {
    title: '媒体等级',
    formKey: 'mediaGradeTag',
    type: 'checkbox',
    anchorId: 'mediaGradeTag',
    default: [],
    option: [],
    optionProps: {
      label: 'label',
      value: 'id'
    },
    checkAllLabel: '不限',
    tooltips: {
      '879-905': t('message.searchForm.mediaLevelTips[0]'),
      '879-906': t('message.searchForm.mediaLevelTips[1]'),
      '879-907': t('message.searchForm.mediaLevelTips[2]'),
      '879-908': t('message.searchForm.mediaLevelTips[3]')
    }
  },
  {
    title: '机构类型',
    formKey: 'institutionTag',
    type: 'cascaderTag',
    anchorId: 'institutionTag',
    default: [],
    option: [],
    optionProps: {
      label: 'label',
      value: 'value'
    }
  },
  {
    title: t('message.searchForm.specifiedMedia'), // 指定媒体
    formKey: 'mediaInfos',
    default: 0,
    option: [],
    anchorId: 'mediaInfos'
  },
  {
    title: t('message.searchForm.mediaTags'), // 媒体标签
    formKey: 'mediaTags',
    type: 'checkbox',
    default: [],
    anchorId: 'mediaTags',
    option: [],
    moreoption: []
  },
  {
    title: t('message.searchForm.areaIds'), //地域识别
    formKey: 'areaIds',
    default: [],
    type: 'cascader',
    anchorId: 'areaIds',
    option: [],
    showAreaType: true,
    formOtherKey: 'areaType',
    checkStrictly: true
  },
  {
    title: t('message.searchForm.articleType'), //数据类型
    formKey: 'articleType',
    type: 'checkbox',
    anchorId: 'articleType',
    default: [],
    option: dataType,
    showOut: true
  },
  {
    title: t('message.searchForm.authTypes'), //账号认证
    formKey: 'authType',
    type: 'checkbox',
    default: '',
    anchorId: 'authType',
    option: accountAuthList,
    checkAllLabel: '不限'
  },
  {
    title: '个体账号',
    formKey: 'accountTag',
    type: 'checkbox',
    anchorId: 'accountTag',
    default: [],
    option: [],
    optionProps: {
      label: 'label',
      value: 'id'
    },
    checkAllLabel: '不限'
  },
  {
    title: t('message.searchForm.fansCount'), //粉丝数量
    formKey: 'fansCount',
    type: 'radio',
    default: '',
    anchorId: 'fansCount',
    option: fansList
  },
  // {
  // 	title: '信息分类', //信息分类--0.7版本无法进行权限控制，先隐藏该筛选项
  // 	formKey: 'mediaGradeTags',
  // 	default: [],
  // 	type: 'cascader',
  // 	anchorId: 'mediaGradeTags',
  // 	option: [],
  // 	showAreaType: false,
  // 	formOtherKey: 'areaType',
  // 	checkStrictly: true,
  // },
  {
    title: '信息分类',
    formKey: 'levelTags',
    type: 'cascaderTag',
    anchorId: 'levelTags',
    default: [],
    option: [],
    optionProps: {
      label: 'label',
      value: 'value'
    }
  },
  {
    title: t('message.searchForm.standardFuzzyLabel'), //标准/模糊
    formKey: 'queryType',
    type: 'radio',
    anchorId: 'queryType',
    default: '0',
    option: queryTypeList
  },
  {
    title: t('message.searchForm.zhandianfanwei'), // 站点范围
    formKey: 'sitePaid',
    type: 'radio',
    anchorId: 'sitePaid',
    default: -1,
    option: sitePaidList
  }
]);
const colectForm = reactive({
  searchTime: {
    endPubTime: '',
    now: '' as string | number,
    realTime: 1,
    startPubTime: ''
  },
  collectionTypeId: '',
  sortInfo: { sortField: 1, sortWay: 'desc' }
});
const schemeIsChanged = ref(false); //标记用户有没有对当前主题进行修改动作，用于提示用户保存
const setSchemeStatus = (changed = true) => {
  schemeIsChanged.value = changed;
};
const addNewSchemeClickShowTip = ref(false); // 标记用户是否重复点击新增主题按钮，用于提示
const borderAnimation = ref(false); // 新增主题点击时，清空输入框，输入框边框是否闪烁
export const defaultFilterFormParams = () => {
  return {
    // 二次检索默认参数
    isCollapse: 0, //是否去重
    displayOption: [1, 2], //展示选项
    sortInfo: { sortField: 12, sortWay: 'desc' }, //排序
    secondSearch: { matchMethod: 7, queryType: true, value: '' } //二次检索
  };
};
const filterForm = reactive<filterFormType>({
  isSimpleComplex: getUserInfoPermissionItem('search:isSimpleComplex:checked')
    ? 1
    : 0,
  startPubTime: '',
  endPubTime: '',
  realTime: 7,
  esRealTime: 99,
  esRangeValue: [
    formatDate(new Date(getDay(-1)), 'YYYY-mm-dd HH:MM:SS'),
    formatDate(new Date(Date.now()), 'YYYY-mm-dd HH:MM:SS')
  ],
  rangeValue: [
    formatDate(new Date(getDay(-1)), 'YYYY-mm-dd HH:MM:SS'),
    formatDate(new Date(Date.now()), 'YYYY-mm-dd HH:MM:SS')
  ],
  matchMethods: '',
  polarity: [],
  authType: [],
  fansCount: {
    fromFan: '',
    toFan: '',
    type: '0'
  },
  /* 媒体渠道 */
  mediaType: [],
  /* 信息语言 */
  languages: [],
  //媒体标签
  mediaTags: [],
  //IP属地
  areaIds: [],
  //信息分类
  mediaGradeTags: [],
  articleType: [],
  region: ['1', '2'],
  areaType: ['1', '2'],
  isForeignMedia: getUserPermItem('dataAuth'),
  queryType: '0',
  mediaInfos: [],
  include: 0,
  isCollapse: defaultFilterFormParams().isCollapse,
  displayOption: defaultFilterFormParams().displayOption,
  sortInfo: defaultFilterFormParams().sortInfo,
  secondSearch: defaultFilterFormParams().secondSearch,
  institutionTag: [], //机构类型标签
  mediaGradeTag: [], //媒体等级标签
  mediaRegionTag: [], //媒体地域标签
  accountTag: [], //个体账号标签
  levelTags: [], //信息分类标签（项目标签）
  translateLanguage: ['english'], // 关键词翻译语种
  isTranslateLanguage: true, // 是否使用了翻译
  subjects: [], //主体
  sentiments: [], //主题情感类型
  subjectSwitch: 0, // 主体是否启用
  sitePaid: -1 // 站点范围
});

const resetSecondSearchParams = () => {
  Object.assign(filterForm, defaultFilterFormParams());
};

//更多筛选回显
// const tagText = ref<string>(t('message.searchResult.moreFilters')); //更多筛选
const tagText = ref<string>(''); //更多筛选
//时间框
const dateStatus = ref<boolean>(false);
//更多筛选icon
const moreIcon = ref<boolean>(true);

const isValidKey = (
  key: string | number | symbol | undefined,
  object: object
): key is keyof typeof object => {
  return (key as string) in object;
};
//级联自定义
const format = (value: Array<{ label: string }>) => {
  const labels = value.map(option => option.label);
  return labels.slice(-1);
};
// 转换字段
const changeObjaryKey = (objAry: Array<any>, key: string, newkey: string) => {
  if (objAry) {
    for (let i = 0, len = objAry.length; i < len; i++) {
      Object.assign(objAry[i], {
        [newkey]: objAry[i][key]
      });
      delete objAry[i][key];
      if (objAry[i].children?.length) {
        changeObjaryKey(objAry[i].children, key, newkey);
      } else {
        delete objAry[i].children;
      }
    }
  }
};
// 便于合并媒体渠道count字段
const arrMerge = (
  arrOne: Array<any>,
  arrTwo: Array<any>,
  key: string | number
) => {
  const combined = arrOne.map((itemOne: any) => {
    arrTwo.map(itemTwo => {
      if (
        itemOne[key].includes(itemTwo[key]) &&
        itemOne.label == itemTwo.label
      ) {
        if (itemOne.children?.length && itemTwo.children?.length) {
          const childOne = itemOne.children,
            childTwo = itemTwo.children;
          Object.assign(itemOne, itemTwo);
          itemOne.children = arrMerge(childOne, childTwo, key);
        } else {
          Object.assign(itemOne, itemTwo);
        }
      }
    });
    return itemOne;
  });
  return combined;
};
// 更新媒体渠道数据量
const updateMediaTypeCount = (data: any) => {
  const mediaTypeList = filterFormItems.find(item => {
    return item.formKey === 'mediaType';
  })?.option;
  const mediaTypeLists = JSON.parse(JSON.stringify(mediaType.value));
  const arrList = arrMerge(mediaTypeLists, data, 'value');
  // arrList.forEach((item: any) => {
  // 	item.label = item.count || item.count == 0 ? item.label + '（' + item.count + '）' : item.label;
  // 	item.children &&
  // 		item.children.forEach((a: any) => {
  // 			a.label = a.count || a.count == 0 ? a.label + '（' + a.count + '）' : a.label;
  // 		});
  // });
  mediaTypeList?.splice(0, mediaTypeList.length);
  mediaTypeList?.push(...arrList);
};

/**
 * 获取媒体渠道dataSource的传参
 * @param list 原始数组
 * @param values 选中的value值的集合
 * @param key 选中值对应的字段
 * @returns
 */
const getfilterList = (list: any, values: string[], key: string) => {
  const arr = list?.filter((item: any) => {
    if (item.children?.length) {
      const arrC = getfilterList(item.children, values, key);
      if (arrC?.length) {
        item.children = arrC;
        return true;
      }
    } else {
      // if (values.includes(item[key])) {
      //     return true;
      // }
      return values.find(code => {
        return item[key].includes(code) || code.includes(item[key]);
      });
    }
  });
  return arr;
};

/**
 * 获取媒体渠道回显值
 * @param list 对应返回的数组
 * @param key 选中值对应的字段
 * @returns channelIds
 */
const getChannelIds = (list: any, key: string) => {
  const channelIds = [] as any;
  list?.forEach((item: any) => {
    if (item.children && item.children.length) {
      channelIds.push(...getChannelIds(item.children, key));
    } else {
      channelIds.push(item[key]);
    }
  });
  return channelIds;
};
//媒体属地参数拼接
const concatMediaAreas = (originList: any, selectList: any) => {
  const result = [] as any;
  for (let i = 0, len = originList.length; i < len; i++) {
    const item = originList[i];
    if (selectList.includes(item.id) && item.parentId == 0) {
      result.push({
        mediaAreaProvince: item.id
      });
    } else if (selectList.includes(item.id) && item.parentId !== 0) {
      if (item.id.substr(4, 2) === '00') {
        result.push({
          mediaAreaCity: item.id
        });
      } else {
        result.push({
          mediaAreaCity1: item.id
        });
      }
    }
    if (item.children?.length) {
      result.push(...concatMediaAreas(item.children, selectList));
    }
  }
  return result;
};
// 重置
const handleReSet = (resetAll?: number) => {
  resetShowEliminatedInfos();
  const hashPage = window.location.hash;
  resetSecondSearchParams();
  //resetAll : 1 删除关键词，默认不删除关键词; 2 境内外切换 不重置境内外
  if (resetAll && resetAll == 1) {
    filterForm.isTranslateLanguage = true;
    filterForm.translateLanguage = ['english'];
    keywords.value = {
      advanced: [],
      must: [],
      any: [],
      not: []
    };
    searchKeywordType.value = 1;
  }
  if (!resetAll || resetAll !== 2) {
    filterForm.region = ['1', '2'];
  }
  changeDataByRegionType(filterForm.region);
  filterForm.isSimpleComplex = getUserInfoPermissionItem(
    'search:isSimpleComplex:checked'
  )
    ? 1
    : 0;
  filterForm.startPubTime = '';
  filterForm.endPubTime = '';
  filterForm.realTime = hashPage.includes('collect') ? 365 : 7;
  filterForm.esRealTime = 99;
  filterForm.rangeValue = [
    formatDate(new Date(getDay(-1)), 'YYYY-mm-dd HH:MM:SS'),
    formatDate(new Date(Date.now()), 'YYYY-mm-dd HH:MM:SS')
  ];
  dateStatus.value = false;
  filterForm.esRangeValue = [
    formatDate(new Date(getDay(-1)), 'YYYY-mm-dd HH:MM:SS'),
    formatDate(new Date(Date.now()), 'YYYY-mm-dd HH:MM:SS')
  ];
  filterForm.matchMethods = '';
  filterForm.polarity = [];
  filterForm.authType = [];
  filterForm.fansCount = {
    fromFan: '' as string | number,
    toFan: '' as string | number,
    type: '0'
  };
  /* 媒体渠道 */
  filterForm.mediaType = [];
  /* 信息语言 */
  filterForm.languages = [];
  //媒体标签
  filterForm.mediaTags = [];
  //IP属地
  filterForm.areaIds = [];
  //信息分类
  filterForm.mediaGradeTags = [];
  filterForm.areaType = ['1', '2', '3'];

  filterForm.articleType = [];
  filterForm.queryType = '0';
  filterForm.mediaInfos = [];
  filterForm.include = 0;
  filterForm.institutionTag = [];
  filterForm.mediaGradeTag = [];
  filterForm.mediaRegionTag = [];
  filterForm.accountTag = [];
  filterForm.levelTags = [];
  // tagText.value = t('message.searchResult.moreFilters'); // 更多筛选
  tagText.value = ''; // 更多筛选
  moreIcon.value = true;
  filterForm.sentiments = [];
  filterForm.subjects = [];
  filterForm.subjectSwitch = 0;
  filterForm.sitePaid = -1;
};
const previewList = ref<Array<previewListItem>>([]);
const setFilterPreview = (param: any) => {
  //var clonedVueObject = JSON.parse(JSON.stringify(params));
  const params = Object.assign(
    cloneDeep(param),
    getMediaRegionAndInstitution(cloneDeep(param))
  );
  const formItems = cloneDeep(filterFormItems);
  previewList.value = [];
  formItems.forEach((item: any) => {
    const content: string[] = [];
    // if (item.showOut || params[item.formKey] === item.default) return;
    if (params[item.formKey] === item.default) return; //发布时间移入更多筛选，增加时间戳转换
    if (item.formKey === 'rangeValue') {
      if (params.realTime === '' || params.realTime == 0) {
        content.push(params.rangeValue[0] + ' 至 ' + params.rangeValue[1]);
      } else {
        content.push(
          ...item.option
            .filter((a: any) => {
              return params.realTime === a.id;
            })
            .map((itm: any) => {
              return itm.name;
            })
        );
      }
    } else if (item.formKey === 'esRangeValue') {
      if (params.esRealTime !== 99) {
        if (params.esRealTime === '' || params.esRealTime == 0) {
          content.push(
            params.esRangeValue[0] + ' 至 ' + params.esRangeValue[1]
          );
        } else {
          content.push(
            ...item.option
              .filter((a: any) => {
                return params.esRealTime == a.id;
              })
              .map((itm: any) => {
                return itm.name;
              })
          );
        }
      }
    } else {
      const tagsKeys = [
        'mediaType',
        'mediaGradeTags',
        'institutionTag',
        'mediaGradeTag',
        'mediaRegionTag',
        'accountTag',
        'levelTags',
        'mediaTags'
      ];
      if (tagsKeys.includes(item.formKey)) {
        content.push(...setLevelPreview(params[item.formKey], item.option, []));
      } else if (item.formKey === 'areaIds') {
        if (item.option.length == 0) {
          item.option.splice(0);
          item.option = JSON.parse(Local.get('areaIds')); //页面刷新地域识别下拉框数据为空时 调用local中的数据保证回显正常
        }
        content.push(...setLevelPreview(params[item.formKey], item.option, []));
      } else if (item.formKey === 'fansCount') {
        if (params.fansCount.type === '') {
          const fromFan = params.fansCount.fromFan || '',
            toFan = params.fansCount.toFan || '';
          (fromFan || toFan) && content.push(fromFan + ' - ' + toFan);
        } else if (
          params.fansCount.type != '0' &&
          params.fansCount.type != ''
        ) {
          content.push(
            ...item.option
              .filter((a: any) => {
                if (a.id != '0') {
                  return params.fansCount.type === a.id;
                }
              })
              .map((itm: any) => {
                return itm.name;
              })
          );
        }
      } else if (item.formKey === 'mediaTags') {
        content.push(...params.mediaTags); //0.7迭代媒体标签新增更多后子标签无法提供id 故不需要进行id与name的匹配
      } else if (item.formKey === 'mediaInfos') {
        const len = params[item.formKey]?.length;
        if (len) {
          content.push(
            `${
              params.include
                ? t('message.searchSpecifiedMedia.exclude')
                : t('message.searchSpecifiedMedia.include')
            } ${len}个`
          );
        }
      } else if (item.formKey === 'sitePaid') {
        content.push(
          ...item.option
            .filter((a: any) => {
              return a.id != -1 && params[item.formKey] === a.id;
            })
            .map((itm: any) => {
              return itm.name;
            })
        );
      } else {
        content.push(
          ...item.option
            .filter((a: any) => {
              return params[item.formKey]
                ?.toString()
                .includes(a.id?.toString());
            })
            .map((itm: any) => {
              return itm.name;
            })
        );
      }
    }
    if (!content.length) return;
    previewList.value.push({
      key: item.formKey,
      title: item.title,
      content: content
    });
  });
  setTagText(previewList.value);
};
const setLevelPreview = (
  params: string[],
  list: string[],
  result: string[]
) => {
  if (!params.length) return [];
  list.forEach((item: any) => {
    if (params.includes(item.id || item.value || item.pek)) {
      result.push(item.name || item.label);
    }
    if (item.children && item.children.length) {
      setLevelPreview(params, item.children, result);
    }
  });
  return result;
};
const setTagText = (data: Array<previewListItem>) => {
  let str = '';
  const arr: any = [
    'mediaType',
    'rangeValue',
    'matchMethods',
    'articleType',
    'polarity',
    'levelTags'
  ];
  data.map((item: any) => {
    if (!arr.includes(item.key)) {
      str
        ? (str += `<span class='flterTitle'>${item.title}：</span>`)
        : (str += `${item.title}：`);
      str += item.content.join('、');
      arr.push(item.key);
    }
  });
  tagText.value = str;
  if (!tagText.value) {
    // tagText.value = t('message.searchResult.moreFilters'); //'更多筛选'
    moreIcon.value = true;
  } else {
    moreIcon.value = false;
  }
};
const handleKeywordsEmpty = () => {
  // 1关键词  0表达式
  if (
    ((searchKeywordType.value == 0 && !keywords.value.advanced.length) ||
      (searchKeywordType.value == 1 &&
        !keywords.value.must.length &&
        !keywords.value.any.length &&
        !keywords.value.not.length)) &&
    !filterForm.mediaInfos?.length &&
    !filterForm.levelTags?.length &&
    !filterForm.mediaTags?.length &&
    !filterForm.mediaGradeTag?.length &&
    !filterForm.mediaRegionTag?.length &&
    !filterForm.institutionTag?.length
  ) {
    return false;
  }
  return true;
};
const checkRequiredKeywords = () => {
  let rst = true;
  if (searchKeywordType.value == 1) {
    if (
      keywords.value.not.length &&
      !keywords.value.must.length &&
      !keywords.value.any.length &&
      !filterForm.mediaInfos?.length &&
      !filterForm.levelTags?.length &&
      !filterForm.mediaTags?.length &&
      !filterForm.mediaGradeTag?.length &&
      !filterForm.mediaRegionTag?.length &&
      !filterForm.institutionTag?.length
    ) {
      ElMessage.warning('请输入需要包含的关键词');
      rst = false;
    }
  }
  return rst;
};
const getIpIds = (list: any[], mediaAreas: string | any[], ipIds: any[]) => {
  list.forEach((area: any) => {
    if (mediaAreas.includes(area.id)) {
      if (area.parentId == 0) {
        !ipIds.includes(area.id) && ipIds.push(area.id);
      } else {
        !ipIds.includes(area.parentId) && ipIds.push(area.parentId);
      }
    }
    if (area.children && area.children.length) {
      getIpIds(area.children, mediaAreas, ipIds);
    }
  });
};
// 获取参数
const getParams = (page?: string, type?: string) => {
  const filterParams = cloneDeep(filterForm);

  const param: any = Object.assign(filterParams, {});
  // 入库时间
  param.startEsInsertTime =
    param.esRealTime === 99
      ? null
      : dayjs(param.esRangeValue[0]).format('YYYY-MM-DD HH:mm:ss');
  param.endEsInsertTime =
    param.esRealTime === 99
      ? null
      : dayjs(param.esRangeValue[1]).format('YYYY-MM-DD HH:mm:ss');
  if (page === 'collect') {
    if (param.realTime == '0') {
      colectForm.searchTime.startPubTime = param.rangeValue[0];
      colectForm.searchTime.endPubTime = param.rangeValue[1];
      colectForm.searchTime.realTime = 0;
    } else {
      colectForm.searchTime.realTime = param.realTime;
    }
    colectForm.searchTime.now = dayjs().format('YYYY-MM-DD HH:mm:ss');
    return colectForm;
  }
  if (param.realTime == '0') {
    // param.realTime = 0;
    param.startPubTime = param.rangeValue[0];
    param.endPubTime = param.rangeValue[1];
  }
  delete param.rangeValue;
  param.matchMethod = param.matchMethods ? [param.matchMethods] : [];
  delete param.matchMethods;
  const channelIds = filterParams.mediaType;
  const searchAnalyseStore = useSearchAnalyse();
  const { sAnalysechannelIds } = storeToRefs(searchAnalyseStore);
  sAnalysechannelIds.value = channelIds;
  let arr = [];
  if (channelIds.length) {
    const mediaTypes = JSON.parse(JSON.stringify(mediaType.value));
    arr = getfilterList(mediaTypes, channelIds, 'value');
  }
  param.dataSource = arr;
  delete param.mediaType;
  param.searchKeywordType = searchKeywordType.value;
  if (searchKeywordType.value == 0 && keywords.value.advanced.length) {
    // const advanced: string[] = JSON.parse(JSON.stringify(keywords.value.advanced));
    // let keywordExp = [] as string[];
    // advanced.forEach((i: any) => {
    // 	let arr = i.split(' ');
    // 	arr = arr.filter((s: any) => {
    // 		return s && s.trim();
    // 	});
    // 	i = arr.join('&');
    // 	keywordExp.push(i);
    // });
    // param.keywordExp = keywordExp;
    if (filterParams.isTranslateLanguage) {
      param.keywordExp = keywords.value.advanced.map((v: any) => {
        return v?.keyword || v;
      });
      param.translateExpression = keywords.value.advanced;
      param.translateLanguage = filterParams.translateLanguage;
      delete param.isTranslateLanguage;
    } else {
      param.keywordExp = keywords.value.advanced;
      delete param.translateExpression;
      delete param.translateLanguage;
    }
  } else {
    param.keywordExp = [];
    param.translateExpression = [];
  }
  if (searchKeywordType.value == 1) {
    delete param.translateExpression;
    delete param.translateLanguage;
  }
  delete param.isTranslateLanguage;
  param.mustKeyWord = type === 'createFolder' ? [] : keywords.value.must;
  param.anyKeyWord = keywords.value.any;
  param.needExclude = keywords.value.not;
  if (param.mediaTags.length) {
    const mediaTagsList = [] as any;
    const mediaTagsoptions: any = filterFormItems.find((item: any) => {
      return item.formKey === 'mediaTags';
    })?.option;
    mediaTagsoptions.map((item: any) => {
      param.mediaTags.includes(item.id) && mediaTagsList.push(item.name);
    });
    // param.mediaTags = mediaTagsList;  //4.25修改 不需要id转name 提交媒体标签数据处理
  }
  param.areaDTO = {
    country: [],
    ipArea: [],
    mediaAreas: [],
    contentAreas: [],
    //处理信息分类字段
    mediaGradeTags: [],
    ipAreaCountry: []
  };
  param.informationClassification = param.mediaGradeTags;
  param.mediaGradeTags.forEach((item: any) => {
    const index = { tags2: item };
    param.areaDTO.mediaGradeTags.push(index);
  });
  delete param.mediaGradeTags;
  if (param.areaType?.length) {
    const mediaAreas = param.areaIds;
    //处理内容提及参数
    if (param.areaType.includes('3')) {
      // 内容提及
      const areaIdsOption: any = filterFormItems.find((item: any) => {
        return item.formKey === 'areaIds';
      })?.option;
      // console.log(areaIdsOption,mediaAreas)
      areaIdsOption[0]?.children?.forEach((element: any) => {
        if (mediaAreas.indexOf(element.value) > -1) {
          param.areaDTO.contentAreas.push({ contentAreaProvince: element.id2 });
        }
        if (element.children) {
          element.children.forEach((moss: any) => {
            if (mediaAreas.indexOf(moss.value) > -1) {
              param.areaDTO.contentAreas.push({ contentAreaCity: moss.id2 });
            }
          });
        }
      });
      areaIdsOption[1]?.children?.forEach((element: any) => {
        if (mediaAreas.indexOf(element.value) > -1) {
          param.areaDTO.contentAreas.push({
            contentAreaCountry: element.value
          });
        }
      });
    }
    if (param.areaType.includes('2')) {
      // 媒体属地
      param.areaDTO.mediaAreas = concatMediaAreas(
        useDictStore().areaTreeList,
        mediaAreas
      );

      if (useDictStore().countryPekList.length) {
        const countryList: any = [],
          countryPekList = useDictStore().countryPekList;
        countryPekList.forEach((item: any) => {
          if (mediaAreas.includes(item.pek)) {
            countryList.push(item.pek);
          }
        });
        param.areaDTO.country = countryList;
        param.areaDTO.ipAreaCountry = [];
      }
    }
    if (param.areaType.includes('1')) {
      // IP属地
      const areaTreeList = useDictStore().areaTreeList;
      const ipIds: any = [];
      getIpIds(areaTreeList, mediaAreas, ipIds);
      // console.log(areaTreeList,mediaAreas,ipIds)
      param.areaDTO.ipArea = ipIds;
      const countryList: any = [],
        countryPekList = useDictStore().countryPekList;
      countryPekList.forEach((item: any) => {
        if (mediaAreas.includes(item.pek)) {
          countryList.push(item.pek);
        }
      });
      param.areaDTO.ipAreaCountry = countryList;
    }
  }
  delete param.areaIds;
  delete param.areaType;
  if (param.fansCount.type !== '') {
    param.fansCount.fromFan = fansList.find((item: any) => {
      return item.id == param.fansCount.type;
    })?.fromFan;
    param.fansCount.toFan = fansList.find((item: any) => {
      return item.id == param.fansCount.type;
    })?.toFan;
  }
  param.isForeignMedia = getUserPermItem('dataAuth');
  if (getUserPermItem('dataAuth') == 0) {
    if (param.region.includes('1') && param.region.includes('2')) {
      param.isForeignMedia = '0';
    } else if (param.region.includes('1') && !param.region.includes('2')) {
      param.isForeignMedia = '1';
    } else if (!param.region.includes('1') && param.region.includes('2')) {
      param.isForeignMedia = '2';
    }
  }

  delete param.region;
  delete param.designatedMediaLabelId;
  return param;
};
/**修改简繁匹配状态 */
const changeIsSimpleComplex = () => {
  try {
    ElMessage && ElMessage.closeAll();
  } catch (error) {}
  if (
    !filterForm.isSimpleComplex &&
    keywordsCurrentNum.value * 2 > keywordsLimitNum.value
  ) {
    ElMessage.warning(
      '选中简繁匹配关键词个数将超过关键词限制个数，请先删除部分关键词！'
    );
    return;
  }
  if (filterForm.isSimpleComplex) {
    filterForm.isSimpleComplex = 0;
  } else {
    filterForm.isSimpleComplex = 1;
  }
  const keywordArr: any = cloneDeep(keywords.value);
  if (filterForm?.isTranslateLanguage && keywordArr.advanced.length) {
    keywordArr.advanced = flat(keywordArr.advanced).map((v: any) => {
      return v?.keyword || v;
    });
  }
  checkKeywordsNum(
    keywordArr,
    searchKeywordType.value,
    filterForm.isSimpleComplex
  );
  setSchemeStatus(true);
};
// 插入二次检索的参数，保存在主题中
const insertSecondSearchParams = (data: any) => {
  const { isCollapse, displayOption, sortInfo, secondSearch } = data;
  filterForm.isCollapse = isCollapse || defaultFilterFormParams().isCollapse;
  filterForm.displayOption = displayOption?.length
    ? displayOption
    : defaultFilterFormParams().displayOption;
  filterForm.sortInfo = sortInfo || defaultFilterFormParams().sortInfo;
  filterForm.secondSearch =
    secondSearch || defaultFilterFormParams().secondSearch;
  Object.assign(data, filterForm);
  return data;
};
// 点击主题回填参数
const handleSetVal = (data: any) => {
  resetShowEliminatedInfos();
  Object.assign(filterForm, data);
  const {
    anyKeyWord,
    mustKeyWord,
    needExclude,
    keywordExp,
    isSimpleComplex,
    realTime,
    esRealTime,
    startPubTime,
    endPubTime,
    matchMethod,
    dataSource,
    polarity,
    languages,
    mediaTags,
    areaDTO,
    authType,
    fansCount,
    articleType,
    isForeignMedia,
    informationClassification,
    mediaInfos,
    include,
    queryType,
    institutionTag,
    mediaGradeTag,
    mediaRegionTag,
    accountTag,
    levelTags,
    sentiments,
    subjects,
    subjectSwitch,
    sitePaid
  } = data;
  filterForm.searchKeywordType = data.searchKeywordType;
  searchKeywordType.value = data.searchKeywordType;
  searchTransLateLanguages.value = data?.translateLanguage?.length
    ? data?.translateLanguage
    : ['english'];
  if (data?.translateLanguage?.length) {
    filterForm.isTranslateLanguage = true;
  } else {
    filterForm.isTranslateLanguage = false;
  }
  const translateExpression = data?.translateExpression
    ? data?.translateExpression
    : [];
  keywords.value.advanced = data?.translateLanguage?.length
    ? translateExpression
    : keywordExp;
  keywords.value.must = mustKeyWord;
  keywords.value.any = anyKeyWord;
  keywords.value.not = needExclude;
  filterForm.isSimpleComplex = isSimpleComplex;
  filterForm.anyKeyWord = anyKeyWord || [];
  filterForm.mustKeyWord = mustKeyWord || [];
  filterForm.needExclude = needExclude || [];
  filterForm.keywordExp = keywordExp || [];
  filterForm.realTime = realTime ? realTime : '0';
  filterForm.startPubTime = startPubTime || '';
  filterForm.endPubTime = endPubTime || '';
  filterForm.esRealTime = esRealTime ? esRealTime : 99;
  filterForm.rangeValue = [
    startPubTime || formatDate(new Date(getDay(-1)), 'YYYY-mm-dd HH:MM:SS'),
    endPubTime || formatDate(new Date(Date.now()), 'YYYY-mm-dd HH:MM:SS')
  ];
  if (realTime == 0) dateStatus.value = true;
  else dateStatus.value = false;
  filterForm.matchMethods = matchMethod.length
    ? Number(matchMethod.join(''))
    : '';
  if (getUserPermItem('dataAuth') == 0) {
    filterForm.region = isForeignMedia
      ? isForeignMedia == 0
        ? ['1', '2']
        : [isForeignMedia]
      : ['1', '2'];
  } else {
    filterForm.region = isForeignMedia
      ? [isForeignMedia]
      : [getUserPermItem('dataAuth')];
  }
  changeDataByRegionType(filterForm.region);
  const dataSourcelist = dataSource.length
    ? getChannelIds(dataSource, 'value')
    : [];
  const mediaTypelist = [] as any;
  mediaType.value.map((item: any) => {
    if (item.children?.length) {
      item.children.map((child: any) => {
        if (dataSourcelist.includes(child.value))
          mediaTypelist.push(child.value);
      });
    } else {
      if (dataSourcelist.includes(item.value)) mediaTypelist.push(item.value);
    }
  });
  filterForm.mediaType = mediaTypelist;
  filterForm.polarity = polarity || [];
  filterForm.languages = languages || [];
  const mediaTagsList = [] as any;
  if (mediaTags.length) {
    const mediaTagsoptions: any = filterFormItems.find((item: any) => {
      return item.formKey === 'mediaTags';
    })?.option;
    mediaTagsoptions.map((item: any) => {
      mediaTags.includes(item.name) && mediaTagsList.push(item.id);
    });
  }
  // filterForm.mediaTags = mediaTagsList;   //4.25修改 不需要id转name 点击更多筛选回显
  filterForm.mediaTags = mediaTags;
  filterForm.mediaGradeTags = informationClassification || []; //信息分类字段绑定在表单上的位置
  let areaIdList = [] as any;
  filterForm.areaType = [];
  if (areaDTO.ipArea.length || areaDTO?.ipAreaCountry?.length) {
    // ip属地
    filterForm.areaType.push('1');
    areaIdList = areaDTO?.ipAreaCountry?.length
      ? areaDTO.ipArea.concat(areaDTO.ipAreaCountry)
      : areaDTO.ipArea;
  }
  if (areaDTO.mediaAreas.length || areaDTO.country.length) {
    // 媒体属地
    filterForm.areaType.push('2');
    const mediaAreas = areaDTO.mediaAreas;
    mediaAreas.forEach((area: any) => {
      if (
        area.mediaAreaProvince &&
        !areaIdList.includes(area.mediaAreaProvince)
      ) {
        areaIdList.push(area.mediaAreaProvince);
      }
      if (area.mediaAreaCity && !areaIdList.includes(area.mediaAreaCity)) {
        areaIdList.push(area.mediaAreaCity);
      }
    });
    areaIdList = areaDTO?.country?.length
      ? areaIdList.concat(areaDTO.country)
      : areaIdList;
  }
  if (areaDTO.contentAreas?.length) {
    //内容提及
    filterForm.areaType.push('3');
    const contentAreas = areaDTO.contentAreas;
    contentAreas.forEach((area: any) => {
      if (area.contentAreaProvince && area.contentAreaProvince !== null) {
        const item = area.contentAreaProvince?.split('-');
        !areaIdList.includes(item[item.length - 1]) &&
          areaIdList.push(item[item.length - 1]);
      }
      if (area.contentAreaCity && area.contentAreaCity !== null) {
        const item = area.contentAreaCity?.split('-');
        !areaIdList.includes(item[item.length - 1]) &&
          areaIdList.push(item[item.length - 1]);
      }
    });
  } //回填地域识别-内容提及
  if (
    !areaDTO.ipArea.length &&
    !areaDTO?.ipAreaCountry?.length &&
    !areaDTO.mediaAreas.length &&
    !areaDTO.country.length &&
    !areaDTO.contentAreas?.length
  )
    filterForm.areaType = ['1', '2', '3'];

  filterForm.areaIds = Array.from(new Set(areaIdList));
  filterForm.authType = filterForm.authTypes
    ? [filterForm.authTypes]
    : authType || []; //旧逻辑单独保存了authTypes字符串字段用于单选回显，现统一存在authType数组字段多选
  delete filterForm.authTypes;
  let fansCounts = {
    fromFan: '',
    toFan: '',
    type: '0'
  };
  if (!fansCount.fromFan && !fansCount.toFan) {
    filterForm.fansCount = fansCounts;
  } else {
    const fansCountOptions: any = filterFormItems.find((item: any) => {
      return item.formKey === 'fansCount';
    })?.option;
    fansCountOptions.map((item: any) => {
      if (fansCount.fromFan == item.fromFan && fansCount.toFan == item.toFan) {
        fansCounts.type = item.id;
      } else {
        fansCounts = Object.assign(fansCounts, fansCount);
        fansCounts.type = '';
      }
    });
    filterForm.fansCount = fansCounts || { fromFan: '', toFan: '', type: '0' };
  }
  filterForm.articleType = articleType || [];
  filterForm.mediaInfos = mediaInfos || [];
  filterForm.include = include || 0;
  filterForm.queryType = queryType || '0';
  filterForm.institutionTag = institutionTag || [];
  filterForm.mediaGradeTag = mediaGradeTag || [];
  filterForm.mediaRegionTag = mediaRegionTag || [];
  filterForm.accountTag = accountTag || [];
  filterForm.levelTags = levelTags || [];
  filterForm.sentiments = sentiments || [];
  filterForm.subjects = subjects ? subjects : [];
  filterForm.subjectSwitch = subjectSwitch || 0;
  filterForm.sitePaid = sitePaid || sitePaid === 0 ? sitePaid : -1;
  setFilterPreview(filterForm);
  insertSecondSearchParams(data);
  setSchemeStatus(false);
};
const RegionFilter: any = ref([]);
//修改境内境外选项
const changeDataByRegionType = (region: any[] | undefined) => {
  const searchAnalyseStore = useSearchAnalyse();
  const { dataAuthType } = storeToRefs(searchAnalyseStore);
  const regions = region as any[];
  RegionFilter.value = regions;
  const mediaList = filterFormItems.find((item: any) => {
    return item.formKey === 'mediaType';
  })?.option;
  const localMediatigs = filterFormItems.find((item: any) => {
    return item.formKey === 'mediaTags';
  })?.option;
  const regionMediatigs = filterFormItems.find((item: any) => {
    return item.formKey === 'mediaTags';
  })?.moreoption;

  //重组媒体标签-更多中的数据
  const transMediaTags: any = ref([
    { id: 0, name: '主流/权威媒体', values: [] },
    { id: 1, name: '媒体机构', values: [] }
  ]);
  useDictStore().mediaTagList.map((item: any) => {
    transMediaTags.value[0].values.push({
      item: item.name,
      type: item.type,
      parent: '主流/权威媒体'
    });
  });
  useDictStore().mediaOrganization.map((item: any) => {
    transMediaTags.value[1].values.push({
      item: item.name,
      type: item.type + 1,
      parent: '媒体机构'
    });
  });

  localMediatigs?.splice(0, localMediatigs.length);
  localMediatigs?.push(...useDictStore().mediaTagList);

  regionMediatigs?.splice(0, regionMediatigs.length);
  regionMediatigs?.push(...transMediaTags.value);
  if ((regions.includes('1') && regions.includes('2')) || !regions.length) {
    dataAuthType.value = 0;
    mediaList?.splice(0, mediaList.length);
    mediaList?.push(...cloneDeep(useDictStore().mediaTypeList));
    mediaType.value = cloneDeep(useDictStore().mediaTypeList);
  } else if (regions.includes('1') && !regions.includes('2')) {
    dataAuthType.value = 1;
    mediaList?.splice(0, mediaList.length);
    mediaList?.push(...cloneDeep(useDictStore().mediaTypeLocalList));
    mediaType.value = cloneDeep(useDictStore().mediaTypeLocalList);
  } else if (!regions.includes('1') && regions.includes('2')) {
    dataAuthType.value = 2;
    mediaList?.splice(0, mediaList.length);
    mediaList?.push(...cloneDeep(useDictStore().mediaTypeForeignList));
    mediaType.value = cloneDeep(useDictStore().mediaTypeForeignList);
  } else {
    mediaList?.splice(0, mediaList.length);
    mediaList?.push(...cloneDeep(useDictStore().mediaTypeList));
    mediaType.value = cloneDeep(useDictStore().mediaTypeList);
  }
};
const flat = (items: any) => {
  let newArr: any = []; // 存储扁平化数组
  items &&
    items.forEach((item: any) => {
      newArr.push(item);
      if (item?.children && Array.isArray(item?.children)) {
        newArr = newArr.concat(item.children);
        flat(item.children);
      }
    });
  return newArr;
};
const checkKeywordsLength = () => {
  let len = 0,
    allArr = [],
    rst = true;
  const keywordArr = cloneDeep(keywords.value);

  if (searchKeywordType.value == 1) {
    allArr = [...keywordArr.must, ...keywordArr.any, ...keywordArr.not];
  } else {
    allArr = keywordArr.advanced.map(v => {
      return v?.keyword || v;
    });
  }
  allArr.forEach((keyword: any) => {
    len += keyword.length;
  });
  rst = len > keywordsStrTotalLength.value ? false : true;
  return rst;
};

// 获取某一筛选条件的option选项
const getFilterOption = (key: string) => {
  const option = filterFormItems.find((item: any) => {
    return item.formKey === key;
  })?.option;
  return option || [];
};
// 获取媒体地域、机构类型传参处理
const getMediaRegionAndInstitution = param => {
  const mediaRegionTag = param?.mediaRegionTag || [];
  const data = {
    mediaRegionTag: [],
    institutionTag: []
  };
  if (mediaRegionTag.length) {
    data.mediaRegionTag = getTagValue(
      getFilterOption('mediaRegionTag'),
      mediaRegionTag
    );
  }

  const institutionTag = param?.institutionTag || [];
  if (institutionTag.length) {
    data.institutionTag = getTagValue(
      getFilterOption('institutionTag'),
      institutionTag
    );
  }
  return data;
};
const getTagValue = (list: any[], values: any[]) => {
  const result: any = [];
  list.forEach((v: any) => {
    if (v?.children?.length) {
      const childList = v.children.filter(child => {
        return values.includes(child.value);
      });
      if (childList.length == v.children.length) {
        result.push(v.value);
      } else {
        result.push(
          ...childList.map(it => {
            return it.value;
          })
        );
      }
    } else {
      values.includes(v.value) && result.push(v.value);
    }
  });
  return result;
};
// 空搜权限下不能转分析和大模型报告
const handleSearchEmpty = () => {
  // 1关键词  0表达式
  if (
    ((searchKeywordType.value == 0 && !keywords.value.advanced.length) ||
      (searchKeywordType.value == 1 &&
        !keywords.value.must.length &&
        !keywords.value.any.length &&
        !keywords.value.not.length)) &&
    !filterForm.mediaInfos?.length &&
    !filterForm.levelTags?.length &&
    !filterForm.mediaTags?.length &&
    !filterForm.mediaGradeTag?.length &&
    !filterForm.mediaRegionTag?.length &&
    !filterForm.institutionTag?.length &&
    !filterForm.mediaType?.length &&
    !filterForm.articleType?.length &&
    !filterForm.matchMethods &&
    !filterForm.polarity?.length &&
    !filterForm.authType?.length &&
    (filterForm?.fansCount?.type == '0' ||
      (!filterForm?.fansCount?.type &&
        !filterForm?.fansCount?.fromFan &&
        filterForm?.fansCount?.toFan)) &&
    !filterForm.areaIds?.length &&
    !filterForm.languages?.length
  ) {
    return false;
  }
  return true;
};
const subjectKnowledgeList = ref<any>([]);
const showEliminatedInfos = ref(false); //显示已剔除信息
const resetShowEliminatedInfos = () => {
  showEliminatedInfos.value = false;
};
// 对于没有空搜权限账号，无关键词搜索时，禁止指定媒体下仅排除媒体查询
const handleKeywordsEmptyExcludeMedia = () => {
  if (
    ((searchKeywordType.value == 0 && !keywords.value.advanced.length) ||
      (searchKeywordType.value == 1 &&
        !keywords.value.must.length &&
        !keywords.value.any.length &&
        !keywords.value.not.length)) &&
    !filterForm.levelTags?.length &&
    !filterForm.mediaTags?.length &&
    !filterForm.mediaGradeTag?.length &&
    !filterForm.mediaRegionTag?.length &&
    !filterForm.institutionTag?.length &&
    filterForm.include == 1 &&
    filterForm.mediaInfos?.length
  ) {
    return false;
  }
  return true;
};

// 搜索列表查询数据中，查询按钮loading，主题树置灰禁用不可点击状态
const searchLoading = ref<boolean>(false);
export default function useSearchForm() {
  return {
    format,
    filterFormItems,
    filterForm,
    dateStatus,
    tagText,
    moreIcon,
    changeIsSimpleComplex,
    searchMenuVisible,
    isValidKey,
    handleReSet,
    handleSetVal,
    previewList,
    setFilterPreview,
    setTagText,
    updateMediaTypeCount,
    getfilterList,
    getParams,
    getChannelIds,
    changeObjaryKey,
    getUserPermItem,
    userDataAuth,
    getDay,
    colectForm,
    handleKeywordsEmpty,
    checkRequiredKeywords,
    changeDataByRegionType,
    RegionFilter,
    insertSecondSearchParams,
    schemeIsChanged,
    setSchemeStatus,
    flat,
    checkKeywordsLength,
    addNewSchemeClickShowTip,
    borderAnimation,
    getFilterOption,
    getMediaRegionAndInstitution,
    handleSearchEmpty,
    concatMediaAreas,
    getIpIds,
    subjectKnowledgeList,
    showEliminatedInfos,
    handleKeywordsEmptyExcludeMedia,
    searchLoading
  };
}
