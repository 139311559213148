import { nextTick } from 'vue';
import type { App } from 'vue';
import * as svg from '@element-plus/icons-vue';
import router from '/@/router/index';
import pinia from '/@/stores/index';
import { storeToRefs } from 'pinia';
import { useThemeConfig } from '/@/stores/themeConfig';
import { Local } from '/@/utils/storage';
import SvgIcon from '/@/components/svgIcon/index.vue';
import type { UploadRawFile, UploadFile } from 'element-plus';
import {
  AnalysisState,
  CoverState,
  LogoType,
  RedHeaderState
} from '../views/video/report/template/types';
import { Session } from '/@/utils/storage';
import { useDataSourceStore } from '/@/stores/dataSourceOption';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { useDictStore } from '/@/stores/dict';
import { ElMessageBox } from 'element-plus';
import axios from 'axios';
import { useRoutesList } from '/@/stores/routesList';
import { i18n } from '/@/i18n/index';
/**
 * 导出全局注册 element plus svg 图标
 * @param app vue 实例
 * @description 使用：https://element-plus.gitee.io/zh-CN/component/icon.html
 */
export function elSvg(app: App) {
  const icons = svg as any;
  for (const i in icons) {
    app.component(`ele-${icons[i].name}`, icons[i]);
  }
  app.component('SvgIcon', SvgIcon);
}

/**
 * 设置浏览器标题国际化
 * @method const title = useTitle(); ==> title()
 */
const t = i18n.global.t;
export function useTitle() {
  const stores = useThemeConfig(pinia);
  const { themeConfig } = storeToRefs(stores);
  nextTick(() => {
    let webTitle = '';
    const globalTitle: string = themeConfig.value.globalTitle;
    const { path, meta } = router.currentRoute.value;
    if (path === '/login') {
      webTitle = <any>meta.title;
    } else {
      webTitle = setTagsViewNameI18n(router.currentRoute.value);
    }
    document.title =
      `${webTitle ? webTitle + '-' : ''}  ${globalTitle}` || globalTitle;
  });
}

/**
 * 设置 自定义 tagsView 名称
 * @param params 路由 query、params 中的 tagsViewName
 * @returns 返回当前 tagsViewName 名称
 */
export function setTagsViewNameI18n(item: any) {
  let tagsViewName: any = '';
  const { query, params, meta } = item;
  if (query?.tagsViewName || params?.tagsViewName) {
    // 非国际化
    tagsViewName = query?.tagsViewName || params?.tagsViewName;
  } else {
    // 非自定义 tagsView 名称
    tagsViewName = meta.title ? t(meta.title) : '';
  }
  return tagsViewName;
}

/**
 * 图片懒加载
 * @param el dom 目标元素
 * @param arr 列表数据
 * @description data-xxx 属性用于存储页面或应用程序的私有自定义数据
 */
export const lazyImg = (el: any, arr: any) => {
  const io = new IntersectionObserver(res => {
    res.forEach((v: any) => {
      if (v.isIntersecting) {
        const { img, key } = v.target.dataset;
        v.target.src = img;
        v.target.onload = () => {
          io.unobserve(v.target);
          arr[key]['loading'] = false;
        };
      }
    });
  });
  nextTick(() => {
    document.querySelectorAll(el).forEach(img => io.observe(img));
  });
};

/**
 * 全局组件大小
 * @returns 返回 `window.localStorage` 中读取的缓存值 `globalComponentSize`
 */
export const globalComponentSize = (): string => {
  const stores = useThemeConfig(pinia);
  const { themeConfig } = storeToRefs(stores);
  return (
    Local.get('themeConfig')?.globalComponentSize ||
    themeConfig.value?.globalComponentSize
  );
};

/**
 * 对象深克隆
 * @param obj 源对象
 * @returns 克隆后的对象
 */
export function deepClone(obj: any) {
  let newObj: any;
  try {
    newObj = obj.push ? [] : {};
  } catch (error) {
    newObj = {};
  }
  for (const attr in obj) {
    if (obj[attr] && typeof obj[attr] === 'object') {
      newObj[attr] = deepClone(obj[attr]);
    } else {
      newObj[attr] = obj[attr];
    }
  }
  return newObj;
}

/**
 * 判断是否是移动端
 */
export function isMobile() {
  if (
    navigator.userAgent.match(
      /('phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone')/i
    )
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * 判断数组对象中所有属性是否为空，为空则删除当前行对象
 * @description @感谢大黄
 * @param list 数组对象
 * @returns 删除空值后的数组对象
 */
export function handleEmpty(list: any) {
  const arr = [];
  for (const i in list) {
    const d = [];
    for (const j in list[i]) {
      d.push(list[i][j]);
    }
    const leng = d.filter(item => item === '').length;
    if (leng !== d.length) {
      arr.push(list[i]);
    }
  }
  return arr;
}

/**
 * 统一批量导出
 * @method elSvg 导出全局注册 element plus svg 图标
 * @method useTitle 设置浏览器标题国际化
 * @method setTagsViewNameI18n 设置 自定义 tagsView 名称、 自定义 tagsView 名称国际化
 * @method lazyImg 图片懒加载
 * @method globalComponentSize() element plus 全局组件大小
 * @method deepClone 对象深克隆
 * @method isMobile 判断是否是移动端
 * @method handleEmpty 判断数组对象中所有属性是否为空，为空则删除当前行对象
 */
const other = {
  elSvg: (app: App) => {
    elSvg(app);
  },
  useTitle: () => {
    useTitle();
  },
  setTagsViewNameI18n(route: any) {
    return setTagsViewNameI18n(route);
  },
  lazyImg: (el: any, arr: any) => {
    lazyImg(el, arr);
  },
  downLoad: (url: any, name: any) => {
    downLoad(url, name);
  },
  globalComponentSize: () => {
    return globalComponentSize();
  },
  deepClone: (obj: any) => {
    return deepClone(obj);
  },
  isMobile: () => {
    return isMobile();
  },
  handleEmpty: (list: any) => {
    return handleEmpty(list);
  }
};

// 统一批量导出
export default other;

// 仅支持视频下载和图片下载
/**
 *
 * @param {文件路径} url
 * @param {文件名称} name
 */
export function downLoad(url: any, name: any) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'arraybuffer'; // 返回类型blob
  xhr.onload = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      const blob = this.response;
      // 转换一个blob链接
      // 注: URL.createObjectURL() 静态方法会创建一个 DOMString(DOMString 是一个UTF-16字符串)，
      // 其中包含一个表示参数中给出的对象的URL。这个URL的生命周期和创建它的窗口中的document绑定
      const downLoadUrl = window.URL.createObjectURL(
        new Blob([blob], {
          type: 'video/mp4'
        })
      );
      // 视频的type是video/mp4，图片是image/jpeg
      // 01.创建a标签
      const a = document.createElement('a');
      // 02.给a标签的属性download设定名称
      a.download = name;
      // 03.设置下载的文件名
      a.href = downLoadUrl;
      // 04.对a标签做一个隐藏处理
      a.style.display = 'none';
      // 05.向文档中添加a标签
      document.body.appendChild(a);
      // 06.启动点击事件
      a.click();
      // 07.下载完毕删除此标签
      a.remove();
    }
  };
  xhr.send();
}

/**
 * 文件转 base64
 * @param {File|UploadRawFile} raw
 * @returns
 */
export const getBase64 = (raw?: File | UploadRawFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!raw) {
      reject(new Error('no raw file'));
      return;
    }
    const reader = new FileReader();
    let imgResult: string;
    reader.readAsDataURL(raw);
    reader.onload = () => {
      imgResult = reader.result as string;
    };
    reader.onerror = error => {
      reject(error);
    };
    reader.onloadend = () => {
      resolve(imgResult);
    };
  });
};

export const imageValidator = (file: UploadFile): Promise<boolean> =>
  new Promise((resolve, reject) => {
    //请上传.jpg、gif、png格式文件，尺寸750*450像素以内，且文件大小不超过1M
    const matched = file.name.match(/\.(\w+)$/);
    if (!matched) {
      resolve(false);
      return;
    }
    const ext = matched[1];
    const keys = Object.keys(LogoType).map(name => name.toLowerCase());
    if (keys.indexOf(ext) < 0) {
      resolve(false);
      return;
    }
    if (typeof file.size === 'undefined' || file.size > 1024 * 1024) {
      resolve(false);
      return;
    }
    if (!file.raw) {
      resolve(false);
      return;
    }
    const url = window.URL.createObjectURL(file.raw);
    const image = new Image();
    image.src = url;
    image.onload = () => {
      if (image.width > 450 || image.height > 750) {
        resolve(false);
      } else {
        resolve(true);
      }
    };
    image.onerror = e => {
      reject(e);
    };
  });

interface Template {
  name: string;
  cover: boolean;
  redHead: boolean;
  configs: (CoverState | RedHeaderState | AnalysisState)[];
}
export const TEMPLATE_KEY = 'report-template';
export const getTemplate = (): Template => {
  let template;
  try {
    template = Session.get(TEMPLATE_KEY) || {};
  } catch (e) {
    template = {};
  }
  return template;
};

export const genId = (count = 0): (() => number) => {
  return (): number => {
    return count++;
  };
};

type Func = (...args: unknown[]) => void;
class TimeTask {
  protected timeout = 1000;
  protected tasks: Func[] = [];
  protected running = false;
  private timer: number | null = null;
  constructor(timeout: number) {
    this.timeout = timeout;
  }
  addTask(task: Func) {
    this.tasks.push(task);
    return this;
  }
  removeTask(task: Func) {
    const index = this.tasks.indexOf(task);
    if (index > -1) {
      this.tasks.splice(index, 1);
    }
    return this;
  }
  clearTask() {
    this.tasks.length = 0;
  }
  perform() {
    this.tasks.slice().forEach(task => task());
    return this;
  }
  protected step() {
    this.perform();
    this.timer = window.setTimeout(() => {
      this.step();
    }, this.timeout);
  }
  start() {
    if (this.running) return;
    this.running = true;
    this.step();
    return this;
  }
  stop() {
    if (!this.running) return;
    this.running = false;
    this.timer && window.clearTimeout(this.timer);
    return this;
  }

  destroy() {
    this.stop();
    this.clearTask();
  }
}

export const getTimeTask = (timeout: number) => new TimeTask(timeout);

export enum DataSource {
  Twitter = 1,
  Facebook = 2,
  Instagram = 3,
  Website = 4, //网站
  Forum = 6, // 论坛
  ENewspaper = 7, // 电子报纸
  Wechat = 8,
  Mblog = 9,
  APP = 10,
  Youtube = 11,
  Custom = 13, // 自定义
  ShortVideo = 14, // 短视频
  Telegram = 15,
  TouTiao = 17, // 头条
  Video = 18, // 视频网站
  ZhiHu = 19, // 知乎
  QA = 20, // 问答
  Translation = 21, // 译文
  TV = 24, // 电视视频
  MblogMessage = 25 // 微博私信
}

export const getDataSourceName = (dataSource: DataSource) => {
  switch (dataSource) {
    case DataSource.Twitter:
      return 'Twitter';
    case DataSource.Facebook:
      return 'Facebook';
    case DataSource.Instagram:
      return 'Instagram';
    case DataSource.APP:
      return 'APP';
    case DataSource.Custom:
      return '自定义';
    case DataSource.ENewspaper:
      return '电子报纸';
    case DataSource.Forum:
      return '论坛';
    case DataSource.Mblog:
      return '微博';
    case DataSource.MblogMessage:
      return '微博私信';
    case DataSource.QA:
      return '问答';
    case DataSource.ShortVideo:
      return '短视频';
    case DataSource.TV:
      return '电视视频';
    case DataSource.Telegram:
      return 'Telegram';
    case DataSource.TouTiao:
      return '头条';
    case DataSource.Translation:
      return '译文';
    case DataSource.Video:
      return '视频网站';
    case DataSource.Website:
      return '网站';
    case DataSource.Wechat:
      return '微信';
    case DataSource.Youtube:
      return 'Youtube';
    case DataSource.ZhiHu:
      return '知乎';
    default:
      return '未知';
  }
};

export const getSourceIcon = (dataSource: string) => {
  switch (dataSource) {
    case '微信':
      return 'weixin';
    case '微博':
      return 'weibo';
    case '网站':
      return 'guojihua-1';
    case '抖音':
      return 'source-1';
    case '快手':
      return 'source-2';
    case '西瓜视频':
      return 'source-3';
    case '腾讯视频':
      return 'source-4';
    case '好看视频':
      return 'source-5';
    case '搜狐视频':
      return 'source-6';
    case 'A站':
      return 'source-7';
    case 'B站':
      return 'source-8';
    case '优酷':
      return 'source-9';
    case '土豆':
      return 'source-10';
    case '爱奇艺':
      return 'source-11';
    default:
      return 'guojihua-1';
  }
};

const sourceIcon = (sourceType: string) => {
  let icon = 'qita';
  switch (sourceType) {
    case '今日头条':
      icon = 'jinritoutiao';
      break;
    case 'APP':
      icon = 'app';
      break;
    case '手机客户端':
      icon = 'app';
      break;
    case 'B站':
      icon = 'bzhan';
      break;
    case 'Facebook':
      icon = 'facebook';
      break;
    case 'Telegram':
      icon = 'telegram';
      break;
    case 'Twitter':
    case 'X(Twitter)':
      icon = 'twitter';
      break;
    case 'Youtube':
      icon = 'shipinwangzhan';
      break;
    case '爱奇艺':
      icon = 'aiqiyi';
      break;
    case '百度贴吧':
      icon = 'baidutieba';
      break;
    case '论坛BBS':
      icon = 'luntanbbs';
      break;
    case '豆瓣':
      icon = 'douban';
      break;
    case '知乎':
      icon = 'zhihu';
      break;
    case 'Reddit':
      icon = 'reddit';
      break;
    case '电子报刊':
      icon = 'dianzibaokan';
      break;
    case '抖音':
      icon = 'douyin';
      break;
    case '好看视频':
      icon = 'haokanshipin';
      break;
    case '快手':
      icon = 'kuaishou';
      break;
    case '搜狐视频':
      icon = 'souhushipin';
      break;
    case '腾讯视频':
      icon = 'tengxunshipin';
      break;
    case '土豆':
      icon = 'tudou';
      break;
    case '网站':
      icon = 'wangzhan';
      break;
    case '网媒':
      icon = 'wangzhan';
      break;
    case '微博':
      icon = 'weibo';
      break;
    case '微信':
      icon = 'weixin';
      break;
    case '微信视频号':
      icon = 'weixinshipinhao';
      break;
    case '西瓜视频':
      icon = 'xiguashipin';
      break;
    case '小红书':
      icon = 'xiaohongshu';
      break;
    case '优酷':
      icon = 'youku';
      break;
    case '电视视频':
      icon = 'dianshishipin';
      break;
    case 'Instagram':
      icon = 'instagram';
      break;
  }
  return icon;
};

export const getMediaIcon = (sourceType = '00101') => {
  const name = getSourceName(sourceType, '');
  // return new URL(`/src/assets/search/mediaIcon/${sourceIcon(name)}.png`, import.meta.url).href
  return new URL(
    `/src/assets/search/mediaIcon/colorful/${sourceIcon(name)}.svg`,
    import.meta.url
  ).href;
};

export const getMediaColorfulIcon = (sourceType = '00101') => {
  const name = getSourceName(sourceType, '');
  return new URL(
    `/src/assets/search/mediaIcon/colorful/${sourceIcon(name)}.svg`,
    import.meta.url
  ).href;
};

export const debounce = (func: { (): void; (): void }, wait: number) => {
  let timer: NodeJS.Timeout | null = null;
  return () => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      func();
    }, wait);
  };
};

// 获取渠道名称
export const getSourceName = (code: string, type: string) => {
  return (
    useDataSourceStore().dataSourceOption.find(item => {
      if (type === 'analysisName') {
        const codeList = code.split(',').sort((a: number, b: number) => {
          return a - b;
        });
        const itemCodeList = item.value
          .split(',')
          .sort((a: number, b: number) => {
            return a - b;
          });
        return isEqual(codeList, itemCodeList);
      } else {
        return item.value.includes(code);
      }
    })?.name || '未知'
  );
};

// 排序方式
export enum EsortWay {
  ASC = 'asc', //正序
  DESC = 'desc' //倒序
}
// 排序选项
export const sortWayOption: IOptionItem[] = [
  { label: '正序', value: EsortWay.ASC },
  { label: '倒序', value: EsortWay.DESC }
];

interface IOptionItem {
  label: string;
  value: number | string;
  color?: string | object;
}
// 排序维度选项
export const sortFieldOption: IOptionItem[] = [
  { label: t('message.analysisDetails.sortFieldOption[0]'), value: 1 }, // 发布时间
  { label: t('message.analysisDetails.sortFieldOption[1]'), value: 5 }, // 点赞量
  { label: t('message.analysisDetails.sortFieldOption[2]'), value: 3 }, // 转发量
  { label: t('message.analysisDetails.sortFieldOption[3]'), value: 4 }, // 评论量
  { label: t('message.analysisDetails.sortFieldOption[4]'), value: 2 }, // 阅读量
  { label: t('message.analysisDetails.sortFieldOption[5]'), value: 6 }, // 播放量
  { label: t('message.analysisDetails.sortFieldOption[6]'), value: 7 }, // 热度
  { label: t('message.analysisDetails.sortFieldOption[7]'), value: 8 }, // 在看量
  { label: t('message.analysisDetails.sortFieldOption[8]'), value: 12 } // 综合排序
];

// 互动类型选项
export const interactiveTypeOption: IOptionItem[] = [
  { label: t('message.analysisDetails.sortFieldOption[9]'), value: 36 }, // 互动量
  { label: t('message.analysisDetails.sortFieldOption[1]'), value: 10 }, // 点赞量
  { label: t('message.analysisDetails.sortFieldOption[3]'), value: 11 }, // 评论量
  { label: t('message.analysisDetails.sortFieldOption[4]'), value: 12 }, // 阅读量
  { label: t('message.analysisDetails.sortFieldOption[2]'), value: 15 } // 转发量
];

// 榜单数量选项
export const hotSizeOption: IOptionItem[] = [
  { label: 'top10', value: 10 },
  { label: 'top20', value: 20 },
  { label: 'top30', value: 30 },
  { label: 'top50', value: 50 }
];

export enum EpolarityType {
  ZHENG_MIAN = 1,
  FU_MIAN = 2,
  ZHONG_LI = 0
}
// 情感正负面选项
export const polarityOption: IOptionItem[] = [
  {
    label: t('message.analysisDetails.polarityOption[0]'),
    value: EpolarityType.ZHENG_MIAN,
    color: '#2055f4'
  }, // 正面
  {
    label: t('message.analysisDetails.polarityOption[1]'),
    value: EpolarityType.FU_MIAN,
    color: '#ed6363'
  }, // 负面
  {
    label: t('message.analysisDetails.polarityOption[2]'),
    value: EpolarityType.ZHONG_LI,
    color: '#f0a431'
  } // 中立
];
// 大屏主题2情感正负面
export const polarityOptionBigscreenThemeTwo: IOptionItem[] = [
  {
    label: t('message.analysisDetails.polarityOption[0]'), // 正面
    value: EpolarityType.ZHENG_MIAN,
    color: {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 0,
      colorStops: [
        {
          offset: 0,
          color: 'rgba(0, 213, 255, 0.2)'
        },
        {
          offset: 1,
          color: '#00D5FF'
        }
      ]
    }
  },
  {
    label: t('message.analysisDetails.polarityOption[1]'), // 负面
    value: EpolarityType.FU_MIAN,
    color: {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 0,
      colorStops: [
        {
          offset: 0,
          color: 'rgba(234, 185, 102, 0)'
        },
        {
          offset: 1,
          color: '#EAB966'
        }
      ]
    }
  },
  {
    label: t('message.analysisDetails.polarityOption[2]'), // 中立
    value: EpolarityType.ZHONG_LI,
    color: {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 0,
      colorStops: [
        {
          offset: 0,
          color: '#10274B'
        },
        {
          offset: 1,
          color: '#1978E5'
        }
      ]
    }
  }
];
// 获取情感名称
export const getPolarityName = (polarity: EpolarityType) => {
  return (
    polarityOption.find(item => {
      return item.value == polarity;
    })?.label || '未知'
  );
};

// 获取情感颜色
export const getPolarityColor = (polarity: EpolarityType) => {
  return (
    polarityOption.find(item => {
      return item.value == polarity;
    })?.color || ''
  );
};

export enum EArticleType {
  YUAN_CHUANG = '1',
  ZHUAN_FA = '2',
  PING_LUN = '3',
  DUI_HUA = '0'
}
// 文章类型选项
export const articleTypeOption: IOptionItem[] = [
  {
    label: t('message.analysisDetails.articleTypeOption[0]'),
    value: EArticleType.YUAN_CHUANG
  }, // 原文
  {
    label: t('message.analysisDetails.articleTypeOption[1]'),
    value: EArticleType.ZHUAN_FA
  }, // 转发
  {
    label: t('message.analysisDetails.articleTypeOption[2]'),
    value: EArticleType.PING_LUN
  }, // 评论
  {
    label: t('message.analysisDetails.articleTypeOption[3]'),
    value: EArticleType.DUI_HUA
  } // 对话
];

// 获取文章类型
export const getArticleTypeName = (type: EArticleType) => {
  return (
    articleTypeOption.find(item => {
      return item.value == type;
    })?.label || '未知'
  );
};

export type realTime = 0 | -1 | 1 | 3 | 7 | 30;
const formatStr = 'YYYY-MM-DD HH:mm:ss';
// 将realTime转为日期数组
export const transRealTime = (realTime: realTime) => {
  if (realTime === 0) {
    return [];
  } else if (realTime === -1) {
    return [
      dayjs().startOf('day').format(formatStr),
      dayjs().format(formatStr)
    ];
  } else {
    return [
      dayjs().subtract(realTime, 'day').format(formatStr),
      dayjs().format(formatStr)
    ];
  }
};

export const rangeShortcuts = [
  {
    label: '今天',
    value: () => transRealTime(-1),
    realTime: -1
  },
  {
    label: '近24小时',
    value: () => transRealTime(1),
    realTime: 1
  },
  {
    label: '近7天',
    value: () => transRealTime(7),
    realTime: 7
  },
  {
    label: '近30天',
    value: () => transRealTime(30),
    realTime: 30
  }
];

// 复制文字到剪贴板
export const copyToClip = (text: string) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  window.document.body.appendChild(textarea);
  textarea.select();
  window.document.execCommand('copy');
  textarea.remove();
  return true;
};

// 判断是否是中文
export const isChinese = (str: string) => {
  str = str.replace(/\s/g, '');
  const patthrn = /^[\u4e00-\u9fa5]+$/i;
  if (!patthrn.test(str)) {
    return false;
  } else {
    return true;
  }
};
// 根据某字段排序
export const getSort = (data: any, key: string) => {
  return data
    .map((el: any) => {
      const { children, ...rest } = el;
      const temp = { ...rest };
      return children ? { ...temp, children: getSort(children, key) } : temp;
    })
    .sort((a: any, b: any) => a[key] - b[key]);
};

// 去除内容中的html标签
export const removeHTML = (str: string) => {
  if (!str) {
    return '';
  }
  let newStr = str;
  const reg = /<\/?[^>]+>/g;
  const reg2 = /&nbsp;/g;
  if (reg.test(str)) {
    reg.lastIndex = 0;
    newStr = newStr.replace(reg, '');
  }
  newStr = newStr.replace(reg2, '');
  return newStr;
};
// 媒体标签
export const mediaTagsOption: IOptionItem[] = [
  { label: '不限', value: 0 },
  { label: '18家中央新闻单位', value: '18家中央新闻单位' },
  { label: '全球48家主流媒体', value: '全球48家主流媒体' },
  { label: '香港主流媒体', value: '香港主流媒体' },
  { label: '台湾主流媒体', value: '台湾主流媒体' },
  { label: '澳门主流媒体', value: '澳门主流媒体' },
  { label: '省级媒体', value: '省级媒体' },
  { label: '全球智库', value: '全球智库' }
];

// 遍历树形数组，获取属性key对应的属性值为value的某一项
export const deepQuery = (tree: any, key: string, value: any) => {
  let retNode = {} as any,
    isGet = false;
  function deepSearch(tree: any, key: string, value: any) {
    for (let i = 0; i < tree.length; i++) {
      if (tree[i].children && tree[i].children.length > 0) {
        deepSearch(tree[i].children, key, value);
      }
      if (value === tree[i][key] || isGet) {
        !isGet && (retNode = tree[i]);
        isGet = true;
        break;
      }
    }
  }
  deepSearch(tree, key, value);
  return retNode;
};

// 随机字符串
export const getRandomStr = (length = 6) => {
  const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i)
    result += str[Math.floor(Math.random() * str.length)];
  return result;
};

export const mediaTagsConfig: IOptionItem[] = [
  { label: t('message.analysisDetails.unlimit'), value: '-1' }, // 不限
  { label: '18家中央新闻单位', value: '18家中央新闻单位' },
  { label: '全球48家主流媒体', value: '全球48家主流媒体' }
];

export const polarityConfig: IOptionItem[] = [
  { label: t('message.analysisDetails.all'), value: -1 }, // 全部
  { label: t('message.analysisDetails.polarityOption[0]'), value: 1 }, // 正面
  { label: t('message.analysisDetails.polarityOption[1]'), value: 2 }, // 负面
  { label: t('message.analysisDetails.polarityOption[2]'), value: 0 } // 中立
];

export const dataFieldOption: IOptionItem[] = [
  { label: '实时热点', value: 1 },
  { label: '全部热点', value: 0 }
];

export const addClass = (ele: Element, name: string) => {
  if (!ele) return;
  const classArr = ele.className.split(' ');
  if (classArr.includes(name)) return;
  ele.className
    ? (ele.className = ele.className + ' ' + name)
    : (ele.className = name);
};

export const removeClass = (ele: Element, name: string) => {
  if (!ele) return;
  const classArr = ele.className.split(' '),
    index = classArr.indexOf(name);
  index > -1 && classArr.splice(index, 1);
  ele.className = classArr.join(' ');
};
//获取权限
export const getUserInfoPermissionItem = (key: string) => {
  const userPermission = JSON.parse(Local.get('userInfoPermission'));
  if (userPermission) {
    return userPermission?.includes(key);
  }
};

export const dataSourceIcon = (sourceType: string) => {
  let icon = 'qita';
  switch (sourceType) {
    case '网站':
      icon = 'wangzhan';
      break;
    case '网媒':
      icon = 'wangzhan';
      break;
    case '微信':
      icon = 'weixin';
      break;
    case '微博':
      icon = 'weibo';
      break;
    case '短视频':
      icon = 'duanshipin';
      break;
    case '视频网站':
      icon = 'shipinwangzhan';
      break;
    case 'APP':
      icon = 'APP';
      break;
    case '电子报刊':
      icon = 'dianzibaokan';
      break;
    case '论坛贴吧':
      icon = 'luntantieba';
      break;
    case '百度贴吧':
      icon = 'baidutieba';
      break;
    case 'Twitter':
    case 'X(Twitter)':
      icon = 'Twitter';
      break;
    case 'Facebook':
      icon = 'Facebook';
      break;
    case 'Instagram':
      icon = 'instagram';
      break;
    case 'Telegram':
      icon = 'telegram';
      break;
    case '电视视频':
      icon = 'dianshishipin';
      break;
  }
  return icon;
};
// 获取search渠道名称
export const getSearchSourceName = (code: string) => {
  return (
    useDictStore().mediaTypeList.find(item => {
      return item.value.includes(code);
    })?.label || '未知'
  );
};
export const getDataSourceIcon = (sourceType = '00101') => {
  const name = getSearchSourceName(sourceType);
  return new URL(
    `/src/assets/dataSourceIcon/${dataSourceIcon(name)}.svg`,
    import.meta.url
  ).href;
};
export const getAppVersion = () => {
  return localStorage.getItem('XINGHE_CLOUD_UI_APP_VERSION') as string;
};
export const setAppVersion = (version: string) => {
  localStorage.setItem('XINGHE_CLOUD_UI_APP_VERSION', version);
};
// 检查服务端是否已经更新，如果更新刷新页面

export async function checkAppNewVersion() {
  console.log('checkAppNewVersion');
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  // 带参数是为了拿到最新的数据
  const url = `${import.meta.env.VITE_HOST_URL}version.json?t=${Date.now()}`;
  let res = null;
  try {
    res = await axios.get(url);
  } catch (err) {
    console.error('checkAppNewVersion error: ', err);
  }
  if (!res) return;
  const version = res.data.version;
  const localVersion = getAppVersion();
  if (!res?.data?.UPDATE_MESAGE_TIP) {
    return;
  }
  if (localVersion && localVersion == version) {
    return;
  }
  if (localVersion && localVersion !== version) {
    // ElMessageBox.alert(
    //   res?.data?.UPDATE_MESAGE,
    //   '晴天·多语言分析平台迭代升级',
    //   {
    //     dangerouslyUseHTMLString: true,
    //     confirmButtonText: '立即更新',
    //     showClose: false,
    //     type: 'info',
    //     center: true,
    //     callback: () => {
    //       setAppVersion(version);
    //       window.location.reload(true);
    //     }
    //   }
    // );
    setAppVersion(version);
    window.location.reload(true);
  }
  setAppVersion(version);
}

/**
 * 根据路由判断页面按钮显隐
 * @param route 可以使用该功能按钮模块的路由
 */
export const hasRoleByRoute = (route: string) => {
  const storesRoutesList = useRoutesList(pinia);
  const routes = storesRoutesList.routesList.map(route => route.path);
  return routes.includes(route);
};
export function generateUUID() {
  let d = new Date().getTime();
  let d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}
export const getUserSearchDaysRange = (key: string) => {
  const userPermission = JSON.parse(Local.get('userInfoPermission'));
  if (userPermission && userPermission?.includes(key)) {
    return 180;
  } else {
    return 92;
  }
};
// 获取浏览器名称和版本
export const getBrowserInfo = () => {
  const ua = navigator.userAgent;
  let name, version;
  if (ua.indexOf('Edg/') > -1) {
    name = 'Edge';
    console.log(ua.match(/Edg\/([\d.]+)/));
    version = ua.match(/Edg\/([\d.]+)/)[1].match(/^(\d+)/)[1];
  } else if (ua.indexOf('Firefox') != -1) {
    name = 'Firefox';
    version = ua.match(/Firefox\/([\d.]+)/)[1];
  } else if (ua.indexOf('Chrome') != -1 && ua.indexOf('Safari') != -1) {
    name = 'Chrome';
    version = ua.match(/Chrome\/([\d.]+)/)[1];
  } else if (ua.indexOf('Safari') != -1 && ua.indexOf('Chrome') == -1) {
    name = 'Safari';
    version = ua.match(/Version\/([\d.]+)/)[1];
  } else if (ua.indexOf('Edge') != -1) {
    name = 'Edge';
    version = ua.match(/Edge\/([\d.]+)/)[1];
  } else if (ua.indexOf('Opera Mini') != -1) {
    name = 'Opera Mini';
    version = ua.match(/Opera Mini\/([\d.]+)/)[1];
  } else if (ua.indexOf('Opera') != -1) {
    name = 'Opera';
    version = ua.match(/Opera\/([\d.]+)/)[1];
  } else if (ua.indexOf('MSIE') != -1) {
    name = 'IE';
    version = ua.match(/MSIE ([\d.]+)/)[1];
  } else {
    name = 'unknown';
    version = '0.0';
  }

  return {
    name: name,
    version: version
  };
};
