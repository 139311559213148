import { defineStore } from 'pinia';
import { useDictApi } from '/@/api/analyst/dict';

interface SourceItem {
  name: string;
  value: string;
}
interface dataSourceState {
  dataSourceOption: SourceItem[];
}
export const useDataSourceStore = defineStore('dataSourceStore', {
  state: (): dataSourceState => ({
    dataSourceOption: []
  }),
  actions: {
    async getDataSourceOption() {
      try {
        const res = await useDictApi().getDataSourceOption();
        this.dataSourceOption.push(...res.data.allChannelMapping);
      } catch (error) {}
    }
  }
});
