// 定义内容
export default {
  searchForm: {
    placeholder1: 'Please enter keyword search',
    placeholder2: 'Enter search content',
    placeholder3: 'Enter a keyword, and press the Enter key to confirm it',
    placeholder4: 'Enter a keyword',
    mode1: 'Expression',
    mode2: 'Keyword',
    mode3: 'Cloud Search',
    mode4: 'Full Text',
    mode5: 'Title',
    mode6: 'Body Text',
    mode7: 'Title Text',
    search: 'Search',
    search1: 'Search',
    transferAnalysis: 'Transfer Analysis',
    turntofmonitor: 'Turnt of monitor',
    example: 'Example',
    tipTtitle1: `1.The formula can include the relationship between "and", "or", and "not", using "&" to represent "and", "{'|'}" for "or", and "!" for "not";`,
    tipTtitle2: `2.Each group of keywords in the formula is included with "()". Example: Beijing&(Winter Olympics{'|'}Winter Paralympic Games)!(Park).`,
    modeSwitchTip:
      'Converting to Expression Search Mode is currently Expression Search. Switching to Keyword Search will clear the entered keywords. Do you want to switch?',
    rememberChoice: `Remember my choice, don't remind me again next time`,
    note: 'Note',
    noteTip:
      'Please be informed that if you switch from expression search mode to keyword search mode, the content in the search box will not be retained.',
    notPrompt: `Don't prompt again in the future`,
    close: 'close',
    moreActions: 'More Actions',
    saveThemeTip: 'Please save the theme before continuing!',
    regionSwitchTip:
      'After modifying the data permissions, the optional items of some filter criteria will also be changed, and all selected content will be reset. Are you sure to modify?',
    domestic: 'Mainland',
    abroad: 'Overseas',
    chooseRegionTip: 'Select at least one data permission!',
    filterCriteria: 'Filter Criteria',
    pleaseChoose: 'Please select',
    reset: 'Reset',
    positive: 'Positive',
    negative: 'Negative',
    neutral: 'Neutral',
    irrelevant: 'Irrelevant',
    toAnalyzed: 'To be analyzed',
    today: 'Today',
    unlimited: 'No Limit',
    nearly24hours: 'Past 24 Hours',
    nearly3days: 'Past 3 Days',
    nearly7days: 'Past 7 Days',
    nearly14days: 'Past 14 Days',
    nearly30days: 'Past 30 Days',
    nearly3months: 'Past 3 Months',
    custom: 'Custom',
    original: 'Original Text',
    repost: 'Retweet',
    comment: 'Comment',
    all: 'All',
    agencyCertification: 'Verified Org Account',
    personalAuthentication: 'Verified Personal Account',
    unauthorized: 'Unverified',
    corporate: 'Corporate',
    other: 'other',
    minfancount: 'Min',
    maxfancount: 'Max',
    more: 'More',
    packup: 'Show Less',
    showMore: 'Show More',
    pubtime: 'Time of Publication',
    warehousetime: 'Retrieval Time',
    matchMethods: 'Text Position',
    mediaType: 'Media type',
    polarity: 'Sentiment',
    languages: 'Language Type',
    mediaTags: 'Recommended Tags',
    areaIds: 'Region Identification',
    authTypes: 'Account Verification',
    fansCount: 'Follower Quantity',
    articleType: 'Data type',
    keywordsTip: 'Keyword cannot be repeated',
    keywordsTip2:
      'The number of simple and complex matching keywords selected will exceed the keyword limit. Please delete some keywords first!',
    keywordsTip3: 'The number of keywords should not exceed {count}',
    keywordsTip4: 'Please fill in the correct formula',
    keywordsTip5:
      'Keyword search mode input cannot contain &, {`|`} (,) Symbol',
    standardFuzzy: ['Exact', 'Fuzzy'],
    standardFuzzyLabel: 'Exact/Fuzzy',
    confirm: 'Confirm',
    cancel: 'Cancel',
    notes: [
      `1. An expression can contain logic operators "&", "{'|'}", and "!" that stand for AND, OR, and NOT relationships respectively.`,
      `2. Each set of keywords must be enclosed in the brackets "()". A pair of brackets cannot contain 2nd-level brackets or more than one keyword set.`,
      `3. In the keyword search mode, an input cannot contain symbols like "&", "{'|'}", "!", "(", ")".`,
      `4. In the expression search mode, each keyword set can only contain any one of "&" or "{'|'}". If there are more than 2 keyword sets, "{'|'}" cannot be used together with "!" or "&" among keyword sets. Only the OR relationship can exist among multiple expressions or keyword sets. Press the Enter key to enter the next set of keywords or expressions.`
    ],
    examples: [
      `(Beijing&Olympic Winter Games)&(Skiing{'|'}Skating{'|'}Ice Hockey{'|'}Luge)!(Bobsleigh{'|'}Curling)`,
      `(China{'|'}Beijing)&(High-tech Winter Olympics{'|'}Snow Tech)`
    ],
    topBtns: [
      'Add Analysis',
      'Add Monitor',
      'LLM-based Report',
      'Visual Dashboard'
    ],
    timeControl: ['Select the start time', 'Select the end time', 'to'],
    infoMatch: 'Info Match',
    dataType: 'Data Type',
    textPosition: 'Text Position',
    Sentiment: 'Sentiment',
    InfoClassification: 'Info Classification',
    STCTMatch: 'SC/TC Match',
    dialogue: 'Dialogue',
    mediaScope: 'Media Scope',
    mediaTag: 'Media Tag',
    specifiedMedia: 'Specified Media',
    mediaLevel: 'Media Level',
    mediaLevelTips: [
      'The most globally influential media organizations featuring wide coverage, fast transmission, and a large group of audience. The major examples are TV stations, radio stations, newspapers, magazines, and news websites.',
      'The media organizations that are highly famous, authoritative, and influential in a certain domain, with their reports outstanding in terms of depth and breadth.',
      'The media organizations that are professional and have a user base in a certain domain.',
      'The media organizations that are directly or indirectly controlled or sponsored by governments.'
    ],
    mediaRegion: 'Media Region',
    organizationType: 'Organization Type',
    accountProperty: 'Account Property',
    regionOrLanguage: 'Region/Language',
    advancedFilter: 'Advanced Filter',
    fansList: [
      '0-10,000',
      '10,000-100,000',
      '100,000-1,000,000',
      'More than 1,000,000'
    ],
    areaList: ['IP Location', 'Media Location', 'Mentions'],
    noKeywordOrMeidasTip: 'Please enter keywords or select media scope',
    zhandianfanwei: 'Site Wide',
    siteWideList: ['All', 'Paid sites', 'Non paid sites'],
    customizeTags: 'Customize tags',
    commonTags: 'Common tags',
    noTags: 'There are currently no relevant tags available',
    eliminate: 'eliminate',
    ExcludedInformation: 'Excluded Information',
    eliminateMsg1:
      '1. Select multiple articles on the current page that need to be eliminated by batch, and click the [Eliminate] button to remove the selected articles from the corresponding topics;',
    eliminateMsg2:
      '2. The eliminated data can be viewed and un-eliminated in the [Excluded Information].',
    eliminateMsg3:
      'Similar information was de-duplicated across channels, but media channels did not de-duplicated within channels.',
    YayiAnalysis: 'Yayi Analysis',
    AppendChat: 'Append chat',
    AppendBriefs: 'Append briefs'
  },
  searchScheme: {
    savedScheme: 'Saved Topics',
    placeholderEnterTopicName: 'Enter the topic name',
    placeholderEnterTopicName1: 'Enter the topic name',
    topicName: 'Topic name',
    lastModifiedDate: 'Last modified date',
    saveBtn: 'Save',
    saveNewTheme: 'Record Save As',
    searchTopics: 'Topic',
    enterName: 'Enter a topic',
    noFilterData: 'No filtering results',
    deleteSchemeTip: 'Confirm delete the topic',
    deleteSchemeChildTip: 'All subtopics will also be deleted',
    cancel: 'Cancel',
    confirm: 'Confirm',
    tip: 'Note',
    transferAnalysisSuccTip: 'Transfer analysis succeeded!',
    viewLater: 'Check later',
    gocheck: 'Check now',
    createAnalysis: 'Create Analysis',
    createSubthemes: 'Create Subtopic',
    rename: 'Rename',
    delete: 'Delete',
    emptyThemeName: 'Topic name cannot be empty',
    themeNameLimit: 'The length of the topic name cannot exceed 20 characters',
    createTheme: 'Create Topic',
    createFolder: 'Create Folder',
    deleteTheme: 'Delete topic',
    createThemeSucessTip: 'Topic created successfully',
    createThemeFailed: 'Topic creation failed',
    saveThemeSucc: 'Topic saved successfully',
    saveThemeFailed: 'Failed to save the theme',
    nameModifiedSucc: 'Name modified successfully',
    nameModifiedFailed: 'Name modification failed',
    deleteSucceeded: 'Delete succeeded',
    deleteFailed: 'Delete failed',
    keywordEmpty: 'Keyword cannot be empty',
    operationSucceeded: 'Operation succeeded',
    operationFailed: 'Operation failed',
    createFailed: 'Creation failed',
    createTopic: 'Create Topic',
    duplicate: 'Duplicate',
    placeholderEnterTopicName2: 'Enter the folder name',
    addedSuccess: 'Added successfully.'
  },
  searchResult: {
    mode1: 'Expression mode',
    mode2: '关键词',
    mode3: '搜索',
    modeSW1: 'Convert to keyword search mode',
    modeSW2: 'Convert to expression search mode',
    inputRich1: 'Required keyword(s)',
    inputRich2: 'Include any of',
    inputRich3: 'Exclude',
    allCheck: '全选',
    multipleSort: '综合排序',
    timeSort: '时间排序',
    hotSort: '热度',
    commonSort: '评论量',
    downLoad: '下载',
    checked: '已选',
    downConfirm: '确定下载',
    confirm: '确认',
    custom: '自定义',
    cancel: '取消',
    moreFilters: 'More',
    full: '全文',
    all: '全部',
    duplicate: '去重',
    notDuplicate: '不去重',
    drowTitle: '标题/Title(更多筛选)',
    Release: '发布渠道',
    clearAll: 'Clear all'
  },
  designatedMediaLabelId: {
    saveBtn: 'Save',
    cancel: 'Cancel',
    addMediaTip: '添加媒体',
    addMediaList: '添加媒体组'
  },
  searchSpecifiedMedia: {
    centralMedia: 'Central Media',
    businessPortal: 'Business Portal',
    selectedMedia: 'Selected Media',
    selectMedia: 'Select Media',
    clear: 'Clear',
    noMediaTip: [
      'No media selected. You can click ',
      'Select Media',
      ' to add.'
    ],
    forfiltering: 'For filtering',
    include: 'Include',
    exclude: 'Exclude',
    mediaGroup: 'Media Group',
    mediaGroupPlaceholder: 'Enter the media group name',
    saveAndApply: 'Save and apply',
    mediaSearchPlaceholder: 'Search and add',
    addAll: 'Add all',
    searchWithinaWebsite: 'Search Within a Website',
    nodata: 'No data available',
    search: 'Search',
    advancedSearchPlaceholder:
      'Enter the website name or domain name, for example, sina.cn',
    advancedSearch: 'Advanced Search',
    loadMoreText: ['loading', 'Up stroke loads more', 'No more.'],
    searchTip:
      'You can add any media channels by using the "Search" field in the upper right corner.',
    addNewGroup: 'Save to a new media group',
    mediaGroupBtn: [
      'Create Group',
      'Edit Group',
      'Enter the group name',
      'Delete Group',
      'After the group is deleted, it cannot be retrieved. Are you sure you want to delete this group?'
    ],
    channelType: ['Site', 'Channel/Group', 'Account'],
    mediaGrouPlaceholder: 'Enter group name',
    masterStation: 'main site',
    subStation: 'sub site'
  },
  AnalysisPreview: {
    commentCount: 'comments',
    likeCount: 'likes',
    watchCount: 'current views',
    readCount: 'views',
    rttCount: 'reposts'
  },
  resultIntelligentWordFilling: ['Smart Fill']
};
