import { reactive, ref } from 'vue';
import { i18n } from '/@/i18n/index';
import { getUserInfoPermissionItem } from '/@/utils/other';
const t = i18n.global.t;
//命中位置
const matchList = reactive([
  { name: t('message.searchForm.mode7'), id: 1 }, // 标题正文
  { name: t('message.searchForm.mode5'), id: 6 }, // 标题
  { name: t('message.searchForm.mode6'), id: 7 }, // 正文
  { name: 'OCR', id: 2 } //OCR
]);
const emotion = reactive([
  { name: t('message.searchForm.positive'), id: 1 }, // 正面
  { name: t('message.searchForm.neutral'), id: 0 }, // 中立
  { name: t('message.searchForm.negative'), id: 2 } // 负面
]);
const subjectEmotion = reactive([
  { name: t('message.searchForm.positive'), id: 1 }, // 正面
  { name: t('message.searchForm.neutral'), id: 0 }, // 中立
  { name: t('message.searchForm.negative'), id: 2 }, // 负面
  { name: t('message.searchForm.irrelevant'), id: 3 }, // 无关
  { name: t('message.searchForm.toAnalyzed'), id: 4 } // 待分析-失败
]);
const mediaType = ref<any>([]);
const information = ref<any>([]);
const timeList = reactive([
  { name: t('message.searchForm.today'), id: -1 }, // 今天
  { name: t('message.searchForm.nearly24hours'), id: 1 }, // 近24小时
  { name: t('message.searchForm.nearly3days'), id: 3 }, // 近3天
  { name: t('message.searchForm.nearly7days'), id: 7 }, // 近7天
  { name: t('message.searchForm.nearly30days'), id: 30 }, // 近30天
  { name: t('message.searchForm.nearly3months'), id: 92 }, // 近3个月
  { name: t('message.searchForm.custom'), id: '0' } // 自定义
]);
const warehouseTime = reactive([
  { name: t('message.searchForm.unlimited'), id: 99 }, // 不限
  { name: t('message.searchForm.today'), id: -1 }, // 今天
  { name: t('message.searchForm.nearly24hours'), id: 1 }, // 近24小时
  { name: t('message.searchForm.nearly7days'), id: 7 }, // 近7天
  { name: t('message.searchForm.nearly30days'), id: 30 }, // 近30天
  { name: t('message.searchForm.custom'), id: '0' } // 自定义
]);
const dataType = reactive([
  { name: t('message.searchForm.original'), id: 1 }, // 原文
  { name: t('message.searchForm.repost'), id: 2 }, // 转发
  { name: t('message.searchForm.comment'), id: 3 }, // 评论
  { name: t('message.searchForm.dialogue'), id: 0 } // 对话
]);
const dataTypeWarn = reactive(
  getUserInfoPermissionItem('system:user:comment-warning')
    ? [
        { name: t('message.searchForm.original'), id: 1 }, // 原文
        { name: t('message.searchForm.repost'), id: 2 }, // 转发
        { name: t('message.searchForm.comment'), id: 3 }, // 评论
        { name: t('message.searchForm.dialogue'), id: 0 }, // 对话
        { name: t('message.filter.codeType4'), id: -99 } // 发文评论
      ]
    : [
        { name: t('message.searchForm.original'), id: 1 }, // 原文
        { name: t('message.searchForm.repost'), id: 2 }, // 转发
        { name: t('message.searchForm.comment'), id: 3 }, // 评论
        { name: t('message.searchForm.dialogue'), id: 0 } // 对话
      ]
);
const removalList = reactive([
  { name: '去重', id: 1 }, // 去重
  { name: '不去重', id: 0 } //
]);
const fansList = reactive([
  { name: t('message.searchForm.all'), id: '0', fromFan: '', toFan: '' }, //全部
  {
    name: t('message.searchForm.fansList[0]'),
    id: '1',
    fromFan: 0,
    toFan: 10000
  }, //0-1万
  {
    name: t('message.searchForm.fansList[1]'),
    id: '2',
    fromFan: 10000,
    toFan: 100000
  }, //1-10万
  {
    name: t('message.searchForm.fansList[2]'),
    id: '3',
    fromFan: 100000,
    toFan: 1000000
  }, //10-100万
  {
    name: t('message.searchForm.fansList[3]'),
    id: '4',
    fromFan: 1000000,
    toFan: ''
  } // 100万以上
]);
const accountAuthList = reactive([
  { name: t('message.searchForm.unauthorized'), id: '0' }, // 未认证'
  { name: t('message.searchForm.personalAuthentication'), id: '1' }, // 个人认证
  { name: t('message.searchForm.agencyCertification'), id: '2' }, // 机构认证
  { name: t('message.searchForm.corporate'), id: '3' }, // 公司企业
  { name: t('message.searchForm.other'), id: '4' } // 其他
]);
const queryTypeList = reactive([
  { name: t('message.searchForm.standardFuzzy[0]'), id: '0' }, // 精准'
  { name: t('message.searchForm.standardFuzzy[1]'), id: '1' } // 模糊'
]);
const ipAreaList = reactive([
  {
    id: 0,
    name: '境内',
    children: []
  },
  {
    id: 1,
    name: '境外',
    children: []
  }
]);
const sitePaidList = reactive([
  { name: t('message.searchForm.siteWideList[0]'), id: -1 }, // 全部站点
  { name: t('message.searchForm.siteWideList[1]'), id: 1 }, // 付费站点
  { name: t('message.searchForm.siteWideList[2]'), id: 0 } // 非付费站点
]);
export function useFilter() {
  return {
    information,
    timeList,
    dataType,
    dataTypeWarn,
    matchList,
    mediaType,
    emotion,
    fansList,
    removalList,
    accountAuthList,
    ipAreaList,
    queryTypeList,
    warehouseTime,
    subjectEmotion,
    sitePaidList
  };
}
