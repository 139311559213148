<script lang="ts" setup>
import { init } from 'echarts';
import {
  onBeforeUnmount,
  onMounted,
  ref,
  shallowRef,
  watch,
  inject
} from 'vue';
import { ECharts } from 'echarts';
import type { ThemeKey, ECOption } from '../utils/buildChart';

const chartRef = ref<HTMLDivElement | null>(null);
// ref echarts 实例会使整个实例变成Proxy对象，resize时会丢失一些数据
// 这里使用 shallowRef
let chart = shallowRef<ECharts | null>(null);

interface HighLightType {
  by:
    | 'seriesIndex'
    | 'seriesId'
    | 'seriesName'
    | 'dataIndex'
    | 'name'
    | 'geoIndex'
    | 'geoId'
    | 'geoName';
  value: (string | number)[];
}

const props = defineProps<{
  option: ECOption;
  theme?: ThemeKey;
  preventResize?: boolean;
  defaultHighLight?: HighLightType;
  bigscreenTheme?: String;
}>();
const option = ref<ECOption>(props.option);
const setExtraOption = () => {
  if (option.value?.legend && props.bigscreenTheme) {
    option.value.legend['pageIconColor'] = '#aaa';
    option.value.legend['pageIconInactiveColor'] = '#2f4554';
    option.value.legend['pageTextStyle'] = { color: '#aaa' };
  }
};
setExtraOption();

const setDefaultHight = () => {
  if (props.defaultHighLight) {
    const config = {
      type: 'highlight',
      [props.defaultHighLight.by as unknown as symbol]:
        props.defaultHighLight?.value
    };
    chart.value?.dispatchAction(config);
  }
};

const resize = () => {
  if (!props.preventResize) {
    chart.value?.resize();
    setDefaultHight();
  }
};
watch(
  () => props.option,
  val => {
    option.value = val;
    setExtraOption();
    chart.value?.setOption(option.value);
  }
);
const anaSearch = inject('anaSearch', undefined);
const chartsClick = inject('chartsClick', undefined);
const componentDrillType = inject('componentDrillType', undefined);
onMounted(() => {
  try {
    const chartIns = init(
      chartRef.value as HTMLDivElement,
      props.theme || 'theme1'
    );
    chart.value = chartIns;
    chartIns.setOption(option.value);
    setDefaultHight();
    window.addEventListener('resize', resize);
    chartIns.on('click', params => {
      if (chartsClick) {
        let chartsData = params;
        chartsData.componentDrillType = componentDrillType;
        anaSearch(chartsData);
      }
    });
  } catch (e) {
    // console.error(e)
  }
});
onBeforeUnmount(() => {
  chart.value?.dispose();
  window.removeEventListener('resize', resize);
});

defineExpose({
  chart: chart
});
</script>
<template>
  <div class="wh-chart" ref="chartRef"></div>
</template>
<style lang="scss" scoped>
@import '../style/index';
</style>
