import type { App, DirectiveBinding, VNode } from 'vue';
import { RootPermission } from '../permission';

const usePermissionDirective = (app: App) => {
  const commentNode = (el: HTMLElement, vnode: VNode) => {
    const comment = document.createComment('');
    vnode.el = comment;
    vnode.dirs = [];
    vnode.type = '';
    (vnode as any).ctx = null;
    vnode.component = null;
    el.parentNode?.replaceChild(comment, el);
  };

  const parseBinding = (binding: DirectiveBinding) => {
    if (binding.value !== undefined) return binding.value;
    const component = binding.instance;
    let permission = (component as any).permission || RootPermission;

    const mode = binding.arg;
    const modifiers = binding.modifiers;
    let isPassed = true;
    if (mode) {
      permission = permission[mode];
    }
    if (!permission) return isPassed;
    for (const key in modifiers) {
      const p = permission[key];
      isPassed = isPassed && (p !== undefined ? p : true);
    }
    return isPassed;
  };

  const mark = (el: HTMLElement) => {
    el.style.display = 'none !important';
  };

  app.directive('perm', {
    beforeMount(el, binding) {
      if (!parseBinding(binding)) {
        mark(el);
      }
    },
    mounted(el, binding, vnode) {
      if (!parseBinding(binding)) {
        commentNode(el, vnode);
      }
    },
    updated(el, binding, vnode) {
      if (!parseBinding(binding)) {
        commentNode(el, vnode);
      }
    },
    beforeUpdate(el, binding) {
      if (!parseBinding(binding)) {
        mark(el);
      }
    }
  });
};

export default usePermissionDirective;
