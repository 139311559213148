import type { App } from 'vue';

import ConfigGroup from './ConfigGroup.vue';
import ConfigCascader from './ConfigCascader.vue';
import ConfigInput from './ConfigInput.vue';
import ConfigSelect from './ConfigSelect.vue';
import ConfigSwitch from './ConfigSwitch.vue';
import ConfigRadio from './ConfigRadio.vue';
import ConfigColorSelect from './ConfigColorSelect.vue';
import ConfigRealtimeRangePicker from './ConfigRealtimeRangePicker.vue';
import ConfigSpecifyArticleButton from './ConfigSpecifyArticleButton.vue';
import ConfigRadioOption from './ConfigRadioOption.vue';
import ConfigLinkedNumber from './ConfigLinkedNumber.vue';
import ConfigCardRadio from './ConfigCardRadio.vue';
import ConfigDatePicker from './ConfigDatePicker.vue';

const configFormItems = [
  ConfigGroup,
  ConfigCascader,
  ConfigInput,
  ConfigSelect,
  ConfigSwitch,
  ConfigRadio,
  ConfigColorSelect,
  ConfigRealtimeRangePicker,
  ConfigSpecifyArticleButton,
  ConfigRadioOption,
  ConfigLinkedNumber,
  ConfigCardRadio,
  ConfigDatePicker
].map(c => {
  return (c.install = (app: App): void => {
    app.component(c.name, c);
  });
});

export default configFormItems;
