<script lang="ts" setup>
import {
  onBeforeUnmount,
  onMounted,
  shallowRef,
  watchEffect,
  nextTick
} from 'vue';
import type { ShallowRef, Ref } from 'vue';
import BaseChart from '../BaseChart/BaseChart.vue';
import { ECOption } from '../utils';
import useChartClick from '../hooks/useChartClick';
import { debounce } from 'lodash-es';

interface CardDatum {
  name: string;
  value: number | string;
  formatter?: (val: number | string) => string;
  icon?: string;
  color?: string;
}

interface CardChartProps {
  data: CardDatum[];
}

interface Emits {
  (e: 'chart:click', params: any): void;
}

const props = defineProps<CardChartProps>();

const option: ShallowRef<ECOption> = shallowRef({});

const emit = defineEmits<Emits>();
const chartRef = useChartClick(emit) as Ref<InstanceType<typeof BaseChart>>;
const size = shallowRef(Symbol());
const resize = debounce(() => {
  size.value = Symbol();
}, 100);

watchEffect(() => {
  // 收集size依赖
  size.value;

  const data = props.data;
  if (!data) return;
  const dom = chartRef.value?.$el as HTMLDivElement;
  if (!dom) return;
  // const { width, height } = dom.getBoundingClientRect()
  let width = dom.clientWidth;
  let height = dom.clientHeight;
  const computedStyle = window.getComputedStyle(dom);
  width =
    width -
    parseFloat(computedStyle.paddingLeft) -
    parseFloat(computedStyle.paddingRight);
  height =
    height -
    parseFloat(computedStyle.paddingTop) -
    parseFloat(computedStyle.paddingBottom);
  const len = data.length;
  if (len <= 0) {
    option.value = {};
    return;
  }
  const margin = 20;
  const cardWidth = width / 4 - margin,
    cardHeight = 192;
  const top = height / 2 - cardHeight / 2;
  const gap = (width - 2 * margin - cardWidth * len) / (len - 1 || 1);
  const iconNames = [
    'comment',
    'emoji',
    'like',
    'play',
    'reading',
    'share'
  ] as const;
  const colors = [
    '#007cff',
    '#fe7770',
    '#ff9030',
    '#b45bf3',
    '#6093ff',
    '#00cb9b'
  ];
  const fontChange = (size: number) => {
    let change = 0;
    if (cardWidth < 60) change = 8;
    if (cardWidth < 100 && cardWidth >= 60) change = 4;
    return size - change + 'px';
  };
  const color = (index: number) => {
    return colors[index % colors.length];
  };

  const bgImage = (index: number) => {
    const images = iconNames;
    const image = images[index % images.length];
    const href = new URL('./images/' + image + '-count-bg.png', import.meta.url)
      .href;
    return href;
  };

  const textWidth = (text: string, font: string) => {
    context.save();
    context.font = font;
    const metric = context.measureText(text);
    context.restore();
    return metric.width;
  };

  const getIcon = (icon: typeof iconNames[number]) => {
    let url: string = icon;
    if (iconNames.indexOf(icon) > -1) {
      url = icon + '-count.png';
      return new URL('./images/' + url, import.meta.url).href;
    }
    return url;
  };

  const context = document
    .createElement('canvas')
    .getContext('2d') as CanvasRenderingContext2D;
  const groups = data.map((d, i) => {
    const formatter = d.formatter || ((val: number | string) => val + '');
    return {
      type: 'group',
      children: [
        {
          type: 'group',
          width: cardWidth,
          height: cardHeight,
          left: (gap + cardWidth) * i,
          children: [
            {
              type: 'image',
              style: {
                image: d?.bg || bgImage(i),
                width: cardWidth,
                height: cardHeight,
                x: 0,
                y: 0
              },
              z: 1
            },
            {
              type: 'text',
              style: {
                text: d.name,
                font: `${fontChange(18)} "Microsoft YaHei"`,
                x:
                  cardWidth / 2 -
                  textWidth(d.name, `${fontChange(18)} "Microsoft YaHei"`) / 2,
                y: 150,
                fill: d?.color || color(i)
              },
              z: 2
            },
            {
              type: 'image',
              style: {
                image: getIcon(
                  (d.icon ||
                    iconNames[i % iconNames.length]) as typeof iconNames[number]
                ),
                width: cardWidth / 3,
                height: cardWidth / 3,
                x: cardWidth / 3,
                y: 20
              },
              z: 2
            },
            {
              type: 'text',
              style: {
                text: formatter(d.value),
                font: `${fontChange(22)} "Microsoft YaHei"`,
                x:
                  cardWidth / 2 -
                  textWidth(
                    d.value + '',
                    `${fontChange(24)} "Microsoft YaHei"`
                  ) /
                    2,
                y: cardWidth / 3 + 20 + 20,
                fill: '#fff'
              },
              z: 2
            }
          ]
        }
      ]
    };
  });
  option.value = {
    graphic: {
      silent: true,
      elements: [
        {
          type: 'group',
          children: groups as any,
          left: len === 1 ? width / 2 - cardWidth / 2 : margin,
          top
        },
        {
          // 数据更新驱动echarts重新渲染第一个背景图会渲染不出来，加上这个节点可以避免
          type: 'image',
          style: {
            image: bgImage(0),
            x: 100,
            y: 100,
            opacity: 0,
            width: cardWidth,
            height: cardHeight
          },
          silent: false,
          z: 0
        }
      ]
    }
  };
});

onMounted(() => {
  window.addEventListener('resize', resize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resize);
});
</script>
<template>
  <base-chart
    ref="chartRef"
    :option="option"
    class="wh-chart--card"
    prevent-resize
  />
</template>
<style lang="scss" scoped></style>
