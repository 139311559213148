import { defineAsyncComponent } from 'vue';
import type { AsyncComponentLoader, Component } from 'vue';

export const loadAsyncComponent = (
  loader: AsyncComponentLoader<any>,
  loadingComponent?: Component
) =>
  defineAsyncComponent({
    loader,
    loadingComponent,
    delay: 20
  });
