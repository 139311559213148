// 定义内容
export default {
  router: {
    home: 'Home',
    hotspot: 'Hot List',
    search: 'Search',
    analysis: 'Analysis',
    dashboard: 'Dashboard',
    monitor: 'Monitor',
    warning: 'Alert',
    report: 'Report',
    bulletin: 'Bulletin',
    proofreading: 'Proofreading',
    contentReview: 'Content Review',
    searchResult: 'Search Result',
    collect: 'My Collection',
    analysisSettings: 'Analysis Settings',
    dashboardSettings: 'Dashboard Settings',
    other: 'Other',
    otherIcon: 'Icon',
    otherI18n: 'Internationalization',
    otherStyle: 'Global Styles',
    contacts: 'User Center',
    task: 'Task Center',
    formation: 'Analysis Preview',
    technologyRadar: {
      technologyRadar: 'Technology Radar',
      dataInfo: 'Data Info',
      report: 'Report',
      preview: 'Preview'
    },
    login: 'Login In',
    analysisShare: 'Analysis Sharing',
    bigscreenRun: 'Run',
    links: {
      videoRiskControl: 'Video Risk Control',
      yaYiLLM: 'YaYi LLM',
      dataMap: 'Data Map'
    },
    bigscreenShare: 'Bigscreen Sharing'
  },
  staticRoutes: {
    signIn: 'signIn',
    notFound: 'notFound',
    noPower: 'noPower'
  },
  user: {
    title0: 'Component size',
    title1: 'Language Switching',
    fontSizeSwitching: 'FontSize Switching',
    StandardFont: 'Standard Font',
    MediumFont: 'Medium Font',
    LargeFont: 'Large Font',
    title2: 'search',
    title3: 'Layout configuration',
    title4: 'Notification',
    title5: 'Full screen on',
    title6: 'Full screen off',
    title9: 'Settings',
    title12: 'Public Opinion Domain Large Model',
    dropdownLarge: 'large',
    dropdownDefault: 'default',
    dropdownSmall: 'small',
    dropdown1: 'home page',
    dropdown2: 'Personal Center',
    dropdown3: '404',
    dropdown4: '401',
    logOut: 'Logout',
    dropdown6: 'Code warehouse',
    searchPlaceholder: 'Menu search: support Chinese, routing path',
    newTitle: 'notice',
    newBtn: 'All read',
    newGo: 'Go to the notification center',
    newDesc: 'No notice',
    logOutTitle: 'Note',
    logOutMessage: 'Are you sure you would like to log out?',
    logOutConfirm: 'Log Out',
    logOutCancel: 'Cancel',
    logOutExit: 'Exiting'
  },
  tagsView: {
    refresh: 'refresh',
    close: 'close',
    closeOther: 'closeOther',
    closeAll: 'closeAll',
    fullscreen: 'fullscreen',
    closeFullscreen: 'closeFullscreen'
  },
  notFound: {
    foundTitle: 'Wrong address input, please re-enter the address~',
    foundMsg:
      'You can check the web address first, and then re-enter or give us feedback.',
    foundBtn: 'Back to home page'
  },
  noAccess: {
    accessTitle: 'You are not authorized to operate~',
    accessMsg: 'Contact information: add QQ group discussion 665452019',
    accessBtn: 'Reauthorization'
  },
  layout: {
    configTitle: 'Layout configuration',
    oneTitle: 'Global Themes',
    twoTopTitle: 'top bar set up',
    twoMenuTitle: 'Menu set up',
    twoColumnsTitle: 'Columns set up',
    twoTopBar: 'Top bar background',
    twoTopBarColor: 'Top bar default font color',
    twoIsTopBarColorGradual: 'Top bar gradient',
    twoMenuBar: 'Menu background',
    twoMenuBarColor: 'Menu default font color',
    twoIsMenuBarColorGradual: 'Menu gradient',
    twoColumnsMenuBar: 'Column menu background',
    twoColumnsMenuBarColor: 'Default font color bar menu',
    twoIsColumnsMenuBarColorGradual: 'Column gradient',
    threeTitle: 'Interface settings',
    threeIsCollapse: 'Menu horizontal collapse',
    threeIsUniqueOpened: 'Menu accordion',
    threeIsFixedHeader: 'Fixed header',
    threeIsClassicSplitMenu: 'Classic layout split menu',
    threeIsLockScreen: 'Open the lock screen',
    threeLockScreenTime: 'screen locking(s/s)',
    fourTitle: 'Interface display',
    fourIsShowLogo: 'Sidebar logo',
    fourIsBreadcrumb: 'Open breadcrumb',
    fourIsBreadcrumbIcon: 'Open breadcrumb icon',
    fourIsTagsview: 'Open tagsview',
    fourIsTagsviewIcon: 'Open tagsview Icon',
    fourIsCacheTagsView: 'Enable tagsview cache',
    fourIsSortableTagsView: 'Enable tagsview drag',
    fourIsShareTagsView: 'Enable tagsview sharing',
    fourIsFooter: 'Open footer',
    fourIsGrayscale: 'Grey model',
    fourIsInvert: 'Color weak mode',
    fourIsDark: 'Dark Mode',
    fourIsWartermark: 'Turn on watermark',
    fourWartermarkText: 'Watermark copy',
    fiveTitle: 'Other settings',
    fiveTagsStyle: 'Tagsview style',
    fiveAnimation: 'page animation',
    fiveColumnsAsideStyle: 'Column style',
    fiveColumnsAsideLayout: 'Column layout',
    sixTitle: 'Layout switch',
    sixDefaults: 'One',
    sixClassic: 'Two',
    sixTransverse: 'Three',
    sixColumns: 'Four',
    tipText:
      'Click the button below to copy the layout configuration to `/src/stores/themeConfig.ts` It has been modified in.',
    copyText: 'replication configuration',
    resetText: 'restore default',
    copyTextSuccess: 'Copy succeeded!',
    copyTextError: 'Copy failed!'
  },
  codeMsg: {
    msg1: 'Service loading failed.'
  },
  yayi: {
    Creativity: 'Creativity',
    Generating: 'Generating...',
    inputPlaceholder:
      'Please input your question content, Shift+Enter line break',
    chatAnswerInitTip: `Hello! I am your AI assistant, and I can help you with article analysis in <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="20" height="20"><g fill="none" fill-rule="evenodd"><path d="M0 0h16v16H0z"></path><path data-follow-fill="#4E5969" d="m11.771 9.885-.942-.942L11.77 8A2.667 2.667 0 1 0 8 4.229l-.943.942-.942-.942.942-.943a4 4 0 0 1 5.657 5.657l-.943.942Zm-1.886 1.886-.942.943a4 4 0 1 1-5.657-5.657l.943-.942.942.942L4.23 8A2.667 2.667 0 1 0 8 11.771l.943-.942.942.942Zm0-6.6.944.944-4.714 4.713-.944-.943 4.714-4.713Z" fill="rgba(22,93,255,1)" fill-rule="nonzero"></path></g></svg>；<br/>`,
    chatAnswerTip: 'Generating...',
    chatAnswerTipJb: 'Generating...',
    chatAnswerInitTipJb:
      'Please provide a URL or the content of the source text in the chat box, and I will generate a brief report for you.',
    chatAnswerInitTipJbTip: `Please follow this reference format for inputting original content. Input the following details, and omit any parts that do not exist:<br/>
      Title: National People's Congress Delegate Jia Xiaoliang Suggesting Establishing a National Unified Internet Identity Authentication Platform<br/>
      Media Channel: Weibo<br/>
      Account Name: China Police Network<br/>
      Account Followers: 4.183 million<br/>
      Account IP: Beijing<br/>
      Publication Time: 03-06 08:57<br/>
      Content: Beijing, March 5th. Tian Haijun reports: "In recent years, the endless emergence of online rumors, cyber violence incidents, and the persistent and unceasing crimes of online water armies and online black and gray industries are mainly due to the inadequate implementation of the real-name system on the internet."...<br/>
      Likes: 57<br/>
      Comments: 16<br/>
      Reposts: 1<br/>
      Reads: 0<br/>
      Comment: SmallTom: I stand for it wholeheartedly!!! `,
    buttonRetry: 'retry',
    buttonCopy: 'copy',
    settingDataName: ['Chat', 'Briefs'],
    tempTextDemo: [
      'Creativity refers to the "temperature" parameter during the generation of responses. This parameter will determine the level of freedom the model has in generating its response.',
      'When creativity is low, ',
      'the model will be more prudent and avoid responses that are too aggressive. ',
      'When creativity is high, ',
      'the model will be more open-minded and explore new and unconventional responses.'
    ],
    chatOptionsItem: ['New chat', 'Links', 'Prompts'],
    linkInputModel: [
      'input links',
      'Please input a link, and use an Enter key between each link',
      'cancel',
      'confirm'
    ],
    promptTabItem: [
      'default prompts',
      'personal prompts',
      'create',
      '(creativity:',
      'edit',
      'Edit prompt',
      'name',
      'content',
      'Default Mode',
      'creativity',
      'Please input name',
      'Please Input content',
      'Prompt deleted successfully',
      'Prompt edited successfully',
      'Prompt added successfully'
    ],
    exampleTip: ['Example'],
    mainPage: [
      'No content available in the',
      ' link, please check again',
      'Searching for artilces according to the link',
      'No content found, please check the link and try again',
      'Please input the link',
      'Incorrect format, please revise it',
      'Maximum of 5 links can be entered',
      'summary',
      'Generating analysis...',
      '',
      '',
      'Generation fails, please try again',
      'The format is incorrect, please follow the specified format',
      'Generation fails, please try again ',
      'Generation fails, please try again'
    ],
    chatAnswerArticle: [
      'Title: ',
      'Source: ',
      'Content: ',
      'likes: ',
      'Comments: ',
      'Reposts: ',
      'Reads: ',
      'Modify link'
    ]
  },
  layoutNavBars: {
    breadcrumbUser: ['Simplified Chinese', 'English'],
    breadcrumbMsgDrawer: ['Notice', 'Cancel', 'Confirm', 'No messages']
  },
  components: {
    articleListListOperation: ['Content Review']
  }
};
