<script lang="ts" setup>
import { ChartDataItem, TTheme } from '../utils';
import { loadAsyncComponent } from '../utils/async-component';
import useChartClick from '../hooks/useChartClick';
import type { TooltipComponentOption } from 'echarts';

interface Emits {
  (e: 'chart:click', params: any): void;
}

const props = defineProps<{
  data: ChartDataItem[];
  type?: 'china' | 'world' | 'province';
  tooltip?: boolean | TooltipComponentOption;
  en?: boolean;
  province?: string;
  theme: TTheme;
  hideMapView?: Boolean;
}>();

const emit = defineEmits<Emits>();
let mapImport: any;

if (props.type === 'china') {
  if (props.theme == 'bigscreen-theme-2') {
    mapImport = () => import('./ChinaMapChartTheme2.vue');
  } else {
    mapImport = () => import('./ChinaMapChart.vue');
  }
} else if (props.type === 'province') {
  mapImport = () => import('./ProvinceMapChart.vue');
} else {
  if (props.theme == 'bigscreen-theme-2') {
    mapImport = () => import('./WorldMapChartTheme2.vue');
  } else {
    mapImport = () => import('./WorldMapChart.vue');
  }
}

const mapChart = loadAsyncComponent(mapImport);

const chartRef = useChartClick(emit);
</script>
<template>
  <div class="publish-map">
    <map-chart v-bind="props" ref="chartRef" />
    <ul
      class="map-view"
      :class="props.theme"
      v-if="props.theme != 'bigscreen-theme-2' && !props.hideMapView"
    >
      <li
        class="view-item"
        v-for="item in props.data
          .map(item => {
            return item;
          })
          .sort((a, b) => {
            return b.value - a.value;
          })
          .splice(0, 5)"
        :key="item.name"
      >
        <span :title="item.name">{{ item.name }}</span>
        <span :title="item.value.toString()">{{ item.value }}</span>
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
.publish-map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .map-view {
    width: 150px;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    bottom: 10px;
    box-shadow: 0px 4px 12px 0px rgba(108, 113, 126, 0.14);

    .view-item {
      line-height: 30px;
      display: flex;

      span {
        width: 50%;
        padding: 0 10px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
          background-color: #f6f7f8;
        }

        &:last-child {
          text-align: center;
        }
      }
    }

    &.bigscreen-theme-1 {
      .view-item {
        span {
          &:first-child {
            background: #03101f;
            color: #8895a4;
          }

          &:last-child {
            background-color: #04162a;
            color: #c4d9f3;
          }
        }
      }
    }
  }
}
</style>
