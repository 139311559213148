<script lang="ts" setup>
import WordCloudBase, { SeriesItem } from './WordCloudBase.vue';
import tree from './images/tree.png';
import { onMounted, ref, shallowRef, watchEffect, onBeforeUnmount } from 'vue';
import { debounce } from 'lodash-es';
defineProps<{ series: SeriesItem }>();

const size = shallowRef(Symbol());
const resize = debounce(() => {
  size.value = Symbol();
}, 100);

watchEffect(() => {
  // 收集size依赖
  size.value;
});

onMounted(() => {
  window.addEventListener('resize', resize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resize);
});
</script>
<template>
  <WordCloudBase :series="series" :image="tree" prevent-resize ref="chartRef" />
</template>
<style lang="scss" scoped></style>
