import { Ref, computed } from 'vue';
import { useEditorStore } from '/@/stores/analysis';
import useChartInstance from '@wenhai/visual-components/hooks/useChartInstance';
import { debounce, removeHTML } from '/@/utils/other';
import domtoimage from 'dom-to-image';
import dayjs from 'dayjs';

export const isImage = computed(() => {
  return useEditorStore().isImage;
});

// 获取图表图片
export const getChartImage = (ref: Ref<any>) => {
  return useChartInstance(ref)?.getDataURL({
    type: 'png',
    pixelRatio: 1,
    backgroundColor: '#fff'
  });
};

export const getfilterList = (list: any, values: string[], key: string) => {
  const arr = list.filter((item: any) => {
    if (item.children?.length) {
      const arrC = getfilterList(item.children, values, key);
      if (arrC?.length) {
        item.children = arrC;
        return true;
      }
    } else {
      if (values.includes(item[key])) {
        return true;
      }
    }
  });
  return arr;
};
// dom转img
export const domToImg = async (node: Node) => {
  return await domtoimage.toPng(node).then((dataUrl: string) => {
    return dataUrl;
  });
};

// 生成导出所需要的数据格式
export const formatExportData = async (ref: any) => {
  const cType: string = ref?.$options?.name; //分析组件名称
  const rst = {};
  switch (cType) {
    // 概述提取文本
    case 'ASummarize':
      rst['isTextList'] = true;
      rst['textList'] = Array.prototype.map.call(
        ref.$el.querySelectorAll('p'),
        p => {
          return p.innerText.trim();
        }
      );
      break;
    // 信息列表相关的展示为表格
    case 'AChannelStart':
      rst['isTable'] = true;
      rst['table'] = ref.data.map((item: any) => {
        const { pubtime, title, dataSource } = item;
        return {
          pubtime,
          title: removeHTML(title),
          dataSource
        };
      });
      break;
    case 'AHotArticle':
    case 'AInfoList':
      rst['isTable'] = true;
      rst['table'] = ref.data.map((item: any) => {
        const { pubtime, title, dataSource } = item;
        return {
          pubtime,
          title: removeHTML(title),
          dataSource
        };
      });
      break;
    // 传播路径
    case 'APropagationPath':
      rst['isTable'] = true;
      rst['table'] = ref.data.map((item: any) => {
        const { publishTime, title, site } = item;
        return {
          pubtime: publishTime,
          title: removeHTML(title),
          dataSource: site
        };
      });
      break;
    // 默认转图片
    default:
      rst['isImg'] = true;
      rst['img'] = await domToImg(ref.$el);
  }
  return rst;
};

export const utilDebounce = debounce;

enum DateFormat {
  'HOUR' = 'MM-DD HH:mm',
  'DAY' = 'MM-DD',
  'MONTH' = 'MM'
}
type FormatInterval = keyof typeof DateFormat;
export const getDateByInterval = (date: Date, Interval: FormatInterval) => {
  const dateStr = dayjs(date).format(DateFormat[Interval]);
  return dateStr;
};
