// 定义内容
export default {
  collectMenu: {
    title: '我的最愛',
    searchPlaceholder: '輸入我的最愛名稱',
    editBtn: ['編輯', '删除'],
    modalDelMenu: [
      '删除標籤',
      '删除標籤將同時删除標籤下所有數據，確認要删除該標籤嗎？'
    ],
    collectMessageToast: ['自定義標籤創建數量已達上限', '名稱不可重複'],
    customMenuName: '自定義標籤',
    useModalBtn: ['取消', '確認']
  },
  addCollectForm: {
    addCollection: '添加收藏',
    createNewCollection: '新建收藏夹',
    add: '添加',
    addTips: ['收藏成功', '稍后查看', '立即查看'],
    note: '提示',
    removeCollectTip: '是否确认取消收藏？'
  }
};
