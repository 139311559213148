<template>
  <div style="overflow-y: auto">
    <HotList
      ref="hotListContent"
      v-if="!loading"
      :data="data"
      :count="count"
      :currentPage="filter.page"
      :pageSize="filter.size"
      :type="params.type"
      @currentPageChange="currentPageChange"
      @pageSizeChange="pageSizeChange"
      :theme="theme"
      stripe
    >
      <div class="content">
        <div
          :class="{
            'content-radio-group-theme2': theme == 'bigscreen-theme-2'
          }"
        >
          <w-radio-group
            type="button"
            size="medium"
            default-value="12"
            @change="mediaChange"
          >
            <w-radio
              v-for="(item, index) in mediaType"
              :value="item.value"
              :key="index"
              >{{ item.name }}</w-radio
            >
          </w-radio-group>
        </div>
        <div>
          <w-input-search
            v-model.trim="searchValue"
            :placehoder="111"
            @search="search"
            @change="search"
          />
        </div>
      </div>
    </HotList>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, reactive } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { transRealTime } from '/@/utils/other';

export default defineComponent({
  // 热点榜单
  name: 'AHotList',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const { hotList } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const hotListContent = ref();
    const searchValue = ref<string>('');
    const data = ref<any[]>([]);
    const loading = ref(false);
    const filter = reactive({
      source: 1,
      page: 1,
      size: 10,
      keyWord: ''
    });
    const mediaType = reactive([
      { name: '微博', value: '12' },
      { name: '抖音', value: '1' },
      { name: '百度', value: '17' },
      { name: '知乎', value: '5' },
      { name: '快手', value: '2' },
      { name: '头条', value: '13' }
    ]);
    const count = ref<number>(0);
    const getData = async () => {
      if (!params.value.schemeId) return;
      data.value.splice(0);
      let param = Object.assign(params.value, filter);
      if (param.type) {
        param.startTime = '';
        param.endTime = '';
      } else {
        param.startTime = transRealTime(param.realTime)[0];
        param.endTime = transRealTime(param.realTime)[1];
      }
      hotListContent.value?.load(true);
      try {
        const res = await hotList(param);
        if (res.code === 200) {
          const resData = res.data as any;
          count.value = resData?.count;
          data.value = resData?.hotListVOList;
        }
        hotListContent.value?.load(false);
      } catch (error) {
        hotListContent.value?.load(false);
      }
    };
    const currentPageChange = (currentPage: number) => {
      filter.page = currentPage;
      getData();
    };
    const pageSizeChange = (size: number) => {
      filter.page = 1;
      filter.size = size;
      getData();
    };
    const search = () => {
      filter.page = 1;
      filter.keyWord = searchValue.value;
      getData();
    };
    const mediaChange = (val: number) => {
      filter.page = 1;
      filter.source = Number(val);
      getData();
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      data,
      count,
      params,
      filter,
      loading,
      mediaType,
      searchValue,
      hotListContent,
      update,
      search,
      mediaChange,
      pageSizeChange,
      currentPageChange
    };
  }
});
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: space-between;
  :deep(.w-input-search) {
    width: 220px;
    border-radius: 25px;
    margin-bottom: 10px;
    // margin-top: -5px;
  }

  :deep(.w-radio-button-content) {
    border: none;
  }

  :deep(.w-radio-button) {
    // border-radius: 15px;
    background-color: #fff;
    color: rgba(60, 69, 85, 1);
  }
  :deep(.w-radio-checked) {
    color: rgba(59, 112, 255, 1);
    border-bottom: 2px solid rgba(59, 112, 255, 1);
  }

  :deep(.w-radio-button:first-child .w-radio-button-content) {
    border: none;
  }
  :deep(.content-radio-group-theme2) {
    .w-radio-group-button {
      .w-radio-button {
        background: transparent;
        background: linear-gradient(
          180deg,
          rgba(172, 206, 234, 0) 0%,
          rgba(172, 206, 234, 0.1) 100%
        );
        border: 1px solid;
        border-image: linear-gradient(
            180deg,
            rgba(172, 206, 234, 0),
            rgba(172, 206, 234, 0.4)
          )
          1 1;
        border-radius: 0;
        white-space: nowrap;
        :deep(.w-radio-button-content) {
          border: none;
          color: rgba(189, 219, 255, 0.6) !important;
          padding: 0 12px;
        }

        &:not(:first-child) {
          border-left: none;
        }

        &.w-radio-checked {
          background: linear-gradient(
              180deg,
              rgba(70, 171, 255, 0) 0%,
              rgba(70, 171, 255, 0.2) 100%
            ),
            linear-gradient(
              180deg,
              rgba(70, 171, 255, 0.3) 0%,
              rgba(70, 171, 255, 0) 100%
            );
          border-image: linear-gradient(
              180deg,
              rgba(70, 171, 255, 0),
              rgba(70, 171, 255, 1)
            )
            1 1;

          :deep(.w-radio-button-content) {
            color: #fff !important;
          }
        }
      }
    }
  }
}
</style>
