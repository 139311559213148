<script lang="ts" setup>
import {
  onMounted,
  onUnmounted,
  PropType,
  shallowRef,
  ShallowRef,
  ref,
  computed,
  watch
} from 'vue';
import { ECOption } from '../utils';
import useChartClick from '../hooks/useChartClick';
import BaseChart from '../BaseChart/BaseChart.vue';
import { getChartGradientColor } from '../utils';
import dayjs from 'dayjs';
const getUTCTime = (timeOffset: string) => {
  const hour =
    Number(timeOffset.split(':')[0]) + new Date().getTimezoneOffset() / 60;
  return new Date(new Date().getTime() + hour * 60 * 60 * 1000);
};

type Theme = 'dark' | 'light' | 'transparent';
const themeOpion = {
  transparent: '',
  dark: '#010309',
  light: getChartGradientColor('linear', ['#EAEFF4', '#DAEEFF', '#EAEFF4'])
};
const props = defineProps({
  data: {
    type: Object as PropType<{
      name: string;
      timeOffsetLabel: string;
      timeOffset: string;
    }>,
    required: true
  },
  theme: {
    type: String as PropType<Theme>,
    default: 'light'
  }
});
interface Emits {
  (e: 'chart:click', params: any): void;
}
const emit = defineEmits<Emits>();
const chartRef = useChartClick(emit);
let option: ShallowRef<ECOption> = shallowRef({});
const intervalTimer = ref();
const currentDate = ref();
const backgroundSize = computed(() => {
  const offsetHeight = chartRef.value?.chart._dom.offsetHeight;
  const offsetWidth = chartRef.value?.chart._dom.offsetWidth;
  return Math.round(Math.min(offsetHeight, offsetWidth));
});
const setOption = () => {
  option.value = {
    grid: {
      bottom: 0
    },
    series: [
      {
        name: 'hour',
        type: 'gauge',
        radius: '100%',
        startAngle: 90,
        endAngle: -270,
        min: 0,
        max: 12,
        splitNumber: 12,
        clockwise: true,
        axisLine: {
          lineStyle: {
            width: backgroundSize.value * 0.03,
            color: [[1, '#1C4271']]
          }
        },
        axisTick: {
          distance: backgroundSize.value * 0.01,
          length: backgroundSize.value * 0.01
        },
        axisLabel: {
          fontSize: backgroundSize.value * 0.04,
          distance: backgroundSize.value * 0.04,
          color: ['transparent', 'dark'].includes(props.theme)
            ? '#BDDBFF'
            : '#272A31',
          formatter: function (value) {
            if (value === 0) {
              return '';
            }
            return value + '';
          }
        },
        pointer: {
          icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
          width: backgroundSize.value * 0.02,
          length: '50%',
          offsetCenter: [0, 0],
          itemStyle: {
            color: ['transparent', 'dark'].includes(props.theme)
              ? '#BDDBFF'
              : '#272A31'
          }
        },
        detail: {
          show: false
        },
        data: [
          {
            value: 0
          }
        ]
      },
      {
        name: 'minute',
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        min: 0,
        max: 60,
        clockwise: true,
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        pointer: {
          icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
          width: backgroundSize.value * 0.02,
          length: '78%',
          offsetCenter: [0, 0],
          itemStyle: {
            color: ['transparent', 'dark'].includes(props.theme)
              ? '#BDDBFF'
              : '#272A31'
          }
        },
        anchor: {
          show: true,
          size: 5,
          showAbove: false,
          itemStyle: {
            borderWidth: 15,
            borderColor: '#BDDBFF'
          }
        },
        detail: {
          show: false
        }
      },
      {
        name: 'second',
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        min: 0,
        max: 60,
        // animationEasingUpdate: 'bounceOut',
        clockwise: true,
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        pointer: {
          icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
          width: backgroundSize.value * 0.02,
          length: '100%',
          offsetCenter: [0, '20%'],
          itemStyle: {
            color: getChartGradientColor('linear', [
              'rgba(255, 48, 0, 0.1)',
              '#FF3000'
            ])
          }
        },
        anchor: {
          show: true,
          size: backgroundSize.value * 0.02,
          showAbove: false,
          itemStyle: {
            borderWidth: backgroundSize.value * 0.03,
            borderColor: '#FF3000'
          }
        },
        detail: {
          show: false
        }
      },
      {
        name: 'outline',
        type: 'gauge',
        radius: '92%',
        min: 0,
        max: 12,
        startAngle: 90,
        endAngle: -270,
        splitNumber: 12,
        clockwise: true,
        axisLine: {
          lineStyle: {
            width: 1,
            color: [[1, '#1C4271']]
          }
        },
        axisTick: {
          length: backgroundSize.value * 0.025,
          distance: 0,
          splitNumber: 1,
          lineStyle: {
            color: ['transparent', 'dark'].includes(props.theme)
              ? '#46ABFF'
              : '#272A31'
          }
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          show: false
        },
        pointer: {
          show: false
        },
        detail: {
          show: false
        },
        data: [
          {
            value: 0
          }
        ]
      },
      {
        name: 'point',
        type: 'gauge',
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        pointer: {
          show: false
        },
        anchor: {
          show: true,
          size: backgroundSize.value * 0.015,
          showAbove: true,
          itemStyle: {
            borderWidth: backgroundSize.value * 0.015,
            borderColor: '#BDDBFF'
          }
        }
      },
      {
        name: 'background',
        type: 'gauge',
        anchor: {
          show: props.theme != 'transparent',
          size: backgroundSize.value * 0.92,
          itemStyle: {
            color: themeOpion[props.theme]
          }
        },
        z: -1,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          show: false
        },
        pointer: {
          show: false
        },
        detail: {
          show: false
        },
        data: [
          {
            value: 0
          }
        ]
      }
    ]
  };
  intervalTimer.value = setInterval(function () {
    const date = getUTCTime(props.data.timeOffset);
    currentDate.value = dayjs(date).format('YYYY-MM-DD HH:mm:ss');
    const second = date.getSeconds();
    const minute = date.getMinutes() + second / 60;
    const hour = (date.getHours() % 12) + minute / 60;
    option.value.animationDurationUpdate = 300;
    option.value = {
      series: [
        {
          name: 'hour',
          animation: hour !== 0,
          data: [{ value: hour }]
        },
        {
          name: 'minute',
          animation: minute !== 0,
          data: [{ value: minute }]
        },
        {
          animation: second !== 0,
          name: 'second',
          data: [{ value: second }]
        }
      ]
    };
  }, 1000);
};
onMounted(() => {
  setOption();
});
onUnmounted(() => {
  clearInterval(intervalTimer.value);
});
</script>
<template>
  <div class="wh-chart--clock">
    <base-chart
      ref="chartRef"
      :option="option"
      class="clock-chart"
      :class="{ 'clock-chart-with-datezoom': !!props.data?.name }"
    />
    <div class="clock-timezone" v-if="props.data?.name">
      <div class="name">{{ props.data?.name }}</div>
      <div class="date">{{ currentDate }}</div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.wh-chart--clock {
  .clock-chart {
    &.clock-chart-with-datezoom {
      height: calc(100% - 70px);
    }
  }

  .clock-timezone {
    text-align: center;
    user-select: none;
    font-size: 16px;

    .name {
      color: #ffffff;
      margin-bottom: 6px;
      margin-top: 6px;
    }

    .date {
      color: #bddbff;
      font-weight: 500;
    }
  }
}
</style>
