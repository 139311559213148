import { defineStore } from 'pinia';
import useSchemeMenuApi from '/@/api/search/schemeMenu';
import { deepClone } from '/@/utils/other';
export interface IScheme {
  id: number;
  schemeId: number;
  schemePid: number;
  name: string;
  schemeName: string;
  level: number;
  config?: any;
  children?: IScheme[];
}

interface SearchSchemeState {
  loading: Boolean;
  total: number;
  schemeList: IScheme[];
  current: IScheme | null | undefined;
  pagedSchemeList: IScheme[]; //分页主题列表
  pageInfo: {
    currentPage: number;
    pageSize: number;
  };
  noMore: Boolean;
  selectLoading: Boolean;
  selectSchemeList: IScheme[];
  flatSelectSchemeList: IScheme[];
}

export const useSearchSchemeStore = defineStore('searchScheme', {
  state: (): SearchSchemeState => ({
    loading: false,
    total: 0,
    schemeList: [],
    current: null,
    pagedSchemeList: [],
    pageInfo: {
      currentPage: 1,
      pageSize: 100
    },
    noMore: false,
    selectLoading: false,
    selectSchemeList: [],
    flatSelectSchemeList: []
  }),
  actions: {
    handleFlatSelectSchemeList(list: IScheme[]) {
      deepClone(list).forEach(item => {
        if (item.children?.length) {
          this.handleFlatSelectSchemeList(item.children);
          delete item.children;
        }
        this.flatSelectSchemeList.push(item);
      });
    },
    async getSelectSchemeList() {
      // 数据源选型，获取最新部分数据
      if (this.selectLoading) return;
      try {
        this.selectLoading = true;
        const res = await useSchemeMenuApi().searchSchemeTree({
          currentPage: 1,
          pageSize: 1000,
          briefFlag: 1
        });
        if (res?.code === 200) {
          this.selectSchemeList = res.data?.schemeList || [];
          // 存一份扁平化的列表，用于回显判断
          this.flatSelectSchemeList.splice(0);
          this.handleFlatSelectSchemeList(this.selectSchemeList);
        }
        this.selectLoading = false;
      } catch (error) {
        this.selectSchemeList = [];
        this.selectLoading = false;
      }
    },
    async getPagedSchemeList() {
      try {
        this.loading = true;
        const res = await useSchemeMenuApi().searchSchemeTree(this.pageInfo);
        if (res?.code === 200) {
          const list = res.data?.schemeList as IScheme[];
          this.pagedSchemeList.push(...list);
          this.total = res.data?.searchSchemeTotal || 0;
          if (list.length < this.pageInfo.pageSize) {
            this.noMore = true;
          }
        } else {
          this.pageInfo.currentPage--;
        }
        this.loading = false;
      } catch (error) {
        this.pageInfo.currentPage--;
        this.loading = false;
      }
    },
    async getFilterSchemeList(val: string) {
      if (val) {
        this.pagedSchemeList.length = 0;
        try {
          this.loading = true;
          const res = await useSchemeMenuApi().getSearchSchemeList({
            schemeName: val
          });
          if (res?.code === 200) {
            const list = res.data as IScheme[];
            this.pagedSchemeList.push(...list);
          }
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      } else {
        this.initPagedSchemeList();
      }
    },
    getNextPage() {
      if (this.noMore || this.loading) return;
      this.pageInfo.currentPage++;
      this.getPagedSchemeList();
    },
    async initPagedSchemeList() {
      this.pageInfo.currentPage = 1;
      (this.noMore = false), (this.pagedSchemeList.length = 0);
      await this.getPagedSchemeList();
    },
    async getSchemeList() {
      try {
        const res = await useSchemeMenuApi().searchSchemeTree({
          currentPage: 1,
          pageSize: 2000
        });
        if (res?.code === 200) {
          this.schemeList = res.data?.schemeList || [];
        }
      } catch (error) {
        this.schemeList = [];
      }
    },
    async RegSchemeList() {
      try {
        this.loading = true;
        const res = await useSchemeMenuApi().getList();
        if (res?.code === 200) {
          this.schemeList = (await this.sliceName(res.data)) || [];
          // 搜索页面初次加载时，将返回的信息分类参数字段赋值给搜索接口实际接受的信息分类参数字段
          if (this.schemeList) {
            this.schemeList.map((item: any) => {
              item.config.areaDTO.mediaGradeTags = [];
              item.config.informationClassification &&
                item.config.informationClassification.map((index: any) => {
                  let arr = { tags2: index };
                  item.config.areaDTO.mediaGradeTags.push(arr);
                });
            });
          }
        }
      } catch (error) {
        this.schemeList = [];
      }
      this.loading = false;
    },
    async sliceName(data: any) {
      await data.map((item: any) => {
        item.schemeName = item.schemeName.slice(0, 2);
        if (item.children) this.sliceName(item.children);
      });
      return data;
    },
    setSchemeList(data: IScheme[]) {
      this.pagedSchemeList = data;
    },
    setCurrent(data: IScheme) {
      this.current = data;
    },
    setloading(data: any) {
      this.loading = data;
    }
  }
});
