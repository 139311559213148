export enum LogoType {
  PNG = '.png',
  JPG = '.jpg',
  GIF = '.gif',
  BMP = '.bmp',
  PICT = '.pict'
}
export enum ModuleType {
  'cover' = 'cover',
  'red_head' = 'red_head',
  'over_view' = 'over_view',
  'text' = 'text',
  'volume_trend' = 'volume_trend',
  'source_ratio' = 'source_ratio',
  'emotional_trend' = 'emotional_trend',
  'emotional_distributed' = 'emotional_distributed',
  'active_media' = 'active_media',
  'hot_keywords' = 'hot_keywords',
  'refer_region' = 'refer_region',
  'public_opinion_list' = 'public_opinion_list'
}
export interface CoverState {
  logo?: string;
  logoType?: LogoType;
  logoWidth?: number;
  logoHeight?: number;
  periodsInfo: string;
  publisher: string;
  tips: string;
  title: string;
  type: ModuleType.cover;
}

export interface RedHeaderState {
  date: string;
  department: string;
  num: string;
  title: string;
  type: ModuleType.red_head;
}

export interface AnalysisState {
  id: number;
  type: ModuleType;
  title: string;
}
export interface ChartAnalysisState extends AnalysisState {
  showChart: true;
  showPlaceholderImg: true;
}

export interface MediaListAnalysisState extends AnalysisState {
  type: ModuleType.public_opinion_list;
  showPlaceholderImg: true;
  shape: 'table' | 'list';
}

export interface TextAnalysisState extends AnalysisState {
  type: ModuleType.text;
  content: string;
}
export enum TemplateMode {
  PREVIEW,
  EDIT
}
