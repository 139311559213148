import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import PublishMap from './src/index.vue';

PublishMap.install = (app: App): void => {
  app.component('APublishMap', PublishMap);
  app.component(
    'APublishMapProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default PublishMap;
