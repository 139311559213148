<template>
  <w-form-item :label="label">
    <w-radio-group
      :type="type"
      size="small"
      v-model="value"
      :disabled="disabled"
    >
      <w-radio v-for="item in option" :key="item.value" :value="item.value">
        <template #radio="{ checked }">
          <div
            align="start"
            class="custom-radio-card"
            :class="{ 'custom-radio-card-checked': checked }"
          >
            <slot :data="item" />
            <span class="custom-radio-label">{{ item.label }}</span>
          </div>
        </template>
      </w-radio>
    </w-radio-group>
  </w-form-item>
</template>
<script lang="ts">
import { defineComponent, ref, watch, PropType } from 'vue';
export default defineComponent({
  name: 'ConfigCardRadio',
  props: {
    label: {
      type: String
    },
    modalValue: {
      type: String,
      required: true
    },
    option: {
      type: Object as PropType<
        { label: string; value: string; disabled?: boolean }[]
      >,
      default: []
    },
    type: {
      type: String as PropType<'radio' | 'button'>,
      default: 'radio'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const value = ref(props.modalValue);
    watch(value, val => {
      emit('update:modalValue', val);
      emit('radioChange', val);
    });
    return { value };
  }
});
</script>
<style scoped lang="scss">
.custom-radio-card {
  width: 80px;
  height: 54px;
  border: 1px solid var(--color-border-2);
  box-sizing: border-box;
  position: relative;
  margin-bottom: 25px;

  &:hover,
  &.custom-radio-card-checked {
    border-color: rgb(32, 85, 244);

    .custom-radio-label {
      color: rgb(32, 85, 244);
    }
  }

  .custom-radio-label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -25px;
    white-space: nowrap;
  }
}
</style>
