<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(dataList.length)"
    class="chart-box-in"
  >
    <OvalRingChart
      v-if="params.chartType === 'pie' && !loading && dataList.length"
      v-memo="[]"
      ref="refChart"
      :data="dataObject.seriesLine"
      tooltip
      :color="dataObject.color"
      layout
      legend
      label
    />
    <LineChart
      v-memo="[]"
      ref="refChart"
      v-if="params.chartType === 'line' && !loading && dataList.length"
      :smooth="true"
      :series="dataObject.seriesLine"
      :x-axis="{ data: dataObject.xAxis }"
      y-axis
      tooltip
      legend
      :color="dataObject.color"
    />
    <ScaleLineChart
      v-if="params.chartType === 'scale' && !loading && dataList.length"
      v-memo="[]"
      ref="refChart"
      :smooth="true"
      :dataObject="dataObject"
      y-axis
      tooltip
      legend
    ></ScaleLineChart>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import ScaleLineChart from '/@/views/analysis/components/emotionalTendency/_components/ScaleLineChart.vue';
import { useSearchApi } from '/@/api/search';
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { useSearchAnalyse } from '/@/stores/searchAnalyse';
import { useDictStore } from '/@/stores/dict';
import { cloneDeep } from 'lodash-es';
import { getfilterList } from '../components/utils';
export default defineComponent({
  // 情感倾向
  name: 'SEmotionalTendency',
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  components: {
    ScaleLineChart
  },
  setup(props) {
    const { analysisPolarityTendency } = useSearchApi();
    const loading = ref(false);
    const tooltips = ref({ trigger: 'axis' });
    const dataObject = reactive({
      xAxis: [],
      color: ['#5EB4EB', '#2A5AF8', '#6220FE'],
      seriesLine: [
        { name: '正面', type: 'line' },
        { name: '中立', type: 'line' },
        { name: '负面', type: 'line' }
      ],
      negative: [],
      neutrality: [],
      front: [],
      negativeSum: '',
      neutralitySum: '',
      frontSum: ''
    });
    const paramsReset = computed(() => {
      return props.params;
    });
    const dataList = ref<Record<string, any>>([]);
    const searchAnalyseStore = useSearchAnalyse();
    const dictStore = useDictStore();
    const {
      searchAnalyseParams,
      sAnalysechannelIds,
      dataAuthType,
      designatedMediaLabelArr,
      searchTime
    } = storeToRefs(searchAnalyseStore);
    const { mediaTypeList, mediaTypeLocalList, mediaTypeForeignList } =
      storeToRefs(dictStore);
    let resParams = reactive({});
    function getData() {
      loading.value = true;
      resParams = cloneDeep(searchAnalyseParams.value);
      delete resParams.dataSource;
      let defalutMediaTypeList = [];
      if (dataAuthType.value === 0) {
        defalutMediaTypeList = cloneDeep(mediaTypeList.value);
      }
      if (dataAuthType.value === 1) {
        defalutMediaTypeList = cloneDeep(mediaTypeLocalList.value);
      }
      if (dataAuthType.value === 2) {
        defalutMediaTypeList = cloneDeep(mediaTypeForeignList.value);
      }
      const arr = getfilterList(
        defalutMediaTypeList,
        sAnalysechannelIds.value,
        'value'
      );
      resParams = Object.assign({}, resParams, {
        dataSourceInfos: arr,
        schemeId: paramsReset.value.schemeId
      });
      if (resParams?.designatedMediaLabelId === 0) {
        resParams.searchDesignatedLabelVOS = [];
      } else {
        resParams.searchDesignatedLabelVOS =
          designatedMediaLabelArr.value.filter(item => {
            return item.id === resParams.designatedMediaLabelId;
          });
      }
      if (searchTime.value.startPubTime && searchTime.value.endPubTime) {
        (resParams.realTime = 0),
          (resParams.startPubTime = searchTime.value.startPubTime),
          (resParams.endPubTime = searchTime.value.endPubTime);
      }
      analysisPolarityTendency(resParams)
        .then((res: any) => {
          dataList.value = res.data;
          dataObject.xAxis = res.data?.[0]?.articleTypeExposureTrend.map(
            (item: Record<string, any>) => {
              return dayjs(item.intervalTime).format('MM月DD日');
            }
          );
          res.data.forEach((item: Record<string, any>) => {
            if (item.type === '0') {
              // 中立
              dataObject.neutralitySum = item.percent;
              dataObject.neutrality = item.articleTypeExposureTrend.map(
                (v: any) => {
                  return v.sum;
                }
              );
              // 中立图表参数
              dataObject.seriesLine.map((v: Record<string, any>) => {
                if (v.name === '中立') {
                  v.data = dataObject.neutrality;
                  v.value = item.sum;
                }
              });
            } else if (item.type === '1') {
              // 正面
              dataObject.frontSum = item.percent;
              dataObject.front = item.articleTypeExposureTrend.map((v: any) => {
                return v.sum;
              });
              // 正面图表参数
              dataObject.seriesLine.map((v: Record<string, any>) => {
                if (v.name === '正面') {
                  v.data = dataObject.front;
                  v.value = item.sum;
                }
              });
            } else if (item.type === '2') {
              // 负面
              dataObject.negativeSum = item.percent;
              dataObject.negative = item.articleTypeExposureTrend.map(
                (v: Record<string, any>) => {
                  return v.sum;
                }
              );
              // 负面图表参数
              dataObject.seriesLine.map((v: Record<string, any>) => {
                if (v.name === '负面') {
                  v.data = dataObject.negative;
                  v.value = item.sum;
                }
              });
            }
          });
        })
        .finally(() => {
          loading.value = false;
        });
    }
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };

    return {
      loading,
      update,
      dataObject,
      tooltips,
      dataList
    };
  }
});
</script>
<style lang="scss" scoped>
.chart-box-in {
  width: 100%;
  height: 330px !important;
  :deep(.el-loading-mask) {
    z-index: 9 !important;
  }
}
</style>
