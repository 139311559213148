import { defineStore } from 'pinia';
import { useDictApi } from '/@/api/analyst/dict';
import { LANGUAGESLIST, LANGUAGESLISTID } from '/@/constants/cons';
import { conformsTo, throttle } from 'lodash-es';
interface mediaTypeItem {
  label: string;
  value: string;
  type?: number;
  children?: mediaTypeItem[];
}
interface mediaTagItem {
  id: string | number;
  name: string;
  type?: number; // 1-境内 2-境外
}
interface informationCategory {
  tagName: string | number;
  levelId: string;
  levelName: string;
  isLeafNode: string;
  children?: informationCategory[];
}
interface DictState {
  countryPekList: string[];
  areaTreeList: any[];
  languagesList: string[];
  mediaTagList: mediaTagItem[];
  mediaTypeList: mediaTypeItem[]; //全部媒体渠道
  mediaTypeForeignList: mediaTypeItem[]; //境外媒体渠道
  mediaTypeLocalList: mediaTypeItem[]; //境内媒体渠道
  informationCategory: informationCategory[]; //信息分类
  mediaOrganization: mediaTagItem[];
  provinceList: { label: string; value: string; id: string }[]; //国内省级地区编码列表
  mediaGradeTagTree: { levelId: string; tagName: string }[]; //媒体等级
  mediaRegionTagTree: { levelId: string; tagName: string }[]; //媒体地域
  institutionTagTree: { levelId: string; tagName: string }[]; //机构类型
  accountTagTree: { levelId: string; tagName: string }[]; //个体账号
  levelTagsTree: { levelId: string; tagName: string }[]; //信息分类标签
  commonMediaTags: any[]; // 常用标签
  mediaTagListByUser: any[]; //用户后台管理配置的媒体标签
  timezoneList: any[]; //时区选项
}
export const useDictStore = defineStore('dict', {
  state: (): DictState => ({
    countryPekList: [],
    areaTreeList: [],
    languagesList: [],
    mediaTagList: [],
    mediaTypeList: [],
    mediaTypeForeignList: [],
    mediaTypeLocalList: [],
    informationCategory: [],
    mediaOrganization: [],
    provinceList: [],
    mediaGradeTagTree: [],
    mediaRegionTagTree: [],
    institutionTagTree: [],
    accountTagTree: [],
    levelTagsTree: [],
    commonMediaTags: [],
    mediaTagListByUser: [],
    timezoneList: []
  }),
  actions: {
    async getCountryPekList() {
      try {
        const res: any = await useDictApi().getCountryPekList();
        if (res?.code === 200 && res?.data?.length) {
          this.countryPekList = res.data || [];
        }
      } catch (error) {
        this.countryPekList = [];
      }
    },
    async getMentionAreaTree() {
      try {
        const res: any = await useDictApi().getMentionAreaTree();
        if (res?.code === 200 && res?.data?.length) {
          this.areaTreeList = res.data || [];
        }
      } catch (error) {
        this.areaTreeList = [];
      }
    },
    async getLanguagesList() {
      try {
        const res: any = await useDictApi().getLanguagesList();
        if (res?.code === 200 && res?.data?.length) {
          const list = res?.data?.filter(item => {
            // return LANGUAGESLIST.includes(item.name);
            return LANGUAGESLISTID.includes(item.id); //国际化对应id
          });
          this.languagesList = list || [];
        }
      } catch (error) {
        this.languagesList = [];
      }
    },
    // async getMediaTagList() {
    // 	try {
    // 		const res: any = await useDictApi().getMediaTagList();
    // 		if (res?.code === 200 && res?.data?.length) {
    // 			this.mediaTagList = res.data || [];
    // 		}
    // 	} catch (error) {
    // 		this.mediaTagList = [];
    // 	}
    // },
    async getInformationCategory() {
      try {
        const res: any = await useDictApi().getInformationCategory();
        if (res?.code === 200 && res?.data) {
          this.informationCategory = res.data || [];
        }
      } catch (error) {
        this.informationCategory = [];
      }
    },
    async getMediaTypeList() {
      try {
        const res: any = await useDictApi().getMediaTypeList();
        if (res?.code === 200 && res?.data) {
          this.mediaTypeList = res.data?.dataSource || [];
          this.mediaTypeForeignList = res.data?.dataSourceForeign || [];
          this.mediaTypeLocalList = res.data?.dataSourceLocal || [];
          this.mediaOrganization = res.data?.mediaOrganization || [];
        }
      } catch (error) {
        this.mediaTypeList = [];
        this.mediaTypeForeignList = [];
        this.mediaTypeLocalList = [];
        this.mediaOrganization = [];
      }
    },
    getProvinceList: throttle(async function () {
      const resdata = (await useDictApi().getProvinceOption()).data as {
        name: string;
        id: string;
      }[];
      this.provinceList = resdata.map(item => {
        return {
          label: item.name,
          value: item.name,
          id: item.id
        };
      });
    }, 1000),
    async getMediaTagTree() {
      const res = await useDictApi().getMediaTagTree();
      if (res?.code === 200 && res.data) {
        const {
          mediaGradeTagTree,
          mediaRegionTagTree,
          institutionTagTree,
          accountTagTree
        } = res.data;
        this.mediaGradeTagTree = mediaGradeTagTree.children;
        this.mediaRegionTagTree = mediaRegionTagTree.children;
        this.institutionTagTree = this.deleteThirdChildren(
          institutionTagTree.children
        );
        this.accountTagTree = accountTagTree.children;
      }
    },
    async getListByUser() {
      const res = await useDictApi().getListByUser();
      if (res?.code === 200 && res.data) {
        this.levelTagsTree = res.data;
      }
    },
    deleteThirdChildren(options: any[]) {
      for (const key in options) {
        // 删除第三层children属性
        for (const i in options[key].children) {
          delete options[key].children[i].children;
        }
      }
      return options;
    },
    async getCommonMediaTag() {
      const res: any = await useDictApi().getMediaTagByHighFrequency();
      if (res?.code === 200 && res.data) {
        this.commonMediaTags = res.data?.highFrequencyList || [];
        this.mediaTagList = res.data?.defaultList || [];
      }
    },
    async getMediaTagListByUser() {
      const res: any = await useDictApi().getMediaTagsByUser();
      if (res?.code === 200) {
        this.mediaTagListByUser = res?.data?.length
          ? res.data
          : this.mediaTagList;
      }
    },
    async getAllTimezoneList() {
      if (this.timezoneList.length) return;
      const res: any = await useDictApi().getAllTimezoneList();
      if (res?.code === 200) {
        this.timezoneList = res.data.map(item => {
          return {
            label: `${item.name}  (UTC)${item.timeOffset}`,
            name: item.name,
            value: item.id,
            timeOffset: item.timeOffset,
            timeOffsetLabel: item.timeOffset.split(':')[0] + '小时'
          };
        });
      }
    }
  }
});
