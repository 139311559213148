import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import RankDistribution from './src/index.vue';

RankDistribution.install = (app: App): void => {
  app.component('ARankDistribution', RankDistribution);
  app.component(
    'ARankDistributionProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default RankDistribution;
