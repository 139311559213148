// 定义内容
export default {
  warningList: {
    fullWarning: '全部预警',
    manualWarning: '手动预警',
    monitoringScheme: '监测方案',
    monitorWarning: '监测预警',
    selectWarning: '搜索预警',
    monitor: '监测',
    search: '搜索'
  },
  filterPanel: {
    WarningTime: '预警时间:',
    polarity: '情感倾向:',
    polarityYayi: '情感分析:',
    mediaType: '媒体渠道:',
    time1: '今天',
    time2: '近24小时',
    time3: '近7天',
    time4: '近30天',
    time5: '自定义',
    time6: '近3天',
    time14: '近14天',
    all: '全部',
    Search: '查询'
  },
  articlelist: {
    newInfo: '有新信息，点击获取',
    upperInfo: '今日数据量已达上限,暂停更新。请联系客服人员增加数据量~',
    chooseAll: '选择当前页',
    collect: '收藏',
    cancelCollect: '取消收藏',
    changeLabels: '更换标签',
    addedWarning: '已加入预警',
    warning: '预警',
    addBrief: '加入简报',
    addedBrief: '已加入简报',
    delete: '删除',
    removeAll: '清空',
    positiveSequence: '正序',
    reverse: '倒序',
    placehoder1: '请输入关键词',
    placehoder2: '请输入预警推送内容',
    text1: '是否确认取消收藏？',
    text2: '确定删除选中素材?',
    text3: '确定删除选中的信息吗?',
    text4: '确定删除这条信息吗?',
    text5: '确定要修改情感信息吗?',
    cancel: '取消',
    confirm: '确定',
    checkText: '查看原文',
    copy: '复制',
    warningContent: '预警推送内容',
    tip1: '内容不可为空',
    reset: '重置',
    sendWarning: '发送预警',
    all: '共',
    tip2: '个字符,不能超过100个字符',
    tip3: '条信息',
    keyword: '关键词:',
    negative: '负面',
    positive: '正面',
    neutrality: '中立',
    originalLink: '原文链接',
    prompt: '提示',
    issueTime: '发布时间',
    fullText: '全文',
    title: '标题',
    publishPlatform: '发布平台',
    author: '作者',
    copyLink: '复制链接',
    original: '原文',
    retransmission: '转发',
    remark: '评论',
    msg1: '取消收藏成功',
    msg2: '所选信息存在已加入简报数据',
    msg3: '加入简报成功',
    msg4: '预警成功',
    msg5: '预警失败',
    msg6: '复制成功',
    link: '链接',
    summary: '摘要',
    accountNumber: '账号',
    site: '站点',
    exportAll: '导出全部',
    dataExport: '数据导出',
    translate: '翻译',
    similarInfoDeduplication: '相似信息去重',
    displayOptions: '展示选项',
    displayOptionsItems: ['正文', '图片'],
    optsSortItems: [
      '综合排序',
      '相关度',
      '热度',
      '发布时间',
      '入库时间',
      '点赞量',
      '转发量',
      '评论量',
      '阅读量',
      '播放量',
      '在看量',
      '入库时间',
      '监测时间',
      '预警时间',
      '互动量'
    ],
    sortWayItems: ['正序', '倒序'],
    matchMethodItems: ['全文', '标题', '站点', '账号'],
    matchMethodTips:
      '站点指网站、电子报刊、APP和论坛贴吧的名称。举例:如果想搜索人民网，在二次筛选中选择“站点“，在输入框中输入“人民网“。',
    analysisPreview: '分析预览',
    trace: '溯源',
    emptyTip: '输入关键词，点击搜索即可查看相关内容',
    nodata: '暂无数据',
    detailsSwitchTip: [
      '键盘中的向上/向下键，可快捷切换上一篇/下一篇',
      '已经是第一条信息',
      '已经是最后一条信息'
    ]
  },
  charts: {
    totalInformation: '信息总量',
    positiveInformation: '正面信息',
    negativeInformation: '负面信息',
    neutralInformation: '中立信息',
    mediaType: '媒体渠道',
    count: '信息量',
    ratio: '占比',
    ChannelRatio: '信息来源占比',
    DownloadPictures: '下载图片',
    ActiveMedia: '活跃媒体',
    area: '地区',
    quantity: '数量',
    MentionRegion: '提及地区',
    HotTopicWords: '热点主题词',
    DataDevelopmentTrend: '数据发展趋势',
    HotArticles: '热门文章',
    ranking: '序号',
    title: '标题',
    heat: '热度',
    PolarityRatio: '情感分布',
    PolarityTrend: '情感走势'
  }
};
