<script lang="ts" setup>
import { ShallowRef, shallowRef, watchEffect } from 'vue';
import BaseChart from '../BaseChart/BaseChart.vue';
import useChartClick from '../hooks/useChartClick';
import { hexToRgb } from '/@/utils/theme';

interface PropDataItem {
  name: string;
  value: number;
  children?: PropDataItem[];
}

interface TreeMapProps {
  data: PropDataItem[];
  tooltip?: Boolean;
  theme?: String;
}

const props = defineProps<TreeMapProps>();
let option: ShallowRef = shallowRef({});
const bigscreenTheme2ColorOption = ['#46ABFF', '#FFBD63', '#63F4FF', '#DB8EFF'];
watchEffect(() => {
  const bigScreenRichData = {};
  const data = props.data.map((item, i) => {
    bigScreenRichData[i + 1] = {
      fontSize: 16,
      lineHeight: 22,
      color: bigscreenTheme2ColorOption[i % bigscreenTheme2ColorOption.length],
      align: 'center'
    };
    return {
      ...item,
      itemStyle: props.theme == 'bigscreen-theme-2' && {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: `#09122F`
            },
            {
              offset: 1,
              color: `rgba(${hexToRgb(
                bigscreenTheme2ColorOption[
                  i % bigscreenTheme2ColorOption.length
                ]
              )},0.5)`
            }
          ]
        },
        borderWidth: 1,
        borderColor:
          bigscreenTheme2ColorOption[i % bigscreenTheme2ColorOption.length]
      }
    };
  });
  option.value = {
    series: {
      type: 'treemap',
      width: '100%',
      height: '100%',
      label: {
        show: true,
        formatter: params => {
          return props.theme == 'bigscreen-theme-2'
            ? `{${params.dataIndex}|${params.data.name}\n${params.data.value}}`
            : `${params.data.name}\n${params.data.value}`;
        },
        rich: props.theme == 'bigscreen-theme-2' ? bigScreenRichData : undefined
      },
      breadcrumb: { show: false },
      data,
      roam: false,
      nodeClick: false
    } as unknown as any
  };
  props.tooltip && (option.value.tooltip = {});
});
interface Emits {
  (e: 'chart:click', params: any): void;
}
const emit = defineEmits<Emits>();
const chartRef = useChartClick(emit);
</script>
<template>
  <base-chart ref="chartRef" :option="option" class="wh-chart--line" />
</template>

<style lang="scss" scoped></style>
