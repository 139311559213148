// 定义内容
export default {
  contactsMenu: {
    menuList: ['帳號設定', '連絡人', '复制模板设置', '帳號權益']
  },
  userInformation: {
    title: '個人資訊',
    userName: '用戶名稱：',
    password: '帳號密碼：',
    nickName: '用戶昵稱：',
    editBtn: '編輯'
  },
  editInformation: [
    {
      title: '修改密碼',
      passwordLabel: ['原始密碼：', '新密碼：', '確認密碼：'],
      placeholder: ['請輸入原始密碼', '請輸入新密碼', '請輸入新密碼'],
      passwordRules: ['密碼長度不少於8位數'],
      contentTip: [
        '1.密碼的長度不少於8位數',
        '2.密碼必須包含英文字母、數位、特殊字元其中兩種或兩種以上（不包括空格）'
      ],
      editBtns: ['確認', '取消']
    },
    {
      title: '修改用戶昵稱',
      nickNameLabel: ['新用戶昵稱：'],
      placeholder: ['請輸入昵稱']
    }
  ],
  updatePasswordRules: {
    passwordEditSuccess: '恭喜！密碼修改成功！',
    oldPassword: '原始密碼不能為空',
    newPassword: '新密碼不能為空',
    confirmPassword: '確認密碼不能為空',
    atypismPassword: '兩次輸入密碼不一致！',
    confirmSameNew: '新密碼不能與舊密碼相同',
    passwordFormat: '密碼格式錯誤，請點擊圖標查看密碼格式詳情。'
  },
  updateNickNameRules: {
    nonEmpty: '輸入不能為空',
    sameAsOldNickName: '昵稱不能為原昵稱',
    updateSuccess: '恭喜！ 昵稱修改成功！',
    format: '昵稱格式錯誤，請重新輸入'
  },
  mycontacts: {
    title: '我的連絡人',
    addContactBtn: '添加微信接收人',
    searchContacts: '請輸入連絡人姓名蒐索',
    orderNumber: '序號',
    nickname: '姓名',
    nicKNamePlaceholder: '請輸入',
    openId: '微訊號',
    createTime: '創建時間',
    operate: '操作',
    operateList: ['編輯', '保存', '删除'],
    unfollowed: '已取消关注'
  },
  addContactsList: {
    title: '添加新用戶',
    scanAdd: '掃碼新增：',
    renovate: '刷新',
    scanTip: '掃描二維碼關注微信公眾號，立享預警服務',
    scanSuccess: '掃碼成功',
    scanName: '姓名:',
    scanNamePl: '請輸入姓名',
    scanMark: [
      '（注：已添加的連絡人可在',
      '“個人中心-連絡人”',
      '中查看或修改）'
    ],
    scanNameRule: '請先輸入姓名',
    modalBtns: ['取消', '保存']
  },
  copyTemplateConfiguration: {
    preview: '效果预览',
    btns: ['編輯', '保存']
  },
  accountInterests: {
    title: '監測、預警權益資訊',
    dayMonitoring: '今日監測總量',
    dayWarning: '今日預警總量',
    totalTip: '共計',
    used: '已用',
    dayWarningTip: '指微信預警日推送數量，平臺內預警提示不計數'
  }
};
