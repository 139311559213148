import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import Evolution from './src/index.vue';

Evolution.install = (app: App): void => {
  app.component('AEvolution', Evolution);
  app.component(
    'AEvolutionProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default Evolution;
