<script lang="ts" setup>
import { init } from 'echarts';
import {
  onBeforeUnmount,
  onMounted,
  ref,
  shallowRef,
  watch,
  inject
} from 'vue';
import { ECharts } from 'echarts';
import type { ECOption } from '@wenhai/visual-components/utils/buildChart';
import type { TTheme } from '@wenhai/visual-components/utils';

const chartRef = ref<HTMLDivElement | null>(null);
// ref echarts 实例会使整个实例变成Proxy对象，resize时会丢失一些数据
// 这里使用 shallowRef
let chart = shallowRef<ECharts | null>(null);

const props = defineProps<{
  option: ECOption;
  theme?: TTheme;
  preventResize?: boolean;
}>();
const resize = () => {
  if (!props.preventResize) {
    chart.value?.resize();
  }
};

watch(
  () => props.option,
  () => {
    chart.value?.setOption(props.option);
  }
);
interface Emits {
  (e: 'chart:click', params: any): void;
}
const emit = defineEmits<Emits>();
onMounted(() => {
  try {
    if (props.theme === 'bigscreen-theme-2') {
      const series: any = props.option.series && props.option.series[0]?.data;
      if (series.length > 5)
        chartRef.value!.style!.height = series.length * 36 + 50 + 'px';
    } else {
      const series: any = props.option.series && props.option.series[0]?.data;
      chartRef.value!.style!.height = series.length * 62 + 50 + 'px';
    }
    const chartIns = init(
      chartRef.value as HTMLDivElement,
      props.theme || 'theme1'
    );
    chart.value = chartIns;
    chart.value?.on('click', obj => {
      emit('chart:click', obj);
    });
    chartIns.setOption(props.option);
    const anaSearch = inject('anaSearch', undefined);
    const chartsClick = inject('chartsClick', undefined);
    const componentDrillType = inject('componentDrillType', undefined);

    window.addEventListener('resize', resize);
    chartIns.on('click', params => {
      if (chartsClick) {
        let chartsData = params;
        chartsData.componentDrillType = componentDrillType;
        anaSearch(chartsData);
      }
    });
  } catch (e) {
    // console.error(e)
  }
});
onBeforeUnmount(() => {
  chart.value?.dispose();
  window.removeEventListener('resize', resize);
});

defineExpose({
  chart: chart
});
</script>
<template>
  <div class="wh-chart" ref="chartRef"></div>
</template>
<style lang="scss" scoped>
.wh-chart {
  width: 100%;
  height: 100%;
}
</style>
