<script lang="ts" setup>
import { computed } from 'vue';
import { TTheme } from '../utils/index';
const props = defineProps<{ description?: string; theme?: TTheme }>();
const description = computed(() => props.description);
</script>
<template>
  <div class="wh-chart wh-chart--overview" :class="props.theme">
    <slot>
      <p v-html="description"></p>
    </slot>
  </div>
</template>
<style lang="scss" scoped>
@import '../style/index.scss';

.wh-chart--overview {
  overflow-y: auto;
  font-size: 14px;
  line-height: 24px;
  &.bigscreen-theme-1 {
    color: #c4d9f3;
  }
}
</style>
