<template>
  <w-form-item :label="label">
    <w-radio-group
      :type="type"
      size="small"
      v-model="value"
      :disabled="disabled"
    >
      <w-radio
        v-for="item in option"
        :key="item.value"
        :value="item.value"
        :disabled="item.disabled"
        >{{ item.label }}</w-radio
      >
    </w-radio-group>
  </w-form-item>
</template>
<script lang="ts">
import { defineComponent, ref, watch, PropType } from 'vue';
export default defineComponent({
  name: 'ConfigRadio',
  props: {
    label: {
      type: String
    },
    modalValue: {
      type: String,
      required: true
    },
    option: {
      type: Object as PropType<
        { label: string; value: string; disabled?: boolean }[]
      >,
      default: []
    },
    type: {
      type: String as PropType<'radio' | 'button'>,
      default: 'radio'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const value = ref(props.modalValue);
    watch(value, val => {
      emit('update:modalValue', val);
      emit('radioChange', val);
    });
    return { value };
  }
});
</script>
