<template>
  <div v-loading="loading" v-nodata="!loading && !Boolean(series.length)">
    <BarChart
      v-if="!loading && series.length"
      ref="refChart"
      :tooltip="tooltip"
      legend
      stack
      :series="series"
      :x-axis="{ data: xAxis }"
      :colors="chartColors"
      v-memo="[]"
      :theme="theme"
      @chart:click="chartClick"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, inject } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { getChartGradientColor } from '@wenhai/visual-components/utils';
import { useEditorStore } from '/@/stores/analysis';
import { useDataCenter } from '../../_hooks/use-data-center';
import { storeToRefs } from 'pinia';
import { i18n } from '/@/i18n/index';
const t = i18n.global.t;
const colors = [
  ['#30DBE9', '#44F1B6'],
  ['#1978E5', '#00D5FF'],
  ['#FF514C', '#FFA736']
];
const emotinOption = [
  {
    label: t('message.analysisDetails.polarityOption[0]'),
    numKey: 'positiveNum',
    percentKey: 'positiveProportion',
    colorStops: getChartGradientColor('linear', colors[0])
  },
  {
    label: t('message.analysisDetails.polarityOption[2]'),
    numKey: 'neutralNum',
    percentKey: 'neutralProportion',
    colorStops: getChartGradientColor('linear', colors[1])
  },
  {
    label: t('message.analysisDetails.polarityOption[1]'),
    numKey: 'negativeNum',
    percentKey: 'negativeProportion',
    colorStops: getChartGradientColor('linear', colors[2])
  }
];
const defaultParams = { dataSource: [] };
export default defineComponent({
  name: 'AEmotionCompare',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const series = ref<any[]>([]);
    const xAxis = ref<any[]>([]);
    const loading = ref(false);
    const paramsObj = computed(() => {
      return props.params;
    });
    const { setCurrentDrillingScheme } = useDataCenter(paramsObj.value);
    const chartsClick = inject('chartsClick', undefined);
    const initDrillingParams = inject('initDrillingParams');
    const EditorStore = useEditorStore();
    const { schemePolarityComparison } = useAnalysisApi();
    const apiData = ref();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        realTime: 0,
        startPubTime: apiData.value?.startPubTime,
        endPubTime: apiData.value?.endPubTime
      };
    };
    const getData = async () => {
      if (!paramsObj.value.schemeIdList.length) return;
      loading.value = true;
      series.value.splice(0);
      xAxis.value.splice(0);
      try {
        const res = await schemePolarityComparison({
          ...paramsObj.value,
          ...defaultParams
        });
        apiData.value = res.data;
        const emotionInfoList = (res.data?.emotionInfoList as any[]).map(
          item => {
            return {
              ...item,
              total: emotinOption
                .map(eitm => {
                  return item[eitm.numKey];
                })
                .reduce((acc, cur) => acc + cur)
            };
          }
        );
        const dataList = emotinOption.map(s => {
          return {
            name: s.label,
            data: emotionInfoList.map(c => {
              return {
                name: c.schemeName,
                value: c[s.numKey],
                percent: c[s.percentKey],
                schemeId: c.schemeId,
                color:
                  props.theme === 'bigscreen-theme-2' ? s.colorStops : undefined
              };
            })
          };
        });
        series.value.push(...dataList);
        xAxis.value.push(...dataList[0].data.map(item => item.name));
        loading.value = false;
        if (chartsClick) {
          setDrillPreviewData();
          const maxTotalSchemeId = emotionInfoList.sort((a, b) => {
            return b.total - a.total;
          })[0].schemeId;
          initDrillingParams({ schemeId: maxTotalSchemeId, ...defaultParams });
        }
      } catch (error) {
        loading.value = false;
      }
    };
    const labelStr = param => {
      const { data } = param as any;
      return `${data.name}\n${data.percent} (${data.value})`;
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const tooltip = {
      trigger: 'axis',
      formatter(val: any[]) {
        let str = `<div style="margin-bottom: 5px;">${val[0].name}</div>`;
        val.forEach((item, i) => {
          str += `<div style="margin-bottom: 5px;display:flex;align-items:center;"><div style="width: 10px;height:10px;border-radius: 50%;background-color:${colors[i][0]};margin-right: 5px;"></div><span>${item.seriesName}</span><span style="padding: 0 10px;font-weight:600;">${item.data.value}</span>${item.data.percent}</div>`;
        });
        return str;
      }
    };
    const chartColors = computed(() => {
      return props.theme === 'bigscreen-theme-2'
        ? colors.map(cs => getChartGradientColor('linear', cs))
        : colors.map(cs => cs[0]);
    });
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(obj.data.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      loading,
      series,
      xAxis,
      getData,
      update,
      labelStr,
      tooltip,
      colors,
      chartColors,
      chartClick
    };
  }
});
</script>
