<template>
  <div v-loading="loading" v-nodata="!Boolean(series.length) && !loading">
    <img v-if="isImage" :src="imageUrl" style="width: 100%; height: 100%" />
    <template
      v-if="!isImage && !loading && series.length && paramsObj.stackType == 0"
    >
      <LineChart
        v-memo="[]"
        ref="refChart"
        v-if="paramsObj.chartType == 'line'"
        :smooth="true"
        :series="series"
        :x-axis="{ data: xAxis }"
        y-axis
        tooltip
        :theme="theme"
        dataZoom
        @chart:click="chartClick"
      />
      <BarChart
        v-memo="[]"
        ref="refChart"
        v-if="paramsObj.chartType == 'bar'"
        tooltip
        stack
        :series="series"
        :x-axis="{ data: xAxis }"
        @chart:click="chartClick"
      />
    </template>
    <template
      v-if="!isImage && !loading && series.length && paramsObj.stackType != 0"
    >
      <LineChart
        @chart:click="chartClick"
        ref="refChart"
        v-if="paramsObj.chartType == 'line'"
        :smooth="true"
        :series="series"
        :x-axis="{ data: xAxis }"
        y-axis
        tooltip
        legend
        :theme="theme"
        :key="updateKey"
        :color="
          paramsObj.stackType == 3
            ? ['#f0a431', '#2055f4', '#ed6363']
            : undefined
        "
      />
      <BarChart
        @chart:click="chartClick"
        v-memo="[]"
        ref="refChart"
        v-if="paramsObj.chartType == 'bar'"
        tooltip
        legend
        stack
        :series="series"
        :x-axis="{ data: xAxis }"
        :colors="
          paramsObj.stackType == 3
            ? ['#f0a431', '#2055f4', '#ed6363']
            : undefined
        "
      />
    </template>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  nextTick,
  toRefs,
  inject,
  watch,
  toRef
} from 'vue';
import useAnalysisApi from '/@/api/analysis/index.ts';
import { isImage, getChartImage, utilDebounce } from '../../utils';
import { getSourceName } from '/@/utils/other';
import {
  getPolarityName,
  getArticleTypeName,
  getPolarityColor
} from '/@/utils/other';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { cloneDeep } from 'lodash-es';
import { getDateByInterval } from '../../utils';
import { useDataCenter } from '../../_hooks/use-data-center';
export default defineComponent({
  // 媒体曝光趋势
  name: 'AExposureTrend',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const updateKey = ref();
    watch(toRef(props, 'theme'), () => {
      updateKey.value = new Date().getTime();
    });
    const refChart = ref();
    const imageUrl = ref('');
    const { exposureTrend } = useAnalysisApi();
    const paramsObj = computed(() => {
      return props.params;
    });
    const { setCurrentDrillingScheme } = useDataCenter(paramsObj.value);
    const xAxis = ref<any[]>([]);
    const series = ref<any[]>([]);
    const loading = ref(false);
    const setTime = (intervalTime: string) => {
      let time = intervalTime.substr(5, 11);
      if (props.params.dateInterval === 'DAY') {
        time = time.split(' ')[0];
      }
      if (props.params.dateInterval === 'MONTH') {
        time = time.substring(0, 2) + '月';
      }
      return time;
    };
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const apiData = ref();
    const setDrillPreviewData = () => {
      const itemList =
        apiData.value?.noStackResult || apiData.value?.stackedResult || [];
      drillPreviewData.value = {
        startPubTime: itemList[0].startPubTime,
        endPubTime: itemList[0].endPubTime,
        aeResultData: itemList
      };
    };
    const getData = async () => {
      if (!paramsObj.value.schemeId) return;
      loading.value = true;
      xAxis.value.splice(0);
      series.value.splice(0);
      try {
        const res = await exposureTrend(paramsObj.value);
        if (res.code === 200) {
          apiData.value = res.data;
          const resData = res.data as any;
          if (paramsObj.value.stackType == 0) {
            // 非堆叠数据
            const yAxis = [] as any[];
            const dateInterval = resData?.noStackResult?.dateInterval;
            resData?.noStackResult?.itemList.forEach((item: any) => {
              const intervalTime = getDateByInterval(
                item.intervalTime,
                dateInterval
              );
              xAxis.value.push(intervalTime);
              yAxis.push({ name: item.intervalTime, value: Number(item.sum) });
            });
            series.value.push({ data: yAxis });
            if (chartsClick) {
              setDrillPreviewData();
              searchResultList();
            }
          } else {
            // 堆叠数据
            // let xResetData = []
            // resData.stackedResult.forEach((item) => {
            // 	item.articleTypeExposureTrend.forEach((i) => {
            // 		xResetData.push(i.intervalTime)
            // 	})
            // })
            // let reData = [...new Set(xResetData)].sort()
            // reData.forEach((item) => {
            // 	xAxis.value.push(setTime(item))
            // })
            const dateInterval = resData?.stackedResult[0]?.dateInterval;
            resData.stackedResult.forEach((item: any) => {
              const data = item.articleTypeExposureTrend.map((itm: any) => {
                const intervalTime: string = getDateByInterval(
                  itm.intervalTime,
                  dateInterval
                );
                !xAxis.value.includes(intervalTime) &&
                  xAxis.value.push(intervalTime);
                return {
                  name: item.label,
                  value: Number(itm.sum),
                  color:
                    paramsObj.value.stackType === 3
                      ? getPolarityColor(item.type)
                      : undefined
                };
              });
              series.value.push({
                name:
                  paramsObj.value.stackType === 3
                    ? getPolarityName(item.type)
                    : paramsObj.value.stackType === 7
                    ? // ? getSourceName(item.type, 'analysisName')
                      item.label
                    : paramsObj.value.stackType === 9
                    ? getArticleTypeName(item.type)
                    : item.type,
                data
              });
            });
            if (chartsClick) {
              setDrillPreviewData();
              searchResultList();
            }
          }
          nextTick(() => {
            saveChartImage();
          });
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const saveChartImage = utilDebounce(() => {
      refChart.value && (imageUrl.value = getChartImage(refChart));
    }, 300);
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(paramsObj.value.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      refChart,
      isImage,
      imageUrl,
      xAxis,
      series,
      loading,
      paramsObj,
      update,
      saveChartImage,
      updateKey,
      chartClick
    };
  }
});
</script>
