<template>
  <div
    v-nodata="
      params.mediaAccountList?.length || state.loading
        ? false
        : { tip: $t('message.analysisDetails.mediaListNodataTip') }
    "
  >
    <div class="media-list-header">
      <div class="content">
        <w-tabs :active-key="tabsKey" @change="tabsChange">
          <w-tab-pane
            v-for="item in tabOption"
            :key="item.code"
            :title="item.name"
            :disabled="state.loading"
          ></w-tab-pane>
        </w-tabs>
      </div>
      <w-tooltip
        :content="$t('message.analysisList.listBtn[1]')"
        v-if="params.mediaAccountList?.length"
      >
        <c-icon
          style="cursor: pointer"
          size="20"
          class="icon"
          color="var(--color-text-2)"
          @click="copy"
          name="fuzhi"
        />
      </w-tooltip>
    </div>
    <div v-loading="state.loading" class="media-list-table">
      <w-table
        v-if="data.length && params.mediaAccountList?.length"
        :columns="state.columns"
        :data="data"
        :pagination="false"
        :sticky-header="true"
      >
        <template #columns>
          <w-table-column
            ellipsis
            v-for="(item, index) in state.columns"
            :key="index"
            :sortable="item.sortable"
            :data-index="item.dataIndex"
            :title="item.title"
            :width="item.width"
          >
            <template #cell="{ rowIndex, record }">
              <template v-if="item.dataIndex === 'ranking'">
                <div class="wh-item--index ranking-first" v-if="rowIndex === 0">
                  {{ rowIndex + 1 }}
                </div>
                <div
                  class="wh-item--index ranking-two"
                  v-else-if="rowIndex === 1"
                >
                  {{ rowIndex + 1 }}
                </div>
                <div
                  class="wh-item--index ranking-third"
                  v-else-if="rowIndex === 2"
                >
                  {{ rowIndex + 1 }}
                </div>
                <div class="wh-item--index" v-else>{{ rowIndex + 1 }}</div>
              </template>
              <div
                v-if="item.dataIndex === 'userName'"
                style="display: flex; align-items: center"
              >
                <img
                  :src="getMediaIcon(record.dataSourceType)"
                  style="width: 14px; margin-right: 4px"
                />{{ record.userName }}
              </div>
              <div v-else>{{ record[item.dataIndex] }}</div>
            </template>
          </w-table-column>
        </template>
      </w-table>
      <!-- <div class="pagination">
        <w-pagination :total="20" show-page-size show-jumper/>
        <span>页</span>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { ElMessage } from 'element-plus/es';
import { getMediaIcon } from '/@/utils/other';
import { useI18n } from 'vue-i18n';
const tabsdata = [
  { value: 'weixin', name: '微信', code: '00105' },
  { value: 'weibo', name: '微博', code: '00301' },
  {
    value: 'duanshiping',
    name: '短视频',
    code: '00401,00402,00400,10401,10400'
  },
  { value: 'X(Twitter)', name: 'X(Twitter)', code: '10302' },
  { value: 'Facebook', name: 'Facebook', code: '10301' }
];
export default defineComponent({
  // 热点榜单
  name: 'AMediaList',
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const { mediaList } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const state = reactive<any>({
      columns: [],
      sortInfo: {
        sortField: 1,
        sortWay: 'desc'
      },
      tabsName: '微信',
      tabsValue: '00105',
      loading: false
    });
    const stateData = reactive({
      columns: [
        {
          title: t('message.analysisDetails.mediaListColumn[0]'), // 排行
          dataIndex: 'ranking',
          slotName: 'ranking',
          width: 60
        },
        {
          title: t('message.analysisDetails.mediaListColumn[1]'), // 账号
          dataIndex: 'userName',
          width: 245
        },
        {
          title: t('message.analysisDetails.mediaListColumn[2]'), // 发文量
          dataIndex: 'count',
          sortable: {
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'descend'
          }
        },
        {
          title: t('message.analysisDetails.mediaListColumn[3]'), // 获赞量
          dataIndex: 'likeCount',
          sortable: {
            sortDirections: ['ascend', 'descend']
          }
        },
        {
          title: t('message.analysisDetails.mediaListColumn[4]'), // 评论量
          dataIndex: 'commentCount',
          sortable: {
            sortDirections: ['ascend', 'descend']
          }
        },
        {
          title: t('message.analysisDetails.mediaListColumn[5]'), // 负面发文
          dataIndex: 'negativeCount',
          sortable: {
            sortDirections: ['ascend', 'descend']
          }
        },
        {
          title: t('message.analysisDetails.mediaListColumn[6]'), // 转发量
          dataIndex: 'rttCount',
          sortable: {
            sortDirections: ['ascend', 'descend']
          }
        },
        {
          title: t('message.analysisDetails.mediaListColumn[7]'), // 在看量
          dataIndex: 'watchCount',
          sortable: {
            sortDirections: ['ascend', 'descend']
          }
        },
        {
          title: t('message.analysisDetails.mediaListColumn[8]'), // 阅读量
          dataIndex: 'readCount',
          sortable: {
            sortDirections: ['ascend', 'descend']
          }
        }
      ],
      sortInfo: {
        sortField: 0,
        sortWay: 'desc'
      },
      tabsName: '微信',
      tabsValue: '00105',
      loading: false
    });
    //列表
    const data = ref<any[]>([]);

    const tabOption = computed(() => {
      return tabsdata.filter(item => {
        return params.value.mediaAccountList?.find(dt => {
          return (
            item.code.includes(dt.dataSourceType) ||
            dt.dataSourceType.includes(item.code)
          );
        });
      });
    });
    const tabsKey = ref<string>(tabOption.value[0]?.code);

    function getData() {
      state.loading = true;
      data.value.splice(0);
      params.value.id = params.value.mediaListAccountId;
      params.value.sortInfo = state.sortInfo;
      params.value.dataSource = [
        {
          children: null,
          label: state.tabsName,
          type: 0,
          value: state.tabsValue
        }
      ];
      delete params.value.dataSourceList;

      if (params.value.mediaAccountList.length) {
        const mediaAccountList = params.value.mediaAccountList.filter(item => {
          return tabsKey.value.includes(item.dataSourceType);
        });
        const pm = { ...params.value, mediaAccountList };
        mediaList(pm)
          .then((res: any) => {
            data.value.push(...res.data.data);
          })
          .finally(() => {
            state.loading = false;
          })
          .catch(err => {});
      } else {
        state.loading = false;
      }
    }

    onMounted(() => {
      tabsChange(tabsKey.value);
    });
    const update = () => {
      tabsChange(tabOption.value[0]?.code);
    };

    // 排序
    function handleSorterList(dataIndex: string, direction: string) {
      if (direction && direction === 'ascend') {
        state.sortInfo.sortWay = 'asc';
      } else if (direction && direction === 'descend') {
        state.sortInfo.sortWay = 'desc';
      }
      switch (dataIndex) {
        case 'count':
          state.sortInfo.sortField = 0;
          break;
        case 'likeCount':
          state.sortInfo.sortField = 1;
          break;
        case 'commentCount':
          state.sortInfo.sortField = 2;
          break;
        case 'rttCount':
          state.sortInfo.sortField = 3;
          break;
        case 'negativeCount':
          state.sortInfo.sortField = 4;
          break;
        case 'readCount':
          state.sortInfo.sortField = 5;
          break;
        case 'watchCount':
          state.sortInfo.sortField = 6;
          break;
      }
      getData();
    }
    function tabsChange(code: string) {
      tabsKey.value = code;
      if (code === tabsdata[0].code) {
        state.columns = stateData.columns.filter(
          item => item.title !== t('message.analysisDetails.mediaListColumn[6]')
        );
      } else {
        state.columns = stateData.columns.filter(
          item =>
            item.title !== t('message.analysisDetails.mediaListColumn[8]') &&
            item.title !== t('message.analysisDetails.mediaListColumn[7]')
        );
      }
      const source = tabsdata.find(item => {
        return item.code == code;
      });
      if (!source) return;
      state.tabsName = source.name;
      state.tabsValue = source.code;
      getData();
    }

    function copyInfo(str: string) {
      const textarea = document.createElement('textarea');
      textarea.value = str;
      // textarea.style.display = 'none'
      window.document.body.appendChild(textarea);
      textarea.select();
      window.document.execCommand('copy');
      textarea.remove();
      return true;
    }

    // 复制
    function copy() {
      let str = '';
      let checkboxSelected = [];
      let tableHeader = [];
      if (tabsKey.value === '1') {
        checkboxSelected = [
          'userName',
          'count',
          'likeCount',
          'commentCount',
          'watchCount',
          'readCount',
          'negativeCount'
        ];
        tableHeader = [
          {
            userName: t('message.analysisDetails.mediaListColumn[1]'), // 账号
            count: t('message.analysisDetails.mediaListColumn[2]'), // 发文量
            likeCount: t('message.analysisDetails.mediaListColumn[3]'), // 获赞量
            commentCount: t('message.analysisDetails.mediaListColumn[4]'), // 评论量
            watchCount: t('message.analysisDetails.mediaListColumn[7]'), // 在看量
            readCount: t('message.analysisDetails.mediaListColumn[8]'), // 阅读量
            negativeCount: t('message.analysisDetails.mediaListColumn[5]') // 负面发文
          }
        ];
      } else {
        checkboxSelected = [
          'userName',
          'count',
          'likeCount',
          'commentCount',
          'rttCount',
          'negativeCount'
        ];
        tableHeader = [
          {
            userName: t('message.analysisDetails.mediaListColumn[1]'), // 账号
            count: t('message.analysisDetails.mediaListColumn[2]'), // 发文量
            likeCount: t('message.analysisDetails.mediaListColumn[3]'), // 获赞量
            commentCount: t('message.analysisDetails.mediaListColumn[4]'), // 评论量
            rttCount: t('message.analysisDetails.mediaListColumn[6]'), // 转发量
            negativeCount: t('message.analysisDetails.mediaListColumn[5]') // 负面发文
          }
        ];
      }
      const copyData = [...tableHeader, ...data.value];
      copyData.forEach(item => {
        // \t:跳到下一制表符位置   \n：换行
        for (let i = 0; i < checkboxSelected.length; i++) {
          if (i + 1 < checkboxSelected.length) {
            str += item[checkboxSelected[i]] + '\t';
          } else {
            str += item[checkboxSelected[i]] + '\n';
          }
        }
      });
      copyInfo(str);
      ElMessage.success(t('message.analysisDetails.duplicationSuccess'));
    }

    return {
      data,
      update,
      state,
      handleSorterList,
      tabsChange,
      copy,
      getMediaIcon,
      tabsdata,
      tabOption,
      tabsKey
    };
  }
});
</script>
<style lang="scss" scoped>
@use '../../../../../visual-components/style/_mixins.scss' as m;

.media-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  /*表单tab按钮样式开始*/
  .content {
    display: flex;
    justify-content: space-between;

    :deep(.w-tabs-nav:before) {
      background: #ffffff;
    }

    :deep(.w-input-search) {
      width: 220px;
      border-radius: 25px;
      margin-bottom: 10px;
      margin-top: -5px;
    }
  }
}

.media-list-table {
  height: 90%;
  overflow-y: auto;

  :deep(.w-table-th) {
    border-bottom: none;
  }

  // :deep(.w-table-cell) {
  //   text-align: center;
  // }
  // :deep(.w-table-cell-with-sorter) {
  //   justify-content: center
  // }
  :deep(.w-table-td) {
    border-bottom: none;
  }

  :deep(.w-table .w-table-cell) {
    padding: 8px 16px;
  }
  :deep(.w-table table) {
    width: auto;
  }
  @include m.indexBox($className: 'wh-item--index', $top: 0, $right: 0);

  .ranking-first {
    background: #fe7770;
    color: #fff;
  }

  .ranking-two {
    background: #ff9030;
    color: #fff;
  }

  .ranking-third {
    background: #6093ff;
    color: #fff;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;

    span {
      margin-left: 8px;
    }
  }
}

/*表单tab按钮样式结束*/
</style>
