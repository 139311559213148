export default {
  monitorReceive: {
    helpContent1:
      '1. 最多支持設置3組關鍵詞,同時輸入框詞與詞之間以回車間隔,為OR關係。',
    helpContent2: '2. 不同輸入框為AND關係。',
    helpContent3: '3. 允許輸入漢字、英文、數字、特殊字符,長度小於100個字符。',
    postComments:
      '發文評論，是指方案命中發文的所有評論信息；只有儅方案開啓“信源監測”時可用。',
    schemeName: '方案名稱不可為空',
    wordSpan: '關鍵詞詞句範圍不可為空',
    messageSourceConfig: '賬號不可為空',
    rules: {
      schemeName: [
        { required: true, message: '方案名称不可为空' }
        // { match: /^[a-zA-Z0-9_\( \)\（ \）\-\u4e00-\u9fa5]+$/, message: '只允许输入汉字，英文，数字，括号，中划线-，下划线_' },
      ],
      wordSpan: [{ required: true, message: '关键词词距范围不可为空' }],
      messageSourceConfig: [
        { required: true, message: '账号不可为空' },
        {
          validator: (val: any[], callback: (error: string) => void) => {
            !val.length && callback('error');
          },
          message: '账号不可为空'
        }
      ]
    },
    keywordTip:
      '1.可輸入多個關鍵詞，關鍵詞之間用回車間隔，為“或”關係。, 2.地域詞、主體詞、事件詞三個之間是“與”關係，只有同時滿足這3個關鍵詞的文章才會被監測到。例：地域詞輸入“北京”，主體詞輸入“張三”，事件詞輸入“收紅包”，只有同時匹配這3個關鍵詞的文章才會被監測到。',
    exculedKeywordTip:
      '1.可輸入多個關鍵詞，關鍵詞之間用回車間隔，為“或”關係。, 2.命中排除關鍵詞的資訊，將不進入系統。',
    wordSpanTip: [
      '1.地域词、主体词、事件词在信息中出现的间距值。词距范围0~500。',
      '2.满足词间距的信息，将出现在精准信息列表中。'
    ],
    wordSpanTipNew:
      '1.詞距匹配指地域詞、主體詞、事件詞三組分別取其中任意關鍵字，在資訊中出現的間距值，詞距可選範圍0-500；,2.地域詞、主體詞、事件詞滿足詞間距將出現在監測信息清單中；,3.地域詞、主體詞、事件詞任意為空則忽略該組詞，關閉詞距匹配將按照關鍵字命中規則進行監測。',
    accountTip:
      '1.可指定媒體、帳號進行定向監測。, 2.未配置關鍵詞將展示媒體/帳號全部資料，配置關鍵詞後將展示媒體/帳號匹配關鍵詞的資料。',
    accountTipGetUser:
      '1.可指定媒體、帳號進行定向監測。, 2.未配置關鍵詞將展示媒體/帳號全部資料，配置關鍵詞後將展示媒體/帳號匹配關鍵詞的資料。, 3.開啓信源監測后，可針對目標信源發文的評論進行預警。',
    EditorialSolutions: '編輯方案',
    CreatedSolutions: '創建监测方案',
    ProgramName: '方案名稱',
    ProgramNameholder: '請輸入方案名稱',
    ContentMonitoring: '內容監測',
    Geographicalwords: '地域詞',
    Mainwords: '主體詞',
    Eventwords: '事件詞',
    errTip: '地域詞、主體詞、事件詞不可同時為空',
    ExclusionWords: '排除詞',
    Precisesettings: '詞距匹配',
    Keywordspacingrange: '關鍵詞詞句範圍',
    TargetedMonitoring: '定向監測',
    MonitoringCancellation: '取消',
    Monitoringpreservation: '保存',
    Preview: '預覽',
    PatternOfExpression: '表達式模式',
    StandardMode: '標準模式',
    MonitorKeyWords: '檢測關鍵詞',
    MonitorKeyWordsTip: [
      `1.公式可包括"与"，"或"，"非"的关系，用"&"代表"与"，"{'|'}"代表"或"，"!"代表"非"；`,
      `2.公式中的每组关键词以"()"包含；`,
      `示例:(北京&冬奥)&(滑雪{'|'}滑冰{'|'}冰球{'|'}雪橇)!(雪车{'|'}冰壶) (中国{'|'}北京)&(科技冬奥{'|'}冰雪科技)；`
    ],
    confirm: '确认',
    reference: '引用方案',
    schemeList: '方案列表',
    copy: '复制',
    popTip: '切换到标准模式，将清空当前设置，确定切换标准模式吗？',
    informationSourceMonitoring: '信源监测',
    informationSourceMonitoringBtn: ['定向信源', '排除信源'],
    specifiedInfoSourceTip: [
      '1.可选多个信源，信源之间匹配关系为“或”',
      '2.未配置关键词将展示信源全部数据，配置关键词后将展示信源中匹配上关键词的数据'
    ],
    excludeInfoSourceTip: [
      '1.可选多个信源，信源之间匹配关系为“或”',
      '2.排除信源发布内容，不进入监测方案'
    ],
    dataInfo: '数据筛选',
    yayi: '雅意'
  },
  TargetedMonitoring: {
    all: '全部',
    placeholder: '請輸入信源名稱、賬號或網站域名',
    search: '查詢',
    specialPre: '特殊字符搜索匹配規則：目前支持',
    specialBeh: '模糊搜索',
    titles: '信源列表,已選信源',
    nodata: '暫無數據',
    noCheckdata: '未選擇數據',
    loder: '加載中...',
    intheEnd: '到底了',
    contentPre: '確認清空所有',
    contentBeh: '嗎？',
    filter: '篩選已選字段',
    confirm: '確定'
  },
  MonitorIndex: {
    name: '專家建議',
    goback: '返回',
    list: '信息列表',
    photo: '圖表分析',
    waring: '預警設置',
    nodata: '還沒有添加監測方案，趕快去創建吧',
    add: '添加方案',
    MonitoringProgram: '監測方案',
    CreatProgram: '創建方案',
    CreateCategory: '創建文件夾',
    edit: '编辑',
    modalPlaceholder: [
      '分类名称不可为空',
      '分类名称长度不可超过20个字符',
      '请输入分类名称'
    ],
    modalTitle: ['创建文件夹', '编辑文件夹', '删除文件夹', '删除方案'],
    deleteTip: [
      '删除分类将同时删除分类下所有方案，确定要删除该分类吗？',
      '确定要删除选中方案吗？'
    ],
    filterTip: '输入名称',
    treeNoDataTip: '提示：暂无'
  },
  filter: {
    ReadAll: '全部',
    ReadingStatus: '閱讀狀態',
    Read: '已讀',
    Unread: '未讀',
    pubtime: '發佈時間',
    warehousetime: '入庫時間',
    Emotional: '情感傾向',
    mediaType: '媒體類型',
    time1: '今天',
    time2: '進24小時',
    time3: '進7天',
    time4: '自定義',
    time5: '進3天',
    time14: '進14天',
    Emotional1: '正面',
    Emotionalf1: '正面',
    Emotional2: '中立',
    Emotionalf2: '中立',
    Emotional3: '負面',
    Emotionalf3: '负面',
    Emotionalf4: '无关',
    EmotionalfTip: '由雅意大模型提供',
    codeType: '數據類型',
    screening: '精準篩選',
    sameindex: '相似信息',
    codeType1: '原創',
    codeType2: '轉發',
    codeType3: '評論',
    codeType4: '發文評論',
    screening1: '全部',
    screening2: '精準',
    sameindex1: '去重',
    sameindex2: '不去重',
    Morefilters: '更多篩選',
    search: '查詢',
    Filtercriteria: '篩選條件',
    resite: '重置',
    moreSearch: '查詢',
    all: '全部',
    content: '全文匹配',
    titlePick: '標題匹配',
    keyCodePlaceholder: '請輸入關鍵詞,回車鍵確認',
    Geographical: '地域識別',
    IP: 'IP屬地',
    MediaProperties: '媒體屬地',
    Contentmentions: '內容提及',
    saveFilters: '保存筛选',
    saveFiltersTip: [
      '1. 保存的筛选条件仅对当前监测方案生效',
      '2. 保存后，该方案下次打开，默认加载保存的筛选条件',
      '3. 发布时间/监测时间-自定义时间筛选条件无法保存'
    ],
    filtersTab: ['账号属性', '地域/语言', '高级筛选'],
    monitoringTime: '监测时间'
  },
  alertSettings: {
    system: '系統預警彈框',
    title: '輿情預警',
    open: '開',
    close: '關',
    titleTip:
      '根據設置規則，自動發送預警信息至預警聯繫人。以便更精準、及時掌握輿情信息。',
    Earlywarning: '預警接收設置',
    warningWay: '接收方式:',
    weixin: '微信公眾號接收',
    newContact: '+ 新增聯繫人',
    oldContact: '+ 從聯繫人中添加',
    name: '姓名',
    namePlaceholder: '請輸入姓名',
    pubtime: '創建時間',
    operation: '操作',
    editor: '編輯',
    noContact: '您還沒有添加微信聯繫人',
    receivingTime: '接收時間:',
    everyday: '每天',
    workday: '工作日',
    EWF: '預警頻次:',
    EWC: '預警內容設置',
    EWkeycode: '預警詞',
    EWplaceholder: '請輸入預警詞，用回車間隔',
    add: '添加',
    delete: '刪除',
    ExclusionWords: '排除詞',
    ExWplaceholder: '請輸入排除詞，用回車間隔',
    tend: '情感傾向:',
    mediatype: '媒體類型:',
    Precisescreening: '精準篩選:',
    target: '命中位置:',
    inexType: '數據類型:',
    cancel: '取消',
    save: '保存',
    addUser: '添加新用戶',
    ScanCode: '掃碼新增：',
    refresh: '刷新',
    ScanCodeTip: '掃描二維碼關注微信公眾號，立享預警服務',
    SacnCodeSuccess: '掃碼成功',
    formName: '姓名:',
    formNameRequire: '請輸入姓名',
    formNamePre: '（注：已添加的聯繫人可在',
    formNameMid: '“個人中心-我的聯繫人”',
    formNameEnd: '中查看或修改）',
    myContact: '我的聯繫人',
    wxContactPre: '微信聯繫人',
    wxContactMid: '共',
    wxContactEnd: '位成員',
    inputContact: '輸入聯繫人',
    Realtime: '實時預警',
    minutes5: '5分鐘',
    minutes10: '10分鐘',
    minutes30: '30分鐘',
    minutes60: '1小時',
    minutes120: '2小時',
    minutes240: '4小時',
    minutes480: '8小時',
    minutes720: '12小時',
    minutes1440: '1天',
    pleaseInputName: '請輸入姓名',
    informationClassification: '信息分類:',
    analogInformation: '相似信息:',
    weChatID: '微信号'
  },
  messageTip: {
    isRead: '已讀',
    creatError: '創建失敗',
    creatSuccess: '創建成功',
    editorSuccess: '修改成功',
    editorError: '修改失敗',
    keycodeLimit: '關鍵詞總數不得超過',
    keycodeLimitEnd: '個',
    Nonrepeatable: '關鍵詞不可重複',
    codeLengthLimit: '單個關鍵詞字符長度最大為',
    alreadyexists: '該聯繫人已存在',
    noSame: '不可重複添加聯繫人',
    setContact: '請設置微信聯繫人後保存',
    choose: '請選擇方案後操作',
    addWXcontact: '請添加微信聯繫人',
    Alread: '已存在預警設置信息，不可新增',
    KeycodeAndExclusionWords: '預警詞與排除詞不可重複',
    keycodeLength: '關鍵詞字符長度超過100',
    startTime: '開始時間必須小於結束時間',
    saveSuccess: '保存成功',
    Noname: '姓名不能為空',
    ScanTheCode: '請先掃碼關注',
    InputName: '請輸入姓名',
    keyCodeRepeat: '關鍵詞不可重複',
    MaxLength: '單個關鍵詞字符長度最大為'
  },
  yayiModel: {
    poweredAnalysis: '智能分析',
    analysisContents: '分析内容',
    example: '效果示例',
    analysisContentsItem: [
      '文章概要',
      '情感分析',
      '主体识别',
      '核心观点',
      '处置建议',
      '内容识别'
    ],
    analysisContentsTab: ['分析主体', '知识背景', '情感倾向', '内容识别'],
    analysisContentsAdd: '新增',
    analysisContentsColumn: [
      '序号',
      '主体名称',
      '知识背景',
      '情感负面类型',
      '内容识别标签',
      '操作'
    ],
    deRowTip: [
      [
        '说明：非必填，如填写大模型将基于配置主体立场的视角进行分析，最多三项',
        '示例：政府管理、中关村街道'
      ],
      [
        '说明：非必填，如部分内容无法关联分析时，可补充您关注领域的相关知识，最多三项',
        '示例：中关村、燕园、清华园街道均属于海淀区'
      ],
      [
        '说明：非必填，请列举需要大模型重点关注的负面类型，最多十个负面类型',
        '示例：自然灾害、环境污染、安全事故'
      ],
      [
        '说明：启用后必填，可填写1-5项，请列举需要大模型重点关注的内容识别标签',
        '示例：旅游投诉、民生投诉、消费者维权'
      ]
    ]
  }
};
