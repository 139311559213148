<template>
  <div v-loading="loading" v-nodata="!Boolean(data) && !loading" class="theme">
    <overview
      v-if="data && !loading && Local.get('globalI18n') !== 'en'"
      :key="data.startTime"
      :theme="anlysisConfig?.extendedConfig?.bigScreenTheme"
    >
      <p>
        本次分析的监测时间为 {{ data.startTime }} 至 {{ data.endTime }} ， 共计
        <span class="color-primary">{{ data.total }}</span> 条数据 。
      </p>
      <p v-if="data.sourceData.length">
        渠道来源中
        <template v-for="(item, i) in data.sourceData" :key="item.source"
          >“{{ item.label }}”<span class="color-primary">{{ item.count }}</span
          >条数据{{ i < data.sourceData.length - 1 ? '、' : '。' }}</template
        >
      </p>
      <p>
        本次分析共监测到数据{{ data.total }}篇， 日均传播量{{
          data.averageDailyTransmission
        }}，情感倾向正面比例占比{{ data.positiveProportion }}、中立占比{{
          data.neutralProportion
        }}、负面占比{{ data.negativeProportion }}。
      </p>
    </overview>
    <overview
      v-if="data && !loading && Local.get('globalI18n') === 'en'"
      :key="data.startTime"
      :theme="anlysisConfig?.extendedConfig?.bigScreenTheme"
    >
      <p>
        The monitoring period for this analysis is from {{ data.startTime }} to
        {{ data.endTime }} , with a total of
        <span class="color-primary">{{ data.total }}</span> pieces of data .
      </p>
      <p v-if="data.sourceData.length">
        Among the media sources,
        <template v-for="(item, i) in data.sourceData" :key="item.source">
          <span class="color-primary">{{ item.count }}</span> pieces of data are
          from “{{ item.label }}”
          {{ i < data.sourceData.length - 1 ? ',' : '.' }}
        </template>
      </p>
      <p>
        A total of {{ data.total }} data were monitored in this analysis, with
        an average daily spread of {{ data.averageDailyTransmission }},where the
        positive sentiment accounts for {{ data.positiveProportion }},neutral
        accounts for {{ data.neutralProportion }},and negative accounts for
        {{ data.negativeProportion }}.
      </p>
    </overview>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { transRealTime } from '/@/utils/other';
import { useEditorStore } from '/@/stores/analysis';
import { Local } from '/@/utils/storage';

export default defineComponent({
  // 概述
  name: 'ASummarize',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const { overview } = useAnalysisApi();
    const editStore = useEditorStore();
    const anlysisConfig = computed(() => {
      return editStore.anlysisConfig;
    });
    const loading = ref(false);
    const params = computed(() => {
      return props.params;
    });
    const data = ref<any>();
    const getData = async () => {
      if (!params.value.schemeId) return;
      data.value = null;
      loading.value = true;
      try {
        const res = await overview(params.value);
        if (res.code === 200) {
          const resData = res.data as any;
          const sourceData =
            resData?.dataSourceCountBean?.dataSourceList.filter(
              (item: any) => item.count
            ) || [];
          const dateRange =
            params.value.realTime === 0
              ? [params.value.startPubTime, params.value.endPubTime]
              : transRealTime(params.value.realTime);
          data.value = {
            startTime: dateRange[0],
            endTime: dateRange[1],
            sourceData,
            total: resData?.dataSourceCountBean.totalCount,
            averageDailyTransmission:
              resData.dataSourceCountBean.averageDailyTransmission,
            positiveProportion: resData.emotionCount.positiveProportion || '-',
            neutralProportion: resData.emotionCount.neutralProportion || '-',
            negativeProportion: resData.emotionCount.negativeProportion || '-'
          };
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      loading,
      data,
      update,
      anlysisConfig,
      Local
    };
  }
});
</script>
<style scoped lang="scss">
p {
  text-indent: 2em;
}
.bigscreen-theme-2 {
  p {
    color: #fff;
  }
  .color-primary {
    color: #56d5e2;
  }
}
</style>
