<template>
  <div v-loading="loading" v-nodata="!Boolean(series.length) && !loading">
    <img v-if="isImage" :src="imageUrl" style="width: 100%; height: 100%" />
    <template v-if="!isImage">
      <RingChart
        ref="refChart"
        v-if="params.chartType == 'pie' && !loading && series.length"
        :data="series"
        :layout="layout"
        tooltip
        :label="theme != 'bigscreen-theme-2'"
        :labelStr="labelStr"
        :theme="theme"
        @chart:click="chartClick"
      />
      <BarChart
        ref="refChart"
        v-if="params.chartType == 'bar' && !loading && series.length"
        tooltip
        legend
        stack
        :series="series"
        :x-axis="
          !theme ? { data: xAxis } : { data: xAxis, axisLabel: { rotate: 60 } }
        "
        :theme="theme"
        @chart:click="chartClick"
      />
    </template>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  nextTick,
  inject
} from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { isImage, getChartImage, utilDebounce } from '../../utils';
import { getPolarityName, getArticleTypeName } from '/@/utils/other';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { cloneDeep } from 'lodash-es';
import { useDataCenter } from '../../_hooks/use-data-center';
export default defineComponent({
  // 渠道声量分析
  name: 'AChannelVolume',
  props: {
    params: {
      type: Object,
      required: true
    },
    layout: {
      type: Object,
      default: {}
    },
    theme: {
      type: String
    },
    drilling: Boolean
  },
  setup(props, { emit }) {
    const refChart = ref();
    const imageUrl = ref('');
    const { datasourceSound } = useAnalysisApi();
    const xAxis = ref<any[]>([]);
    const series = ref<any[]>([]);
    const loading = ref(false);
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const EditorStore = useEditorStore();
    const anlysisConfig = computed(() => {
      return EditorStore.anlysisConfig;
    });
    const { drillPreviewData } = storeToRefs(EditorStore);
    const labelStr = param => {
      const { data } = param as any;
      let count = numFormat(data.value);
      return `${data.name} ${count} (${data.percent})`;
    };
    const apiData = ref();
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        startPubTime: apiData.value?.startPubTime,
        endPubTime: apiData.value?.endPubTime,
        stackType: props.params.stackType,
        aeResultData:
          apiData.value?.noStackResult || apiData.value?.stackedResult || []
      };
    };
    const { setCurrentDrillingScheme } = useDataCenter(props.params);
    const getData = async () => {
      if (!props.params.schemeId) return;
      loading.value = true;
      xAxis.value.splice(0);
      series.value.splice(0);
      try {
        const res = await datasourceSound(props.params);
        if (res.code === 200) {
          const resData = res.data as any;
          apiData.value = res.data;
          if (props.params.chartType === 'pie') {
            // 饼图
            if (chartsClick) {
              setDrillPreviewData();
              searchResultList();
            }
            series.value.push(
              ...resData.noStackResult.map((item: any) => {
                return {
                  name: item.label,
                  value: item.count,
                  percent: item.percent
                };
              })
            );
          } else {
            // 折线图
            if (props.params.stackType == 0) {
              // 非堆叠数据
              if (chartsClick) {
                setDrillPreviewData();
                searchResultList();
              }
              const yAxis = [] as any[];
              resData.noStackResult.forEach((item: any) => {
                xAxis.value.push(item.label);
                yAxis.push({ value: Number(item.count) });
              });
              series.value.push({ data: yAxis });
            } else {
              // 堆叠数据
              if (chartsClick) {
                setDrillPreviewData();
                searchResultList();
              }
              resData.stackedResult.forEach((item: any, i: number) => {
                const data = item.propagatedStackTypeVOS.map((itm: any) => {
                  const xAxisItemStr =
                    props.params.stackType == 3
                      ? getPolarityName(Number(itm.type))
                      : getArticleTypeName(itm.type);
                  !xAxis.value.includes(xAxisItemStr) &&
                    xAxis.value.push(xAxisItemStr);
                  return { value: Number(itm.count) };
                });
                series.value.push({
                  name: item.label,
                  data
                });
              });
            }
          }
          nextTick(() => {
            saveChartImage();
          });
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
      imageUrl.value = '';
    });
    const update = () => {
      getData();
    };
    const numFormat = num => {
      num = num.toString().split('.');
      let arr = num[0].split('').reverse();
      let res = [];
      for (let i = 0, len = arr.length; i < len; i++) {
        if (i % 3 === 0 && i !== 0) {
          res.push(',');
        }
        res.push(arr[i]);
      }
      res.reverse();
      if (num[1]) {
        res = res.join('').concat('.' + num[1]);
      } else {
        res = res.join('');
      }
      return res;
    };
    const saveChartImage = utilDebounce(() => {
      refChart.value && (imageUrl.value = getChartImage(refChart));
    }, 300);
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(props.params.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      refChart,
      isImage,
      imageUrl,
      xAxis,
      series,
      loading,
      update,
      saveChartImage,
      labelStr,
      anlysisConfig,
      chartClick
    };
  }
});
</script>
