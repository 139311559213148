<template>
  <div v-loading="loading" v-nodata="!loading && !Boolean(series.length)">
    <BarChart
      v-if="!loading && series.length"
      ref="refChart"
      :tooltip="tooltip"
      legend
      stack
      :series="series"
      :x-axis="
        isMobile()
          ? { data: xAxis, axisLabel: { rotate: 60 } }
          : { data: xAxis }
      "
      :colors="colors"
      horizontal
      v-memo="[]"
      :theme="theme"
      @chart:click="chartClick"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, inject } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { getChartGradientColor } from '@wenhai/visual-components/utils';
import { hexToRgb } from '/@/utils/theme';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { useDataCenter } from '../../_hooks/use-data-center';
import { isMobile } from '/@/utils/other';
const defaultParams = { dataSource: [] };
const colors = [
  '#007CFF',
  '#FF9030',
  '#6093FF',
  '#3AC4D4',
  '#FE7770',
  '#00CB9B',
  '#B45BF3',
  '#00AAF8',
  '#FFAC30',
  '#FF70A9',
  '#FF8F66',
  '#2CD36A',
  '#3B6FFF',
  '#6093FF'
];
export default defineComponent({
  name: 'AChannelVolumeCompare',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const EditorStore = useEditorStore();
    const series = ref<any[]>([]);
    const xAxis = ref<any[]>([]);
    const loading = ref(false);
    const copyData = ref();
    const paramsObj = computed(() => {
      return props.params;
    });
    const { setCurrentDrillingScheme } = useDataCenter(paramsObj.value);
    const chartsClick = inject('chartsClick', undefined);
    const initDrillingParams = inject('initDrillingParams');
    const { soundDatasourceComparison } = useAnalysisApi();
    const apiData = ref();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        realTime: 0,
        startPubTime: apiData.value?.startPubTime,
        endPubTime: apiData.value?.endPubTime
      };
    };
    const getData = async () => {
      if (!paramsObj.value.schemeIdList.length) return;
      loading.value = true;
      series.value.splice(0);
      xAxis.value.splice(0);
      try {
        const res = await soundDatasourceComparison({
          ...paramsObj.value,
          ...defaultParams
        });
        apiData.value = res.data;
        const dataSourceInfoList = res.data?.dataSourceInfoList as any[];
        copyData.value = dataSourceInfoList.map(item => {
          const numList = item.dataSourceCountVOList.map(item => item.count);
          return {
            name: item.schemeName,
            schemeId: item.schemeId,
            total: numList.length && numList.reduce((acc, cur) => acc + cur)
          };
        });
        xAxis.value.push(...dataSourceInfoList.map(item => item.schemeName));
        const sourceList = (res.data?.dataSourceList as any[]).map(
          item => item.label
        );
        const dataList = sourceList.map((s, i) => {
          return {
            name: s,
            data: dataSourceInfoList.map(item => {
              return {
                name: item.schemeName,
                value: item.dataSourceCountVOList.find(dt => dt.label == s)
                  ?.count,
                schemeId: item.schemeId,
                show: item.dataSourceCountVOList
                  .map(item => item.label)
                  .includes(s),
                color:
                  props.theme === 'bigscreen-theme-2'
                    ? getChartGradientColor('linear', [
                        `rgba(${hexToRgb(colors[i]).join(',')},0.1)`,
                        colors[i]
                      ])
                    : undefined
              };
            })
          };
        });
        series.value.push(...dataList);
        loading.value = false;
        const maxTotalSchemeId = copyData.value.sort((a, b) => {
          return b.total - a.total;
        })[0].schemeId;
        if (chartsClick) {
          setDrillPreviewData();
          initDrillingParams({ schemeId: maxTotalSchemeId, ...defaultParams });
        }
      } catch (error) {
        loading.value = false;
      }
    };
    const labelStr = param => {
      const { data } = param as any;
      return `${data.name}\n${data.percent} (${data.value})`;
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const tooltip = {
      trigger: 'axis',
      // confine: true,
      formatter(val: any[]) {
        let str = `<div style="margin-bottom: 5px;">${val[0].name}</div>`;
        val.forEach((item, i) => {
          if (!item.data.show) return;
          str += `<div style="margin-bottom: 5px;display:flex;align-items:center;"><div style="width: 10px;height:10px;border-radius: 50%;background-color:${
            colors[i % colors.length]
          };margin-right: 5px;"></div><span>${
            item.seriesName
          }</span><span style="padding: 0 10px;font-weight:600;">${
            item.data.value
          }</span></div>`;
        });
        return str;
      }
    };
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(obj.data.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      loading,
      series,
      xAxis,
      getData,
      update,
      labelStr,
      tooltip,
      copyData,
      colors,
      chartClick,
      isMobile
    };
  }
});
</script>
