<template>
  <div
    v-loading="loading"
    class="publish-map"
    v-nodata="!data?.length && !loading"
  >
    <MapChart
      class="map-chart"
      v-if="!loading && data?.length"
      :data="data"
      :type="pm.chartType"
      tooltip
      :theme="theme"
      :key="updateKey"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  toRef,
  computed,
  inject,
  watch
} from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { cloneDeep } from 'lodash-es';

enum mapType {
  'world' = 0,
  'china' = 1,
  'province' = 2
}

export default defineComponent({
  name: 'APublishMap',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const updateKey = ref();
    watch(toRef(props, 'theme'), avl => {
      updateKey.value = new Date().getTime();
    });
    const { geographicDistribution } = useAnalysisApi();
    const data = ref<{ name: string; value: number }[]>([]);
    const loading = ref(false);
    const pm = toRef(props, 'params');
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const EditorStore = useEditorStore();
    const anlysisConfig = computed(() => {
      return EditorStore.anlysisConfig;
    });
    const { drillPreviewData } = storeToRefs(EditorStore);
    const getData = async () => {
      if (!pm.value.schemeId) return;
      loading.value = true;
      const res = (await geographicDistribution({
        ...pm.value,
        mapType: mapType[pm.value.chartType]
      }).catch(() => {
        loading.value = false;
      })) as any;
      if (chartsClick) {
        drillPreviewData.value = Object.assign(
          {
            startPubTime: res?.data?.startPubTime,
            endPubTime: res?.data?.endPubTime,
            mapType: mapType[pm.value.chartType]
          },
          { aeResultData: cloneDeep(res?.data?.territoryCountList || []) }
        );
        searchResultList();
      }
      data.value = res?.data?.territoryCountList
        ?.filter(itm => {
          return itm.territory != '未知';
        })
        .map((item: { territory: string; totalCount: number }) => {
          return {
            name: item.territory,
            value: item.totalCount
          };
        });
      loading.value = false;
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      data,
      loading,
      pm,
      update,
      anlysisConfig,
      updateKey
    };
  }
});
</script>
