import type { App, Plugin } from 'vue';
import usePermissionDirective from './permission';
import useRoamDirective from './roam';
import useInfiniteScrollDirective from './infiniteScroll';
import highlight from './highlight';

/**
 * 导出指令方法：v-xxx
 * @methods highlight 自定义高亮指定，用法：v-highlight
 */

const install: Plugin = (app: App) => {
  usePermissionDirective(app);
  useRoamDirective(app);
  useInfiniteScrollDirective(app);
  highlight(app);
};

export default {
  install
};
