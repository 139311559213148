<template>
  <div
    style="overflow: hidden"
    v-loading="loading"
    v-nodata="!Boolean(data.length) && !loading"
  >
    <InfoList
      v-if="!loading"
      :data="data"
      :theme="anlysisConfig?.extendedConfig?.bigScreenTheme"
      :itemClickAble="drilling"
      @chart:click="chartClick"
      :titleRows="paramsObj.titleRows"
      :autoScroll="paramsObj.autoScroll && bigScreenMode"
      :step="paramsObj.autoScrollStep"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { getSourceName } from '/@/utils/other';
import { useEditorStore } from '/@/stores/analysis';

export default defineComponent({
  name: 'AInfoList',
  props: {
    params: {
      type: Object,
      required: true
    },
    drilling: Boolean
  },
  setup(props, { emit }) {
    const { searchInfos } = useAnalysisApi();
    const paramsObj = computed(() => {
      return props.params;
    });
    const editStore = useEditorStore();
    const anlysisConfig = computed(() => {
      return editStore.anlysisConfig;
    });
    const bigScreenMode = computed(() => {
      return editStore.anlysisConfig?.bigScreenMode;
    });
    const data = ref<any[]>([]);
    const loading = ref(false);
    const getData = async () => {
      if (!paramsObj.value.schemeId) return;
      loading.value = true;
      data.value.splice(0);
      try {
        paramsObj.value.polarity =
          paramsObj.value.polarity === -1
            ? undefined
            : paramsObj.value.polarity;
        paramsObj.value.tagName =
          paramsObj.value.tagName === '-1'
            ? undefined
            : paramsObj.value.tagName;
        const res = await searchInfos(paramsObj.value);
        if (res.code === 200) {
          const resData = res?.data as any[];
          data.value.push(
            ...resData.map(item => {
              return {
                id: item.uuid,
                title: item.title,
                url: item.url,
                dataSource: getSourceName(item.dataSourceType),
                site: ['10302'].includes(item.dataSourceType)
                  ? 'X(Twitter)'
                  : item.websiteName,
                pubtime: item.publishTime,
                readCount: item.readCount,
                likeCount: item.likeCount,
                commentCount: item.commentCount,
                rttCount: item.rttCount,
                playCount: item.playCount,
                sortField: paramsObj.value.sortInfo.sortField,
                dataSourceType: item.dataSourceType,
                articleType: item.articleType,
                publishTime: item.publishTime,
                keyword: item.keyword || [],
                uuid: item.uuid,
                traceId: res.traceId
              };
            })
          );
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const chartClick = obj => {
      if (!props.drilling || editStore.drillingTrigger !== 'chartItemClick')
        return;
      emit('infoItemClick', obj);
    };
    return {
      data,
      loading,
      paramsObj,
      update,
      anlysisConfig,
      chartClick,
      bigScreenMode
    };
  }
});
</script>
