<script lang="ts" setup>
import SiteName from './SiteName.vue';
import InteractionItem from './InteractionItem.vue';
import { removeHTML } from '/@/utils/other';
import ArticleType from './ArticleType.vue';

interface InfoListItemProps {
  titleRows?: number;
  index: number | string;
  id: string;
  title: string;
  url: string;
  dataSource: string;
  dataSourceType: string;
  site: string;
  pubtime: string;
  readCount: number;
  likeCount: number;
  commentCount: number;
  rttCount: number;
  playCount: number;
  sortField: number;
  articleType: any;
}

const item = defineProps<InfoListItemProps>();
const openLink = url => {
  window.open(url);
};
</script>
<template>
  <div
    :class="['wh-item--index-theme2', 'wh-item--index-theme2-' + item.index]"
  >
    {{ item.index + 1 }}
  </div>
  <div class="wh-item--wrapper">
    <div
      class="wh-item--main"
      :class="[titleRows && `title-row-${titleRows}`]"
      v-html="item.title"
      @click="openLink(item.url)"
      :title="removeHTML(item.title)"
    ></div>
    <div class="wh-item--addon">
      <slot name="addon" v-bind="item">
        <div style="width: 100%; display: flex; justify-content: space-between">
          <div style="display: flex; gap: 10px">
            <site-name :data="item" iconType="colorful" />
            <span class="wh-text">{{ item.pubtime }}</span>
          </div>
          <div style="display: flex; gap: 10px">
            <interaction-item :data="item"></interaction-item>
            <ArticleType
              :articleType="item.articleType"
              theme="bigscreen-theme-2"
            />
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.wh-item--index-theme2 {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background: linear-gradient(
    270deg,
    rgba(166, 220, 254, 0) 0%,
    rgba(166, 220, 254, 0.3) 100%
  );
  border: 1px solid;
  border-right: none;
  border-image: linear-gradient(
      270deg,
      rgba(166, 220, 254, 0),
      rgba(166, 220, 254, 0.5)
    )
    1 1;
  margin-top: 5px;
  color: #bddbff;

  &.wh-item--index-theme2-0 {
    background: linear-gradient(
      270deg,
      rgba(254, 119, 112, 0) 0%,
      rgba(254, 119, 112, 0.4) 100%
    );
    border-image: linear-gradient(
        270deg,
        rgba(254, 119, 112, 0),
        rgba(254, 119, 112, 1)
      )
      1 1;
  }

  &.wh-item--index-theme2-1 {
    background: linear-gradient(
      270deg,
      rgba(255, 187, 102, 0) 0%,
      rgba(255, 187, 102, 0.4) 100%
    );
    border-image: linear-gradient(
        270deg,
        rgba(255, 187, 102, 0),
        rgba(255, 187, 102, 1)
      )
      1 1;
  }

  &.wh-item--index-theme2-2 {
    background: linear-gradient(
      270deg,
      rgba(70, 171, 255, 0) 0%,
      rgba(70, 171, 255, 0.4) 100%
    );
    border-image: linear-gradient(
        270deg,
        rgba(70, 171, 255, 0),
        rgba(70, 171, 255, 1)
      )
      1 1;
  }
}

.wh-item--wrapper {
  flex: 1;
  padding: 0 0 10px 5px;

  .wh-item--main {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 22px;
    padding: 0 17px 0 4px;
    color: #c4d9f3;
    cursor: pointer;
    &.title-row-1 {
      -webkit-line-clamp: 1;
    }
    &.title-row-2 {
      -webkit-line-clamp: 2;
    }
    &:hover {
      background: linear-gradient(
        270deg,
        rgba(26, 123, 230, 0) 0%,
        rgba(69, 193, 255, 0.7) 100%
      );
    }
  }

  .wh-item--addon {
    display: flex;
    gap: 20px;
    line-height: 24px;
    color: rgba(189, 219, 255, 0.6);
    font-size: 12px;
    white-space: nowrap;
  }
}
</style>
