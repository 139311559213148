<script lang="ts" setup>
import { computed, ref } from 'vue';
import nodata from '/@/components/noData/index.vue';
interface HotItem {
  ranking: number;
  uid: string | number;
  title: string;
  insertTime: string;
  updateTime: string;
  onTime: number;
  heatValue: number;
}

interface HotListProps {
  type: number;
  count: number;
  data: HotItem[];
  pageSize: number;
  currentPage: number;
  deletable?: boolean;
  theme?: string;
  stripe?: boolean;
}
const props = defineProps<HotListProps>();

const loading = ref<boolean>(false);
const emit = defineEmits<{
  (e: 'delete', item: HotItem): void;
  (e: 'currentPageChange', currentPage: number): void;
  (e: 'pageSizeChange', size: number): void;
}>();

const remove = (item: HotItem) => {
  emit('delete', item);
};

const load = (load: boolean) => {
  loading.value = load;
};

const currentPageChange = (currentPage: number) => {
  emit('currentPageChange', currentPage);
};

const pageSizeChange = (size: number) => {
  emit('pageSizeChange', size);
};

const noData = computed(() => !props.data || !props.data.length);

const data = computed(() => props.data);

defineExpose({
  load
});
</script>
<template>
  <div
    class="wh-chart--topic-list"
    :class="{ 'wh-chart--topic-list-theme2': theme == 'bigscreen-theme-2' }"
  >
    <slot></slot>
    <div class="topic-header">
      <slot name="header">
        <div class="column">排名</div>
        <div class="column">热点</div>
        <div class="column">上榜时间</div>
        <div class="column" v-if="!type">最后在榜时间</div>
        <div class="column">在榜时长</div>
        <div class="column">热度</div>
      </slot>
    </div>
    <div class="topic-body" v-loading="loading">
      <div
        class="topic-item"
        :class="{ 'topic-item-stripe': stripe }"
        v-for="(item, i) in data"
        :key="item.uid"
      >
        <slot name="body" :item="item">
          <div class="column">
            <div
              class="wh-item--index"
              :id="'topic' + item.ranking"
              v-show="item.ranking"
            >
              {{ item.ranking }}
            </div>
            <svg-icon
              size="16"
              name="zhiding"
              :raw="true"
              v-show="!item.ranking"
              style="margin-top: 12px"
            ></svg-icon>
          </div>
          <div class="column item-title">{{ item.title }}</div>
          <div class="column item-upTime">{{ item.insertTime }}</div>
          <div class="column item-lastTime" v-if="!type">
            {{ item.updateTime }}
          </div>
          <div class="column item-longList">{{ item.onTime || '-' }}</div>
          <div class="column item-hot" :id="'hot' + item.ranking">
            <span class="hot-num">{{ item.heatValue || '-' }}</span>
            <c-icon
              v-if="deletable"
              size="16"
              name="guanbi"
              class="icon-delete"
              @click="remove(item)"
            ></c-icon>
          </div>
        </slot>
      </div>
      <slot name="noData" v-if="noData && !loading">
        <nodata
          icon="zanwushibie"
          icon-size="200"
          tip=""
          style="background: unset; margin: 8% auto"
        />
      </slot>
      <slot name="pagination" v-if="!noData && !loading && count >= pageSize">
        <div class="pagination">
          <w-pagination
            :total="count"
            :current="currentPage"
            :page-size="pageSize"
            :page-size-options="[10, 20, 30, 50]"
            show-jumper
            show-page-size
            @change="currentPageChange"
            @page-size-change="pageSizeChange"
          />
        </div>
      </slot>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '../style/colors' as c;
@use '../style/mixins' as m;

$height: 40px;

.wh-chart--topic-list {
  height: 95%;
  font-size: 14px;
  font-weight: 400;

  .topic-header,
  .topic-body .topic-item {
    display: flex;
    align-items: center;
  }

  .topic-header:deep(.column),
  .topic-body .topic-item:deep(.column) {
    &:first-child {
      flex: 0 0 60px;
      width: 60px;
      text-align: center;
    }

    &:nth-child(2) {
      flex: 1 1 20%;
      width: 20%;
    }

    &:nth-child(3) {
      flex: 0 0 150px;
      text-align: center;
    }

    &:nth-child(4) {
      flex: 0 0 150px;
      text-align: center;
    }

    &:nth-child(5) {
      flex: 0 0 80px;
      text-align: center;
    }

    &:last-child {
      flex: 0 0 80px;
      text-align: center;
    }
  }

  .topic-header {
    height: $height;
    line-height: $height;
    background-color: c.$gray-1;
    font-weight: 600;
  }

  .topic-body {
    height: calc(100% - 40px);
    // max-height: 320px;
    // overflow-y: auto;
    // min-height: 400px;

    .pagination {
      justify-content: right;
      display: flex;
      margin-top: 5px;
      padding-right: 20px;
    }
    :deep(#topic1) {
      background-color: c.$red-0;
      color: #fff;
    }
    :deep(#topic2) {
      background-color: c.$orange-0;
      color: #fff;
    }

    :deep(#topic3) {
      background-color: c.$blue-1;
      color: #fff;
    }

    :deep(.topic-item) {
      @include m.indexBox($className: 'wh-item--index', $top: 0, $right: 0);
      // @include m.markTop3("wh-item--index");
      height: $height;
      line-height: $height;
      .wh-item--index {
        display: inline-block;
      }

      .item-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      #hot1,
      #hot2,
      #hot3 {
        background: url(./images/fire.png) no-repeat left/16px 16px;
      }
      .item-hot {
        display: flex;
        align-items: center;
        padding-left: 16px;

        .hot-num {
          display: inline-block;
          width: 45px;
        }

        &.column {
          width: 80px;
          flex: 0 0 80px;
        }
      }
    }
    :deep(.topic-item-stripe) {
      &:nth-child(even) {
        background-color: c.$gray-1;
      }
    }
  }

  .icon-delete {
    fill: c.$text-gray-1;
    margin-left: 6px;
    cursor: pointer;
  }

  .noData[data-v-fb259286] {
    height: 90%;
  }
  &.wh-chart--topic-list-theme2 {
    .topic-header {
      background-color: transparent;
      .column {
        color: rgba(189, 219, 255, 0.6);
        white-space: nowrap;
      }
    }
    .topic-body {
      .topic-item {
        .column {
          color: #c4d9f3;
          .hot-num {
            color: #ffa538;
          }
        }
        &:nth-child(even) {
          background: rgba(70, 171, 255, 0.1);
        }
        #hot1,
        #hot2,
        #hot3 {
          background: url(./images/theme2/fire.png) no-repeat left/16px 16px;
        }
      }
      .wh-item--index {
        width: 28px;
        height: 20px;
        line-height: 20px;
        background-color: unset;
        background: url(./images/theme2/top-icon.png) no-repeat center/cover;
        color: #bddbff;
        &#topic1 {
          background-color: unset;
          color: #ffbb66;
          background-image: url(./images/theme2/top-icon-1.png);
        }
        &#topic2 {
          background-color: unset;
          color: #46abff;
          background-image: url(./images/theme2/top-icon-2.png);
        }

        &#topic3 {
          background-color: unset;
          color: #46abff;
          background-image: url(./images/theme2/top-icon-2.png);
        }
      }
    }
    :deep(.pagination) {
      .w-pagination-list {
        .w-pagination-item {
          background-color: transparent;
          border: none;
          color: #c4d9f3;
          &.w-pagination-item-active {
            color: #165dff;
          }
        }
      }
      .w-pagination-jumper {
        .w-pagination-jumper-text-goto {
          color: #c4d9f3;
        }
      }
    }
  }
}

@include m.noData();
</style>
