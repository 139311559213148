// 定义内容
export default {
  contactsMenu: {
    menuList: [
      'Account Settings',
      'Contacts',
      'Copy Template Configuration',
      'Account Rights And Interests'
    ]
  },
  userInformation: {
    title: 'Personal Info',
    userName: 'Username:',
    password: 'Password:',
    nickName: 'Nickname:',
    editBtn: 'Edit'
  },
  editInformation: [
    {
      title: 'Change Password',
      passwordLabel: [
        'Original password:',
        'New password:',
        'Confirm password:'
      ],
      placeholder: [
        'Please enter the original password',
        'Please enter a new password',
        'Please enter a new password'
      ],
      passwordRules: ['Password length is not less than 8 digits'],
      contentTip: [
        '1.The length of the password should not be less than 8 digits',
        '2.The password must contain two or more English letters, numbers, and special characters (excluding spaces)'
      ],
      editBtns: ['Confirm', 'Cancel']
    },
    {
      title: 'Modify user nickname',
      nickNameLabel: ['New user nickname:'],
      placeholder: ['Please enter a nickname']
    }
  ],
  updatePasswordRules: {
    passwordEditSuccess: 'Congratulations! Password modified successfully!',
    oldPassword: 'The original password cannot be empty',
    newPassword: 'The new password cannot be empty',
    confirmPassword: 'Confirm password cannot be empty',
    atypismPassword: 'The passwords entered twice are inconsistent!',
    confirmSameNew: 'The new password cannot be the same as the old password',
    passwordFormat:
      'The password format is incorrect. Please click the icon to view the details of the password format.'
  },
  updateNickNameRules: {
    nonEmpty: 'Input cannot be empty',
    sameAsOldNickName: 'The nickname cannot be the original nickname',
    updateSuccess: 'Congratulations! Successfully modified the nickname!',
    format: 'Nickname format error, please re-enter'
  },
  mycontacts: {
    title: 'My Contacts',
    addContactBtn: 'Add WeChat Users',
    searchContacts: 'Please enter a contact name to search',
    orderNumber: 'No.',
    nickname: 'Name',
    nicKNamePlaceholder: 'Please enter',
    openId: 'WeChat ID',
    createTime: 'Creation Time',
    operate: 'Operation',
    operateList: ['Edit', 'Save', 'Delete'],
    unfollowed: 'Unfollowed'
  },
  addContactsList: {
    title: 'Add New User',
    scanAdd: 'Scan to add:',
    renovate: 'Renovate',
    scanTip:
      'Scan the QR code to follow our Wechat official account for alert notifications.',
    scanSuccess: 'Code scanning succeeded',
    scanName: 'Name:',
    scanNamePl: 'Enter the name',
    scanMark: [
      '(Note: Added contacts can be found at',
      '"Personal Center - Contacts"',
      'View or modify)'
    ],
    scanNameRule: 'Please enter your name first',
    modalBtns: ['Cancel', 'Save']
  },
  copyTemplateConfiguration: {
    preview: 'Preview',
    btns: ['Edit', 'Save']
  },
  accountInterests: {
    title: 'Monitoring and early warning rights and interests information',
    dayMonitoring: 'The total amount of monitoring today',
    dayWarning: 'The total amount of early warning today',
    totalTip: 'Total',
    used: 'Used',
    dayWarningTip:
      "Refers to the number of push messages on Wechat's early warning day, and the early warning prompts on the platform are not counted."
  }
};
