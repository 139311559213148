// 定义内容
export default {
  router: {
    home: '首页',
    hotspot: '热榜',
    search: '搜索',
    analysis: '分析',
    dashboard: '大屏',
    monitor: '监测',
    warning: '预警',
    report: '报告',
    bulletin: '舆情简报',
    proofreading: '内容校对',
    contentReview: '内容审查',
    searchResult: '搜索结果',
    collect: '收藏',
    analysisSettings: '分析设置',
    dashboardSettings: '大屏设置',
    other: '其他',
    otherIcon: '图标',
    otherI18n: '国际化',
    otherStyle: '全局样式',
    contacts: '个人中心',
    task: '任务中心',
    formation: '预览',
    technologyRadar: {
      technologyRadar: '科技雷达',
      dataInfo: '数据筛选',
      report: '报告管理',
      preview: '报告预览'
    },
    login: '登录',
    analysisShare: '分析分享',
    bigscreenRun: '大屏运行',
    links: {
      videoRiskControl: '视频风控',
      yaYiLLM: 'YAYI雅意大模型',
      dataMap: '数据地图'
    },
    bigscreenShare: '大屏分享'
  },
  staticRoutes: {
    signIn: '登录',
    notFound: '找不到此页面',
    noPower: '没有权限'
  },
  user: {
    title0: '组件大小',
    title1: '语言切换',
    title2: '搜索',
    title3: '布局配置',
    title4: '消息',
    title5: '切换全屏模式',
    title6: '退出全屏模式',
    title7: '更多应用',
    title8: '客服',
    title9: '设置',
    title10: '暗黑模式',
    title11: '亮色模式',
    title12: '舆论领域大模型',
    fontSizeSwitching: '字体大小切换',
    StandardFont: '标准字体',
    MediumFont: '中号字体',
    LargeFont: '大号字体',
    dropdownLarge: '大型',
    dropdownDefault: '默认',
    dropdownSmall: '小型',
    dropdown1: '首页',
    dropdown2: '个人中心',
    dropdown3: '404',
    dropdown4: '401',
    logOut: '退出登录',
    dropdown6: '代码仓库',
    searchPlaceholder: '菜单搜索：支持中文、路由路径',
    newTitle: '通知',
    newBtn: '全部已读',
    newGo: '前往通知中心',
    newDesc: '暂无通知',
    logOutTitle: '提示',
    logOutMessage: '此操作将退出登录, 是否继续?',
    logOutConfirm: '确定',
    logOutCancel: '取消',
    logOutExit: '退出中'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOther: '关闭其它',
    closeAll: '全部关闭',
    fullscreen: '当前页全屏',
    closeFullscreen: '关闭全屏'
  },
  notFound: {
    foundTitle: '地址输入错误，请重新输入地址~',
    foundMsg: '您可以先检查网址，然后重新输入或给我们反馈问题。',
    foundBtn: '返回首页'
  },
  noAccess: {
    accessTitle: '您未被授权，没有操作权限~',
    accessMsg: '联系方式：加QQ群探讨 665452019',
    accessBtn: '重新授权'
  },
  layout: {
    configTitle: '布局配置',
    oneTitle: '全局主题',
    twoTopTitle: '顶栏设置',
    twoMenuTitle: '菜单设置',
    twoColumnsTitle: '分栏设置',
    twoTopBar: '顶栏背景',
    twoTopBarColor: '顶栏默认字体颜色',
    twoIsTopBarColorGradual: '顶栏背景渐变',
    twoMenuBar: '菜单背景',
    twoMenuBarColor: '菜单默认字体颜色',
    twoIsMenuBarColorGradual: '菜单背景渐变',
    twoColumnsMenuBar: '分栏菜单背景',
    twoColumnsMenuBarColor: '分栏菜单默认字体颜色',
    twoIsColumnsMenuBarColorGradual: '分栏菜单背景渐变',
    threeTitle: '界面设置',
    threeIsCollapse: '菜单水平折叠',
    threeIsUniqueOpened: '菜单手风琴',
    threeIsFixedHeader: '固定 Header',
    threeIsClassicSplitMenu: '经典布局分割菜单',
    threeIsLockScreen: '开启锁屏',
    threeLockScreenTime: '自动锁屏(s/秒)',
    fourTitle: '界面显示',
    fourIsShowLogo: '侧边栏 Logo',
    fourIsBreadcrumb: '开启 Breadcrumb',
    fourIsBreadcrumbIcon: '开启 Breadcrumb 图标',
    fourIsTagsview: '开启 Tagsview',
    fourIsTagsviewIcon: '开启 Tagsview 图标',
    fourIsCacheTagsView: '开启 TagsView 缓存',
    fourIsSortableTagsView: '开启 TagsView 拖拽',
    fourIsShareTagsView: '开启 TagsView 共用',
    fourIsFooter: '开启 Footer',
    fourIsGrayscale: '灰色模式',
    fourIsInvert: '色弱模式',
    fourIsDark: '深色模式',
    fourIsWartermark: '开启水印',
    fourWartermarkText: '水印文案',
    fiveTitle: '其它设置',
    fiveTagsStyle: 'Tagsview 风格',
    fiveAnimation: '主页面切换动画',
    fiveColumnsAsideStyle: '分栏高亮风格',
    fiveColumnsAsideLayout: '分栏布局风格',
    sixTitle: '布局切换',
    sixDefaults: '默认',
    sixClassic: '经典',
    sixTransverse: '横向',
    sixColumns: '分栏',
    tipText:
      '点击下方按钮，复制布局配置去 `src/stores/themeConfig.ts` 中修改。',
    copyText: '一键复制配置',
    resetText: '一键恢复默认',
    copyTextSuccess: '复制成功！',
    copyTextError: '复制失败！'
  },
  codeMsg: {
    msg1: '服务加载失败'
  },
  yayi: {
    Creativity: '创造力',
    Generating: '正在为您生成概要信息',
    inputPlaceholder: '请输你的问题内容 , Shift + Enter 换行',
    chatAnswerInitTip: `您好，我是您的个人助手，可以帮您进行文章分析。<br/>
			您可以：<br/>
			分析<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="20" height="20"><g fill="none" fill-rule="evenodd"><path d="M0 0h16v16H0z"></path><path data-follow-fill="#4E5969" d="m11.771 9.885-.942-.942L11.77 8A2.667 2.667 0 1 0 8 4.229l-.943.942-.942-.942.942-.943a4 4 0 0 1 5.657 5.657l-.943.942Zm-1.886 1.886-.942.943a4 4 0 1 1-5.657-5.657l.943-.942.942.942L4.23 8A2.667 2.667 0 1 0 8 11.771l.943-.942.942.942Zm0-6.6.944.944-4.714 4.713-.944-.943 4.714-4.713Z" fill="rgba(22,93,255,1)" fill-rule="nonzero"></path></g></svg>中的文章；<br/>
		`,
    chatAnswerTip: '正在为您生成分析信息',
    chatAnswerTipJb: '正在为您生成简报',
    chatAnswerInitTipJb:
      '你已进入简报生成模块，请点击<span class="color">【输入链接】</span>输入相关文章链接或者聊天框直接输入原文内容，生成简报',
    chatAnswerInitTipJbTip: `原文内容输入参考格式如下，如对应内容缺少请忽略，输入原文存在内容即可：<br/>
      文章标题：全国人大代表贾晓亮：建议建立国家统一网络身份认证平台<br/>
      媒体渠道：微博<br/>
      账号名称：中国警察网<br/>
      账号粉丝数：418.3万<br/>
      账号ip：北京<br/>
      发布时间：03-06 08:57<br/>
      内容：本报北京3月5日电 记者田海军报道：“近年来，层出不穷的网络谣言、网络暴力事件以及网络水军、网络黑灰产犯罪屡禁不止、屡打不绝，其主要原因是网络实名制落实不到位。”...<br/>
      点赞量：57<br/>
      评论量：16<br/>
      转发量：1<br/>
      阅读量：0<br/>
      评论：夏天太热冬天太冷秋天刚刚好-：支持支持支持！！！`,
    buttonRetry: '重新生成',
    buttonCopy: '复制',
    settingDataName: ['聊天', '简报'],
    tempTextDemo: [
      '创造力是指模型在生成答案时的“温度（temperature）”参数。这个参数可以影响大模型的回答自由度。',
      '创造力较低时',
      '模型会更加谨慎，避免产生过于激进或冒险的答案。',
      '创造力较高时',
      '模型会更加开放，敢于尝试新的、不寻常的答案。'
    ],
    chatOptionsItem: ['新建会话', '链接', '提示词'],
    linkInputModel: [
      '链接输入',
      '请输入文章链接，输入多个文章链接时，链接间按回车换行以区分。',
      '取消',
      '确定'
    ],
    promptTabItem: [
      '公共词库',
      '个人词库',
      '新建',
      '（创造力：',
      '编辑',
      '提示词修改',
      '名称',
      '内容',
      '恢复默认',
      '创造力',
      '请输入名称',
      '请输入内容',
      '提示词删除成功',
      '提示词编辑成功',
      '提示词新增成功'
    ],
    exampleTip: ['示例'],
    mainPage: [
      '第',
      '个文章链接无相应内容，请检查输入内容',
      '正在根据链接查找相应文章',
      '未找到相应文章,请检查文章链接后重新输入',
      '请输入链接信息',
      '网址格式错误，请重新输入',
      '最大输入链接数量为5',
      '概要',
      '正在为您生成',
      '分析',
      '信息',
      '文章信息生成失败， 请稍后再试',
      '所输入内容格式错误，请按规定格式输入',
      '简报生成失败， 请稍后再试',
      '信息生成失败， 请稍后再试'
    ],
    chatAnswerArticle: [
      '文章标题：',
      '来源：',
      '内容：',
      '点赞量：',
      '评论量：',
      '转发量：',
      '阅读量：',
      '修改链接'
    ]
  },
  layoutNavBars: {
    breadcrumbUser: ['简体中文', '英文'],
    breadcrumbMsgDrawer: ['产品公告', '取消', '确定', '暂无消息']
  },
  components: {
    articleListListOperation: ['内容审查']
  }
};
