<template>
  <div class="config-group">
    <div class="config-group-label">
      <div>
        <c-icon
          color=""
          size="16"
          :name="showItem ? 'xia' : 'you'"
          @click="changeShowItem"
          style="cursor: pointer"
        ></c-icon>
        <span>{{ label }}</span>
      </div>
      <slot name="button" />
    </div>
    <div v-show="showItem" class="config-group-item">
      <slot />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'ConfigGroup',
  props: {
    label: {
      type: String
    },
    icon: {
      type: String,
      default: 'you'
    }
  },
  setup() {
    const showItem = ref(true);
    const changeShowItem = () => {
      showItem.value = !showItem.value;
    };
    return {
      showItem,
      changeShowItem
    };
  }
});
</script>
<style scoped lang="scss">
.config-group {
  margin-bottom: 10px;
  .config-group-label {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #1d2129;
    margin-bottom: 10px;
    user-select: none;
    justify-content: space-between;
  }

  .config-group-item {
    padding-left: 16px;
  }
}
</style>
