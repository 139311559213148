import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import AEmotionalTendency from './src/index.vue';

AEmotionalTendency.install = (app: App): void => {
  app.component('AEmotionalTendency', AEmotionalTendency);
  app.component(
    'AEmotionalTendencyProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default AEmotionalTendency;
