<template>
  <div v-loading="initLoading" v-nodata="!data.length && !loading">
    <RelationalGraph
      v-if="!initLoading && data.length"
      :data="data"
      nodeColorBy="branch"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  toRef,
  shallowRef,
  PropType
} from 'vue';
import { useDataCenter, TParams } from '../../_hooks/use-data-center';
import useAnalysisApi from '/@/api/analysis';
interface dataItem {
  name: string;
  level: number;
  value: number;
  children: dataItem[];
  articleId?: string;
  articleType?: number;
  startPubTime?: string;
}
const levelLimit = 4; //层级限制
const nodeLimit = 5; //同级节点数量限制
const rootValue = 100;

export default defineComponent({
  name: 'AWeiboForwarding',
  props: {
    params: {
      type: Object as PropType<TParams>,
      required: true
    }
  },
  setup(props) {
    const { getForwardRelationList, forwardRelation } = useAnalysisApi();
    const pm = toRef(props, 'params');
    const { SchemeTreeOption } = useDataCenter(pm.value);
    const initRootName = () => {
      !pm.value.rootName &&
        (pm.value.rootName = SchemeTreeOption.value.find(item => {
          return item.value === pm.value.schemeId;
        })?.label!);
    };
    const data = shallowRef<dataItem[]>([
      {
        name: pm.value.rootName!,
        value: rootValue,
        level: 1,
        children: []
      }
    ]);
    const loading = ref(false);
    const initLoading = ref(false);
    const loadNext = (parent: dataItem) => {
      loading.value = true;
      parent.children.forEach(async item => {
        const { articleId, articleType, startPubTime } = item; //{ articleId: "1647968900662038536", articleType: 2, startPubTime: "2023-3-20 01:21:37" }
        const res = await forwardRelation({
          articleId,
          articleType,
          startPubTime
        });
        const resList = ((res?.data as any[]) || []).splice(0, nodeLimit);
        const level = item.level + 1;
        const nextList = resList.map(itm => {
          return {
            name: itm.source,
            value: rootValue / level,
            level,
            children: [],
            articleId: itm.articleId,
            articleType: itm.articleType,
            startPubTime: itm.publishTime
          };
        });
        item.children = nextList;
        data.value = [...data.value];
        level < levelLimit && loadNext(item);
      });
      loading.value = false;
    };
    const getData = async () => {
      const level = 2;
      loading.value = true;
      initLoading.value = true;
      data.value[0].name = pm.value.rootName!;
      const res = await getForwardRelationList(pm.value.schemeId);
      const resList = ((res?.data as any[]) || []).map(item => {
        return {
          id: item.uuid,
          name: item.source,
          value: rootValue / level,
          level,
          children: [],
          articleId: item.articleId,
          articleType: item.articleType,
          startPubTime: item.publishTime
        };
      });
      data.value[0].children = resList.splice(0, nodeLimit);
      initLoading.value = false;
      level < levelLimit && loadNext(data.value[0]);
    };
    onMounted(() => {
      initRootName();
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      data,
      loading,
      initLoading,
      update
    };
  }
});
</script>
