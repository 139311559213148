<template>
  <w-form-item :label="label + '：'">
    <div class="select-color">
      <input
        class="color-input"
        type="color"
        v-model="value"
        @input="emitChange"
      />
      <span class="color-value">{{ value }}</span>
    </div>
    <slot />
  </w-form-item>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  name: 'ConfigColorSelect',
  props: {
    label: {
      type: String
    },
    modalValue: {
      type: String
    }
  },
  setup(props, { emit }) {
    const value = ref(props.modalValue);
    watch(value, val => {
      emit('update:modalValue', val);
    });
    const setValue = (val: string) => {
      value.value = val;
    };
    const emitChange = () => {
      emit('change', value.value);
    };
    return { value, emitChange, setValue };
  }
});
</script>
<style scoped lang="scss">
:deep.w-form-item {
  // display: inline-flex;
  // align-items: flex-start;
  // .w-form-item-label-col {
  // 	margin-bottom: 0;
  // 	margin-right: 16px;
  // }
  // .w-form-item-content {
  // 	flex-wrap: wrap;
  // }
  .select-color {
    display: flex;
    align-items: center;
    margin-right: 10px;
    .color-input {
      border: none;
      min-width: 30px;
      max-width: 30px;
      height: 32px;
    }
    .color-value {
      color: #4e5969;
      padding: 9px 10px;
      margin-left: 4px;
      // margin-right: 4px;
      border-radius: 2px;
      background: #f2f3f5;
    }
  }
}
</style>
