<template>
  <w-form-item :label="label" field="uuid" :rules="validate">
    <div class="specify-article">
      <div class="specify-article-button" @click="handleClick">
        <div class="specify-article-info" v-html="copy"></div>
      </div>
      <slot />
    </div>
  </w-form-item>
</template>
<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue';
export default defineComponent({
  name: 'ConfigSpecifyArticleButton',
  props: {
    label: {
      type: String
    },
    text: {
      type: String,
      default: () => '指定文章'
    },
    uuid: {
      type: String
    },
    validate: {
      type: Array,
      default: () => {
        [];
      }
    }
  },
  setup(props, { emit }) {
    const handleClick = () => {
      emit('handleClick');
    };
    const copy = ref();
    watchEffect(() => {
      //指定文章输入框中不需要高亮关键字
      var reg = RegExp(/<\/?[a-z][\s\S]*>/i);
      props.text.match(reg)
        ? (copy.value = props.text.replace(/<[^<>]+>/g, ''))
        : (copy.value = props.text);
    });
    return {
      handleClick,
      copy
    };
  }
});
</script>
<style lang="scss" scoped>
.specify-article {
  display: flex;
  flex-direction: column;
  width: 100%;
  .specify-article-button {
    background-color: var(--color-white);
    border: 1px solid var(--border-base-color);
    border-radius: 2px;
    padding: 8px 16px;
    cursor: pointer;
    .specify-article-info {
      line-height: 18px;
      color: var(--primary-text-color);
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
