<template>
  <div v-loading="loading" v-nodata="!Boolean(data)" class="chart-box-in">
    <overview
      v-if="data && !loading && Local.get('globalI18n') === 'zh-cn'"
      :key="data.startTime"
    >
      <p>
        本次分析的监测时间为 {{ data.startTime }} 至 {{ data.endTime }}
        <!-- 查询的关键词为 必须包含“<b>{{ data.keyWords }}</b
			>”  -->
        ， 共计 <span class="color-primary">{{ data.total }}</span> 条数据 。
      </p>
      <div v-for="(item, i) in data.sourceData" :key="item.source">
        <p v-if="item.count">
          <span
            >{{ item.label
            }}<span v-if="item.mediaCount">{{ item.mediaCount }}个</span
            >：发文<span class="color-primary"
              ><span class="source-count" @click="handleClick(item)">{{
                item.count
              }}</span></span
            >篇<span v-if="item.interactionStr?.length">，</span
            >{{ item.interactionStr.join('，') }}；</span
          >
        </p>
      </div>
      <p>
        本次分析共监测到数据{{ data.total }}篇， 日均传播量{{
          data.averageDailyTransmission
        }}，情感倾向正面比例占比{{ data.positiveProportion }}、中立占比{{
          data.neutralProportion
        }}、负面占比{{ data.negativeProportion }}。
      </p>
    </overview>
    <overview
      v-if="data && !loading && Local.get('globalI18n') === 'en'"
      :key="data.startTime"
    >
      <p>
        The monitoring period spans from {{ data.startTime }} to
        {{ data.endTime }}
        <!-- 查询的关键词为 必须包含“<b>{{ data.keyWords }}</b
                        >”  -->
        , and <span class="color-primary">{{ data.total }}</span> pieces of data
        are collected.
      </p>
      <div v-for="(item, i) in data.sourceData" :key="item.source">
        <p v-if="item.count">
          <span
            ><span v-if="item.mediaCount">{{ item.mediaCount }} </span>
            {{ item.label }} accounts:
            <span class="color-primary"
              ><span class="source-count" @click="handleClick(item)">{{
                item.count
              }}</span></span
            >
            posts<span v-if="item.interactionStr?.length">, </span
            >{{ item.interactionStr.join(', ') }};</span
          >
        </p>
      </div>
      <p>
        {{ data.total }} pieces of data are monitored in this analysis, with an
        average daily spread of {{ data.averageDailyTransmission }} data.
        {{ data.positiveProportion }} of the data carries a positive sentiment,
        {{ data.neutralProportion }} is neutral,
        {{ data.negativeProportion }} is negative.
      </p>
    </overview>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  inject,
  computed
} from 'vue';
import { storeToRefs } from 'pinia';
import { useSearchApi } from '/@/api/search';
import { transRealTime } from '/@/utils/other';
import { useSearchAnalyse } from '/@/stores/searchAnalyse';
import { cloneDeep } from 'lodash-es';
import { getfilterList } from '../components/utils';
import { useDictStore } from '/@/stores/dict';
import { useEditorStore } from '/@/stores/analysis';
import { useDataCenter } from '../components/_hooks/use-data-center';
import { Local } from '/@/utils/storage';
import { useI18n } from 'vue-i18n';

const dataSourceMap = [
  {
    name: '网媒',
    label: '网媒 ',
    code: ['00101', '10101', '00104', '10104', '00100', '10100'],
    interactionKeys: ['commentCount']
  },
  {
    name: '微信',
    label: '微信账号 ',
    code: ['00105'],
    interactionKeys: ['commentCount', 'likeCount', 'readCount', 'watchCount']
  },
  {
    name: '微博',
    label: '微博账号 ',
    code: ['00301'],
    interactionKeys: ['commentCount', 'likeCount', 'rttCount']
  },
  {
    name: '短视频',
    label: '短视频账号 ',
    code: ['00401', '00402', '00400', '10401', '10400'],
    interactionKeys: ['commentCount', 'likeCount']
  },
  {
    name: '视频网站',
    label: '视频网站 ',
    code: [
      '00503',
      '00501',
      '00506',
      '00508',
      '00504',
      '00505',
      '00507',
      '10501',
      '00500',
      '00502',
      '10500'
    ],
    interactionKeys: ['commentCount', 'likeCount']
  },
  {
    name: 'APP',
    label: 'APP ',
    code: ['00103', '10103', '00302'],
    interactionKeys: []
  },
  {
    name: '电子报刊',
    label: '电子报刊 ',
    code: ['00102', '10102'],
    interactionKeys: []
  },
  {
    name: '论坛贴吧',
    label: '论坛贴吧账号 ',
    code: ['00202', '00201', '00203', '10201', '00200', '10200'],
    interactionKeys: ['commentCount', 'likeCount']
  },
  {
    name: 'X(Twitter)',
    label: 'X(Twitter)账号 ',
    code: ['10302'],
    interactionKeys: ['commentCount', 'likeCount', 'readCount', 'rttCount']
  },
  {
    name: 'Facebook',
    label: 'Facebook账号 ',
    code: ['10301'],
    interactionKeys: ['commentCount', 'likeCount', 'rttCount']
  },
  {
    name: '电视视频',
    label: '电视视频',
    code: ['00509'],
    interactionKeys: []
  },
  {
    name: '其他',
    label: '其他',
    code: ['10303', '10304', '00300', '10300'],
    interactionKeys: []
  }
];

export default defineComponent({
  // 概述
  name: 'SSummarize',
  props: {
    params: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(props) {
    const { t } = useI18n();
    const keyMap = {
      commentCount: t('message.AnalysisPreview.commentCount'),
      likeCount: t('message.AnalysisPreview.likeCount'),
      watchCount: t('message.AnalysisPreview.watchCount'),
      readCount: t('message.AnalysisPreview.readCount'),
      rttCount: t('message.AnalysisPreview.rttCount')
    };
    const { analysisOverview } = useSearchApi();
    const searchAnalyseStore = useSearchAnalyse();
    const dictStore = useDictStore();
    const {
      searchAnalyseParams,
      sAnalysechannelIds,
      dataAuthType,
      designatedMediaLabelArr,
      searchTime
    } = storeToRefs(searchAnalyseStore);
    const { mediaTypeList, mediaTypeLocalList, mediaTypeForeignList } =
      storeToRefs(dictStore);
    const loading = ref(false);
    const data = ref<any>();
    let resParams = reactive({});
    const { getApiDataSouce } = useDataCenter({ dataSource: [] });
    const anaSearch = inject('anaSearch', undefined);
    const handleClick = (pm: { code: string[] }) => {
      const params = {
        data: { dataSource: getApiDataSouce(pm.code) },
        seriesName: 'SSummarize'
      };
      anaSearch(params);
    };
    const matchDataSourceByCode = (codes: string[]) => {
      return dataSourceMap.find(item => item.code.includes(codes[0]))!;
    };
    const schemeId = computed(() => {
      return props.params.schemeId &&
        !props.params.schemeId.toString().includes('mockId')
        ? props.params.schemeId
        : null;
    });
    const getData = async () => {
      try {
        resParams = cloneDeep(searchAnalyseParams.value);
        data.value = null;
        loading.value = true;
        delete resParams.dataSource;
        let defalutMediaTypeList = [];
        if (dataAuthType.value === 0) {
          defalutMediaTypeList = cloneDeep(mediaTypeList.value);
        }
        if (dataAuthType.value === 1) {
          defalutMediaTypeList = cloneDeep(mediaTypeLocalList.value);
        }
        if (dataAuthType.value === 2) {
          defalutMediaTypeList = cloneDeep(mediaTypeForeignList.value);
        }
        const arr = getfilterList(
          defalutMediaTypeList,
          sAnalysechannelIds.value,
          'value'
        );
        resParams = Object.assign({}, resParams, {
          dataSourceInfos: arr,
          schemeId: schemeId.value
        });
        if (resParams?.designatedMediaLabelId === 0) {
          resParams.searchDesignatedLabelVOS = [];
        } else {
          resParams.searchDesignatedLabelVOS =
            designatedMediaLabelArr.value.filter(item => {
              return item.id === resParams.designatedMediaLabelId;
            });
        }
        if (searchTime.value.startPubTime && searchTime.value.endPubTime) {
          (resParams.realTime = 0),
            (resParams.startPubTime = searchTime.value.startPubTime),
            (resParams.endPubTime = searchTime.value.endPubTime);
        }
        const res = await analysisOverview(resParams);
        if (res.code === 200) {
          const resData = res.data as any;
          let sourceData = [];
          if (Local.get('globalI18n') === 'zh-cn') {
            sourceData = (
              resData?.dataSourceCountBean?.dataSourceList || []
            ).map(item => {
              const targetDataSource = matchDataSourceByCode(item.code);
              return {
                ...item,
                label: targetDataSource.label,
                interactionStr: targetDataSource.interactionKeys
                  .map(key => {
                    return item[key] ? keyMap[key] + item[key] + '次' : '';
                  })
                  .filter(str => str)
              };
            });
          }
          if (Local.get('globalI18n') === 'en') {
            sourceData = (
              resData?.dataSourceCountBean?.dataSourceList || []
            ).map(item => {
              const targetDataSource = matchDataSourceByCode(item.code);
              return {
                ...item,
                label: item.label,
                interactionStr: targetDataSource.interactionKeys
                  .map(key => {
                    return item[key] ? item[key] + ' ' + keyMap[key] : '';
                  })
                  .filter(str => str)
              };
            });
          }

          data.value = {
            startTime: searchTime.value?.startPubTime || '',
            endTime: searchTime.value?.endPubTime || '',
            sourceData,
            total: resData?.dataSourceCountBean.totalCount,
            averageDailyTransmission:
              resData.dataSourceCountBean.averageDailyTransmission,
            positiveProportion: resData.emotionCount.positiveProportion || '-',
            neutralProportion: resData.emotionCount.neutralProportion || '-',
            negativeProportion: resData.emotionCount.negativeProportion || '-'
          };
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      loading,
      data,
      update,
      handleClick,
      Local
    };
  }
});
</script>
<style scoped>
p {
  text-indent: 2em;
}
</style>
<style lang="scss" scoped>
.chart-box-in {
  width: 100%;
  min-height: 100px !important;
  padding: 0 24px;
  :deep(.el-loading-mask) {
    z-index: 9 !important;
  }
  .source-count {
    cursor: pointer;
  }
}
</style>
