import { computed, onMounted, reactive, ref } from 'vue';
import { useSearchSchemeStore } from '/@/stores/searchScheme';
import useSearchForm from '/@/views/search/hooks/useSearchForm';
import {
  useEditorStore,
  IPrivateSettingParams,
  getCurrentSchemeConfig,
  flatSchemeTreeOption,
  transSchemeTree
} from '/@/stores/analysis';
import { useDictStore } from '/@/stores/dict';
import {
  ComponentOptionMap,
  EComponent
} from '/@/views/analysis/analysis-editor/canvas-panel/widget/component-library/library';
import {
  hotSizeOption,
  polarityOption,
  EpolarityType,
  deepClone,
  mediaTagsOption,
  mediaTagsConfig,
  polarityConfig,
  dataFieldOption,
  polarityOptionBigscreenThemeTwo
} from '/@/utils/other';
import { useRouter } from 'vue-router';
import { Local } from '/@/utils/storage';
import { i18n } from '/@/i18n/index';

const t = i18n.global.t;
const userPermission = Local.get('userPermission');
const dataAuth = userPermission ? JSON.parse(userPermission)?.dataAuth : 0;
const colorOption = [
  { label: '白色', value: '#ffffff' },
  { label: '黑色', value: '#000000' },
  { label: '透明', value: 'transparent' }
];

const fontSizeOption = [
  { label: '12', value: '12px' },
  { label: '14', value: '14px' },
  { label: '16', value: '16px' },
  { label: '18', value: '18px' },
  { label: '20', value: '20px' },
  { label: '22', value: '22px' },
  { label: '24', value: '24px' },
  { label: '26', value: '26px' },
  { label: '28', value: '28px' },
  { label: '30', value: '30px' }
];

export const titleRowsOption = [
  { label: '单行显示', value: 1 },
  { label: '双行显示', value: 2 }
];

// 信息列表自动滚动固定配置信息
export const autoScrollConfig = {
  step: 0.1,
  precision: 1,
  max: 5,
  min: 0.5
};

const { getChannelIds, getfilterList } = useSearchForm();
interface OptionSourceItem {
  label: string;
  value: number;
  config: any;
  children?: OptionSourceItem[];
}
export type TParams = IPrivateSettingParams;

// 获取不同组件的chartTypeOption
export const getChartTypeOption = (compType: EComponent) => {
  return (ComponentOptionMap[compType] as any).chartTypeOption || [];
};
// 获取不同组件的stackTypeOption
const getStackTypeOption = (compType: EComponent) => {
  return (ComponentOptionMap[compType] as any).stackTypeOption || [];
};
// 获取不同组件的stackTypeOption
const getSortFieldOption = (compType: EComponent) => {
  return (ComponentOptionMap[compType] as any).sortFieldOption || [];
};
const getDateIntervalOption = (compType: EComponent) => {
  return (ComponentOptionMap[compType] as any).dateIntervalOption || [];
};
// 根据情感数字获取中文名称和颜色
export const getPolarity = (polarityType: EpolarityType, theme?: string) => {
  if (theme == 'bigscreen-theme-2') {
    return polarityOptionBigscreenThemeTwo.find(item => {
      return item.value === polarityType;
    });
  } else {
    return polarityOption.find(item => {
      return item.value === polarityType;
    });
  }
};

// 获取不同组件的媒体标签mediaTagsOption
const getMediaTagsOption = (compType: EComponent) => {
  return (ComponentOptionMap[compType] as any).mediaTagsOption || [];
};

// 文章类型选项
export const articleTypeOption = [
  { label: t('message.analysisDetails.articleTypeOption[0]'), value: 1 }, // 原文
  { label: t('message.analysisDetails.articleTypeOption[1]'), value: 2 }, // 转发
  { label: t('message.analysisDetails.articleTypeOption[2]'), value: 3 }, // 评论
  { label: t('message.analysisDetails.articleTypeOption[3]'), value: 0 } // 对话
];

export const useDataCenter = (params?: IPrivateSettingParams) => {
  const router = useRouter();
  const { anlysisConfig } = useEditorStore();
  const SchemeStore = useSearchSchemeStore();
  //主题选项
  const SchemeTreeOption = computed(() => {
    const { transObj } = transSchemeTree(SchemeStore.schemeList);
    return [...transObj];
  });

  const dictStore = useDictStore();
  // 媒体渠道选项（和选中的数据源联动）
  const mediaTypeOption = ref(
    dataAuth == 0
      ? dictStore.mediaTypeList
      : dataAuth == 1
      ? dictStore.mediaTypeLocalList
      : dictStore.mediaTypeForeignList
  );
  // 动态设置媒体渠道选项
  const setMediaTypeOption = (id: number) => {
    mediaTypeOption.value = getCurrentSchemeConfig(id).dataSourceInfos;
  };

  const toSearchPage = (id: number) => {
    router.push({
      path: '/search/result',
      query: { id }
    });
  };
  !dictStore.provinceList.length && dictStore.getProvinceList();
  const provinceOption = computed(() => {
    return dictStore.provinceList;
  });

  // 获取当前搜索主题的config
  const getSchemeConfig = (id: number) => {
    const scheme = deepClone(flatSchemeTreeOption()).find(
      (item: { value: number }) => {
        return item.value === id;
      }
    );
    const config = scheme?.config;
    config.dataSource = config?.dataSourceInfos || config?.dataSource || [];
    delete config.dataSourceInfos;
    config.schemeConfigId && delete config.schemeConfigId;
    config.schemeId && delete config.schemeId;
    delete config.schemeName;
    if (!config.isForeignMedia && config.isForeignMedia != 0) {
      config.isForeignMedia = dataAuth;
    }
    return config;
  };
  // 点击下钻设置当前选中的主题（搜索列表导出增加schemeId传参，从curren取值）
  const setCurrentDrillingScheme = (schemeId: number) => {
    const scheme = deepClone(flatSchemeTreeOption()).find(
      (item: { value: number }) => {
        return item.value === schemeId;
      }
    );
    SchemeStore.setCurrent(scheme);
  };
  params?.schemeId && setCurrentDrillingScheme(params?.schemeId);
  if (!params) {
    // 画布全局设置
    return {
      SchemeTreeOption,
      getCurrentSchemeConfig,
      colorOption,
      fontSizeOption,
      toSearchPage,
      getChartTypeOption,
      getSchemeConfig,
      dataAuth,
      mediaTypeOption,
      EComponent,
      setCurrentDrillingScheme
    };
  }
  // 组件私有设置↓
  const currentParams = reactive(params);
  const mediaType = ref<string[]>(
    getChannelIds(
      currentParams.dataSourceUpdated
        ? currentParams.dataSource
        : getCurrentSchemeConfig(currentParams.schemeId).dataSourceInfos,
      'value'
    )
  ); // 字符串数组，用于前端回显
  // 联动更改媒体渠道为当前主题选中主题配置的媒体渠道（只做前端展示）
  const updateMediaType = () => {
    mediaType.value.splice(0);
    mediaType.value.push(
      ...getChannelIds(
        deepClone(
          getCurrentSchemeConfig(currentParams.schemeId).dataSourceInfos
        ),
        'value'
      )
    );
  };
  //更新参数的dataSource字段（接口需要的格式）
  const updateDataSouce = () => {
    currentParams.dataSource.splice(0);
    currentParams.dataSource.push(
      ...getfilterList(
        deepClone(mediaTypeOption.value),
        mediaType.value,
        'value'
      )
    );
  };
  const getApiDataSouce = (mediaType: string[]) => {
    return getfilterList(deepClone(mediaTypeOption.value), mediaType, 'value');
  };
  // 数据源修改后
  const schemeIdChange = (id: number) => {
    // setMediaTypeOption(id);
    updateMediaType();
    updateDataSouce();
    currentParams.dataSourceUpdated = false; //修改媒体渠道后标识为未修改，媒体渠道与数据源同步
  };
  // 媒体渠道修改后
  const mediaTypeChange = (val: string[]) => {
    updateDataSouce();
    currentParams.dataSourceUpdated = true; //修改媒体渠道后标识为已修改，已修改的媒体渠道不再和数据源同步
  };
  const mediaTags = ref<string>(
    currentParams.mediaTags && currentParams.mediaTags.length
      ? currentParams.mediaTags?.join('')
      : ''
  );
  const mediaTagsChange = (val: string) => {
    currentParams.mediaTags
      ? currentParams.mediaTags.splice(0)
      : (currentParams.mediaTags = []);
    if (!val) return;
    currentParams.mediaTags.splice(0);
    currentParams.mediaTags.push(val);
  };
  onMounted(() => {
    // updateDataSouce()
  });
  return {
    SchemeTreeOption,
    mediaTypeOption,
    mediaType,
    setMediaTypeOption,
    getChannelIds,
    getfilterList,
    schemeIdChange,
    mediaTypeChange,
    getSchemeConfig,
    getChartTypeOption,
    getStackTypeOption,
    EComponent,
    getSortFieldOption,
    hotSizeOption,
    getPolarity,
    getDateIntervalOption,
    toSearchPage,
    provinceOption,
    mediaTagsOption,
    polarityOption,
    getMediaTagsOption,
    dataAuth,
    getCurrentSchemeConfig,
    mediaTags,
    mediaTagsChange,
    polarityConfig,
    mediaTagsConfig,
    dataFieldOption,
    getApiDataSouce,
    setCurrentDrillingScheme
  };
};
