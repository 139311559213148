// 定义内容
export default {
  formI18nLabel: {
    name: 'name',
    email: 'email',
    autograph: 'autograph'
  },
  formI18nPlaceholder: {
    name: 'Please enter your name',
    email: 'Please enter the users Department',
    autograph: 'Please enter the login account name'
  },
  dataExportForm: {
    dataExportTitle: 'Data Export',
    dataExportBtn: ['Reset', 'Export', 'Check later', 'Check now'],
    dataExportLabel: [
      'Task Name:',
      'Exported Data Quantity:',
      'Remaining Quantity Available'
    ],
    dataExportPlaceholder: [
      'Enter a task name',
      'Task name cannot be longer than 30 characters',
      'Enter exported data quantity'
    ],
    exportField: 'Export Field',
    selectAll: 'Select All',
    exportFieldLabel: [
      'General Fields',
      'Article Information',
      'Interaction Statistics Fields',
      'Region Fields',
      'Content Extraction',
      'Intelligent Analysis',
      'More Fields'
    ],
    dataExportTip:
      'The data export task has begun. You can go to Task Center to view its progress.',
    exportFieldTip: [
      '',
      '',
      'The amount of interaction is the sum of retweets, comments and likes. If there are special requirements, you can export and recalculate.',
      'The country/region and provincial and municipal regional information is usually the location of the site, and the IP territory is usually the area where the account is published.',
      'Content extraction is the identification and extraction of high-frequency words and entities in the content of an article.',
      '',
      'The group field displays the page information of the e-newspaper, the channel information of the website, and the group information of the forum according to different media types.'
    ],
    tip: 'Note'
  },
  informationOverviewDrawer: {
    trace: 'Trace',
    informationOverview: 'Information Overview',
    disseminationPath: 'Dissemination Path'
  },
  copyModal: {
    copiedsuccessfully: 'Copied successfully.',
    tip: [
      'You can go to User Center > Copy Template Configuration to configure the information fields. ',
      'Set now>>'
    ],
    rememberTip: 'Remember my choice and do not ask again'
  },
  articleDetailsDrawer: {
    articleDetails: 'Article Details',
    hotspotExtraction: 'Hotspot Extraction',
    hotspotExtractionItems: [
      'Popular Terms',
      'Popular Locations',
      'Popular Figures',
      'Popular Organizations'
    ],
    similarInformation: 'Similar Information',
    nodata: ['No data available']
  },
  analysisShareForm: {
    modalTitle: 'Link Sharing',
    modalLabel: ['Mode', 'Validity Period', 'Link', 'Password'],
    pubStatus: ['Private', 'Public'],
    pubStatusTip: [
      'Only available for password holders',
      'Available for anyone'
    ],
    expireDayItem: ['Permanent', 'Valid for 7 Days', 'Valid for 30 Days'],
    copyLike: ['Copy link ', 'and password'],
    modalBtn: ['Cancel', 'Reset', 'Create Link'],
    copySuccss: 'Copy successfully'
  },
  createReportForm: {
    modalTitle: 'Create LLM-based Report',
    formLabel: [
      'Report Name',
      'Data Source',
      'Report Template',
      'Report Entity'
    ],
    formPlaceholder: [
      'Enter the report name',
      'Select the data source',
      'Enter the entity/position to generate from a specific perspective'
    ],
    dataSourceTip:
      'Please ensure that the data source you select has adequate news and social platform data. If the data volume is too small, the generated report may not be desired. If the data source involves various event topic data, the generated report may contain the analysis results for different topics.',
    entityTip:
      'The report is generated based on the viewpoint analysis of the parties involved from a specified entity/position. If the field is left empty, the report is generated based on a general public perspective.',
    countTip: [
      'Currently, 1 large model report is required,',
      ' with ',
      ' remaining reports.',
      ' remaining report'
    ],
    note: 'Note',
    createSuccTip: 'created successfully!',
    modalBtn: ['Cancel', 'Confirm', 'Check later', 'Check now']
  },
  timeControl: {
    selectTime: 'Select Time'
  }
};
