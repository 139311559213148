export default {
  hotSpot: {
    allChannels: '全部',
    pleaseEnterKeywords: '请输入搜索关键词',
    last24Hours: '近24小时',
    today: '今天',
    days3: '近3天',
    days7: '近7天',
    days30: '近30天',
    customize: '自定义',
    search: '查询',
    WeiboHot: '微博热搜',
    hotSearch: '热搜榜',
    trendingVideo: '视频榜',
    sameCity: '同城榜',
    rank: '排名',
    topic: '热点',
    onListForHours: '在榜时长/h',
    popularity: '热度',
    million: '万',
    hundredMillion: '亿',
    DouyinHot: '抖音热榜',
    ToutiaoHot: '头条热榜',
    BaiduHot: '百度热榜',
    KwaiHot: '快手热榜',
    XiaohongshuHot: '小红书热榜',
    BilibiliHot: 'B站热榜',
    ZhihuHot: '知乎热榜',
    hotSearchPlaceholder: '热点检索',
    listColumn: ['排名', '热点', '排名变化', '热度'],
    detailBtn: '查看热点详情>>',
    zbListColumn: [
      '排名',
      '热点榜单',
      '热点',
      '热度值',
      '热度趋势',
      '排名变化',
      '操作'
    ],
    detail: '详情',
    hotDetail: '热点详情',
    hotDetailBtn: ['搜一搜', '原文'],
    source: '来源',
    detailTop: ['当前排名', '排名变化', '最新热度', '在榜时长'],
    hotTrends: '热点趋势',
    hotNode: '热点节点',
    hotNodeColumn: ['编号', '节点', '排名', '热度值', '时间'],
    hotEnumNodeTexts: ['首次上榜', '掉出榜单', '再次上榜'],
    goback: '返回热榜',
    Xigua: '西瓜视频',
    Tencent: '腾讯视频',
    AcFun: 'A站',
    Youku: '优酷视频',
    Tudou: '土豆视频',
    iQIYI: '爱奇艺',
    huoshan: '火山小视频',
    WeiboVideo: '新浪微博视频',
    Weishi: '微视',
    Wangyi: '网易号',
    pipixia: '皮皮虾',
    huawei: '华为视频',
    deiwu: '得物',
    weiboxVideo: '微信视频号',
    Weibo: '微博',
    Douyin: '抖音',
    Toutiao: '头条',
    Baidu: '百度',
    Kwai: '快手',
    Xiaohongshu: '小红书',
    Bilibili: 'B站',
    Zhihu: '知乎',

    Wechat: '微信',
    Website: '网站',
    haokan: '好看视频',
    souhu: '搜狐视频',

    day: '天',
    hour: '小时',
    minute: '分',
    abnormalInformation: '异常信息'
  }
};
