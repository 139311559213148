<template>
  <w-form-item :label="label" field="name" :rules="validate">
    <w-input
      v-model.trim="name"
      :max-length="maxLength"
      :placeholder="placeholder"
      v-if="type === 'string'"
    />
    <w-input-number
      v-if="type === 'number'"
      v-model.trim="name"
      :max="max"
      :min="min"
      :step="step"
      :precision="precision"
      :placeholder="placeholder"
      mode="button"
    />
  </w-form-item>
</template>
<script lang="ts">
import { PropType, defineComponent, ref, watch } from 'vue';
import { i18n } from '/@/i18n/index';
const t = i18n.global.t;

export default defineComponent({
  name: 'ConfigInput',
  props: {
    label: {
      type: String
    },
    modalValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: t('message.analysisDetails.componentNamePl')
    },
    maxLength: {
      type: Number,
      default: 20
    },
    validate: {
      type: Array,
      default: () => {
        [];
      }
    },
    type: {
      type: String as PropType<'number' | 'string'>,
      default: 'string'
    },
    step: {
      type: Number,
      default: 1
    },
    precision: {
      type: Number,
      default: 0
    },
    max: {
      type: Number
    },
    min: {
      type: Number
    }
  },
  setup(props, { emit }) {
    const name = ref(props.modalValue);
    watch(name, val => {
      emit('update:modalValue', val);
    });
    return { name };
  }
});
</script>
