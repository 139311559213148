// 定义内容
export default {
  label: {
    one1: 'User name login',
    two2: 'Mobile number',
    three3: 'SMS login',
    four4: 'Account login'
  },
  link: {
    one3: 'Third party login',
    two4: 'Links'
  },
  account: {
    accountPlaceholder1: 'Please enter the user name',
    accountPlaceholder2: 'Please enter the password',
    accountPlaceholder3: 'Enter verification code',
    accountPlaceholder4: 'Verification code',
    accountBtnText: 'Sign in',
    forgetPassword: 'Forget password'
  },
  mobile: {
    placeholder1: 'Please input mobile phone number',
    placeholder2: 'Please enter the verification code',
    placeholder3: 'The format of the phone number is incorrect',
    codeText: 'Get code',
    btnText: 'Sign in',
    msgText:
      'Warm tip: it is recommended to use Google, Microsoft edge, version 79.0.1072.62 and above browsers, and 360 browser, please use speed mode'
  },
  scan: {
    text: 'Open the mobile phone to scan and quickly log in / register'
  },
  signInText: 'welcome back!'
};
