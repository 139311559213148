<script lang="ts" setup>
import IconItem from './IconItem.vue';
import { InfoListDataItem } from './InfoList.vue';

interface SiteNameProps {
  data: InfoListDataItem;
  size?: number;
  color?: string;
  iconType?: 'colorful';
}
const props = defineProps<SiteNameProps>();
// eslint-disable-next-line vue/no-setup-props-destructure
const dataSourceType = props.data.dataSourceType;
</script>
<template>
  <icon-item
    :sourceCode="dataSourceType"
    :name="data.site"
    :size="size"
    :color="color"
    :iconType="props.iconType"
  />
</template>
