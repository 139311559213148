<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(series[0]?.data?.length)"
    class="chart-box-in"
  >
    <LineChart
      ref="refChart"
      v-if="
        paramsReset.chartType == 'line' && !loading && series[0]?.data?.length
      "
      :series="series"
      :x-axis="{ data: xAxis }"
      y-axis
      :tooltip="tooltip"
      area
      :color="['#3B70FF']"
      :areaStyle="areaStyle"
      dataZoom
    />
    <BarChart
      v-memo="[]"
      ref="refChart"
      v-if="paramsReset.chartType == 'bar' && !loading && series.length"
      tooltip
      legend
      stack
      :series="series"
      :x-axis="{ data: xAxis }"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, reactive } from 'vue';
import { useSearchApi } from '/@/api/search';
import { getSourceName } from '/@/utils/other';
import { getPolarityName, getArticleTypeName } from '/@/utils/other';
import { storeToRefs } from 'pinia';
import { useSearchAnalyse } from '/@/stores/searchAnalyse';
import { useDictStore } from '/@/stores/dict';
import { cloneDeep } from 'lodash-es';
import { getfilterList } from '../components/utils';
import dayjs from 'dayjs';
import { getDateByInterval } from '/@/views/analysis/components/utils';
export default defineComponent({
  // 媒体曝光趋势
  name: 'SExposureTrend',
  props: {
    params: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(props) {
    const refChart = ref();
    const { analysisExposureTrend } = useSearchApi();
    const paramsReset = computed(() => {
      return props.params;
    });
    const xAxis = ref<any[]>([]);
    const xAxisWithYear = ref<any[]>([]);
    const series = ref<any[]>([]);
    const loading = ref(false);
    const tooltip = {
      formatter: param => {
        const { data, marker, name } = param as any;
        return `
      <div style="max-width: 320px;white-space:normal;">
        <div style="margin: 0px 0 0;line-height:1;">
          <div style="margin: 0px 0 0;line-height:1;">
            ${marker}
            <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${name}</span>
            <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${data}</span>
            <div style="clear:both"></div>
          </div>
          <div style="clear:both"></div>
        </div>
      </div>
      `;
      }
    };
    const areaStyle = {
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: 'rgba(58,132,255, 0.5)' // 0% 处的颜色
          },
          {
            offset: 1,
            color: 'rgba(58,132,255, 0)' //   100% 处的颜色
          }
        ],
        global: false // 缺省为 false
      }
    };
    const searchAnalyseStore = useSearchAnalyse();
    const dictStore = useDictStore();
    const {
      noStackResultData,
      searchAnalyseParams,
      sAnalysechannelIds,
      dataAuthType,
      designatedMediaLabelArr,
      searchTime
    } = storeToRefs(searchAnalyseStore);
    const { mediaTypeList, mediaTypeLocalList, mediaTypeForeignList } =
      storeToRefs(dictStore);
    const setTime = (intervalTime: string) => {
      let time = intervalTime.substr(5, 11);
      if (props.params.dateInterval === 'HOUR') {
        time = time.split(' ')[1];
      }
      if (props.params.dateInterval === 'DAY') {
        time = time.split(' ')[0];
      }
      if (props.params.dateInterval === 'MONTH') {
        time = time.substring(0, 2) + '月';
      }
      return time;
    };
    let resParams = reactive({});
    const getData = async () => {
      try {
        resParams = cloneDeep(searchAnalyseParams.value);
        loading.value = true;
        xAxis.value.splice(0);
        xAxisWithYear.value.splice(0);
        series.value.splice(0);
        delete resParams.dataSource;
        let defalutMediaTypeList = [];
        if (dataAuthType.value === 0) {
          defalutMediaTypeList = cloneDeep(mediaTypeList.value);
        }
        if (dataAuthType.value === 1) {
          defalutMediaTypeList = cloneDeep(mediaTypeLocalList.value);
        }
        if (dataAuthType.value === 2) {
          defalutMediaTypeList = cloneDeep(mediaTypeForeignList.value);
        }
        const arr = getfilterList(
          defalutMediaTypeList,
          sAnalysechannelIds.value,
          'value'
        );
        resParams = Object.assign({}, resParams, {
          dataSourceInfos: arr,
          schemeId: paramsReset.value.schemeId,
          stackType: paramsReset.value.stackType,
          dateInterval: paramsReset.value.dateInterval
        });
        if (
          resParams.realTime == 1 ||
          (resParams.realTime == 0 &&
            dayjs(resParams.endPubTime).diff(resParams.startPubTime, 'day')) <=
            1
        ) {
          resParams.dateInterval = 'HOUR';
        }
        if (resParams?.designatedMediaLabelId === 0) {
          resParams.searchDesignatedLabelVOS = [];
        } else {
          resParams.searchDesignatedLabelVOS =
            designatedMediaLabelArr.value.filter(item => {
              return item.id === resParams.designatedMediaLabelId;
            });
        }
        if (searchTime.value.startPubTime && searchTime.value.endPubTime) {
          (resParams.realTime = 0),
            (resParams.startPubTime = searchTime.value.startPubTime),
            (resParams.endPubTime = searchTime.value.endPubTime);
        }
        const res = await analysisExposureTrend(resParams);
        if (res.code === 200) {
          const resData = res.data as any;
          noStackResultData.value = resData?.noStackResult?.itemList || [];
          if (paramsReset.value.stackType == 0) {
            const dateInterval = resData?.noStackResult?.dateInterval;
            // 非堆叠数据
            const yAxis = [] as any[];
            resData?.noStackResult?.itemList.forEach((item: any) => {
              const dateStr = getDateByInterval(
                item.intervalTime,
                dateInterval
              );
              xAxis.value.push(dateStr);
              yAxis.push(Number(item.sum));
              xAxisWithYear.value.push(dateStr);
            });
            series.value.push({ data: yAxis, name: 'SExposureTrend' });
          } else {
            // 堆叠数据
            resData.stackedResult.forEach((item: any) => {
              const data = item.articleTypeExposureTrend.map((itm: any) => {
                const intervalTime: string = setTime(itm.intervalTime);
                !xAxis.value.includes(intervalTime) &&
                  xAxis.value.push(intervalTime);
                return Number(itm.sum);
              });
              series.value.push({
                name:
                  paramsReset.value.stackType === 3
                    ? getPolarityName(item.type)
                    : paramsReset.value.stackType === 7
                    ? getSourceName(item.type)
                    : paramsReset.value.stackType === 9
                    ? getArticleTypeName(item.type)
                    : item.type,
                data
              });
            });
          }
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      refChart,
      // isImage,
      xAxis,
      series,
      loading,
      paramsReset,
      update,
      tooltip,
      areaStyle,
      xAxisWithYear
    };
  }
});
</script>
<style lang="scss" scoped>
.chart-box-in {
  width: 100%;
  height: 330px !important;
  :deep(.el-loading-mask) {
    z-index: 9 !important;
  }
}
</style>
