import type { Arrayable, ECOption } from './buildChart';
import type { ShallowRef, Plugin, Component } from 'vue';
import { shallowRef } from 'vue';
import { TooltipComponentOption, Color } from 'echarts';

export * from './buildChart';

export const getOption = (option: ECOption): ShallowRef<ECOption> => {
  return shallowRef(option);
};

export const withInstall = (comp: Component) => {
  (comp as Component & Plugin).install = app => {
    app.component(comp.name as string, comp);
  };
  return comp;
};

export const isObject = (target: any) =>
  Object.prototype.toString.call(target) === '[object Object]';

export enum RangeTypes {
  increment = 'increment',
  decrement = 'decrement'
}

// 用于阶段演化组件
/**
 * 计算增减区间
 * @param data
 */

export const calcRange = (data: number[]) => {
  const ranges = [] as { start: number; end: number; type: RangeTypes }[];

  if (data.length <= 0) {
    return ranges;
  }
  if (data.length <= 1) {
    const [start] = data;
    return [
      {
        start: 0,
        end: 0,
        type: start >= 0 ? RangeTypes.increment : RangeTypes.decrement
      }
    ];
  }
  const symbols = data.map(d => (d > 0 ? 1 : d < 0 ? -1 : 0));
  let i = 1,
    lastIndex = 0,
    last = symbols[lastIndex];
  while (i < symbols.length) {
    let current = symbols[i];

    if (last !== current) {
      if (last === 0) {
        last = current;
      }

      if (current === 0) {
        current = last;
      }
    }
    if (last !== current) {
      ranges.push({
        start: lastIndex,
        end: i - 1,
        type: last < 0 ? RangeTypes.decrement : RangeTypes.increment
      });
      lastIndex = i;
      last = symbols[i];
    }

    i++;
  }

  ranges.push({
    start: lastIndex,
    end: i - 1,
    type: last < 0 ? RangeTypes.decrement : RangeTypes.increment
  });
  return ranges;
};

/**
 * 计算数据一阶差分
 * @param data
 */
export const lag = (data: number[]) => {
  if (!Array.isArray(data)) return data;
  const result = data.reduce((r, n) => {
    const l = r.pop();
    if (l !== undefined) {
      r.push(n - l);
    }
    r.push(n);
    return r;
  }, [] as number[]);
  result.pop();
  return result;
};

/**
 * @note
 * const data = [3, 3, 3, 2, 3, 4, 4, 1, 1]
 * console.log(calcRange(lag(data))) 
 * // result => [
  { start: 0, end: 2, type: 'decrement' },
  { start: 3, end: 5, type: 'increment' },
  { start: 6, end: 7, type: 'decrement' }
  ]
 * 使用差分函数计算出来的区间是一个序数区间，即 第0到第2个区间是一个减区间，对应着 0-3减
 * 3-6 增 6-8 减
 */

/**
 * hex颜色转rgb颜色
 * @param str 颜色值字符串
 * @returns 返回处理后的颜色值
 */
export function hexToRgb(str: any) {
  let hexs: any = '';
  const reg = /^\#?[0-9A-Fa-f]{6}$/;
  if (!reg.test(str)) throw new Error('输入错误的hex');
  str = str.replace('#', '');
  hexs = str.match(/../g);
  for (let i = 0; i < 3; i++) hexs[i] = parseInt(hexs[i], 16);
  return hexs;
}

export enum DataSource {
  Twitter = 1,
  Facebook = 2,
  Instagram = 3,
  Website = 4, //网站
  Blog = 5, // 博客
  Forum = 6, // 论坛
  ENewspaper = 7, // 电子报纸
  Wechat = 8,
  Mblog = 9,
  APP = 10,
  Youtube = 11,
  TVChannel = 12, // 电视节目
  Custom = 13, // 自定义
  ShortVideo = 14, // 短视频
  Telegram = 15,
  APPRecommend = 16, // APP 推荐
  TouTiao = 17, // 头条
  Video = 18, // 视频网站
  ZhiHu = 19, // 知乎
  QA = 20, // 问答
  Translation = 21, // 译文
  NewsComment = 22, // 新闻评论
  TV = 24, // 电视视频
  MblogMessage = 25, // 微博私信
  VK = 26,
  EC = 27, // 电商评论
  Scholar = 28 // 论文
}

export const getDataSourceName = (dataSource: DataSource) => {
  switch (dataSource) {
    case DataSource.Twitter:
      return 'Twitter';
    case DataSource.Facebook:
      return 'Facebook';
    case DataSource.Instagram:
      return 'Instagram';
    case DataSource.APP:
      return 'APP';
    case DataSource.Custom:
      return '自定义';
    case DataSource.ENewspaper:
      return '电子报纸';
    case DataSource.Forum:
      return '论坛';
    case DataSource.Mblog:
      return '微博';
    case DataSource.MblogMessage:
      return '微博私信';
    case DataSource.QA:
      return '问答';
    case DataSource.ShortVideo:
      return '短视频';
    case DataSource.TV:
      return '电视视频';
    case DataSource.Telegram:
      return 'Telegram';
    case DataSource.TouTiao:
      return '头条';
    case DataSource.Translation:
      return '译文';
    case DataSource.Video:
      return '视频网站';
    case DataSource.Website:
      return '网站';
    case DataSource.Wechat:
      return '微信';
    case DataSource.Youtube:
      return 'Youtube';
    case DataSource.ZhiHu:
      return '知乎';
    default:
      return '未知';
  }
};

export const getDataSourceCode = (dataSource: DataSource) => {
  let code = '';
  switch (dataSource) {
    case 1:
      code = 'twitter_tweet';
      break;
    case 2:
      code = 'facebook_blog';
      break;
    case 3:
      code = 'instagram_blog';
      break;
    case 4:
      code = 'news_info';
      break;
    case 5:
      code = 'blog_info';
      break;
    case 6:
      code = 'forum_thread';
      break;
    case 7:
      code = 'newspaper_info';
      break;
    case 8:
      code = 'wechat';
      break;
    case 9:
      code = 'mblog_info';
      break;
    case 10:
      code = 'appdata';
      break;
    case 11:
      code = 'youtube_videoinfo';
      break;
    case 12:
      code = 'tv_video_info';
      break;
    case 13:
      code = 'user_define_info';
      break;
    case 14:
      code = 'short_video_info';
      break;
    case 15:
      code = 'chat_monitor';
      break;
    case 16:
      code = 'app_recommend';
      break;
    case 17:
      code = 'self_media_info';
      break;
    case 18:
      code = 'video_data';
      break;
    case 19:
      code = 'zhihu_info';
      break;
    case 20:
      code = 'qa_info';
      break;
    case 21:
      code = 'translation_major_info';
      break;
    case 22:
      code = 'major_info_comments';
      break;
    case 24:
      code = 'tv_info';
      break;
    case 25:
      code = 'mblog_message';
      break;
    case 26:
      code = 'general_social_info';
      break;
    case 27:
      code = 'ec_info';
      break;
    case 28:
      code = 'scholar_info';
      break;
    default:
      code = 'user_define_info';
  }
  // return new URL(`./images/${url}.png`, import.meta.url).href
  return code;
};

export const getDataSourceImg = (dataSource: any) => {
  let code = '';
  switch (dataSource) {
    case 'Twitter':
    case 'X(Twitter)':
      code = 'twitter_tweet';
      break;
    case 'Facebook':
      code = 'facebook_blog';
      break;
    case 'Instagram':
      code = 'instagram_blog';
      break;
    case 'news':
      code = 'news_info';
      break;
    case 'blog':
      code = 'blog_info';
      break;
    case '电子报刊':
      code = 'newspaper_info';
      break;
    case '微信':
      code = 'wechat';
      break;
    case '微博':
      code = 'mblog_info';
      break;
    // case 'APP':
    //   code = 'appdata';
    //   break;
    case 'Youtube':
      code = 'youtube_videoinfo';
      break;
    case '西瓜视频':
      code = 'tv_info';
      break;
    case '腾讯视频':
      code = 'tv_info';
      break;
    case '好看视频':
      code = 'tv_info';
      break;
    case '搜狐视频':
      code = 'tv_info';
      break;
    case 'A站':
      code = 'tv_info';
      break;
    case 'B站':
      code = 'tv_info';
      break;
    case '优酷':
      code = 'tv_info';
      break;
    case '土豆':
      code = 'tv_info';
      break;
    case '爱奇艺':
      code = 'tv_info';
      break;
    case '抖音':
      code = 'short_video_info';
      break;
    case '快手':
      code = 'short_video_info';
      break;
    case 'APP':
      code = 'app_recommend';
      break;
    case '知乎':
      code = 'zhihu_info';
      break;
    case 20:
      code = 'qa_info';
      break;
    case 21:
      code = 'translation_major_info';
      break;
    case 22:
      code = 'major_info_comments';
      break;
    case 24:
      code = 'tv_info';
      break;
    case 25:
      code = 'mblog_message';
      break;
    case 26:
      code = 'general_social_info';
      break;
    case 27:
      code = 'ec_info';
      break;
    case 28:
      code = 'scholar_info';
      break;
    default:
      code = 'news_info';
  }
  // return new URL(`./images/${url}.png`, import.meta.url).href
  return code;
};

export const formatUnit = (count: number | string): string => {
  count = +count;
  if (count >= 1e12) {
    return (count / 1e12).toFixed(2) + ' 万亿';
  } else if (count >= 1e8) {
    return (count / 1e8).toFixed(2) + ' 亿';
  } else if (count >= 1e6) {
    return (count / 1e4).toFixed(2) + ' 万';
  }
  return count + '';
};

export const getFontWidth = (
  context?: CanvasRenderingContext2D,
  str?: string
) => {
  context =
    context ||
    (document
      .createElement('canvas')
      .getContext('2d') as CanvasRenderingContext2D);
  return context!.measureText(str || 'M').width;
};

export const buildAxisLabelFormatter = (props: { withUnit: boolean }) =>
  props.withUnit
    ? {
        axisLabel: {
          formatter: (val: number | string) => {
            return formatUnit(val);
          }
        }
      }
    : {};

export const buildTooltip = (
  tooltip?: boolean | TooltipComponentOption,
  option?: TooltipComponentOption
): TooltipComponentOption => {
  option = option || {};
  if (!tooltip) {
    return {
      show: false,
      ...option
    };
  } else if (typeof tooltip === 'boolean') {
    return {
      show: true,
      ...option
    };
  } else {
    return {
      ...option,
      ...tooltip
    };
  }
};

/**
 * 当坐标轴标签过长右侧标签被遮住时计算一个更大的右侧 grid
 * @param label 坐标轴label
 */
export const calcRightMargin = (label: string) => {
  const width = label ? getFontWidth(undefined, label) : 0;
  const half = Math.ceil(width / 2);
  const margin = half < 20 ? 20 : half + 10;
  return margin;
};

// 颜色选项
export const colors = [
  '#FE7770',
  '#FF9030',
  '#007CFF',
  '#7ABBFF',
  '#FFC797',
  '#6093FF',
  '#A7C3FF',
  '#3AC4D4',
  '#7EDAE5',
  '#FFA29E'
];

// 获取随机hex颜色
export const getRandomColor = () => {
  return '#' + ((Math.random() * 0xffffff) << 0).toString(16);
};

export type TTheme = 'bigscreen-theme-1' | 'bigscreen-theme-2' | undefined;

/**
 * 获取渐变颜色
 */
export const getChartGradientColor = (
  type: 'linear' | 'radial',
  colors: string[],
  xyr?: { x: number; y: number; x2?: number; y2?: number; r?: number }
): Color => {
  return {
    type,
    x: 0,
    y: 0,
    x2: 1,
    y2: 1,
    colorStops: colors.map((c, i) => {
      return {
        offset: i / (colors.length - 1),
        color: c
      };
    }),
    ...xyr
  } as Color;
};
