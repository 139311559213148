<script lang="ts" setup>
import { computed } from 'vue';
interface TopicItem {
  uid: string | number;
  title: string;
  hot: string | number;
}

interface TopicListProps {
  data: TopicItem[];
  deletable?: boolean;
  theme?: string;
}

const props = defineProps<TopicListProps>();

const emit = defineEmits<{
  (e: 'delete', item: TopicItem): void;
}>();

const remove = (item: TopicItem) => {
  emit('delete', item);
};

const noData = computed(() => !props.data || !props.data.length);

const data = computed(() => props.data);
</script>
<template>
  <div class="wh-chart--topic-list" :class="props.theme">
    <div class="topic-header">
      <slot name="header">
        <div class="column">序号</div>
        <div class="column">热点主题</div>
        <div class="column">主题热度</div>
      </slot>
    </div>
    <div class="topic-body">
      <div class="topic-item" v-for="(item, i) in data" :key="item.uid">
        <slot name="body">
          <div class="column">
            <div class="wh-item--index">{{ i + 1 }}</div>
          </div>
          <div class="column item-title">{{ item.title }}</div>
          <div class="column item-hot">
            <span class="hot-num">{{ item.hot }}</span
            ><c-icon
              v-if="deletable"
              size="16"
              name="guanbi"
              class="icon-delete"
              @click="remove(item)"
            ></c-icon>
          </div>
        </slot>
      </div>
      <slot name="noData" v-if="noData">
        <div class="noData">No Data</div>
      </slot>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '../style/colors' as c;
@use '../style/mixins' as m;

$height: 40px;

.wh-chart--topic-list {
  height: 100%;
  font-size: 14px;
  font-weight: 400;

  .topic-header,
  .topic-body .topic-item {
    display: flex;
  }

  .topic-header:deep(.column),
  .topic-body .topic-item:deep(.column) {
    &:first-child {
      flex: 0 0 60px;
      width: 60px;
      text-align: center;
    }

    &:nth-child(2) {
      flex: 1 1 80%;
      width: 80%;
    }

    &:last-child {
      flex: 0 0 90px;
      width: 90px;
      text-align: center;
    }
  }

  .topic-header {
    height: $height;
    line-height: $height;
    background-color: c.$gray-1;
    font-weight: 600;
  }

  .topic-body {
    height: calc(100% - 40px);
    // max-height: 320px;
    overflow-y: auto;

    .topic-item {
      &:nth-child(even) {
        background-color: c.$gray-1;
      }

      height: $height;
      line-height: $height;
      @include m.indexBox($className: 'wh-item--index', $top: 0, $right: 0);
      @include m.markTop3('wh-item--index');

      .wh-item--index {
        display: inline-block;
      }

      .item-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:nth-child(-n + 100) .item-hot {
        background: url(./images/fire.png) no-repeat left/16px 16px;
      }

      .item-hot {
        display: flex;
        align-items: center;
        padding-left: 16px;

        .hot-num {
          display: inline-block;
          width: 30px;
        }

        &.column {
          width: 80px;
          flex: 0 0 80px;
        }
      }
    }
  }

  .icon-delete {
    fill: c.$text-gray-1;
    margin-left: 6px;
    cursor: pointer;
  }
  &:deep.bigscreen-theme-2 {
    .topic-header {
      background-color: transparent;
      color: rgba(189, 219, 255, 0.6);
    }
    .topic-body {
      .topic-item {
        &:nth-child(odd) {
          background-color: rgba(70, 171, 255, 0.1);
        }
        &:nth-child(even) {
          background-color: transparent;
        }
        .wh-item--index {
          color: #bddbff;
          width: 28px;
          height: 20px;
          line-height: 20px;
          background-color: transparent !important;
          background-image: url(./images/top-icon.png);
          background-repeat: no-repeat;
          background-position: top left;
        }
        &:first-child {
          .wh-item--index {
            background-image: url(./images/top-icon-1.png);
            color: #ffbb66;
          }
        }
        &:nth-child(2) {
          .wh-item--index {
            background-image: url(./images/top-icon-2.png);
            color: #46abff;
          }
        }

        &:nth-child(3) {
          .wh-item--index {
            background-image: url(./images/top-icon-3.png);
            color: #46abff;
          }
        }
        .item-title {
          color: #c4d9f3;
        }
        &:nth-child(-n + 100) .item-hot {
          background: url(./images/fire-theme2.png) no-repeat left/19px 22px;
        }
        .item-hot {
          .hot-num {
            color: #ffa538;
          }
        }
      }
    }
  }
}

@include m.noData();
</style>
