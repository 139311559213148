<template>
  <div v-loading="loading" v-nodata="!Boolean(data.length) && !loading">
    <TopicList v-if="!loading && data.length" :data="data" :theme="theme">
      <template #header>
        <div class="column">
          {{ $t('message.analysisDetails.hotTopicHeaders[0]') }}
        </div>
        <div
          class="column no-wrap"
          :title="$t('message.analysisDetails.hotTopicHeaders[1]')"
        >
          {{ $t('message.analysisDetails.hotTopicHeaders[1]') }}
        </div>
        <div
          class="column no-wrap"
          :title="$t('message.analysisDetails.hotTopicHeaders[2]')"
        >
          {{ $t('message.analysisDetails.hotTopicHeaders[2]') }}
        </div>
      </template>
    </TopicList>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, inject } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import TopicList from '@wenhai/visual-components/TopicList';

export default defineComponent({
  // 热点主题
  name: 'AHotTopic',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const { hotTheme } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const chartsClick = inject('chartsClick', undefined);
    const initDrillingParams = inject('initDrillingParams');
    const data = ref<any[]>([]);
    const loading = ref(false);
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      data.value.splice(0);
      try {
        const res = await hotTheme(params.value);
        if (res.code === 200) {
          data.value = res.data;
          if (chartsClick) {
            initDrillingParams();
          }
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      data,
      loading,
      params,
      update
    };
  }
});
</script>
<style lang="scss" scoped>
@import '/@/theme/mixins/index.scss';

.no-wrap {
  @include text-no-wrap();
}
</style>
