// 定义内容
export default {
  contactsMenu: {
    menuList: ['账号设置', '联系人', '复制模板设置', '账号权益']
  },
  userInformation: {
    title: '个人信息',
    userName: '用户名称:',
    password: '账号密码:',
    nickName: '用户昵称:',
    editBtn: '编辑'
  },
  editInformation: [
    {
      title: '修改密码',
      passwordLabel: ['原始密码:', '新密码:', '确认密码:'],
      placeholder: ['请输入原始密码', '请输入新密码', '请输入新密码'],
      passwordRules: ['密码长度不少于8位数'],
      contentTip: [
        '1.密码的长度不少于8位数',
        '2.密码必须包含英文字母、数字、特殊字符其中两种或两种以上(不包括空格)'
      ],
      editBtns: ['确认', '取消']
    },
    {
      title: '修改用户昵称',
      nickNameLabel: ['新用户昵称:'],
      placeholder: ['请输入昵称']
    }
  ],
  updatePasswordRules: {
    passwordEditSuccess: '恭喜！密码修改成功！',
    oldPassword: '原始密码不能为空',
    newPassword: '新密码不能为空',
    confirmPassword: '确认密码不能为空',
    atypismPassword: '两次输入密码不一致!',
    confirmSameNew: '新密码不能与旧密码相同',
    passwordFormat: '密码格式错误，请点击图标查看密码格式详情。'
  },
  updateNickNameRules: {
    nonEmpty: '输入不能为空',
    sameAsOldNickName: '昵称不能为原昵称',
    updateSuccess: '恭喜！昵称修改成功！',
    format: '昵称格式错误，请重新输入'
  },
  mycontacts: {
    title: '我的联系人',
    addContactBtn: '添加微信接收人',
    searchContacts: '请输入联系人姓名搜索',
    orderNumber: '序号',
    nickname: '姓名',
    nicKNamePlaceholder: '请输入',
    openId: '微信号',
    createTime: '创建时间',
    operate: '操作',
    operateList: ['编辑', '保存', '删除'],
    unfollowed: '已取消关注'
  },
  addContactsList: {
    title: '添加新用户',
    scanAdd: '扫码新增：',
    renovate: '刷新',
    scanTip: '扫描二维码关注微信公众号，立享预警服务',
    scanSuccess: '扫码成功',
    scanName: '姓名:',
    scanNamePl: '请输入姓名',
    scanMark: [
      '（注：已添加的联系人可在',
      '“个人中心-联系人”',
      '中查看或修改）'
    ],
    scanNameRule: '请先输入姓名',
    modalBtns: ['取消', '保存']
  },
  copyTemplateConfiguration: {
    preview: '效果预览',
    btns: ['编辑', '保存']
  },
  accountInterests: {
    title: '监测、预警权益信息',
    dayMonitoring: '今日监测总量',
    dayWarning: '今日预警总量',
    totalTip: '共计',
    used: '已用',
    dayWarningTip: '指微信预警日推送数量，平台内预警提示不计数'
  }
};
