import type { App } from 'vue';
const useInfiniteScrollDirective = (app: App) => {
  let timer: NodeJS.Timer | null = null;
  const handleCb = (cb: Function, delay: number = 200) => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      cb();
    }, delay);
  };
  app.directive('infinite-scroll-xh', {
    mounted(el, binding) {
      if (!binding.value) return;
      const { immediate, distance, delay, callback } = binding.value;
      const dis = distance || 0;
      const restDistance = el.scrollHeight - (el.scrollTop + el.offsetHeight);
      if (immediate || restDistance <= dis) handleCb(callback, delay);
      el.addEventListener('scroll', () => {
        const restDistance = el.scrollHeight - (el.scrollTop + el.offsetHeight);
        if (restDistance <= dis) {
          handleCb(callback, delay);
        }
      });
    }
  });
};

export default useInfiniteScrollDirective;
