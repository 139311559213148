import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import AWritingHabit from './src/index.vue';

AWritingHabit.install = (app: App): void => {
  app.component('AWritingHabit', AWritingHabit);
  app.component(
    'AWritingHabitProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default AWritingHabit;
