import { post, get } from '/@/utils/request';
import { ResponseData } from '/@/api/report/interface';
import { AnalysisListItem } from '/@/stores/analysis';

type TopicResponseData = ResponseData<{
  analysisTopicVOList: AnalysisListItem[];
}>;

export interface AnalysisTopicSaveParams {
  name: string;
  id?: number;
  sid: number;
  templateId?: number;
  startTime: string;
  endTime: string;
  layout: string;
}

export default function useAnalysisApi() {
  return {
    // 获取分析列表
    getAnalysisTopicList: (pm: object) => {
      return post<TopicResponseData>('/analysis/analysisTopicList', pm);
    },
    // 获取分析专题画布
    getAnalysisCanvas: (id: number) => {
      return post<ResponseData>(`/analysis/analysisCanvas/${id}`, {});
    },
    // 创建分析
    saveAnalysisConfig: (pm: AnalysisTopicSaveParams) => {
      return post<ResponseData>('/analysis/saveAnalysisConfig', pm);
    },
    // 更新分析
    updateAnalysisConfig: (pm: AnalysisTopicSaveParams) => {
      return post<ResponseData>('/analysis/updateAnalysisConfig', pm);
    },
    // 保存模板
    saveAnalysisTemplate: (pm: {
      id?: number;
      name: string;
      layout: string;
    }) => {
      return post<ResponseData>('/analysis/updateAnalysisTemplate', pm);
    },
    // 获取模板列表
    getAnalysisTemplateList: () => {
      return post<ResponseData>('/analysis/analysisTemplateList', {});
    },
    // 获取大屏模板列表
    getBigScreenTemplateList: () => {
      return post<ResponseData>('/analysis/bigScreenTemplateList', {});
    },
    // 删除分析
    delAnalysisTopic: (id: number) => {
      return post<ResponseData>(`/analysis/delAnalysisTopic/${id}`, {});
    },
    // 复制分析
    copyAnalysisTopic: (pm: { name: string; id: number }) => {
      return post<ResponseData>('/analysis/copyAnalysisTopic', pm);
    },
    // 创建分析分享
    shareAnalysisResult: (pm: {
      id: number;
      pubStatus: number;
      expireDay: number;
    }) => {
      return post<
        ResponseData<{
          accessToken: string;
          path: string;
          sharePassword: string;
        }>
      >('/analysis/shareAnalysisResult', pm);
    },
    // 访问分享分析
    visitShareAnalysis: (pm: {
      accessToken: string;
      sharePassword: string;
    }) => {
      return post<ResponseData<{ layout: any[] }>>(
        '/analysis/VisitShareAnalysis',
        pm
      );
    },
    // 编辑分析专题
    saveAnalysisTopic: (pm: AnalysisTopicSaveParams) => {
      return post<ResponseData>('/analysis/saveAnalysisTopic', pm);
    },
    // 以下为各组件接口
    // 概述
    overview: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/overview', pm);
    },
    // 渠道首发
    channelDebut: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/channelDebut', pm);
    },
    // 媒体曝光趋势
    exposureTrend: (pm: object) => {
      return post<ResponseData>('/analysis/media/exposure-trend', pm);
    },
    // 媒体曝光趋势对比
    exposureTrendComparison: (pm: object) => {
      return post<ResponseData>('/analysis/media/exposureTrendComparison', pm);
    },
    // 渠道声量分析
    datasourceSound: (pm: object) => {
      return post<ResponseData>('/analysis/media/datasource-sound', pm);
    },
    // 账号榜单
    activeWebsite: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/activeWebsite', pm);
    },
    // 活跃账号
    activeAccount: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/activeAccount', pm);
    },
    // 热点文章
    hotArticle: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/hotArticle', pm);
    },
    // 信息内容分类
    informationApi: (pm: object) => {
      return post<ResponseData>(
        '/analysis/analyzeModule/contentClassification',
        pm
      );
    },
    // 热点词语
    hotWords: (pm: object) => {
      return post<ResponseData>('/analysis/information/hotWords', pm);
    },
    // 热点主题
    hotTheme: (pm: object) => {
      return post<ResponseData>('/analysis/information/hotTheme', pm);
    },
    // 专家观点
    expertOpinion: (pm: object) => {
      return post<ResponseData>('/analysis/information/expertOpinion', pm);
    },
    // 微博网民观点
    wbNetizensView: (pm: object) => {
      return post<ResponseData>('/analysis/information/wbNetizensView', pm);
    },
    // 信息概览
    infoOverview: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/infoOverview', pm);
    },
    // 互动概览
    interactionOverview: (pm: object) => {
      return post<ResponseData>(
        '/analysis/analyzeModule/interactionOverview',
        pm
      );
    },
    // 互动趋势
    interactiveTrend: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/interactiveTrend', pm);
    },
    // 热点短语
    hotPhrases: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/hotPhrases', pm);
    },
    // 传播国家量
    transCountryCount: (pm: object) => {
      return post<ResponseData>(
        '/analysis/analyzeModule/transCountryCount',
        pm
      );
    },
    // 传播语种量
    language: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/language', pm);
    },
    // 账号认证分布
    accountAuthentication: (pm: object) => {
      return post<ResponseData>(
        '/analysis/analyzeModule/certificationRatio',
        pm
      );
    },
    // 情感倾向
    emotionalTendency: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/polarityTendency', pm);
    },
    // 发文习惯
    writingHabit: (pm: object) => {
      return post<ResponseData>('/analysis/media/writingHabits', pm);
    },
    // 热门人物
    popularPeople: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/hotPerson', pm);
    },
    // 热门组织
    popularOrganization: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/hotOrg', pm);
    },
    // 提及地域分布
    mentionDistribution: (pm: object) => {
      return post<ResponseData>(
        '/analysis/analyzeArea/mentionDistribution',
        pm
      );
    },
    // 发布地域分布
    geographicDistribution: (pm: object) => {
      return post<ResponseData>(
        '/analysis/analyzeArea/geographicDistribution',
        pm
      );
    },
    // 微博转发关系
    getForwardRelationList: (id: number) => {
      return get<ResponseData>(
        '/search/search/getForwardRelationList?schemeId=' + id
      );
    }, //1.转发量文章列表
    forwardRelation: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/forwardRelation', pm);
    }, //2.转发关系
    // 账号性别分布
    genderDistribution: (pm: object) => {
      return post<ResponseData>(
        '/analysis/analyzeModule/genderDistribution',
        pm
      );
    },
    // 传播路径
    hotArticleDiffusionList: (pm: object) => {
      return post<ResponseData>(
        '/analysis/analyzeModule/hotArticleDiffusion/list',
        pm
      );
    },
    // 传播路径渠道数量统计
    hotArticleDiffusionCount: (pm: object) => {
      return post<ResponseData>(
        '/analysis/analyzeModule/hotArticleDiffusion/count',
        pm
      );
    },
    // 媒体等级分布
    mediaLevelDistributed: (pm: object) => {
      return post<ResponseData>(
        '/analysis/analyzeModule/mediaLevelDistributed',
        pm
      );
    },
    // 热门榜单
    hotList: (pm: object) => {
      return post<ResponseData>('/analysis/hotList/getHotList', pm);
    },
    // 获取媒体榜单账号
    mediaAccount: (pm: object) => {
      return post<ResponseData>('/analysis/hotList/getMediaListAccount', pm);
    },
    // 获取媒体榜单
    mediaList: (pm: object) => {
      return post<ResponseData>('/analysis/hotList/getMediaList', pm);
    },
    // 设置媒体榜单账号
    setMediaAccount: (pm: object) => {
      return post<ResponseData>('/analysis/hotList/setMediaListAccount', pm);
    },
    // 热门话题
    popularTopic: (pm: object) => {
      return post<ResponseData>('/analysis/analyzeModule/hotTopic', pm);
    },
    // 信息列表
    searchInfos: (pm: object) => {
      return post<ResponseData>('/analysis/information/searchInfos', pm);
    },
    // 声量占比
    soundProportion: (pm: object) => {
      return post<ResponseData>('/analysis/media/soundProportion', pm);
    },
    // 总声量渠道对比
    soundDatasourceComparison: (pm: object) => {
      return post<ResponseData>(
        '/analysis/media/soundDatasourceComparison',
        pm
      );
    },
    // 多主题情感对比
    schemePolarityComparison: (pm: object) => {
      return post<ResponseData>('/analysis/media/schemePolarityComparison', pm);
    },
    // 互动趋势对比
    interactiveTrendComparison: (pm: object) => {
      return post<ResponseData>(
        '/analysis/analyzeModule/interactiveTrendComparison',
        pm
      );
    },
    // 影响力指数
    influenceIndexation: (pm: object) => {
      return post<ResponseData>('/analysis/media/influenceIndexation', pm);
    },
    // 影响力指数榜单
    influenceRank: (pm: object) => {
      return post<ResponseData>('/analysis/media/influenceRank', pm);
    }
  };
}
