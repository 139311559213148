export default {
  reportList: {
    title: '大模型报告',
    activeBtn: [
      '创建报告',
      '重置',
      '查询',
      '查看',
      '重新生成',
      '删除',
      '取消',
      '确认'
    ],
    formPlaceholder: ['报告名称', '模板类型'],
    tableColumn: ['报告名称', '数据源', '模板类型', '创建时间', '状态', '操作'],
    deleteModalTip: ['删除报告', '确定要删除报告'],
    currentCount: ['当前剩余', '次'],
    statusMap: ['进行中', '成功', '失败'],
    flowTip: [
      '正在为您生成报告，请耐心等待...',
      '大模型报告生成完成',
      '立即查看'
    ],
    flowStatus: ['数据预处理', '大模型事件分析', '报告生成'],
    nodeDataStatus: ['等待中...', '中...', '成功'],
    reportOutline: '报告大纲',
    reportOutlineSet: '报告大纲设置',
    reportOutlineSetBtn: [
      '展开',
      '收起',
      '上移',
      '下移',
      '应用',
      '保存编辑',
      '报告下载'
    ],
    templateType: ['默认报告', '舆情报告']
  }
};
