import request, { post } from '/@/utils/request';

export function useSearchApi() {
  return {
    queryHots: () => {
      return request({
        url: '/search/hotList/getHotList',
        method: 'get'
      });
    },
    postTest: (params?: object) => {
      return request({
        url: '/gitee/lyt-top/vue-next-admin-images/raw/master/menu/adminMenu.json',
        method: 'get',
        params
      });
    },
    riskType: () => {
      return request({
        url: '/search/riskType/searchRiskType',
        method: 'get'
      });
    },
    areaList: () => {
      return request({
        url: '/search/searchMentionArea/tree',
        method: 'get'
      });
    },
    getAreaList: () => {
      return request({
        url: '/search/searchMentionArea/tree',
        method: 'get'
      });
    },
    AiList: () => {
      return request({
        url: '/search/windControl/searchWindControl',
        method: 'get'
      });
    },
    searchDataSOurce: () => {
      return request({
        url: '/search/dataSource/searchDataSOurce',
        method: 'get'
      });
    },
    channelStatistics: (params?: object) => {
      return request({
        url: '/search/search/getChannelStatistics',
        method: 'post',
        data: params
      });
    },
    searchProgramme: () => {
      return request({
        url: '/sentiment/monitorScheme/getList',
        method: 'get'
      });
    },
    warningConfigInfo: (id?: string) => {
      return request({
        url: '/warning/warningConfigInfo/' + id,
        method: 'get'
      });
    },
    warnSave: (params?: object) => {
      return request({
        url: '/warning/save',
        method: 'post',
        data: params
      });
    },
    searchDesignatedLabel: (params: any) => {
      return request({
        url: '/search/searchDesignatedLabel/list',
        method: 'post',
        data: params
      });
    },
    addDesignatedLabel: (params: any) => {
      return request({
        url: '/search/searchDesignatedLabel/insert',
        method: 'post',
        data: params
      });
    },
    updateDesignatedLabel: (params: any) => {
      return request({
        url: '/search/searchDesignatedLabel/update',
        method: 'post',
        data: params
      });
    },
    removeDesignatedLabel: (params: any) => {
      return request({
        url: `/search/searchDesignatedLabel/remove/${params.id}`,
        method: 'delete'
      });
    },
    designatedLabelAccount: (params: any) => {
      return request({
        url: '/search/searchDesignatedLabel/getAccount',
        method: 'post',
        data: params
      });
    },
    analysisOverview: (params: any) => {
      return request({
        url: '/analysis/overviewDetail',
        method: 'post',
        data: params
      });
    },
    analysisExposureTrend: (params: any) => {
      return request({
        url: '/analysis/exposure-trend',
        method: 'post',
        data: params
      });
    },
    analysisDatasourceSound: (params: any) => {
      return request({
        url: '/analysis/datasource-sound',
        method: 'post',
        data: params
      });
    },
    analysisActiveWebsite: (params: any) => {
      return request({
        url: '/analysis/activeWebsite',
        method: 'post',
        data: params
      });
    },
    articleTranslate: (params: any) => {
      return request({
        url: '/search/search/articleTranslate',
        method: 'post',
        data: params
      });
    },
    getLoadErrorImg: (params: any) => {
      return request({
        url: `/search/search/getImage?url=${params}`,
        method: 'get'
      });
    },
    getLoadErrorImgFig: (params: any) => {
      return request({
        url: `/search/search/getAbroadImage`,
        method: 'post',
        data: params
      });
    },
    getSearchSimilarityList: (params: any) => {
      return request({
        url: `/search/search/getSearchSimilarityList`,
        method: 'post',
        data: params
      });
    },
    analysisHotTheme: (params: any) => {
      return request({
        url: '/analysis/hotTheme',
        method: 'post',
        data: params
      });
    },
    analysisInteractiveTrend: (params: any) => {
      return request({
        url: '/analysis/interactiveTrend',
        method: 'post',
        data: params
      });
    },
    analysisPolarityTendency: (params: any) => {
      return request({
        url: '/analysis/polarityTendency',
        method: 'post',
        data: params
      });
    },
    //搜索-榜单列表
    queryHotListData: (params: { title?: string } = { title: '' }) => {
      return request({
        url: '/analysis/hotList/queryHotList',
        method: 'post',
        data: params
      });
    },
    //搜索-聚合榜单列表
    allHotListData: (params: any) => {
      return request({
        url: '/analysis/hotList/queryCityHotList',
        method: 'post',
        data: params
      });
    },
    // 获取翻译语种
    getTranslateLanguage: () => {
      return request({
        url: '/search/search/translateLanguage',
        method: 'post'
      });
    },
    // 获取翻译结果
    getTranslateResult: (params: any) => {
      return request({
        url: '/search/search/translateExpression',
        method: 'post',
        data: params
      });
    },
    //获取关键词智能推荐词
    getGeneralizedWords: (params: any) => {
      return request({
        url: '/search/search/keyword',
        method: 'post',
        data: params
      });
    },
    // 获取评论列表
    getCommentList: (params: any) => {
      return request({
        url: '/search/search/listArticleComment',
        method: 'post',
        data: params
      });
    },
    //热榜概述接口
    getHotListNode: (params: { uuid: string }) => {
      return request({
        url: '/analysis/hotList/getHotListNode',
        method: 'post',
        data: params
      });
    },
    //手动预警
    manualWarn: (params: any) => {
      return request({
        url: '/search/warn/manualWarn',
        method: 'post',
        data: params
      });
    },
    // 查询指定媒体标签组(中央媒体、商业门户)
    getSearchDesignatedLabelGroup: () => {
      return request({
        url: '/search/searchDesignatedLabel/searchDesignatedLabelGroup',
        method: 'post'
      });
    },
    // 剔除-取消剔除
    updateArticleHideMark: (pm: any) =>
      post<{ code: 200 | 500; data: any; msg: string }>(
        '/search/search/updateArticleHideMark',
        pm
      ),
    // 搜索转监测指定媒体
    convertMediaInfo: (params: any) => {
      return request({
        url: '/monitor/monitorPublic/convertMediaInfo',
        method: 'post',
        data: params
      });
    },
    // 主体情感修改
    subjectSentimentUpdate: (params: any) => {
      return request({
        url: '/search/search/subjectSentimentUpdate',
        method: 'post',
        data: params
      });
    }
  };
}
