<template>
  <div class="wh-chart--propagation-path-list">
    <target-article :info="props.targetInfo" :theme="theme"></target-article>
    <slot></slot>
    <div
      class="list-body"
      :class="{ 'list-body-theme2': theme == 'bigscreen-theme-2' }"
    >
      <div
        v-if="isMounted"
        v-infinite-scroll="load"
        :infinite-scroll-immediate="false"
        class="list"
      >
        <w-timeline>
          <w-timeline-item
            v-for="(item, index) in data"
            :key="item.uuid"
            class="item-list"
          >
            <template #dot>
              <span class="timeline-item-dot"></span>
            </template>
            <div class="item-list-container">
              <div class="item-time" v-if="item.publishTime">
                {{ item.publishTime }}
              </div>
              <div class="item-content">
                <div class="item-title">
                  <span class="firstPublish" v-if="index == 0">首发</span>
                  <span
                    class="title"
                    v-if="item.title"
                    v-html="item.title"
                  ></span>
                </div>
                <div class="item-bottom">
                  <span v-if="item.site">{{ item.site }}</span>
                  <span v-if="item.author">作者：{{ item.author }}</span>
                </div>
              </div>
            </div>
          </w-timeline-item>
        </w-timeline>
      </div>
      <w-divider v-if="loading" content-position="center">加载中...</w-divider>
      <w-divider v-if="bottomLine" content-position="center">到底了</w-divider>
      <slot name="nodata"></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
import TargetArticle from '@wenhai/visual-components/PropagationPathList/TargetArticle.vue';
import { onMounted, ref } from 'vue';
interface targetInfo {
  uuid?: string;
  title?: string;
  author?: string;
  publishTime?: string;
  site?: string;
  coverImage?: string;
}
interface PropagationPathProps {
  targetInfo: targetInfo;
  data?: any[];
  loading?: boolean;
  bottomLine?: boolean;
  theme?: String;
}
const emit = defineEmits<{
  (e: 'loadData'): void;
}>();
const props = withDefaults(defineProps<PropagationPathProps>(), {
  targetInfo: () => ({}),
  data: () => [],
  loading: false,
  bottomLine: false
});

const load = () => {
  emit('loadData');
};
const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;
});
</script>
<style lang="scss" scoped>
.wh-chart--propagation-path-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  .label-title {
    display: flex;
    align-items: center;
  }

  .list-body {
    flex: 1;
    overflow-y: auto;

    .item-list {
      .timeline-item-dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #c9cdd4;
        border-radius: 8px;
      }

      .item-list-container {
        .item-time {
          color: var(--color-text-3);
          line-height: 16px;
          font-size: 12px;
        }

        .item-content {
          // width: calc(100% - 16px);
          padding: 12px;
          margin-top: 8px;
          background: #f7f8fa;
          margin-bottom: 16px;

          .item-title {
            font-size: 14px;
            line-height: 22px;
            color: #3c4555;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 8px;

            .firstPublish {
              display: inline-block;
              margin-right: 8px;
              background: #d93026;
              border-radius: 2px;
              font-size: 12px;
              color: #fff;
              line-height: 20px;
              padding: 0 8px;
            }
          }

          .item-bottom {
            display: flex;
            flex-wrap: wrap;

            span {
              margin-right: 16px;
              color: var(--color-text-3);
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }

    :deep(.w-divider-horizontal.w-divider-with-text) {
      width: 75%;
      min-width: 50%;
      max-width: 75%;
      margin: 8px auto;

      .w-divider-text {
        background: var(--color-bg-white);
        color: var(--color-text-3);
        font-size: 12px;
      }
    }

    &.list-body-theme2 {
      :deep(.item-list) {
        .w-timeline-item-dot-wrapper {
          left: 5px;
        }

        .w-timeline-item-dot-line {
          border-left-style: dashed !important;
          border-left-color: rgba(255, 255, 255, 0.12);
        }

        .timeline-item-dot {
          width: 22px;
          height: 22px;
          border-radius: 0;
          background: url(./image/timeline_deco.png) no-repeat center/cover;
        }

        .item-time {
          color: rgba(189, 219, 255, 0.6);
        }

        .item-content {
          background: linear-gradient(
            90deg,
            #023e70 0%,
            rgba(2, 62, 112, 0) 100%
          );
          border-radius: 2px;

          .item-title {
            color: #c4d9f3;
          }

          .item-bottom {
            color: rgba(189, 219, 255, 0.6);
          }
        }
      }
    }
  }
}
</style>
