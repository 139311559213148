import { createI18n } from 'vue-i18n';
import pinia from '/@/stores/index';
import { storeToRefs } from 'pinia';
import { Local } from '/@/utils/storage';
import { useThemeConfig } from '/@/stores/themeConfig';
import zhcnLocale from 'element-plus/lib/locale/lang/zh-cn';
import enLocale from 'element-plus/lib/locale/lang/en';
import zhtwLocale from 'element-plus/lib/locale/lang/zh-tw';

import nextZhcn from '/@/i18n/lang/zh-cn';
import nextEn from '/@/i18n/lang/en';
import nextZhtw from '/@/i18n/lang/zh-tw';

import pagesLoginZhcn from '/@/i18n/pages/login/zh-cn';
import pagesLoginEn from '/@/i18n/pages/login/en';
import pagesLoginZhtw from '/@/i18n/pages/login/zh-tw';
import pagesFormI18nZhcn from '/@/i18n/pages/formI18n/zh-cn';
import pagesFormI18nEn from '/@/i18n/pages/formI18n/en';
import pagesFormI18nZhtw from '/@/i18n/pages/formI18n/zh-tw';
import pageSearchZhcn from '/@/i18n/pages/search/zh-cn';
import pageWarnningZhcn from '/@/i18n/pages/warning/zh-cn';
import pageWarnningZhtw from '/@/i18n/pages/warning/zh-tw';
import pageWarnningEn from '/@/i18n/pages/warning/en';
import pageSearchEn from '/@/i18n/pages/search/en';
import pageSearchZhtw from '/@/i18n/pages/search/zh-tw';
import pageMonitorZhcn from '/@/i18n/pages/monitor/zh-cn';
import pageMonitorZhyw from '/@/i18n/pages/monitor/zh-tw';
import pageMonitorEn from '/@/i18n/pages/monitor/en';
import pageCollectZhcn from '/@/i18n/pages/collect/zh-cn';
import pageCollectZhtw from '/@/i18n/pages/collect/zh-tw';
import pageCollectZhEn from '/@/i18n/pages/collect/en';
import pageContactsZhcn from '/@/i18n/pages/contacts/zh-cn';
import pageContactsZhtw from '/@/i18n/pages/contacts/zh-tw';
import pageContactsZhEn from '/@/i18n/pages/contacts/en';
import pageAnalysisZhcn from '/@/i18n/pages/analysis/zh-cn';
import pageAnalysisZhtw from '/@/i18n/pages/analysis/zh-tw';
import pageAnalysisEn from '/@/i18n/pages/analysis/en';
import pageReportZhcn from '/@/i18n/pages/report/zh-cn';
import pageReportZhtw from '/@/i18n/pages/report/zh-tw';
import pageReportEn from '/@/i18n/pages/report/en';
import pageTaskZhcn from '/@/i18n/pages/task/zh-cn';
import pageTaskZhtw from '/@/i18n/pages/task/zh-tw';
import pageTaskEn from '/@/i18n/pages/task/en';
import pageHotSpotZhcn from '/@/i18n/pages/hotspot/zh-cn';
import pageHotSpotZhtw from '/@/i18n/pages/hotspot/zh-tw';
import pageHotSpotEn from '/@/i18n/pages/hotspot/en';
import pageSearchReportZhcn from '/@/i18n/pages/searchReport/zh-cn';
import pageSearchReportZhtw from '/@/i18n/pages/searchReport/zh-tw';
import pageSearchReportEn from '/@/i18n/pages/searchReport/en';

// 定义语言国际化内容
/**
 * 说明：
 * /src/i18n/lang 下的 ts 为框架的国际化内容
 * /src/i18n/pages 下的 ts 为各界面的国际化内容
 */
const messages = {
  [zhcnLocale.name]: {
    ...zhcnLocale,
    message: {
      ...nextZhcn,
      ...pagesLoginZhcn,
      ...pagesFormI18nZhcn,
      ...pageWarnningZhcn,
      ...pageSearchZhcn,
      ...pageMonitorZhcn,
      ...pageCollectZhcn,
      ...pageContactsZhcn,
      ...pageAnalysisZhcn,
      ...pageReportZhcn,
      ...pageTaskZhcn,
      ...pageHotSpotZhcn,
      ...pageSearchReportZhcn
    }
  },
  [enLocale.name]: {
    ...enLocale,
    message: {
      ...nextEn,
      ...pagesLoginEn,
      ...pagesFormI18nEn,
      ...pageWarnningEn,
      ...pageMonitorEn,
      ...pageCollectZhEn,
      ...pageContactsZhEn,
      ...pageSearchEn,
      ...pageAnalysisEn,
      ...pageReportEn,
      ...pageTaskEn,
      ...pageHotSpotEn,
      ...pageSearchReportEn
    }
  },
  [zhtwLocale.name]: {
    ...zhtwLocale,
    message: {
      ...nextZhtw,
      ...pagesLoginZhtw,
      ...pagesFormI18nZhtw,
      ...pageWarnningZhtw,
      ...pageMonitorZhyw,
      ...pageCollectZhtw,
      ...pageContactsZhtw,
      ...pageSearchZhtw,
      ...pageAnalysisZhtw,
      ...pageReportZhtw,
      ...pageTaskZhtw,
      ...pageHotSpotZhtw,
      ...pageSearchReportZhtw
    }
  }
};

// 读取 pinia 默认语言
const stores = useThemeConfig(pinia);
const { themeConfig } = storeToRefs(stores);

// 导出语言国际化
// https://vue-i18n.intlify.dev/guide/essentials/fallback.html#explicit-fallback-with-one-locale
export const i18n = createI18n({
  silentTranslationWarn: true,
  missingWarn: false,
  silentFallbackWarn: true,
  fallbackWarn: false,
  locale: Local.get('globalI18n') || 'zh-cn',
  fallbackLocale: zhcnLocale.name,
  messages
});
