<template>
  <div :class="['bigscreen-page', theme]">
    <span class="name" @click.prevent.stop="changePage(obj.pageLinkId)">{{
      obj.name
    }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useEditorStore, EsideBarType } from '/@/stores/analysis';
import { useRoute } from 'vue-router';
export default defineComponent({
  name: 'ABigscreenPage',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const obj = computed(() => {
      return props.params;
    });
    const { changeCurrentBigscreenPage, changeSideBarType } = useEditorStore();
    const route = useRoute();
    const changePage = (pageLinkId: number) => {
      if (route.name === 'bigscreenEditor') return;
      changeCurrentBigscreenPage(pageLinkId);
      changeSideBarType(EsideBarType.globalSetting);
    };
    return {
      obj,
      changePage
    };
  }
});
</script>
<style scoped lang="scss">
.bigscreen-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(17, 156, 254, 0.0116) 0%,
    rgba(0, 145, 255, 0.4982) 50%,
    rgba(20, 154, 255, 0.0115) 100%
  );
  border: 2px solid !important;
  border-image: linear-gradient(
      270deg,
      rgba(20.000000707805157, 154.00000602006912, 255, 0),
      rgba(150.7499796152115, 210.19467383623123, 255, 1),
      rgba(19.0000007674098, 154.00000602006912, 255, 0)
    )
    2 2 !important;

  span.name {
    cursor: pointer;
  }

  &.bigscreen-theme-2 {
    > span.name {
      color: #ffffff;
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      background: linear-gradient(
        180deg,
        #149aff 0%,
        #ffffff 50%,
        #ffffff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
      letter-spacing: 2px;
    }
  }
}
</style>
