<template>
  <div v-loading="loading" v-nodata="!Boolean(data.data.length) && !loading">
    <StageEvolution
      v-if="!loading && data.data.length"
      :series="data"
      :theme="theme"
      @chart:click="chartClick"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, toRef, inject } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { useDataCenter } from '../../_hooks/use-data-center';

export default defineComponent({
  name: 'AEvolution',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const { exposureTrend } = useAnalysisApi();
    const data = ref<{ data: { name: string; value: [string, number] }[] }>({
      data: []
    });
    const loading = ref(false);
    const params = toRef(props, 'params');
    const { setCurrentDrillingScheme } = useDataCenter(params.value);
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const apiData = ref();
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        startPubTime: apiData.value?.noStackResult.startPubTime,
        endPubTime: apiData.value?.noStackResult.endPubTime,
        aeResultData: apiData.value?.noStackResult?.itemList || []
      };
    };
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      try {
        const res = (await exposureTrend({
          ...params.value,
          useDateInterval: 1
        })) as any;
        apiData.value = res.data;
        if (chartsClick) {
          setDrillPreviewData();
          searchResultList();
        }
        data.value.data = res.data?.noStackResult?.itemList?.map(
          (item: { intervalTime: string; sum: number }) => {
            return {
              name: item.intervalTime.split(' ')[0],
              value: [item.intervalTime.split(' ')[0], item.sum]
            };
          }
        );
      } catch (error) {
        data.value.data = [];
        loading.value = false;
      }
      loading.value = false;
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(params.value.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      data,
      loading,
      params,
      update,
      chartClick
    };
  }
});
</script>
