<template>
  <div v-loading="loading" v-nodata="!Boolean(data.length) && !loading">
    <InfoList v-if="!loading" :data="data" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import useAnalysisApi from '/@/api/analysis';

export default defineComponent({
  // 负面信息列表
  name: 'ANegativeInfoList',
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { hotArticle } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const data = ref<any[]>([]);
    const loading = ref(false);
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      data.value.splice(0);
      try {
        const res = await hotArticle(params.value);
        if (res.code === 200) {
          const resData = res.data as any[];
          data.value.push(
            ...resData.map(item => {
              return {
                id: item.uuid,
                title: item.title,
                url: item.url,
                dataSource: item.dataSourceType,
                site: item.websiteName,
                pubtime: item.publishTime
              };
            })
          );
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      data,
      loading,
      params,
      update
    };
  }
});
</script>
<style scoped>
.wh-chart--info-list {
  width: 100%;
  height: 100%;
}
</style>
