import axios from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Session, Local } from '/@/utils/storage';
import {
  NOT_ERROR_LIS,
  TOKEN_ERROR_CODE,
  SENTRY_ERROR_CODE,
  NOT_WARNING_LIS,
  MUST_WARNING_LIS,
  REQUEST_HEADER_AGENT,
  SHARE_LIST,
  ROUTER_LIST,
  ROUTER2_LIST
} from '/@/constants/cons';
import * as Sentry from '@sentry/vue';
import { i18n } from '/@/i18n/index';
const t = i18n.global.t;
// 配置新建一个 axios 实例
const service = axios.create({
  baseURL: import.meta.env.VITE_API_URL as any,
  timeout: 500000,
  headers: { 'Content-Type': 'application/json' }
});
// 添加请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么 token
    if (Session.get('wisky-token')) {
      (<any>config.headers).common['Authorization'] = `${Session.get(
        'wisky-token'
      )}`;
    }
    // 分享页面单独存的token
    if (
      (window.location.hash.indexOf('from=sheqing') > -1 ||
        window.location.hash.indexOf('from=qinghai') > -1 ||
        window.location.hash.indexOf('#/analysis') > -1 ||
        window.location.hash.indexOf('#/bigscreen') > -1) &&
      Session.get('share-token')
    ) {
      (<any>config.headers).common['Authorization'] = `${Session.get(
        'share-token'
      )}`;
    }
    if (
      ROUTER_LIST.includes(window.location.hash) &&
      Session.get('share-token')
    ) {
      (<any>config.headers).common['Authorization'] = `${Session.get(
        'share-token'
      )}`;
    }
    // 分享其他接口用原有token
    if (
      (window.location.hash === '#/qinghai' &&
        SHARE_LIST.includes(config.url)) ||
      (window.location.hash.indexOf('from=qinghai') > -1 &&
        SHARE_LIST.includes(config.url)) ||
      (window.location.hash === '#/sheqing' &&
        SHARE_LIST.includes(config.url)) ||
      (window.location.hash.indexOf('from=sheqing') > -1 &&
        SHARE_LIST.includes(config.url))
    ) {
      (<any>config.headers).common['Authorization'] = `${Session.get(
        'wisky-token'
      )}`;
    }
    if (REQUEST_HEADER_AGENT.includes(config.url)) {
      if (Session.get('agent-uuid')) {
        (<any>config.headers).common['Agent-Uuid'] = `${Session.get(
          'agent-uuid'
        )}`;
      }
      if (Session.get('browser-info')) {
        const browserInfo = Session.get('browser-info');
        (<any>config.headers).common['Agent-Name'] = `${browserInfo.name}`;
        (<any>config.headers).common[
          'Agent-Version'
        ] = `${browserInfo.version}`;
      }
    }
    // 国际化翻译请求头
    if (Local.get('globalI18n')) {
      const globalI18n = Local.get('globalI18n') === 'zh-cn' ? 'zh' : 'en';
      (<any>config.headers).common['sysThemeLanguage'] = `${globalI18n}`;
    }
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    const res = response.data;
    if (res.code && res.code !== 0) {
      // `token` 过期或者账号已在别处登录
      if (TOKEN_ERROR_CODE.includes(res.code)) {
        if (!Session.get('userInfo')) {
          return Promise.reject(response.data);
        }
        if (!Session.get('wisky-token')) {
          // window.location.href = '/'; // 去登录页
          window.location.href = window.location.pathname;
        }
        Session.clear(); // 清除浏览器全部临时缓存
        ElMessageBox.alert(res.msg, '提示', {
          confirmButtonText: 'OK',
          callback: () => {
            // window.location.href = '/'; // 去登录页
            window.location.href = window.location.pathname;
          }
        })
          .then(() => {})
          .catch(() => {});
        return Promise.reject(response.data);
      } else if (res.code !== 200 && res.code !== 600 && res.code !== 4003) {
        ElMessage.closeAll();
        if (
          NOT_ERROR_LIS.find(item => {
            return (
              item.includes(response.config.url) ||
              response.config.url?.includes(item)
            );
          })
        )
          return;
        if (
          !NOT_WARNING_LIS.find(item => {
            return (
              (item.includes(response.config.url) ||
                response.config.url?.includes(item)) &&
              !MUST_WARNING_LIS.includes(response.config.url)
            );
          })
        )
          (res.msg && ElMessage.warning(res.msg)) ||
            ElMessage.warning(t('message.codeMsg.msg1'));
        Local.set('ERRORMSG', {
          request: response?.config?.data,
          response: response?.data
        });
        if (
          SENTRY_ERROR_CODE.includes(res.code) &&
          !import.meta.env.VITE_LOCALIZATION
        ) {
          console.log(JSON.stringify(response));
          Sentry.captureException(response, {
            contexts: {
              message: {
                userInfos: Session.get('userInfo'),
                url: response?.config?.baseURL + response?.config?.url,
                data: JSON.stringify(response?.config?.data),
                method: response?.config?.method,
                status: response?.status,
                statusText: response?.statusText,
                responseData: JSON.stringify(response?.data)
              }
            }
          });
        }
        return Promise.reject(response.data);
      }
      return response.data;
    } else {
      return response.data;
    }
  },
  error => {
    ElMessage.closeAll();
    const url = error?.config?.url.split('?')?.[0] || '';
    if (!NOT_ERROR_LIS.includes(url)) {
      if (error.message.indexOf('timeout') != -1) {
        ElMessage.error('网络超时');
      } else if (error.message == 'Network Error') {
        ElMessage.error('网络连接错误');
      } else {
        if (error.response.data) ElMessage.error(error.response.statusText);
        else ElMessage.error('网络连接错误');
      }
      !import.meta.env.VITE_LOCALIZATION &&
        Sentry.captureException(error, {
          contexts: {
            message: {
              url: error?.config?.baseURL + error?.config?.url,
              data: error?.config?.data,
              method: error?.config?.method,
              status: error?.code,
              statusText: error?.message,
              responseData: JSON.stringify(error?.data)
            }
          }
        });
    }
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

// 导出 axios 实例
export default service;

export const post = <T>(url: string, params: unknown, cancelToken?: any) =>
  new Promise<T>((resolve, reject) => {
    // 响应做了拦截需要把AxiosResponse解出来
    (
      service({
        method: 'post',
        url,
        data: params,
        cancelToken: cancelToken ? cancelToken.token : undefined
      }) as Promise<T>
    )
      .then(value => {
        resolve(value);
      })
      .catch(reject);
  });

export const get = <T>(url: string, params?: unknown) =>
  new Promise<T>((resolve, reject) => {
    // 响应做了拦截需要把AxiosResponse解出来
    (
      service({
        method: 'get',
        url,
        params
      }) as Promise<T>
    )
      .then(value => {
        resolve(value);
      })
      .catch(reject);
  });

export const del = <T>(url: string, params?: unknown) =>
  new Promise<T>((resolve, reject) => {
    // 响应做了拦截需要把AxiosResponse解出来
    (
      service({
        method: 'delete',
        url,
        data: params
      }) as Promise<T>
    )
      .then(value => {
        resolve(value);
      })
      .catch(reject);
  });

export const put = <T>(url: string, params: unknown) =>
  new Promise<T>((resolve, reject) => {
    // 响应做了拦截需要把AxiosResponse解出来
    (
      service({
        method: 'put',
        url,
        data: params
      }) as Promise<T>
    )
      .then(value => {
        resolve(value);
      })
      .catch(reject);
  });
