<template>
  <div class="time-control">
    <div
      ref="timeTriggerPop"
      class="timeTriggerPop"
      style="position: relative"
    ></div>
    <w-form-item
      :label="$t('message.analysisDetails.timeRangLabel[0]')"
      class="w-form-item-time-control"
    >
      <w-config-provider :locale="i18nLocale">
        <w-date-picker
          ref="startTimePicker"
          :placeholder="$t('message.analysisDetails.timeRangLabelPlace[0]')"
          format="YYYY-MM-DD HH:mm:ss"
          v-model="startTimeInput"
          :style="styles"
          :show-time="showTime"
          :size="size"
          :clearable="clearable"
          :disabled="disabled"
          :disabledDate="getDisabledStartDate"
          :disabledTime="startDisabledTime"
          :default-picker-value="endTimeInput"
          :time-picker-props="timePickerPropsStart"
          @change="stratTimeChange"
          @select="stratTimeSelect"
          @ok="handleOk('start')"
          @clear="handleClearStartTime"
          @popup-visible-change="popupVisibleChangeStart"
          @picker-value-change="pickerValueChange"
        />
      </w-config-provider>
    </w-form-item>
    <w-form-item
      :label="$t('message.analysisDetails.timeRangLabel[1]')"
      class="w-form-item-time-control"
    >
      <w-config-provider :locale="i18nLocale">
        <w-date-picker
          ref="endTimePicker"
          :placeholder="$t('message.analysisDetails.timeRangLabelPlace[1]')"
          format="YYYY-MM-DD HH:mm:ss"
          v-model="endTimeInput"
          :style="styles"
          :show-time="showTime"
          :size="size"
          :clearable="clearable"
          :disabled="disabled"
          :disabledDate="getDisabledEndDate"
          :disabledTime="endDisabledTime"
          :default-picker-value="startTimeInput"
          :time-picker-props="timePickerPropsEnd"
          @change="endTimeChange"
          @select="endTimeSelect"
          @clear="handleClearEndTime"
          @ok="handleOk('end')"
          @popup-visible-change="popupVisibleChangeEnd"
          @picker-value-change="pickerValueChange"
        />
      </w-config-provider>
    </w-form-item>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  watchEffect,
  nextTick,
  PropType
} from 'vue';
import { useThemeConfig } from '/@/stores/themeConfig';
import { storeToRefs } from 'pinia';
import enUS from 'winbox-ui-next/es/locale/lang/en-us';
import zhCN from 'winbox-ui-next/es/locale/lang/zh-cn';
import zhTW from 'winbox-ui-next/es/locale/lang/zh-tw';
import { useI18n } from 'vue-i18n';
enum disabledTimeTypes {
  start = 'start',
  end = 'end'
}
export default defineComponent({
  name: 'TimeControl',
  props: {
    size: {
      type: String,
      default: 'default'
    },
    showTime: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dateRangeLimit: {
      // 可选时间范围限制
      type: Number
    },
    dateRangeSelectLimit: {
      // 可选时间跨度
      type: Number
    },
    disabledDate: {
      type: Function,
      default: () => false
    },
    startTime: {
      type: String
    },
    endTime: {
      type: String
    },
    allowFuture: {
      type: Boolean,
      default: false
    },
    styles: {
      type: String,
      default: 'width: 100%'
    },
    modelValue: {
      type: Array,
      default: () => ['', '']
    },
    timePickerPropsStart: {
      type: Object as PropType<any>
    },
    timePickerPropsEnd: {
      type: Object as PropType<any>
    }
  },
  setup(props, { emit }) {
    const startTimePicker = ref();
    const endTimePicker = ref();
    const timeTriggerPop = ref();
    const startTimeInput = ref<any>('');
    const endTimeInput = ref<any>('');
    // 不可选开始时间范围
    const getDisabledStartDate = (current: any) => {
      if (
        typeof props.disabledDate === 'function' &&
        props.disabledDate(current)
      )
        return true;
      if (dateMilliseconds(current) > dateMilliseconds(endTimeInput.value))
        return true;
      let _timer = new Date(current).getTime();
      let endTimer = new Date(endTimeInput.value).getTime();
      if (props.dateRangeSelectLimit) {
        let maxDay = props.dateRangeSelectLimit * 24 * 60 * 60 * 1000;
        // 超出跨度范围的禁用
        if (_timer < endTimer - maxDay) return true;
      }
      return (
        Math.abs(
          (new Date(current).getTime() - Date.now()) / (24 * 60 * 60 * 1000)
        ) > props.dateRangeLimit || new Date(current).getTime() > Date.now()
      );
    };
    const getDisabledEndDate = (current: any) => {
      if (
        typeof props.disabledDate === 'function' &&
        props.disabledDate(current)
      )
        return true;
      // 小于开始时间的禁用
      if (
        startTimeInput.value &&
        dateMilliseconds(current) < dateMilliseconds(startTimeInput.value)
      )
        return true;
      let _timer = new Date(current).getTime();
      let startTimer = new Date(startTimeInput.value).getTime();
      if (props.dateRangeSelectLimit) {
        let maxDay = props.dateRangeSelectLimit * 24 * 60 * 60 * 1000;
        // 超出跨度范围的禁用
        if (_timer > startTimer + maxDay) return true;
      }
      if (props.allowFuture) {
        return (
          new Date(current).getTime() - Date.now() < 0 &&
          Math.abs(
            (new Date(current).getTime() - Date.now()) / (24 * 60 * 60 * 1000)
          ) > props.dateRangeLimit
        );
        if (new Date(current).getTime() > Date.now()) return false;
      } else {
        return (
          Math.abs(
            (new Date(current).getTime() - Date.now()) / (24 * 60 * 60 * 1000)
          ) > props.dateRangeLimit || new Date(current).getTime() > Date.now()
        );
      }
    };
    // 时间禁用规则
    const disabledTime = (time: Date, type: string) => {
      // 判断输入时间类型是开始时间/结束时间
      const timeInput =
        type === disabledTimeTypes.start
          ? endTimeInput.value
          : startTimeInput.value;
      const hours = new Date(timeInput).getHours();
      const minutes = new Date(timeInput).getMinutes();
      const seconds = new Date(timeInput).getSeconds();
      const nowHours = new Date().getHours();
      const nowMinutes = new Date().getMinutes();
      const nowSeconds = new Date().getSeconds();
      let disabledHours: number[] = [];
      let disabledMinutes: number[] = [];
      let disabledSeconds: number[] = [];
      // 获取最长可选天数毫秒数
      let maxDay = props.dateRangeSelectLimit * 24 * 60 * 60 * 1000;
      // 开始时间和结束时间为同一天禁用逻辑

      if (dateMilliseconds(timeInput) === dateMilliseconds(time)) {
        let rangeHours = [];
        let rangeMinutes = [];
        let rangeSeconds = [];
        if (type === disabledTimeTypes.start) {
          rangeHours = range(hours + 1, 24);
          rangeMinutes = range(minutes + 1, 60);
          rangeSeconds = range(seconds + 1, 60);
        } else {
          rangeHours = range(0, hours);
          rangeMinutes = range(0, minutes);
          rangeSeconds = range(0, seconds);
        }
        disabledHours.push(...rangeHours);
        if (new Date(time).getHours() === hours)
          disabledMinutes.push(...rangeMinutes);
        if (
          new Date(time).getHours() === hours &&
          minutes === new Date(time).getMinutes()
        )
          disabledSeconds.push(...rangeSeconds);
      }
      // 当前选择时间组件与当前时间禁用逻辑
      if (dateMilliseconds(time) === dateMilliseconds(new Date())) {
        if (props.allowFuture && type === disabledTimeTypes.end) {
          console.log(1);
        } else {
          disabledHours.push(...range(nowHours + 1, 24));
          if (new Date(time).getHours() === nowHours)
            disabledMinutes.push(...range(nowMinutes + 1, 60));
          if (
            new Date(time).getHours() === nowHours &&
            new Date(time).getMinutes() === nowMinutes
          )
            disabledSeconds.push(...range(nowSeconds + 1, 60));
        }
      }
      // 最长可选天数临界点时间禁用
      if (
        props.dateRangeSelectLimit &&
        Math.abs(dateMilliseconds(timeInput) - dateMilliseconds(time)) ===
          maxDay
      ) {
        if (type === disabledTimeTypes.end) {
          disabledHours.push(...range(hours + 1, 24));
          if (new Date(time).getHours() === hours)
            disabledMinutes.push(...range(minutes + 1, 60));
          if (
            new Date(time).getHours() === hours &&
            new Date(time).getMinutes() === minutes
          )
            disabledSeconds.push(...range(seconds + 1, 60));
        }
      }
      return {
        disabledHours: () => disabledHours,
        disabledMinutes: () => disabledMinutes,
        disabledSeconds: () => disabledSeconds
      };
    };
    // 开始时间禁用判断
    const startDisabledTime = (time: Date) => {
      return disabledTime(time, disabledTimeTypes.start);
    };
    // 结束时间禁用判断
    const endDisabledTime = (time: Date) => {
      return disabledTime(time, disabledTimeTypes.end);
    };
    // 不可选时间数组
    const range = (start: number, end: number) => {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    };
    // 获取年月日的毫秒数
    const dateMilliseconds = (time: any) => {
      const date = new Date(time);
      let timer = new Date(
        date.getFullYear() +
          '-' +
          (date.getMonth() + 1) +
          '-' +
          date.getDate() +
          ' 00:00:00'
      ).getTime();
      return timer;
    };
    const stratTimeChange = (value: any) => {
      startTimeInput.value = value;
    };
    const stratTimeSelect = (value: any) => {
      startTimeInput.value = value;
    };
    const handleOk = type => {
      emit('update:modelValue', [startTimeInput.value, endTimeInput.value]);
      emit('change', [startTimeInput.value, endTimeInput.value], type);
    };
    const handleClearStartTime = () => {
      startTimeInput.value = '';
      emit('update:modelValue', [startTimeInput.value, endTimeInput.value]);
      emit('change', [startTimeInput.value, endTimeInput.value]);
    };
    const popupVisibleChangeStart = (visible: boolean) => {
      if (!visible) {
        emit('update:modelValue', [startTimeInput.value, endTimeInput.value]);
        emit('change', [startTimeInput.value, endTimeInput.value], 'start');
        // emit('update:endTime', endTimeInput.value);
        nextTick(() => {
          endTimePicker.value.$el.nextElementSibling.click();
        });
      }
      setTimeout(() => {
        changeTimeElementText();
      }, 0);
    };
    const endTimeChange = (value: any) => {
      endTimeInput.value = value;
    };
    const endTimeSelect = (value: any) => {
      endTimeInput.value = value;
    };
    const handleClearEndTime = () => {
      endTimeInput.value = '';
      emit('update:modelValue', [startTimeInput.value, endTimeInput.value]);
      emit('change', [startTimeInput.value, endTimeInput.value]);
    };
    const popupVisibleChangeEnd = (visible: boolean) => {
      if (!visible) {
        // emit('update:startTime', startTimeInput.value);
        emit('update:modelValue', [startTimeInput.value, endTimeInput.value]);
        emit('change', [startTimeInput.value, endTimeInput.value], 'end');
      }
      setTimeout(() => {
        changeTimeElementText();
      }, 0);
    };
    const startTimeShowPicker = () => {
      nextTick(() => {
        startTimePicker.value.$el.nextElementSibling.click();
      });
    };
    const storesThemeConfig = useThemeConfig();
    const { themeConfig } = storeToRefs(storesThemeConfig);
    const { t } = useI18n();
    const i18nLocale = computed(() => {
      const globalI18n = themeConfig.value.globalI18n || 'zh-cn';
      if (globalI18n == 'en') return enUS;
      else if (globalI18n == 'zh-tw') return zhTW;
      return zhCN;
    });
    const changeTimeElementText = () => {
      const titleElement = document.querySelectorAll(
        '.w-trigger-popup .w-picker-panel-wrapper .w-picker-header .w-picker-header-title'
      );
      for (let i = 0; i < titleElement.length; i++) {
        titleElement[i].innerHTML = titleElement[i].innerHTML
          .replace(/ 年 /g, '-')
          .replace(/月/g, '');
      }
      const selectTimeElement = document.querySelectorAll(
        '.w-trigger-popup .w-picker-panel-wrapper .w-panel-date-timepicker .w-panel-date-timepicker-title'
      );
      for (let i = 0; i < selectTimeElement.length; i++) {
        selectTimeElement[i].innerHTML = t('message.timeControl.selectTime');
      }
    };
    // 开始结束时间选择值
    watchEffect(() => {
      startTimeInput.value = props.modelValue[0] || '';
      endTimeInput.value = props.modelValue[1] || '';
      setTimeout(() => {
        changeTimeElementText();
      }, 0);
    });
    const pickerValueChange = () => {
      setTimeout(() => {
        changeTimeElementText();
      }, 0);
    };
    return {
      startTimePicker,
      endTimePicker,
      timeTriggerPop,
      startTimeInput,
      endTimeInput,
      getDisabledStartDate,
      startDisabledTime,
      getDisabledEndDate,
      endDisabledTime,
      stratTimeChange,
      stratTimeSelect,
      handleOk,
      popupVisibleChangeStart,
      endTimeChange,
      endTimeSelect,
      popupVisibleChangeEnd,
      startTimeShowPicker,
      handleClearStartTime,
      handleClearEndTime,
      i18nLocale,
      pickerValueChange
    };
  }
});
</script>
<style lang="scss" scoped>
.time-control {
  display: inline-block;
  .time-span {
    margin: 0 8px;
  }
  .timeTriggerPop {
  }
  :deep(.w-form-item-layout-vertical) {
    display: inline-flex !important;
    align-items: center;
    margin-bottom: 4px;
    > .w-form-item-label-col {
      margin-bottom: 0px;
      margin-right: 8px;
    }
  }
}
</style>
<style lang="scss">
.w-picker-shortcuts {
  display: flex !important;
  .w-btn {
    border: none;
  }
}
</style>
