import request from '/@/utils/request';
const vite_name = import.meta.env.VITE_NAME;
export default function useAuthorityApi() {
  return {
    // 用户权限查询
    getUserPermControl: () => {
      return request({
        url:
          vite_name == 'video'
            ? '/sentiment/monitorPublic/getUserPermControl'
            : '/system/userPermControl/getUserPermControl',
        method: 'post'
      });
    },
    //获取定制化
    getDetailProductCustomization: (
      id?: number | string,
      customConfiguration = { isReturnResponse: true }
    ) => {
      return request({
        url: `/system/odm/detail/${id}`,
        method: 'get',
        customConfiguration
      });
    }
  };
}
