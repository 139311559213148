<template>
  <div v-loading="loading">
    <Propagation-Capacity
      ref="refChart"
      v-if="!loading"
      :data="data"
      :class="{ 'propagation-theme2': theme == 'bigscreen-theme-2' }"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  reactive,
  watch,
  toRef
} from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  // 传播国家量
  name: 'APropagationCountry',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const { t } = useI18n();
    const refChart = ref();
    const { transCountryCount } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const loading = ref(false);
    const data = reactive({
      title: t('message.analysisDetails.propagationCountryDetail'),
      icon:
        props.theme == 'bigscreen-theme-2'
          ? new URL('./image/theme-2/country.png', import.meta.url).href
          : new URL('./image/country.png', import.meta.url).href,
      count: 0
    });
    watch(toRef(props, 'theme'), val => {
      data.icon =
        val == 'bigscreen-theme-2'
          ? new URL('./image/theme-2/country.png', import.meta.url).href
          : new URL('./image/country.png', import.meta.url).href;
    });
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      try {
        const res: any = await transCountryCount(params.value);
        if (res.code === 200) {
          data.count = res.data?.count || 0;
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      refChart,
      data,
      loading,
      params,
      update
    };
  }
});
</script>
<style lang="scss" scoped>
.propagation-theme2 {
  background: url(./image/theme-2/background.png) no-repeat center/100% 100%;

  :deep(.propagation-icon) {
    position: absolute;
    width: unset;
    height: 90px;
    top: 50%;
    transform: translateY(-50%);
    left: 10%;
  }

  :deep(.propagation-count) {
    position: absolute;
    left: 50%;
    top: 56%;
    font-size: 22px;
    font-family: YouSheBiaoTiHei;
    color: #d8f0ff;
    text-shadow: 0px 0px 4px #00a7ff;
  }

  :deep(.propagation-title) {
    position: absolute;
    left: 50%;
    top: 23%;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: rgba(189, 219, 255, 0.6);
  }
}
</style>
