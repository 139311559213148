import { defineAsyncComponent, AsyncComponentLoader } from 'vue';
import AsyncLoading from '/@/components/ui/loading/src/async-loading.vue';
import AsyncLoadingError from '/@/components/ui/loading/src/async-loading-error.vue';

export const loadAsyncComponent = (loader: AsyncComponentLoader<any>) =>
  defineAsyncComponent({
    loader,
    loadingComponent: AsyncLoading,
    errorComponent: AsyncLoadingError,
    delay: 20
  });
