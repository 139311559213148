export default {
  reportList: {
    title: 'LLM-based Report',
    activeBtn: [
      'Create Report',
      'Reset',
      'Search',
      'View',
      'Regenerate',
      'Delete',
      'Cancel',
      'Confirm'
    ],
    formPlaceholder: ['Report Name', 'Template Type'],
    tableColumn: [
      'Report Name',
      'Data Source',
      'Template Type',
      'Creation Time',
      'Status',
      'Action'
    ],
    deleteModalTip: ['Delete Report', 'Make sure to delete the report '],
    currentCount: ['Current remaining ', ''],
    statusMap: ['In Progress', 'Successful', 'Failed'],
    flowTip: [
      'Your report is being generated. Please wait...',
      'The LLM-based report generation is completed',
      'Check now'
    ],
    flowStatus: [
      'Data pre-processing ',
      'LLM-powered event analysis ',
      'Report generation '
    ],
    nodeDataStatus: ['is waiting...', 'is in progress...', 'succeeded'],
    reportOutline: 'Report Outline',
    reportOutlineSet: 'Report Outline Settings',
    reportOutlineSetBtn: [
      'Show More',
      'Show Less',
      'Move Up',
      'Move Down',
      'Apply',
      'Save',
      'Download'
    ],
    templateType: ['Default Report', 'Public Opinion Report']
  }
};
