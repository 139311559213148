<script lang="ts" setup>
import WordCloudBase, { SeriesItem } from './WordCloudBase.vue';
import WordCloudRect from './WordCloudRect.vue';
import WordCloudTree from './WordCloudTree.vue';
import WordCloudMap from './WordCloudMap.vue';
import WordCloudTopic from './WordCloudTopic.vue';
import WordCloudBaseTheme2 from './WordCloudBaseTheme2.vue';
import useChartClick from '../hooks/useChartClick';
import { ref } from 'vue';

interface Emits {
  (e: 'chart:click', params: any): void;
}

const props = defineProps<{
  series: SeriesItem;
  type: { value: string };
  theme: string;
  drilling: boolean;
  size: number[];
}>();
const emit = defineEmits<Emits>();
const chartRef = useChartClick(emit);
const types = ref(props.type?.value);
</script>
<template>
  <word-cloud-rect
    ref="chartRef"
    v-if="types === 'rect'"
    :series="props.series"
    :size="size"
  />
  <word-cloud-tree
    ref="chartRef"
    v-else-if="types === 'tree'"
    :series="props.series"
    :size="size"
  />
  <word-cloud-map
    ref="chartRef"
    v-else-if="types === 'map'"
    :series="props.series"
    drilling
    @item-click="emit('chart:click', $event)"
    :size="size"
  />
  <word-cloud-topic
    ref="chartRef"
    v-else-if="types === 'topic'"
    :series="props.series"
    :size="size"
  />
  <template v-else>
    <WordCloudBaseTheme2
      ref="chartRef"
      :series="series"
      v-if="props.theme == 'bigscreen-theme-2'"
      :size="size && [size[0], size[0]]"
    />
    <word-cloud-base v-else ref="chartRef" :series="series" :size="size" />
  </template>
</template>
<style lang="scss" scoped></style>
