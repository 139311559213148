<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(data.length) && !loading"
    :class="isMobile ? 'isMobile' : ''"
  >
    <CardStatisticsChart v-if="!loading" :data="data" :theme="theme" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch, toRef } from 'vue';

import useAnalysisApi from '/@/api/analysis';
import { useEditorStore } from '/@/stores/analysis';
import { useI18n } from 'vue-i18n';
import other from '/@/utils/other';
export default defineComponent({
  // 信息概览
  name: 'AInfoOverview',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const { t } = useI18n();
    const { infoOverview } = useAnalysisApi();
    const editStore = useEditorStore();
    const anlysisConfig = computed(() => {
      return editStore.anlysisConfig;
    });
    const iconOption = computed(() => {
      return props.theme == 'bigscreen-theme-2'
        ? [
            new URL('./icon/theme-2/xinxi.png', import.meta.url).href,
            new URL('./icon/theme-2/meiti.png', import.meta.url).href,
            new URL('./icon/theme-2/fengzhi.png', import.meta.url).href,
            new URL('./icon/theme-2/rijun.png', import.meta.url).href
          ]
        : [
            new URL('./icon/xinxi.png', import.meta.url).href,
            new URL('./icon/meiti.png', import.meta.url).href,
            new URL('./icon/fengzhi.png', import.meta.url).href,
            new URL('./icon/rijun.png', import.meta.url).href
          ];
    });
    watch(toRef(props, 'theme'), () => {
      data.value.forEach((item, i) => {
        item.icon = iconOption.value[i];
      });
    });
    const data = ref<
      { name: string; value: number | string; icon: string; color: string }[]
    >([]);
    const loading = ref(false);
    const getData = async () => {
      if (!props.params.schemeId) return;
      data.value.splice(0);
      loading.value = true;
      try {
        const res = await infoOverview(props.params);
        if (res?.code === 200) {
          const resData = res.data as any;
          data.value.push(
            ...[
              {
                name: t('message.analysisDetails.infoOverviewItem[0]'), //信息总量
                value: resData.totalInfo,
                icon: iconOption.value[0],
                color: '#A06FFF'
              },
              {
                name: t('message.analysisDetails.infoOverviewItem[1]'), // 媒体总量
                value: resData.totalMedia,
                icon: iconOption.value[1],
                color: '#FF6021'
              },
              {
                name: t('message.analysisDetails.infoOverviewItem[2]'), // 峰值传播量/小时
                value: `${resData.peakValue}`,
                icon: iconOption.value[2],
                color: '#FFBA2B'
              },
              {
                name: t('message.analysisDetails.infoOverviewItem[3]'), // 日均信息量
                value: resData.avgDailyValue,
                icon: iconOption.value[3],
                color: '#28B4FA'
              }
            ]
          );
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    const isMobile = ref(false);
    onMounted(() => {
      isMobile.value = other.isMobile();
      getData();
    });
    const update = () => {
      getData();
    };
    return {
      data,
      loading,
      update,
      anlysisConfig,
      isMobile
    };
  }
});
</script>
<style lang="scss" scoped>
.isMobile {
  :deep(.wh-chart--card) {
    .card-item {
      .icon {
        margin: 0 8px 0 8px;
      }
      .titleText {
        .sum {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
