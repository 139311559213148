<script lang="ts" setup>
import BaseChart from '../BaseChart/BaseChart.vue';
import { onMounted, shallowRef, watchEffect } from 'vue';
import type { ShallowRef } from 'vue';
import { ECOption, SeriesItem, ChartDataItem, buildTooltip } from '../utils';
import { buildLegend, hexToRgb } from '../utils';
import type { Color } from 'echarts';
import useChartClick from '../hooks/useChartClick';
import type { TooltipComponentOption, ECharts } from 'echarts';

interface Emits {
  (e: 'chart:click', params: any): void;
}

const props = defineProps<{
  series?: SeriesItem;
  data?: ChartDataItem[];
  color?: Color[];
  tooltip?: boolean | TooltipComponentOption;
  seriesName: string;
  label?: boolean;
  legend?: boolean;
  labelFormatter: string;
}>();

const emit = defineEmits<Emits>();
const chartRef = useChartClick(emit);
onMounted(() => {
  const chart = chartRef.value?.chart;
  const colors = props.color || (chart as ECharts).getOption().color;
  const colorLen = (colors as any[])?.length || 1;
  watchEffect(() => {
    const series = props.series as Exclude<SeriesItem, any[]>;
    let data = props.data || series?.data;
    const legend = buildLegend({
      show: props.legend,
      top: 0,
      left: 'center',
      type: 'scroll',
      icon: 'circle',
      itemHeight: 8,
      itemWidth: 8
    });
    const tooltip = buildTooltip(props.tooltip) as any;
    option.value = {
      legend,
      tooltip,
      grid: {
        containLabel: true
      },
      series: [
        {
          name: props.seriesName,
          type: 'pie',
          radius: ['30%', '50%'],
          center: ['50%', '54%'],
          data,
          label: {
            show: props.label,
            position: 'outside',
            formatter: props.labelFormatter
              ? props.labelFormatter
              : '{top|{b}}\n{middle|{c}}\n{bottom|{d}%}',
            rich: {
              top: {
                color: '#8C8C8C',
                fontSize: 12
              },
              middle: {
                color: '#0B7CFF',
                fontSize: 16,
                lineHeight: 20
              },
              bottom: {
                color: '#303030',
                fontSize: 14
              }
            }
          },
          minAngle: 25,
          startAngle: 180,
          labelLine: {
            length: 15
          },
          emphasis: {
            label: {
              show: props.label
            }
          },
          zlevel: 1
        },
        {
          type: 'pie',
          radius: ['20%', '30%'],
          center: ['50%', '54%'],
          data: data.map((d, i) => {
            let obj = typeof d === 'number' ? { value: d } : d;
            return {
              ...obj,
              itemStyle: {
                color: `rgba(${hexToRgb(colors?.[i % colorLen]).join(
                  ','
                )}, 0.4)`
              }
            };
          }),
          label: {
            show: false
          },
          minAngle: 25,
          startAngle: 180,
          zlevel: 2,
          silent: true
        }
      ]
    };
  });
  if (props.color) option.value.color = props.color;
  chart?.setOption(option.value);
});
const option: ShallowRef<ECOption> = shallowRef({});
</script>
<template>
  <BaseChart :option="option" ref="chartRef" />
</template>
<style lang="scss" scoped></style>
