import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import WorldClock from './src/index.vue';

WorldClock.install = (app: App): void => {
  app.component('AWorldClock', WorldClock);
  app.component(
    'AWorldClockProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default WorldClock;
