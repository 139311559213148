import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import PropagationLanguage from './src/index.vue';

PropagationLanguage.install = (app: App): void => {
  app.component('APropagationLanguage', PropagationLanguage);
  app.component(
    'APropagationLanguageProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default PropagationLanguage;
