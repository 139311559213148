// 定义内容
export default {
  formI18nLabel: {
    name: '姓名',
    email: '用戶歸屬部門',
    autograph: '登入帳戶名'
  },
  formI18nPlaceholder: {
    name: '請輸入姓名',
    email: '請輸入用戶歸屬部門',
    autograph: '請輸入登入帳戶名'
  },
  dataExportForm: {
    dataExportTitle: '数据导出',
    dataExportBtn: ['重置', '导出', '稍后查看', '立即查看'],
    dataExportLabel: ['任务名称：', '导出条数：', '权益剩余条数'],
    dataExportPlaceholder: [
      '请输入任务名称',
      '任务名称不可超过30个字符',
      '请输入导出条数'
    ],
    exportField: '导出字段',
    selectAll: '全选',
    exportFieldLabel: [
      '通用字段',
      '文章信息',
      '互动量数据',
      '地域信息',
      '内容提取',
      '智能分析',
      '更多字段'
    ],
    dataExportTip: '数据导出任务已开始，可前往“任务中心”查看任务进度',
    exportFieldTip: [
      '',
      '',
      '互动量为转发、评论、点赞之和。如有特殊需求，可导出后重新计算。',
      '国家/地区及省市地域信息通常为站点所属地域，IP属地通常为账号发布地域。',
      '内容提取是对文章内容中的高频词语和实体进行识别抽取。',
      '',
      '版面/频道/群组字段根据不同媒体渠道展示电子报纸的版面信息、网站等的频道信息、论坛等的群组信息。'
    ],
    tip: '提示'
  },
  informationOverviewDrawer: {
    trace: '信息溯源',
    informationOverview: '信息概况',
    disseminationPath: '传播路径'
  },
  copyModal: {
    copiedsuccessfully: '复制成功！',
    tip: ['可在个人中心-复制模板设置中设置复制信息字段，', '去设置>>'],
    rememberTip: '记住我的选择，下次不再提醒'
  },
  articleDetailsDrawer: {
    articleDetails: '文章详情',
    hotspotExtraction: '热点提取',
    hotspotExtractionItems: ['热点词语', '热点地点', '热门人物', '热门组织'],
    similarInformation: '相似信息',
    nodata: ['暂无识别内容']
  },
  analysisShareForm: {
    modalTitle: '链接分享',
    modalLabel: ['分享形式', '有效期', '链接', '密码'],
    pubStatus: ['加密', '公开'],
    pubStatusTip: ['权限拥有密码者可查看，更加隐私安全', '任何人可查看'],
    expireDayItem: ['永久有效', '7天有效', '30天有效'],
    copyLike: ['复制链接', '及密码'],
    modalBtn: ['取消', '重置', '创建链接'],
    copySuccss: '复制成功'
  },
  createReportForm: {
    modalTitle: '新建大模型报告',
    formLabel: ['报告名称', '数据源', '报告模板', '报告主体'],
    formPlaceholder: [
      '请输入报告名称',
      '请选择数据源',
      '如需基于特定视角生成报告，请输入主体/立场'
    ],
    dataSourceTip:
      '所选数据源需有足够的新闻和社交平台数据，如数据量过少，可能影响生成效果。若数据源包含多类事件主题的数据，报告可能包含多个主题的分析结果。',
    entityTip:
      '结合给定的主体或立场对各方观点进行研判,并生成报告;如输入为空则基于一般大众视角输出报告。',
    countTip: ['当前需要使用1次大模型报告，', '剩余', '次', '次'],
    note: '提示',
    createSuccTip: '大模型报告创建成功！',
    modalBtn: ['取消', '确定', '稍后查看', '去查看']
  },
  timeControl: {
    selectTime: '选择时间'
  }
};
