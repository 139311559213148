<script lang="ts" setup>
import type {
  XAXisComponentOption,
  YAXisComponentOption,
  TooltipComponentOption,
  GridComponentOption
} from 'echarts';
import { shallowRef, ShallowRef, PropType, watchEffect } from 'vue';
import BaseChart from '../BaseChart/BaseChart.vue';
import {
  SeriesItem,
  ChartDataItem,
  ECOption,
  normalizeAxis,
  Arrayable,
  buildLegend,
  buildGrid,
  normalizeSeries,
  buildAxisLabelFormatter,
  buildTooltip,
  buildGridWidthLegend,
  calcRightMargin
} from '../utils';
import { buildAxis } from '../utils';
import useChartClick from '../hooks/useChartClick';

export interface BarChartProps {
  series: SeriesItem<number | ChartDataItem>;
  xAxis?: XAXisComponentOption | boolean;
  yAxis?: YAXisComponentOption | boolean;
  legend?: boolean;
  tooltip?: boolean | TooltipComponentOption;
  horizontal?: boolean;
  rounded?: boolean;
  barWidth?: number;
}

interface Emits {
  (e: 'chart:click', params: any): void;
}
const emit = defineEmits<Emits>();

const colors = [
  '#007CFF',
  '#FF9030',
  '#3AC4D4',
  '#FE7770',
  '#00CB9B',
  '#B45BF3',
  '#00AAF8',
  '#FFAC30',
  '#FF70A9',
  '#6E70FF',
  '#FF8F66',
  '#2CD36A',
  '#3B6FFF',
  '#6093FF'
];

// 用 ts 泛型的方式在传参校验阶段会不识别类型
const props = defineProps({
  series: {
    type: Object as PropType<SeriesItem<number | ChartDataItem>>,
    required: true
  },
  tooltip: [Boolean, Object] as PropType<boolean | TooltipComponentOption>,
  legend: Boolean as PropType<boolean>,
  horizontal: Boolean as PropType<boolean>,
  rounded: Boolean as PropType<boolean>,
  stack: Boolean as PropType<boolean>,
  barWidth: {
    type: Number as PropType<number>,
    default: 24
  },
  xAxis: {
    type: [Object, Boolean] as PropType<XAXisComponentOption | boolean>,
    default: true
  },
  yAxis: {
    type: [Object, Boolean] as PropType<YAXisComponentOption | boolean>,
    default: true
  },
  withUnit: {
    type: Boolean as PropType<boolean>,
    default: true
  },
  label: Object,
  grid: Object as PropType<GridComponentOption>,
  dataZoom: Boolean,
  theme: {
    type: String as PropType<string>,
    required: false
  },
  colors: Array as PropType<string[]>
});
let option: ShallowRef<ECOption> = shallowRef({});
watchEffect(() => {
  const barWidth = props.barWidth;
  const axisLabel = buildAxisLabelFormatter(props);
  let xAxis = buildAxis('x', {
    ...normalizeAxis(props.xAxis),
    boundaryGap: true
  }) as Arrayable<XAXisComponentOption>;
  let yAxis = buildAxis('y', {
    ...normalizeAxis(props.yAxis),
    ...axisLabel
  } as YAXisComponentOption) as Arrayable<YAXisComponentOption>;

  let series = normalizeSeries(props.series, 'bar');
  series = series.map((s, i) => ({
    ...s,
    label: props.label,
    barMaxWidth: barWidth,
    stack: props.stack ? 'bar-stack' : undefined,
    itemStyle: {
      color: (props.colors || colors)[i % colors.length]
    },
    data: s.data?.map(d => {
      return {
        ...d,
        itemStyle: {
          color: d.color
        }
      };
    })
  }));

  if (props.horizontal) {
    [xAxis, yAxis] = [
      yAxis as Arrayable<XAXisComponentOption>,
      xAxis as Arrayable<YAXisComponentOption>
    ];
    yAxis = {
      ...yAxis,
      axisTick: {
        show: false
      }
    };
    if (props?.xAxis?.axisLabel?.rotate) {
      xAxis = {
        ...xAxis,
        axisLabel: { rotate: props.xAxis.axisLabel.rotate || 0 }
      };
    }
  } else {
    xAxis = {
      ...xAxis,
      axisTick: {
        alignWithLabel: true
      }
    } as XAXisComponentOption;
  }
  if (props.rounded) {
    series = series.map((s, i) => ({
      ...s,
      barWidth,
      itemStyle: { borderRadius: barWidth / 2 }
    }));
  }

  option.value = {
    series: series as unknown as any,
    xAxis,
    yAxis,
    dataZoom:
      props.dataZoom == false
        ? null
        : {
            startValue: 0,
            type: 'inside',
            minValueSpan: 2
          }
  };

  const label = !props.horizontal ? (xAxis as any)?.data?.[0] || '' : '';
  const rightMargin = calcRightMargin(label);

  if (props.legend) {
    option.value.legend = buildLegend({
      top: 10,
      icon: props.rounded ? 'roundRect' : 'rect',
      itemWidth: 8,
      itemHeight: 8,
      type: 'scroll'
    });
    option.value.grid = buildGridWidthLegend({
      right: rightMargin,
      ...(props.grid || {})
    });
  } else {
    option.value.grid = buildGrid({
      right: rightMargin,
      ...(props.grid || {})
    });
  }
  // props.tooltip && (option.value.tooltip = {})
  option.value.tooltip = buildTooltip(props.tooltip) as any;

  if (props.theme == 'bigscreen-theme-2') {
    const xStyle = {
      splitLine: {
        lineStyle: {
          color: 'rgba(176,215,255,0.25)',
          type: [5, 10],
          dashOffset: 5
        }
      },
      axisLabel: {
        color: 'rgba(216,240,255,0.6)',
        rotate: props.xAxis.axisLabel.rotate || 0
      }
    };
    const yStyle = {
      splitLine: {
        lineStyle: {
          color: 'rgba(176,215,255,0.25)',
          type: [5, 10],
          dashOffset: 5
        }
      },
      axisLabel: {
        color: 'rgba(216,240,255,0.6)'
      }
    };
    Object.assign(option.value.yAxis, yStyle);
    Object.assign(option.value.xAxis, xStyle);
  }
});

const chartRef = useChartClick(emit);
</script>
<template>
  <base-chart
    ref="chartRef"
    :option="option"
    class="wh-chart--bar"
    :bigscreenTheme="theme"
  />
</template>
<style lang="scss" scoped></style>
