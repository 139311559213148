<template>
  <w-form-item :label="label" field="name">
    <w-date-picker
      style="width: 100%"
      :show-time="showTime"
      :format="format"
      v-model="date"
    />
  </w-form-item>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import dayjs from 'dayjs';
export default defineComponent({
  name: 'ConfigDatePicker',
  props: {
    label: {
      type: String
    },
    modalValue: {
      type: String,
      required: true
    },
    showTime: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss'
    },
    disabledDate: {
      type: () => {}
    }
  },
  setup(props, { emit }) {
    const date = ref(props.modalValue);
    watch(date, val => {
      emit('update:modalValue', val);
    });
    return { date, dayjs };
  }
});
</script>
