<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(series.length)"
    class="chart-box-in"
  >
    <RingChart
      v-memo="[]"
      ref="refChart"
      v-if="paramsReset.chartType == 'pie' && !loading && series.length"
      :data="series"
      :tooltip="tooltip"
      label
      :labelStr="labelStr"
      seriesName="SChannelVolume"
    />
    <BarChart
      v-memo="[]"
      ref="refChart"
      v-if="paramsReset.chartType == 'bar' && !loading && series.length"
      tooltip
      legend
      stack
      :series="series"
      :x-axis="{ data: xAxis }"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, reactive } from 'vue';
import { useSearchApi } from '/@/api/search';
import { getPolarityName, getArticleTypeName } from '/@/utils/other';
import { storeToRefs } from 'pinia';
import { useSearchAnalyse } from '/@/stores/searchAnalyse';
import { useDictStore } from '/@/stores/dict';
import { cloneDeep } from 'lodash-es';
import { getfilterList } from '../components/utils';
export default defineComponent({
  // 渠道声量分析
  name: 'SChannelVolume',
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const refChart = ref();
    const { analysisDatasourceSound } = useSearchApi();
    const paramsReset = computed(() => {
      return props.params;
    });
    const xAxis = ref<any[]>([]);
    const series = ref<any[]>([]);
    const loading = ref(false);
    const tooltip = {
      formatter: param => {
        const { data, marker } = param as any;
        return `
      <div style="max-width: 320px;white-space:normal;">
        <div style="margin: 0px 0 0;line-height:1;">
          <div style="margin: 0px 0 0;line-height:1;">
            ${marker}
            <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${data.name}</span>
            <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${data.value} (${data.percent})</span>
            <div style="clear:both"></div>
          </div>
          <div style="clear:both"></div>
        </div>
      </div>
      `;
      }
    };
    const searchAnalyseStore = useSearchAnalyse();
    const dictStore = useDictStore();
    const {
      searchAnalyseParams,
      sAnalysechannelIds,
      dataAuthType,
      designatedMediaLabelArr,
      searchTime
    } = storeToRefs(searchAnalyseStore);
    const { mediaTypeList, mediaTypeLocalList, mediaTypeForeignList } =
      storeToRefs(dictStore);
    let resParams = reactive({});
    const labelStr = param => {
      const { data } = param as any;
      let count = numFormat(data.value);
      return `${data.name} ${count} (${data.percent})`;
    };
    const getData = async () => {
      try {
        resParams = cloneDeep(searchAnalyseParams.value);
        loading.value = true;
        xAxis.value.splice(0);
        series.value.splice(0);
        delete resParams.dataSource;
        let defalutMediaTypeList = [];
        if (dataAuthType.value === 0) {
          defalutMediaTypeList = cloneDeep(mediaTypeList.value);
        }
        if (dataAuthType.value === 1) {
          defalutMediaTypeList = cloneDeep(mediaTypeLocalList.value);
        }
        if (dataAuthType.value === 2) {
          defalutMediaTypeList = cloneDeep(mediaTypeForeignList.value);
        }
        const arr = getfilterList(
          defalutMediaTypeList,
          sAnalysechannelIds.value,
          'value'
        );
        resParams = Object.assign({}, resParams, {
          dataSourceInfos: arr,
          schemeId: paramsReset.value.schemeId,
          stackType: paramsReset.value.stackType
        });
        if (resParams?.designatedMediaLabelId === 0) {
          resParams.searchDesignatedLabelVOS = [];
        } else {
          resParams.searchDesignatedLabelVOS =
            designatedMediaLabelArr.value.filter(item => {
              return item.id === resParams.designatedMediaLabelId;
            });
        }
        if (searchTime.value.startPubTime && searchTime.value.endPubTime) {
          (resParams.realTime = 0),
            (resParams.startPubTime = searchTime.value.startPubTime),
            (resParams.endPubTime = searchTime.value.endPubTime);
        }
        const res = await analysisDatasourceSound(resParams);
        if (res.code === 200) {
          const resData = res.data as any;
          if (paramsReset.value.chartType === 'pie') {
            // 饼图
            series.value.push(
              ...resData.noStackResult.map((item: any) => {
                return {
                  name: item.label,
                  value: item.count,
                  percent: item.percent
                };
              })
            );
          } else {
            // 折线图
            if (paramsReset.value.stackType == 0) {
              // 非堆叠数据
              const yAxis = [] as any[];
              resData.noStackResult.forEach((item: any) => {
                xAxis.value.push(item.label);
                yAxis.push(Number(item.count));
              });
              series.value.push({ data: yAxis });
            } else {
              // 堆叠数据
              resData.stackedResult.forEach((item: any, i: number) => {
                const data = item.propagatedStackTypeVOS.map((itm: any) => {
                  if (i === 0) {
                    xAxis.value.push(
                      paramsReset.value.stackType == 3
                        ? getPolarityName(Number(itm.type))
                        : getArticleTypeName(itm.type)
                    );
                  }
                  return Number(itm.count);
                });
                series.value.push({
                  name: item.label,
                  data
                });
              });
            }
          }
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const numFormat = num => {
      num = num.toString().split('.');
      let arr = num[0].split('').reverse();
      let res = [];
      for (let i = 0, len = arr.length; i < len; i++) {
        if (i % 3 === 0 && i !== 0) {
          res.push(',');
        }
        res.push(arr[i]);
      }
      res.reverse();
      if (num[1]) {
        res = res.join('').concat('.' + num[1]);
      } else {
        res = res.join('');
      }
      return res;
    };
    return {
      refChart,
      xAxis,
      series,
      loading,
      update,
      tooltip,
      paramsReset,
      labelStr
    };
  }
});
</script>
<style lang="scss" scoped>
.chart-box-in {
  width: 100%;
  height: 330px !important;
  :deep(.el-loading-mask) {
    z-index: 9 !important;
  }
}
</style>
