export default {
  hotSpot: {
    allChannels: 'All Channels',
    pleaseEnterKeywords: 'Please enter keywords',
    last24Hours: 'Last 24 hours',
    today: 'Today',
    days3: 'Last 3 days',
    days7: 'Last 7 days',
    days30: 'Last 30 days',
    customize: 'Customize',
    search: 'Search',
    WeiboHot: 'Weibo',
    hotSearch: 'Hot search',
    trendingVideo: 'Trending video',
    sameCity: 'Local rank',
    rank: 'rank',
    topic: 'topic',
    onListForHours: 'on list for/h',
    popularity: 'Popularity',
    million: 'million',
    hundredMillion: 'hundred million',
    DouyinHot: 'Douyin',
    ToutiaoHot: 'Toutiao',
    BaiduHot: 'Baidu',
    KwaiHot: 'Kwai',
    XiaohongshuHot: 'Xiaohongshu',
    BilibiliHot: 'Bilibili',
    ZhihuHot: 'Zhihu',
    hotSearchPlaceholder: 'Hot search',
    listColumn: ['rank', 'topic', 'ranking changes', 'popularity'],
    detailBtn: 'view hotspot details>>',
    zbListColumn: [
      'rank',
      'Hot List',
      'topic',
      'heat value',
      'Hot trend',
      'ranking changes',
      'operation'
    ],
    detail: 'details',
    hotDetail: 'Hot spot details',
    hotDetailBtn: ['Search for it', 'original text'],
    source: 'source',
    detailTop: [
      'current ranking',
      'ranking changes',
      'latest heat',
      'duration on the leaderboard'
    ],
    hotTrends: 'Hot trends',
    hotNode: 'Hot nodes',
    hotNodeColumn: ['number', 'node', 'ranking', 'Heat value', 'time'],
    hotEnumNodeTexts: [
      'First time on the list',
      'Falling off the list',
      'Listed again'
    ],
    goback: 'Return to Hot List',
    Xigua: 'Xigua',
    Tencent: 'Tencent',
    AcFun: 'AcFun',
    Youku: 'Youku',
    Tudou: 'tudou',
    iQIYI: 'iQIYI',
    huoshan: 'Vigo Video',
    WeiboVideo: 'Sina Weibo Video',
    Weishi: 'WeSee',
    Wangyi: 'NetEase',
    pipixia: 'PipiXia',
    huawei: 'Huawei Video',
    deiwu: 'Poizon',
    weiboxVideo: 'Channels',
    Weibo: 'Weibo',
    Douyin: 'Douyin',
    Toutiao: 'Toutiao',
    Baidu: 'Baidu',
    Kwai: 'Kwai',
    Xiaohongshu: 'Xiaohongshu',
    Bilibili: 'Bilibili',
    Zhihu: 'Zhihu',

    Youtube: 'Wechat',
    Website: 'Website',
    haokan: '好看视频',
    souhu: '搜狐视频',

    day: 'day',
    hour: 'hour',
    minute: 'minute',
    abnormalInformation: 'abnormal information'
  }
};
