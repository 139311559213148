export default {
  monitorReceive: {
    helpContent1:
      '1. 最多支持设置3组关键词，同个输入框词与词之间以回车间隔，为OR关系。',
    helpContent2: '2. 不同输入框为AND关系。',
    helpContent3: '3. 允许输入汉字、英文、数字、特殊字符，长度小于100个字符。',
    postComments:
      '发文评论，是指方案命中发文的所有评论信息；只有当方案开启“信源监测”时可用。',
    schemeName: '方案名称不可为空',
    wordSpan: '关键词词距范围不可为空',
    messageSourceConfig: '账号不可为空',
    rules: {
      schemeName: [
        { required: true, message: '方案名称不可为空' }
        // { match: /^[a-zA-Z0-9_\( \)\（ \）\-\u4e00-\u9fa5]+$/, message: '只允许输入汉字，英文，数字，括号，中划线-，下划线_' },
      ],
      wordSpan: [{ required: true, message: '关键词词距范围不可为空' }],
      messageSourceConfig: [
        { required: true, message: '账号不可为空' },
        {
          validator: (val: any[], callback: (error: string) => void) => {
            !val.length && callback('error');
          },
          message: '账号不可为空'
        }
      ]
    },
    keywordTip:
      '1.可输入多个关键词，关键词之间用回车间隔，为“或”关系。, 2.地域词、主体词、事件词三个之间是“与”关系，只有同时满足这3个关键词的文章才会被监测到。例：地域词输入“北京”，主体词输入“张三”，事件词输入“收红包”，只有同时匹配这3个关键词的文章才会被监测到。',
    exculedKeywordTip:
      '1.可输入多个关键词，关键词之间用回车间隔，为“或”关系。, 2.命中排除关键词的信息，将不进入系统。',
    wordSpanTip: [
      '1.地域词、主体词、事件词在信息中出现的间距值。词距范围0~500。',
      '2.满足词间距的信息，将出现在精准信息列表中。'
    ],
    wordSpanTipNew:
      '1.词距匹配指地域词、主体词、事件词三组分别取其中任意关键词，在信息中出现的间距值，词距可选范围0-500；,2.地域词、主体词、事件词满足词间距将出现在监测信息列表中；,3.地域词、主体词、事件词任意为空则忽略该组词，关闭词距匹配将按照关键词命中规则进行监测。',
    accountTip:
      '1.可指定媒体、账号进行定向监测。, 2.未配置关键词将展示媒体/账号全部数据，配置关键词后将展示媒体/账号匹配关键词的数据。',
    accountTipGetUser:
      '1.可指定媒体、账号进行定向监测。, 2.未配置关键词将展示媒体/账号全部数据，配置关键词后将展示媒体/账号匹配关键词的数据。, 3.开启信源监测后，可针对目标信源发文的评论进行预警。',
    EditorialSolutions: '编辑方案',
    CreatedSolutions: '创建监测方案',
    ProgramName: '方案名称',
    ProgramNameholder: '请输入方案名称',
    ContentMonitoring: '内容监测',
    Geographicalwords: '地域词',
    Mainwords: '主体词',
    Eventwords: '事件词',
    errTip: '地域词、主体词、事件词不可同时为空',
    ExclusionWords: '排除词',
    Precisesettings: '词距匹配',
    Keywordspacingrange: '关键词词距范围',
    TargetedMonitoring: '定向监测',
    MonitoringCancellation: '取消',
    Monitoringpreservation: '保存',
    Preview: '预览',
    PatternOfExpression: '表达式模式',
    StandardMode: '标准模式',
    MonitorKeyWords: '监测关键词',
    MonitorKeyWordsTip: [
      `1.公式可包括"与"，"或"，"非"的关系，用"&"代表"与"，"{'|'}"代表"或"，"!"代表"非"；`,
      `2.公式中的每组关键词以"()"包含；`,
      `示例:(北京&冬奥)&(滑雪{'|'}滑冰{'|'}冰球{'|'}雪橇)!(雪车{'|'}冰壶) (中国{'|'}北京)&(科技冬奥{'|'}冰雪科技)；`
    ],
    confirm: '确认',
    reference: '引用方案',
    schemeList: '方案列表',
    copy: '复制',
    popTip: '切换到标准模式，将清空当前设置，确定切换标准模式吗？',
    informationSourceMonitoring: '信源监测',
    informationSourceMonitoringBtn: ['定向信源', '排除信源'],
    specifiedInfoSourceTip: [
      '1.可选多个信源，信源之间匹配关系为“或”',
      '2.未配置关键词将展示信源全部数据，配置关键词后将展示信源中匹配上关键词的数据'
    ],
    excludeInfoSourceTip: [
      '1.可选多个信源，信源之间匹配关系为“或”',
      '2.排除信源发布内容，不进入监测方案'
    ],
    dataInfo: '数据筛选',
    yayi: '雅意'
  },
  TargetedMonitoring: {
    all: '全部',
    placeholder: '请输入信源名称、账号或网站域名',
    search: '查询',
    specialPre: '特殊字符搜索匹配规则：目前支持',
    specialBeh: '模糊搜索',
    titles: '信源列表,已选信源',
    nodata: '暂无数据',
    noCheckdata: '未选择数据',
    loder: '加载中...',
    intheEnd: '到底了',
    contentPre: '确认清空所有',
    contentBeh: '吗？',
    filter: '筛选已选账号',
    confirm: '确定'
  },
  MonitorIndex: {
    goback: '返回',
    name: '专家建议',
    list: '信息列表',
    photo: '图表分析',
    waring: '预警设置',
    nodata: '还没有添加监测方案，赶快去创建吧',
    add: '添加方案',
    MonitoringProgram: '监测方案',
    CreatProgram: '创建方案',
    CreateCategory: '创建文件夹',
    edit: '编辑',
    modalPlaceholder: [
      '分类名称不可为空',
      '分类名称长度不可超过20个字符',
      '请输入分类名称'
    ],
    modalTitle: ['创建文件夹', '编辑文件夹', '删除文件夹', '删除方案'],
    deleteTip: [
      '删除分类将同时删除分类下所有方案，确定要删除该分类吗？',
      '确定要删除选中方案吗？'
    ],
    filterTip: '输入名称',
    treeNoDataTip: '提示：暂无'
  },
  filter: {
    ReadAll: '全部',
    ReadingStatus: '阅读状态',
    Read: '已读',
    Unread: '未读',
    pubtime: '发布时间',
    warehousetime: '入库时间',
    Emotional: '情感倾向',
    Emotionalf: '情感分析',
    mediaType: '媒体渠道',
    time1: '今天',
    time2: '近24小时',
    time3: '近7天',
    time4: '自定义',
    time5: '近3天',
    time14: '近14天',
    Emotional1: '正面',
    Emotionalf1: '正面',
    Emotional2: '中立',
    Emotionalf2: '中立',
    Emotional3: '负面',
    Emotionalf3: '负面',
    Emotionalf4: '无关',
    EmotionalfTip: '由雅意大模型提供',
    codeType: '数据类型',
    screening: '精准筛选',
    sameindex: '相似信息',
    codeType1: '原文',
    codeType2: '转发',
    codeType3: '评论',
    codeType4: '发文评论',
    screening1: '全部',
    screening2: '精准',
    sameindex1: '去重',
    sameindex2: '不去重',
    Morefilters: '更多筛选',
    search: '查询',
    Filtercriteria: '筛选条件',
    resite: '重置',
    moreSearch: '查询',
    all: '全部',
    content: '全文匹配',
    titlePick: '标题匹配',
    keyCodePlaceholder: '请输入关键词，回车键确认',
    Geographical: '地域识别',
    IP: 'IP属地',
    MediaProperties: '媒体属地',
    Contentmentions: '内容提及',
    saveFilters: '保存筛选',
    saveFiltersTip: [
      '1. 保存的筛选条件仅对当前监测方案生效',
      '2. 保存后，该方案下次打开，默认加载保存的筛选条件',
      '3. 发布时间/监测时间-自定义时间筛选条件无法保存'
    ],
    filtersTab: ['账号属性', '地域/语言', '高级筛选'],
    monitoringTime: '监测时间'
  },
  alertSettings: {
    system: '系统弹框预警',
    title: '舆情预警',
    open: '开',
    close: '关',
    titleTip:
      '根据设置规则，自动发送预警信息至预警联系人。以便更精准、及时掌握舆情信息。',
    Earlywarning: '预警接收设置',
    warningWay: '接收方式:',
    weixin: '微信公众号接收',
    newContact: '+ 新增联系人',
    oldContact: '+ 从联系人中添加',
    name: '姓名',
    namePlaceholder: '请输入姓名',
    pubtime: '创建时间',
    operation: '操作',
    editor: '编辑',
    noContact: '您还没有添加微信联系人',
    receivingTime: '接收时间:',
    everyday: '每天',
    workday: '工作日',
    EWF: '预警频次:',
    deweighting: '相似信息去重:',
    EWC: '预警内容设置',
    EWkeycode: '预警词',
    EWplaceholder: '请输入预警词，用回车间隔',
    add: '添加',
    delete: '删除',
    ExclusionWords: '排除词',
    ExWplaceholder: '请输入排除词，用回车间隔',
    tend: '情感倾向:',
    tendf: '情感分析:',
    mediatype: '媒体渠道:',
    Precisescreening: '精准筛选:',
    target: '命中位置:',
    inexType: '数据类型:',
    cancel: '取消',
    save: '保存',
    addUser: '添加新用户',
    ScanCode: '扫码新增：',
    refresh: '刷新',
    ScanCodeTip: '扫描二维码关注微信公众号，立享预警服务',
    SacnCodeSuccess: '扫码成功',
    formName: '姓名:',
    formNameRequire: '请先输入姓名',
    formNamePre: '（注：已添加的联系人可在',
    formNameMid: '“个人中心-我的联系人”',
    formNameEnd: '中查看或修改）',
    myContact: '我的联系人',
    wxContactPre: '微信联系人',
    wxContactMid: '共',
    inputContact: '输入联系人',
    wxContactEnd: '位成员',
    Realtime: '实时预警',
    minutes5: '5分钟',
    minutes10: '10分钟',
    minutes30: '30分钟',
    minutes60: '1小时',
    minutes120: '2小时',
    minutes240: '4小时',
    minutes480: '8小时',
    minutes720: '12小时',
    minutes1440: '1天',
    pleaseInputName: '请输入姓名',
    informationClassification: '信息分类:',
    analogInformation: '相似信息:',
    weChatID: '微信号'
  },
  messageTip: {
    isRead: '已读',
    creatError: '创建失败',
    creatSuccess: '创建成功',
    editorSuccess: '修改成功',
    editorError: '修改失败',
    keycodeLimit: '关键词总数不得超过',
    keycodeLimitEnd: '个',
    Nonrepeatable: '关键词不可重复',
    codeLengthLimit: '单个关键词字符长度最大为',
    alreadyexists: '该联系人已存在',
    noSame: '不可重复添加联系人',
    setContact: '请设置微信联系人后保存',
    choose: '请选择方案后操作',
    addWXcontact: '请添加微信联系人',
    Alread: '已存在预警设置信息，不可新增',
    KeycodeAndExclusionWords: '预警词与排除词不可重复',
    keycodeLength: '关键词字符长度超过100',
    startTime: '开始时间必须小于结束时间',
    saveSuccess: '保存成功',
    Noname: '姓名不能为空',
    ScanTheCode: '请先扫码关注',
    InputName: '请输入姓名',
    keyCodeRepeat: '关键词不可重复',
    MaxLength: '单个关键词字符长度最大为'
  },
  yayiModel: {
    poweredAnalysis: '智能分析',
    analysisContents: '分析内容',
    example: '效果示例',
    analysisContentsItem: [
      '文章概要',
      '情感分析',
      '主体识别',
      '核心观点',
      '处置建议',
      '内容识别'
    ],
    analysisContentsTab: ['分析主体', '知识背景', '情感倾向', '内容识别'],
    analysisContentsAdd: '新增',
    analysisContentsColumn: [
      '序号',
      '主体名称',
      '知识背景',
      '情感负面类型',
      '内容识别标签',
      '操作'
    ],
    deRowTip: [
      [
        '说明：非必填，如填写大模型将基于配置主体立场的视角进行分析，最多三项',
        '示例：政府管理、中关村街道'
      ],
      [
        '说明：非必填，如部分内容无法关联分析时，可补充您关注领域的相关知识，最多三项',
        '示例：中关村、燕园、清华园街道均属于海淀区'
      ],
      [
        '说明：非必填，请列举需要大模型重点关注的负面类型，最多十个负面类型',
        '示例：自然灾害、环境污染、安全事故'
      ],
      [
        '说明：启用后必填，可填写1-5项，请列举需要大模型重点关注的内容识别标签',
        '示例：旅游投诉、民生投诉、消费者维权'
      ]
    ]
  },
  yayiModelIndex: [
    '中国广电山东公司与山东文旅集团签署战略合作协议',
    '文章中明确提到了中国广电山东公司与山东文旅集团签署战略合作协议',
    '相关',
    '中国广电山东公司、山东文旅集团',
    '双方合作有着坚实的合作基础和广阔的发展前景，将发挥各自在资源、资本、渠道、技术等方面的优势，共同推动产业合作，在助力数字强省建设、促进文旅多元融合等方面作出新的更大贡献。',
    '中国广电山东公司愿与文旅集团在打造文旅产业项目、服务乡村振兴战略等方面深入合作，以“数智化”赋能山东省文旅产业高质量发展。',
    '双方将在云资源建设、项目运营等方面建立深入的合作机制，共同拓展政务信息化业务，深化智慧酒店、智慧景区、智慧园区等业务合作。',
    '中国广电分公司、子公司',
    '进一步加强与山东文旅集团的合作，共同推动文旅产业发展，加大云资源建设和政务信息化业务拓展，提升服务水平和质量。',
    '与主体立场 ',
    '文章主体：',
    '无',
    '中立',
    '正面',
    '负面',
    '无关'
  ]
};
