<template>
  <w-form-item
    :label="label"
    class="w-form-item-cascader"
    style="position: relative"
  >
    <w-cascader
      :options="cpOption"
      v-model="value"
      :multiple="multiple"
      :placeholder="placeholder"
      :max-tag-count="maxTagCount"
      :filterable="false"
      :check-strictly="checkStrictly"
      :disabled="disabled"
      :clearable="clearable && multiple"
      @change="emitChange"
      expand-trigger="click"
    />
    <span
      class="suffix-button"
      v-if="suffix?.type === 'button'"
      @click="suffix?.clickFn"
      >{{ suffix?.label }}</span
    >
  </w-form-item>
</template>
<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { deepClone } from '/@/utils/other';
import { Message } from 'winbox-ui-next';
interface suffix {
  type: string;
  clickFn: () => {};
}
export default defineComponent({
  name: 'ConfigCascader',
  props: {
    label: {
      type: String
    },
    modalValue: {
      type: [String, Number, Array],
      required: true
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    option: {
      type: Object as PropType<{ label: string; value: string }[]>,
      default: []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    checkStrictly: {
      type: Boolean,
      default: false
    },
    maxTagCount: {
      type: Number,
      default: 2
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    suffix: {
      type: Object as PropType<suffix>
    },
    max: {
      type: Number
    },
    reachMaxWarningMessage: {
      type: String,
      default: '已选内容数量达到最大限制'
    }
  },
  setup(props, { emit }) {
    const value = ref(props.modalValue);
    const cpOption = ref(deepClone(props.option));
    const updateOption = (list: any[]) => {
      list.forEach(item => {
        item.disabled = false;
        if (
          props.multiple &&
          props.max &&
          Array.isArray(value.value) &&
          value.value.length >= props.max &&
          !value.value.includes(item?.value)
        ) {
          item.disabled = true;
        }
        if (item.children) {
          updateOption(item.children);
        }
      });
    };
    // updateOption(cpOption.value)
    const validateMax = (val: any[]) => {
      if (props.multiple && val.length > props.max!) {
        (value.value as any[]).splice(val.length - 1, 1);
        try {
          Message.clear();
        } catch (error) {}
        Message.warning(props.reachMaxWarningMessage);
      }
    };
    const emitChange = val => {
      emit('update:modalValue', value.value);
      emit('change', value.value);
      // updateOption(cpOption.value)
      validateMax(val);
    };
    return { value, emitChange, cpOption };
  }
});
</script>
<style scoped>
.suffix-button {
  white-space: nowrap;
  color: var(--el-color-primary);
  cursor: pointer;
  margin-left: 10px;
  user-select: none;
}
</style>
