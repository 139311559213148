import { RouteRecordRaw } from 'vue-router';

/**
 * 路由meta对象参数说明
 * meta: {
 *      title:          菜单栏及 tagsView 栏、菜单搜索名称（国际化）
 *      isLink：        是否超链接菜单，开启外链条件，`1、isLink: 链接地址不为空`
 *      isHide：        是否隐藏此路由
 *      isKeepAlive：   是否缓存组件状态
 *      isAffix：       是否固定在 tagsView 栏上
 *      isIframe：      是否内嵌窗口，开启条件，`1、isIframe:true 2、isLink：链接地址不为空`
 *      roles：         当前路由权限标识，取角色管理。控制路由显示、隐藏。超级管理员：admin 普通角色：common  基础版： base-version  标准版： standard-version  高级版：advanced-version  旗舰版： flagship-version
 *      icon：          菜单、tagsView 图标，阿里：加 `iconfont xxx`，fontawesome：加 `fa xxx`
 * }
 */

/**
 * 定义动态路由
 * 前端添加路由，请在顶级节点的 `children 数组` 里添加
 * @description 未开启 isRequestRoutes 为 true 时使用（前端控制路由），开启时第一个顶级 children 的路由将被替换成接口请求回来的路由数据
 * @description 各字段请查看 `/@/views/system/menu/component/addMenu.vue 下的 ruleForm`
 * @returns 返回路由菜单数据
 */
export const dynamicRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '/',
    component: () => import('/@/layout/index.vue'),
    redirect: '/hotspot',
    meta: {
      isKeepAlive: true
    },
    children: [
      // {
      //     path: '/home',
      //     name: 'home',
      //     component: () => import('/@/views/home/index.vue'),
      //     meta: {
      //         title: '首页',
      //         isLink: '',
      //         isHide: false,
      //         isKeepAlive: true,
      //         isAffix: true,
      //         isIframe: false,
      //         roles: ['admin', 'common'],
      //         icon: 'fenxi',
      //     },
      // },
      {
        // 搜索页作为首页
        path: '/home',
        name: 'home',
        component: () => import('/@/views/search/index.vue'),
        meta: {
          title: 'message.router.home', //首页
          isHide: true,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          // roles: ['admin', 'common'],
          icon: 'sousuo',
          isMainPaddingHide: true
        }
      },
      {
        path: '/overview',
        name: 'overview',
        component: () => import('/@/views/overview/index.vue'),
        meta: {
          title: '总览',
          isLink: '',
          isHide: false,
          isKeepAlive: true,
          isAffix: true,
          isIframe: false,
          roles: ['menu-overview'],
          icon: 'overview'
        }
      },
      {
        path: '/caseLibrary',
        name: 'caseLibrary',
        component: () => import('/@/views/caseLibrary/index.vue'),
        meta: {
          title: '案例库',
          isLink: '',
          isHide: false,
          isKeepAlive: true,
          isAffix: true,
          isIframe: false,
          roles: ['menu-caseLibrary'],
          icon: 'caseLibrary'
        }
      },
      {
        path: '/caseLibrary/detail',
        name: 'caseLibraryDetail',
        component: () => import('/@/views/caseLibrary/detail.vue'),
        meta: {
          title: '案例库详情',
          isLink: '',
          isHide: true,
          isKeepAlive: true,
          isAffix: true,
          isIframe: false,
          activeMenu: '/caseLibrary',
          roles: ['menu-caseLibrary'],
          icon: 'caseLibrary'
        }
      },
      {
        path: '/caseLibrary/add',
        name: 'caseLibraryAdd',
        component: () => import('/@/views/caseLibrary/add.vue'),
        meta: {
          title: '案例库新增/编辑',
          isLink: '',
          isHide: true,
          isKeepAlive: true,
          isAffix: true,
          isIframe: false,
          activeMenu: '/caseLibrary',
          roles: ['menu-caseLibrary'],
          icon: 'caseLibrary'
        }
      },
      {
        path: '/decesionLibrary/index',
        name: 'decesionLibrary',
        component: () => import('/@/views/decesionLibrary/index.vue'),
        meta: {
          title: '策略库',
          isLink: '',
          isHide: false,
          isKeepAlive: true,
          isAffix: true,
          isIframe: false,
          roles: ['menu-decesionLibrary-edit', 'menu-decesionLibrary-view'],
          icon: 'decesionLibrary'
        }
      },
      // {
      //   path: '/decesionLibrary/edit',
      //   name: 'decesionLibraryEdit',
      //   component: () => import('/@/views/decesionLibrary/edit.vue'),
      //   meta: {
      //     title: '决策库',
      //     isLink: '',
      //     isHide: false,
      //     isKeepAlive: true,
      //     isAffix: true,
      //     isIframe: false,
      //     roles: ['menu-decesionLibrary-edit'],
      //     icon: 'h-fenxi'
      //   }
      // },
      // {
      //   path: '/decesionLibrary/view',
      //   name: 'decesionLibraryView',
      //   component: () => import('/@/views/decesionLibrary/view.vue'),
      //   meta: {
      //     title: '决策库',
      //     isLink: '',
      //     isHide: false,
      //     isKeepAlive: true,
      //     isAffix: true,
      //     isIframe: false,
      //     roles: ['menu-decesionLibrary-view'],
      //     icon: 'h-fenxi'
      //   }
      // },
      {
        // 热点
        path: '/hotspot',
        name: 'hotspot',
        component: () => import('/@/views/search/hotspot.vue'),
        meta: {
          title: 'message.router.hotspot', // 热榜
          isHide: false,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          // roles: ['admin', 'common', 'menu-hotspot'],
          icon: 'h-rebang',
          isMainPaddingHide: true,
          isLink: '/#/hotspot'
        }
      },
      {
        // 内容校对
        path: '/proofreading',
        name: 'proofreading',
        component: () => import('/@/views/review/proofreading.vue'),
        meta: {
          title: 'message.router.proofreading', // 内容校对,
          isHide: true,
          isKeepAlive: false,
          isAffix: false,
          isIframe: false,
          // roles: ['admin', 'common'],
          icon: '',
          isMainPaddingHide: true
        }
      },
      {
        // 内容审查
        path: '/contentReview',
        name: 'contentReview',
        component: () => import('/@/views/review/contentReview.vue'),
        meta: {
          title: 'message.router.contentReview', // 内容审查
          isHide: true,
          isKeepAlive: false,
          isAffix: false,
          isIframe: false,
          // roles: ['admin', 'common'],
          icon: '',
          isMainPaddingHide: true
        }
      },
      {
        // 搜索结果页作为搜索主页
        path: '/search',
        name: 'search',
        redirect: '/search/result',
        component: () => import('/@/views/search/index.vue'),
        meta: {
          title: 'message.router.search', // 搜索
          isHide: false,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          roles: [
            'admin',
            'common',
            'menu-search-result',
            'base-version',
            'standard-version',
            'advanced-version',
            'flagship-version'
          ],
          icon: 'h-sousuo',
          isMainPaddingHide: true,
          isLink: '/#/search'
        },
        children: [
          {
            path: '/search/result',
            name: 'searchResult',
            component: () => import('/@/views/search/result/index.vue'),
            meta: {
              title: 'message.router.searchResult', // 搜索结果
              isLink: '',
              isHide: true,
              isKeepAlive: false,
              isAffix: false,
              isIframe: false,
              roles: [
                'admin',
                'common',
                'menu-search-result',
                'base-version',
                'standard-version',
                'advanced-version',
                'flagship-version'
              ],
              icon: 'iconfont icon-caidan',
              isMainPaddingHide: true,
              activeMenu: '/search'
            }
          }
        ]
      },
      {
        path: '/collect',
        name: 'collect',
        component: () => import('/@/views/search/collect/index.vue'),
        meta: {
          title: 'message.router.collect', // 收藏
          isHide: true,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false
          // roles: ['admin', 'common'],
          // icon: 'sousuo',
        }
      },

      {
        path: '/analysis',
        name: 'analysis',
        component: () => import('/@/views/analysis/index.vue'),
        meta: {
          title: 'message.router.analysis', // 分析
          isHide: false,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          roles: [
            'admin',
            'common',
            'menu-analysis',
            'standard-version',
            'advanced-version',
            'flagship-version'
          ],
          icon: 'h-fenxi',
          isMainPaddingHide: true,
          isLink: '/#/analysis'
        },
        children: [
          {
            path: '/analysis/:projectId',
            name: 'analysisEditor',
            component: () =>
              import('/@/views/analysis/analysis-editor/index.vue'),
            meta: {
              title: 'message.router.analysisSettings', // 分析设置
              isLink: '',
              isHide: true,
              isKeepAlive: true,
              isAffix: false,
              isIframe: true,
              roles: [
                'admin',
                'common',
                'menu-analysis',
                'standard-version',
                'advanced-version',
                'flagship-version'
              ],
              icon: 'iconfont icon-caidan',
              isMainPaddingHide: true,
              activeMenu: '/analysis'
            }
          }
        ]
      },
      {
        path: '/bigscreen',
        name: 'bigscreen',
        component: () => import('/@/views/analysis/bigscreenIndex.vue'),
        meta: {
          title: 'message.router.dashboard', // 大屏
          isHide: false,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          roles: ['common', 'menu-bigscreen', 'flagship-version'],
          icon: 'h-daping',
          isMainPaddingHide: true,
          iconSize: 20,
          isLink: '/#/bigscreen'
        },
        children: [
          {
            path: '/bigscreen/:projectId',
            name: 'bigscreenEditor',
            component: () =>
              import('/@/views/analysis/analysis-editor/index.vue'),
            meta: {
              title: 'message.router.dashboardSettings', // 大屏设置
              isLink: '',
              isHide: true,
              isKeepAlive: true,
              isAffix: false,
              isIframe: true,
              roles: ['admin', 'common', 'menu-bigscreen', 'flagship-version'],
              isMainPaddingHide: true,
              activeMenu: '/bigscreen',
              hideMenu: true,
              bigscreenMode: true
            }
          }
        ]
      },
      {
        path: '/other',
        name: 'other',
        component: () => import('/@/layout/routerView/parent.vue'),
        redirect: '/other/icon',
        meta: {
          title: 'message.router.other', // 其他
          isLink: '',
          isHide: false,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          roles: ['admin'],
          icon: 'iconfont icon-xitongshezhi'
        },
        children: [
          {
            path: '/other/icon',
            name: 'otherIcon',
            component: () => import('/@/views/other/icon/index.vue'),
            meta: {
              title: 'message.router.otherIcon', // 图标
              isLink: '',
              isHide: false,
              isKeepAlive: true,
              isAffix: false,
              isIframe: false,
              roles: ['common'],
              icon: 'iconfont icon-caidan'
            }
          },
          {
            path: '/other/i18n',
            name: 'otherI18n',
            component: () => import('/@/views/other/i18n/index.vue'),
            meta: {
              title: 'message.router.otherI18n', // 国际化
              isLink: '',
              isHide: false,
              isKeepAlive: true,
              isAffix: false,
              isIframe: false,
              roles: ['admin'],
              icon: 'iconfont icon-caidan'
            }
          },
          {
            path: '/other/style',
            name: 'otherStyle',
            component: () => import('/@/views/other/style/index.vue'),
            meta: {
              title: 'message.router.otherStyle', // 全局样式
              isLink: '',
              isHide: false,
              isKeepAlive: true,
              isAffix: false,
              isIframe: false,
              roles: ['admin'],
              icon: 'iconfont icon-caidan'
            }
          }
        ]
      },
      {
        path: '/contacts',
        name: 'contacts',
        component: () => import('/@/views/video/personalCenter/contacts.vue'),
        meta: {
          title: 'message.router.contacts', // 个人中心
          isHide: true,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          // roles: ['admin', 'common', 'chunfeng'],
          icon: 'dianzan'
        },
        children: []
      },
      {
        path: '/task',
        name: 'task',
        component: () => import('/@/views/task/index.vue'),
        meta: {
          title: 'message.router.task', // 任务中心
          isHide: true,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          // roles: ['admin', 'common', 'chunfeng'],
          icon: 'dianzan'
        },
        children: []
      },
      {
        path: '/monitor',
        name: 'monitor',
        component: () => import('/@/views/monitor/index.vue'),
        meta: {
          title: 'message.router.monitor', // 监测
          isHide: false,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          roles: [
            'admin',
            'common',
            'chunfeng',
            'menu-monitor',
            'standard-version',
            'advanced-version',
            'flagship-version'
          ],
          icon: 'h-jiance',
          isLink: '/#/monitor'
        },
        children: []
      },
      {
        path: '/warning',
        name: 'warning',
        component: () => import('/@/views/warning/index.vue'),
        meta: {
          title: 'message.router.warning', // 预警
          isHide: false,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          roles: [
            'admin',
            'common',
            'chunfeng',
            'menu-warning',
            'base-version',
            'standard-version',
            'advanced-version',
            'flagship-version'
          ],
          icon: 'h-yujing',
          isLink: '/#/warning'
        },
        children: []
      },
      {
        // 涉藏
        path: '/qinghai',
        name: 'qinghai',
        component: () => import('/@/views/qinghai/index.vue'),
        meta: {
          title: '涉藏', // 涉藏
          isHide: false,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          roles: ['analysis-qinghai'],
          icon: 'h-fenxi',
          isMainPaddingHide: true,
          isLink: '/#/qinghai'
        }
      },
      {
        // 涉青
        path: '/sheqing',
        name: 'sheqing',
        component: () => import('/@/views/sheqing/index.vue'),
        meta: {
          title: '涉青',
          isHide: false,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          roles: ['analysis-sheqing'],
          icon: 'h-fenxi',
          isMainPaddingHide: true,
          isLink: '/#/sheqing'
        }
      },
      // {
      //   // 账号管理
      //   path: '/account',
      //   name: 'account',
      //   component: () =>
      //     import('/@/views/accountAnalysis/keyAccountManagement/index.vue'),
      //   meta: {
      //     title: '账号管理分析',
      //     isHide: false,
      //     isKeepAlive: true,
      //     isAffix: false,
      //     isIframe: false,
      //     roles: ['analysis-account'],
      //     icon: 'h-account-analysis',
      //     isMainPaddingHide: true,
      //     isLink: '/#/account'
      //   }
      // },
      {
        path: '/report',
        name: 'report',
        component: () => import('/@/views/report/index.vue'),
        meta: {
          title: 'message.router.bulletin',
          isHide: false,
          isKeepAlive: false,
          isAffix: false,
          isIframe: false,
          roles: ['menu-report'],
          icon: 'h-baogao'
        },
        children: [
          {
            path: '/report/template',
            name: 'template',
            component: () => import('/@/views/report/template/index.vue'),
            meta: {
              title: '模板',
              isHide: true,
              isKeepAlive: false,
              isAffix: false,
              isIframe: false,
              roles: ['menu-report'],
              activeMenu: '/report'
            }
          }
        ]
      },
      {
        path: '/formation',
        name: 'formation',
        component: () => import('/@/views/video/formation/index.vue'),
        meta: {
          title: 'message.router.formation', // 预览
          isHide: true,
          isKeepAlive: false,
          isAffix: false,
          isIframe: false,
          roles: ['menu-report'],
          activeMenu: '/report'
        }
      },
      {
        path: '/technology-radar',
        name: 'technologyReport',
        redirect: '/technology-radar/data-info',
        meta: {
          title: 'message.router.technologyRadar.technologyRadar', // 科技雷达
          isHide: false,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          roles: ['technology-radar'],
          icon: 'h-technology-radar'
        },
        children: [
          {
            path: '/technology-radar/data-info',
            name: 'technologyRadarDataInfo',
            component: () =>
              import('/@/views/technologyRadar/dataInfoPage/index.vue'),
            meta: {
              title: 'message.router.technologyRadar.dataInfo', // 数据筛选
              isHide: false,
              isKeepAlive: true,
              isAffix: false,
              isIframe: false,
              activeMenu: '/technology-radar'
            }
          },
          {
            path: '/technology-radar/report',
            name: 'technologyRadarReport',
            component: () =>
              import('/@/views/technologyRadar/reportPage/index.vue'),
            meta: {
              title: 'message.router.technologyRadar.report', // 报告管理
              isHide: false,
              isKeepAlive: true,
              isAffix: false,
              isIframe: false,
              activeMenu: '/technology-radar'
            }
          },
          {
            path: '/technology-radar/preview/:reportId',
            name: 'technologyRadarReportPreview',
            component: () =>
              import('/@/views/technologyRadar/reportPage/preview.vue'),
            meta: {
              title: 'message.router.technologyRadar.preview', // 报告预览
              isHide: true,
              isKeepAlive: true,
              isAffix: false,
              isIframe: false,
              activeMenu: '/technology-radar'
            }
          }
          // {
          // 	path: '/technology-radar/template',
          // 	name: 'technologyRadarTemplate',
          // 	component: () => import('/@/views/technologyRadar/templatePage/index.vue'),
          // 	meta: {
          // 		title: '模板管理',
          // 		isHide: false,
          // 		isKeepAlive: true,
          // 		isAffix: false,
          // 		isIframe: false,
          // 		roles: ['admin', 'common'],
          // 	},
          // },
        ]
      },
      {
        path: '/search-report',
        name: 'searchReport',
        component: () => import('/@/views/searchReport/index.vue'),
        meta: {
          title: 'message.router.report', // 报告
          isHide: false,
          isKeepAlive: true,
          isAffix: false,
          isIframe: false,
          roles: [
            'llmReport',
            'advanced-version',
            'flagship-version',
            'common'
          ],
          icon: 'h-baogao',
          rightIcon: 'beta',
          isLink: '/#/search-report'
        },
        children: [
          {
            path: '/search-report/preview',
            name: 'searchReportPreview',
            component: () =>
              import('/@/views/searchReport/reportList/preview/index.vue'),
            meta: {
              title: 'message.router.formation', // 报告预览
              isHide: true,
              isKeepAlive: false,
              isAffix: false,
              isIframe: false,
              roles: [
                'llmReport',
                'advanced-version',
                'flagship-version',
                'common'
              ]
            }
          }
        ]
      },
      {
        path: '/yayi-model',
        name: 'yayiModel',
        component: () => import('/@/views/yayiModel/index.vue'),
        meta: {
          title: 'UIE',
          isHide: true,
          isKeepAlive: false,
          isAffix: false,
          isIframe: false,
          roles: ['yayi-model'],
          icon: 'h-baogao',
          rightIcon: 'beta'
        },
        children: [
          {
            path: '/yayi-model/detail/:modelId',
            name: 'yayiModelDetail',
            component: () => import('/@/views/yayiModel/detail.vue'),
            meta: {
              title: 'UIE详情',
              isHide: true,
              isKeepAlive: false,
              isAffix: false,
              isIframe: false,
              roles: ['yayi-model']
            }
          }
        ]
      }
    ]
  }
];

/**
 * 定义404、401界面
 * @link 参考：https://next.router.vuejs.org/zh/guide/essentials/history-mode.html#netlify
 */
export const notFoundAndNoPower = [
  {
    path: '/:path(.*)*',
    name: 'notFound',
    component: () => import('/@/views/error/404.vue'),
    meta: {
      title: 'message.staticRoutes.notFound',
      isHide: true
    }
  },
  {
    path: '/401',
    name: 'noPower',
    component: () => import('/@/views/error/401.vue'),
    meta: {
      title: 'message.staticRoutes.noPower',
      isHide: true
    }
  }
];

/**
 * 定义静态路由（默认路由）
 * 此路由不要动，前端添加路由的话，请在 `dynamicRoutes 数组` 中添加
 * @description 前端控制直接改 dynamicRoutes 中的路由，后端控制不需要修改，请求接口路由数据时，会覆盖 dynamicRoutes 第一个顶级 children 的内容（全屏，不包含 layout 中的路由出口）
 * @returns 返回路由菜单数据
 */
export const staticRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('/@/views/login/index.vue'),
    meta: {
      title: '登录' // 登录
    }
  },
  {
    path: '/analysis-share',
    name: 'analysisShare',
    component: () => import('/@/views/analysis/analysisShare/index.vue'),
    meta: {
      title: 'message.router.analysisShare' // 分析分享
    }
  },
  {
    path: '/bigscreen-run/:id',
    name: 'bigscreenRun',
    component: () =>
      import(
        '/@/views/analysis/analysis-editor/canvas-panel/widget/bigscreenLayout/run.vue'
      ),
    meta: {
      title: 'message.router.bigscreenRun' // 大屏运行
    }
  },
  {
    path: '/search-report/preview-online',
    name: 'searchReportPreviewOnline',
    component: () =>
      import('/@/views/searchReport/reportList/preview/indexOnline.vue'),
    meta: {
      title: 'message.router.formation' // 报告预览
    }
  },
  {
    path: '/bigscreen-share',
    name: 'bigscreenShare',
    component: () =>
      import(
        '/@/views/analysis/analysis-editor/canvas-panel/widget/bigscreenLayout/share.vue'
      ),
    meta: {
      title: 'message.router.bigscreenShare' // 大屏分享
    }
  }
];
