<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(series.length && series.length) && !loading"
    style="overflow-y: auto"
  >
    <StripChart
      ref="refChart"
      v-if="!loading && series.length"
      :tooltip="{ confine: true }"
      :series="series"
      :theme="theme"
      v-memo="[]"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed, defineProps, defineExpose } from 'vue';
import { getChartGradientColor } from '@wenhai/visual-components/utils';
import useAnalysisApi from '/@/api/analysis';
import { useI18n } from 'vue-i18n';
const colors = [
  ['#422D4A', '#FF3831'],
  ['#473B4D', '#FFAF37'],
  ['#193774', '#37F1FF'],
  ['#0F274B', '#1977E4']
];
const props = defineProps({
  params: {
    type: Object,
    required: true
  },
  theme: String
});
const series = ref<any[]>([]);
const loading = ref(false);
const paramsObj = computed(() => {
  return props.params;
});
const { influenceRank } = useAnalysisApi();
const { t } = useI18n();
const getData = async () => {
  if (!paramsObj.value.schemeId) return;
  loading.value = true;
  series.value.splice(0);
  try {
    const res = await influenceRank(paramsObj.value);
    if (res.code == 200) {
      const resData = res.data?.infoList || [];
      series.value.push({
        name: t('message.analysisComponents.informationOverviewComponents[2]'), // 影响力指数榜单
        data: resData.map((item, i) => {
          return {
            name: item.schemeName,
            value: item.influenceNum,
            color:
              props.theme == 'bigscreen-theme-2'
                ? getChartGradientColor(
                    'linear',
                    i <= 2 ? colors[i] : colors[colors.length - 1],
                    { x: 0, y: 0, x2: 1, y2: 0 }
                  )
                : undefined
          };
        })
      });
    }
    loading.value = false;
  } catch (error) {
    loading.value = false;
  }
};
onMounted(() => {
  getData();
});
const update = () => {
  getData();
};

defineExpose({
  update
});
</script>

<style lang="scss">
.influence-index-list {
  overflow-y: auto;

  .column {
    &:last-child {
      flex: 0 0 30% !important;
    }

    &.item-value {
      display: flex;
      align-items: center;

      .bar-bg {
        flex: 0 0 calc(100% - 48px);
        height: 15px;
        background-color: #f2f2f2;
      }

      .bar-active {
        height: 100%;
        background-color: #0a62fe;
      }

      .bar-value {
        padding-left: 8px;
      }
    }
  }
}
</style>
