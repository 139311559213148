<script lang="ts" setup>
import { ShallowRef, PropType, watchEffect, shallowRef, watch } from 'vue';
import type { TooltipComponentOption } from 'echarts';
import type { ECOption } from '../utils';
import {
  buildLegend,
  buildGridWidthLegend,
  buildGrid,
  buildTooltip
} from '../utils';
import BaseChart from '../BaseChart/BaseChart.vue';
import useChartClick from '../hooks/useChartClick';
interface Emits {
  (e: 'chart:click', params: any): void;
}
const props = defineProps({
  series: {
    type: Object as PropType<any>,
    required: true
  },
  indicator: {
    type: Object as PropType<any>,
    required: true
  },
  shape: {
    type: String
  },
  symbolicon: {
    type: String
  },
  tooltip: [Object, Boolean] as PropType<TooltipComponentOption | boolean>,
  legend: Boolean as PropType<boolean>,
  theme: String
});
const option: ShallowRef<ECOption> = shallowRef({});
const colors = [
  '#1C6EFD',
  '#7ABBFF',
  '#FF9030',
  '#FFC797',
  '#6093FF',
  '#A7C3FF',
  '#3AC4D4',
  '#7EDAE5',
  '#FE7770',
  '#FFA29E',
  '#00CB9B',
  '#73DFC6',
  '#B45BF3',
  '#D3A9F1',
  '#00AAF8',
  '#7FD4FB',
  '#FFAC30',
  '#FFCF88',
  '#FF70A9',
  '#FFAECE',
  '#6E70FF',
  '#B0B1FF',
  '#FF8F66',
  '#FFB9A1',
  '#2CD36A',
  '#75E29E',
  '#3B6FFF',
  '#91AEFF'
];

watchEffect(() => {
  const propSeries = props.series;
  const indicator = props.indicator.map(item => {
    return {
      ...item,
      color:
        props.theme == 'bigscreen-theme-2' ? 'rgba(216,240,255,0.6)' : undefined
    };
  });
  option.value = {
    color: colors,
    radar: {
      shape: props?.shape || '',
      indicator,
      radius: '65%',
      splitNumber: 4,
      splitArea: {
        areaStyle: {
          color:
            props.theme == 'bigscreen-theme-2'
              ? [
                  'rgba(212, 232, 255, .5)',
                  'rgba(212, 232, 255, .4)',
                  'rgba(212, 232, 255, .3)',
                  'rgba(212, 232, 255, .2)'
                ]
              : [
                  'rgba(212, 232, 255, 1)',
                  'rgba(212, 232, 255, .8)',
                  'rgba(212, 232, 255, .6)',
                  'rgba(212, 232, 255, .4)'
                ]
        }
      },
      splitLine: {
        show: props.theme == 'bigscreen-theme-2' ? false : true,
        lineStyle: {
          color: '#C0D6FF'
        }
      },
      axisLine: {
        lineStyle: {
          width: props.theme == 'bigscreen-theme-2' ? 2 : 0,
          color: 'rgba(212, 232, 255, .1)'
        },
        symbol: props.symbolicon
          ? ['none', 'image://' + props.symbolicon]
          : 'none',
        symbolSize: [16, 16],
        symbolOffset: [0, 8]
      },
      axisName: {
        color: '#262626',
        lineHeight: 20
      },
      nameGap: 22
    },
    series: [
      {
        name: '',
        type: 'radar',
        symbol: 'circle',
        symbolSize: props.theme == 'bigscreen-theme-2' ? 0 : 8,
        data: propSeries,
        areaStyle:
          props.theme == 'bigscreen-theme-2'
            ? {
                opacity: 0.1
              }
            : undefined
      }
    ]
  };

  if (props.legend) {
    option.value.legend = buildLegend({
      left: 0,
      top: 0,
      itemWidth: 8,
      itemHeight: 8
    });
    option.value.grid = buildGridWidthLegend({ top: 10 });
  } else {
    option.value.grid = buildGrid();
  }
  option.value.tooltip = buildTooltip(props.tooltip) as any;
});
const emit = defineEmits<Emits>();
const chartRef = useChartClick(emit);
</script>
<template>
  <base-chart ref="chartRef" :option="option" class="wh-chart--radar" />
</template>
