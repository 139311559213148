export default {
  analysisList: {
    createAnalysis: '创建分析',
    createAnalysisModal: {
      modalTitle: ['创建分析', '编辑分析', '重命名', '可视化大屏'],
      formNameLabel: ['分析名称', '大屏名称'],
      dataSource: '数据源',
      placeholder: ['请输入名称', '请选择数据源', '请选择模板'],
      templateSelection: '模板选择',
      templateType: ['推荐模板', '我的模板'],
      modalBtn: ['取消', '确认'],
      notemplate: '暂无可用模板'
    },
    countTip: ['您已创建', ' 个分析专题', ' 个分析专题', '/总计享有', '个'],
    sortType: ['最近编辑', '最新创建'],
    templateAll: '所有模板',
    searchPlaceholder: ['模板类型', '输入筛选内容'],
    listDetail: ['数据总量', '媒体总量', '峰值传播量', '最后编辑', '创建时间'],
    unit: ['条', '个', '小时'],
    listBtn: ['编辑', '复制', '删除', '运行', '复制'],
    deletedTip: '分析专题数据源已删除',
    copyTheme: '复制分析专题',
    deleteAnalysisTheme: '删除分析专题',
    note: '提示',
    deleteTip: ['数据源已删除，是否删除该主题？', '你确定要删除分析专题'],
    createSuccTip: ['创建成功', '稍后查看', '去查看']
  },
  bigscreenList: {
    create: '立即创建',
    countTip: ['您已创建', ' 个大屏', ' 个大屏', '/总计享有', '个'],
    deletedTip: '大屏数据源已删除',
    copybigscreen: '复制大屏',
    deletebigscreen: '删除大屏',
    deleteTip: '你确定要删除大屏'
  },
  analysisDetails: {
    actionItem: [
      '编辑',
      '保存',
      '另存为',
      '保存模板',
      '取消',
      '分享',
      '生成报告',
      '运行',
      '自动刷新',
      '重命名',
      '删除'
    ],
    timeRange: '时间范围',
    timeRangeItem: [
      '今天',
      '近24小时',
      '近7天',
      '近30天',
      '自定义',
      '近3天',
      '近3个月'
    ],
    modifyTime: '修改时间',
    modifyTimeTip: '是否更改全部组件？',
    changeStyleTip:
      '切换页面风格后需手动调整组件布局。请确认是否调整页面风格？',
    saveRulesTip: ['分析名称不可为空', '名称长度不可超过100个字符'],
    savePlaceholder: ['请输入', '模板', '分析', '名称'],
    contentStructure: '内容结构',
    addSection: '新增栏目',
    modalTitleContentStructure: ['添加栏目', '重命名', '删除栏目'],
    modalTitleRules: [
      '栏目名称不可为空',
      '栏目名称长度不可超过20个字符',
      '请输入栏目名称'
    ],
    deleteSection: [
      '确定删除该栏目标题？',
      '删除标题会同时删除栏目下全部组件。'
    ],
    actionBtn: ['确定', '重置', '编辑'],
    componentsLibrary: '组件库',
    canvasSettings: '画布设置',
    configGroupLabel: ['分析数据', '页面设置', '组件样式', '数据筛选'],
    single: '单选',
    multiple: '多选',
    configGroupItem: [
      '数据源',
      '页面风格',
      '大屏尺寸',
      '页面样式',
      '背景颜色',
      '组件背景',
      '背景透明',
      '组件标题',
      '组件名称',
      '图表类型',
      '媒体渠道',
      '数据类型',
      '媒体标签',
      '数据堆叠',
      '情感倾向',
      '榜单数量',
      '排序方式',
      '统计维度',
      '互动类型',
      '热点数据范围',
      '指定文章',
      '文章首发溯源',
      '指定媒体',
      '时间范围',
      '信息滚动'
    ],
    componentNameEmpty: '组件名称不能为空！',
    componentNamePl: '请输入组件名称',
    componentLoadErrorr: '加载失败，请刷新页面',
    specifiedArticleTip: '默认展示最高传播文章，点击重选文章',
    mediaConfiguration: '媒体配置',
    timeRangLabel: ['开始时间', '结束时间'],
    timeRangLabelPlace: ['请选择开始时间', '请选择结束时间'],
    bigscreenThemeOption: ['科技蓝', '暗夜黑'],
    stackTypeOption: ['无', '情感类型', '媒体渠道', '数据类型'],
    dateIntervalOption: ['按小时', '按天', '按月'],
    mapTypeOption: ['热点地图（全球）', '热点地图（境内）', '热点地图（省级）'],
    TextChartTypeOption: [
      '文本数据',
      '热点榜单',
      '传播时间轴',
      '信息列表',
      '指标卡',
      '传播量',
      '排行榜'
    ],
    gaugeChartTypeOption: [
      '多色仪表盘',
      '多色渐变仪表盘',
      '单色仪表盘',
      '单色渐变仪表盘'
    ],
    chartTypeOption: [
      '折线图',
      '柱状图',
      '饼图',
      '演变面积图',
      '横向条形图',
      '圆角条形图',
      '比例折线图',
      '气泡图',
      '面积图',
      '关系图谱',
      '雷达图'
    ],
    wordCloudTypeOption: [
      '圆形词云图',
      '矩形词云图',
      '树形词云图',
      '词云地图',
      '话题词云图'
    ],
    sortFieldOption: [
      '发布时间',
      '点赞量',
      '转发量',
      '评论量',
      '阅读量',
      '播放量',
      '热度',
      '在看量',
      '综合排序',
      '互动量'
    ],
    sortWayOption: ['正序', '倒序'],
    polarityOption: ['正面', '负面', '中立'],
    articleTypeOption: ['原文', '转发', '评论', '对话'],
    all: ' 全部',
    unlimit: '不限',
    unknown: '未知',
    pleaseSelect: '请选择',
    componentOperations: ['数据复制', '数据透视', '设置', '删除'],
    duplicationSuccess: '复制成功',
    deleteSuccess: '删除成功',
    deleteFailed: '删除失败',
    exportToWordTip: '请等待数据加载完毕操作',
    hotTopicHeaders: ['序号', '热点主题', '主题热度'],
    expertOpinionHeaders: ['序号', '专家观点', '信息量'],
    netizenOpinionHeaders: ['序号', '网民观点', '信息量'],
    infoOverviewItem: ['信息总量', '媒体总量', '峰值传播量/小时', '日均信息量'],
    interactionOverviewItem: ['阅读总量', '点赞总量', '评论总量', '分享总量'],
    phasedEvolutionItem: ['回落', '发展', '高峰'],
    phasedEvolutionDetail: ['首发量', '信息总量'],
    sexDistributionTitle: ['男性占比', '女性占比'],
    sexDistributionItem: ['男性', '女性'],
    propagationCountryDetail: '覆盖总量（条）',
    propagationLanguageDetail: '覆盖总量（种）',
    mediaListNodataTip: '请配置媒体',
    mediaListColumn: [
      '排行',
      '账号',
      '发文量',
      '获赞量',
      '评论量',
      '负面发文',
      '转发量',
      '在看量',
      '阅读量'
    ]
  },
  analysisComponents: {
    groupName: [
      '静态组件',
      '信息概览',
      '热点信息',
      '传播趋势',
      '信息提取',
      '传播路径',
      '传播地域',
      '媒体分析',
      '时间组件',
      '全局组件'
    ],
    staticComponents: ['大屏标题'],
    informationOverviewComponents: [
      '概述',
      '影响力指数',
      '影响力指数榜单',
      '渠道首发',
      '信息概览',
      '互动概览',
      '负面信息列表',
      '传播国家量',
      '传播语种量',
      '信息列表'
    ],
    hotspotInformationComponents: [
      '活跃站点',
      '热点文章',
      '信息内容分类',
      '活跃账号'
    ],
    disseminationTrendComponents: [
      '媒体曝光趋势',
      '媒体曝光趋势对比',
      '渠道声量分析',
      '互动趋势',
      '互动趋势对比',
      '阶段演化',
      '情感倾向',
      '声量占比',
      '总声量渠道对比',
      '多主题情感对比'
    ],
    informationExtractionComponents: [
      '热点词语',
      '热点短语',
      '热点主题',
      '热点榜单',
      '热门人物',
      '热门组织',
      '热门话题',
      '专家观点',
      '微博网民观点'
    ],
    disseminationPathComponents: ['传播路径', '微博转发关系'],
    disseminationRegionsComponents: ['发布地域分布', '提及地域分布'],
    mediaAnalysisComponents: [
      '账号认证分布',
      '账号性别分布',
      '媒体等级分布',
      '发文习惯',
      '媒体榜单'
    ],
    timeComponents: ['世界时钟', '倒计时'],
    overallComponent: ['tab页面切换']
  },
  analysisOther: {
    viewTitle: '查看原文'
  },
  mediaTypeList: {
    Twitter: 'Twitter',
    Facebook: 'Facebook',
    Instagram: 'Instagram',
    APP: 'APP',
    Custom: '自定义',
    ENewspaper: '电子报纸',
    Forum: '论坛',
    Mblog: '微博',
    MblogMessage: '微博私信',
    QA: '问答',
    ShortVideo: '短视频',
    TV: '电视视频',
    Telegram: '头条',
    TouTiao: '头条',
    Translation: '译文',
    Video: '视频网站',
    Website: '网站',
    Wechat: '微信',
    Youtube: 'Youtube',
    ZhiHu: '知乎'
  },
  Symbol: {
    hour: '时',
    week: {
      星期日: '星期日',
      星期一: '星期一',
      星期二: '星期二',
      星期三: '星期三',
      星期四: '星期四',
      星期五: '星期五',
      星期六: '星期六'
    },
    componentConfig: '组件配置'
  },
  analysisIndexPage: ['默认模板', '编辑成功'],
  analysis: {
    analysisEditorGlobalSetting: [
      '页面',
      '添加页面',
      '新页面',
      '不可新增页面',
      '标准风格',
      '大屏风格'
    ]
  }
};
