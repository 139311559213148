<template>
  <div v-loading="loading" v-nodata="!Boolean(dataList.length) && !loading">
    <template v-if="theme == 'bigscreen-theme-2'">
      <RingChart
        ref="refChart"
        v-if="!loading && dataList.length"
        :data="dataList"
        :tooltip="tooltip"
        label
        :labelStr="labelFormatter"
        :legendStr="legendStr"
        :theme="theme"
      />
    </template>
    <template v-else>
      <OvalRingChart
        v-if="!loading && dataList.length"
        v-memo="[]"
        ref="refChart"
        :data="dataList"
        :tooltip="tooltip"
        :color="Color"
        legend
        label
        :labelFormatter="labelFormatter"
        @chart:click="chartClick"
      />
    </template>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  inject,
  toRef,
  watch,
  nextTick
} from 'vue';
import useAnalysisApi from '/@/api/analysis/index.ts';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { useDataCenter } from '../../_hooks/use-data-center';

export default defineComponent({
  // 账号认证分布
  name: 'AAuthentication',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: {
      type: String
    },
    drilling: Boolean
  },
  setup(props, { emit }) {
    const { accountAuthentication } = useAnalysisApi();
    const loading = ref(false);
    const Color = ref(['#5EB4EB', '#2A5AF8', '#6220FE', '#5087EC']);
    const params = computed(() => {
      return props.params;
    });
    const { setCurrentDrillingScheme } = useDataCenter(params.value);
    const dataList = ref<any[]>([]);
    const tooltip = {
      formatter: param => {
        const { data, marker, name } = param as any;
        return `
      <div style="max-width: 320px;white-space:normal;">
        <div style="margin: 0px 0 0;line-height:1;">
          <div style="margin: 0px 0 0;line-height:1;">
            ${marker}
            <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${name}</span>
            <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${
              data.value + '%'
            }</span>
            <div style="clear:both"></div>
          </div>
          <div style="clear:both"></div>
        </div>
      </div>
      `;
      }
    };
    const labelFormatter = '{top|{b}}\n{middle|{c}%}';
    const legendStr = (data, name) => {
      const l = data.find(item => {
        return item.name == name;
      });
      const i = data.findIndex(item => {
        return item.name == name;
      });
      return `{name|${l.name}}   {percent${i}|${l.percent}}`;
    };
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    onMounted(() => {
      getData();
    });
    const apiData = ref();
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        startPubTime: apiData.value?.startPubTime,
        endPubTime: apiData.value?.endPubTime
      };
    };
    function getData() {
      if (!params.value.schemeId) return;
      loading.value = true;
      dataList.value.splice(0);
      accountAuthentication(params.value)
        .then((res: any) => {
          if (res.code === 200) {
            apiData.value = res.data;
            if (chartsClick) {
              setDrillPreviewData();
              searchResultList();
            }
            const resData = res?.data?.itemList as any;
            dataList.value.push(
              ...resData.map((item: any) => {
                if (item.percent.replace('%', '') != 0) {
                  return {
                    name: item.certification,
                    value: item.percent.replace('%', ''),
                    percent: item.percent,
                    certificationType: item.certificationType
                  };
                }
              })
            );
          }
        })
        .finally(() => {
          loading.value = false;
        });
    }

    const update = () => {
      getData();
    };
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(params.value.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      loading,
      dataList,
      Color,
      update,
      tooltip,
      labelFormatter,
      legendStr,
      chartClick
    };
  }
});
</script>
<style scoped></style>
