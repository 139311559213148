<template>
  <div
    style="overflow: hidden"
    v-loading="loading"
    v-nodata="!Boolean(data.length) && !loading"
  >
    <InfoList
      v-if="!loading"
      :data="data"
      :theme="anlysisConfig?.extendedConfig?.bigScreenTheme"
      :itemClickAble="drilling"
      @chart:click="chartClick"
      :titleRows="params.titleRows"
      :autoScroll="params.autoScroll && bigScreenMode"
      :step="params.autoScrollStep"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { getSourceName } from '/@/utils/other';
import { useEditorStore } from '/@/stores/analysis';

export default defineComponent({
  // 热点文章
  name: 'AHotArticle',
  props: {
    params: {
      type: Object,
      required: true
    },
    drilling: Boolean
  },
  setup(props, { emit }) {
    const { hotArticle } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const editStore = useEditorStore();
    const anlysisConfig = computed(() => {
      return editStore.anlysisConfig;
    });
    const bigScreenMode = computed(() => {
      return editStore.anlysisConfig?.bigScreenMode;
    });
    const data = ref<any[]>([]);
    const loading = ref(false);
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      data.value.splice(0);
      try {
        params.value.polarity =
          params.value.polarity === -1 ? undefined : params.value.polarity;
        params.value.tagName =
          params.value.tagName === '-1' ? undefined : params.value.tagName;
        const res = await hotArticle(params.value);
        if (res.code === 200) {
          const resData = res?.data?.itemList as any[];
          data.value.push(
            ...resData.map(item => {
              return {
                id: item.uuid,
                title: item.title,
                url: item.url,
                dataSource: getSourceName(item.dataSourceType),
                site: ['10302'].includes(item.dataSourceType)
                  ? 'X(Twitter)'
                  : item.websiteName,
                pubtime: item.publishTime,
                readCount: item.readCount,
                likeCount: item.likeCount,
                commentCount: item.commentCount,
                rttCount: item.rttCount,
                playCount: item.playCount,
                sortField: params.value.sortInfo.sortField,
                dataSourceType: item.dataSourceType,
                articleType: item.articleType,
                keyword: item.keyword,
                uuid: item.uuid,
                publishTime: item.publishTime,
                traceId: res.traceId
              };
            })
          );
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const chartClick = obj => {
      emit('infoItemClick', obj);
    };
    return {
      data,
      loading,
      params,
      update,
      anlysisConfig,
      chartClick,
      bigScreenMode
    };
  }
});
</script>
