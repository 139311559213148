import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import NetizenOpinion from './src/index.vue';

NetizenOpinion.install = (app: App): void => {
  app.component('ANetizenOpinion', NetizenOpinion);
  app.component(
    'ANetizenOpinionProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default NetizenOpinion;
