<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(data.length) && !loading"
    style="overflow-y: auto"
  >
    <template v-if="data.length && !loading">
      <StripChart
        ref="refChart"
        v-if="params.chartType == 'bar_horizontal'"
        :rounded="theme == 'bigscreen-theme-2' ? false : true"
        :tooltip="tooltip"
        :series="{ name: '', data: data }"
        :theme="theme"
        :key="updateKey"
        :indexTagSeries="false"
        @chart:click="chartClick"
        v-memo="[]"
      />
      <TopicList
        v-if="params.chartType == 'hotlist'"
        :data="data"
        :theme="theme"
      >
        <template #header>
          <div class="column">
            {{ $t('message.analysisDetails.netizenOpinionHeaders[0]') }}
          </div>
          <div class="column no-wrap" :title="params.titleName">
            {{ params.titleName }}
          </div>
          <div class="column no-wrap" :title="params.valueName">
            {{ params.valueName }}
          </div>
        </template>
      </TopicList>
    </template>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  nextTick,
  toRef,
  watch,
  inject
} from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { getPolarity, useDataCenter } from '../../_hooks/use-data-center';
import { isImage, getChartImage, utilDebounce } from '../../utils';
import { TooltipComponentOption } from 'echarts';
import { useEditorStore } from '/@/stores/analysis';
import { useI18n } from 'vue-i18n';
import { transRealTime } from '/@/utils/other';
import { storeToRefs } from 'pinia';
const requiredAticleType = [2, 3]; //必选传的数据类型
export default defineComponent({
  // 微博网民观点
  name: 'ANetizenOpinion',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const refChart = ref();
    const imageUrl = ref('');
    const { wbNetizensView } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const chartsClick = inject('chartsClick', undefined);
    const initDrillingParams = inject('initDrillingParams');
    const data = ref<any[]>([]);
    const resData = ref<any[]>([]);
    const loading = ref(false);
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const setDrillPreviewData = () => {
      const dateRange =
        params.value.realTime === 0
          ? [params.value.startPubTime, params.value.endPubTime]
          : transRealTime(params.value.realTime);
      drillPreviewData.value = {
        realTime: 0,
        startPubTime: dateRange[0],
        endPubTime: dateRange[1]
      };
    };
    const { setCurrentDrillingScheme } = useDataCenter(params.value);
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      data.value.splice(0);
      const pm = {
        ...params.value,
        articleType: [
          ...new Set(
            (params.value.articleType || []).concat(requiredAticleType)
          )
        ]
      };
      try {
        const res = await wbNetizensView(pm);
        if (res.code === 200) {
          resData.value = res.data as any[];
          changeOption();
          if (chartsClick) {
            setDrillPreviewData();
            initDrillingParams();
          }
          nextTick(() => {
            saveChartImage();
          });
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const saveChartImage = utilDebounce(() => {
      refChart.value && (imageUrl.value = getChartImage(refChart));
    }, 300);
    const tooltip: TooltipComponentOption = {
      show: true,
      enterable: true,
      confine: true,
      formatter: param => {
        const { data, marker } = param as any;
        (window as any).tooltipUnfold = function (dom: HTMLAnchorElement) {
          const thisEl = dom as HTMLAnchorElement;
          const fillContent = (content: string, btnName: string) => {
            const parentEl = thisEl.parentElement;
            const contentEl = parentEl?.querySelector('.opinion-content');
            contentEl &&
              // contentEl.innerHTML = data.originName
              (contentEl.innerHTML = content);
            // thisEl.innerHTML = '收起'
            thisEl.innerHTML = btnName;
          };

          if (thisEl.classList.contains('unfold')) {
            fillContent(data.name, '展开');
            thisEl.classList.remove('unfold');
          } else {
            fillContent(data.originName, '收起');
            thisEl.classList.add('unfold');
          }
        };
        return `
      <div style="max-width: 320px;white-space:normal;">
        <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${marker} ${
          data.title
        }</div>
        <div style="margin: 10px 0 0;">
          <div style="margin: 0px 0 0;line-height:1.5;font-size:14px;color:#666;font-weight:400;">
            <div style="height: "><b>${t(
              'message.analysisDetails.netizenOpinionHeaders[1]'
            )}：</b><span class="opinion-content">${data.name}</span> ${
          data.originName === data.name
            ? ''
            : `<a href="javascript:void(0);" style="color: #666" onclick="tooltipUnfold(this)">展开</a>`
        }</div>
            <div><b>${t(
              'message.analysisDetails.netizenOpinionHeaders[2]'
            )}：</b>${data.origin}</div>
          </div>
        </div>
      </div>
      `;
      }
    };
    const labelStr = param => {
      return `${String(params.data.index + 1).padStart(2, '0')}`;
    };
    const updateKey = ref();
    watch(toRef(props, 'theme'), val => {
      nextTick(() => {
        changeOption();
        updateKey.value = new Date().getTime();
      });
    });
    const changeOption = () => {
      data.value.splice(0);
      data.value.push(
        ...resData.value.map((item: any) => {
          const polarity = getPolarity(item.polarity, props.theme);
          const colorWZ = {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: 'rgba(230,247,255,0)'
              },
              {
                offset: 1,
                color: '#8C8C8C'
              }
            ]
          };
          return {
            name: item.opinion,
            value: item.docCount,
            polarity: polarity?.label || t('message.analysisDetails.unknown'),
            color: polarity?.color
              ? polarity?.color
              : props.theme == 'bigscreen-theme-2'
              ? colorWZ
              : undefined,
            title:
              params.value.chartType == 'bar_horizontal'
                ? polarity?.label || t('message.analysisDetails.unknown')
                : item.opinion,
            hot: item.docCount,
            originalOpinion: item.originalOpinion
          };
        })
      );
    };
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(params.value.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      refChart,
      isImage,
      imageUrl,
      data,
      loading,
      params,
      update,
      saveChartImage,
      tooltip,
      updateKey,
      labelStr,
      chartClick
    };
  }
});
</script>
<style lang="scss" scoped>
@import '/@/theme/mixins/index.scss';

.no-wrap {
  @include text-no-wrap();
}
</style>
