<script lang="ts" setup>
import { ShallowRef, shallowRef, PropType, nextTick, onUnmounted } from 'vue';
import BaseChart from '../BaseChart/BaseChart.vue';
import useChartClick from '../hooks/useChartClick';
import { colors, getRandomColor } from '../utils';
import { hexToRgb } from '/@/utils/theme';

interface PropDataItem {
  name: string;
  value: number;
}

type symbolType =
  | 'circle'
  | 'rect'
  | 'roundRect'
  | 'triangle'
  | 'diamond'
  | 'pin'
  | 'arrow'
  | string;

const props = defineProps({
  data: {
    type: Array as PropType<PropDataItem[]>,
    required: true
  },
  tooltip: Boolean as PropType<boolean>,
  symbol: String as PropType<symbolType>,
  theme: String
});

let option: ShallowRef = shallowRef({});

interface Emits {
  // eslint-disable-next-line no-unused-vars
  (e: 'chart:click', params: any): void;
}
const emit = defineEmits<Emits>();
const chartRef = useChartClick(emit);

// 生成随机数坐标
const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
};

// 生成随机坐标的不重叠圆
const drawSymbol = (chartData: any[], max_x: number, max_y: number) => {
  let circles = [] as any[];
  let anti_crash_count = 0;
  chartData.forEach(dt => {
    const radius = (dt.symbolSize / 2) * 1.1;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      let circle = {
        name: dt.name,
        x: getRandomNumber(radius, Math.floor(max_x) - radius),
        y: getRandomNumber(radius, Math.floor(max_y) - radius),
        radius
      };

      const isOverlapping = !!circles.some(item => {
        let distance = Math.hypot(circle.x - item.x, circle.y - item.y);
        return distance < circle.radius + item.radius;
      });
      if (!isOverlapping) {
        dt['x'] = circle.x;
        dt['y'] = circle.y;
        circles.push(circle);
        break;
      }
      anti_crash_count++;
      if (anti_crash_count > 10000) {
        break;
      }
    }
  });
};

const bigscreenTheme2ColorOption = ['#4EC1CE', '#317ABC', '#E7AD5E'];
const renderBubbleChart = () => {
  const { width, height } =
    chartRef?.value?.$el?.getBoundingClientRect() as unknown as DOMRect;
  const maxSize =
    props.theme == 'bigscreen-theme-2'
      ? Math.round((width > height ? width : height) / 3)
      : 70;
  const minSize = Math.round(maxSize / 3);
  const maxVal = Math.max(
    ...props.data.map(item => {
      return item.value;
    })
  );
  const chartData = [...props.data]
    .sort((a, b) => {
      return b.value - a.value;
    })
    .map((item, i) => {
      const symbolSize = Math.max(
        Math.floor((item.value / maxVal) * maxSize),
        minSize
      );
      return {
        ...item,
        symbolSize,
        itemStyle: {
          color:
            props.theme == 'bigscreen-theme-2'
              ? {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: `rgba(${hexToRgb(
                        bigscreenTheme2ColorOption[
                          i % bigscreenTheme2ColorOption.length
                        ]
                      )},0.5)`
                    },
                    {
                      offset: 1,
                      color: `rgba(${hexToRgb(
                        bigscreenTheme2ColorOption[
                          i % bigscreenTheme2ColorOption.length
                        ]
                      )},0.1)`
                    }
                  ]
                }
              : `${colors[i % colors.length]}`,
          borderWidth: props.theme == 'bigscreen-theme-2' ? 2 : 10,
          borderColor:
            props.theme == 'bigscreen-theme-2'
              ? bigscreenTheme2ColorOption[
                  i % bigscreenTheme2ColorOption.length
                ]
              : `${colors[i % colors.length]}80`
        }
      };
    });
  drawSymbol(chartData, width, height);
  option.value = {
    series: [
      {
        type: 'graph',
        symbol: props.symbol || 'circle',
        roam: false,
        data: chartData,
        label: {
          show: true,
          formatter: (pm: any) => {
            return `${pm.name}\n${pm.data.value}`;
          },
          fontSize: props.theme == 'bigscreen-theme-2' ? 20 : undefined,
          lineHeight: props.theme == 'bigscreen-theme-2' ? 22 : undefined,
          fontWeight: props.theme == 'bigscreen-theme-2' ? 500 : undefined
        },
        z: 100
      }
    ]
  };
  props.tooltip && (option.value.tooltip = {});
  if (props.theme == 'bigscreen-theme-2') {
    const circleBorderData = chartData.map(item => {
      return {
        ...item,
        symbolSize: item.symbolSize * 1.1,
        itemStyle: {
          color: 'transparent',
          borderWidth: 2,
          borderColor: 'rgba(70,171,255,0.2)'
        }
      };
    });
    option.value.series.push({
      type: 'graph',
      symbol: 'circle',
      roam: false,
      data: circleBorderData,
      label: {
        show: false
      }
    });
  }
};
nextTick(() => {
  renderBubbleChart();
  window.addEventListener('resize', renderBubbleChart);
});
onUnmounted(() => {
  window.removeEventListener('keydown', renderBubbleChart);
});
</script>
<template>
  <base-chart ref="chartRef" :option="option" class="wh-chart--line" />
</template>

<style lang="scss" scoped></style>
