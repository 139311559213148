<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(data.length) && !loading"
    style="overflow: hidden"
  >
    <!-- <img v-if="isImage && data.length" :src="imageUrl" style="width: 100%; height: 100%" /> -->
    <WordCloud
      ref="refChart"
      v-if="!loading && data.length"
      :series="{ data: data }"
      :type="theme == 'bigscreen-theme-2' ? 'default' : chartType"
      :theme="theme"
      @chart:click="chartClick"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  nextTick,
  toRefs,
  inject
} from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { isImage, getChartImage, utilDebounce } from '../../utils';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { useDataCenter } from '../../_hooks/use-data-center';
export default defineComponent({
  // 热点短语
  name: 'AHotPhrase',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const refChart = ref();
    const imageUrl = ref('');
    const { hotPhrases } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const chartType = toRefs(props.params.chartType);
    const data = ref<any[]>([]);
    const loading = ref(false);
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const apiData = ref();
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        startPubTime: apiData.value?.startPubTime,
        endPubTime: apiData.value?.endPubTime
      };
    };
    const { setCurrentDrillingScheme } = useDataCenter(params.value);
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      data.value.splice(0);
      chartType.value = params.value.chartType;
      try {
        const res = await hotPhrases(params.value);
        if (res.code === 200) {
          apiData.value = res.data;
          if (chartsClick) {
            setDrillPreviewData();
            searchResultList();
          }
          const resData = res?.data?.itemList as any[];
          data.value.push(
            ...resData.map((item: any) => {
              return {
                name: item.phrase,
                value: item.docCount
              };
            })
          );
          nextTick(() => {
            saveChartImage();
          });
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const saveChartImage = utilDebounce(() => {
      refChart.value && (imageUrl.value = getChartImage(refChart));
    }, 300);
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(params.value.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      refChart,
      isImage,
      imageUrl,
      data,
      loading,
      params,
      update,
      saveChartImage,
      chartType,
      chartClick
    };
  }
});
</script>
