<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(series.length && data.length)"
    class="chart-box-in"
  >
    <img v-if="isImage" :src="imageUrl" style="width: 100%; height: 100%" />
    <LineChart
      v-memo="[]"
      ref="refChart"
      v-if="!isImage && !loading && series.length"
      :smooth="true"
      :series="series"
      :x-axis="{ data: xAxis }"
      y-axis
      tooltip
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, reactive } from 'vue';
import { useSearchApi } from '/@/api/search';
import { storeToRefs } from 'pinia';
import { useSearchAnalyse } from '/@/stores/searchAnalyse';
import { useDictStore } from '/@/stores/dict';
import { cloneDeep } from 'lodash-es';
import { getfilterList } from '../components/utils';
export default defineComponent({
  // 互动趋势
  name: 'SInteractionTrend',
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const refChart = ref();
    const paramsReset = computed(() => {
      return props.params;
    });
    const { analysisInteractiveTrend } = useSearchApi();
    const xAxis = ref<any[]>([]);
    const series = ref<any[]>([]);
    const loading = ref(false);
    const data = ref<any[]>([]);
    const searchAnalyseStore = useSearchAnalyse();
    const dictStore = useDictStore();
    const {
      searchAnalyseParams,
      sAnalysechannelIds,
      dataAuthType,
      designatedMediaLabelArr,
      searchTime
    } = storeToRefs(searchAnalyseStore);
    const { mediaTypeList, mediaTypeLocalList, mediaTypeForeignList } =
      storeToRefs(dictStore);
    let resParams = reactive({});
    const getData = async () => {
      loading.value = true;
      xAxis.value.splice(0);
      series.value.splice(0);
      try {
        resParams = cloneDeep(searchAnalyseParams.value);
        delete resParams.dataSource;
        let defalutMediaTypeList = [];
        if (dataAuthType.value === 0) {
          defalutMediaTypeList = cloneDeep(mediaTypeList.value);
        }
        if (dataAuthType.value === 1) {
          defalutMediaTypeList = cloneDeep(mediaTypeLocalList.value);
        }
        if (dataAuthType.value === 2) {
          defalutMediaTypeList = cloneDeep(mediaTypeForeignList.value);
        }
        const arr = getfilterList(
          defalutMediaTypeList,
          sAnalysechannelIds.value,
          'value'
        );
        resParams = Object.assign({}, resParams, {
          dataSourceInfos: arr,
          schemeId: paramsReset.value.schemeId,
          interactiveType: paramsReset.value.interactiveType
        });
        if (resParams?.designatedMediaLabelId === 0) {
          resParams.searchDesignatedLabelVOS = [];
        } else {
          resParams.searchDesignatedLabelVOS =
            designatedMediaLabelArr.value.filter(item => {
              return item.id === resParams.designatedMediaLabelId;
            });
        }
        if (searchTime.value.startPubTime && searchTime.value.endPubTime) {
          (resParams.realTime = 0),
            (resParams.startPubTime = searchTime.value.startPubTime),
            (resParams.endPubTime = searchTime.value.endPubTime);
        }
        const res = await analysisInteractiveTrend(resParams);
        if (res.code === 200) {
          const resData = res?.data?.itemList as any[];
          data.value = resData;
          const yAxis = [] as any[];
          resData.forEach((item: any) => {
            xAxis.value.push(item.date.substr(5, 11));
            yAxis.push(Number(item.count));
          });
          series.value.push({ data: yAxis });
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };

    return {
      refChart,
      xAxis,
      series,
      loading,
      paramsReset,
      update,
      data
    };
  }
});
</script>
<style lang="scss" scoped>
.chart-box-in {
  width: 100%;
  height: 330px !important;
  :deep(.el-loading-mask) {
    z-index: 9 !important;
  }
}
</style>
