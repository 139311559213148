// 定义内容
export default {
  warningList: {
    fullWarning: 'All Alerts',
    manualWarning: 'Manual Alerts',
    monitoringScheme: 'Monitor Scheme',
    monitorWarning: 'SurveillanceAlert',
    selectWarning: 'SelectWarning',
    monitor: 'Monitor',
    search: 'Search'
  },
  filterPanel: {
    WarningTime: 'Alert Time:',
    polarity: 'Sentiment:',
    polarityYayi: 'Sentiment Analysis:',
    mediaType: 'Media Type:',
    time1: 'Today',
    time2: 'Past 24 Hours',
    time3: 'Past 7 days',
    time4: 'Past 30 days',
    time5: 'Custom',
    time6: 'Past 3 days',
    time14: 'Past 14 days',
    all: 'All',
    Search: 'Search'
  },
  articlelist: {
    newInfo: 'New information available, click to obtain',
    upperInfo:
      'You have reached the data update quantity limit today. The data update has been suspended. Contact customer service to add additional data quantity.',
    chooseAll: 'Select the current page',
    collect: 'Collect',
    cancelCollect: 'Remove',
    changeLabels: 'Change Labels',
    addedWarning: 'Added Warning',
    warning: 'Alert',
    addBrief: 'Add Brief',
    addedBrief: 'Added Brief',
    delete: 'Delete',
    removeAll: 'Remove All',
    positiveSequence: 'PositiveSequence',
    reverse: 'Reverse',
    placehoder1: 'Enter the keyword',
    placehoder2: 'Please enter alert push content',
    text1: 'Are you sure to cancel the collection?',
    text2: 'Are you sure to delete the selected material?',
    text3: 'Are you sure to delete the selected information?',
    text4: 'Are you sure to delete this message?',
    text5: 'Are you sure you want to modify emotional information?',
    cancel: 'Cancel',
    confirm: 'Confirm',
    checkText: 'Original Text',
    copy: 'Copy',
    warningContent: 'Alert Notification',
    tip1: 'Content cannot be empty',
    reset: 'Reset',
    sendWarning: 'Send',
    all: 'Total:',
    tip2: 'Characters in total, no more than 100 characters',
    tip3: '',
    keyword: 'Keywords:',
    negative: 'Negative',
    positive: 'Positive',
    neutrality: 'Neutral',
    originalLink: 'OriginalLink',
    prompt: 'Note',
    issueTime: 'IssueTime',
    fullText: 'FullText',
    title: 'Title',
    publishPlatform: 'PublishPlatform',
    author: 'Author',
    copyLink: 'CopyLink',
    original: 'Original',
    retransmission: 'Retransmission',
    remark: 'Remark',
    msg1: 'Cancel collection succeeded',
    msg2: 'The selected information has added briefing data',
    msg3: 'Successfully joined the briefing',
    msg4: 'Warning succeeded',
    msg5: 'Warning failed',
    msg6: 'Copy succeeded',
    link: 'Link',
    summary: 'Summary',
    accountNumber: 'account number',
    site: 'site',
    exportAll: 'Export All',
    dataExport: 'Data Export',
    translate: 'Translate',
    similarInfoDeduplication: 'Similar Info Deduplication',
    displayOptions: 'Display Options',
    displayOptionsItems: ['Text', 'Image'],
    optsSortItems: [
      'Comprehensive',
      'Relevancy',
      'Popularity',
      'Publication Time',
      'Retrieval Time',
      'Likes',
      'Retweets',
      'Comments',
      'Views',
      'Playbacks',
      'Current Views',
      'Retrieval Time',
      'Monitoring Time',
      'Alert Time',
      'Interaction volume'
    ],
    sortWayItems: ['ASC', 'DESC'],
    matchMethodItems: ['Text', 'Title', 'Site', 'Account'],
    matchMethodTips:
      'A site indicates the name of a given website, e-paper, APP, or forum. For example, if you want to search for news articles from People.cn, select "Site" and then enter "People.cn" in the input box.',
    analysisPreview: 'Analysis Preview',
    trace: 'Trace',
    emptyTip: 'Enter keywords and click search to view relevant content',
    nodata: 'No data available',
    detailsSwitchTip: [
      'Keyboard up/down keys, can quickly switch to the previous/next',
      `It's already the first message`,
      'This is the last message'
    ]
  },
  charts: {
    totalInformation: 'Total',
    positiveInformation: 'Positive',
    negativeInformation: 'Negative',
    neutralInformation: 'Neutral',
    mediaType: 'MediaType',
    count: 'Count',
    ratio: 'Ratio',
    ChannelRatio: 'ChannelRatio',
    DownloadPictures: 'DownloadPictures',
    ActiveMedia: 'ActiveMedia',
    area: 'Area',
    quantity: 'Quantity',
    MentionRegion: 'MentionRegion',
    HotTopicWords: 'HotTopicWords',
    DataDevelopmentTrend: 'DataDevelopmentTrend',
    HotArticles: 'HotArticles',
    ranking: 'Ranking',
    title: 'Title',
    heat: 'Heat',
    PolarityRatio: 'PolarityRatio',
    PolarityTrend: 'PolarityTrend'
  }
};
