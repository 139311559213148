<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(data.length && series.length) && !loading"
    style="overflow-y: auto"
  >
    <StripChart
      ref="refChart"
      v-if="!loading && series.length"
      :tooltip="{ confine: true }"
      :series="series"
      :theme="theme"
      v-memo="[]"
      @chart:click="chartClick"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  nextTick,
  inject
} from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { ElMessage as msg } from 'element-plus';
import { isImage, getChartImage, utilDebounce } from '../../utils';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { getChartGradientColor } from '@wenhai/visual-components/utils';
import { useI18n } from 'vue-i18n';
import { useDataCenter } from '../../_hooks/use-data-center';
export default defineComponent({
  // 信息内容分类
  name: 'AInformationList',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const refChart = ref();
    const imageUrl = ref('');
    const { informationApi } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const { setCurrentDrillingScheme } = useDataCenter(params.value);
    const editStore = useEditorStore();
    const anlysisConfig = computed(() => {
      return editStore.anlysisConfig;
    });
    const series = ref<any[]>([]);
    const data = ref<any[]>([]);

    const loading = ref(false);
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const apiData = ref();
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        startPubTime: apiData.value?.startPubTime,
        endPubTime: apiData.value?.endPubTime
      };
    };
    const getData = async () => {
      series.value.splice(0);
      if (!params.value.schemeId) return;
      loading.value = true;
      try {
        const res = await informationApi(params.value);
        if (res.code === 200) {
          apiData.value = res.data;
          if (chartsClick) {
            setDrillPreviewData();
            searchResultList();
          }
          const resData = res?.data?.itemList as any[];
          data.value = resData;
          // if (params.value.stackType == 0) {
          // 非堆叠数据
          series.value.push({
            name: t(
              'message.analysisComponents.hotspotInformationComponents[2]'
            ),
            data: resData.map((item: any) => {
              return {
                name: item.contentClassificationName,
                value: Number(item.count),
                color:
                  props.theme === 'bigscreen-theme-2'
                    ? getChartGradientColor('linear', ['#10274B', '#1978E5'], {
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 0
                      })
                    : undefined
              };
            })
          });
          // } else {
          // 	// 堆叠数据
          // }
          nextTick(() => {
            saveChartImage();
          });
        } else {
          msg.error(res.msg);
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const saveChartImage = utilDebounce(() => {
      refChart.value && (imageUrl.value = getChartImage(refChart));
    }, 300);
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(params.value.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      refChart,
      isImage,
      imageUrl,
      series,
      loading,
      params,
      update,
      saveChartImage,
      data,
      anlysisConfig,
      chartClick
    };
  }
});
</script>
