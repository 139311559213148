export default {
  taskList: {
    taskCenter: 'Task Center',
    taskSearchLabel: ['Task Status', 'Enter the task name'],
    taskColumns: [
      'No.',
      'Task Name',
      'Data Volume',
      'Creator',
      'Creation Time',
      'Status',
      'Operation'
    ],
    activeBtn: ['Cancel', 'Download', 'Delete'],
    exportStatusType: ['Be queuing', 'Underway', 'Succeed', 'Failed', 'Cancel']
  }
};
