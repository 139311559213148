<template>
  <div v-loading="loading">
    <PropagationPathList
      v-if="data.length || isLoading"
      :targetInfo="params.targetArticleInfo"
      :loading="isLoading"
      :bottomLine="noMore"
      :data="data"
      @loadData="loadData"
      :theme="theme"
    >
      <div style="margin-bottom: 12px">
        <ConfigRadioOption
          v-if="dataSourcelist.length"
          class="dataSourcelist"
          :list="dataSourcelist"
          v-model="mediaType"
          :showCheckedAll="true"
          :showCount="true"
          :AllCount="mediaCount"
          :theme="theme"
        />
        <div class="label-title" v-show="theme != 'bigscreen-theme-2'">
          <c-icon color="" size="22" name="biaotizhuangshi"></c-icon>
          <span>传播路径</span>
        </div>
      </div>
      <template v-if="!isLoading && !data.length" #nodata>
        <div
          style="height: 80%; width: 100%"
          v-nodata="!Boolean(data.length)"
        ></div>
      </template>
    </PropagationPathList>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, toRef, watch, computed } from 'vue';
import { useDataCenter } from '../../_hooks/use-data-center';
import { listMethod } from '/@/api/article';
import dayjs from 'dayjs';
import { useDictStore } from '/@/stores/dict';
import useAnalysisApi from '/@/api/analysis';
import useSchemeMenuApi from '/@/api/search/schemeMenu';
export default defineComponent({
  //传播路径
  name: 'APropagationPath',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String
  },
  setup(props) {
    const params = toRef(props, 'params');
    const { hotArticleDiffusionList, hotArticleDiffusionCount } =
      useAnalysisApi();
    const { getSchemeConfig, getfilterList, dataAuth } = useDataCenter(
      params.value
    );
    const loading = ref<boolean>(false);
    const isUpdate = ref<boolean>(false);
    const data = ref<any[]>([]);
    const isLoading = ref<boolean>(false);
    const noMore = ref<boolean>(false);
    const mediaCount = ref<number>(0);
    const pageInfo = ref({
      currentPage: 1,
      pageSize: 20
    });
    const dictStore = useDictStore();
    const mediaTypeOption = ref(
      !dictStore.mediaTypeList.length
        ? JSON.parse(JSON.stringify(params.value.dataSource))
        : dataAuth == 0
        ? dictStore.mediaTypeList
        : dataAuth == 1
        ? dictStore.mediaTypeLocalList
        : dictStore.mediaTypeForeignList
    );

    const mediaTypeList = ref(
      JSON.parse(JSON.stringify(mediaTypeOption.value))
        .filter((v: any) =>
          ['网站', '微信', 'APP', '电子报刊'].includes(v.label)
        )
        .map((v: any) => ({
          id: v.value,
          name: v.label,
          count: 0
        }))
    );
    const dataSourcelist = computed(() => {
      return JSON.parse(JSON.stringify(mediaTypeList.value)).filter(
        (v: any) => {
          return v.count;
        }
      );
    });
    const mediaType = ref('');
    const loadData = () => {
      if (isLoading.value || noMore.value) return;
      pageInfo.value.currentPage += 1;
      getData();
    };
    const getData = async () => {
      if (!params.value.schemeId || !params.value.uuid) {
        loading.value = false;
        return;
      }
      if (isUpdate.value) isLoading.value = false;
      else isLoading.value = true;
      noMore.value = false;
      try {
        const res: any = await hotArticleDiffusionList({
          ...pageInfo.value,
          ...params.value
        });
        if (res?.code === 200 && res?.data?.articleList) {
          data.value.push(
            ...res.data.articleList.map((item: any) => {
              return {
                uuid: item.uuid,
                title: item.title || '',
                author: item.author || '',
                publishTime: item.publishTime,
                site: item?.websiteName || '',
                url: item?.url
              };
            })
          );
          if (res.data?.articleList.length < pageInfo.value.pageSize) {
            noMore.value = true;
          }
        }
        if (isUpdate.value)
          propagationPathCount({ ...pageInfo.value, ...params.value });
        isLoading.value = false;
        loading.value = false;
      } catch (error) {
        isLoading.value = false;
        loading.value = false;
      }
    };
    const getArticleInfo = async () => {
      // const param = getSchemeConfig(params.value.schemeId);
      const res: any = await useSchemeMenuApi().getSearchSchemeConfig(
        params.value.schemeId
      );
      const param = res?.data?.config;
      param.dataSource = JSON.parse(
        JSON.stringify(mediaTypeOption.value)
      ).filter((v: any) =>
        ['网站', '微信', 'APP', '电子报刊'].includes(v.label)
      );
      param.sortInfo = { sortField: 7, sortWay: 'desc' };
      param.pageInfo = { currentPage: 1, pageSize: 1, total: 0 };
      param.now = dayjs().format('YYYY-MM-DD HH:mm:ss');
      try {
        const res: any = await listMethod().list(param);
        if (res.code == 200 && res?.data?.list.length) {
          params.value.targetArticleInfo = res.data?.list.map((v: any) => ({
            uuid: v.uuid,
            title: v.title || v.content,
            author: v.author || v.user?.userName || '',
            publishTime: v.publishTime,
            site: v.platform?.websiteName,
            coverImage:
              v.sourceType == 3
                ? v?.videosCoverArray?.length && v.videosCoverArray[0]
                : v?.picturesArray?.length && v.picturesArray[0],
            fingerprint: v?.analysis?.fingerprint || ''
          }))[0];
          params.value.uuid = params.value.targetArticleInfo.uuid;
          params.value.articlePublishTime =
            params.value.targetArticleInfo.publishTime;
          params.value.fingerprint = params.value.targetArticleInfo.fingerprint;
        } else {
          loading.value = false;
          params.value.targetArticleInfo = {};
          params.value.uuid = '';
          params.value.articlePublishTime = '';
          params.value.fingerprint = '';
        }
      } catch (error) {}
    };
    const propagationPathCount = async (params: any) => {
      const param = JSON.parse(JSON.stringify(params));
      param.dataSource = JSON.parse(
        JSON.stringify(mediaTypeOption.value)
      ).filter((v: any) =>
        ['网站', '微信', 'APP', '电子报刊'].includes(v.label)
      );
      try {
        const res: any = await hotArticleDiffusionCount(param);
        if (res?.code === 200) {
          isUpdate.value = false;
          const dataSourceCountList = res.data?.dataSourceCountList;
          mediaCount.value = res.data?.count || 0;
          mediaTypeList.value.forEach((v: any) => {
            const obj = dataSourceCountList.find((item: any) => {
              return item.label == v.name;
            });
            if (obj) v.count = obj.count;
          });
        }
      } catch (error) {}
    };
    const update = async () => {
      isUpdate.value = true;
      pageInfo.value.currentPage = 1;
      loading.value = true;
      if (!params.value.targetArticleInfo) {
        await getArticleInfo();
      }
      data.value = [];
      mediaType.value = '';
      params.value.dataSource = JSON.parse(
        JSON.stringify(mediaTypeOption.value)
      ).filter((v: any) =>
        ['网站', '微信', 'APP', '电子报刊'].includes(v.label)
      );
      await getData();
    };
    watch(mediaType, val => {
      params.value.dataSource = [];
      pageInfo.value.currentPage = 1;
      data.value = [];
      if (val) {
        params.value.dataSource.push(
          ...getfilterList(
            JSON.parse(JSON.stringify(mediaTypeOption.value)),
            [mediaType.value],
            'value'
          )
        );
      } else {
        params.value.dataSource = JSON.parse(
          JSON.stringify(mediaTypeOption.value)
        ).filter((v: any) =>
          ['网站', '微信', 'APP', '电子报刊'].includes(v.label)
        );
      }
      if (val) isUpdate.value = false;
      !isUpdate.value && getData();
    });
    onMounted(async () => {
      loading.value = true;
      if (!params.value.targetArticleInfo) {
        await getArticleInfo();
      }
      isUpdate.value = true;
      mediaType.value = '';
      pageInfo.value.currentPage = 1;
      params.value.dataSource = JSON.parse(
        JSON.stringify(mediaTypeOption.value)
      ).filter((v: any) =>
        ['网站', '微信', 'APP', '电子报刊'].includes(v.label)
      );
      await getData();
    });
    return {
      loading,
      isUpdate,
      data,
      isLoading,
      noMore,
      mediaTypeList,
      mediaType,
      pageInfo,
      mediaCount,
      dataSourcelist,
      loadData,
      update
    };
  }
});
</script>
<style lang="scss" scoped>
:deep(.wh-chart--propagation-path-list) {
  .dataSourcelist {
    margin-top: 8px;
  }
  .label-title {
    display: flex;
    align-items: center;
    line-height: 22px;
    font-weight: 600;
    color: #3c4555;
    font-size: 14px;
    margin-top: 8px;
  }
}
</style>
