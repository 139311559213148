<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(data.length) && !loading"
    style="overflow: hidden"
  >
    <img
      v-if="isImage && data.length"
      :src="imageUrl"
      style="width: 100%; height: 100%"
    />
    <WordCloud
      v-memo="[]"
      ref="refChart"
      v-if="!isImage && !loading && data.length"
      :series="{ data: data }"
      :type="chartType"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  nextTick,
  toRefs
} from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { isImage, getChartImage, utilDebounce } from '../../utils';
export default defineComponent({
  name: 'APopularTopic',
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const refChart = ref();
    const imageUrl = ref('');
    const { popularTopic } = useAnalysisApi();
    const params = computed(() => {
      return props.params;
    });
    const chartType = toRefs(props.params.chartType);

    const data = ref<any[]>([]);
    const loading = ref(false);
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      data.value.splice(0);
      chartType.value = params.value.chartType;

      try {
        const res = await popularTopic(params.value);
        if (res.code === 200) {
          const resData = res.data as any[];
          data.value.push(
            ...resData.map((item: any) => {
              return {
                name: item.name,
                value: item.docCount
              };
            })
          );
          nextTick(() => {
            saveChartImage();
          });
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const saveChartImage = utilDebounce(() => {
      refChart.value && (imageUrl.value = getChartImage(refChart));
    }, 300);
    return {
      refChart,
      isImage,
      imageUrl,
      data,
      loading,
      update,
      saveChartImage,
      chartType
    };
  }
});
</script>
