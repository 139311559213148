<script lang="ts" setup>
import { shallowRef, watchEffect, ref } from 'vue';
import BaseChart from '../BaseChart/BaseChart.vue';
import {
  buildAxis,
  buildGrid,
  buildGridWidthLegend,
  buildLegend,
  ChartDataItem,
  ECOption,
  normalizeSeries,
  SeriesItem,
  getFontWidth,
  buildTooltip
} from '../utils';
import type { ShallowRef } from 'vue';
import {
  Color,
  XAXisComponentOption,
  YAXisComponentOption,
  TooltipComponentOption
} from 'echarts';
import { sumBy, unzipWith } from 'lodash-es';
import useChartClick from '../hooks/useChartClick';
import { TTheme } from '../utils';

interface ColorDataItem extends ChartDataItem {
  color?: Color;
  origin?: number;
  originName?: string;
}

interface StripChartProps {
  series: SeriesItem<ColorDataItem>;
  legend?: boolean;
  barWidth?: number;
  background?: Color;
  tooltip?: boolean | TooltipComponentOption | object;
  rounded?: boolean;
  colors?: Color[];
  label?: object;
  theme: TTheme;
  indexTagSeries?: boolean;
}

const backgroundOption = {
  ['bigscreen-theme-1']: 'rgba(44,126,251,0.1)',
  ['bigscreen-theme-2']: 'rgba(255,255,255,0.08)'
};

interface Emits {
  (e: 'chart:click', params: any): void;
}

const props = withDefaults(defineProps<StripChartProps>(), {
  barWidth: 16,
  background: '#F5F5F5',
  label: () => {
    return {};
  },
  indexTagSeries: true
});
const height = ref();
const emit = defineEmits<Emits>();
const chartRef = useChartClick(emit) as Ref<InstanceType<typeof BaseChart>>;

let option: ShallowRef<ECOption> = shallowRef({});

const data = props.series.data || props.series[0].data;
//  height.value = (data.length > 10 ? data.length : 10) * 10 + '%'
height.value = data.length * 50 + 'px';
watchEffect(() => {
  const { barWidth, background, rounded, colors } = props;
  const propSeries = props.series;
  let series = normalizeSeries(propSeries, 'bar') as Extract<
    SeriesItem<ColorDataItem>,
    any[]
  >;
  let stack = series.length > 1 ? true : false;

  const max = (...args: number[]) => Math.max(...args);
  const ratio = (num: number, max: number) => num / max;
  // 堆叠情况需要加总
  let maxVal = 1;
  // 加总的数组
  let totalValueArr;
  if (stack) {
    totalValueArr = unzipWith(
      series.map(s => s.data),
      (...args) => sumBy(args, 'value')
    );
  } else {
    totalValueArr = series?.[0]?.data?.map(d => d.value as number);
  }
  if (!totalValueArr || !totalValueArr.length) return;
  maxVal = max(...totalValueArr) || 1;
  maxVal *= 1.1;

  const context = document
    .createElement('canvas')
    .getContext('2d') as CanvasRenderingContext2D;
  const $el = chartRef.value?.$el;
  if (!$el) return;
  // const domWidth = $el.offsetWidth || 0
  const style = window.getComputedStyle($el);
  const paddingLeft = parseInt(style.paddingLeft) || 0;
  const paddingRight = parseInt(style.paddingRight) || 0;
  // const contentWidth = domWidth - paddingLeft - paddingRight
  // if (!contentWidth) throw new Error('[error] dom width')
  // const nameLimitWidth = 0.6 * contentWidth

  // 每个bar单独设置颜色
  series = series.map((s, i) => {
    s.data = s.data.map(d => {
      let name = d.name;
      // 每个字符的宽度
      // const letterWidth = getFontWidth(context)
      // // 整个name的宽度
      // const wholeWidth = getFontWidth(context, name)
      // // name 的长度大于 60% 的 dom 宽度
      // if (wholeWidth > nameLimitWidth) {
      //   // 字符限制的长度
      //   const nameLimitLen = ((nameLimitWidth / letterWidth) | 0) || name.length
      //   name = name.slice(0, nameLimitLen) + '...'
      // }

      return {
        ...d,
        origin: d.value,
        value: ratio(d.value, maxVal),
        originName: d.name,
        name,
        itemStyle: {
          color: d.color
        }
      };
    });
    return {
      ...s,
      barWidth,
      showBackground: true,
      backgroundStyle: {
        color: props.theme ? backgroundOption[props.theme] : background
      },
      stack: stack ? 'bar-stack' : undefined,
      itemStyle: rounded
        ? {
            borderRadius: barWidth / 2
          }
        : undefined,
      label:
        i === 0
          ? {
              show: true,
              position: 'insideLeft',
              offset:
                props.theme == 'bigscreen-theme-2' && props.indexTagSeries
                  ? [50, -1 * barWidth - 4]
                  : [0, -1 * barWidth],
              formatter: (d: any) => {
                const domWidth = $el.offsetWidth || 0;
                const contentWidth = domWidth - paddingLeft - paddingRight;
                const nameLimitWidth = 0.4 * contentWidth;
                let name = d.name;
                // 每个字符的宽度
                const letterWidth = getFontWidth(context);
                // 整个name的宽度
                const wholeWidth = getFontWidth(context, name);
                // name 的长度大于 60% 的 dom 宽度
                if (wholeWidth > nameLimitWidth) {
                  // 字符限制的长度
                  const nameLimitLen =
                    (nameLimitWidth / letterWidth) | 0 || name.length;
                  name = name.slice(0, nameLimitLen) + '...';
                }
                return name;
              },
              color: props.theme == 'bigscreen-theme-2' ? '#D8F0FF' : '#262626'
            }
          : undefined,
      emphasis: {
        itemStyle: {
          color: undefined
        }
      },
      markPoint:
        props.theme == 'bigscreen-theme-2'
          ? {
              symbol: 'rect',
              data: s.data.map((item, i) => {
                return {
                  yAxis: i,
                  x: (ratio(item.origin, maxVal) * 100).toString() + '%',
                  symbolSize: [4, barWidth + 4]
                };
              }),
              itemStyle: {
                color: '#fff'
              },
              label: {
                show: false
              },
              silent: true
            }
          : undefined
    };
  });

  const shadowSeries = {
    name: 'shadow',
    type: 'bar',
    data: totalValueArr.map(t => ({ value: 1, name: t })),
    itemStyle: {
      color: 'transparent'
    },
    barGap: '-100%',
    barWidth,
    label: {
      show: true,
      position: 'insideRight',
      offset: [0, -1 * barWidth],
      formatter: '{b}',
      color: props?.label?.color
        ? '#272A31'
        : props.theme == 'bigscreen-theme-2'
        ? '#D8F0FF'
        : '#BFC4CD'
    },
    silent: true,
    zlevel: -1
  };

  series.push(shadowSeries as unknown as any);

  const indexTagSeries = {
    name: 'indexTag',
    type: 'bar',
    data: totalValueArr.map((t, i) => ({
      value: 1,
      name: t,
      index: i,
      label: {
        show:
          props.theme == 'bigscreen-theme-2' && props.indexTagSeries
            ? true
            : false,
        position: 'insideLeft',
        offset: [0, -1 * barWidth - 4],
        formatter(params) {
          return `TOP${String(params.data.index + 1).padStart(2, '0')}`;
        },
        color: '#D8F0FF',
        borderWidth: i <= 2 ? 2 : 0,
        borderColor: '#0A4B86',
        lineHeight: 20,
        padding: [0, 4]
      }
    })),
    itemStyle: {
      color: 'transparent'
    },
    // barGap: '-100%',
    barWidth,
    silent: true
  };
  series.push(indexTagSeries as unknown as any);

  const xAxis = buildAxis('y', { show: false }) as XAXisComponentOption;
  const yAxis = buildAxis('x', {
    show: false,
    inverse: true,
    data: series[0].data.map(() => '')
  }) as YAXisComponentOption;

  option.value = {
    color: colors,
    xAxis,
    yAxis,
    series,
    textStyle: {
      color: '#fff'
    }
  };

  if (props.legend) {
    option.value.legend = buildLegend();
    option.value.grid = buildGridWidthLegend();
  } else {
    option.value.grid =
      props.theme == 'bigscreen-theme-2'
        ? buildGrid({ left: 0, right: 0, containLabel: false })
        : buildGrid();
  }
  option.value.tooltip = buildTooltip(props.tooltip, {
    formatter: param => {
      const { data, marker, seriesName } = param as any;
      return `
      <div style="max-width: 320px;white-space:normal;">
        <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${
          seriesName || data.name
        }</div>
        <div style="margin: 10px 0 0;line-height:1;">
          <div style="margin: 0px 0 0;line-height:1;">
            ${marker}
            <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${
              data.originName || data.name
            }</span>
            <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${
              data.origin
            }</span>
            <div style="clear:both"></div>
          </div>
          <div style="clear:both"></div>
        </div>
      </div>
      `;
    }
  }) as any;
});
</script>
<template>
  <base-chart
    ref="chartRef"
    :option="option"
    :style="{ height: height }"
    style="width: 100%"
  ></base-chart>
</template>
