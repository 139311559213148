import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import AAuthentication from './src/index.vue';

AAuthentication.install = (app: App): void => {
  app.component('AAuthentication', AAuthentication);
  app.component(
    'AAuthenticationProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default AAuthentication;
