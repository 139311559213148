<template>
  <div v-loading="loading" v-nodata="!sortData.length && !loading">
    <div style="width: 100%; height: 100%" v-if="sortData.length">
      <BubbleChart
        v-if="!loading && params.chartType == 'bubble'"
        :data="sortData"
        tooltip
        :symbol="
          theme == 'bigscreen-theme-2'
            ? undefined
            : 'path://M512 0l443.40224 256v512L512 1024 68.59776 768V256z'
        "
        :key="upateKey"
        :theme="theme"
        @chart:click="chartClick"
        class="a-popular-people--bubble"
      />
      <RingChart
        v-if="!loading && params.chartType == 'pie'"
        :data="data"
        label
        tooltip
        legend
        @chart:click="chartClick"
      />
      <BarChart
        v-if="!loading && params.chartType == 'bar'"
        horizontal
        :series="{
          data: sortData.map(item => {
            return { value: item.value };
          })
        }"
        :x-axis="{
          show: false,
          data: sortData.map(item => {
            return item.name;
          })
        }"
        :label="{ show: true, position: [-50, '30%'], color: '#9D9D9D', formatter: (params: any) => { return params.name } }"
        tooltip
        :dataZoom="false"
        @chart:click="chartClick"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  toRef,
  computed,
  inject,
  watch
} from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { useEditorStore } from '/@/stores/analysis';
import { storeToRefs } from 'pinia';
import { useDataCenter } from '../../_hooks/use-data-center';

export default defineComponent({
  name: 'APopularPeople',
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const upateKey = ref();
    watch(toRef(props, 'theme'), () => {
      upateKey.value = new Date().getTime();
    });
    const { popularPeople } = useAnalysisApi();
    const data = ref<{ name: string; value: number }[]>([]);
    const sortData = computed(() => {
      return data.value.sort((a, b) => {
        return a.value - b.value;
      });
    });
    const loading = ref(false);
    const params = toRef(props, 'params');
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const apiData = ref();
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        startPubTime: apiData.value?.startPubTime,
        endPubTime: apiData.value?.endPubTime
      };
    };
    const { setCurrentDrillingScheme } = useDataCenter(params.value);
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      try {
        const res = (await popularPeople(params.value)) as any;
        apiData.value = res.data;
        if (chartsClick) {
          setDrillPreviewData();
          searchResultList();
        }
        data.value = res?.data?.itemList.map(
          (item: { personName: string; count: number }) => {
            return {
              name: item.personName,
              value: item.count
            };
          }
        );
      } catch (error) {
        loading.value = false;
      }
      loading.value = false;
    };
    onMounted(() => {
      getData();
    });
    const update = () => {
      getData();
    };
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setCurrentDrillingScheme(params.value.schemeId);
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      data,
      loading,
      params,
      update,
      sortData,
      upateKey,
      chartClick
    };
  }
});
</script>
