<template>
  <w-form-item :label="label" class="w-form-item-realtime-range">
    <w-select v-model="rTime" @change="realTimeChange">
      <w-option
        v-for="item in limitedDays < 92 ? realTimeOption.filter((option: any)=> {return option?.value !== 92 }) : realTimeOption"
        :key="item.value"
        :value="item.value"
        v-show="realTimeShortcut.includes(item.value)"
        >{{ item.label }}</w-option
      >
    </w-select>
    <ConfigTimeControl
      ref="rangePickerRef"
      :time-picker-props-start="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }"
      :time-picker-props-end="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }"
      :clearable="true"
      :dateRangeLimit="limitedDays"
      :dateRangeSelectLimit="limitedRangeDays"
      :model-value="range"
      @change="handleChangeTime"
      allowFuture
    ></ConfigTimeControl>
    <!-- <w-range-picker v-model="range" show-time format="YYYY-MM-DD HH:mm:ss" :clearable="clearable" @clear="handleClear"
			@ok="handleOk" :disabledDate="getDisabledDate" @select="onSelect" :disabledTime="getDisabledRangeTime"
			@popup-visible-change="popupVisibleChange" /> -->
  </w-form-item>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, watch, computed } from 'vue';
import { transRealTime } from '/@/utils/other';
import dayjs from 'dayjs';
import { ElMessage as msg } from 'element-plus';
import ConfigTimeControl from './ConfigTimeControl.vue';
import { i18n } from '/@/i18n/index';
import { useUserPermission } from '/@/stores/useUserPermission';

const t = i18n.global.t;
type RealTime = -1 | 1 | 3 | 7 | 30 | 92 | 0;
const realTimeOption = [
  {
    label: t('message.analysisDetails.timeRangeItem[0]'),
    value: -1 as RealTime
  }, // 今天
  {
    label: t('message.analysisDetails.timeRangeItem[1]'),
    value: 1 as RealTime
  }, // 近24小时
  {
    label: t('message.analysisDetails.timeRangeItem[5]'),
    value: 3 as RealTime
  }, // 近3天
  {
    label: t('message.analysisDetails.timeRangeItem[2]'),
    value: 7 as RealTime
  }, // 近7天
  {
    label: t('message.analysisDetails.timeRangeItem[3]'),
    value: 30 as RealTime
  }, // 近30天
  {
    label: t('message.analysisDetails.timeRangeItem[6]'),
    value: 92 as RealTime
  }, // 近3个月
  { label: t('message.analysisDetails.timeRangeItem[4]'), value: 0 as RealTime } // 自定义
];
export default defineComponent({
  name: 'ConfigRealtimeRangePicker',
  components: {
    ConfigTimeControl
  },
  props: {
    label: {
      type: String
    },
    realTime: {
      type: Number as PropType<RealTime>
    },
    realTimeShortcut: {
      // realTime下拉展示选项
      type: Array as PropType<RealTime[]>,
      default: () => {
        return [-1, 1, 3, 7, 30, 92, 0];
      }
    },
    startTime: {
      type: String
    },
    endTime: {
      type: String
    },
    dateRangeLimit: {
      // 可选时间范围限制
      type: Number
    },
    clearable: {
      type: Boolean,
      default: false
    },
    dateRangeDaysLimit: {
      // 选中时间跨度限制
      type: Number
    }
  },
  setup(props, { emit }) {
    const userPermission = useUserPermission();
    const limitedDays = computed(() => {
      return userPermission.searchDataRange;
    });
    const limitedRangeDays = computed(() => {
      return userPermission.searchDataSelectRange;
    });
    const rangePickerRef = ref();
    const rTime = ref(props.realTime);
    const range = ref(
      props.realTime == 0
        ? [props.startTime, props.endTime]
        : transRealTime(props.realTime!)
    );
    watch(rTime, val => {
      emit('update:realTime', val);
    });
    watch(
      range,
      (val, old) => {
        if (!old) return;
        emit('update:startTime', val[0]);
        emit('update:endTime', val[1]);
      },
      {
        deep: true,
        immediate: true
      }
    );
    const getDaysDif = (start: Date, end: Date | number | string) => {
      return Math.abs(
        (new Date(start).getTime() - new Date(end).getTime()) /
          (24 * 60 * 60 * 1000)
      );
    };
    const getDisabledDate = (current: any) => {
      const origionDisabled =
        getDaysDif(current, Date.now()) > limitedDays.value ||
        new Date(current).getTime() > Date.now();
      const dynamicDisabled =
        (range.value[0] &&
          getDaysDif(current, range.value[0]) >
            (props.dateRangeLimit || limitedRangeDays.value)) ||
        (range.value[1] &&
          getDaysDif(current, range.value[1]) >
            (props.dateRangeLimit || limitedRangeDays.value));

      return origionDisabled || dynamicDisabled;
    };
    const onSelect = (current: string[]) => {
      range.value = current;
    };
    const handleClear = () => {
      range.value = [];
      rTime.value = 0;
    };
    const handleOk = () => {
      const starttime = range.value[0]!,
        endtime = range.value[1]!;
      if (
        props.dateRangeDaysLimit &&
        Date.parse(endtime) - Date.parse(starttime) >
          props.dateRangeDaysLimit * 24 * 60 * 60 * 1000
      ) {
        msg.warning(`时间跨度不超过${props.dateRangeDaysLimit}天`);
        range.value = [
          dayjs(endtime)
            .subtract(props.dateRangeDaysLimit, 'days')
            .format('YYYY-MM-DD HH:ss:mm'),
          endtime
        ];
      }
      rTime.value = 0;
    };
    const realTimeChange = () => {
      range.value =
        rTime.value === 0 ? transRealTime(1) : transRealTime(rTime.value!);
      if (rTime.value == 0) {
        rangePickerRef.value?.startTimeShowPicker();
      }
    };
    const currentDate = ref();
    const limitedDate = ref();
    const popupVisibleChange = (visible: boolean) => {
      // 记录打开选择器的时间，用于限制时分秒选择
      if (!visible) {
        currentDate.value = '';
        limitedDate.value = '';
      }
      currentDate.value = dayjs().format('YYYY-MM-DD HH:mm:ss');
      limitedDate.value = dayjs()
        .subtract((props.dateRangeLimit || limitedRangeDays.value) - 1, 'day')
        .format('YYYY-MM-DD HH:mm:ss');
    };
    const getTimeRange = (start: number, end: number) => {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    };
    const getDisabledRangeTime = (date: any, type: string) => {
      const rst = {
        disabledHours: () => {},
        disabledMinutes: () => {},
        disabledSeconds: () => {}
      };
      if (
        type === 'start' &&
        dayjs(date).format('YYYY-MM-DD') ===
          dayjs(limitedDate.value).format('YYYY-MM-DD')
      ) {
        rst.disabledHours = () =>
          getTimeRange(0, dayjs(currentDate.value).get('h'));
        rst.disabledMinutes = () =>
          dayjs(date).get('h') > dayjs(currentDate.value).get('h')
            ? []
            : getTimeRange(0, dayjs(currentDate.value).get('m'));
        rst.disabledSeconds = () =>
          dayjs(date).get('h') == dayjs(currentDate.value).get('h') &&
          dayjs(date).get('m') == dayjs(currentDate.value).get('m')
            ? getTimeRange(0, dayjs(currentDate.value).get('s'))
            : [];
      }
      if (
        type === 'end' &&
        dayjs(date).format('YYYY-MM-DD') ===
          dayjs(currentDate.value).format('YYYY-MM-DD')
      ) {
        rst.disabledHours = () =>
          getTimeRange(dayjs(currentDate.value).get('h') + 1, 24);
        rst.disabledMinutes = () =>
          dayjs(date).get('h') < dayjs(currentDate.value).get('h')
            ? []
            : getTimeRange(dayjs(currentDate.value).get('m') + 1, 60);
        rst.disabledSeconds = () =>
          dayjs(date).get('h') == dayjs(currentDate.value).get('h') &&
          dayjs(date).get('m') == dayjs(currentDate.value).get('m')
            ? getTimeRange(dayjs(currentDate.value).get('s') + 1, 60)
            : [];
      }
      return rst;
    };

    const handleChangeTime = (value: any, type?: string) => {
      if (value && Object.prototype.toString.call(value) === '[object Array]') {
        const starttime = value[0]!,
          endtime = value[1]!;
        if (
          props.dateRangeDaysLimit &&
          Date.parse(endtime) - Date.parse(starttime) >
            props.dateRangeDaysLimit * 24 * 60 * 60 * 1000
        ) {
          msg.warning(`时间跨度不超过${props.dateRangeDaysLimit}天`);
          if (type == 'end') {
            value = [
              starttime,
              dayjs(starttime)
                .add(props.dateRangeDaysLimit, 'day')
                .format('YYYY-MM-DD HH:ss:mm')
            ];
          } else if (type == 'start') {
            value = [
              dayjs(endtime)
                .subtract(props.dateRangeDaysLimit, 'days')
                .format('YYYY-MM-DD HH:ss:mm'),
              endtime
            ];
          }
        }
        range.value = value;
        rTime.value = 0;
      }
    };
    return {
      range,
      rTime,
      realTimeOption,
      getDisabledDate,
      realTimeChange,
      handleOk,
      onSelect,
      handleClear,
      popupVisibleChange,
      getDisabledRangeTime,
      limitedRangeDays,
      limitedDays,
      handleChangeTime,
      rangePickerRef,
      dayjs
    };
  }
});
</script>
<style lang="scss">
.w-form-item-realtime-range {
  .w-form-item-wrapper-col {
    .w-form-item-content-wrapper {
      .w-form-item-content {
        display: block;

        .w-select-view-single {
          margin-bottom: 6px;
        }
      }
    }
  }
}
</style>
