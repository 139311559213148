import { defineStore } from 'pinia';
import { ThemeConfigStates, ThemeConfigState } from './interface';
import * as themeConfig from './theme/themeConfigvideo';
/**
 * 布局配置
 * 修复：https://gitee.com/lyt-top/vue-next-admin/issues/I567R1，感谢@lanbao123
 * 2020.05.28 by lyt 优化。开发时配置不生效问题
 * 修改配置时：
 * 1、需要每次都清理 `window.localStorage` 浏览器永久缓存
 * 2、或者点击布局配置最底部 `一键恢复默认` 按钮即可看到效果
 */
export const useThemeConfig = defineStore('themeConfig', {
  state: (): ThemeConfigStates => ({
    themeConfig:
      themeConfig[
        'themeConfig' +
          (import.meta.env.VITE_NAME && `-${import.meta.env.VITE_NAME}`)
      ]
  }),
  actions: {
    setThemeConfig(data: ThemeConfigState) {
      this.themeConfig = data;
    },
    // 布局配置
    setThemeConfigOne(key, value) {
      this.themeConfig[key] = value;
    }
  }
});
