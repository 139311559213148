import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import Summarize from './src/index.vue';

Summarize.install = (app: App): void => {
  app.component('ASummarize', Summarize);
  app.component(
    'ASummarizeProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default Summarize;
