// 定义内容
export default {
  searchForm: {
    placeholder1: '請輸入關鍵字檢索',
    placeholder2: '输入蒐索内容',
    placeholder3: '請輸入關鍵字，回車鍵確認',
    placeholder4: '請輸入關鍵字',
    mode1: '運算式',
    mode2: '關鍵字',
    mode3: '雲蒐索',
    mode4: '全文',
    mode5: '標題',
    mode6: '正文',
    mode7: '標題正文',
    search: '蒐索',
    search1: '査詢',
    transferAnalysis: '轉分析',
    turntofmonitor: '轉監測',
    example: '示例',
    tipTtitle1:
      '1.公式可包括“與”、“或”、“非”的關係，用“&”代表“與”，“|”代表“或”，“！”代表“非”；',
    tipTtitle2:
      '2.公式中的每組關鍵字以“（）”包含。 示例：北京&(冬奧會|冬殘奧會)!(公園）。',
    modeSwitchTip:
      '轉換為運算式蒐索模式當前為運算式蒐索，切換到關鍵字搜索將清空已輸入的關鍵字，是否切換？',
    rememberChoice: '記住我的選擇，下次不再提醒',
    note: '帮助说明：',
    noteTip:
      '從運算式蒐索模式轉換到關鍵字搜索模式，蒐索框內的內容將無法保留，請悉知。',
    notPrompt: '以後不再提示',
    close: '關閉',
    moreActions: '更多操作',
    saveThemeTip: '請先保存主題再繼續操作！',
    regionSwitchTip:
      '修改數據許可權後部分篩選條件的可選項也會改變，並重置全部已選內容，確定修改嗎？',
    domestic: '境內',
    abroad: '境外',
    chooseRegionTip: '最少選擇一個數據許可權！',
    filterCriteria: '篩選條件',
    pleaseChoose: '請選擇',
    reset: '重置',
    positive: '正面',
    negative: '負面',
    neutral: '中立',
    irrelevant: '无关',
    toAnalyzed: '待分析',
    today: '今天',
    unlimited: '不限',
    nearly24hours: '近24小時',
    nearly3days: '近3天',
    nearly7days: '近7天',
    nearly14days: '近14天',
    nearly30days: '近30天',
    nearly3months: '近3个月',
    custom: '自定義',
    original: '原創',
    repost: '轉發',
    comment: '評論',
    all: '全部',
    agencyCertification: '機构認證',
    personalAuthentication: '個人認證',
    unauthorized: '未認證',
    corporate: '公司企业',
    other: '其他',
    minfancount: '最少粉絲數',
    maxfancount: '最多粉絲數',
    more: '更多',
    packup: '收起',
    showMore: '更多',
    pubtime: '發佈時間',
    warehousetime: '入庫時間',
    matchMethods: '命中位置',
    mediaType: '媒體類型',
    polarity: '情感傾向',
    languages: '資訊語言',
    mediaTags: '推薦標籤',
    areaIds: '地域識別',
    authTypes: '帳號認證',
    fansCount: '粉絲數量',
    articleType: '資料類型',
    keywordsTip: '關鍵字不可重複',
    keywordsTip2:
      '選中簡繁匹配關鍵字個數將超過關鍵字限制個數，請先删除部分關鍵字！',
    keywordsTip3: '關鍵字個數不超過{count}個',
    keywordsTip4: '請按正確的公式填寫',
    keywordsTip5: '關鍵字搜索模式輸入不能包含&、{`|`}、！、（、）符號',
    standardFuzzy: ['精准', '模糊'],
    standardFuzzyLabel: '精准/模糊',
    confirm: '确定',
    cancel: '取消',
    notes: [
      `1. 公式可包括"与"，"或”，"非"的关系，用"&"代表"与"，"{'|'}"代表"或"，"!"代表"非";`,
      `2. 每个公式中的每组关键词必须以"()"包含，不支持2级括号及1组以上配置；`,
      `3. 关键词搜索模式输入不能包含&、{'|'}、!、(、)符号；`,
      `4. 表达式搜索模式下，每个公式中的每组关键词只能有&或{'|'}一种关系，大于2组关键词时，每组关键词之间不能出现{'|'}和!同时存在，也不能出现{'|'}和&同时存在；多组公式或关键词之间是"或关系"；回车输入下一组关键词或公式。`
    ],
    examples: [
      `(北京&冬奥)&(滑雪{'|'}滑冰{'|'}冰球{'|'}雪橇)!(雪车{'|'}冰壶)`,
      `(中国{'|'}北京)&(科技冬奥{'|'}冰雪科技)`
    ],
    topBtns: ['添加分析', '添加监测', '大模型报告', '可视化大屏'],
    timeControl: ['请选择开始时间', '请选择结束时间', '至'],
    infoMatch: '信息匹配',
    dataType: '数据类型',
    textPosition: '命中位置',
    Sentiment: '情感倾向',
    InfoClassification: '信息分类',
    STCTMatch: '简繁匹配',
    dialogue: '对话',
    mediaScope: '媒体范围',
    mediaTag: '媒体标签',
    specifiedMedia: '指定媒体',
    mediaLevel: '媒体等级',
    mediaLevelTips: [
      '在全球影响力大、覆盖面广、传播力强、受众数量多的传媒机构，主要包括电视台、广播电台、报纸、杂志和新闻资讯网站等',
      '在领域有很高的知名度、权威性、影响力，报道内容深度和广度都很高',
      '指在领域内具有一定专业性和用户群体的媒体',
      '具有国家官方背景，由政府直接或间接控制，或者为其提供经费补贴资源的媒体'
    ],
    mediaRegion: '媒体地域',
    organizationType: '机构类型',
    accountProperty: '账号属性',
    regionOrLanguage: '地域/语言',
    advancedFilter: '高级筛选',
    fansList: ['0-1万', '1-10万', '10-100万', '100万以上'],
    areaList: ['IP属地', '媒体属地', '内容提及'],
    noKeywordOrMeidasTip: '请输入关键词或选择媒体范围',
    zhandianfanwei: '站点范围',
    siteWideList: ['全部站点', '付费站点', '非付费站点']
  },
  searchScheme: {
    savedScheme: '已保存的主題',
    placeholderEnterTopicName: '輸入主題名稱',
    placeholderEnterTopicName1: '請輸入主題名稱',
    topicName: '主題名稱',
    lastModifiedDate: '最後修改日期',
    saveBtn: '保存',
    saveNewTheme: '記錄另存',
    searchTopics: '蒐索主題',
    enterName: '輸入名稱',
    noFilterData: '無篩選結果',
    deleteSchemeTip: '確認刪除主題',
    deleteSchemeChildTip: '將同時删除所有子主題',
    cancel: '取消',
    confirm: '確認',
    tip: '提示',
    transferAnalysisSuccTip: '添加分析成功!',
    viewLater: '稍後查看',
    gocheck: '去查看',
    createAnalysis: '創建分析',
    createSubthemes: '創建子主題',
    rename: '重命名',
    delete: '删除',
    emptyThemeName: '主題名稱不可為空',
    themeNameLimit: '主題名稱長度不可超過20個字元',
    createTheme: '創建主題',
    createFolder: '創建文件夹',
    deleteTheme: '刪除主題',
    createThemeSucessTip: '主題創建成功',
    createThemeFailed: '主題創建失敗',
    saveThemeSucc: '主題保存成功',
    saveThemeFailed: '主題保存失敗',
    nameModifiedSucc: '名稱修改成功',
    nameModifiedFailed: '名稱修改失敗',
    deleteSucceeded: '删除成功',
    deleteFailed: '删除失敗',
    keywordEmpty: '關鍵字不可為空',
    operationSucceeded: '操作成功',
    operationFailed: '操作失敗',
    createFailed: '創建失敗',
    createTopic: '創建主題',
    duplicate: '复制',
    placeholderEnterTopicName2: '請輸入文件夹名稱',
    addedSuccess: '添加成功!'
  },
  searchResult: {
    mode1: '表达式模式',
    mode2: '关键词模式',
    mode3: '搜索',
    modeSW1: '轉換為關鍵字搜索模式',
    modeSW2: '轉換為運算式蒐索模式',
    inputRich1: '必要關鍵字',
    inputRich2: '任意包含',
    inputRich3: '需要排除',
    allCheck: '全选',
    multipleSort: '综合排序',
    timeSort: '时间排序',
    hotSort: '热度',
    commonSort: '评论量',
    downLoad: '下载',
    checked: '已选',
    downConfirm: '确定下载',
    confirm: '确认',
    custom: '自定义',
    cancel: '取消',
    moreFilters: '更多篩選',
    full: '全文',
    all: '全部',
    duplicate: '去重',
    notDuplicate: '不去重',
    drowTitle: '标题/Title(更多筛选)',
    Release: '发布渠道',
    clearAll: '全部清空'
  },
  designatedMediaLabelId: {
    saveBtn: '保存',
    cancel: '取消',
    addMediaTip: '添加媒体',
    addMediaList: '添加媒体组'
  },
  searchSpecifiedMedia: {
    centralMedia: '中央媒体',
    businessPortal: '商业门户',
    selectedMedia: '已选媒体',
    selectMedia: '选择媒体',
    clear: '清空',
    noMediaTip: ['暂无媒体,可点击', '选择媒体', '进行添加'],
    forfiltering: '在结果中',
    include: '筛选',
    exclude: '排除',
    mediaGroup: '媒体组',
    mediaGroupPlaceholder: '输入媒体组名称',
    saveAndApply: '保存并应用',
    mediaSearchPlaceholder: '搜索添加',
    addAll: '添加全部',
    searchWithinaWebsite: '站点内搜索',
    nodata: '暂无数据',
    search: '检索',
    advancedSearchPlaceholder: '请输入站点名称和域名，如sina.cn',
    advancedSearch: '高级搜索',
    loadMoreText: ['加载中', '上划加载更多', '没有更多了'],
    searchTip: '可通过右上角“搜索”功能，搜索添加',
    addNewGroup: '保存到新的媒体组',
    mediaGroupBtn: [
      '创建分组',
      '编辑分组',
      '请输入分组名称',
      '删除分组',
      '分组被删除后，无法找回，是否确认要删除此分组'
    ],
    channelType: ['站点', '频道/群组', '账号'],
    mediaGrouPlaceholder: '输入媒体组名称',
    masterStation: '主站',
    subStation: '子站'
  }
};
