import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import ABigScreenPage from './src/index.vue';

ABigScreenPage.install = (app: App): void => {
  app.component('ABigScreenPage', ABigScreenPage);
  app.component(
    'ABigScreenPageProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default ABigScreenPage;
