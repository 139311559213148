<script lang="ts" setup>
import { onBeforeUnmount, onMounted, shallowRef, watchEffect } from 'vue';
import type { ShallowRef, Ref } from 'vue';
import { debounce } from 'lodash-es';

interface CardDatum {
  name: string;
  value: number | string;
  formatter?: (val: number | string) => string;
  icon?: string;
  color?: string;
}

interface CardChartProps {
  data: CardDatum[];
}

interface Emits {
  (e: 'chart:click', params: any): void;
}

const props = defineProps<CardChartProps>();

const emit = defineEmits<Emits>();
const size = shallowRef(Symbol());
const resize = debounce(() => {
  size.value = Symbol();
}, 100);

const iconNames = [
  'comment',
  'emoji',
  'like',
  'play',
  'reading',
  'share'
] as const;
const colors = [
  '#007cff',
  '#fe7770',
  '#ff9030',
  '#b45bf3',
  '#6093ff',
  '#00cb9b'
];
const bgImage = (index: number) => {
  const images = iconNames;
  const image = images[index % images.length];
  const href = new URL('./images/' + image + '-count-bg.png', import.meta.url)
    .href;
  return href;
};
const color = (index: number) => {
  return colors[index % colors.length];
};
const getIcon = (icon: typeof iconNames[number]) => {
  let url: string = icon;
  if (iconNames.indexOf(icon) > -1) {
    url = icon + '-count.png';
    return new URL('./images/' + url, import.meta.url).href;
  }
  return url;
};
watchEffect(() => {
  // 收集size依赖
  size.value;

  const data = props.data;
});

onMounted(() => {
  window.addEventListener('resize', resize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resize);
});
</script>
<!-- <template>
  <base-chart ref="chartRef" :option="option" class="wh-chart--card" prevent-resize />*
</template> -->
<template>
  <ul class="wh-chart--card" prevent-resize>
    <li
      class="card-item"
      v-for="(item, index) of data"
      :key="item.name"
      :style="{ backgroundImage: `url(${item.bg})` }"
    >
      <img class="icon" :src="getIcon(item.icon)" />
      <p>{{ item.value }}</p>
      <p :style="{ color: color(index) }" class="count">{{ item.name }}</p>
    </li>
  </ul>
</template>
<style lang="scss" scoped>
.wh-chart--card {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .card-item {
    position: relative;
    width: 23%;
    height: 70%;
    background-size: 100% 100%;
    list-style: none;
    .icon {
      display: block;
      margin: 20% auto;
    }
    p {
      font-size: 20px;
      text-align: center;
      color: #fff;
      & + p {
        position: absolute;
        bottom: 10%;
        left: 0;
        right: 0;
        font-size: 16px;
      }
    }
  }
}
</style>
