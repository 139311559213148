<template>
  <div
    v-loading="loading"
    v-nodata="!Boolean(data.length) && !loading"
    style="overflow-y: auto"
  >
    <!-- <img v-if="isImage && imageUrl" :src="imageUrl" style="width: 100%; height: 100%" /> -->
    <chart
      ref="refChart"
      v-if="!loading && data.length"
      :option="option"
      :theme="theme"
      :key="updateKey"
      @chart:click="chartClick"
    />
  </div>
</template>
<script lang="ts">
import {
  ShallowRef,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  shallowRef,
  toRef,
  inject,
  watch
} from 'vue';
import useAnalysisApi from '/@/api/analysis';
import { isImage, getChartImage, utilDebounce } from '../../utils';
import type { ECOption } from '@wenhai/visual-components/utils/buildChart';
import chart from './chart.vue';
import * as echarts from 'echarts';
import { storeToRefs } from 'pinia';
import { useEditorStore } from '/@/stores/analysis';
import { useI18n } from 'vue-i18n';
import { useThemeConfig } from '/@/stores/themeConfig';

const backgroundcolor = ['#F6F9FF', '#FEFAFA'];
const shadowColor = ['#DFE6FF', '#FFDADB'];
const itemStyleColor = [
  new echarts.graphic.LinearGradient(
    0,
    0,
    1,
    0,
    [
      {
        offset: 0,
        color: '#2D6AEA' // 0% 处的颜色
      },
      {
        offset: 1,
        color: '#48B3F0' // 100% 处的颜色
      }
    ],
    false
  ),
  new echarts.graphic.LinearGradient(
    0,
    0,
    1,
    0,
    [
      {
        offset: 0,
        color: '#FF6163' // 0% 处的颜色
      },
      {
        offset: 1,
        color: '#FF9A9C' // 100% 处的颜色
      }
    ],
    false
  )
];
export default defineComponent({
  // 账号性别分布
  name: 'ASexDistribution',
  components: { chart },
  props: {
    params: {
      type: Object,
      required: true
    },
    theme: String,
    drilling: Boolean
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const storesThemeConfig = useThemeConfig();
    const { themeConfig } = storeToRefs(storesThemeConfig);
    const { genderDistribution } = useAnalysisApi();
    const params = toRef(props, 'params');
    const loading = ref<boolean>(false);
    const refChart = ref();
    const imageUrl = ref('');
    const data = ref<any[]>([]);
    const legendData = [
      t('message.analysisDetails.sexDistributionItem[0]'),
      t('message.analysisDetails.sexDistributionItem[1]')
    ]; // ['男性', '女性']
    let option: ShallowRef<ECOption> = shallowRef({});
    const EditorStore = useEditorStore();
    const { drillPreviewData } = storeToRefs(EditorStore);
    const chartsClick = inject('chartsClick', undefined);
    const searchResultList = inject('searchResultList', undefined);
    const apiData = ref();
    const setDrillPreviewData = () => {
      drillPreviewData.value = {
        startPubTime: apiData.value?.startPubTime,
        endPubTime: apiData.value?.endPubTime
      };
    };
    const getData = async () => {
      if (!params.value.schemeId) return;
      loading.value = true;
      data.value.splice(0);
      imageUrl.value = '';
      try {
        const res: any = await genderDistribution(params.value);
        if (res.code === 200) {
          apiData.value = res.data;
          if (chartsClick) {
            setDrillPreviewData();
            searchResultList();
          }
          data.value = res?.data?.itemList;
          changeOption();
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
        imageUrl.value = '';
      }
    };
    const update = () => {
      getData();
    };
    const saveChartImage = utilDebounce(() => {
      refChart.value && (imageUrl.value = getChartImage(refChart));
    }, 300);
    const updateKey = ref();
    watch(toRef(props, 'theme'), val => {
      nextTick(() => {
        changeOption();
        updateKey.value = new Date().getTime();
      });
    });
    const changeOption = () => {
      option.value = {};
      const mData: any = [],
        fData: any = [],
        yAxisData: any = [];
      data.value.forEach(v => {
        yAxisData.push(v.dataSource);
        fData.push({
          name: v.dataSource,
          value: v.femalePercent.substring(0, v.femalePercent.length - 1),
          valueCount: v.femaleCount,
          tooltip: {
            formatter: function (params: any) {
              // console.log(params,'fData');

              var s =
                params.marker +
                params.seriesName +
                '<br/>' +
                params.data.name +
                '：' +
                params.data.value +
                '%';
              return s;
            },
            confine: true
          }
        });
        mData.push({
          name: v.dataSource,
          value: v.malePercent.substring(0, v.malePercent.length - 1),
          valueCount: v.maleCount,
          tooltip: {
            formatter: function (params: any) {
              // console.log(params,'mData');
              var s =
                params.marker +
                params.seriesName +
                '<br/>' +
                params.data.name +
                '：' +
                params.data.value +
                '%';
              return s;
            },
            confine: true
          }
        });
      });

      if (props.theme == 'bigscreen-theme-2') {
        option.value = {
          tooltip: {
            trigger: 'axis',
            formatter: function (params: any) {
              let s = params[0].name + '</br>';
              params.forEach(v => {
                s += v.marker + v.seriesName + '  ' + v.data.value + '%<br/>';
              });
              return s;
            },
            confine: true
          },
          legend: {
            top: 0,
            icon: 'rect',
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              color: 'rgba(216,240,255,0.8)'
            },
            backgroundColor: 'rgba(54,97,158,0.22)',
            itemGap: 24
          },
          grid: {
            top: 20,
            left: 0,
            right: 16,
            bottom: '0%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            splitLine: {
              //纵向分隔线
              show: true,
              lineStyle: {
                color: 'rgba(176, 215, 255, 0.25)',
                type: 'dotted'
              }
            },
            axisLabel: {
              color: 'rgba(216, 240, 255, 0.60)',
              formatter: function (value) {
                return value + '%';
              }
            }
          },
          yAxis: {
            type: 'category',
            data: yAxisData,
            axisLine: {
              lineStyle: {
                color: 'rgba(176, 215, 255, 0.40)',
                type: 'dotted'
              }
            },
            axisLabel: {
              color: 'rgba(216, 240, 255, 0.60)'
            },
            axisTick: {
              show: false
            }
          },
          series: [
            {
              name: t('message.analysisDetails.sexDistributionItem[0]'), // 男性
              type: 'bar',
              stack: 'total',
              barWidth: 16,
              label: {
                show: false
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: '#1978E5'
                  },
                  {
                    offset: 1,
                    color: 'rgba(25, 120, 229, 0)'
                  }
                ])
              },
              data: mData
            },
            {
              name: t('message.analysisDetails.sexDistributionItem[1]'), // 女性
              type: 'bar',
              stack: 'total',
              barWidth: 16,
              label: {
                show: false
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: ' #FF6FBE'
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 111, 190, 0)'
                  }
                ])
              },
              data: fData
            }
          ]
        };
      } else {
        const optionsSeries: any = [];

        legendData.forEach((item: any, index: number) => {
          optionsSeries.push({
            name: item,
            type: 'bar',
            barWidth: 20,
            data: index == 0 ? mData : fData,
            itemStyle: {
              color: itemStyleColor[index],
              borderRadius: 2,
              shadowColor: shadowColor[index],
              shadowOffsetX: 1,
              shadowOffsetY: 1
            },
            label: {
              show: true,
              color: '#fff',
              verticalAlign: 'middle',
              position: [10, 11],
              formatter: function (params: any) {
                return `{icon|}` + '  ' + params.value + '%';
              },
              rich: {
                icon: {
                  width: 3,
                  height: 3,
                  borderColor: 'rgba(255,255,255,0.34)',
                  borderRadius: 3,
                  borderWidth: 1,
                  backgroundColor: '#fff'
                }
              }
            },
            showBackground: true,
            backgroundStyle: {
              color: backgroundcolor[index]
            }
          });
        });
        option.value = {
          tooltip: {
            trigger: 'item',
            confine: true
          },
          graphic: [
            {
              type: 'group',
              left: 20,
              top: 2,
              children: [
                {
                  type: 'text',
                  z: 100,
                  left: 140,
                  top: 5,
                  style: {
                    fill: '#007CFF',
                    text: t('message.analysisDetails.sexDistributionTitle[0]'), // 男性占比
                    fontSize: 18,
                    fontWeight: 'bold',
                    width: themeConfig.value.globalI18n == 'en' ? 160 : 76
                  }
                },
                {
                  type: 'text',
                  z: 100,
                  left: themeConfig.value.globalI18n == 'en' ? 300 : 216,
                  top: 5,
                  style: {
                    fill: '#41464F',
                    text: '&',
                    fontSize: 18,
                    fontWeight: 'bold'
                  }
                },
                {
                  type: 'text',
                  z: 100,
                  left: themeConfig.value.globalI18n == 'en' ? 320 : 236,
                  top: 5,
                  style: {
                    fill: '#FF6A68',
                    text: t('message.analysisDetails.sexDistributionTitle[1]'), // 女性占比
                    fontSize: 18,
                    fontWeight: 'bold',
                    width: themeConfig.value.globalI18n == 'en' ? 160 : 76
                  }
                }
              ]
            }
          ],
          grid: {
            left: '3%',
            right: '4%',
            top: 20,
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            max: 100,
            axisTick: { show: false },
            axisLine: {
              show: false
            },
            splitLine: { show: false },
            axisLabel: { show: false }
          },
          yAxis: {
            type: 'category',
            inverse: true,
            data: yAxisData,
            axisTick: { show: false },
            axisLine: {
              show: false
            },
            axisLabel: {
              color: '#41464F',
              align: 'right',
              verticalAlign: 'bottom',
              padding: [0, 0, 8, 0]
            }
          },
          series: optionsSeries
        };
      }
    };
    onMounted(() => {
      getData();
    });
    const chartClick = obj => {
      if (!props.drilling || EditorStore.drillingTrigger !== 'chartItemClick')
        return;
      setDrillPreviewData();
      emit('chartItemClick', obj);
    };
    return {
      loading,
      isImage,
      refChart,
      imageUrl,
      data,
      option,
      update,
      updateKey,
      chartClick
    };
  }
});
</script>
