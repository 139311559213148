<template>
  <div
    style="width: 100%; height: 100%"
    class="without-padding"
    v-loading="loading"
  >
    <div
      v-if="!loading"
      :key="updateKey"
      class="list-container"
      :style="{
        'grid-template-columns': comColumns,
        'grid-template-rows': comRows
      }"
    >
      <ClockChart
        class="clock-item"
        v-for="c in clockList"
        :key="c.value"
        :data="c"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, onMounted, ref, computed } from 'vue';
import { TParams } from '../../_hooks/use-data-center';
import { useDictStore } from '/@/stores/dict';

export default defineComponent({
  name: 'AWorldClock',
  props: {
    params: {
      type: Object as PropType<TParams>,
      required: true
    }
  },
  setup(props) {
    const { timezoneList } = useDictStore();

    const clockList = ref();
    const loading = ref(false);
    const getClockList = () => {
      loading.value = true;
      clockList.value = props.params.clockList?.map(id => {
        return { ...timezoneList.find(tz => tz.value === id) };
      });
      setTimeout(() => {
        loading.value = false;
      });
    };
    const comColumns = computed(() => {
      const length = clockList.value?.length;
      let rst = '';
      if (length <= 3) {
        rst = `repeat(${length}, 1fr)`;
      } else if (length === 4) {
        rst = `repeat(2, 1fr)`;
      } else {
        rst = `repeat(3, 1fr)`;
      }
      return rst;
    });
    const comRows = computed(() => {
      const length = clockList.value?.length;
      let rst = '';
      if (length <= 3) {
        rst = `repeat(1, 1fr)`;
      } else {
        rst = `repeat(2, 1fr)`;
      }
      return rst;
    });
    const updateKey = ref();
    const update = () => {
      getClockList();
    };
    onMounted(() => {
      getClockList();
      window.addEventListener('resize', () => {
        updateKey.value = new Date().getTime();
      });
    });
    return {
      update,
      clockList,
      loading,
      comColumns,
      comRows,
      updateKey
    };
  }
});
</script>
<style lang="scss" scoped>
.list-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
}
</style>
