import type { App } from 'vue';
import { loadAsyncComponent } from '/@/utils/async-component';
import ExpertOpinion from './src/index.vue';

ExpertOpinion.install = (app: App): void => {
  app.component('AExpertOpinion', ExpertOpinion);
  app.component(
    'AExpertOpinionProp',
    loadAsyncComponent(() => import('./src/config.vue'))
  );
};

export default ExpertOpinion;
