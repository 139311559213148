<template>
  <div
    class="config-radio-option"
    :class="{ 'config-radio-option-theme2': theme == 'bigscreen-theme-2' }"
  >
    <w-radio-group
      size="small"
      :model-value="modelValue"
      @change="getValue"
      type="button"
    >
      <w-radio value="" v-if="showCheckedAll"
        >{{ $t('message.searchForm.all') }}
        {{ showCount && `(${allcount})` }}</w-radio
      >
      <w-radio v-for="item in list" :key="item.id" :value="item.id"
        >{{ item.name }}{{ showCount ? `(${item.count})` : `` }}</w-radio
      >
    </w-radio-group>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';
export default defineComponent({
  name: 'ConfigRadioOption',
  props: [
    'list',
    'modelValue',
    'showCheckedAll',
    'showCount',
    'AllCount',
    'theme'
  ],
  setup(props, { emit }) {
    const allcount = computed(() => {
      if (props.showCount) {
        return (
          props.AllCount ||
          props.list.reduce((pre: any, v: any) => pre + v.count, 0)
        );
      } else return 0;
    });

    const getValue = (value: string) => {
      emit('update:modelValue', value);
    };
    return { getValue, allcount };
  }
});
</script>
<style lang="scss" scoped>
.config-radio-option {
  width: 100%;

  .w-radio-group-button {
    display: inline-block;
  }

  &.config-radio-option-theme2 {
    .w-radio-group-button {
      .w-radio-button {
        background: transparent;
        background: linear-gradient(
          180deg,
          rgba(172, 206, 234, 0) 0%,
          rgba(172, 206, 234, 0.1) 100%
        );
        border: 1px solid;
        border-image: linear-gradient(
            180deg,
            rgba(172, 206, 234, 0),
            rgba(172, 206, 234, 0.4)
          )
          1 1;
        border-radius: 0;

        :deep(.w-radio-button-content) {
          border: none;
          color: rgba(189, 219, 255, 0.6) !important;
          padding: 0 12px;
        }

        &:not(:first-child) {
          border-left: none;
        }

        &.w-radio-checked {
          background: linear-gradient(
              180deg,
              rgba(70, 171, 255, 0) 0%,
              rgba(70, 171, 255, 0.2) 100%
            ),
            linear-gradient(
              180deg,
              rgba(70, 171, 255, 0.3) 0%,
              rgba(70, 171, 255, 0) 100%
            );
          border-image: linear-gradient(
              180deg,
              rgba(70, 171, 255, 0),
              rgba(70, 171, 255, 1)
            )
            1 1;

          :deep(.w-radio-button-content) {
            color: #fff !important;
          }
        }
      }
    }
  }
}
</style>
