<script lang="ts" setup>
import InfoListItem from '@wenhai/visual-components/InfoList/InfoListItem.vue';
import InfoListItemTheme2 from '@wenhai/visual-components/InfoList/InfoListItemTheme2.vue';
import { TTheme } from '../utils';
import { Vue3SeamlessScroll } from 'vue3-seamless-scroll';
import { toRef } from 'vue';

export interface InfoListDataItem {
  id: string;
  title: string;
  url: string;
  dataSource: string;
  dataSourceType: string;
  site: string;
  pubtime: string;
  readCount: number;
  likeCount: number;
  commentCount: number;
  rttCount: number;
  sortField: number;
}
interface InfoListProps {
  data: InfoListDataItem[];
  theme: TTheme;
  itemClickAble: boolean;
  titleRows?: number;
  autoScroll: boolean;
  step: number;
}
const props = defineProps<InfoListProps>();
interface Emits {
  (e: 'chart:click', params: any): void;
}
const emit = defineEmits<Emits>();
const autoScroll = toRef(props, 'autoScroll');
</script>
<template>
  <div v-if="autoScroll" @wheel.prevent>
    <vue3-seamless-scroll
      style="overflow: hidden"
      :list="data"
      v-model="autoScroll"
      :step="step"
      hover
      wheel
    >
      <ul class="wh-chart--info-list" :class="props.theme">
        <li
          class="wh-chart--info--item"
          :class="{ 'click-able': itemClickAble }"
          v-for="(item, i) in data"
          :key="item.id"
          @click="itemClickAble ? emit('chart:click', item) : null"
        >
          <slot name="item" v-bind="{ index: i, ...item }">
            <InfoListItemTheme2
              :titleRows="titleRows"
              :index="i"
              v-bind="item"
              v-if="props.theme == 'bigscreen-theme-2'"
            />
            <info-list-item
              :titleRows="titleRows"
              :index="i"
              v-bind="item"
              v-else
            ></info-list-item>
          </slot>
        </li>
      </ul>
    </vue3-seamless-scroll>
  </div>
  <ul class="wh-chart--info-list" :class="props.theme" v-else>
    <li
      class="wh-chart--info--item"
      :class="{ 'click-able': itemClickAble }"
      v-for="(item, i) in data"
      :key="item.id"
      @click="itemClickAble ? emit('chart:click', item) : null"
    >
      <slot name="item" v-bind="{ index: i, ...item }">
        <InfoListItemTheme2
          :titleRows="titleRows"
          :index="i"
          v-bind="item"
          v-if="props.theme == 'bigscreen-theme-2'"
        />
        <info-list-item
          :titleRows="titleRows"
          :index="i"
          v-bind="item"
          v-else
        ></info-list-item>
      </slot>
    </li>
  </ul>
</template>
<style lang="scss" scoped>
@use '../style/colors' as c;
@use '../style/mixins' as m;

.wh-chart--info-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  font-size: 14px;
  padding-right: 8px;
  &:deep.bigscreen-theme-1 {
    .wh-chart--info--item {
      .wh-item--wrapper {
        .wh-item--main {
          .wh-item--title {
            color: #c4d9f3;
          }
        }
      }
    }
  }

  .wh-chart--info--item {
    display: flex;
    &.click-able {
      cursor: pointer;
    }
    @include m.markTop3('wh-item--index');
  }
}
</style>
<style lang="scss">
@use '../style/mixins' as m;
@include m.markRed();
</style>
