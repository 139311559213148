import { defineStore } from 'pinia';
import useAuthorityApi from '/@/api/authority';
import { Local } from '../utils/storage';
const { getUserPermControl } = useAuthorityApi();
interface Permission {
  sourcesNum: number; //监测单方案信源量 (对应后台管理schemeSourceNum字段)
  schemeNum: number; //监测方案总量
  expireTime: string; //到期时间
  dataAuth: number; //数据权限（境内外）
  keywordNum: number; //监测单方案关键词量（对应后台管理schemeKeywordNum字段）
  schemeDataNum: number; //监测/预警方案日最大数据量
  analysisPlanNum: number; //分析方案总量
  largeScreenSolutionNum: number; //大屏方案总量
  largeModelReportNum: number; //大模型报告剩余次数

  searchDataRange: number; //历时数据范围
  searchDataSelectRange: number; //检索时间跨度
  searchKeywordNum: number; //搜索关键词数量
  searchMediaNum: number; //搜索指定媒体数量
  searchSchemeNum: number; //搜索方案总量
  paidEnable: number; //付费站点查询
}
export const useUserPermission = defineStore('userPermission', {
  state: (): Permission => ({
    sourcesNum: 0,
    schemeNum: 5,
    expireTime: '',
    dataAuth: 0,
    keywordNum: 0,
    schemeDataNum: 0,
    analysisPlanNum: 0,
    largeScreenSolutionNum: 0,
    largeModelReportNum: 0,
    searchDataRange: 92,
    searchDataSelectRange: 180,
    searchKeywordNum: 50,
    searchMediaNum: 50,
    searchSchemeNum: 1000,
    paidEnable: 0
  }),
  actions: {
    async getPermission() {
      const res = await getUserPermControl();
      const obj = res.data;
      Object.keys(obj).forEach(key => {
        obj[key]?.toString() != 'undefined' &&
          obj[key]?.toString() !== 'null' &&
          (this[key] = obj[key]);
      });
      Local.set('userPermission', JSON.stringify(obj));
    },
    update(key: string, value: number | string) {
      this[key] = value;
      const localObj = JSON.parse(Local.get('userPermission'));
      localObj[key] = value;
      Local.set('userPermission', JSON.stringify(localObj));
    }
  }
});
