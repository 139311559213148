<template>
  <el-config-provider :size="getGlobalComponentSize" :locale="zhCn">
    <router-view v-show="themeConfig.lockScreenTime > 1" />
    <LockScreen v-if="themeConfig.isLockScreen" />
    <Setings ref="setingsRef" v-show="themeConfig.lockScreenTime > 1" />
    <CloseFull v-if="!themeConfig.isLockScreen" />
  </el-config-provider>
</template>

<script lang="ts">
import {
  computed,
  ref,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onUnmounted,
  nextTick,
  defineComponent,
  watch
} from 'vue';
import { useRoute } from 'vue-router';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import { storeToRefs } from 'pinia';
import { useTagsViewRoutes } from '/@/stores/tagsViewRoutes';
import { useThemeConfig } from '/@/stores/themeConfig';
import other from '/@/utils/other';
import { Local, Session } from '/@/utils/storage';
import setIntroduction from '/@/utils/setIconfont';
import LockScreen from '/@/layout/lockScreen/index.vue';
import Setings from '/@/layout/navBars/breadcrumb/setings.vue';
import CloseFull from '/@/layout/navBars/breadcrumb/closeFull.vue';
import Cookies from 'js-cookie';
import { useUserInfo } from '/@/stores/userInfo';
import pinia from '/@/stores';
import useAuthorityApi from '/@/api/authority';
import { checkAppNewVersion, getBrowserInfo } from '/@/utils/other';
import logoWisky from '/@/assets/logo-wisky-1x.png';
import logoWiskyWhite from '/@/assets/logo-wisky-white-1x.png';
import loginBackground from '/@/assets/login/bg.jpg';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { UPDATE_MESAGE_TIP_WHITE_NAME } from '/@/constants/cons';
export default defineComponent({
  name: 'app',
  components: { LockScreen, Setings, CloseFull },
  setup() {
    const { proxy } = <any>getCurrentInstance();
    const setingsRef = ref();
    const route = useRoute();
    const stores = useTagsViewRoutes();
    const storesThemeConfig = useThemeConfig();
    const { themeConfig } = storeToRefs(storesThemeConfig);
    // 获取全局组件大小
    const getGlobalComponentSize = computed(() => {
      return other.globalComponentSize();
    });
    // 布局配置弹窗打开
    const openSetingsDrawer = () => {
      setingsRef.value.openDrawer();
    };
    const { getDetailProductCustomization } = useAuthorityApi();
    const currentLogo = computed(() => {
      return route.meta?.bigscreenMode ? logoWiskyWhite : logoWisky;
    });
    const getDetailCustomization = () => {
      const appId =
        window.location.href.indexOf(import.meta.env.VITE_HOST_URL) == -1
          ? Cookies.get('appId')
          : proxy.$route.query.appId || Local.get('appId');

      // 本地调试用下面一行，注释上面的一行，测试或生产环境需注释下面一行，放开上面一行
      // const appId = proxy.$route.query.appId || Local.get('appId');
      if (!appId) {
        Local.get('appId') && Local.remove('appId');
        themeConfig.value.globalTitle = 'WiSKY•晴天 多语言分析平台';
        themeConfig.value.typeLogo = currentLogo.value;
        themeConfig.value.navigationLogo = currentLogo.value;
        themeConfig.value.loginBackground = loginBackground;
        themeConfig.value.loginBoxLocation = 0;
        document.head.querySelectorAll('link').forEach(link => {
          if (link.rel === 'icon') {
            link.parentNode?.removeChild(link);
          }
        });
        let favicon = document.createElement('link');
        favicon.rel = 'icon';
        favicon.href = '/favicon.ico';
        document.head.appendChild(favicon);
        return;
      }
      if (appId) Local.set('appId', appId);
      getDetailProductCustomization(appId).then((res: any) => {
        if (res?.code == 200) {
          themeConfig.value.globalTitle =
            res?.data?.bannerIconTitle || 'WiSKY•晴天 多语言分析平台';
          other.useTitle();
          if (res?.data?.logoEnable == 1) {
            themeConfig.value.typeLogo = '';
            // 导航logo 彩色/白色
            themeConfig.value.navigationLogo = '';
            themeConfig.value.loginBackground = loginBackground;
            themeConfig.value.loginBoxLocation = 0;
            document.head.querySelectorAll('link').forEach(link => {
              if (link.rel === 'icon') {
                link.parentNode?.removeChild(link);
              }
            });
            let favicon = document.createElement('link');
            favicon.rel = 'icon';
            favicon.href = 'www.xxx.com/img/flexible/logo/pc/result.png';

            document.head.appendChild(favicon);
          } else {
            themeConfig.value.typeLogo = res?.data?.loginLogo || currentLogo;
            // 导航logo 彩色/白色
            themeConfig.value.navigationLogo =
              res?.data?.bannerLogo || currentLogo;
            themeConfig.value.loginBackground =
              res?.data?.loginBackground || loginBackground;
            themeConfig.value.loginBoxLocation =
              res?.data?.loginBoxLocation || 0;
            document.head.querySelectorAll('link').forEach(link => {
              if (link.rel === 'icon') {
                link.parentNode?.removeChild(link);
              }
            });
            let favicon = document.createElement('link');
            favicon.rel = 'icon';
            favicon.href = res?.data?.bannerIcon || '/favicon.ico';
            document.head.appendChild(favicon);
          }
        }
      });
    };
    // 设置初始化，防止刷新时恢复默认
    onBeforeMount(() => {
      // 设置批量第三方 icon 图标
      setIntroduction.cssCdn();
      // 设置批量第三方 js
      setIntroduction.jsCdn();
    });
    const getBrowserFingerprint = async () => {
      try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        Session.set('agent-uuid', result.visitorId);
      } catch (error) {
        console.error('获取浏览器指纹失败', error);
      }
    };
    // 页面加载时
    onMounted(() => {
      nextTick(() => {
        // 监听布局配置弹窗点击打开
        proxy.mittBus.on('openSetingsDrawer', () => {
          openSetingsDrawer();
        });
        // 获取缓存中的布局配置
        if (Local.get('themeConfig')) {
          storesThemeConfig.setThemeConfig(Local.get('themeConfig'));
          document.documentElement.style.cssText =
            Local.get('themeConfigStyle');
        }
        // 获取缓存中的全屏配置
        if (Session.get('isTagsViewCurrenFull')) {
          stores.setCurrenFullscreen(Session.get('isTagsViewCurrenFull'));
        }
        // getDetailCustomization();
      });
      !import.meta.env.VITE_LOCALIZATION &&
        document.addEventListener('visibilitychange', function () {
          // console.log('show ===>', document.visibilityState, !document.hidden)
          if (
            !document.hidden &&
            !UPDATE_MESAGE_TIP_WHITE_NAME.includes(route.name)
          ) {
            checkAppNewVersion();
          }
        });
      // 刷新时保持布局设置
      storesThemeConfig.setThemeConfigOne(
        'layout',
        Local.get('oldLayout') || 'transverse'
      );
    });
    // 页面销毁时，关闭监听布局配置/i18n监听
    onUnmounted(() => {
      proxy.mittBus.off('openSetingsDrawer', () => {});
    });
    // 监听路由的变化，设置网站标题
    const { userInfos } = storeToRefs(useUserInfo(pinia));
    watch(
      () => route.path,
      () => {
        other.useTitle();
        // 新页面切换账号后（原页面刷新/操作，登录账号需要也刷新）Cookies中的userName和pinia中的userName不一样代表浏览器登录了两个账号，返回原页面进行操作需要刷新页面，userName没值代表未登录账号
        if (
          Cookies.get('userName') != userInfos.value.userName &&
          Cookies.get('userName') &&
          userInfos.value.userName
        ) {
          location.reload(); // 页面刷新
        }
        getDetailCustomization();
        getBrowserFingerprint();
        const browserInfo = getBrowserInfo();
        Session.set('browser-info', browserInfo);
      },
      {
        deep: true
      }
    );

    return {
      zhCn,
      themeConfig,
      setingsRef,
      getGlobalComponentSize
    };
  }
});
</script>
