<template>
  <div class="count-down" ref="countdownRef">
    <div ref="countdownContentRef" :style="{ transform: `scale(${scale})` }">
      <span class="count-down-label">{{ params.countdownLabel }}</span>
      <div class="count-down-content">
        <template v-for="item in dateAllList" :key="item.key">
          <div class="count-down-content-item" v-if="item.value">
            <span
              class="item-count"
              :style="{ fontSize: item.fontSize + 'px' }"
              >{{ item.value.join('') }}</span
            >
            <span class="item-label">{{ item.label }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  onMounted,
  ref,
  onUnmounted,
  reactive,
  computed,
  nextTick
} from 'vue';
import { TParams } from '../../_hooks/use-data-center';
import { Message } from 'winbox-ui-next';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'ACountdown',
  props: {
    params: {
      type: Object as PropType<TParams>,
      required: true
    }
  },
  setup(props) {
    const date = reactive({
      day: 0,
      hour: 0,
      minute: 0,
      seconds: 0
    });
    const reset = () => {
      date.day = 0;
      date.hour = 0;
      date.minute = 0;
      date.seconds = 0;
    };
    const timer = ref();
    const endTimeFormat = reactive({
      second: 'YYYY-MM-DD HH:mm:ss',
      minute: 'YYYY-MM-DD HH:mm',
      hour: 'YYYY-MM-DD HH:00',
      day: 'YYYY-MM-DD'
    });
    const getData = () => {
      reset();
      clearInterval(timer.value);
      const countdownEndtime = props.params.countdownEndtime;
      if (!countdownEndtime) return;
      if (new Date(countdownEndtime) <= new Date()) {
        Message.warning('截止时间必须大于当前时间');
        return;
      }
      timer.value = setInterval(() => {
        const now = dayjs().format(
          endTimeFormat[props.params?.countdownUnit as any]
        );
        const deadLine = dayjs(countdownEndtime);
        const day = deadLine.diff(now, 'day');
        const hour = deadLine.diff(now, 'hour') - day * 24;
        const minute = deadLine.diff(now, 'minute') - day * 24 * 60 - hour * 60;
        const seconds =
          deadLine.diff(now, 'seconds') -
          day * 24 * 60 * 60 -
          hour * 60 * 60 -
          minute * 60;
        date.day = day;
        date.hour = hour;
        date.minute = minute;
        date.seconds = seconds;
        if (day + hour + minute + seconds == 0) {
          clearInterval(timer.value);
        }
      }, 1000);
    };
    const day = computed(() => {
      const rst = date.day;
      return rst.toString().split('');
    });
    const hour = computed(() => {
      const visible = !['day'].includes(props.params?.countdownUnit!);
      if (!visible) return;
      const rst = date.hour;
      return rst.toString().padStart(2, '0').split('');
    });
    const minute = computed(() => {
      const visible = !['day', 'hour'].includes(props.params?.countdownUnit!);
      if (!visible) return;
      const rst = date.minute;
      return rst.toString().padStart(2, '0').split('');
    });
    const second = computed(() => {
      const visible = props.params?.countdownUnit == 'second';
      if (!visible) return;
      const rst = date.seconds;
      return rst.toString().padStart(2, '0').split('');
    });
    const dateAllList = computed(() => {
      return [
        { key: 'day', label: '天', value: day.value, fontSize: 64 },
        { key: 'hour', label: '小时', value: hour.value, fontSize: 32 },
        { key: 'minute', label: '分钟', value: minute.value, fontSize: 32 },
        { key: 'second', label: '秒', value: second.value, fontSize: 32 }
      ];
    });
    const countdownRef = ref();
    const countdownContentRef = ref();
    const scale = ref();
    const updateScale = () => {
      nextTick(() => {
        const widthScale =
          countdownContentRef.value?.offsetWidth /
          countdownRef.value?.offsetWidth;
        const heightScale =
          countdownContentRef.value?.offsetHeight /
          countdownRef.value?.offsetHeight;
        scale.value = Number(
          (0.9 / Number(Math.max(widthScale, heightScale))).toFixed(2)
        );
      });
    };
    const update = () => {
      getData();
    };
    onMounted(() => {
      getData();
      window.addEventListener('resize', updateScale);
      updateScale();
    });
    onUnmounted(() => {
      clearInterval(timer.value);
      window.removeEventListener('resize', updateScale);
    });
    return {
      update,
      date,
      day,
      hour,
      minute,
      second,
      dateAllList,
      countdownRef,
      countdownContentRef,
      scale
    };
  }
});
</script>
<style lang="scss" scoped>
.count-down {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .count-down-label {
    font-size: 24px;
    color: #bddbff;
    font-family: YouSheBiaoTiHei;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 26px;
      height: 6px;
      top: 50%;
      transform: translateY(-50%);
      background: url('/@/assets/bigscreen/bg-title-dec2.png') no-repeat
        center/cover;
    }

    &:before {
      left: -33px;
    }

    &:after {
      right: -33px;
      transform: rotate(-180deg) translateY(50%);
    }
  }

  .count-down-content {
    // width: fit-content;
    width: 530px;
    height: 120px;
    white-space: nowrap;
    padding: 20px 60px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 16px;
    background: url('/@/assets/bigscreen/bg-count-down-content.png') no-repeat
      center/100% 100%;

    .count-down-content-item {
      .item-count {
        letter-spacing: 4px;
        font-weight: 500;
        background-image: -webkit-linear-gradient(top, #fff, #e3feff, #9afcff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
      }

      .item-label {
        font-size: 14px;
        color: #abb5c1;
        margin-left: 3px;
      }
    }
  }
}
</style>
